import styled from 'styled-components'

import { AvatarSize } from 'components/avatar/Avatar'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import UploadImage from 'components/input/uplaod-input/UploadImage'
import { BUTTONS, FORM, PLACEHOLDERS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import { requiredValidation } from 'utils/helpers/validations'

interface IGeneralProps {
  avatar: string
  name: string
  description: string
  formChecked: boolean
  onChange: (key: string, value: string) => void
  onUploadGroupAvatar: (fileUrl: string) => void
}

const General = ({
  avatar,
  name,
  description,
  formChecked,
  onChange,
  onUploadGroupAvatar,
}: IGeneralProps) => {
  const { t } = useTranslation()

  const isNameValid = formChecked ? requiredValidation(name, t(FORM.TEAM_NAME)).errorText : ''

  const onChangeTeamName = (value: string) => {
    onChange('name', value)
  }

  const onChangeTeamDescription = (value: string) => {
    onChange('description', value)
  }
  return (
    <Wrapper>
      <UploadImage
        avatar={avatar}
        text={name || 'G'}
        buttonText={BUTTONS.UPLOAD}
        avatarSize={AvatarSize.XXL}
        onUploadPhoto={onUploadGroupAvatar}
      />
      <FormWrapper>
        <CustomFormInput
          value={name}
          title={t(FORM.TEAM_NAME) as string}
          placeholder={t(PLACEHOLDERS.TEAM_NAME)}
          onChange={onChangeTeamName}
          errorText={isNameValid}
        />
        <CustomFormInput
          value={description}
          title={t(FORM.TEAM_DESCRIPTION) as string}
          placeholder={t(PLACEHOLDERS.TEAM_DESCRIPTION)}
          optionalTitle={t(FORM.OPTIONAL) as string}
          onChange={onChangeTeamDescription}
        />
      </FormWrapper>
    </Wrapper>
  )
}

export default General

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
`

const FormWrapper = styled.div`
  width: 100%;
`
