import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { useState } from 'react'

import SettingsSidePanel from './SettingsSidePanel'

const SystemSettings = () => {
  const [visible, setVisible] = useState(false)
  const onToggleConversationPanel = () => {
    setVisible(!visible)
  }

  return (
    <Wrapper>
      <SettingsSidePanel onToggleConversationPanel={onToggleConversationPanel} visible={visible} />
      <Outlet context={{ onToggleConversationPanel, visible }} />
    </Wrapper>
  )
}

export default SystemSettings

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`
