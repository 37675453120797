import styled, { keyframes } from 'styled-components'
import colors from 'utils/colors'

const Spinner = ({
  loading,
  position,
  width = '24',
  height = '24',
  color = colors.vimeDark,
}: {
  width?: string
  height?: string
  loading: boolean
  position?: string
  color?: string
}) => {
  if (!loading) return null
  return (
    <SpinnerWrapper position={position}>
      <CSpinner width={width} height={height} color={color} />
    </SpinnerWrapper>
  )
}

export default Spinner

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerWrapper = styled.div.attrs(
  (props: { position: string; top?: number; left?: number }) => props,
)`
  display: flex;
  position: ${({ position }) => position || 'relative'};
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 4;
`

const CSpinner = styled.div.attrs(
  (props: { width: string; height: string; color?: string }) => props,
)`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: ${({ color }) => `3px solid ${color}`};
  border-right: ${({ color }) => `3px solid ${color}`};
  border-bottom: ${({ color }) => `3px solid ${color}`};
  border-left: 6px solid transparent;
  background: transparent;
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  border-radius: 50%;
`
