import { useEffect, useRef, useState, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import _isArray from 'lodash/isArray'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { BUTTONS } from 'locales/locales'

import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import CustomIcon, { IconSourceType } from 'components/icon/CustomIcon'
import Spinner from 'components/spinner/Spinner'

interface ICustomModalProps {
  tab?: Array<{ title: string; action?: boolean; icon?: IconSourceType }>
  title?: string
  header?: ReactNode
  cancelTitle?: string
  okTitle?: string
  showButtons?: boolean
  extraButtons?: Array<ReactNode>
  children?: Array<ReactNode> | ReactNode
  hasMember?: boolean
  loading?: boolean
  overflow?: string
  size?: 'medium' | 'large'
  onOk?: () => void
  onCancel?: () => void
  closeModal: () => void
}

const CustomModal = ({
  tab,
  title,
  header,
  cancelTitle,
  okTitle,
  hasMember,
  showButtons = true,
  extraButtons,
  children,
  loading,
  overflow,
  size = 'medium',
  onCancel,
  closeModal,
  onOk = () => null,
}: ICustomModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const { t } = useTranslation()
  const [index, setIndex] = useState(0)

  useEffect(() => {
    modalRef.current?.focus()
  }, [])

  const onKeyUp = (event: { code: string }) => {
    if (event.code === 'Escape') {
      closeModal?.()
    }
  }

  const onChangeIndex = (currentIndex: number) => {
    setIndex(currentIndex)
  }

  const childrenInArray = _isArray(children)
    ? children.filter((c: ReactNode | undefined) => c !== false)
    : [children]
  const showActionButtons = tab?.[index]?.action || showButtons

  return (
    <Modal tabIndex={0} ref={modalRef} onKeyUp={onKeyUp}>
      <Wrapper size={size}>
        <HeaderWrapper>
          {title && <HeaderText typography="header2">{title}</HeaderText>}
          {header}
          <CloseButton onClick={closeModal} source={icons.closeIcon} />
          <TabWrapper>
            {tab?.map(({ title, icon }, tabIndex: number) => {
              return (
                <TabItemWrapper key={title + tabIndex} onClick={() => onChangeIndex(tabIndex)}>
                  {icon && <CustomIcon iconPreset="small" source={icon} />}
                  <CustomText
                    typography="header2"
                    color={tabIndex === index ? colors.gray100 : colors.gray60}
                  >
                    {title}
                  </CustomText>
                </TabItemWrapper>
              )
            })}
          </TabWrapper>
        </HeaderWrapper>
        <Content overflow={overflow} hasMember={tab ? index === 1 && hasMember : hasMember}>
          {loading && <Spinner loading />}
          {!loading &&
            childrenInArray?.map((node: ReactNode, nIndex: number) => index === nIndex && node)}
        </Content>
        {showActionButtons && (
          <BottomWrapper justify={extraButtons ? 'space-between' : 'flex-end'}>
            <ExtraButtonsWrapper>
              {extraButtons?.map((item: ReactNode) => item)}
            </ExtraButtonsWrapper>
            <div style={{ display: 'flex', gap: '20px' }}>
              {onCancel && (
                <CustomButton
                  disabled={loading}
                  text={cancelTitle || t(BUTTONS.CANCEL)}
                  type="secondary"
                  typography="body4"
                  onClick={onCancel}
                />
              )}
              {onOk && (
                <CustomButton
                  disabled={loading}
                  text={okTitle || t(BUTTONS.SAVE)}
                  typography="body4"
                  onClick={onOk}
                />
              )}
            </div>
          </BottomWrapper>
        )}
      </Wrapper>
    </Modal>
  )
}

export default CustomModal

const Modal = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 5;
    top: 0;
    right: 0;
    display: flex;
    align-items: center; 
    justify-content: center;
    flex-direction: column;
    background-color: ${colors.transparentBackground};

    @media only screen and (max-width: 1500px) {
        padding: 5px; 
    }

    @media only screen and (max-width: 474px) {
        padding: 8px; 
    }

    @media only screen and (max-height: 655px) {
        padding: 8px; 
    }
`


const Wrapper = styled.div`
  position:relative;
  display: flex;
  flex-direction: column;
  width: ${({ size }: { size?: string }) => (size === 'large' ? '700px' : '450px')};

  @media only screen and (max-width: 474px) {
    width: 100%;
  }
  @media only screen and (max-height: 655px) {
    height: 100%;
    padding: 8px 0;
  }
  background-color: ${colors.white};
  border: 1px solid ${colors.light80};
  border-radius: 8px;
`

const Content = styled.div`
  padding: 20px 20px; 
  max-height: 600px;
  overflow-y: ${({ hasMember, overflow }: { hasMember?: boolean; overflow?: string }) =>
    overflow || hasMember ? 'initial' : 'auto'};
`
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${colors.light80};
  padding: 20px 10px 20px 20px;
  position: relative;
`

const HeaderText = styled(CustomText)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const TabWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  margin-bottom: -10px;
  padding-top: 20px;
`

const TabItemWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
  cursor: pointer;
`

const CloseButton = styled(CustomIcon)`
  position: absolute;
  right: 10px;
`

const BottomWrapper = styled.div`
  display: flex;
  justify-content: ${({ justify }: { justify: string }) => justify || 'flex-end'};
  column-gap: 16px;
  padding: 0px 20px 10px 20px;
`

export const ExtraButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`
