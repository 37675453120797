import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroll-component'

import { FixMeLater } from 'types'
import { IMessage } from 'types/conversation.types'

import Spinner from 'components/spinner/Spinner'
import NewMessage from 'components/new-message/NewMessage'
import Separator from 'components/separator/Separator'
import { v4 } from 'uuid'

interface ICustomConversationScrollProps {
  visibleOption?: boolean
  isOtr?: boolean
  myId: string
  data: IMessage[]
  hasMore: boolean
  scrollRef: FixMeLater
  dataLength: number
  draftData?: FixMeLater
  jumpToIndex?: number
  roomId: string
  onTogglePinMessage?: (messageId: string, pinned: boolean) => void
  onDeleteMessage?: (messageId: string) => void
  onReactionMessage?: (messageId: string, emoji: string, isAdd: boolean) => void
  onReplyMessage?: (visible: boolean, type: string, params?: { messageId: string }) => void
  onPagination: () => void
  onResetJumpToIndex?: (item: number) => void
  onScrollHandler?: () => void
}

const CustomConversationScroll = ({
  visibleOption,
  isOtr,
  myId,
  data,
  dataLength,
  hasMore,
  draftData,
  jumpToIndex,
  roomId,
  onPagination,
  onTogglePinMessage,
  onDeleteMessage,
  onReactionMessage,
  onReplyMessage,
  onResetJumpToIndex,
  onScrollHandler,
  scrollRef,
}: ICustomConversationScrollProps) => {
  const printedMessages: string[] = []
  return (
    <Wrapper id="scrollableDiv" ref={scrollRef} onScroll={onScrollHandler}>
      <InfiniteScroll
        dataLength={dataLength}
        hasMore={hasMore}
        inverse={true}
        next={onPagination}
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          flexGrow: 1,
          overflow: 'inherit',
        }}
        scrollableTarget="scrollableDiv"
        loader={<Spinner loading />}
        scrollThreshold={600}
      >
        {data.map((message: FixMeLater, index: number) => {
          let messageKey = message.cti || message._id || v4()

          if (message.separator) {
            messageKey = index + '_separator'
          }

          if (printedMessages.includes(messageKey)) {
            return null
          }

          printedMessages.push(messageKey)

          if (message.separator) {
            return (
              <Separator
                key={messageKey}
                description={message.description}
                margin={{ top: 20, bottom: 20 }}
              />
            )
          }

          return (
            <NewMessage
              key={messageKey}
              visibleOption={visibleOption}
              isOtr={isOtr}
              myId={myId}
              index={index}
              jumpToIndex={jumpToIndex}
              data={message}
              roomId={roomId}
              draftData={draftData}
              visibleThread={!message.isDeleted}
              onTogglePinMessage={onTogglePinMessage}
              onDeleteMessage={onDeleteMessage}
              onReactionMessage={onReactionMessage}
              onReplyMessage={onReplyMessage}
              onResetJumpToIndex={onResetJumpToIndex}
            />
          )
        })}
      </InfiniteScroll>
    </Wrapper>
  )
}

export default CustomConversationScroll

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column-reverse;
`
