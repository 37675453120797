import styled from 'styled-components'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { ICollapsibleSubMenu } from './SettingsSidePanel'

import CustomIcon, { IconSourceType } from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

interface ICollapsiblePanelMenuProps {
  onToggleConversationPanel: () => void
  url: string
  active: boolean
  title: string
  sub: ICollapsibleSubMenu[]
  icon: IconSourceType
}

const CollapsiblePanelMenu = ({
  onToggleConversationPanel,
  url,
  active,
  title,
  sub,
  icon,
}: ICollapsiblePanelMenuProps) => {
  const [open, setOPen] = useState(false)
  const navigate = useNavigate()
  const toggle = () => {
    setOPen(!open)
  }
  const navigateToKey = (url: string) => {
    navigate(url)
  }

  return (
    <ConteinerWrapper>
      <MainMenuWrapper
        onClick={() => {
          !sub && navigateToKey(url)
          toggle()
        }}
        active={!sub && active}
      >
        <LeftWrapper>
          <CustomIcon iconPreset="thin" source={icon} />
          <CustomText color={colors.white} typography="header3">
            {title}
          </CustomText>
        </LeftWrapper>
        {sub && (
          <CustomIcon
            iconPreset="small"
            source={!open ? icons.chevronDownWhiteIcon : icons.chevronUpWhiteIcon}
          />
        )}
      </MainMenuWrapper>
      <SubMenuContainer>
        {open &&
          sub &&
          sub.map((item: ICollapsibleSubMenu, index: number) => {
            return (
              <SubMenuWrapper
                onClick={() => {
                  navigateToKey(item.url)
                  onToggleConversationPanel()
                }}
                key={index}
              >
                <SubTitleText color={colors.white} active={item.active} typography="body8">
                  {item.title}
                </SubTitleText>
              </SubMenuWrapper>
            )
          })}
      </SubMenuContainer>
    </ConteinerWrapper>
  )
}
export default CollapsiblePanelMenu

const LeftWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-between;
`

const SubMenuWrapper = styled.div`
  z-index: 2;
`
const SubTitleText = styled(CustomText)`
  margin-top: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 42px;
  min-height: 30px;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? 'rgba(231, 245, 251, 0.35)' : colors.vime};

  &:hover {
    background-color: rgba(231, 245, 251, 0.2);
  }
`

const MainMenuWrapper = styled.div`
  height: 36px;
  padding-left: 16px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? 'rgba(231, 245, 251, 0.35)' : colors.vime};
`

const SubMenuContainer = styled.div`
  display: 'flex';
  flex-direction: 'column';
  gap: 8px;
`

const ConteinerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
`
