import { memo } from 'react'
import styled from 'styled-components'

import { icons } from 'utils/icons'

import CustomIcon from 'components/icon/CustomIcon'
import CustomButton from 'components/button/CustomButton'
import { useTranslation } from 'react-i18next'
import { BUTTONS } from 'locales/locales'
import { IFiles } from 'types/file.types'
import { FixMeLater } from 'types'
import { useAppSelector } from 'app/hooks'

interface IToolsProps {
  isValid: boolean
  isEdit: boolean
  queue?: Array<IFiles>
  getInputProps?: FixMeLater
  onVisibleEmojiPicker: (e: FixMeLater) => void
  onStartRecording: () => void
  onClickAttachment?: () => void
  onSaveEditMessage: () => void
  onCancelEditMessage?: () => void
  onSendMessage?: () => void
}


const Tools = ({
  isValid,
  isEdit,
  queue,
  getInputProps,
  onVisibleEmojiPicker,
  onStartRecording,
  onSaveEditMessage,
  onCancelEditMessage,
  onSendMessage,
}: IToolsProps) => {
  const { t } = useTranslation()
  const systemSettings = useAppSelector((state) => state.system.data)
  const isSendButtonEnabled = (isValid || (queue && queue.length > 0));
  return (
    <div>
      {isEdit && (
        <SpaceBetweenWrapper>
          <CustomIcon source={icons.emojiIcon} onClick={onVisibleEmojiPicker} />
          <ButtonWrapper>
            <CustomButton
              text={t(BUTTONS.CLOSE)}
              size="small"
              typography="body7"
              type="secondary"
              onClick={onCancelEditMessage}
            />
            <CustomButton
              text={t(BUTTONS.SAVE)}
              size="small"
              typography="body7"
              onClick={onSaveEditMessage}
            />
          </ButtonWrapper>
        </SpaceBetweenWrapper>
      )}
      {!isEdit && (
        <SpaceBetweenWrapper>
          <div>
            <CustomIcon source={icons.emojiIcon} onClick={onVisibleEmojiPicker} />
            <CustomIcon source={icons.microphoneIcon} onClick={onStartRecording} />
            <CustomIcon isFilePicker source={icons.attachmentIcon} getInputProps={getInputProps} />
          </div>
          <CustomIcon
            onClick={isSendButtonEnabled ? onSendMessage : undefined}
            source={isSendButtonEnabled ? icons.sendIconGreenActive : icons.sendIconWithoutBackground}
          />
        </SpaceBetweenWrapper>
      )}
    </div>
  )
}

export default memo(Tools)

const SpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 3;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`
