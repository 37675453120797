import { useNavigate } from 'react-router-dom'
import { useLazyGetDashboardStatisticsQuery } from 'app/services/dashboard.service'
import { icons } from 'utils/icons'

import DashboardCard from 'components/cards/DashboardCard'
import SettingsHeader from '../SettingsHeader'
import { BUTTONS, TITLES } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Loading from 'components/spinner/Loading'
import styled from 'styled-components'
import { utils, writeFile } from 'xlsx'
import CustomButton from 'components/button/CustomButton'
import { useAppSelector } from 'app/hooks'
import { IRolePermissions, SettingsPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'

type GetIconReturnType = {
  src: string
  srcset: string
}
interface IDataItem {
  title: string
  value: number
}
interface IData {
  key: string
  value: number
  description: string
  body: IDataItem[]
}

interface IDashboardData {
  key: string
  value: number
  description: string
  body: { title: string; value: string | number }[]
}

interface IDataToExport {
  key: string
  value: string | number
}

const formatDataToBeExported = (data: IDashboardData[]) => {
  return data.reduce((acc, stat) => {
    acc.push(
      { key: stat.description, value: stat.value },
      ...stat.body.map((s) => ({ key: s.title, value: s.value })),
    )
    return acc
  }, [] as { key: string; value: string | number }[])
}

const exportDataAs = (extension: string, data: IDataToExport[]) => () => {
  const worksheet = utils.json_to_sheet(data)
  const workbook = utils.book_new()
  utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  writeFile(workbook, `dashboard_report.${extension}`)
}

const Dashboard = () => {
  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions);
  const [dashboardData, setDashboardData] = useState([])
  const { t } = useTranslation()
  const [fetchDashboardData] = useLazyGetDashboardStatisticsQuery()
  const navigate = useNavigate()
  const canExportData = isFlagSetIn([SettingsPermissions.EXPORT_DASHBOARD], permissions.settings);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      Loading.ref.show()
      const data = await fetchDashboardData({})
      setDashboardData(data.data.response)
    } catch(e) {
//      console.warn('ERROR', e);
    } finally {
      Loading.ref.hide()
    }
  }

  const getIcons = (key: string): GetIconReturnType => {
    switch (key) {
      case 'groups':
        return icons.groupActiveIcon
      case 'channels':
        return icons.channelActiveIcon
      case 'users':
        return icons.userActiveIcon
      case 'video':
        return icons.videoCallActiveIcon
      case 'document':
        return icons.fileActiveIcon
      case 'message':
        return icons.messageActiveIcon
      default:
        return icons.dashboardIcon //! DEĞİŞECEK
    }
  }

  const navigateByRouteName = (key: string) => {
    if (key === 'users') return navigate('/system-settings/user-management')
    if (key === 'groups') return navigate('/system-settings/group-management')
    if (key === 'channels') return navigate('/system-settings/channel-management')
  }

  return (
    <Wrapper>
      <CustomSettingsHeader
        title={t(TITLES.DASHBOARD_TITLE)}
        content={
          <ExporterButtonsWrapper>
            <CustomButton
              disabled={!canExportData}
              text={t(BUTTONS.EXPORT_AS_CSV)}
              onClick={exportDataAs('csv', formatDataToBeExported(dashboardData))}
            />
            <CustomButton
              disabled={!canExportData}
              text={t(BUTTONS.EXPORT_AS_XLS)}
              onClick={exportDataAs('xls', formatDataToBeExported(dashboardData))}
            />
          </ExporterButtonsWrapper>
        }
      />
      <Content>
        {dashboardData?.map((cardItem: IData, index: number) => {
          return (
            <DashboardCard
              onSeeMore={() => navigateByRouteName(cardItem.key)}
              key={index}
              index={index}
              icon={getIcons(cardItem.key)}
              headerValue={cardItem.value}
              body={cardItem.body}
              isDisplay={
                cardItem.key == 'groups' || cardItem.key == 'channels' || cardItem.key == 'users'
                  ? true
                  : false
              }
            />
          )
        })}
      </Content>
    </Wrapper>
  )
}

export default Dashboard

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 30px;
  column-gap: 30px;
  padding-top: 68px;
  padding: 0px 30px;
`

const CustomSettingsHeader = styled(SettingsHeader)`
  padding-left: 30px;
`

const ExporterButtonsWrapper = styled.div`
  display: flex;
  column-gap: 20px;
  margin-left: auto;
  margin-right: 35px;
`
