import styled from 'styled-components'

import { icons } from 'utils/icons'
import { MOBILE_WIDTH } from 'utils/constants'
import colors from 'utils/colors'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
interface IConversationSidePanelProps {
  title: string
  content: React.ReactNode
  onClose: (visible: boolean) => void
  onToggleSearchInput?: (visible: boolean) => void
  onChangeSearch?: (text: string) => void
}

const ConversationSidePanel = ({
  title,
  content,
  onClose,
  onChangeSearch,
  onToggleSearchInput,
}: IConversationSidePanelProps) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <CustomText typography="header1">{title}</CustomText>
        <CustomIcon
          onClick={() => {
            onClose(false)
            onToggleSearchInput?.(false)
            onChangeSearch?.('')
          }}
          source={icons.closeIcon}
        />
      </HeaderWrapper>
      {content}
    </Wrapper>
  )
}

export default ConversationSidePanel

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 430px;
  flex-shrink: 0;
  justify-content: ${(props: { justify?: string }) => props.justify || 'flex-start'};
  transition: 'width 0.2s ease-out';
  border-left: 1px solid ${colors.light80};
  box-shadow: -6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px 0px 0px 20px;
  background-color: ${colors.light40};
  @media (max-width: ${MOBILE_WIDTH - 370}px) {
    width: 100%;
  }
`

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
`
