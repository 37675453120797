import { icons } from 'utils/icons'
import StatisticsCard from 'components/cards/StatisticsCard'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TITLES } from 'locales/locales'

interface IChannelStatistic {
  totalAudioCall: number
  totalFiles: number
  totalMessages: number
  totalUser: number
  totalVideoCall: number
}

const ChannelStatistic = ({
  totalAudioCall,
  totalFiles,
  totalMessages,
  totalUser,
  totalVideoCall,
}: IChannelStatistic) => {
  const { t } = useTranslation()
  return (
    <StatisticWrapper>
      <StatisticsCard
        description={t(TITLES.TOTAL_USER) as string}
        value={totalUser}
        icon={icons.userActiveIcon}
      />
      <StatisticsCard
        description={t(TITLES.TOTAL_VIDEO_CALL) as string}
        value={totalVideoCall}
        icon={icons.videoCallActiveIcon}
      />
      <StatisticsCard
        description={t(TITLES.TOTAL_AUDIO_CALL) as string}
        value={totalAudioCall}
        icon={icons.voiceActiveIcon}
      />
      <StatisticsCard
        description={t(TITLES.TOTAL_MESSAGES) as string}
        value={totalMessages}
        icon={icons.messageActiveIcon}
      />
      <StatisticsCard
        description={t(TITLES.TOTAL_FILES) as string}
        value={totalFiles}
        icon={icons.fileActiveIcon}
      />
    </StatisticWrapper>
  )
}

export default ChannelStatistic

const StatisticWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  row-gap: 16px;
`
