import styled from 'styled-components'

import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { POST } from 'locales/locales'
import { useGetReactionsOfPostQuery } from 'app/services/post.service'

import Spinner from 'components/spinner/Spinner'
import CustomText from 'components/text/CustomText'
import CustomModal from 'components/modals/CustomModal'
import { IconSourceType } from 'components/icon/CustomIcon'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'

interface IPostReactionsProps {
  postId: string
  onClose: () => void
}

const PostReactions = ({ postId, onClose }: IPostReactionsProps) => {
  const { t } = useTranslation()

  const { data = { likes: [], dislikes: [] }, isFetching } = useGetReactionsOfPostQuery(
    { postId },
    { refetchOnMountOrArgChange: true },
  )

  const tab: Array<{ title: string; action?: boolean; icon?: IconSourceType }> = [
    {
      title: `${t(POST.ALL)}  ${data.likes.length + data.dislikes.length || ''}`,
    },
  ]
  if (data.likes.length !== 0) {
    tab.push({ title: data.likes.length || '', icon: icons.likeCircleIcon })
  }
  if (data.dislikes.length !== 0) {
    tab.push({ title: data.dislikes.length || '', icon: icons.dislikeCircleIcon })
  }

  return (
    <CustomModal showButtons={false} closeModal={onClose} title={t(POST.REACTIONS)} tab={tab}>
      {isFetching && <Spinner loading />}
      {data && <Content data={[...data.likes, ...data.dislikes]} />}
      {data.likes.length !== 0 && <Content data={data.likes} />}
      {data.dislikes.length !== 0 && <Content data={data.dislikes} />}
    </CustomModal>
  )
}

export default PostReactions

const Content = ({ data }: { data: Array<{ _id: string; avatar: string; fullname: string }> }) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      {length !== 0 && (
        <TotalReactionText typography="header5" color={colors.gray80}>
          {length + ' ' + t(POST.PEOPLE)}
        </TotalReactionText>
      )}
      <ReactionList>
        {data?.map((user) => {
          return (
            <ReactionPeopleWrapper key={user._id}>
              <Avatar source={user?.avatar} text={user.fullname} size={AvatarSize.average} />
              <CustomText typography="header3">{user.fullname}</CustomText>
            </ReactionPeopleWrapper>
          )
        })}
      </ReactionList>
    </Wrapper>
  )
}

const TotalReactionText = styled(CustomText)`
  margin-bottom: 10px;
`

const ReactionList = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  row-gap: 10px;
`

const ReactionPeopleWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
`

const Wrapper = styled.div``
