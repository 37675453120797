import { memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { MESSAGES } from 'locales/locales'
import { FixMeLater } from 'types'

import CustomText from 'components/text/CustomText'

interface ITypingProps {
  data: FixMeLater[]
}

const Typing = ({ data }: ITypingProps) => {
  const { t } = useTranslation()

  const combinedTypers = () => {
    return data.map((user) => user).join(', ')
  }

  return (
    <TypingWrapper>
      <CustomText typography="body3"> {combinedTypers()}</CustomText>
      {data.length !== 0 && (
        <CustomText typography="body6" color={colors.gray60}>
          {data.length === 1 ? t(MESSAGES.IS_TYPING) : t(MESSAGES.ARE_TYPING)}
        </CustomText>
      )}
    </TypingWrapper>
  )
}

export default memo(Typing)

const TypingWrapper = styled.div.attrs((props: { typers: string[] }) => props)`
  display: flex;
  margin: 2px 0px;
  column-gap: 5px;
  height: 16px;
  align-items: flex-end;
  white-space: nowrap;
  overflow: hidden;
`
