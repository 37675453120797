import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { BUTTONS, MODALS, POST } from 'locales/locales'
import { INewsFeedSlice, newsFeedActions, setPostData } from 'app/slices/news-feed.slice'
import {
  useDeletePostMutation,
  useDislikePostMutation,
  useLazyGetJumpedCommentsQuery,
  useLazyGetCommentsOfPostQuery,
  useLazyGetPostByIdQuery,
  useLikePostMutation,
  useUnreadPostCommentNotificationMutation,
} from 'app/services/post.service'
import { FixMeLater } from 'types'
import { findLikeAndDislike } from 'utils/helpers/postHelper'
import styled from 'styled-components'
import colors from 'utils/colors'

import Separator from 'components/separator/Separator'
import Post from '../post/Post'
import PostReactions from '../_components/post-reactions/PostReactions'
import useInfoModal from 'utils/hooks/useInfoModal'
import Comments from '../post/comments/Comments'
import CommonInput from '../_components/common-input/CommonInput'
import EditPost from '../edit-post/EditPost'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import useCreatePostInput from 'utils/hooks/useCreatePostInput'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { useOutletNewsFeedState } from 'utils/hooks/useOutletState'
import Header from '../_components/header/Header'

const PostDetail = () => {
  const { postId = '' } = useParams()
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()
  const myId = useAppSelector((state) => state.auth.id)
  const authUser = useAppSelector((state) => state.auth.user)

  const [reactions, setReactions] = useState({ visible: false, postId: '' })
  const [readyToScrollComment, setReadyToScrollComment] = useState(true)
  const { isCommentLoading, commentInputData, commentPageIndex, isPostLoading }: INewsFeedSlice =
    useAppSelector((state) => state.newsFeed)

  const [comments, setComments] = useState<FixMeLater>([])
  const [likePost] = useLikePostMutation()
  const [dislikePost] = useDislikePostMutation()
  const [mentionsDropdown, setMentionsDropdown] = useState(false)
  const [pageNumber, setPageNumber] = useState(0)
  const [getPost] = useLazyGetPostByIdQuery()

  const [post, setPost] = useState<FixMeLater>({})

  const [getComments] = useLazyGetCommentsOfPostQuery()
  const [totalCommentCount, setTotalCommentCount] = useState(0)
  const [editPost, setEditPost] = useState<FixMeLater>({ visible: false, post: null })
  const [deletePost] = useDeletePostMutation()
  const dispatch = useAppDispatch()

  const {
    newsFeedJumpData,
    onResetNewsFeedJumpData,
    onJumpComment,
    onCloseNotification,
    onToggleNotification,
    openNotifications,
  } = useOutletNewsFeedState()

  const { openModal, closeModal, Modal } = useInfoModal()

  const { isValid, onNewCommentForPost, onSelectEmoji, onChangeInputData } = useCreatePostInput({
    inputData: commentInputData?.[postId],
    postId,
    setComments,
    setPost,
    setTotalCommentCount,
  })

  const [unreadComments] = useUnreadPostCommentNotificationMutation()

  const fetchData = async (isRefresh = false) => {
    try {
      const resultPost = await getPost({ postId })
      const resultComment = await getComments({ postId, page: pageNumber })

      setTotalCommentCount(resultComment.data.totalCount)

      if (!resultPost.isError) {
        setPost(setPostData({ ...resultPost.data }))
      }

      if (!resultComment.isError) {
        let commentsToSet = resultComment.data.data

        if (!isRefresh) {
          commentsToSet = [...comments, ...commentsToSet]
        }

        setComments(commentsToSet)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const fetchJumpedComments = async () => {
    const { data: result }: FixMeLater = await jumpToComment({
      postId: newsFeedJumpData.postId,
      commentId: newsFeedJumpData.commentId,
    })
    if (result) {
      const { page, data, totalCommentCount } = result
      setTotalCommentCount(totalCommentCount)
      setComments(data)
      setPageNumber(page)
      setReadyToScrollComment(true)
    }
  }

  const onShowReactions = (postId: string) => {
    setReactions({ visible: true, postId })
  }

  const onHideReactions = () => {
    setReactions({ visible: false, postId: '' })
  }

  const onConfirmDeletePost = async (postId: string) => {
    try {
      const result: FixMeLater = await deletePost({ postId })
      if (!result.error) {
        dispatch(newsFeedActions.deletePost({ postId }))
      }
    } finally {
      closeModal()
    }
  }

  const onShowEditPostModal = (postId?: string) => {
    if (postId) {
      setEditPost({ visible: true, post: comments.find((item: FixMeLater) => item._id === postId) })
      return
    }
    setEditPost({ visible: true, post })
  }

  const onDeletePost = (postId: string) => {
    openModal({
      title: t(MODALS.DELETE_POST_TITLE),
      description: t(MODALS.DELETE_POST_DESCRIPTION),
      okTitle: t(BUTTONS.DELETE) as string,
      onCancel: closeModal,
      onOk: () => onConfirmDeletePost(postId),
    })
  }

  const onHideEditPostModal = () => {
    setEditPost({ visible: false, post: null })
  }

  const onKeyUp = async (event: FixMeLater) => {
    event.preventDefault()
    if (
      event.key === 'Enter' &&
      isValid &&
      !event.shiftKey &&
      !mentionsDropdown &&
      !isCommentLoading
    ) {
      onNewComment()
    }
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  const onReactionPost = async (postId: string, isLike: boolean) => {
    if (isLike) {
      likePost({ postId, myId })
    } else {
      dislikePost({ postId, myId })
    }
    const { likeData, dislikeData } = findLikeAndDislike(isLike, post, myId)
    setPost({ ...post, likes: likeData, dislikes: dislikeData })
    dispatch(
      newsFeedActions.updateLikeCount({ type: isLike ? 'increase' : 'descrease', myId, postId }),
    )
  }

  const onConfirmDeleteComment = async (postId: string) => {
    try {
      const result: FixMeLater = await deletePost({ postId })
      if (!result.error) {
        const filteredComment = comments.filter((item: FixMeLater) => item._id !== postId)
        setComments(filteredComment)
        setTotalCommentCount((prev) => prev - 1)
      }
    } finally {
      closeModal()
    }
  }

  const onDeleteComment = (postId: string) => {
    openModal({
      title: t(MODALS.DELETE_COMMENT_TITLE),
      description: t(MODALS.DELETE_COMMENT_DESCRIPTION),
      okTitle: t(BUTTONS.DELETE) as string,
      onCancel: closeModal,
      onOk: () => onConfirmDeleteComment(postId),
    })
  }

  useEffect(() => {
    unreadComments({ postId })
  }, [newsFeedJumpData])

  const onNewComment = async () => {
    await onNewCommentForPost()
    await fetchData(true)
  }
  useEffect(() => {
    fetchData()
  }, [pageNumber])

  const [jumpToComment] = useLazyGetJumpedCommentsQuery()

  useEffect(() => {
    if (comments.length > 0 && newsFeedJumpData) {
      const existInMyComments = comments.find(
        (comment: FixMeLater) => comment._id === newsFeedJumpData.commentId,
      )
      setReadyToScrollComment(!!existInMyComments)
      if (!!existInMyComments === false) {
        fetchJumpedComments()
      }
    }
  }, [comments.length, newsFeedJumpData])

  useEffect(() => {
    setPageNumber(0)
    setComments([])
    fetchData(true)
  }, [postId])

  return (
    <Wrapper key={postId}>
      <Header
        backButton
        onJumpComment={onJumpComment}
        onCloseNotification={onCloseNotification}
        openNotifications={openNotifications}
        onToggleNotification={onToggleNotification}
      />
      {post && post.userData && (
        <PostWrapper className="post">
          <Post
            isPostDetail={true}
            postId={post?._id}
            data={post?.content}
            creatorInfo={post?.userData[post?.ownerId]}
            sentAt={post?.sentAt}
            status={post?.status}
            isPostLoading={isPostLoading}
            myId={myId}
            likes={post?.likes}
            dislikes={post?.dislikes}
            users={post?.userData}
            commentCount={totalCommentCount}
            commentToggle={post?.commentToggle ?? true}
            onDeletePost={onDeletePost}
            onReactionPost={onReactionPost}
            onShowReactions={onShowReactions}
            onShowEditPostModal={onShowEditPostModal}
          />
          <Separator margin={{ top: 10, bottom: 10 }} />
          {post.commentToggle && (
            <>
              <CommonInputWrapper>
                {screenWidth > MOBILE_DEVICE_WIDTH && (
                  <Avatar
                    source={authUser.avatar}
                    text={authUser.fullname}
                    size={AvatarSize.medium}
                    type="circle"
                  />
                )}
                <CommonInput
                  data={commentInputData[postId]}
                  id={'comment'}
                  isValid={isValid}
                  placeHolder={t(POST.CREATE_COMMENT)}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  onMentionDropDown={setMentionsDropdown}
                  onNewCommentForPost={onNewComment}
                  onChangeInputData={onChangeInputData}
                  onSelectEmoji={onSelectEmoji}
                />
              </CommonInputWrapper>
              <Comments
                myId={myId}
                postId={post?.postId}
                commentPageIndex={commentPageIndex}
                comments={comments}
                totalCommentCount={totalCommentCount}
                readyToScrollComment={readyToScrollComment}
                onDeleteComment={onDeleteComment}
                onPagenateComment={setPageNumber}
                onShowEditPostModal={onShowEditPostModal}
                newsFeedJumpData={newsFeedJumpData}
                onResetNewsFeedJumpData={onResetNewsFeedJumpData}
              />
            </>
          )}
        </PostWrapper>
      )}
      {editPost.visible && (
        <EditPost
          avatar={authUser?.avatar}
          fullname={authUser?.fullname}
          post={editPost?.post}
          userInfo={authUser}
          onClose={onHideEditPostModal}
          onEditComment={fetchData}
        />
      )}
      {Modal}
      {reactions.visible && <PostReactions postId={reactions.postId} onClose={onHideReactions} />}
    </Wrapper>
  )
}
export default PostDetail

const Wrapper = styled.div`
  overflow: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.light40};
  .post {
    width: 700px;
    @media only screen and (max-width: 800px) {
      width: 100%;
    }
  }
`

const PostWrapper = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.gray60};
  border-radius: 8px;
  padding: 20px;
  margin-top: 16px;
  @media only screen and (min-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 20px;
  }
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 12px;
  }
`

const CommonInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
  margin-bottom: 16px;
`
