import { useEffect, useState } from 'react'
import ChannelInfo from './ChannelInfoForm'
import CustomModal from 'components/modals/CustomModal'
import ChannelStatistic from './ChannelStatistic'
import Members from 'components/members/Members'
import {
  useAddChannelMemberMutation,
  useRemoveChannelMemberMutation,
} from 'app/services/group.service'
import { IChannelMember } from 'types/user.types'
import { FixMeLater } from 'types'
import useInfoModal from 'utils/hooks/useInfoModal'
import {
  useLazyGetRoomInfoForAdminQuery,
  useUpdateChannelMutation,
} from 'app/services/room.service'
import { useTranslation } from 'react-i18next'
import { BUTTONS, MODALS, PLACEHOLDERS, TABS } from 'locales/locales'
import { requiredValidation } from 'utils/helpers/validations'
import { Header } from 'features/channels/channel-info/ChannelInfoModal'
import { useAppSelector } from 'app/hooks'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
interface ICreateUserModalProps {
  roomId: string
  fetchChanels: () => void
  onClose: () => void
}

const ChannelInfoModal = ({ roomId, fetchChanels, onClose }: ICreateUserModalProps) => {
  const { t } = useTranslation()
  const { openModal, closeModal, Modal } = useInfoModal()

  const [updateChannel] = useUpdateChannelMutation()
  const [addChannelMember] = useAddChannelMemberMutation()
  const [removeChannelMember] = useRemoveChannelMemberMutation()
  const [getChannelInfoById, { data }] = useLazyGetRoomInfoForAdminQuery()

  const [formChecked, setFormChecked] = useState(false)
  const [generalInfo, setGeneralInfo] = useState({ ...data })
  const [members, setMembers] = useState<IChannelMember[]>([])
  const [statisticInfo, setStatisticInfo] = useState({ ...data })
  const [isLoading, setIsLoading] = useState(true)
  const permissions = useAppSelector<IRolePermissions>(state => state.auth.permissions);
  const isAdmin = isFlagSetIn([TeamAndChannelPermissions.UPDATE_CHANNEL], permissions.teamAndChannel);

  useEffect(() => {
    fetchChannelInfo()
  }, [])

  const fetchChannelInfo = async () => {
    try {
      const result: FixMeLater = await getChannelInfoById({ roomId: roomId })
      setGeneralInfo(result.data.team)
      setMembers(result.data.members)
      setStatisticInfo(result.data.statistic)
    } catch {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeInput = (value: string | boolean, key: string) => {
    setGeneralInfo({ ...generalInfo, [key]: value })
  }

  const onConfirmOk = async () => {
    setFormChecked(true)
    const { isValid } = requiredValidation(generalInfo.name)
    if (!isValid) {
      closeModal()
      return
    }
    try {
      setIsLoading(true)
      await updateChannel({
        channelId: generalInfo._id,
        params: {
          name: generalInfo.name,
          description: generalInfo.description,
          isPrivate: generalInfo.isPrivate,
        },
      })
      onClose()
      fetchChanels()
    } finally {
      setIsLoading(false)
    }
    closeModal()
    setFormChecked(false)
  }

  const onOk = async () => {
    openModal({
      title: t(MODALS.UPDATE_CHANNEL_CONFIRM_TITLE),
      description: t(MODALS.UPDATE_CHANNEL_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL),
      okTitle: t(BUTTONS.SAVE),
      onCancel: onConfirmCancel,
      onOk: onConfirmOk,
    })
  }
  const onConfirmCancel = () => {
    closeModal()
  }

  const onRemoveMember = async (user: IChannelMember) => {
    try {
      const result: FixMeLater = await removeChannelMember({ channelId: roomId, userId: user._id })
      if (result?.data?.success) {
        const removedUserFromMembersData = members.filter(
          (member: IChannelMember) => member._id !== user._id,
        )
        setMembers([...removedUserFromMembersData])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onAddMember = async (user: IChannelMember) => {
    try {
      await addChannelMember({ channelId: roomId, userId: user._id })
      setMembers([...members, user])
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <CustomModal
        closeModal={onClose}
        header={<Header name={generalInfo.name} />}
        onCancel={onClose}
        onOk={onOk}
        loading={isLoading}
        tab={[
          { title: t(TABS.GENERAL), action: true },
          { title: t(TABS.MEMBERS), action: false },
          { title: t(TABS.STATISTIC), action: false },
        ]}
        showButtons={false}
        hasMember
      >
        <ChannelInfo {...generalInfo} formChecked={formChecked} onChange={onChangeInput} />
        <Members
          data={members}
          isAdmin={isAdmin}
          isRemove={(data?.isModerator || isAdmin)}
          isAddMember={true}
          isUserCardDisplayable={true}
          placeholder={t(PLACEHOLDERS.MEMBERS_PLACEHOLDER)}
          onRemoveUser={onRemoveMember}
          onSelectMember={onAddMember}
        />
        <ChannelStatistic {...statisticInfo} />
      </CustomModal>
      {Modal}
    </>
  )
}

export default ChannelInfoModal
