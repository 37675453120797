import { getToken,isSupported, } from 'firebase/messaging'
import { IGroup } from 'types/group.types'
import { RoomData } from 'types/rooms.types'
import { PUBLIC_VAPID_KEY } from 'utils/constants'
import { messaging } from './firebaseConfig'
import { FixMeLater } from 'types'

const notificationSounds = {
  relax: '/notification-1.mp3',
  makeItPossible: '/notification-2.mp3',
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const registerWebPushServiceWorker = async () => {
  try {
    await navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
    await navigator.serviceWorker.ready
  } catch {
    console.error('Error while registering service worker...')
  }
}

const getTokenFromFirebase = async () => {
  try {
    await navigator.serviceWorker.ready
    if (await isSupported()) {
      const fcmToken = await getToken(messaging, { vapidKey: PUBLIC_VAPID_KEY })
      if (fcmToken) {
      return fcmToken
      } else {
        console.log('No registration token available. Request permission to generate one.')
      }
    } else {
      console.error("This browser doesn't supporting FCM.")
    }
  } catch (e) {
    console.error('Error while registering service worker...',)
  }
}

const subscribeToPushService = async () => {
  try {
    const registration = await navigator.serviceWorker.ready
    const existingSubscription = await registration.pushManager?.getSubscription()
    // Unsubscribe first if there is already an existing subscription.
    if (existingSubscription) {
      await existingSubscription.unsubscribe()
    }
    // const newSubscription = await registration.pushManager.subscribe(subscriptionOptions)
    // const fcmToken = await getTokenFromFirebase()
    // await axiosApi.put('api/user/fcm', { fcmToken: fcmToken })
    // await axiosApi.put('api/public/notification/subscribe', { subscription: newSubscription })
    console.log('Subscribed to push service...')
  } catch (error) {
    console.log('Error while subscribing to push service...')
  }
}

const unsubscribeFromPushService = async () => {
  try {
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager?.getSubscription()
    if (!subscription) {
      return console.log('There is no subscription exists...')
    }
    const isUnsubbed = await subscription.unsubscribe()
    if (!isUnsubbed) {
      return console.error('Error occurred while unsubscribing from push service...')
    }
    console.log('Unsubscribed from push service...')
  } catch (error) {
    console.error('Error occurred while unsubscribing from push service...', error)
  }
}

export default {
  registerWebPushServiceWorker,
  getTokenFromFirebase,
  subscribeToPushService,
  unsubscribeFromPushService,
}

export const playNotificationSound = () => {
  const audio = new Audio(notificationSounds.makeItPossible)
  audio.volume = 0.1
  audio.play()
}

export const getNotificationBadgeForTeams = (groups: IGroup[]) => {
  return groups.some((group) => group?.channels?.some((channel) => channel?.notificationCount))
}

export const getNotificationBadgeForMessages = (rooms: RoomData) => {
  return rooms.some((room) => room?.notificationCount ?? 0)
}
