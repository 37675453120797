import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'

import { AvatarSize } from 'components/avatar/Avatar'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { BUTTONS, FORM, PLACEHOLDERS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import UploadImage from 'components/input/uplaod-input/UploadImage'
import { phoneNumberValidation, requiredValidation } from 'utils/helpers/validations'
import styled from 'styled-components'
import { useAppSelector } from 'app/hooks'

interface IUserInfoProps {
  avatar: string
  fullname: string
  email: string
  title: string
  team?: string
  phoneNumber?: string
  formChecked: boolean
  isLdapUser: boolean
  onChange: (name: string, value: string) => void
  onClickChangePassword: () => void
  onUploadPhoto: (fileUrl: string) => void
}

const UserInfo = ({
  avatar,
  fullname,
  title,
  team,
  phoneNumber,
  email,
  formChecked,
  isLdapUser,
  onChange,
  onUploadPhoto,
  onClickChangePassword,
}: IUserInfoProps) => {
  const { t } = useTranslation()
  const isValidPhoneNumber =
    formChecked && phoneNumber && phoneNumberValidation(phoneNumber).errorText
  const isValidName = formChecked && requiredValidation(fullname, t(FORM.FULLNAME)).errorText
  const systemSettings = useAppSelector((state) => state.system.data)
  
  return (
    <AccountSettingsWrapper>
      <UploadImage
        avatar={avatar}
        text={fullname}
        buttonText={BUTTONS.UPLOAD}
        avatarSize={AvatarSize.XXL}
        onUploadPhoto={onUploadPhoto}
      />
      <FormWrapper>
        <CustomFormInput
          value={fullname}
          title={t(FORM.FULLNAME) as string}
          placeholder={t(PLACEHOLDERS.FULLNAME) as string}
          disabled={isLdapUser}
          errorText={isValidName}
          onChange={(value: string) => onChange('fullname', value)}
        />
        <CustomFormInput
          value={title}
          title={t(FORM.TITLE) as string}
          placeholder={t(PLACEHOLDERS.TITLE) as string}
          disabled
          onChange={(value: string) => onChange('title', value)}
        />
        <CustomFormInput
          value={team}
          title={t(FORM.TEAM) as string}
          disabled
          onChange={(value: string) => onChange('teamId', value)}
        />
        <CustomFormInput
          value={email}
          title={t(FORM.EMAIL) as string}
          placeholder={t(PLACEHOLDERS.EMAIL) as string}
          disabled
          onChange={(value: string) => onChange('email', value)}
        />
        <CustomFormInput
          optionalTitle={t(FORM.OPTIONAL) as string}
          value={phoneNumber}
          title={t(FORM.PHONE_NUMBER) as string}
          placeholder={t(PLACEHOLDERS.PHONE_NUMBER) as string}
          disabled={isLdapUser}
          onChange={(value: string) => onChange('phoneNumber', value)}
          errorText={isValidPhoneNumber}
        />
       {!isLdapUser && systemSettings?.features?.passwordChangeEnabled && (
          <CustomText
            typography="body4"
            color={colors.vime}
            cursor="pointer"
            onClick={onClickChangePassword}
          >
            {t(BUTTONS.CHANGE_PASSWORD)}
          </CustomText>
        )}
      </FormWrapper>
    </AccountSettingsWrapper>
  )
}

export default UserInfo

const AccountSettingsWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`
