import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import styled from 'styled-components';
import { BUTTONS } from 'locales/locales'

interface TagData {
  title: string;
  value?: string;
}

interface CollapsibleTagsProps {
  data: TagData[];
  title: string;
  maxItemsToShow: number;
}

const CollapsibleTags: React.FC<CollapsibleTagsProps> = ({ data, title, maxItemsToShow }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);
  const [showAll, setShowAll] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };

  return (
    <StyledPanelBar onSelect={toggleExpansion} expandMode="single">
      <PanelBarItem title={title} expanded={expanded}>
        <TagsContainer>
          {data.slice(0, showAll ? data.length : maxItemsToShow).map((item, index) => (
            <Tag key={item.value || index}>{item.title}</Tag>
          ))}
        </TagsContainer>
        {data.length > maxItemsToShow && (
          <ShowMoreButton onClick={() => setShowAll(!showAll)}>
            {showAll ? t(BUTTONS.SHOW_LESS) : t(BUTTONS.SHOW_MORE)}
          </ShowMoreButton>
        )}
      </PanelBarItem>
    </StyledPanelBar>
  );
};

export default CollapsibleTags;

const StyledPanelBar = styled(PanelBar)`
  .k-panelbar {
    width: 100%;
    .k-link {
      cursor: pointer;
    }
  }
`;

const TagsContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const Tag = styled.div`
  border: 1px solid rgb(227 227 227);
  background-color: white;
  color: black;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: rgb(0 0 0 / 4%) 0px 5px 9px;
  font-weight: 500;
`;

const ShowMoreButton = styled.button`
  margin: 10px;
  padding: 8px 16px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight:600;
  font-size: 12px;
`;
