import api from 'app/api'

export const userService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      createUser: build.mutation({
        query: (args) => ({
          url: `user`,
          method: 'POST',
          body: args,
        }),
      }),
      getUserById: build.query({
        query: ({ id: userId }) => ({
          url: `user/${userId}`,
        }),
      }),
      getMyUser: build.query({
        query: () => ({
          url: `user/my/user`,
        }),
      }),
      updateUserById: build.mutation({
        query: ({ _id: userId, params }) => ({
          url: `user/${userId}`,
          method: 'PUT',
          body: params,
        }),
        invalidatesTags: ['UPDATE_USER_BY_ID'],
      }),

      getUsersByFullname: build.query({
        query: ({ searchText }) => ({ url: `user/list/search?searchText=${searchText}` }),
      }),
      
      
      getPaginatedUsers: build.query({
        query: ({ search, limit, sort, pageNumber, column, filter }) => ({
          url: `user/list/filter?searchText=${search}&limit=${limit}&sort=${sort}&page=${pageNumber}&column=${column}&filter=${filter}`,
        }),
      }),
      deleteUser: build.mutation({
        query: (args) => ({
          url: `user/delete`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['DELETE_USER'],
      }),
      updateUser: build.mutation({
        query: (args) => ({
          url: `user/account/settings`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_USER'],
      }),
      updateUserPassword: build.mutation({
        query: (args) => ({
          url: `user/password`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_USER'],
      }),
      updateUserStatus: build.mutation({
        query: (args) => ({
          url: `user/status`,
          method: 'PUT',
          body: args,
        }),
      }),
      updateUserLanguage: build.mutation({
        query: (args) => ({
          url: `user/language?lang=${args.lang}`,
          method: 'PUT',
        }),
      }),
      updateUserArchive: build.mutation({
        query: ({ userId, isDeleted }) => ({
          url: `/user/${userId}/archive`,
          method: 'PUT',
          body: { isDeleted },
        }),
        invalidatesTags: ['UPDATE_USER_ARCHIVE'],
      }),
      updateUserFcm: build.mutation({
        query: ({ fcmToken }) => ({
          url: `/user/fcm`,
          method: 'PUT',
          body: { fcmToken },
        }),
        invalidatesTags: ['UPDATE_USER_FCM_TOKEN'],
      }),
    }
  },
})

export const {
  useUpdateUserByIdMutation,
  useCreateUserMutation,
  useLazyGetMyUserQuery,
  useLazyGetUserByIdQuery,
  useLazyGetUsersByFullnameQuery,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useUpdateUserPasswordMutation,
  useUpdateUserStatusMutation,
  useLazyGetPaginatedUsersQuery,
  useUpdateUserLanguageMutation,
  useUpdateUserArchiveMutation,
  useUpdateUserFcmMutation
} = userService
