import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDtxJO12gL-dhJXy1vip6j6CwQyV0lGdmE",
  authDomain: "space-a545a.firebaseapp.com",
  projectId: "space-a545a",
  storageBucket: "space-a545a.appspot.com",
  messagingSenderId: "489243830500",
  appId: "1:489243830500:web:92308b03710e83138724f1"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging, onMessage};