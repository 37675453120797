import styled from 'styled-components'
import { createRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { BUTTONS, FORM, TITLES } from 'locales/locales'

import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

interface ICustomModalProps {
  visible?: boolean
  closeModal: FixMeLater
  value: string
  errorText?: string | null
  onChangeValue: FixMeLater
  onSubmit: FixMeLater
}

const ForgotPasswordModal = ({
  visible,
  closeModal = () => null,
  value,
  errorText,
  onChangeValue,
  onSubmit,
}: ICustomModalProps) => {
  const { t } = useTranslation()
  const modalRef = createRef<HTMLInputElement>()

  useEffect(() => {
    if (modalRef?.current) {
      modalRef?.current?.focus()
    }
  }, [visible])

  const handleEscape = (event: { code: string }) => {
    if (event.code === 'Escape') {
      closeModal()
    }
  }

  if (!visible) return null
  return (
    visible && (
      <ModalContainer onKeyDown={handleEscape} ref={modalRef}>
        <ModalWrapper>
          <HeadWrapper>
            <CustomText typography="header1">{t(TITLES.FORGOT_PASSWORD)}</CustomText>
            <CustomText textAlign="center" color={colors.gray80} typography="body2">
              {t(FORM.FORGOT_PASSWORD_DESCRIPTION)}
            </CustomText>
          </HeadWrapper>
          <BottomWrapper>
            <Form onSubmit={onSubmit}>
              <CustomFormInput
                title="Email"
                placeholder="Email"
                value={value}
                preset="medium"
                onChange={onChangeValue}
                errorText={errorText}
              />
              <CustomButton typography="header1" size="large" text={t(BUTTONS.RESET_PASSWORD)} />
            </Form>
            <CustomText
              onClick={closeModal}
              cursor="pointer"
              color={colors.vime}
              typography="body4"
            >
              {t(BUTTONS.BACK_TO_SIGN_IN)}
            </CustomText>
          </BottomWrapper>
        </ModalWrapper>
      </ModalContainer>
    )
  )
}

export default ForgotPasswordModal

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.transparentBackground};
`
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 326px;
  align-items: center;
  gap: 16px;
  margin-bottom: 60px;
`

const BottomWrapper = styled.div`
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 26px;
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  width: 764px;
  height: 580px;
  background-color: ${colors.white};
`
const Form = styled.form`
  width: 372px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
