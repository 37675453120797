import { useState } from 'react'

import InfoModal from 'components/info-modal/InfoModal'

export interface ModalState {
  title: string
  description: string
  cancelTitle?: string
  okTitle?: string
  visible?: boolean
  onCancel?: () => void
  onOk?: () => void
}

const initialState: ModalState = {
  title: '',
  description: '',
  cancelTitle: '',
  okTitle: '',
  visible: false,
}

const useInfoModal = () => {
  const [modalState, setModalState] = useState<ModalState>(initialState)

  const openModal = (modalProps: ModalState) => {
    setModalState({ ...modalProps, visible: true })
  }

  const closeModal = () => setModalState(initialState)

  return {
    openModal,
    closeModal,
    Modal: InfoModal({ ...modalState, closeModal, visible: modalState.visible as boolean }),
  }
}

export default useInfoModal
