import styled from 'styled-components'

import colors from 'utils/colors'
import useHandleFileQueue from 'utils/hooks/useHandleFileQueue'

import CustomText from 'components/text/CustomText'

type Type = {
  progress: number
  bgcolor: string
}

const ProgressBar = () => {
  const { progress, fileName } = useHandleFileQueue()

  if (progress === null) {
    return null
  }
  return (
    <Wrapper>
      <Container>
        <Filler progress={progress} bgcolor={colors.mainVimeMid}>
          <Title>
            <StyledText typography="header2" color="white">
              {progress === 100 ? 'Processing file...' : `Uploading ${fileName}`}
            </StyledText>
          </Title>
          <Label>
            <CustomText typography="header2" color="white">
              {`%${progress}`}
            </CustomText>
          </Label>
        </Filler>
      </Container>
    </Wrapper>
  )
}

export default ProgressBar

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 4;
  left: 0px;
  top: 100px;
  right: 0px;
`

const Container = styled.div`
  z-index: 4;
  position: absolute;
  height: 40px;
  width: 384px;
  background-color: ${colors.mainVime};
  border-radius: 16px;
`

const Filler = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  transition: 'width 500ms ease-in-out';
  width: ${(props: Type) => props.progress}%;
  background-color: ${(props: Type) => props.bgcolor};
  border-radius: 16px;
  text-align: 'right';
`

const Label = styled.div`
  position: absolute;
  z-index: 4;
  right: 16px;
`

const Title = styled.div`
  position: absolute;
  z-index: 4;
  left: 16px;
`

const StyledText = styled(CustomText)`
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
