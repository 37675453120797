export const BUTTONS = {
  RESEND_INVITE_DESCRIPTION: 'RESEND_INVITE_DESCRIPTION',
  CANCEL: 'CANCEL',
  REMOVE: 'REMOVE',
  LEAVE: 'LEAVE',
  DELETE: 'DELETE',
  EDIT: 'EDIT',
  SAVE: 'SAVE',
  UPDATE: 'UPDATE',
  CLOSE: 'CLOSE',
  CREATE: 'CREATE',
  RETRY: 'RETRY',
  DISAPPEAR: 'DISAPPEAR',
  APPEAR: 'APPEAR',
  PRIVATE: 'PRIVATE',
  NOTIFICATIONS: 'NOTIFICATIONS',
  MENTIONS_ONLY: 'MENTIONS_ONLY',
  ARCHIVE: 'ARCHIVE',
  UNARCHIVE: 'UNARCHIVE',
  DISAPPEAR_CONVERSATION: 'DISAPPEAR_CONVERSATION',
  APPEAR_CONVERSATION: 'APPEAR_CONVERSATION',
  APPEAR_CHANNEL: 'APPEAR_CHANNEL',
  DISAPPEAR_CHANNEL: 'DISAPPEAR_CHANNEL',
  LEAVE_CHANNEL: 'LEAVE_CHANNEL',
  LEAVE_GROUP: 'LEAVE_GROUP',
  JOIN: 'JOIN',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CREATE_TEAM: 'CREATE_TEAM',
  CREATE_USER: 'CREATE_USER',
  CREATE_MEETING: 'CREATE_MEETING',
  SEE_MORE: 'SEE_MORE',
  PARTICIPANT: 'PARTICIPANT',
  CLEAR_ALL: 'CLEAR_ALL',
  SHOW_LESS: 'SHOW_LESS',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
  UPLOAD: 'UPLOAD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  BACK_TO_SIGN_IN: 'BACK_TO_SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  SIGN_IN: 'SIGN_IN',
  HAVE_ACCOUNT: 'HAVE_ACCOUNT',
  YES: 'YES',
  INVITE: 'INVITE',
  RESEND_INVITE: 'RESEND_INVITE',
  SHOW_MORE: 'SHOW_MORE',
  CHANNEL_ACTIVE: 'CHANNEL_ACTIVE',
  CHANNEL_DEACTIVE: 'CHANNEL_DEACTIVE',
  SEARCH: 'SEARCH',
  VIDEO_CALL: 'VIDEO_CALL',
  AUDIO_CALL: 'AUDIO_CALL',
  MESSAGE_VIDEO_CALL: 'MESSAGE_VIDEO_CALL',
  MESSAGE_AUDIO_CALL: 'MESSAGE_AUDIO_CALL',
  OTR_CALL: 'OTR_CALL',
  CLOSE_OTR: 'CLOSE_OTR',
  DETAILS: 'DETAILS',
  LINK: 'LINK',
  EXPORT_AS_CSV: 'EXPORT_AS_CSV',
  EXPORT_AS_XLS: 'EXPORT_AS_XLS',
  ADD_NEW_ROLE: 'ADD_NEW_ROLE',
  USERS: 'USERS',
  PERMISSIONS: 'PERMISSIONS',
  MESSAGING: 'MESSAGING',
}
export const MODALS = {
  LEAVE_OTR_TITLE: 'LEAVE_OTR_TITLE',
  LEAVE_OTR_DESCRIPTION: 'LEAVE_OTR_DESCRIPTION',
  LEAVE_CHANNEL_TITLE: 'LEAVE_CHANNEL_TITLE',
  LEAVE_CHANNEL_DESCRIPTION: 'LEAVE_CHANNEL_DESCRIPTION',
  DISAPPEAR_TITLE: 'DISAPPEAR_TITLE',
  DISAPPEAR_DESCRIPTION: 'DISAPPEAR_DESCRIPTION',
  DISAPPEAR_CHANNEL_DESCRIPTION: 'DISAPPEAR_CHANNEL_DESCRIPTION',
  APPEAR_TITLE: 'APPEAR_TITLE',
  APPEAR_DESCRIPTION: 'APPEAR_DESCRIPTION',
  APPEAR_CHANNEL_DESCRIPTION: 'APPEAR_CHANNEL_DESCRIPTION',
  ARCHIVE_TITLE: 'ARCHIVE_TITLE',
  ARCHIVE_DESCRIPTION: 'ARCHIVE_DESCRIPTION',
  UNARCHIVE_TITLE: 'UNARCHIVE_TITLE',
  UNARCHIVE_DESCRIPTION: 'UNARCHIVE_DESCRIPTION',
  NEW_CONVERSATION_TITLE: 'NEW_CONVERSATION_TITLE',
  DELETE_MESSAGE_TITLE: 'DELETE_MESSAGE_TITLE',
  DELETE_MESSAGE_DESCRIPTION: 'DELETE_MESSAGE_DESCRIPTION',
  UNARCHIVE_TEAM_DESCRIPTION: 'UNARCHIVE_TEAM_DESCRIPTION',
  ARCHIVE_TEAM_DESCRIPTION: 'ARCHIVE_TEAM_DESCRIPTION',
  OTR_REQUEST_TITLE: 'OTR_REQUEST_TITLE',
  CLOSE_OTR_TITLE: 'CLOSE_OTR_TITLE',
  CLOSE_OTR_DESCRIPTION: 'CLOSE_OTR_DESCRIPTION',
  CREATE_TEAM_TITLE: 'CREATE_TEAM_TITLE',
  CREATE_USER_TITLE: 'CREATE_USER_TITLE',
  CREATE_MEETING_TITLE: 'CREATE_MEETING_TITLE',
  MEETING_INFO_TITLE: 'MEETING_INFO_TITLE',
  USER_INFO_TITLE: 'USER_INFO_TITLE',
  UPDATE_USER_TITLE: 'UPDATE_USER_TITLE',
  UPDATE_USER_DESCRIPTION: 'UPDATE_USER_DESCRIPTION',
  SWITCH_USER_TITLE: 'SWITCH_USER_TITLE',
  SWITCH_USER_DESCRPTION: 'SWITCH_USER_DESCRPTION',
  ACTIVE_USER_TITLE: 'ACTIVE_USER_TITLE',
  ACTIVE_USER_DESCRIPTION: 'ACTIVE_USER_DESCRIPTION',
  INACTIVE_USER_TITLE: 'INACTIVE_USER_TITLE',
  INACTIVE_USER_DESCRIPTION: 'INACTIVE_USER_DESCRIPTION',
  UPDATE_CHANNEL_CONFIRM_TITLE: 'UPDATE_CHANNEL_CONFIRM_TITLE',
  UPDATE_CHANNEL_DESCRIPTION: 'UPDATE_CHANNEL_DESCRIPTION',
  UPDATE_TEAM_CONFIRM_TITLE: 'UPDATE_TEAM_CONFIRM_TITLE',
  UPDATE_TEAM_DESCRIPTION: 'UPDATE_TEAM_DESCRIPTION',
  CREATE_NEW_CHANNEL_TITLE: 'CREATE_NEW_CHANNEL_TITLE',
  DELETE_POST_TITLE: 'DELETE_POST_TITLE',
  DELETE_COMMENT_TITLE: 'DELETE_COMMENT_TITLE',
  DELETE_POST_DESCRIPTION: 'DELETE_POST_DESCRIPTION',
  DELETE_COMMENT_DESCRIPTION: 'DELETE_COMMENT_DESCRIPTION',
  CREATE_VIDEO_CONFERENCE_TITLE: 'CREATE_VIDEO_CONFERENCE_TITLE',
  CREATE_VIDEO_CONFERENCE_DESCRIPTION: 'CREATE_VIDEO_CONFERENCE_DESCRIPTION',
  CREATE_AUDIO_CONFERENCE_TITLE: 'CREATE_AUDIO_CONFERENCE_TITLE',
  CREATE_AUDIO_CONFERENCE_DESCRIPTION: 'CREATE_AUDIO_CONFERENCE_DESCRIPTION',
  DELETE_CONFERANCE_TITLE: 'DELETE_CONFERANCE_TITLE',
  DELETE_CONFERANCE_DESCRIPTION: 'DELETE_CONFERANCE_DESCRIPTION',
  START_OTR_TITLE: 'START_OTR_TITLE',
  START_OTR_DESCRIPTION: 'START_OTR_DESCRIPTION',
  NOTIFICATION: 'NOTIFICATION',
}

export const ERRORS = {
  CHECK_LDAP_INPUTS: 'CHECK_LDAP_INPUTS',
  CHECK_JSON_FILE: 'CHECK_JSON_FILE',
  INVALID_JSON: 'INVALID_JSON',
  VALID_JSON: 'VALID_JSON',
  NO_CHANNELS_FOUND: 'NO_CHANNELS_FOUND',
  CREATE_LDAP_ERROR: 'CREATE_LDAP_ERROR',
  MEMBER_LIMIT_EXCEEDED: 'MEMBER_LIMIT_EXCEEDED',
  SHORT_TEAM_NAME: 'SHORT_TEAM_NAME',
  INVALID_PASSWORD: 'INVALID_PASSWORD',
  INVALID_FULLNAME: 'INVALID_FULLNAME',
  OTR_REJECTED_ERROR: 'OTR_REJECTED_ERROR',
  OTR_EXPIRE_ERROR: 'OTR_EXPIRE_ERROR',
  INPUT_REQUIRED: 'INPUT_REQUIRED',
  EMAIL_EMPTY: 'EMAIL_EMPTY',
  EMAIL_INVALID: 'EMAIL_INVALID',
  PASSWORD_EMPTY: 'PASSWORD_EMPTY',
  PASSWORD_DONT_MATCH: 'PASSWORD_DONT_MATCH',
  PASSWORD_NEEDS: 'PASSWORD_NEEDS',
  PASSWORD_CHAR_LONG: 'PASSWORD_CHAR_LONG',
  PASSWORD_UNIQUE_LEAST: 'PASSWORD_UNIQUE_LEAST',
  PASSWORD_UPPER_CASE: 'PASSWORD_UPPER_CASE',
  PASSWORD_LOWER_CASE: 'PASSWORD_LOWER_CASE',
  PASSWORD_LETTER: 'PASSWORD_LETTER',
  PASSWORD_NUMBER: 'PASSWORD_NUMBER',
  PASSWORD_END: 'PASSWORD_END',
  INVALID_PASSWORD_SETTINGS: 'INVALID_PASSWORD_SETTINGS',
  PHONE_NUMBER_ERROR: 'PHONE_NUMBER_ERROR',
  THERE_WAS_NO_CHANGES: 'THERE_WAS_NO_CHANGES',
  ALREADY_EXIST_CONVERSATION: 'ALREADY_EXIST_CONVERSATION',
  SOMETHING_WENT_WRONG_ERROR: 'SOMETHING_WENT_WRONG_ERROR',
  ACCOUNT_DEACTIVE: 'ACCOUNT_DEACTIVE',
  FILE_SIZE_ERROR: 'FILE_SIZE_ERROR',
  UPLOAD_IMAGE_ERROR: 'UPLOAD_IMAGE_ERROR',
  UPLOAD_FOLDER: 'UPLOAD_FOLDER',
  FILE_COUNT_ERROR: 'FILE_COUNT_ERROR',
  CANNOT_MAKE_PRIVATE: 'CANNOT_MAKE_PRIVATE',
  DONT_HAVE_ACCESS: 'DONT_HAVE_ACCESS',
  DISCONNECTED_SOCKET: 'DISCONNECTED_SOCKET',
}

export const PLACEHOLDERS = {
  TEAM_PLACEHOLDER: 'TEAM_PLACEHOLDER',
  REPLY_PLACEHOLDER: 'REPLY_PLACEHOLDER',
  MESSAGE_PLACEHOLDER: 'MESSAGE_PLACEHOLDER',
  CHANNEL_NAME_PLACEHOLDER: 'CHANNEL_NAME_PLACEHOLDER',
  CHANNEL_DESCRIPTION_PLACEHOLDER: 'CHANNEL_DESCRIPTION_PLACEHOLDER',
  SEARCH_FILES_PLACEHOLDER: 'SEARCH_FILES_PLACEHOLDER',
  SEARCH_PLACEHOLDER: 'SEARCH_PLACEHOLDER',
  MEMBERS_PLACEHOLDER: 'MEMBERS_PLACEHOLDER',
  FULLNAME: 'FULLNAME',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  DEPARTMENT: 'DEPARTMENT',
  TITLE: 'TITLE',
  ISVERIFY:'ISVERIFY',
  PHONE_NUMBER: 'PHONE_NUMBER',
  SYSTEM_NAME: 'SYSTEM_NAME',
  TEAM_NAME: 'TEAM_NAME',
  TEAM_DESCRIPTION: 'TEAM_DESCRIPTION',
  CHANNEL_NAME: 'CHANNEL_NAME',
  CHANNEL_DESCRIPTION: 'CHANNEL_DESCRIPTION',
  MAIL_HOST: 'HOSTNAME',
  MAIL_PORT: 'PORT',
  MAIL_FROM: 'FROM',
  MAIL_FROM_NAME: 'FROM_NAME',
  MAIL_API_USERNAME: 'EMAIL_API_USERNAME',
  CREATE_A_COMMENT: 'CREATE_A_COMMENT',
  ADD_NEW_LINE: 'ADD_NEW_LINE',
  MEETING_NAME: 'MEETING_NAME',
  MEETING_DESCRIPTION: 'MEETING_DESCRIPTION',
  MEETING_TIME: 'MEETING_TIME',
  MEETING_DURATION: 'MEETING_DURATION',
  MEETING_GROUP: 'MEETING_GROUP',
  MEETING_SINGLE_PARTICIPANT: 'MEETING_SINGLE_PARTICIPANT',
  CHECK_LDAP_JSON_INPUTS: 'CHECK_LDAP_JSON_INPUTS',
}

export const MESSAGES = {
  YOU: 'YOU',
  IS_TYPING: 'IS_TYPING',
  ARE_TYPING: 'ARE_TYPING',
  DEACTIVE: 'DEACTIVE',
  CREATED_BY: 'CREATED_BY',
  OTR_CREATED: 'OTR_CREATED',
  OTR_JOINED: 'OTR_JOINED',
  OTR_LEFT: 'OTR_LEFT',
  NO_RESULTS_FOR: 'NO_RESULTS_FOR',
  CHECK_SPELLING: 'CHECK_SPELLING',
  ARCHIVED_CHANNEL_DESCRIPTION: 'ARCHIVED_CHANNEL_DESCRIPTION',
  DISAPPEARED_CHANNEL_DESCRIPTION: 'DISAPPEARED_CHANNEL_DESCRIPTION',
  DISAPPEARED_CONVERSATION_DESCRIPTION: 'DISAPPEARED_CONVERSATION_DESCRIPTION',
  READ_ONLY_DESCRIPTION: 'READ_ONLY_DESCRIPTION',
  DISABLED_USER_DESCRIPTION: 'DISABLED_USER_DESCRIPTION',
  JOIN_CHANNEL_DESCRIPTION: 'JOIN_CHANNEL_DESCRIPTION',
  DISABLE_OTR_DESCRIPTION: 'DISABLE_OTR_DESCRIPTION',
  DELETED_MESSAGE: 'DELETED_MESSAGE',
  EDITED_MESSAGE: 'EDITED_MESSAGE',
  VIEW_THREAD: 'VIEW_THREAD',
  REPLIES: 'REPLIES',
  REPLY: 'REPLY',
  DRAFT: 'DRAFT',
  JUMP_NEW_MESSAGES: 'JUMP_NEW_MESSAGES',
  NEW_MESSAGES: 'NEW_MESSAGES',
  TODAY: 'TODAY',
  ARCHIVED: 'ARCHIVED',
  ALREADY_ADDED: 'ALREADY_ADDED',
  UPLOAD_FILE: 'UPLOAD_FILE',
  CHANNEL_HEADER_CHANGE: 'CHANNEL_HEADER_CHANGE',
  CHANNEL_PRIVACY_CHANGE: 'CHANNEL_PRIVACY_CHANGE',
  AND: 'AND',
  OTHER_USER_OFFLINE:'OTHER_USER_OFFLINE',
  USER_OFFLINE: 'USER_OFFLINE',
}

export const TABS = {
  GENERAL: 'GENERAL',
  FILES: 'FILES',
  MEMBERS: 'MEMBERS',
  STATISTIC: 'STATISTIC',
  PARTICIPANTS: 'PARTICIPANTS',
  ADVANCED: 'ADVANCED',
  JSON_FIELDS: 'JSON_FIELDS',
  USERS_GROUPS: 'USERS_GROUPS',
}

export const EMPTY = {
  EMPTY_VIDEO_CALL_SCREEN: 'EMPTY_VIDEO_CALL_SCREEN',
  EMPTY_LIVE_STEAM_SCREEN: 'EMPTY_LIVE_STEAM_SCREEN',
  EMPTY_FILES_SCREEN: 'EMPTY_FILES_SCREEN',
  EMPTY_TASKS_SCREEN: 'EMPTY_TASKS_SCREEN',
  EMPTY_CALENDAR_SCREEN: 'EMPTY_CALENDAR_SCREEN',
  EMPTY_LEARN_SCREEN: 'EMPTY_LEARN_SCREEN',
  EMPTY_NO_RESULTS: 'EMPTY_NO_RESULTS',
  EMPTY_FOR_SEARCH_MESSAGE: 'EMPTY_FOR_SEARCH_MESSAGE',
  EMPTY_FOR_PIN_MESSAGE_HEADER: 'EMPTY_FOR_PIN_MESSAGE_HEADER',
  EMPTY_FOR_PIN_MESSAGE: 'EMPTY_FOR_PIN_MESSAGE',
  EMPTY_NO_FILES: 'EMPTY_NO_FILES',
  EMPTY_TEAMS_DESCRIPTION: 'EMPTY_TEAMS_DESCRIPTION',
  EMPTY_TEAMS_TITLE: 'EMPTY_TEAMS_TITLE',
  EMPTY_NEWS_FEED_TITLE: 'EMPTY_NEWS_FEED_TITLE',
  EMPTY_NEWS_FEED_DESCRIPTION: 'EMPTY_NEWS_FEED_DESCRIPTION',
  EMPTY_NEWS_FEED_NOTIFICATION: 'EMPTY_NEWS_FEED_NOTIFICATION',
}

export const FORM = {
  ACTIONS:'ACTIONS',
  CHANNEL_NAME: 'CHANNEL_NAME',
  CHANNEL_DESCRIPTION: 'CHANNEL_DESCRIPTION',
  CURRENT_PASSWORD: 'CURRENT_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  FULLNAME: 'FULLNAME',
  EMAIL: 'EMAIL',
  ADMIN_EMAIL: 'ADMIN_EMAIL',
  ADMIN_PASSWORD: 'ADMIN_PASSWORD',
  TEAM: 'TEAM',
  TITLE: 'TITLE',
  PASSWORD: 'PASSWORD',
  PHONE_NUMBER: 'PHONE_NUMBER',
  LOGO: 'LOGO',
  FAVICON: 'FAVICON',
  HOSTNAME: 'HOSTNAME',
  PORT: 'PORT',
  PATH: 'PATH',
  USER_FILTER: 'USER_FILTER',
  GROUP_FULTER: 'GROUP_FILTER',
  FROM: 'FROM',
  FROM_NAME: 'FROM_NAME',
  EMAIL_API_USERNAME: 'EMAIL_API_USERNAME',
  SSL: 'SSL',
  SYSTEM_NAME: 'SYSTEM_NAME',
  LICENCE: 'LICENCE',
  API_KEY: 'API_KEY',
  SYSTEM_VERSION: 'SYSTEM_VERSION',
  NAME: 'NAME',
  MAKE_ADMIN: 'MAKE_ADMIN',
  MAKE_SYSTEM_USER: 'MAKE_SYSTEM_USER',
  MAKE_POST_CREATOR: 'MAKE_POST_CREATOR',
  OPTIONAL: 'OPTIONAL',
  TEAM_NAME: 'TEAM_NAME',
  TEAM_DESCRIPTION: 'TEAM_DESCRIPTION',
  LOWER_CASE_REQUIRED: 'LOWER_CASE_REQUIRED',
  UPPER_CASE_REQUIRED: 'UPPER_CASE_REQUIRED',
  LETTER_CHAR_REQUIRED: 'LETTER_CHAR_REQUIRED',
  NUMBER_REQUIRED: 'NUMBER_REQUIRED',
  UNIQUE_CHARACTER_REQUIRED: 'UNIQUE_CHARACTER_REQUIRED',
  MINIMUM_CHARACTER_REQUIRED: 'MINIMUM_CHARACTER_REQUIRED',
  RESET_PASSWORD_DESCRIPTION: 'RESET_PASSWORD_DESCRIPTION',
  FORGOT_PASSWORD_DESCRIPTION: 'FORGOT_PASSWORD_DESCRIPTION',
  MEETING_NAME: 'MEETING_NAME',
  MEETING_DESCRIPTION: 'MEETING_DESCRIPTION',
  MEETING_TIME: 'MEETING_TIME',
  MEETING_CREDATE: 'MEETING_CREDATE',
  MEETING_STATE: 'MEETING_STATE',
  MEETING_STATE_TRUE: 'MEETING_STATE_TRUE',
  MEETING_STATE_FALSE: 'MEETING_STATE_FALSE',
  MEETING_DURATION: 'MEETING_DURATION',
  MEETING_DELETE: 'MEETING_DELETE',
  MEETING_GROUP: 'MEETING_GROUP',
  MEETING_SINGLE_PARTICIPANT: 'MEETING_SINGLE_PARTICIPANT',
  DEVICE_SHARE_RESTRICTION: 'DEVICE_SHARE_RESTRICTION',
  WAITING_ROOM: 'WAITING_ROOM',
  NOTIFICATION: 'NOTIFICATION',
  DRAW: 'DRAW',
  IS_PUBLIC: 'IS_PUBLIC',
  PUBLIC: 'PUBLIC',
  GUEST_MODE: 'GUEST_MODE',
  IS_ROOM_PROTECTED: 'IS_ROOM_PROTECTED',
  ROOM_PASSWORD: 'ROOM_PASSWORD',
  REPEATABLE: 'REPEATABLE',
  REPEAT_DAYS: 'REPEAT_DAYS',
  REPEAT_END_TIME: 'REPEAT_END_TIME',
  CAPTCHA: 'CAPTCHA',
  FCM_DETAIL: 'FCM_DETAIL',
  NEWS_FEED_DELETE_EDIT:'NEWS_FEED_DELETE_EDIT',
  VIDEO_CALL_ENABLE:'VIDEO_CALL_ENABLE',
  RELOAD: 'RELOAD',
  SYSTEM_SETTINGS: 'SYSTEM_SETTINGS',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FILE_UPLOAD: 'FILE_UPLOAD',
  NOTIFICATION_SOUND: "NOTIFICATION_SOUND",
  GROUP_MEMBERS_ENABLE: "GROUP_MEMBERS_ENABLE",
  GROUP_WITH_MEMBERS:"GROUP_WITH_MEMBERS",
  OTR: 'OTR',
  OFFLINE_CONVERSATION: 'OFFLINE_CONVERSATION',
  FILE_UPLOAD_TITLE:'FILE_UPLOAD_TITLE',
  SYSTEM_SETTINGS_TITLE:'SYSTEM_SETTINGS_TITLE',
  SHOW_UNPERMITTED_FEATURES: 'SHOW_UNPERMITTED_FEATURES'
}

export const TITLES = {
  INITIAL_SETUP: 'INITIAL_SETUP',
  PIN_TITLE: 'PIN_TITLE',
  THREAD_TITLE: 'THREAD_TITLE',
  SEARCH_TITLE: 'SEARCH_TITLE',
  TEAMS_SETTINGS_TITLE: 'TEAMS_SETTINGS_TITLE',
  LDAP_SETTINGS_TITLE: 'LDAP_SETTINGS_TITLE',
  DASHBOARD_TITLE: 'DASHBOARD_TITLE',
  SETTINGS_TITLE: 'SETTINGS',
  SYSTEM_SETTINGS_TITLE: 'SYSTEM_SETTINGS',
  ROLES_SETTINGS_TITLE: 'ROLES_SETTINGS',
  PASSWORD_SETTINGS_TITLE: 'PASSWORD_SETTINGS_TITLE',
  MAIL_SETTINGS_TITLE: 'MAIL_SETTINGS_TITLE',
  ROLE_SETTINGS_TITLE: "ROLE_SETTINGS_TITLE",
  ROLE_SELECT_ALL_RIGHT: "ROLE_SELECT_ALL_RIGHT",
  USER_SETTINGS_TITLE: 'USER_SETTINGS_TITLE',
  MEETINGS_SETTINGS_TITLE: 'MEETINGS_SETTINGS_TITLE',
  CHANNEL_SETTINGS_TITLE: 'CHANNEL_SETTINGS_TITLE',
  MANAGEMENT_TITLE: 'MANAGEMENT_TITLE',
  TOTAL_TEAM: 'TOTAL_TEAM',
  TOTAL_CHANNEL: 'TOTAL_CHANNEL',
  TOTAL_USER: 'TOTAL_USER',
  TOTAL_VIDEO_CALL: 'TOTAL_VIDEO_CALL',
  TOTAL_AUDIO_CALL: 'TOTAL_AUDIO_CALL',
  TOTAL_MESSAGES: 'TOTAL_MESSAGES',
  TOTAL_FILES: 'TOTAL_FILES',
  CHANNELS_JOINED: 'CHANNELS_JOINED',
  REGISTER_TITLE: 'REGISTER_TITLE',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  TEAM_INFO_TITLE: 'TEAM_INFO_TITLE',
  OTR_INFO_TITLE:'OTR_INFO_TITLE'
}

export const TABLE = {
  TEAM_NAME: 'TEAM_NAME',
  TEAM_DESCRIPTION: 'DESCRIPTION',
  TEAM_STATUS: 'STATUS',
  TEAM_MEMBERS: 'MEMBERS',
  TEAM_DELETED: 'DELETED',

  TEAM_CHANNELS: 'CHANNELS',
  TEAM_ACTIONS: 'ACTIONS',
  USER_FULLNAME: 'FULLNAME',
  USER_EMAIL: 'EMAIL',
  USER_TITLE: 'TITLE',
  USER_TYPE: 'USER_TYPE',
  USER_DEPARTMENT: 'DEPARTMENT',
  USER_ACTIVE: 'USER_ACTIVE',
  USER_DELETED: 'DELETED',
  USER_ACTIONS: 'ACTIONS',
  USER_DEACTIVE: 'USER_DEACTIVE',
  USER_RESEND_INVITE: 'USER_RESEND_INVITE',
  USER_ROLE_SUPER: 'USER_ROLE_SUPER',
  USER_ROLE_ADMIN: 'USER_ROLE_ADMIN',
  USER_ROLE_USER: 'USER_ROLE_USER',
  USER_ROLE_GUEST: 'USER_ROLE_GUEST',

  CHANNEL_NAME: 'CHANNEL_NAME',
  CHANNEL_DESCRIPTION: 'CHANNEL_DESCRIPTION',
  CHANNEL_TEAM_NAME: 'TEAM_NAME',
  CHANNEL_MEMBERS: 'MEMBERS',
  CHANNEL_STATUS: 'STATUS',
  CHANNEL_ARCHIVE: 'ARCHIVE',
  CHANNEL_ACTIONS: 'ACTIONS',
  CHANNEL_UNARCHIVE: 'UNARCHIVE',

  LDAP_NAME: 'LDAP_NAME',
  LDAP_HOSTNAME: 'LDAP_HOSTNAME',
  LDAP_PORT: 'LDAP_PORT',
  LDAP_STATUS: 'LDAP_STATUS',
  LDAP_LAST_SYNC: 'LDAP_LAST_SYNC',

  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  CHANNEL_DELETED  :'YES',
  CHANNEL_NO_DELETED  :'NO',

  ROLE_NAME: 'ROLE_NAME'
}

export const DROPDOWN = {
  ALL_TEAM: 'ALL_TEAM',
  ACTIVE_TEAM: 'ACTIVE_TEAM',
  DEACTIVE_TEAM: 'DEACTIVE_TEAM',
  ALL_USERS: 'ALL_USERS',
  ACTIVE_USERS: 'ACTIVE_USERS',
  DEACTIVE_USERS: 'DEACTIVE_USERS',
  ALL_CHANNELS: 'ALL_CHANNELS',
  ACTIVE_CHANNELS: 'ACTIVE_CHANNELS',
  DEACTIVE_CHANNELS: 'DEACTIVE_CHANNELS',
  ALL_LDAP_SERVERS: 'ALL_LDAP_SERVERS',
  ACTIVE_LDAP_SERVERS: 'ACTIVE_LDAP_SERVERS',
  INACTIVE_LDAP_SERVERS: 'INACTIVE_LDAP_SERVERS',
  ALL_MEETINGS: 'ALL_MEETINGS',
  ACTIVE_MEETINGS: 'ACTIVE_MEETINGS',
  PASSIVE_MEETINGS: 'PASSIVE_MEETINGS',
  COMPLETED_MEETINGS: 'COMPLETED_MEETINGS',
  CANCELLED_MEETINGS: 'CANCELLED_MEETINGS',
  DELETED_MEETINGS: 'DELETED_MEETINGS',
  DELETED_MEETINGS_TRUE:'DELETED_MEETINGS_TRUE',
  DELETED_MEETINGS_FALSE:'DELETED_MEETINGS_FALSE',
  UNRESTIRECTE: 'UNRESTIRECTE',
  ASK_ADMINISTATOR: 'ASK_ADMINISTATOR',
  ADMINISTATOR_INITIATES: 'ADMINISTATOR_INITIATES',
  MONDAY: 'MONDAY',
  TUESDAY: 'TUESDAY',
  WEDNESDAY: 'WEDNESDAY',
  THURSDAY: 'THURSDAY',
  FRIDAY: 'FRIDAY',
  SATURDAY: 'SATURDAY',
  SUNDAY: 'SUNDAY',
  WEEKDAY: 'WEEKDAY',
  WEEKEND: 'WEEKEND',
  EVERYDAY: 'EVERYDAY',
}

export const INFORMATION = {
  OTR_APPROVED_ALERT: 'OTR_APPROVED_ALERT',
  ALLOW_NOTIFICATIONS_ALERT: 'ALLOW_NOTIFICATIONS_ALERT',
  COPIED_ALERT: 'COPIED_ALERT',
  MESSAGES_RECEIVED: 'MESSAGES_RECEIVED',
  CHANNEL_MESSAGES_RECEIVED: 'CHANNEL_MESSAGES_RECEIVED',
  NEWSFEED_RECEIVED: 'NEWSFEED_RECEIVED',
  NEW_NOTIFICATION:'NEW_NOTIFICATION'
}

export const NAVIGATION = {
  NEWS_FEED: 'NEWS_FEED',
  TEAMS: 'TEAMS',
  MESSAGES: 'MESSAGES',
  VIDEO_CALL: 'VIDEO_CALL',
  LIVE_STREAM: 'LIVE_STREAM',
  FILES: 'FILES',
  TASKS: 'TASKS',
  E_LEARNING: 'E_LEARNING',
  CALENDAR: 'CALENDAR',
  ACCOUNT_SETTINGS: 'ACCOUNT_SETTINGS',
  SETTINGS: 'SETTINGS',
  SYSTEM_SETTINGS: 'SYSTEM_SETTINGS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SET_PASSWORD: 'SET_PASSWORD',
  LOGOUT: 'LOGOUT',
}

export const USER_STATUS = {
  ONLINE: 'ONLINE',
  AWAY: 'AWAY',
  DO_NOT_DISTURB: 'DO_NOT_DISTURB',
  OFFLINE: 'OFFLINE',
  INVITED: 'INVITED',
  GUEST_USER: 'GUEST_USER',
  MODERATOR: 'MODERATOR',
}


export const ROLES = {
  BACK: 'BACK',
  FORWARD: 'FORWARD',
  SELECT_ALL: 'SELECT_ALL',
}


export const POST = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE',
  CREATE_COMMENT: 'CREATE_COMMENT',
  COMMENT: 'COMMENT',
  PEOPLE: 'PEOPLE',
  LIKES: 'LIKES',
  DISLIKES: 'DISLIKES',
  EDIT_POST: 'EDIT_POST',
  EDIT_CONTENT: 'EDIT_CONTENT',
  EDIT_COMMENT: 'EDIT_COMMENT',
  TODAY_AT: 'TODAY_AT',
  YESTERDAY_AT: 'YESTERDAY_AT',
  NEW_POST: 'NEW_POST',
  CREATE_POST: 'CREATE_POST',
  ARTICLE: 'ARTICLE',
  PHOTO: 'PHOTO',
  VIDEO: 'VIDEO',
  LIVE_STREAM: 'LIVE_STREAM',
  SHARE: 'SHARE',
  REACTIONS: 'REACTIONS',
  ALL: 'ALL',
  READ_MORE: 'READ_MORE',
  READ_LESS: 'READ_LESS',
}
export const OTHERS = {
  LICENCE_EXPIRY: 'LICENCE_EXPIRY',
  NO_ENTRIES_YET: 'NO_ENTRIES_YET',
  NO_COMMENTS_YET: 'NO_COMMENTS_YET',
  JUST_NOW: 'JUST_NOW',
  MINUTE: 'MINUTE',
  HOUR: 'HOUR',
  DAY: 'DAY',
}

export const LDAP_MANAGEMENT = {
  TEST_CONNECTION: 'TEST_CONNECTION',
  RESYNCHRONIZATION_TIME: 'RESYNCHRONIZATION_TIME',
  SYNC_CONNECTION: 'SYNC_CONNECTION',
  NEW_REGISTRY: 'NEW_REGISTRY',
  UPDATE_LDAP_SERVER: 'UPDATE_LDAP_SERVER',
  GROUP_FILTER: 'GROUP_FILTER',
  PATH: 'PATH',
  USERNAME: 'USERNAME',
  USER_FILTER: 'USER_FILTER',
  LDAP_FIELD_INFO: 'LDAP_FIELD_INFO',
  FIELD_SYNCHRONIZATION_SETTINGS: 'FIELD_SYNCHRONIZATION_SETTINGS',
  DELETE_SYNCHRONIZATION: 'DELETE_SYNCHRONIZATION',

  ADD_UPDATE_LDAP_USERS: 'ADD_UPDATE_LDAP_USERS',
  ADD_UPDATE_LDAP_GROUP: 'ADD_UPDATE_LDAP_GROUP',
  ID: 'ID',
  TYPE: 'TYPE',
  USER_NAME: 'USER_NAME',
  EMAIL: 'EMAIL',
  ACTION: 'ACTION',
  SYNCHRONIZATION_DATE: 'SYNCHRONIZATION_DATE',
  CREATION_DATE: 'CREATION_DATE',
  UPDATING_LDAP: 'UPDATING_LDAP',

  GROUP_SYNC: 'GROUP_SYNC',
  USER_SYNC:'USER_SYNC',
  UPDATE_SYNC:'UPDATE_SYNC',
  CREATE_SYNC: 'CREATE_SYNC'

}

export const PERMISSIONS: { [key: string]: string; } = {
  SETTINGS_PERMISSIONS: 'SETTINGS_PERMISSIONS',
  NEWS_FEED_PERMISSIONS: 'NEWS_FEED_PERMISSIONS',
  VIDEO_CONFERENCE_PERMISSIONS: 'VIDEO_CONFERENCE_PERMISSIONS',
  MESSAGING_PERMISSIONS: 'MESSAGING_PERMISSIONS',
  TEAM_AND_CHANNEL_PERMISSIONS: 'TEAM_AND_CHANNEL_PERMISSIONS',
  USER_MANAGEMENT_PERMISSIONS: 'USER_MANAGEMENT_PERMISSIONS',

  UPDATE_USER: 'UPDATE_USER',
  CREATE_AND_ARCHIVE_USER: 'CREATE_USER_P',
  VIEW_USER_STATISTICS: 'VIEW_USER_STATISTICS',
  MAKE_USER_POST_CREATOR: 'MAKE_USER_POST_CREATOR',

  UPDATE_SYSTEM_SETTINGS: 'UPDATE_SYSTEM_SETTINGS',
  UPDATE_PASSWORD_SETTINGS: 'UPDATE_PASSWORD_SETTINGS',
  UPDATE_EMAIL_SETTINGS: 'UPDATE_EMAIL_SETTINGS',
  UPDATE_LDAP_SETTINGS: 'UPDATE_LDAP_SETTINGS',
  CREATE_UPDATE_ROLES: 'CREATE_UPDATE_ROLES',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  EXPORT_DASHBOARD: 'EXPORT_DASHBOARD',

  CREATE_NEWSFEED: 'CREATE_NEWSFEED',
  VIEW_NEWSFEED: 'VIEW_NEWSFEED',
  NEWSFEED_FILE_SHARE: 'NEWSFEED_FILE_SHARE',
  EDIT_NEWSFEED: 'EDIT_NEWSFEED',
  COMMENT_NEWSFEED: 'COMMENT_NEWSFEED',

  CREATE_MEETING: 'CREATE_MEETING_P',
  VIEW_ALL_MEETING_LIST: 'VIEW_ALL_MEETING_LIST',
  VIEW_JOIN_MEETING: 'VIEW_JOIN_MEETING',

  SEARCH_ALL_MESSAGES: 'SEARCH_ALL_MESSAGES',
  START_MESSAGING: 'START_MESSAGING',
  START_OTR_MESSAGING: 'START_OTR_MESSAGING',
  DOCUMENT_SHARE: 'DOCUMENT_SHARE',
  IMAGE_VIDEO_SHARE: 'IMAGE_VIDEO_SHARE',
  AUDIO_SHARE: 'AUDIO_SHARE',
  CREATE_MEETING_IN_MESSAGE: 'CREATE_MEETING_IN_MESSAGE',

  CREATE_OR_ARCHIVE_TEAM: 'CREATE_OR_ARCHIVE_TEAM',
  REMOVE_TEAM_FROM_ARCHIVE: 'REMOVE_TEAM_FROM_ARCHIVE',
  UPDATE_TEAM: 'UPDATE_TEAM',
  CREATE_OR_ARCHIVE_CHANNEL: 'CREATE_OR_ARCHIVE_CHANNEL',
  UPDATE_CHANNEL: 'UPDATE_CHANNEL',
  CREATE_MEETING_IN_CHANNEL: 'CREATE_MEETING_IN_CHANNEL',
  VIEW_TEAM_MEMBERS: 'VIEW_TEAM_MEMBERS',
  VIEW_TEAM_STATISTICS: 'VIEW_TEAM_STATISTICS',
  CREATE_MENTION: 'CREATE_MENTION',
  SEARCH_PUBLIC_CHANNEL :'SEARCH_PUBLIC_CHANNEL',
  VIEW_MEMBERSHIPS:'VIEW_MEMBERSHIPS',
  VIEW_TEAMS:"VIEW_TEAMS"
  
}
