import React from 'react'
import styled from 'styled-components'

import Spinner from './Spinner'

class Loading extends React.PureComponent {
  static ref: Loading
  state = { isLoading: false }

  show = () => {
    this.setState({ isLoading: true })
  }

  hide = () => {
    this.setState({ isLoading: false })
  }

  render() {
    const { isLoading } = this.state
    if (!isLoading) return null
    return (
      <Wrapper>
        <Spinner loading width="40" height="40" />
      </Wrapper>
    )
  }
}

export default Loading

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.3);
`
