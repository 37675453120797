import React, { RefObject, useEffect, useState } from 'react'
import { MentionsInput, Mention } from 'react-mentions'

import { FixMeLater } from 'types'
import { IUser, IUserData } from 'types/user.types'

import MentionsDropdown from './MentionsDropdown'
import styled from 'styled-components'
import { useLazyGetUsersForMentionQuery } from 'app/services/room.service'
import { useLazyGetUsersByFullnameQuery } from 'app/services/user.service'

interface UserInfo {
  id: string
  display: string
}

interface IMention {
  isOtr?: boolean
  value: string
  roomId: string
  placeholder: string
  inputRef: FixMeLater
  users?: IUserData
  mentionsDropdownRef: RefObject<HTMLDivElement>
  onBlur: () => void
  onChange: (event: FixMeLater) => void
  onFocus: (event: React.FocusEvent<HTMLElement>) => void
  onKeyUp: (event: React.KeyboardEvent<HTMLElement>) => void
  onKeyDown: (event: React.KeyboardEvent<HTMLElement>) => void
  onMentionDropdown: (item: boolean) => void
}

const Mentions = ({
  isOtr,
  value,
  roomId,
  inputRef,
  placeholder,
  mentionsDropdownRef,
  users,
  onBlur,
  onKeyUp,
  onFocus,
  onChange,
  onKeyDown,
  onMentionDropdown,
}: IMention) => {
  const [getMembersBySearch] = useLazyGetUsersByFullnameQuery()
  const [mentionedUsers, setMentionedUsers] = useState<IUserData>({})

  const searchMembersToMention = async (query: string, callback: (response: any) => void) => {
    // fetch users
    const searchResult = (await getMembersBySearch({ searchText: query })).data?.data || []

    // Temporary object to hold new mentioned users
    const newMentionedUsers: IUserData = {}

    searchResult.forEach((user: IUser) => {
      newMentionedUsers[user._id] = user
    })

    setMentionedUsers(newMentionedUsers)
    
    callback(
      Object.values(newMentionedUsers).map((user: IUser) => ({
        id: user._id,
        display: user.fullname,
        ...user,
      })),
    )
  }

  return (
    <MentionWrapper id="mention">
      <MentionsInput
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        forceSuggestionsAboveCursor={true}
        inputRef={inputRef}
        allowSpaceInQuery={true}
        customSuggestionsContainer={(children: FixMeLater) => {
          return (
            <MentionsDropdown
              id={'mention'}
              items={children}
              users={mentionedUsers}
              mentionsDropdownRef={mentionsDropdownRef}
              onMentionDropdown={onMentionDropdown}
            />
          )
        }}
      >
        <Mention
          trigger={'@'}
          style={defaultMentionStyle}
          appendSpaceOnAdd={true}
          markup={'@[__display__]&_id:__id__&'}
          data={searchMembersToMention}
          displayTransform={(id, display) => {
            return `@${display}`
          }}
        />
      </MentionsInput>
    </MentionWrapper>
  )
}

export default Mentions

export const defaultMentionStyle = {
  backgroundColor: '#00A9E0',
  opacity: 0.3,
}

const MentionWrapper = styled.div`
  word-break: break-word;
`
