import moment from 'moment'
import { useMemo } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { IUserData } from 'types/user.types'
import { DATES_IN_MINUTES } from 'utils/constants'
import { updateContentText } from 'utils/helpers/messageHelper'

import CustomText from 'components/text/CustomText'
import PostHeader from 'features/news-feed/_components/post-header/PostHeader'
import { useTranslation } from 'react-i18next'
import { OTHERS } from 'locales/locales'

interface ICommentProps {
  id: string
  postId: string
  fullname: string
  avatar: string
  creatorId:string
  team?: string
  data: { mentionedUsers: Array<string>; text: string }
  createdAt: string
  users: IUserData
  isOption: boolean
  onDeleteComment: (commentId: string) => void
  onShowEditPostModal?: FixMeLater
}

const { DAY, HOUR } = DATES_IN_MINUTES

const Comment = ({
  id,
  postId,
  fullname,
  avatar,
  team,
  data,
  createdAt,
  creatorId,
  users,
  isOption,
  onDeleteComment,
  onShowEditPostModal,
}: ICommentProps) => {
  const { t } = useTranslation()
  const textByRepliedUsers = updateContentText(data?.text, data?.mentionedUsers, undefined, users)

  const commentSentAt = useMemo(() => {
    // Get the difference between the comment creation date and now in minutes
    // and print that diff in custom format below.
    const diffInMins = moment().diff(moment(createdAt), 'minutes')
    // Just sent, print 'just now'
    if (diffInMins < 1) {
      return t(OTHERS.JUST_NOW)
      // Minutes, ex: 30min
    } else if (diffInMins >= 1 && diffInMins < HOUR) {
      return `${diffInMins} ${t(OTHERS.MINUTE)}`
      // Hours, ex: 3h
    } else if (diffInMins >= HOUR && diffInMins < DAY) {
      return `${Math.floor(diffInMins / HOUR)} ${t(OTHERS.HOUR)}`
      // Days, ex: 5d
    } else if (diffInMins >= DAY && diffInMins < DAY * 2) {
      return `${Math.floor(diffInMins / DAY)} ${t(OTHERS.DAY)}`
      // Weeks, ex: 3w
    } else if (diffInMins >= DAY * 2) {
      return `${moment(new Date(createdAt)).format('LLL')}`
    }

    return ''
  }, [])

  return (
    <Wrapper id={id}>
      <PostHeader
        creatorId={creatorId}
        postId={postId}
        fullname={fullname}
        avatar={avatar}
        team={team}
        date={commentSentAt}
        isComment
        isOption={isOption}
        onDelete={onDeleteComment}
        onUpdate={onShowEditPostModal}
      />
      <CommentText typography="body2">{textByRepliedUsers}</CommentText>
    </Wrapper>
  )
}

export default Comment

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 0px -20px;
  padding: 8px 20px;
  &:hover {
    background-color: ${colors.light50};
  }
`

const CommentText = styled(CustomText)`
  padding-left: 60px;
`
