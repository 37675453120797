
import { Checkbox } from "@progress/kendo-react-inputs";


export const SelectionCell = ({onChangeSelected,isSelected}:any) => {


  return (
    <td
    
      role={"gridcell"}
    
    >
      <Checkbox checked={isSelected} onChange={(e)=>onChangeSelected(e)} />
    </td>
  );
};