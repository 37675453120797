import api from 'app/api'

export const otrService = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      sendOtrResponse: builder.mutation({
        query: ({ response, roomId }) => ({
          url: `room/${roomId}/otr/response`,
          method: 'POST',
          body: { response },
        }),
        invalidatesTags: ['SEND_OTR_RESPONSE'],
      }),
      leaveOtr: builder.mutation({
        query: ({ roomId }) => ({
          url: `room/${roomId}/otr/leave/`,
          method: 'PUT',
        }),
        invalidatesTags: ['LEAVE_OTR'],
      }),
      closeOtr: builder.mutation({
        query: ({ roomId }) => ({
          url: `room/${roomId}/otr/close`,
          method: 'PUT',
        }),
        invalidatesTags: ['DELETE_OTR'],
      }),
    }
  },
})

export const { useSendOtrResponseMutation, useCloseOtrMutation, useLeaveOtrMutation } = otrService
