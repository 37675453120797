import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import CustomText from 'components/text/CustomText'
import styled from 'styled-components'
import { FixMeLater } from 'types'
import colors from 'utils/colors'
import React from 'react'
import { IUserData } from 'types/user.types'

interface IProps {
  item: FixMeLater
  users?: IUserData
}

const MentionList: React.FC<IProps> = ({ item, users }) => {
  const user = users?.[item?.props?.suggestion?.id]
  const avatar = user?.avatar ?? ''
  const fullname = user?.fullname ?? ''
  
  return (
    <MentionableUserRow onClick={item.props.onClick}>
      <Avatar source={avatar} size={AvatarSize.small} text={fullname} />
      <CustomText cursor="pointer" typography="header3">
        {item.props.suggestion.display}
      </CustomText>
    </MentionableUserRow>
  )
}

export const MentionableUserRow = styled.div.attrs((props: { isActive: boolean }) => props)`
  width: 250px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 12px;
  gap: 10px;
  &:hover {
    background-color: ${colors.light50};
  }
`

export default MentionList
