export interface IRolePermissions {
  settings: SettingsPermissions;
  newsFeed: NewsFeedPermissions;
  videoConference: VideoConferencePermissions;
  messaging: MessagingPermissions;
  teamAndChannel: TeamAndChannelPermissions;
  userManagement: UserManagementPermissions;
}

export enum PermissionType {
  SETTINGS_PERMISSIONS,
  NEWS_FEED_PERMISSIONS,
  VIDEO_CONFERENCE_PERMISSIONS,
  MESSAGING_PERMISSIONS,
  TEAM_AND_CHANNEL_PERMISSIONS,
  USER_MANAGEMENT_PERMISSIONS
}

export enum SettingsPermissions {
  UPDATE_SYSTEM_SETTINGS = 1 << 0, //46
  UPDATE_PASSWORD_SETTINGS = 1 << 1, //47
  UPDATE_EMAIL_SETTINGS = 1 << 2, //48
  UPDATE_LDAP_SETTINGS = 1 << 3, //49
  VIEW_DASHBOARD = 1 << 4, //50
  EXPORT_DASHBOARD = 1 << 5, //51,
  CREATE_UPDATE_ROLES = 1 << 6
}

export enum NewsFeedPermissions {
  CREATE_NEWSFEED = 1 << 0, //33
  VIEW_NEWSFEED = 1 << 1, //34
  NEWSFEED_FILE_SHARE = 1 << 2, //35
  EDIT_NEWSFEED = 1 << 3, //36
  COMMENT_NEWSFEED = 1 << 4 //37
}

export enum VideoConferencePermissions {
  CREATE_MEETING = 1 << 0, //29
  VIEW_ALL_MEETING_LIST = 1 << 1, //30
  VIEW_JOIN_MEETING = 1 << 2 //31
}

export enum MessagingPermissions {
  SEARCH_ALL_MESSAGES = 1 << 0, //21
  START_MESSAGING = 1 << 1, //22
  START_OTR_MESSAGING = 1 << 2, //23
  DOCUMENT_SHARE = 1 << 3, //24
  IMAGE_VIDEO_SHARE = 1 << 4, //25
  AUDIO_SHARE = 1 << 5, //26
  CREATE_MEETING_IN_MESSAGE = 1 << 6 //27
}

export enum TeamAndChannelPermissions {
  CREATE_OR_ARCHIVE_TEAM = 1 << 0, //14
  REMOVE_TEAM_FROM_ARCHIVE = 1 << 1, //15
  UPDATE_TEAM = 1 << 2, //16
  CREATE_OR_ARCHIVE_CHANNEL = 1 << 3, //17
  UPDATE_CHANNEL = 1 << 4, //18
  CREATE_MEETING_IN_CHANNEL = 1 << 5, //19
  VIEW_TEAM_MEMBERS = 1 << 6, //44
  VIEW_TEAM_STATISTICS = 1 << 7, //45'
  SEARCH_PUBLIC_CHANNEL =1  << 8,
  VIEW_MEMBERSHIPS = 1 << 9, //512
  VIEW_TEAMS = 1 << 10 

}

export enum UserManagementPermissions {
  UPDATE_USER = 1 << 0, //39
  CREATE_AND_ARCHIVE_USER = 1 << 1, //40
  VIEW_USER_STATISTICS = 1 << 2, //41
  MAKE_USER_POST_CREATOR = 1 << 3, //43
}
