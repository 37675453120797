import { useEffect, useMemo, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import styled from 'styled-components'

import { FixMeLater } from 'types'
import { RoomData } from 'types/rooms.types'
import { EMPTY, NAVIGATION } from 'locales/locales'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { useLazyGetRoomsQuery, useSearchConversationMutation } from 'app/services/room.service'
import { MOBILE_WIDTH } from 'utils/constants'

import CreateNewRoom from './create-new-room/CreateNewRoom'
import ConversationList from './converation-list/ConversationList'
import ConversationPanel from 'components/conversation-panel/ConversationPanel'

export type FileState = {
  files: { id: string; isLoading: boolean; data: string; file: File }[]
  isValidUpload: boolean
  errorText: string
}

const Messages = () => {
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()
  const [searchConversation] = useSearchConversationMutation()

  const [visible, setVisible] = useState(false)
  const [visibleCreateRoom, setVisibleCreateRoom] = useState(false)
  const [getRooms]: FixMeLater = useLazyGetRoomsQuery()
  const [roomsBySearch, setRoomsBySearch] = useState<RoomData | undefined | string>()
  const currentUserId = useAppSelector((state) => state.auth.id)

  useEffect(() => {
    getRooms()
  }, [])

  useEffect(() => {
    if (screenWidth > MOBILE_WIDTH && visible) {
      onToggleConversationPanel()
    }
  }, [screenWidth])

  const onChangeSearchText = async (text: string) => {
    try {
      
      if (text && text.length > 0) {
        const result: FixMeLater = await searchConversation(text)
        setRoomsBySearch(
          result?.data?.data.length !== 0 ? result?.data?.data : t(EMPTY.EMPTY_NO_RESULTS),
        )
        return
      }

      setRoomsBySearch(undefined)
    } catch {
      setRoomsBySearch(t(EMPTY.EMPTY_NO_RESULTS))
    }
  }

  const onToggleConversationPanel = () => {
    setVisible((prev) => !prev)
  }

  const onToggleCreateRoomModal = () => {
    setVisibleCreateRoom((prevState) => !prevState)
    setVisible(false)
  }

  const Content = useMemo(() => {
    return <Outlet context={{ onToggleConversationPanel }} />
  }, [])

  return (
    <Wrapper>
      {visibleCreateRoom && (
        <CreateNewRoom currentUserId={currentUserId} onClose={() => setVisibleCreateRoom(false)} />
      )}
      <ConversationPanel
        title={t(NAVIGATION.MESSAGES)}
        visible={visible}
        content={
          <ConversationList
            roomsBySearch={roomsBySearch}
            onHideConversationPanel={onToggleConversationPanel}
          />
        }
        onChangeSearch={onChangeSearchText}
        onClickCircleButton={onToggleCreateRoomModal}
        onHideConversationPanel={onToggleConversationPanel}
      />
      {Content}
    </Wrapper>
  )
}

export default Messages

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  position: relative;
`
