import colors from 'utils/colors'

export const CustomButtontypographyKey = {
  //For color
  primary: {
    backgroundColor: colors.vime,
    hoverBackgroundColor: colors.mainVimeMid,
    color: colors.white,
    disabledColor: colors.white,
  },
  secondary: {
    backgroundColor: colors.light80,
    hoverBackgroundColor: colors.secondaryLight,
    color: colors.black,
    disabledColor: colors.black,
  },
  danger: {
    backgroundColor: colors.red,
    hoverBackgroundColor: colors.secondaryRed,
    color: colors.white,
    disabledColor: colors.white,
  },

  //For Size

  small: {
    height: '20px',
    padding: '4px 8px',
    width: '',
  },

  medium: {
    height: '36px',
    withIconPadding: '6px 8px 6px 2px',
    padding: '6px 16px',
    width: '',
  },

  large: {
    height: '50px',
    padding: '16px 8px',
    width: '100%',
  },
}
