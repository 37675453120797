import styled from 'styled-components'
import { MESSAGES } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import { DropzoneRootProps } from 'react-dropzone'

import colors from 'utils/colors'
import { icons } from 'utils/icons'

import CustomIcon from 'components/icon/CustomIcon'

interface Props {
  isDragging: boolean
  isNewsFeed?: boolean
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
}

export default function Dropzone({ isDragging, isNewsFeed = false, getRootProps }: Props) {
  const { t } = useTranslation()
  return isDragging ? (
    <DropzoneContainer>
      <DropzoneWrapper {...getRootProps()}>
        <CustomIcon
          width="150px"
          height="150px"
          source={isNewsFeed ? icons.filesIcon : icons.fileIcon}
        />
        <DropText>{t(MESSAGES.UPLOAD_FILE)}</DropText>
      </DropzoneWrapper>
    </DropzoneContainer>
  ) : null
}

const DropzoneContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 30px;
  backdrop-filter: blur(15px);
  z-index: 4;
`
const DropText = styled.p`
  font-weight: 600;
  font-size: 32px;
  line-height: 24px;
  color: ${colors.vime};
`

const DropzoneWrapper = styled.div`
  border: 8px dashed ${colors.vime};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  border-radius: 20px;
`
