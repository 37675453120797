import { useEffect, useState } from 'react'

import { IUser } from 'types/user.types'
import { useTranslation } from 'react-i18next'
import { useLazyGetUserByIdQuery, useUpdateUserMutation,useUpdateUserPasswordMutation ,useLazyGetMyUserQuery} from 'app/services/user.service'
import { FixMeLater } from 'types'
import { passwordValidator } from 'utils/helpers/passwordValidator'
import { NAVIGATION, TABS } from 'locales/locales'
import {
  confirmPasswordValidation,
  phoneNumberValidation,
  requiredValidation,
} from 'utils/helpers/validations'
import { useAppSelector } from 'app/hooks'
import UserInfo from './user-info/UserInfo'
import ChangePassword from './change-password/ChangePassword'
import CustomModal from 'components/modals/CustomModal'
import Loading from 'components/spinner/Loading'
import UserStatistic from '../../components/user-statistic/UserStatistics'
import Spinner from 'components/spinner/Spinner'

interface IAccountSettingsProps {
  myUserInfo: IUser
  onClose: () => void
}

const statisticDataInitial = {
  totalChannels: 0,
  totalMessages: 0,
  totalFiles: 0,
  totalVideoCall: 0,
}
const AccountSettings = ({ myUserInfo, onClose }: IAccountSettingsProps) => {
  const passwordSettingsSlice = useAppSelector((state) => state.system.passwordSettings)
  const myId = useAppSelector((state) => state.auth.id)

  const { t } = useTranslation()
  const [formChecked, setFormChecked] = useState(false)
  const [visibleChangePassword, setVisibleChangePassword] = useState(false)
  const [userStatisticData, setUserStatisticData] = useState(statisticDataInitial)
  const [userInfo, setUserInfo] = useState({
    avatar: "",
    fullname: "",
    email: "",
    title: "",
    team: "",
    phoneNumber: "",
    isLdapUser: false,
  })
  const [passwordInfo, setPasswordInfo] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  const [updateUser] = useUpdateUserMutation()
  const [updateUserPassword] = useUpdateUserPasswordMutation()
  const [getMyUser, { isFetching }] = useLazyGetMyUserQuery()

  useEffect(() => {
    fetchUserInfo()
  }, [])

  const fetchUserInfo = async () => {
    try {
      const result: FixMeLater = await getMyUser({});
      setUserStatisticData(result?.data?.data?.statistic)
      setUserInfo(result?.data?.data?.info)
    } catch (err) {
      console.log(err)
    }
  }

  const onUpdateUserInfo = async () => {
    setFormChecked(true)
    const { isValid: isValidPhone } = phoneNumberValidation(
      userInfo.phoneNumber ? userInfo.phoneNumber : '',
    )

    const { isValid: isValidName } = requiredValidation(userInfo.fullname)
    if (isValidPhone && isValidName) {
      try {
        Loading.ref.show()
        await updateUser({
          fullname: userInfo.fullname.trim(),
          avatar: userInfo.avatar,
          phoneNumber: userInfo.phoneNumber.trim(),
        })
      } catch (error) {
        finishUpdate()
      } finally {
        Loading.ref.hide()
        finishUpdate()
      }
    }
  }

  const onUpdatePassword = async () => {
    setFormChecked(true)
    const { isValid: isRequiredCurrent } = requiredValidation(passwordInfo.currentPassword)
    const { isValid: isRequiredNew } = requiredValidation(passwordInfo.newPassword)
    const { isValid: isRequiredConfirmNew } = requiredValidation(passwordInfo.confirmNewPassword)
    const { isValid: isConfirm } = confirmPasswordValidation(
      passwordInfo.newPassword,
      passwordInfo.confirmNewPassword,
    )

    if (isRequiredCurrent && isRequiredNew && isRequiredConfirmNew && isConfirm) {
      if (passwordSettingsSlice && Object.keys(passwordSettingsSlice)?.length) {
        const validate = passwordValidator(passwordInfo.newPassword, passwordSettingsSlice)
        if (!validate.valid) {
          return
        }
      }
      try {
        Loading.ref.show()
        const data = (await updateUserPassword({
          currentPassword: passwordInfo.currentPassword,
          password: passwordInfo.newPassword,
        })) as FixMeLater

        //To not close the Modal
        if (data.error) return
        finishUpdate()
      } finally {
        Loading.ref.hide()
      }
    }
  }

  const uploadAvatar = async (fileUrl: string) => {
    setUserInfo({ ...userInfo, avatar: fileUrl })
  }

  const onChangeUserInfo = (name: string, value: string) => {
    setUserInfo({ ...userInfo, [name]: value })
  }

  const onChangePasswordInfo = (name: string, value: string) => {
    setPasswordInfo({ ...passwordInfo, [name]: value })
  }

  const onClickChangePassword = () => {
    setVisibleChangePassword(true)
  }

  const finishUpdate = () => {
    onClose()
    setFormChecked(false)
  }

  const tab = [{ title: t(TABS.GENERAL), action: true }, { title: t(TABS.STATISTIC) }]

  return (
    <CustomModal
      title={t(NAVIGATION.ACCOUNT_SETTINGS)}
      onCancel={onClose}
      closeModal={onClose}
      onOk={!visibleChangePassword ? onUpdateUserInfo : onUpdatePassword}
      tab={tab}
      showButtons={false}
    >
      {!visibleChangePassword ? (
        <UserInfo
          {...userInfo}
          formChecked={formChecked}
          onChange={onChangeUserInfo}
          onUploadPhoto={uploadAvatar}
          onClickChangePassword={onClickChangePassword}
        />
      ) : (
        <ChangePassword
          {...passwordInfo}
          formChecked={formChecked}
          onChange={onChangePasswordInfo}
        />
      )}
      {isFetching && <Spinner loading />}
      <UserStatistic {...userStatisticData} />
    </CustomModal>
  )
}

export default AccountSettings
