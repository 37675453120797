import { FixMeLater } from 'types'
import { ICustomFile, IFiles } from './file.types'
import { IUser, IUserData } from './user.types'

export interface IMessageContent {
  text?: string
  files?: IFiles
  mentionedUsers?: Array<string>
  value?: string
  
}

export interface IMessage {
  _id: string
  sender: string
  roomId: string
  createdAt?: string
  sentAt: string
  type: Array<string>
  content: IMessageContent
  threadCount: number
  isThreadOpen?: boolean
  repliedUsers: Array<string>
  isDeleted: boolean
  isEdited: boolean
  threadId: string
  reactions: Array<{ emoji: string; users: Array<string> }>
  status?: string
  pinned: boolean
  subsequent?: boolean
  cti: string
  users: IUser[]
  userData: IUserData
  threads?: IMessage[]
}

export interface ConversationSidePanelState {
  visible: boolean
  type?: string
  params?: FixMeLater
}

export interface IFailedMessage {
  roomId: string
  message: IMessage
}

export interface IConversation {
  [key: string]: {
    page: number
    hasMore?: boolean
    messages: Array<IMessage | FixMeLater>
    newMessage?: boolean
  }
}

export type TReceivedMessage = Array<IMessage>

export interface IDraftData {
  [key: string]: { id: string; files: ICustomFile[]; text: string; value: string }
}

export enum MessageStatus {
  PENDING = 'pending',
  FAILED = 'failed',
}

export enum MessageUserType {
  SYSTEM = 'SYSTEM',
}
