import alg from 'crypto-js'
import { getDataFromDB } from './storageHelper'
import { FixMeLater } from 'types'


const LOCAL_HOST = 'http://localhost:8080/'
const DEVELOPMENT_HOST = 'https://chat.tai.com.tr/'

export const CONFERENCE_API_PATH = 'https://vcc.tai.com.tr/api/'





export default function getBaseUrl(stripSlash = false) {

  let toReturn = DEVELOPMENT_HOST; 
  
  if (process.env.REACT_APP_STAGE !== 'development') {
    toReturn = LOCAL_HOST;
  }

  if(process.env.REACT_APP_API_BASE_URL){
    toReturn = process.env.REACT_APP_API_BASE_URL;
  }

  if(stripSlash) {
    return toReturn.slice(0, -1);
  }

  return toReturn;
}

export async function getApiHash(token: string, myId: string) {
  const clientId = ((await getDataFromDB('vimesoft', 'clientId')) as string) || ''
  const uuidV4 = ((await getDataFromDB('vimesoft', 'clientUUID')) as string) || ''
  const config = (await getDataFromDB('vimesoft', 'config')) as FixMeLater
  const clientHash = alg.SHA512(clientId + uuidV4 + config?.salt).toString()
  const tokenHash = alg.SHA512(token + myId + clientId + config?.salt).toString()

  return {
    clientId,
    clientHash,
    tokenHash,
  }
}
