import axios from 'axios'
import getBaseUrl, { getApiHash } from 'utils/helpers/apiHelper'
import { FixMeLater } from 'types'

let controller = new AbortController()
const axiosApi = {
  setHeader: async (token: string, myId: string) => {
    const { clientHash, clientId, tokenHash } = await getApiHash(token, myId)
    axios.defaults.headers.common[
      'Authorization'
    ] = `Vmsft:Token ${clientId}:${clientHash},${token}:${tokenHash}`
  },
  request: async (method: string, path: string, params?: FixMeLater, config?: FixMeLater) => {
    return await axios({
      method,
      url: getBaseUrl() + path,
      data: params,
      ...config,
    })
  },
  uploadFile: async (
    path: string,
    files: File[],
    appendName: string,
    onUploadProgress?: (event: ProgressEvent) => void,
  ) => {
    controller = new AbortController()
    const formData = new FormData()
    files.forEach((file) => {
      formData.append(appendName, file)
    })
    return await axiosApi.request('POST', path, formData, {
      onUploadProgress,
      signal: controller.signal,
    })
  },
  clearToken: () => {
    axios.defaults.headers.common['Authorization'] = ''
  },
  cancelUploadFile: () => {
    controller.abort()
  },
  downloadFileWithUrl: async (url: string) => {
    return axios.get(url, { responseType: 'blob', withCredentials: true }).then((response) => {
      return URL.createObjectURL(response.data)
    })
  },
  get: (path: string) => axiosApi.request('GET', path),
  post: (path: string, params: FixMeLater) => axiosApi.request('POST', path, params),
  put: (path: string, params?: FixMeLater) => axiosApi.request('PUT', path, params),
}

export default axiosApi
