import { useState } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { IDropdownOption, TDropdown } from 'types'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

interface IDropdownProps {
  selectedOption: IDropdownOption
  options: TDropdown
  onChangeOption: (params: IDropdownOption) => void
  className?: string
  title?: string
  disabled?: boolean
}

const Dropdown = ({
  selectedOption,
  options,
  onChangeOption,
  title,
  className,
  disabled,
}: IDropdownProps) => {
  const [visible, setVisible] = useState(false)

  const ontoggleOption = () => {
    if (!disabled) {
      setVisible((prevState) => !prevState)
    }
  }

  return (
    <>
      <DrowdownContainer className={className} onClick={ontoggleOption}>
        {title && (
          <TitleWrapper>
            <CustomText typography="header3">{title}</CustomText>
          </TitleWrapper>
        )}
        <Button type="button" disabled={disabled}>
          <CustomText typography="body2">{selectedOption.title}</CustomText>
          <CustomIcon source={icons.chevronDownIconBig} />
        </Button>

        <OptionList visible={visible}>
          {options.map((option: IDropdownOption) => {
            return (
              <StyledOption
                key={option.title}
                value={option.value}
                onClick={() => onChangeOption(option)}
              >
                <CustomText typography="body2">{option.title}</CustomText>
              </StyledOption>
            )
          })}
        </OptionList>
      </DrowdownContainer>
      {visible && <BackDrop onClick={ontoggleOption} />}
    </>
  )
}

export default Dropdown

const BackDrop = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
`

const DrowdownContainer = styled.div`
  position: relative;
  z-index: 4;
`

const Button = styled.button`
  width: 100%;
  height: 32px;
  cursor: pointer;
  border: 1px solid ${colors.light80};
  border-radius: 8px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 11px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? colors.light50 : colors.white};
`

const OptionList = styled.ul.attrs((props: { visible: boolean }) => props)`
  padding: 6px 0px;
  border: 1px solid ${colors.light80};
  border-radius: 4px;
  background-color: ${colors.white};
  position: absolute;
  top: 35px;
  left: 0;
  width: 100%;
  max-height: 200px;
  border-radius: 8px;
  overflow: auto;
  z-index: 3;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`

const StyledOption = styled.li`
  list-style-type: none;
  width: 100%;
  height: 32px;
  padding-bottom: 6px;
  padding-top: 6px;
  padding-left: 11px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.light50};
  }
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 8px;
`
