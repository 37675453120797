import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { FetchArgs, FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'

import { FixMeLater } from 'types'
import { RootState } from './store'
import getBaseUrl, { getApiHash } from 'utils/helpers/apiHelper'

const baseUrl = getBaseUrl() + 'api/'

const TIMEOUT = 30 * 1000

const fetchBase = () => {
  const baseQueryApi = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers, { getState }) => {
      const { token, id } = (getState() as RootState).auth
      const { clientHash, clientId, tokenHash } = await getApiHash(token, id)
      headers.set('Accept-Language', navigator.language || 'tr-TR')
      headers.set('Authorization', `Vmsft:Client ${clientId}:${clientHash}`)
      if (token) {
        headers.set('Authorization', `Vmsft:Token ${clientId}:${clientHash},${token}:${tokenHash}`)
      }
      return headers
    },
    timeout: TIMEOUT,
    credentials: 'include',
  })

  return async (args: FetchArgs, api: BaseQueryApi, extraOptions: FetchBaseQueryArgs) => {
    if (args?.url?.includes('undefined')) {
      return { error: { status: 'CUSTOM_ERROR', error: 'Invalid URL' }};
    }
    const result: FixMeLater = await baseQueryApi(args, api, extraOptions)
    return result
  }
}

const api = createApi({
  reducerPath: 'apis',
  tagTypes: [
    'INITIALIZE_SYSTEM',
    'CONFIG',
    'AUTH',
    'LOGOUT',
    'REGISTER',
    'GET_USER',
    'GET_USER_FOR_ADMIN',
    'DELETE_USER',
    'UPDATE_USER',
    'UPDATE_USER_ARCHIVE',
    'SEND_MESSAGE',
    'GET_MESSAGE',
    'SEND_THREAD_MESSAGE',
    'SEND_PINNED_MESSAGE',
    'GET_PINNED_MESSAGES',
    'GET_MESSAGES_BY_PAGE',
    'GET_ROOM',
    'GET_USERS_BY_EMAIL',
    'GET_THREAD_MESSAGE',
    'UPDATE_NOTIFICATION',
    'CREATE_CONVERSATION',
    'SEARCH_CONVERSATION',
    'GET_GROUP',
    'GET_GROUPS_FOR_ADMIN',
    'GET_CHANNELS_FOR_ADMIN',
    'CREATE_CHANNEL',
    'CREATE_GROUP',
    'GET_MEMBERS',
    'UPDATE_GROUP',
    'UPDATE_REACTION',
    'DELETE_MESSAGE',
    'EDIT_MESSAGE',
    'GET_USERS_WITHOUT_GROUP',
    'UPDATE_CHANNEL',
    'GET_CHANNELS',
    'GET_ROOM_FILES',
    'GET_ROOM_SEARCH_MESSAGES',
    'GET_ROOM_SEARCH_FILES',
    'GET_JUMP_MESSAGES',
    'READ_MESSAGES',
    'GET_USERS_FOR_MENTION',
    'SEND_VANISH_MESSAGE',
    'LDAP_SYNC_LOGS',
    'GET_LDAP_SERVER_LIST',
    'GET_LDAP_SERVER',
    'GET_LDAP_SYNC_LIST',
    'CREATE_NEW_SERVER',
    'CHECK_LDAP_CONNECTION',
    'CREATE_LDAP_SERVER',
    'CREATE_LDAP_SERVER_WITH_CONFERENCE',
    'CREATE_LDAP_GROUP_WITH_CONFERENCE',
    'SYNCHRONIZE_LDAP_TO_MONGO',
    'PAIR_LDAP_SERVER',
    'UPDATE_LDAP_SERVER',
    'CREATE_NEW_SETTING',
    'UPDATE_SYSTEM_SETINGS',
    'UPDATE_SYSTEM_PASSWORD_SETTINGS',
    'CREATE_PASSWORD_SETTINGS',
    'UPDATE_PASSWORD',
    'CREATE_MAIL_SETTINGS',
    'UPDATE_MAIL_SETTINGS',
    'SEND_OTR_RESPONSE',
    'GET_USERS_BY_ROOM_ID',
    'UPDATE_APPARANCE',
    'UPDATE_ARCHIVE',
    'LEAVE_CHANNEL',
    'REMOVE_CHANNEL_MEMBER',
    'ADD_CHANNEL_MEMBER',
    'INVITE_CHANNEL',
    'UPDATE_USER_BY_ID',
    'UPDATE_USER_PERMISSION',
    "UPDATE_USER_FCM_TOKEN",
    'SEND_POST',
    'SEND_COMMENT',
    'LIKE_POST',
    'DISLIKE_POST',
    'DELETE_POST',
    'EDIT_POST',
    'ADD_GROUP_MEMBER',
    'REMOVE_GROUP_MEMBER',
    'REINVITE_CHANNEL',
    'VIEW_MESSAGES',
    'DELETE_OTR',
    'SEARCH_QUERY',
    'LEAVE_OTR',
    'GET_POSTS',
    'GET_UNREAD_POSTS',
    'INITIAL_GET_POSTS',
    'GET_UNREAD_NOTIFICATION',
    'UNVIEW_MESSAGES',
    'REGISTER_CLIENT',
    'RE_CONNECTION',
    'GET_ALL_CHANNELS',
    'GET_UNREAD_COMMENTS_NOTIFICATION',
    'GET_NEWS_FEED_NOTIFICATION',
    'GET_NEWS_FEED_NOTIFICATION_COUNT',
    'GET_NEWS_FEED_JUMPED_COMMENTS',
    'GET_THREAD_NOTIFICATIONS',
    'GET_ROLES',
  ],
  baseQuery: fetchBase(),
  endpoints: () => ({}),
})

export default api
