/* eslint-disable no-prototype-builtins */
import reactStringReplace from 'react-string-replace'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

import colors from 'utils/colors'
import { t } from 'i18next'
import { MESSAGES } from 'locales/locales'

const URL_CAPTURING_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

const PHONE_CAPTURING_REGEX =
  /(\b(?:0)?(?:4\d{2}|850|\d{3})\s?\d{3}\s?(?:\d{1}\s?)?\d{2}\s?\d{2}\b)|(\b850\d{7}\b)/g

export const updateContentText = (
  contentText,
  mentionedUsersId,
  searchTextInMessage,
  users,
  onShowUserInfo,
) => {
  contentText = reactStringReplace(contentText, URL_CAPTURING_REGEX, (match) => {
    const hasProtocol = match.includes('http') || match.includes('https')
    return (
      <span
        key={uuidv4()}
        style={{ cursor: 'pointer', color: colors.mainVimeMid }}
        onClick={() => window.open(`${hasProtocol ? match : 'https://' + match}`, '_blank')}
      >
        {match}
      </span>
    )
  })

  contentText = reactStringReplace(contentText, searchTextInMessage, (match) => {
    return (
      <span
        key={uuidv4()}
        style={{
          backgroundColor: colors.vime,
          borderRadius: 2,
          padding: '0px 4px',
          color: colors.white,
        }}
      >
        {match}
      </span>
    )
  })

  mentionedUsersId?.forEach((userId) => {
    contentText = reactStringReplace(contentText, `@&_id:${userId}&`, () => (
      <span
        key={uuidv4()}
        style={{ color: colors.vime, cursor: 'pointer', fontWeight: '600' }}
        onClick={() => onShowUserInfo?.(userId)}
      >
        @{users ? users[userId]?.fullname : 'Unknown'}
      </span>
    ))
  })

  contentText = reactStringReplace(contentText, PHONE_CAPTURING_REGEX, (match) => {
    return (
      <a
        key={uuidv4()}
        href={`tel:${match.replace(/[\s-]/g, '')}`}
        style={{ color: 'blue', textDecoration: 'none', fontWeight: 'bold' }}
      >
        {match}
      </a>
    )
  })

  return contentText
}

export const convertTextForInitialInputData = (inputData, users) => {
  const initialInputData = { ...inputData }
  let result = inputData.text

  initialInputData.mentionedUsers?.forEach((userId) => {
    const fullname = users && users[userId] ? users[userId].fullname : ''
    result = result.replaceAll(`@&_id:${userId}&`, `@[${fullname}]&_id:${userId}&`)
  })
  initialInputData.text = result

  return initialInputData
}

export const getInputValueByfullname = (inputData) => {
  const initialInputData = { ...inputData }
  /*
  inputData.mentionedUsers?.forEach((userId) => {
    const replacedText = initialInputData.text.replaceAll(`@[${users[userId]?.fullname}]`, `@`)
    initialInputData.text = replacedText
  })
  */
  const regex = /@\[(.*?)\]&_id:(.*?)&/g
  initialInputData.originalText = initialInputData.text
  initialInputData.text = initialInputData.text.replaceAll(regex, '@&_id:$2&')

  return initialInputData
}

export const parseUserDataFromInputData = (inputData) => {
  const regex = /@\[(.*?)\]&_id:(.*?)&/g
  
  let matches;
  const results = {};

  while ((matches = regex.exec(inputData.text)) !== null) {
    // The first capturing group is the full name, the second is the id
    const fullname = matches[1];
    const _id = matches[2];
    results[_id] = { _id, fullname };
  }

  return results;
}


export const geValueByfullname = (text, mentionedUsers, users) => {
  let value = text
  mentionedUsers?.forEach((userId) => {
    const fullname =
      users && users[userId] && users[userId]?.fullname ? users[userId]?.fullname : ''
    value = value.replaceAll(`@&_id:${userId}&`, `@${fullname}`)
  })
  return value
}

export const getSubsequentMessages = (messages) => {
  const previous = { sender: null, time: null }
  const result = messages.reverse().map((message) => {
    if (message.sender && message.sentAt) {
      const currentTime = new Date(message.sentAt)
      if (previous.sender === message.sender) {
        const timeDiff = currentTime - previous.time
        const oneMinute = 60 * 1000
        if (timeDiff <= oneMinute) {
          previous.sender = message.sender
          previous.time = currentTime
          return { ...message, subsequent: true }
        }
        previous.sender = message.sender
        previous.time = currentTime
        return message
      }
      previous.sender = message.sender
      previous.time = currentTime
      return message
    }
    return message
  })

  return result.reverse()
}

export const concatDaySeparator = (data) => {
  const dataWithDaySeparator = []
  data.reverse().forEach((message) => {
    if (
      dataWithDaySeparator.length !== 0 &&
      moment(dataWithDaySeparator[dataWithDaySeparator?.length - 1]?.createdAt).format('LL') !==
        moment(message.createdAt).format('LL')
    ) {
      dataWithDaySeparator.push({
        separator: true,
        description: moment(message.createdAt).format('LL'),
      })
    }
    dataWithDaySeparator.push(message)
  })

  return dataWithDaySeparator.reverse()
}

export const singleMessageConstructor = (messages, payload, withoutDaySep = false) => {
  const messagesWithoutSep = messages.filter((item) => item.hasOwnProperty('_id'))
  const copiedPayload = [{ ...payload }]
  const lastIndex = messagesWithoutSep.length - 1
  const lastMessage = { ...messagesWithoutSep.reverse()[lastIndex] }
  copiedPayload.push(lastMessage)
  const subsequentedData = getSubsequentMessages(copiedPayload)
  if (withoutDaySep) {
    subsequentedData.pop()
    return subsequentedData
  }
  const structeredMessages = concatDaySeparator(subsequentedData)
  structeredMessages.pop()
  return structeredMessages
}

export const concatNewMessagesSeparator = (data, unreadCount) => {
  if (unreadCount) {
    data?.splice(unreadCount, 0, {
      description: t(MESSAGES.NEW_MESSAGES),
      separator: true,
    })
  }
  return data
}

export const getPreparedMessages = (data, unreadCount) => {
  const dataWithSubsequentMessages = getSubsequentMessages(data)
  const dataWithDaySeparator = concatDaySeparator(dataWithSubsequentMessages)
  const dataWithNewMessagesSeparator = concatNewMessagesSeparator(dataWithDaySeparator, unreadCount)
  return dataWithNewMessagesSeparator
}
