import React, { FC, useState } from 'react'
import {
  Grid,
  GridColumn,
  GridToolbar,
  GridCellProps,
  GridItemChangeEvent,
  GridFilterChangeEvent,
} from '@progress/kendo-react-grid'
import { CommandCell } from './CommandCell'
import { addChange, addNew, discard, enterEdit, itemChange } from './GridServices'
import { cloneDeep } from 'lodash'
import { SelectionCell } from 'components/kendo/select/SelectionCell'

interface IGridData {
  loading?: boolean
  datasource: any
  onDataStateChange?: any
  dataState: any
  filterable?: boolean
  sortable?: boolean
  total?:number
  pageable?: boolean
  insertMode?: boolean
  editMode?: boolean
  key?:any
  selectionMode?: boolean
  selectedList?: any
  onInEditChange?: any
  onFilterChange?: (event: GridFilterChangeEvent) => void
  onChangeSelected?: any
  remove?: any
  update?: any
  selectedField?: string;
  add?: any
  selectable?: {
    enabled: boolean;
    drag: boolean;
    cell: boolean;
    mode: string;
  };
}

const KGrid: FC<React.PropsWithChildren<IGridData>> = ({
  children,
  loading,
  datasource,
  key,
  total,
  dataState,
  filterable = false,
  sortable = false,
  pageable = false,
  insertMode = false,
  editMode = false,
  selectionMode = false,
  selectedList,
  onDataStateChange,
  onInEditChange,
  onChangeSelected,
  onFilterChange,
  remove,
  update,
  add,
}) => {
  const [orginalDataSource, setOrginalDataSource] = useState([])

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Grid
          key={key}
          data={datasource}
          pageable={pageable}
          sortable={sortable}
          total={total}
          filterable={filterable}
          onFilterChange={onFilterChange}
          onDataStateChange={onDataStateChange}
          onItemChange={(e: GridItemChangeEvent) => onInEditChange(itemChange(datasource, e))}


          editField="inEdit"
          {...dataState}
        >
          {insertMode && (
            <GridToolbar>
              <button
                title="Add new"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                onClick={() => onInEditChange(addNew(datasource))}
              >
                Add new
              </button>
            </GridToolbar>
          )}

          {selectionMode && (
            <GridColumn
            filterable={false}
              title=""
              width="50px"
              cell={(e) => (
                <SelectionCell
                  isSelected={selectedList.some((dataItem: any) => dataItem._id === e.dataItem._id)}
                  onChangeSelected={(e2: any) => onChangeSelected(e.dataItem, e2.value)}
                />
              )}
            />
          )}

          {children &&
            datasource &&
            [children].map((child) => {
              return child
            })}

          {editMode && (
            <GridColumn
              cell={(props: GridCellProps) => (
                <CommandCell
                  {...props}
                  edit={(e: any) => {
                    onInEditChange(enterEdit(datasource, e._id, true))
                    setOrginalDataSource(cloneDeep(datasource))
                  }}
                  remove={(e: any) => {
                    remove({ ...e })
                  }}
                  add={async (e: any) => {
                    delete e.inEdit;
                    try {
                      const result = await add({ ...e });
                      if (result && result.data && result.data.insertedId) {
                        onInEditChange(addChange(datasource, result.data.insertedId));
                      } else {
                       //empty
                      }
                    } catch (error) {
                      // Hata durumunda ilgili işlemler
                    }
                  }}
                  discard={() => onInEditChange(discard(datasource))}
                  update={(e: any) => {
                    delete e.inEdit
                    update({ ...e })
                  }}
                  cancel={() => onInEditChange(orginalDataSource)}
                  editField={'inEdit'}
                />
              )}
              width="200px"
            />
          )}
        </Grid>
      )}
    </>
  )
}

export default KGrid
