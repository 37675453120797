import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { RoomTypes } from 'types/rooms.types'
import { BUTTONS, PLACEHOLDERS } from 'locales/locales'
import { MOBILE_WIDTH, SIDE_PANEL_PIN, SIDE_PANEL_SEARCH } from 'utils/constants'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import SearchInput from 'components/input/search-input/SearchInput'
import ThreadNotificationModal, {
  INotificationData,
} from 'components/modals/ThreadNotificationsModal'
import useThreadNotifications from 'utils/hooks/useThreadNotifications'
import { useAppSelector } from 'app/hooks'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import { IRolePermissions, MessagingPermissions, TeamAndChannelPermissions } from 'types/role.types'

interface IConversationHeaderProps {
  isMe?: boolean
  isDeleted?: boolean
  isPrivate?: boolean
  isMyRoom?: boolean
  type: RoomTypes
  url?: string
  roomName: string
  description: string
  pins: Array<string>
  searchText: string
  visibleSearchInput: boolean
  isVideoCall?: boolean
  notifications?: INotificationData[]
  roomId: string
  onStartOtr?: () => void
  onVideoCall?: () => void
  onOpenRoomInfo: () => void
  onChangeSearch: (text: string) => void
  onToggleSearchInput: (visible: boolean) => void
  onToggleConversationPanel: () => void
  onToggleConversationSidePanel: (visible: boolean, type: string) => void
}

const ConversationHeader = ({
  roomId,
  isMe,
  isMyRoom,
  isDeleted = false,
  isPrivate,
  type,
  url,
  roomName,
  description,
  pins,
  searchText,
  visibleSearchInput,
  isVideoCall = true,
  onStartOtr,
  onVideoCall,
  onOpenRoomInfo,
  onChangeSearch,
  onToggleSearchInput,
  onToggleConversationPanel,
  onToggleConversationSidePanel,
}: IConversationHeaderProps) => {
  const [visibleOption, setVisibleOption] = useState(false)

  const { t } = useTranslation()
  const users = useAppSelector((state) => state.users.data)

  const otrEnabled = useAppSelector((state) => state.system.data.features?.otrEnabled)
  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)

  const hasCreateVideoCall = isFlagSetIn(
    [MessagingPermissions.CREATE_MEETING_IN_MESSAGE], permissions.teamAndChannel);

  const hasCreateOTR = isFlagSetIn(
      [MessagingPermissions.START_OTR_MESSAGING], permissions.teamAndChannel);

      
  const {
    data,
    notificationState,
    onClickShowMore,
    onClickNotification,
    onToggleNotifications,
    onFetchInitialNotifications,
  } = useThreadNotifications({ currentRoomId: roomId })

  const onToggleVisible = () => {
    setVisibleOption(!visibleOption)
  }
  return (
    <Wrapper>
      <LeftWrapper>
        <SideBarToggleWrapper onClick={onToggleConversationPanel}>
          <CustomIcon source={icons.sidebarIcon} />
        </SideBarToggleWrapper>
        <RoomInfoWithAvatarWrapper isSearchOpen={visibleSearchInput}>
          {type !== RoomTypes.CM && (
            <Avatar
              className="header-avatar"
              text={type === RoomTypes.GM ? url : roomName}
              source={url}
              size={AvatarSize.large}
              onClick={type !== RoomTypes.OTR ? onOpenRoomInfo : undefined}
            />
          )}
          <RoomInfoWrapper>
            <RoomNameWrapper
              onClick={type !== RoomTypes.OTR ? onOpenRoomInfo : undefined}
              type={type}
            >
              {type === RoomTypes.CM && (
                <CustomIcon
                  iconPreset="grande"
                  source={isPrivate ? icons.lockIconBold : icons.channelIconBold}
                />
              )}
              <StyledText typography="header2">{roomName}</StyledText>
            </RoomNameWrapper>
            <DescriptionWrapper>
              {!!description && (
                <StyledText typography="body5" color={colors.gray60}>
                  {description}
                </StyledText>
              )}
              {type !== RoomTypes.OTR && (
                <PinWrapper onClick={() => onToggleConversationSidePanel(true, SIDE_PANEL_PIN)}>
                  <CustomIcon width="14px" height="14px" source={icons.pinIconSmall} />
                  {pins?.length !== 0 && (
                    <CustomText typography="body5" color={colors.gray60} cursor="pointer">
                      {pins?.length}
                    </CustomText>
                  )}
                </PinWrapper>
              )}
            </DescriptionWrapper>
          </RoomInfoWrapper>
        </RoomInfoWithAvatarWrapper>
      </LeftWrapper>

      {visibleSearchInput && type !== RoomTypes.OTR && (
        <StyledSearchInput
          isSearchForThread
          isOval
          value={searchText}
          placeHolder={t(PLACEHOLDERS.SEARCH_PLACEHOLDER)}
          onChange={onChangeSearch}
          onClickCancelButton={() => {
            onToggleSearchInput?.(false)
            onToggleConversationSidePanel(false, SIDE_PANEL_SEARCH)
          }}
        />
      )}
      <OptionContainer>
        <StyledIcon onClick={onToggleVisible} source={icons.threedotIcon}></StyledIcon>
        {visibleOption && (
          <OptionWrapper>
            {!visibleSearchInput && type !== RoomTypes.OTR && (
              <IconWithText
                onClick={() => {
                  onToggleSearchInput?.(true)
                  onToggleConversationSidePanel(true, SIDE_PANEL_SEARCH)
                  setVisibleOption(false)
                }}
              >
                <CustomIcon iconPreset="grande" source={icons.searchIconFilled} />
                <CustomText typography="body3">{t(BUTTONS.SEARCH)}</CustomText>
              </IconWithText>
            )}
            {isVideoCall && type !== RoomTypes.OTR && !isMyRoom && (
              <IconWithText
                onClick={() => {
                  onVideoCall && onVideoCall()
                  setVisibleOption(false)
                }}
              >
                <>
                  <CustomIcon iconPreset="grande" source={icons.cameraIconFilled} />
                  <CustomText typography="body3">{t(BUTTONS.VIDEO_CALL)}</CustomText>
                </>
              </IconWithText>
            )}
            {type !== RoomTypes.CM && type !== RoomTypes.OTR && !isMe && !isMyRoom && (
              <IconWithText
                onClick={() => {
                  onStartOtr && onStartOtr()
                  setVisibleOption(false)
                }}
              >
                <>
                  <CustomIcon iconPreset="grande" source={icons.otrIcon} />
                  <CustomText typography="body3">{t(BUTTONS.OTR_CALL)}</CustomText>
                </>
              </IconWithText>
            )}
            {type === RoomTypes.OTR && !isMyRoom && !isDeleted && (
              <IconWithText
                onClick={() => {
                  onStartOtr && onStartOtr()
                  setVisibleOption(false)
                }}
              >
                <>
                  <CustomIcon iconPreset="grande" source={icons.otrIconOff} onClick={onStartOtr} />
                  <CustomText typography="body3">{t(BUTTONS.CLOSE_OTR)}</CustomText>
                </>
              </IconWithText>
            )}
          </OptionWrapper>
        )}
      </OptionContainer>
      {visibleOption && <BackDrop onClick={() => setVisibleOption(false)} />}
      <RightWrapper visibleSearchInput={visibleSearchInput}>
        {notificationState.showNotifications && (
          <ThreadNotificationModal
            {...notificationState}
            data={data || []}
            title="Threads"
            onClickNotification={onClickNotification}
            onClickShowMore={onClickShowMore}
            onFetchInitialData={onFetchInitialNotifications}
          />
        )}
        {!isMyRoom && (
          <BellIconWrapper onClick={onToggleNotifications}>
            {notificationState.unreadCount > 0 && (
              <NotificationWrapper>
                <NotificationCount>{notificationState.unreadCount}</NotificationCount>
              </NotificationWrapper>
            )}
            <CustomIcon width="21" height="21" source={icons.bellIconMd} />
          </BellIconWrapper>
        )}
        {!visibleSearchInput && type !== RoomTypes.OTR && (
          <div>
            <CustomIcon
              iconPreset="grande"
              source={icons.searchIconFilled}
              onClick={() => {
                onToggleSearchInput?.(true)
                onToggleConversationSidePanel(true, SIDE_PANEL_SEARCH)
              }}
            />
          </div>
        )}
        {isVideoCall && type !== RoomTypes.OTR && !isMyRoom && hasCreateVideoCall && (
          <CustomIcon iconPreset="grande" source={icons.cameraIconFilled} onClick={onVideoCall} />
        )}
        {type !== RoomTypes.CM && type !== RoomTypes.OTR && !isMe && !isMyRoom && hasCreateOTR && otrEnabled && (
          <CustomIcon iconPreset="grande" source={icons.otrIcon} onClick={onStartOtr} />
        )}
        {type === RoomTypes.OTR && !isMyRoom && !isDeleted && hasCreateOTR && (
          <CustomIcon iconPreset="grande" source={icons.otrIconOff} onClick={onStartOtr} />
        )}
      </RightWrapper>
      {notificationState.showNotifications && <Backdrop onClick={onToggleNotifications} />}
    </Wrapper>
  )
}

export default memo(ConversationHeader)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 1.25rem 1.5rem 1.25rem 1rem;
  border-bottom: 1px solid ${colors.light80};
  justify-content: space-between;
  width: 100%;
  height: 72px;
  box-shadow: 4px 2px 6px rgba(0, 0, 0, 0.1);
  @media only screen and (min-width: ${MOBILE_WIDTH}px) {
    padding-left: 30px;
  }
`

const SideBarToggleWrapper = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  @media only screen and (min-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`
const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  flex-grow: 1;
  @media (max-width: 650px) {
    flex-grow: 0;
    gap: 16px;
  }
`
const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  gap: 16px;
  align-items: center;
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    /* display: ${({ visibleSearchInput }: { visibleSearchInput?: boolean }) =>
      visibleSearchInput ? 'none' : 'flex'}; */
    display: none;
  }
`

const PinWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
`

const RoomNameWrapper = styled.div`
  display: flex;
  align-items: center;
  /* width: fit-content; */
  margin-left: ${({ type }: { type?: RoomTypes }) => (type === RoomTypes.CM ? -10 : -4)}px;
  padding: ${({ type }: { type?: RoomTypes }) =>
    type === RoomTypes.CM ? '0px 5px 0px 0px' : '4px'};
  border-radius: 4px;
  cursor: ${({ type }: { type?: RoomTypes }) => (type === RoomTypes.OTR ? 'initial' : 'pointer')};
  &:hover {
    background-color: ${colors.light50};
  }
`

const RoomInfoWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
`

const RoomInfoWithAvatarWrapper = styled.div.attrs((props: { isSearchOpen: boolean }) => props)`
  display: flex;
  gap: 12px;
  @media (max-width: 550px) {
    display: ${({ isSearchOpen }) => (isSearchOpen ? 'none' : 'flex')};
    .header-avatar {
      display: none;
    }
  }
`

const StyledSearchInput = styled(SearchInput)`
  max-width: 300px;
  margin-right: 16px;
  @media (max-width: ${MOBILE_WIDTH - 300}px) {
    width: 100%;
  }
`

const OptionContainer = styled.div`
  position: relative;
`

const StyledIcon = styled(CustomIcon)`
  display: none;
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    display: block;
  }
`
const IconWithText = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 6px 10px;

  &:hover {
    background-color: ${colors.light50};
  }
  width: 100%;
  cursor: pointer;
`
export const OptionWrapper = styled.div`
  position: absolute;
  display: none;
  height: fit-content;
  width: 176px;
  border-radius: 8px;
  border: 1px solid ${colors.light80};
  right: 0;
  background-color: ${colors.white};
  z-index: 4;
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    /* display: ${({ visibleSearchInput }: { visibleSearchInput?: boolean }) =>
      visibleSearchInput ? 'none' : 'flex'}; */
    display: block;
  }
`

const BackDrop = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
`

const StyledText = styled(CustomText)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  @media (max-width: 500px) {
    max-width: 250px;
  }
  @media (max-width: 450px) {
    max-width: 200px;
  }
  @media (max-width: 400px) {
    max-width: 180px;
  }
  @media (max-width: 350px) {
    max-width: 130px;
  }
  @media (max-width: 300px) {
    max-width: 80px;
  }
`

const BellIconWrapper = styled.span`
  position: relative;
  z-index: 2;
`
const NotificationCount = styled.span`
  color: ${colors.white};
  font-size: 8px;
  font-weight: 600;
`
const NotificationWrapper = styled.span`
  background-color: ${colors.red};
  position: absolute;
  top: -5px;
  height: 16px;
  width: 16px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
`
