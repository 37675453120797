import moment from 'moment'
import styled from 'styled-components'

import colors from 'utils/colors'
import { MB } from 'utils/constants'
import { IFiles } from 'types/file.types'

import Image from './image/Image'
import Video from './video/Video'

import Audio from './audio/Audio'

import OtherFile from './other-file/OtherFile'
import FileModal from 'components/modals/FileModal'
import CustomText from 'components/text/CustomText'

interface ICustomFileProps {
  preset:
    | 'preview'
    | 'input'
    | 'files'
    | 'fullscreen'
    | 'post'
    | 'editPost'
    | 'postInput'
    | 'editPostResponsive'
  source: string
  mimetype: string
  sentAt: string
  fullname?: string
  filename?: string
  size?: number
  files?: IFiles
  index?: number
  fileKey?: string
  thumbnail?: string
}

const setVideoMimetype = (name: string, mimetype: string) => {
  if (name.split('.').at(-1) === 'mkv') {
    return 'video/webm'
  }

  return mimetype
}

const showRightContainerByMimetype = (mimetype: string) => {
  if (
    !mimetype?.includes('image') &&
    !mimetype?.includes('video') &&
    !mimetype?.includes('audio')
  ) {
    return true
  }
}

const showPreviewableByMimetype = (mimetype: string) => {
  if (mimetype?.includes('application/pdf')) {
    return true
  }
}

const CustomFile = ({
  preset,
  source,
  mimetype,
  sentAt,
  fullname,
  filename = '',
  size = 0,
  files,
  index = 0,
  thumbnail
}: ICustomFileProps) => {
  const onClickFile = () => {
    if (files) FileModal?.ref?.show(true, index, files)
  }

  if (preset === 'fullscreen' && showRightContainerByMimetype(mimetype)) {
    if (showPreviewableByMimetype(mimetype)) {
      return <iframe src={source} width="100%" height="100%"></iframe>
    }
  }

  const rightContainer = () => {
    const showRightContainer = showRightContainerByMimetype(mimetype)
    if (preset === 'files') {
      return (
        <SharedRightWrapper>
          <StyledText typography="header3">{filename}</StyledText>
          <StyledText typography="body6" color={colors.gray80}>
            Shared by {fullname} at {moment(sentAt).format('DD/MM/YYYY - HH:mm')}
          </StyledText>
        </SharedRightWrapper>
      )
    } else if (
      (preset === 'editPost' ||
        preset === 'post' ||
        preset === 'input' ||
        preset === 'preview' ||
        preset === 'fullscreen' ||
        preset === 'editPostResponsive' ||
        preset === 'postInput') &&
      showRightContainer
    ) {
      return (
        <MessageRightWrapper preset={preset}>
          <MessageDescriptionWrapper style={{ maxWidth: 135 }}>
            <StyledText typography="body7" textAlign="left">
              {filename}
            </StyledText>
            <StyledText typography="body7" textAlign="left" color={colors.gray80}>
              {(size / MB).toFixed(2) + ' MB'}
            </StyledText>
          </MessageDescriptionWrapper>
        </MessageRightWrapper>
      )
    }
  }

  const leftContainer = () => {
    return (
      <div style={{ maxHeight: '90vh', display: 'flex', justifyContent: 'center' }}>
        {mimetype?.includes('image') && <Image source={source} preset={preset} />}

        {mimetype?.includes('video') && (
          <Video source={source} type={setVideoMimetype(filename, mimetype)} preset={preset} thumbnail={thumbnail}/>
        )}
        {mimetype?.includes('audio') && (
          <Audio source={source}  />
        )}

        {!mimetype?.includes('image') && !mimetype?.includes('video') && (
          <OtherFile mimetype={mimetype} preset={preset} />
        )}
      </div>
    )
  }

  return (
    <Wrapper onClick={onClickFile}>
      {leftContainer()}
      {rightContainer()}
    </Wrapper>
  )
}

export default CustomFile

const Wrapper = styled.div.attrs((props: { preset: string }) => props)`
  display: contents;
  border: thin solid gray;
  cursor: ${({ preset }) => (preset !== 'input' ? 'pointer' : 'inherit')};
`

const SharedRightWrapper = styled.div`
  width: 93%;
`

const MessageRightWrapper = styled.div.attrs((props: { preset: string }) => props)`
  display: flex;
  align-items: ${({ preset }) => (preset === 'fullscreen' ? 'flex-start' : 'center')};
  flex: ${({ preset }) => (preset === 'fullscreen' ? '0' : '1')};
  justify-content: ${({ preset }) => (preset === 'fullscreen' ? 'center' : 'space-between')};
`

const MessageDescriptionWrapper = styled.div.attrs((props: { preset: string }) => props)`
  width: 135px;
`

const StyledText = styled(CustomText)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
