import { useState } from 'react'
import { IChannelMember } from 'types/user.types'
import {
  useCreateGroupMutation,
  useLazyGetMembersBySearchForGroupQuery,
} from 'app/services/group.service'
useLazyGetMembersBySearchForGroupQuery
import { FixMeLater } from 'types'
import CustomModal from 'components/modals/CustomModal'
import Members from 'components/members/Members'
import General from './General'
import { useTranslation } from 'react-i18next'
import { MODALS, PLACEHOLDERS, TABS } from 'locales/locales'
import { requiredValidation } from 'utils/helpers/validations'
import { useAppSelector } from 'app/hooks'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'

interface ICreateChannelModalProps {
  refetch: () => void
  onClose: () => void
}

const CreateGroupModal = ({ refetch, onClose }: ICreateChannelModalProps) => {
  const { t } = useTranslation()
  const [createGroup] = useCreateGroupMutation()
  const [getMembersBySearch] = useLazyGetMembersBySearchForGroupQuery()
  const [generalInfo, setGeneralInfo] = useState({ avatar: '', name: '', description: '' })
  const [members, setMembers] = useState<IChannelMember[]>([])
  const [membersBySearch, setMembersBySearch] = useState<IChannelMember[]>([])
  const [formChecked, setformChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const permissions = useAppSelector<IRolePermissions>(state => state.auth.permissions);
  const isAdmin = isFlagSetIn([TeamAndChannelPermissions.CREATE_OR_ARCHIVE_TEAM], permissions.teamAndChannel);

  const onCreateGroup = async () => {
    setformChecked(true)
    const { isValid: isValidName } = requiredValidation(generalInfo.name)

    if (!isValidName) return
    try {
      setIsLoading(true)
      const result: FixMeLater = await createGroup({
        members: members.map(({ _id }) => _id),
        name: generalInfo.name?.trim(),
        description: generalInfo.description?.trim(),
        imageUrl: generalInfo.avatar,
      })
      if (result?.data?.success) {
        onClose()
        refetch()
      }
    } finally {
      setIsLoading(false)
      setformChecked(false)
    }
  }

  const onChangeGeneralInfo = (key: string, value: string) => {
    setGeneralInfo({ ...generalInfo, [key]: value })
  }

  const onChangeGroupImage = async (fileUrl: string) => {
    setGeneralInfo({ ...generalInfo, avatar: fileUrl })
  }

  const onSelectMember = (user: IChannelMember) => {
    setMembers([...members, user])
  }

  const onSearch = async (text: string) => {
    try {
      const result: FixMeLater = await getMembersBySearch({ searchName: text })
      const filteredUser = result.data.data.filter((fetchedUser: IChannelMember) => {
        const findedMember = members.find((member) => member._id === fetchedUser._id)
        return findedMember ? false : true
      })
      setMembersBySearch(filteredUser)
    } catch {
      console.log('error')
    }
  }

  const onRemoveUser = (user: IChannelMember) => {
    const removedData = members.filter((member) => member._id !== user._id)
    setMembers([...removedData])
  }

  return (
    <CustomModal
      title={t(MODALS.CREATE_TEAM_TITLE)}
      onOk={onCreateGroup}
      closeModal={onClose}
      onCancel={onClose}
      loading={isLoading}
      hasMember
      tab={[{ title: t(TABS.GENERAL) }, { title: t(TABS.MEMBERS) }]}
    >
      <General
        {...generalInfo}
        formChecked={formChecked}
        onChange={onChangeGeneralInfo}
        onUploadGroupAvatar={onChangeGroupImage}
      />
      <Members
        isRemove
        isSearch
        isAddMember
        isAdmin
        data={members}
        isUserCardDisplayable={true}
        searchedData={membersBySearch}
        placeholder={t(PLACEHOLDERS.MEMBERS_PLACEHOLDER) as string}
        onSelectMember={onSelectMember}
        onChangeSearchText={onSearch}
        onRemoveUser={onRemoveUser}
      />
    </CustomModal>
  )
}

export default CreateGroupModal
