import { Dispatch } from 'react'
import { NavigateFunction } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Action, AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import {
  DestroyOtrEventPayload,
  OtrMessageEventPayload,
  OtrRequestedEventPayload,
  OtrRoomUpdatedEventPayload,
  OtrUserLeftRoomEventPayload,
} from 'types/otr.types'
import { FixMeLater } from 'types'
import { ERRORS } from 'locales/locales'
import { SECOND } from 'utils/constants'
import { otrActions } from 'app/slices/otr.slice'
import { roomsActions } from 'app/slices/rooms.slice'
import { MessageUserType } from 'types/conversation.types'
import { IRoom, RoomData, RoomTypes } from 'types/rooms.types'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'

export const otrEventHandlers = (
  currentUserId: string,
  rooms: RoomData,
  dispatch: ThunkDispatch<FixMeLater, undefined, AnyAction> & Dispatch<Action<FixMeLater>>,
  navigate: NavigateFunction,
) => {
  const { t } = useTranslation()
  // Find users own room.
  const navigateToMyRoom = (roomId: string) => {
    if (window.location.href.includes(roomId)) {
      const currentUsersRoomId = rooms.find(
        (room: IRoom) =>
          room.members.length === 1 && !room.invitedUsers && room.type !== RoomTypes.CM,
      )?._id
      navigate(`/messages/${currentUsersRoomId || ''}`, { replace: true })
    }
  }
  return {
    onDestroyOtrRoom: ({ message, roomId }: DestroyOtrEventPayload) => {
      // Show alert regarding the closing of the OTR room and delete it's data.
      DropdownAlert.ref.show({ message })
      // This update is needed to prevent user sending message after destroy event has been received.
      dispatch(
        roomsActions.updateConversation({
          _id: roomId,
          members: [currentUserId],
          addRoomIfNotExist: false,
          isDeleted: true,
        }),
      )
      // Remove OTR after 10 seconds.
      setTimeout(() => {
        navigateToMyRoom(roomId)
        dispatch(otrActions.deleteOtrRoom({ roomId }))
        dispatch(roomsActions.removeConversation({ _id: roomId }))
      }, 10 * SECOND)
    },
    onOtrRejected: ({ roomId }: { roomId: string }) => {
      navigateToMyRoom(roomId)
      dispatch(otrActions.deleteOtrRoom({ roomId }))
      dispatch(roomsActions.removeConversation({ _id: roomId }))
      DropdownAlert.ref.show({ isError: true, message: t(ERRORS.OTR_REJECTED_ERROR) })
    },
    onOtrMessage: ({
      message,
      members,
    }: {
      message: OtrMessageEventPayload
      members: Array<string>
    }) => {
      // show otr messages if user is a member of that room
      if (members.includes(currentUserId)) {
        dispatch(otrActions.sendOtrMessage(message))
      }
    },
    onUserLeavesOtrRoom: ({ roomId, userId }: OtrUserLeftRoomEventPayload) => {
      // Print leave message.
      dispatch(
        otrActions.sendOtrMessage({
          roomId,
          type: ['text'],
          sender: MessageUserType.SYSTEM,
          content: {
            mentionedUsers: [userId],
            text: `@&_id:${userId}& OTR_LEFT`,
          },
        }),
      )
    },
    onOtrRoomUpdated: ({ room, joinedUser }: OtrRoomUpdatedEventPayload) => {
      dispatch(roomsActions.updateConversation(room))
      dispatch(
        otrActions.createOtrRoom({
          roomId: room._id,
          timeout: null,
          requestSentAt: new Date().toISOString(),
        }),
      )
      // Print join message.
      if (joinedUser && joinedUser !== room.createdByUserId) {
        dispatch(
          otrActions.sendOtrMessage({
            type: ['text'],
            roomId: room._id,
            sender: MessageUserType.SYSTEM,
            content: {
              mentionedUsers: [joinedUser || currentUserId],
              text: `@&_id:${joinedUser || currentUserId}& OTR_JOINED`,
            },
          }),
        )
      }
    },
    onOtrRequest: ({ room }: OtrRequestedEventPayload) => {
      // Dispatch OTR room and its data in slice when invited.
      dispatch(roomsActions.createConversation(room))
      dispatch(
        otrActions.createOtrRoom({
          timeout: 1,
          roomId: room._id,
          requestSentAt: new Date().toISOString(),
        }),
      )
      dispatch(
        otrActions.sendOtrMessage({
          type: ['text'],
          roomId: room._id,
          sender: MessageUserType.SYSTEM,
          content: {
            mentionedUsers: [room.createdByUserId],
            text: `OTR_CREATED @&_id:${room.createdByUserId}&.`,
          },
        }),
      )
    },
    onDestroyOtrRoomImmediately: ({ roomId, message }: { roomId: string; message: string }) => {
      navigateToMyRoom(roomId)
      DropdownAlert.ref.show({ message })
      dispatch(otrActions.deleteOtrRoom({ roomId }))
      dispatch(roomsActions.removeConversation({ _id: roomId }))
    },
  }
}
