import { FixMeLater } from 'types'
import { createSlice } from '@reduxjs/toolkit'
import { systemService } from 'app/services/system.service'
import {cloneDeep} from "lodash"

interface System {
  data: {
    // _id: string
    name: string
    licence: string
    apiKey: string
    imageUrlFavicon: string
    imageUrlLogo: string
    features: {
      newsFeedEditAndDeleteEnabled:boolean,
      fcmDetailEnabled: boolean,
      videoCallEnabled:boolean,
      otrEnabled: boolean,
      groupWithMembers:boolean,
      isGroupMembersEnabled:boolean,
      captchaEnabled: boolean,
      passwordChangeEnabled: boolean,
      passwordResetEnabled: boolean,
      offlineModeEnabled: boolean,
      notificationSound: boolean
    }
  }
  passwordSettings: FixMeLater
}

const initialSystemState: System = {
  data: {
    // _id: '',
    name: '',
    licence: '',
    apiKey: '',
    imageUrlFavicon: '',
    imageUrlLogo: '',
    features: {
      newsFeedEditAndDeleteEnabled:false,
      fcmDetailEnabled: false,
      videoCallEnabled:false,
      isGroupMembersEnabled:true,
      groupWithMembers:true,
      otrEnabled: false,
      captchaEnabled: false,
      passwordChangeEnabled: false,
      passwordResetEnabled: false,
      offlineModeEnabled: false,
      notificationSound: true 
    },
  },
  passwordSettings: {},
}

export const { actions: systemActions, reducer: systemReducer } = createSlice({
  name: 'system',
  initialState: initialSystemState,
  reducers: {
    updateSystem: (state, action) => {
      const stateData = cloneDeep(state.data)
      state.data = {stateData, ...action.payload }
    },
    updatePasswordSettings: (state, action) => {
      state.passwordSettings = { ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(systemService.endpoints.getSystem.matchFulfilled, (state, { payload }) => {
      document.title = payload.data.name
      document.getElementById('favicon')?.setAttribute('href', `${payload.data.url}`)
      document.getElementById('login-logo')?.setAttribute('src', `${payload.data.logo}`)
      document.getElementById('login-logo')?.setAttribute('srcSet', `${payload.data.logo}`)
      state.data = payload.data
    })
  },
})
