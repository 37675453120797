import { useState } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { presets } from './presets'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import { customTextTypography } from 'components/text/customTextTypography'
import Dropdown from 'components/dropdown/Dropdown'
import { FixMeLater, IDropdownOption } from 'types'

export type TInputType = 'text' | 'number' | 'password' | 'email'

interface FormInputProps {
  title?: string
  optionalTitle?: string
  value?: string
  type?: TInputType
  preset?: 'small' | 'medium'
  placeholder?: string
  errorText?: string | null | boolean | JSX.Element
  errorColor?: string
  validationText?: string | null | boolean
  disabled?: boolean
  willError?: boolean
  dropDownData?: Array<FixMeLater>
  selectedOption?: FixMeLater
  onChange?: (value: FixMeLater) => void
}

interface IInputProps {
  isFocus?: boolean
  isError?: boolean
  disabled?: boolean
  fontSize?: number
  fontWeight?: number
  dropDownData?: FixMeLater

  lineHeight?: number
  padding?: string
  iconPosition?: string
  height?: string
}

const CustomFormInput = ({
  title,
  optionalTitle,
  value = '',
  type = 'text',
  placeholder,
  errorText,
  preset = 'small',
  errorColor = colors.light100,
  validationText,
  disabled = false,
  willError = true,
  dropDownData = [],
  selectedOption = {},
  onChange,
}: FormInputProps) => {
  const [isFocus, setFocus] = useState(false)
  const [inputType, setInputType] = useState(type)
  const onFocus = () => {
    setFocus(true)
  }

  const onBlur = () => {
    setFocus(false)
  }

  const onToggleVisible = () => {
    setInputType(inputType === 'text' ? 'password' : 'text')
  }
  
  return (
    <Wrapper>
      {title && (
        <TitleWrapper>
          <CustomText typography="header3">{title}</CustomText>
          <CustomText typography="body5" color={colors.gray60}>
            {optionalTitle}
          </CustomText>
        </TitleWrapper>
      )}
      {dropDownData.length > 0 ? (
        <StyledDropDown
          options={dropDownData}
          onChangeOption={(params: IDropdownOption) => onChange?.(params)}
          selectedOption={selectedOption}
        />
      ) : (
        <InputWrapper
          disabled={disabled}
          isFocus={isFocus}
          isError={!!errorText || errorColor === colors.red}
          {...presets[preset]}
        >
          <Input
            value={value}
            disabled={disabled}
            {...customTextTypography[preset === 'medium' ? 'body2' : 'body3']}
            placeholder={placeholder}
            type={inputType}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
          />
          {value?.length > 0 && type !== 'password' && type !== 'number' && isFocus && (
            <IconWrapper>
              <CustomIcon source={icons.closeIcon} onMouseDown={() => onChange?.('')} />
            </IconWrapper>
          )}
          {type === 'password' && (
            <IconWrapper>
              <CustomIcon
                source={inputType === 'password' ? icons.passwordEye : icons.passwordEyeSlach}
                onClick={onToggleVisible}
              />
            </IconWrapper>
          )}
          {type == 'number' && (
            <RightWrapper>
              <IconWrapper iconPosition="top">
                <CustomIcon
                  width="12px"
                  height="12px"
                  onClick={() => onChange?.((+value + 1).toString())}
                  source={icons.chevronUpIconSmall}
                />
              </IconWrapper>
              <IconWrapper iconPosition="bottom">
                <CustomIcon
                  width="12px"
                  height="12px"
                  onClick={() => onChange?.((+value - 1).toString())}
                  source={icons.chevronDownIconSmall}
                />
              </IconWrapper>
            </RightWrapper>
          )}
        </InputWrapper>
      )}

      {willError && (
        <ErrorText typography="body9" color={errorText ? colors.red : errorColor}>
          {errorText || validationText || ''}
        </ErrorText>
      )}
    </Wrapper>
  )
}

export default CustomFormInput

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: visible;
`

const TitleWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 8px;
`
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid
    ${(props: IInputProps) =>
      props.isError ? colors.red : props.isFocus ? colors.vime : colors.light80};
  border-radius: 8px;
  padding: ${(props: IInputProps) => props.padding};
  height: ${(props: IInputProps) => props.height};
  background-color: ${(props: IInputProps) => (props.disabled ? colors.light50 : colors.white)};
  box-shadow: 0px 0px 6px
    ${(props: IInputProps) => (props.isFocus && !props.isError ? colors.vime : 'transparent')};
  margin-bottom: 4px;
`

const Input = styled.input`
  width: 90%;
  font-size: ${(props: IInputProps) => props.fontSize}px;
  font-weight: ${(props: IInputProps) => props.fontWeight};
  line-height: ${(props: IInputProps) => props.lineHeight}px;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ErrorText = styled(CustomText)`
  min-height: 20px;
`
const IconWrapper = styled.div`
  cursor: pointer;
  margin: ${(props: IInputProps) =>
    props.iconPosition === 'top'
      ? '-12px 0 -3px 0px'
      : props.iconPosition === 'bottom'
      ? '-3px 0 -12px 0px'
      : '-5px 0 -8px 20px'};
`

const StyledDropDown = styled(Dropdown)`
  //todo
  position: absolute;
  width: 100%;
  z-index:1;
`
