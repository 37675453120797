import  { useState, useEffect } from 'react'

import SettingsHeader from 'features/settings/SettingsHeader'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { BUTTONS, TITLES } from 'locales/locales'
import '@progress/kendo-theme-default/dist/all.css'
import { GridColumn } from '@progress/kendo-react-grid'

import KGrid from 'components/kendo/grid/KGrid'
import {
  useAddRoleMutation,
  useDeleteRoleMutation,
  useLazyGetRolesQuery,
  useUpdateRoleMutation,
} from 'app/services/system.service'
import { Button } from '@progress/kendo-react-buttons'
import { useNavigate } from 'react-router-dom'

const RolesManagement = () => {
  const { t } = useTranslation()

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
  })
  const [roles, setRoles] = useState([])
  const [isModify, setIsModify] = useState(false)
  const [loading, setLoading] = useState(true)
  const [lazyGetRoles] = useLazyGetRolesQuery()
  const [updateRole] = useUpdateRoleMutation()
  const [deleteRole] = useDeleteRoleMutation()
  const [addRole] = useAddRoleMutation()
  const navigate = useNavigate()

  const getRoles = async () => {
    const responseRoles = await lazyGetRoles({
      ...dataState,
      sort: -1,
      count: true,
    })

    setRoles(responseRoles.data)
    setLoading(false)
  }

  const handleAddRole = async (roleData: any) => {
    try {
      const result = await addRole(roleData).unwrap()

      if (result) {
        getRoles()
      }
    } catch (error) {
      //error
    }
  }

  useEffect(() => {
    setLoading(true)
    getRoles()
  }, [dataState, isModify])

  const onDataStateChange = (e: any) => {
    setDataState(e.dataState)
  }

  const onInEditChange = (roles: any) => {
    setRoles(roles)
  }
  const remove = async (e: any) => {
    const result: any = await deleteRole(e)

    if (result.data?.data?.acknowledged) setIsModify(!isModify)
  }
  const onClickDetail = (roleId: any, type: string) => {
    navigate(`${type}/${roleId}`)
  }

  return (
    <Wrapper>
      <SettingsHeader title={t(TITLES.ROLE_SETTINGS_TITLE)} />

      <KGrid
        loading={loading}
        datasource={roles}
        onDataStateChange={onDataStateChange}
        dataState={dataState}
        pageable={true}
        editMode={true}
        insertMode={true}
        onInEditChange={onInEditChange}
        update={updateRole}
        remove={remove}
        add={handleAddRole}
      >
        <GridColumn field="_id" title="_id" editable={false} />
        <GridColumn field="name" title="name" />
        <GridColumn field="level" title="level" />
        <GridColumn
          field="detay"
          title="Detay"
          cell={(props) => (
            <td>
              {props.dataItem._id && (
                <>
                  <Button
                    onClick={() => onClickDetail(props.dataItem._id, 'users')}
                    themeColor={'primary'}
                    fillMode="outline"
                  >
                    {t(BUTTONS.USERS)}
                  </Button>
                  <Button
                    onClick={() => onClickDetail(props.dataItem._id, 'permissions')}
                    themeColor={'primary'}
                    fillMode="outline"
                    style={{marginLeft:20}}
                  >
                   {t(BUTTONS.PERMISSIONS)}
                  </Button>
                  <Button
                    onClick={() => onClickDetail(props.dataItem._id, 'messaging')}
                    themeColor={'primary'}
                    fillMode="outline"
                    style={{marginLeft:20}}
                  >
                   {t(BUTTONS.MESSAGING)}
                  </Button>
                </>
              )}
            </td>
          )}
        />
      </KGrid>
    </Wrapper>
  )
}

export default RolesManagement

const Wrapper = styled.div`
  /* margin-top: 40px; */
  padding: 0px 30px;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`
