import { useCallback, useEffect, useMemo, useState } from 'react'

import { SECOND } from 'utils/constants'
import { SOCKET } from 'app/socket/socketHelper'
import { useSocket } from './useSocket'
import { useAppSelector } from 'app/hooks'

export type TypingUser = { id: string; fullname: string; lastTypingTime: number }

interface IUseGetTyperProps {
  currentRoomId: string
  messageText: string
  openThreadId: string
}

type TypingEventData = {
  typingUser: TypingUser
  roomId: string
  threadId: string
}

export default function useGetTypingUsers({
  messageText,
  currentRoomId,
  openThreadId,
}: IUseGetTyperProps) {
  const [typingUsers, setTypingUsers] = useState<TypingUser[]>([])

  const socket = useSocket()

  const myId = useAppSelector((state) => state.auth.id)
  const users = useAppSelector((state) => state.users.data)

  let typingUserInterval: NodeJS.Timer

  const handlerFactory = useCallback(() => {
    return {
      onTyping: ({ typingUser, roomId, threadId }: TypingEventData) => {
        // Process data if typing event is coming from the room that is currently being viewed.
        if (typingUser.id !== myId) {
          if ((!threadId && roomId === currentRoomId) || (threadId && threadId === openThreadId)) {
            typingUser.lastTypingTime = Date.now()
            const userIndex = typingUsers.findIndex((user) => user.id === typingUser.id)

            if (userIndex !== -1) {
              typingUsers[userIndex].lastTypingTime = Date.now()
            } else {
              typingUsers.push(typingUser)
            }
            setTypingUsers([...typingUsers])
          }
        }
      },
    }
  }, [currentRoomId, openThreadId])

  useEffect(() => {
    setTypingUsers([])
    clearInterval(typingUserInterval)

    if (currentRoomId || openThreadId) {
      socket.on(SOCKET.USER_STARTED_TYPING, handlerFactory().onTyping)
      socket.on(SOCKET.USER_STARTED_TYPING_REPLY, handlerFactory().onTyping)
      typingUserInterval = setInterval(() => {
        typingUsers.forEach((user) => {
          if (Date.now() - user.lastTypingTime > SECOND * 5) {
            const userIndex = typingUsers.findIndex((u) => u.id === user.id)
            if (userIndex !== -1) {
              typingUsers.splice(userIndex, 1)
              setTypingUsers([...typingUsers])
            }
          }
        })
      }, SECOND * 5)
    }
  }, [currentRoomId, openThreadId])

  useEffect(() => {
    if (messageText?.length > 0) {
      socket.emit(SOCKET.TYPING_STARTED, {
        roomId: currentRoomId,
        isReplying: !!openThreadId,
        threadId: openThreadId,
      })
    }
  }, [messageText, currentRoomId, openThreadId])

  const getUsersOfTyping = useMemo(() => {
    return (
      typingUsers.map((userInfo) => userInfo.fullname || users?.[userInfo.id]?.fullname || '') || []
    )
  }, [typingUsers, users])

  return { usersOfTyping: getUsersOfTyping }
}
