import { memo } from 'react'

import Switch from 'components/switch/Switch'
import CustomText from 'components/text/CustomText'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { BUTTONS, FORM } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IGeneralProps {
  name: string
  description: string
  isPrivate: boolean
  onChange: (name: string, text: string | boolean) => void
}

const General = ({ name, description, isPrivate, onChange }: IGeneralProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <CustomFormInput
        value={name}
        title={t(FORM.CHANNEL_NAME)}
        onChange={(text: string) => onChange('name', text)}
      />
      <CustomFormInput
        value={description}
        title={t(FORM.CHANNEL_DESCRIPTION)}
        optionalTitle={t(FORM.OPTIONAL)}
        onChange={(text: string) => onChange('description', text)}
      />
      <PrivateWrapper>
        <CustomText typography="header3">{t(BUTTONS.PRIVATE)}</CustomText>
        <Switch
          checked={isPrivate}
          onChange={(checked: boolean) => onChange('isPrivate', !checked)}
        />
      </PrivateWrapper>
    </Wrapper>
  )
}

export default memo(General)

const Wrapper = styled.div``

const PrivateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
