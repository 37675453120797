/* eslint-disable no-prototype-builtins */
import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../app/hooks'

import { FixMeLater } from 'types'
import uploadApi from 'app/axiosApi'
import { UserStatus } from 'types/user.types'
import { usersActions } from 'app/slices/users.slice'
import { useLazyGetRoomsQuery } from 'app/services/room.service'
import { useUpdateUserLanguageMutation } from 'app/services/user.service'
import useHandleNotification from 'utils/hooks/useHandleNotification'
// import { useGetAllMessagesMutation } from 'app/services/conversation.service'

import NavigationBar from 'components/navigation-bar/NavigationBar'
import { MainLayout } from './rStyled'
import {
  useLazyGetRealNotificationCountQuery,
  useLazyGetUnreadPostsQuery,
  useLazyGetPaginatedPostsQuery,
  useLazyUnreadPostNotificationQuery,
} from 'app/services/post.service'
import { useLazyGetChannelsQuery } from 'app/services/group.service'
//import { useGetUserConferencesByUserIdMutation } from 'app/services/conference.service'
import { conferenceActions } from 'app/slices/conference.slice'
import { otrActions } from 'app/slices/otr.slice'
import { useSocket } from 'utils/hooks/useSocket'
import { newsFeedActions } from 'app/slices/news-feed.slice'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import DropdownSocket from 'components/dropdown-socket/DropdownSocket'
import { useLazyGetReconnectedMessagesQuery } from 'app/services/conversation.service'
import { conversationActions } from 'app/slices/conversations.slice'
import { useLazyGetSystemQuery } from 'app/services/system.service'
import { IRolePermissions, NewsFeedPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'

export default function ProtectedMainLayout() {
  const [didFetchAllData, setDidFetchAllData] = useState(false)
  const [reconnectionAttemptCount, setReconnectionAttemptCount] = useState(0)
  const dispatch = useAppDispatch()
  const socket = useSocket()

  const { id: myId = '', token = '' } = useAppSelector((state) => state.auth)
  const messagesData = useAppSelector((state) => state.message.data)
  const { screenHeight } = useWindowDimensions()

  const [getRooms]: FixMeLater = useLazyGetRoomsQuery()
  const [getChannels]: FixMeLater = useLazyGetChannelsQuery()
  const [getPosts] = useLazyGetPaginatedPostsQuery()
  const [getUnreadPosts] = useLazyGetUnreadPostsQuery()
  const [getNewsFeedNotifCount] = useLazyGetRealNotificationCountQuery()
  const [getSystemSettings] = useLazyGetSystemQuery()

  const [
    getUnreadNotificatonCount,
    { data: unreadNotificationData, isSuccess: isGetUnreadNotificationSuccess },
  ] = useLazyUnreadPostNotificationQuery()
  // const [getMyConferences, { data, isSuccess: getConferences }] =
  //   useGetUserConferencesByUserIdMutation()

  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)

  const hasViewNewsFeed = isFlagSetIn([NewsFeedPermissions.VIEW_NEWSFEED], permissions.newsFeed)

  useHandleNotification()

  const [updateUserLanguage] = useUpdateUserLanguageMutation()
  const [getReconnectedMessages] = useLazyGetReconnectedMessagesQuery()

  const myLocation = location.pathname.split('/')?.[1]
  const roomId = location.pathname.split('/')?.[2]

  useEffect(() => {
    updateUserlang()

    const handleBeforeUnload = () => {
      if (socket && socket.connected) {
       // socket.disconnect()
       // socket.io.engine.close(); 
        console.log("socket disconnected")
      }
    }
    
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === 'hidden') {
        handleBeforeUnload()
      }
    });

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('languagechange', updateUserlang)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('visibilitychange', handleBeforeUnload)
      window.removeEventListener('languagechange', updateUserlang)
    }
  }, [])

  useEffect(() => {
    const vh = screenHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }, [screenHeight])

  useEffect(() => {
    if (reconnectionAttemptCount >= 3) {
      DropdownSocket.ref.show()
    }
  }, [reconnectionAttemptCount])

  useEffect(() => {
    if (!token) return
    _getAllInitials()
    async function _getAllInitials() {
      uploadApi.setHeader(token, myId)
      dispatch(newsFeedActions.postLoading(true))
      if (hasViewNewsFeed) {
        await getPosts({ page: 0 })
      }
      try {
        const [roomsResponse] = await Promise.all([
          getRooms(),
          getChannels(),
          getUnreadNotificatonCount({}),
          getNewsFeedNotifCount({}),
          //  getMyConferences({ userId: myId }),
          getSystemSettings({}),
        ])
        if (roomsResponse.isSuccess) {
          const otrRooms = roomsResponse.data.data.filter((room: FixMeLater) => room.invitedUsers)
          otrRooms.forEach((room: FixMeLater) => {
            dispatch(
              otrActions.createOtrRoom({
                timeout: null,
                roomId: room._id,
                requestSentAt: room.createdAt,
              }),
            )
          })
          socket.emit('join-otr-rooms', { data: otrRooms })
        }
      } catch (error) {
        console.error('Initial Error:', error)
      } finally {
        onFetchAllData()
      }
    }
    return () => {
      socket.off('join-otr-rooms')
    }
  }, [token])

  useEffect(() => {
    socket.io.on('reconnect', async () => {
      DropdownSocket.ref.hide()
      setReconnectionAttemptCount(0)
      console.log('re-connected again...')
      if (myLocation === 'channels' || myLocation === 'messages') {
        const messages = messagesData?.[roomId]?.messages || []
        const myLastMessageDate =
          messages.filter((item: FixMeLater) => item.hasOwnProperty('_id'))?.[0]?.createdAt || ''
        const {
          data: { data },
        }: any = await getReconnectedMessages({ roomId, createdAt: myLastMessageDate })
        dispatch(conversationActions.getReconnectedMessages({ roomId, data }))
        dispatch(conversationActions.exceptionalClearAllMessages({ roomId }))
        dispatch(newsFeedActions.clearAllPost())
      }
      if (myLocation === 'news-feed') {
        dispatch(conversationActions.clearAllMessages())
        const { data }: FixMeLater = await getUnreadPosts({})
        dispatch(newsFeedActions.addUnreadPost(data))
      }
    })

    if (token) {
      socket.on('connect_error', () => {
        setReconnectionAttemptCount((prevCount) => ++prevCount)
      })
    }

    return () => {
      socket.io.off('reconnect')
      socket.off('connect_error')
    }
  }, [messagesData, roomId, myLocation])

  useEffect(() => {
    //if (isSuccess) {
    //if (isSuccess) {
    dispatch(usersActions.updateUsersStatus({ userId: myId, status: UserStatus.ONLINE }))
    //}
    //if (getConferences) {
    //  dispatch(conferenceActions.fillInitialState(data.data))
    //}
    if (isGetUnreadNotificationSuccess) {
      dispatch(newsFeedActions.setInitialUnreadPostCount({ count: unreadNotificationData.data }))
    }
  }, [isGetUnreadNotificationSuccess])

  if (!token) return <Navigate to="/login" />

  function onFetchAllData() {
    dispatch(newsFeedActions.resetUnreadPostCount())
    setDidFetchAllData(true)
    dispatch(newsFeedActions.postLoading(false))
  }

  function updateUserlang() {
    if (token) {
      const language = navigator.language.split('-')[0]
      updateUserLanguage({
        lang: language || 'en',
      })
    }
    return
  }

  return (
    <MainLayout>
      <NavigationBar />
      <Outlet context={{ didFetchAllData }} />
    </MainLayout>
  )
}
