import { useMemo } from 'react'
import styled from 'styled-components'

import { presets } from '../presets'
import { getFileIcon } from 'components/file/fileHelper'

import CustomIcon from 'components/icon/CustomIcon'

interface IOtherFileProps {
  mimetype: string
  preset:
    | 'preview'
    | 'input'
    | 'files'
    | 'fullscreen'
    | 'post'
    | 'editPost'
    | 'postInput'
    | 'editPostResponsive'
}

const OtherFile = ({ mimetype, preset }: IOtherFileProps) => {
  const icon = useMemo(() => getFileIcon(mimetype), [mimetype])
  return <StyledOtherFile source={icon} {...presets[preset].other} preset={preset} />
}

export default OtherFile

const StyledOtherFile = styled(CustomIcon).attrs(
  (props: { preset: string; maxWidth: string }) => props,
)`
  width: ${({ preset, maxWidth }) => (preset === 'files' ? maxWidth : 'auto')};
  object-fit: none;
`
