import { useEffect, useState } from 'react'
import WaveSurfer from 'wavesurfer.js'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import colors from 'utils/colors'
import { icons } from 'utils/icons'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

interface Props {
  url: string
}

const secondsToHms = (d: number) => {
  const m = Math.floor((d % 3600) / 60)
  const s = Math.floor((d % 3600) % 60)

  const mDisplay = m > 0 && m < 10 ? m : m
  const sDisplay = s >= 0 && s < 10 ? '0' + s : s
  return { mDisplay, sDisplay }
}

const Waveform = ({ url }: Props) => {
  const [playing, setPlaying] = useState(false)
  const [finish, setFinish] = useState(false)
  const [waveform, setWaveform] = useState<WaveSurfer | null>()
  const uniqueId = uuidv4()

  useEffect(() => {
    const waveform = WaveSurfer.create({
      barGap: 2,
      height: 50,
      barWidth: 3,
      barRadius: 3,
      cursorWidth: 1,
      barMinHeight: 2,
      responsive: true,
      backend: 'WebAudio',
      progressColor: '#3d4f94',
      container: `#abc${uniqueId}`,
      waveColor: `${colors.mainVime}`,
      cursorColor: 'transparent',
      xhr: {
        credentials: 'include',
      },
    })
    waveform.on('finish', () => {
      setFinish(true)
      setPlaying(false)
    })
    waveform.load(url)
    setWaveform(waveform)
  }, [])

  const handlePlay = () => {
    setPlaying(!playing)
    setFinish(false)
    waveform?.playPause()
  }

  const { mDisplay, sDisplay } = secondsToHms(
    waveform?.getDuration ? Math.floor(waveform.getDuration()) : 0,
  )

  return (
    <WaveformContianer>
      <PlayButton onClick={handlePlay}>
        {(!playing || finish) && (
          <CustomIcon iconPreset="large" source={icons.sendActiveRecordPlay} />
        )}
        {playing && !finish && (
          <CustomIcon iconPreset="large" source={icons.sendActiveRecordPause} />
        )}
      </PlayButton>
      <Wave id={`abc${uniqueId}`} style={{ zIndex: 0 }} />
      <Timer>
        <CustomText typography="body7" color={colors.gray80}>
          {`${mDisplay}:`}
        </CustomText>
        <CustomText typography="body7" color={colors.gray80}>
          {`${sDisplay}`}
        </CustomText>
      </Timer>
    </WaveformContianer>
  )
}
export default Waveform

const Timer = styled.div`
  width: 28px;
  height: 12px;
  display: flex;
  align-self: center;
  flex-shrink: 0;
`
const WaveformContianer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 212px;
  background: transparent;
  border-radius: 10px;
  gap: 10px;
  border: 1px solid ${colors.gray60};
  padding: 0px 5px;
`

const Wave = styled.div`
  width: 100%;
  height: 100%;
`
const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  outline: none;
  cursor: pointer;
  padding-bottom: 3px;
`
