import { useEffect, useState } from 'react'

const requestRecorder = async () => {
  const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
  return new MediaRecorder(stream) //recorder= start(),stop(),pause() work with this form
}

const useAudioRecord = ({ onUploadAudio }: { onUploadAudio?: (audioData: Blob) => void }) => {
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null)
  const [audioData, setAudioData] = useState<Blob | null>(null)
  const [recordingStatus, setRecordingStatus] = useState('')
  const [timer, setTimer] = useState(0)

  useEffect(() => {
    // Lazily obtain recorder first time we're recording.
    if (recorder === null) {
      if (recordingStatus === 'recording') {
        requestRecorder().then(setRecorder, console.error)
      }
      return
    }
    // Manage recorder state.
    if (recordingStatus === 'recording') {
      recorder.start()
    } else if (recordingStatus === 'save') {
      recorder.stop()
      recorder?.stream
        .getTracks() // get all tracks from the MediaStream
        .forEach((track: MediaStreamTrack) => track.stop()) // stop each of them
    } else if (recordingStatus === 'cancel') {
      recorder.stop()
      recorder?.stream
        .getTracks() // get all tracks from the MediaStream
        .forEach((track: MediaStreamTrack) => track.stop()) // stop each of them
      setRecorder(null)
      setAudioData(null)
    }

    // Obtain the audio when ready.
    const handleData = (e: BlobEvent) => {
      setAudioData(e.data)
    }

    recorder.addEventListener('dataavailable', handleData)
    return () => recorder.removeEventListener('dataavailable', handleData)
  }, [recorder, recordingStatus])

  const startRecording = () => {
    setRecordingStatus('recording')
  }

  const cancelRecording = () => {
    setRecordingStatus('cancel')
  }

  const stopRecording = () => {
    setRecordingStatus('save')
  }

  useEffect(() => {
    let counter: NodeJS.Timer | undefined
    if (recordingStatus === 'recording') {
      setTimer(0)
      counter = setInterval(() => {
        setTimer((prevState) => prevState + 1)
      }, 1000)
    } else {
      clearInterval(counter)
      setTimer(0)
    }

    return () => {
      clearInterval(counter)
      setTimer(0)
    }
  }, [recordingStatus])

  useEffect(() => {
    if (recordingStatus === 'save' && audioData) {
      setAudioData(null)
      setRecorder(null)
      onUploadAudio?.(audioData)
    } else if (recordingStatus === 'cancel') {
      setAudioData(null)
      setRecorder(null)
    }
  }, [audioData, recordingStatus])

  return {
    timer,
    recorder: recorder?.stream, //MediaStream
    status: recordingStatus,
    startRecording,
    cancelRecording,
    stopRecording,
  }
}

export default useAudioRecord
