import styled from 'styled-components'
import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import { useTranslation } from 'react-i18next'
import { MESSAGES } from 'locales/locales'

const ReadOnly = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <CustomText typography="body2" textAlign="center">
        {t(MESSAGES.READ_ONLY_DESCRIPTION)}
      </CustomText>
    </Wrapper>
  )
}

export default ReadOnly

const Wrapper = styled.div`
  display: flex;
  height: 120px;
  border-top: 1px solid ${colors.light80};
  align-items: center;
  justify-content: center;
  column-gap: 15px;
`
