import { useEffect } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { FixMeLater } from 'types'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
interface Recorder {
  timer: number
  onCancel?: () => void
  stream?: MediaStream
  onSave?: () => void
}

const Recorder = ({ onCancel, timer, stream, onSave }: Recorder) => {
  const obj: FixMeLater = {}

  const init = () => {
    obj.canvas = document.getElementById('canvas')
    obj.ctx = obj.canvas.getContext('2d')
    obj.width = 140
    obj.height = 40
    obj.canvas.width = obj.width * window.devicePixelRatio
    obj.canvas.height = obj.height * window.devicePixelRatio
    obj.canvas.style.width = obj.width + 'px'
    obj.canvas.style.height = obj.height + 'px'
    obj.ctx.scale(window.devicePixelRatio, window.devicePixelRatio)
  }

  useEffect(() => {
    if (stream) {
      soundAllowed(stream)
    }
  }, [stream])

  const timeOffset = 100
  let now = performance.now() / timeOffset

  const loop = () => {
    obj.ctx.clearRect(0, 0, obj.canvas.width, obj.canvas.height)
    obj.analyser.getByteFrequencyData(obj.frequencyArray)
    let max = 40

    if (performance.now() / timeOffset > now) {
      now = performance.now() / timeOffset
      for (let i = 0; i < obj.frequencyArray.length; i++) {
        if (obj.frequencyArray[i] > max) {
          max = obj.frequencyArray[i]
        }
      }

      const freq = Math.floor(max * 1.6)

      obj.bars.push({
        x: obj.width,
        y: obj.height / 1 - freq / 10,
        height: max,
        width: 2,
      })
    }
    draw()
    requestAnimationFrame(loop)
  }
  obj.bars = []

  const draw = () => {
    for (let i = 0; i < obj.bars.length; i++) {
      const bar = obj.bars[i]
      obj.ctx.fillStyle = `${colors.mainVime}`
      obj.ctx.fillRect(bar.x, bar.y, bar.width, bar.height)
      bar.x = bar.x - 4

      if (bar.x < 1) {
        obj.bars.splice(i, 1)
      }
    }
  }

  const soundAllowed = (stream: MediaStream) => {
    const AudioContext = window.AudioContext
    const audioContent = new AudioContext()
    const streamSource = audioContent.createMediaStreamSource(stream)

    obj.analyser = audioContent.createAnalyser()
    streamSource.connect(obj.analyser)
    obj.analyser.fftSize = 512
    obj.frequencyArray = new Uint8Array(obj.analyser.frequencyBinCount)
    init()
    loop()
  }

  const secondsToHms = (d: number) => {
    const m = Math.floor((d % 3600) / 60)
    const s = Math.floor((d % 3600) % 60)

    const mDisplay = m > 0 && m < 10 ? m : m
    const sDisplay = s >= 0 && s < 10 ? '0' + s : s
    return { mDisplay, sDisplay }
  }

  const { mDisplay, sDisplay } = secondsToHms(timer)

  return (
    <RecordWrapper>
      <RecordCloseIcon onClick={onCancel}>
        <CloseIcon iconPreset="small" source={icons.closeIconWhite} />
      </RecordCloseIcon>
      <Canvas id="canvas" />
      <CanvasRigthSide>
        <TimeWrapper>
          <CustomText typography="body7" color={colors.gray80}>
            {`${mDisplay}:`}
          </CustomText>
          <CustomText typography="body7" color={colors.gray80}>
            {`${sDisplay}`}
          </CustomText>
        </TimeWrapper>
        <div style={{ cursor: 'pointer', alignSelf: 'center' }} onClick={onSave}>
          <CustomIcon iconPreset="large" source={icons.sendActiveRecordPause} />
        </div>
      </CanvasRigthSide>
    </RecordWrapper>
  )
}

export default Recorder

const RecordWrapper = styled.div`
  height: 50px;
  width: 218px;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  align-self: flex-start;
  border: 1px solid ${colors.gray60};
  gap: 3px;
`
const RecordCloseIcon = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  cursor: pointer;
`

const CanvasRigthSide = styled.div`
  display: flex;
  position: absolute;
  right: 8px;
  top: 8px;
`

const TimeWrapper = styled.div`
  width: 28px;
  height: 12px;
  display: flex;
  align-self: center;
`

const CloseIcon = styled(CustomIcon).attrs((props: { isCancel: boolean }) => props)`
  flex-shrink: 0;
  background-color: ${colors.vime};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const Canvas = styled.canvas`
  flex-grow: 1;
  margin: auto;
  position: relative;
`
