import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UploadedFile } from 'types/file.types'

interface Upload {
  queue: Array<UploadedFile>
  progress: number | null
  title: string | null
}

const initialUploadState: Upload = {
  queue: [],
  progress: null,
  title: null,
}

export const { actions: uploadActions, reducer: uploadReducer } = createSlice({
  name: 'upload',
  initialState: initialUploadState,
  reducers: {
    addQueue(state, { payload }: PayloadAction<UploadedFile>) {
      state.queue.push(payload)
    },
    setProgressInQueue(state, { payload }: { payload: number }) {
      state.progress = payload
    },
    removeFromQueue(state, { payload }) {
      const fileIndex = state.queue.findIndex(
        (file: UploadedFile) => file.uniqueId === payload.uniqueId,
      )
      if (fileIndex === -1) {
        return
      }
      state.queue.splice(fileIndex, 1)
    },
  },
})
