import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FixMeLater } from 'types'
import { TABS } from 'locales/locales'
import { IChannelMember } from 'types/user.types'
import { useLazyGetGroupInfoByGroupIdQuery } from 'app/services/group.service'

import General from './General'
import Members from 'components/members/Members'
import CustomModal from 'components/modals/CustomModal'

interface ICreateUserModalProps {
  groupId: string
  onClose: () => void
}

const GroupInfoModal = ({ groupId, onClose }: ICreateUserModalProps) => {
  const { t } = useTranslation()
  const [getGroupInfoByGroupId, { data }] = useLazyGetGroupInfoByGroupIdQuery()
  const [generalInfo, setGeneralInfo] = useState({ ...data })
  const [members, setMembers] = useState<IChannelMember[]>([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchGroupInfo()
  }, [])

  const fetchGroupInfo = async () => {
    try {
      const result: FixMeLater = await getGroupInfoByGroupId({ groupId })
      setGeneralInfo(result.data.team)
      setMembers(result.data.team.members)
    } catch {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <CustomModal
      closeModal={onClose}
      title={generalInfo.name}
      tab={[{ title: t(TABS.GENERAL) }, { title: t(TABS.MEMBERS) }]}
      showButtons={false}
      loading={isLoading}
      hasMember
    >
      <General {...generalInfo} />
      <Members data={members} />
    </CustomModal>
  )
}

export default GroupInfoModal
