import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useAppSelector } from 'app/hooks'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { IUser, IUserData } from 'types/user.types'
import Actions from '../_components/actions/Actions'
import { formatPostDate } from 'utils/helpers/postHelper'
import { newsFeedActions } from 'app/slices/news-feed.slice'

import Content from './content/Content'
import Spinner from 'components/spinner/Spinner'
import PostHeader from '../_components/post-header/PostHeader'
import { useCreateConversationMutation } from 'app/services/room.service'
import UserCard from 'components/new-message/user-card/UserCard'
import Loading from 'components/spinner/Loading'
import { useNavigate } from 'react-router-dom'
import { BUTTONS, MODALS, TABS } from 'locales/locales'
import useInfoModal from 'utils/hooks/useInfoModal'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'
import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO
} from 'utils/constants'
import { t } from 'i18next'
import UserGroupsModal from 'features/channels/userGroupModal'


interface IPostProps {
  isPostDetail?: boolean
  postId: string
  commentToggle: boolean
  data: {
    header?:string
    text: string
    files: FixMeLater[]
    mentionedUsers: Array<string>
  }
  creatorInfo: IUser
  sentAt: string
  myId: string
  likes: Array<string>
  dislikes: Array<string>
  commentCount: number
  users: IUserData
  isPostLoading?: boolean
  status?: string
  onShowReactions: (postId: string) => void
  onDeletePost?: (postId: string) => void
  onShowEditPostModal?: () => void
  onNavigateToPostDetail?: (postId: string) => void
  onReactionPost: (postId: string, isLike: boolean) => void
}

const Post = ({
  postId,
  data,
  creatorInfo,
  sentAt,
  myId,
  likes,
  dislikes,
  commentCount,
  users,
  status,
  isPostDetail,
  commentToggle,
  onShowReactions,
  onDeletePost,
  onShowEditPostModal,
  onNavigateToPostDetail,
  onReactionPost,
}: IPostProps) => {
  const navigate = useNavigate()
  const commentData = useAppSelector((state) => state.newsFeed.commentInputData)
  const dispatch = useDispatch()
  const [createConversation] = useCreateConversationMutation()
  const [windowApi,setWindowApi] = useState<FixMeLater>(null);
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [selectedUserName, setSelectedUserName] = useState("");
  const [isUserGroupsModalVisible, setIsUserGroupsModalVisible] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [visibleUserCard, setVisibleUserCard] = useState<{
    visible: boolean
    userId: string | null
    fullname:string | null
  }>({ visible: false, userId: null , fullname:null})
  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()

  useEffect(() => {
    if (!commentData[postId]) {
      dispatch(newsFeedActions.setInitialCommentData({ postId }))
    }
  }, [postId])



  const onUsersGrup = (userId: string, fullname: string) => {
    setIsUserGroupsModalVisible(true);
    setSelectedUserId(userId);
    setSelectedUserName(fullname);  
  };
  
  const closeUserGroupsModal = () => {
    setIsUserGroupsModalVisible(false);
  };

  
  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && visibleUserCard.visible) {
      setVisibleUserCard({ visible: false, userId: null, fullname:null })
    }
  }
  const onVisibleUserCard = useCallback((userId?: string, fullname?: string) => {
    const safeUserId = userId ?? null;
    const safeFullname = fullname ?? null;
    if (safeUserId || safeFullname) {
      setVisibleUserCard({ visible: true, userId: safeUserId, fullname: safeFullname });
    }
  }, []);

  const onClickBackDrop = () => {
    setVisibleUserCard({ visible: false, userId: null, fullname:null })
  }

  const onVideoCall = async () => {
    openConferenceModal({
      title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
      description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeConferenceModal,
      onOk: () => handleChatAndConference(CONFERENCE_TYPE_VIDEO)
    })
  
}
const creatingConference = async (conferenceType: string, roomId?: string) => {
  try {
    Loading.ref.show();
    const data = await createConference({
      roomId: roomId, 
      type: 'single',
      conferenceType: conferenceType,
    });
    if (windowApi) {
      windowApi.send('openConferenceUrl', { url: data.data?.url });
    } else {
      window.open(data.data?.url, '', CONFERENCE_BLANK_DIMENSIONS);
    }
  } finally {
    Loading.ref.hide();
    closeConferenceModal();
  }
};


const onClickChatIcon = async (): Promise<string | null> => {
  try {
    Loading.ref.show();
    const result = await createConversation({ members: [visibleUserCard.userId]});
    if (!result.error) {
      const roomId = typeof result.data.data === 'string' ? result.data.data : result.data.data._id;
      navigate('/messages/' + roomId);
      return roomId; 
    }
  } catch (error) {
    console.error('Error in creating or fetching conversation:', error);
  } finally {
    Loading.ref.hide();
  }
  return null;  
};


const handleChatAndConference = async (conferenceType: string) => {
  const roomId = await onClickChatIcon();
  if (roomId) {
    await creatingConference(conferenceType, roomId);
  } else {
    console.error('Failed to get room ID');
  }
};

  return (
    <WrapperWithSpinner>
      <Spinner position="absolute" loading={status === 'pending'} />
      <Wrapper isLoading={status === 'pending'}>
        <PostHeader
          postId={postId}
          avatar={creatorInfo?.avatar}
          fullname={creatorInfo?.fullname}
          team={creatorInfo?.team}
          creatorId={creatorInfo?._id}
          isPostDetail={isPostDetail}
          date={formatPostDate(moment(sentAt))}
          isOption={myId === creatorInfo?._id}
          onUpdate={onShowEditPostModal}
          onDelete={onDeletePost}
        />
        <Content data={data} users={users} onShowUserInfo={onVisibleUserCard} isCollapsible={true} />
        <Actions
          postId={postId}
          myId={myId}
          likes={likes}
          dislikes={dislikes}
          commentCount={commentCount}
          commentToggle={commentToggle}
          onShowReactions={onShowReactions}
          onClickComment={onNavigateToPostDetail}
          onReactionPost={onReactionPost}
        />
        {visibleUserCard.visible && visibleUserCard.userId && users[visibleUserCard.userId] && (
          <UserCard
            showChatIcon={visibleUserCard.userId !== myId}
            showCallIcon={false}
            showCameraIcon={visibleUserCard.userId !== myId}
            onClickCameraIcon={onVideoCall}
            onClickChatIcon={onClickChatIcon}
            onClickUserGroups={() => {
              if (visibleUserCard.userId && users[visibleUserCard.userId]?.fullname) {
                onUsersGrup(visibleUserCard.userId, users[visibleUserCard.userId].fullname);
              }
            }}
            onKeyUp={onKeyUp}
            isCentered={true}
            {...users[visibleUserCard.userId]}
          />
        )}
      </Wrapper>
      {visibleUserCard.visible && <BackDrop onClick={onClickBackDrop} />}
      {ConferenceModal}
      {isUserGroupsModalVisible && selectedUserId && (
      <UserGroupsModal 
        onClose={closeUserGroupsModal} 
        isVisible={isUserGroupsModalVisible} 
        userId={selectedUserId}
        userName={selectedUserName} 
        /> 
      )}
     
    </WrapperWithSpinner>

  )
}

export default Post

const WrapperWithSpinner = styled.div`
  position: relative;
`

const BackDrop = styled.div`
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px);
`

const Wrapper = styled.div.attrs((props: { isLoading?: boolean }) => props)`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 100%;
  opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`
