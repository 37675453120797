import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { FORM, PLACEHOLDERS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import {
  confirmPasswordValidation,
  generatePasswordError,
  requiredValidation,
} from 'utils/helpers/validations'
import { useAppSelector } from 'app/hooks'
import colors from 'utils/colors'
import styled from 'styled-components'

interface IChangePasswordProps {
  formChecked: boolean
  currentPassword: string
  newPassword: string
  confirmNewPassword: string
  onChange: (name: string, value: string) => void
}

const ChangePassword = ({
  formChecked,
  currentPassword,
  newPassword,
  confirmNewPassword,
  onChange,
}: IChangePasswordProps) => {
  const passwordSettingsSlice = useAppSelector((state) => state.system.passwordSettings)
  const { t } = useTranslation()

  const isValidName =
    formChecked && requiredValidation(currentPassword, t(FORM.CURRENT_PASSWORD)).errorText
  const isConfirmValid =
    formChecked && confirmPasswordValidation(newPassword, confirmNewPassword).confirmError
  const isValiPass = formChecked && requiredValidation(newPassword, t(FORM.NEW_PASSWORD)).errorText
  const passValidationText = generatePasswordError(passwordSettingsSlice, newPassword).errorText

  const passValidationColor = formChecked
    ? generatePasswordError(passwordSettingsSlice, newPassword).color
    : colors.gray80

  return (
    <ChangePasswordWrapper>
      <FormWrapper>
        <CustomFormInput
          errorText={isValidName}
          value={currentPassword}
          title={t(FORM.CURRENT_PASSWORD) as string}
          placeholder={t(PLACEHOLDERS.PASSWORD) as string}
          type="password"
          preset="medium"
          onChange={(value: string) => onChange('currentPassword', value)}
        />
        <CustomFormInput
          value={newPassword}
          title={t(FORM.NEW_PASSWORD) as string}
          placeholder={t(PLACEHOLDERS.PASSWORD) as string}
          type="password"
          preset="medium"
          errorText={isValiPass}
          errorColor={passValidationColor}
          validationText={passValidationText}
          onChange={(value: string) => onChange('newPassword', value)}
        />
        <CustomFormInput
          value={confirmNewPassword}
          title={t(FORM.CONFIRM_PASSWORD) as string}
          placeholder={t(PLACEHOLDERS.PASSWORD) as string}
          type="password"
          preset="medium"
          errorText={isConfirmValid}
          onChange={(value: string) => onChange('confirmNewPassword', value)}
        />
      </FormWrapper>
    </ChangePasswordWrapper>
  )
}

export default ChangePassword

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4px;
`

const ChangePasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
