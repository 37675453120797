import React from 'react'
import styled from 'styled-components'


interface IAudioProps {
  source: string

}

const Audio = ({ source }: IAudioProps) => {
  return (
    <div style={{ position: 'relative' }}>
      <StyledAudio  controls>
        <source src={source} />
        Your browser does not support the audio tag.
      </StyledAudio>
    </div>
  )
}

export default React.memo(Audio)

const StyledAudio = styled.audio.attrs(
  (props: { maxWidth: string; maxHeight: string }) => props,
)`
  width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
`