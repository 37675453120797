import styled from 'styled-components'
import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import { useTranslation } from 'react-i18next'
import { BUTTONS, MESSAGES } from 'locales/locales'

interface IJoinChannelProps {
  onJoinChannel?: () => void
}

const JoinChannel = ({ onJoinChannel }: IJoinChannelProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <CustomText typography="body3" textAlign="center">
        {t(MESSAGES.JOIN_CHANNEL_DESCRIPTION)}
      </CustomText>
      <CustomButton
        text={t(BUTTONS.JOIN)}
        textColor={colors.white}
        size="medium"
        onClick={onJoinChannel}
      />
    </Wrapper>
  )
}

export default JoinChannel

const Wrapper = styled.div`
  display: flex;
  height: 120px;
  border-top: 1px solid ${colors.light80};
  align-items: center;
  justify-content: center;
  column-gap: 15px;
`
