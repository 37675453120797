import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TABS } from 'locales/locales'
import { useAppSelector } from 'app/hooks'
import { IRoom, RoomTypes } from 'types/rooms.types'

const useRoom = () => {
  const { roomId = '' } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const rooms = useAppSelector((state) => state.rooms.data)
  const myId = useAppSelector((state) => state.auth.id)

  const roomInfo = useMemo(() => {
    const {
      createdAt,
      members,
      invitedUsers,
      acceptingUsers,
      type,
      isVanishMode,
      createdByUserId,
      isAppear,
      isDeleted,
      pins = [],
      name,
      unreadCount = 0,
      userData = {}
    } = rooms.find((room: IRoom) => room._id === roomId) || {}
    if (!createdAt) {
      const myRoom = rooms.find(
        (room: IRoom) => room.members.length === 1 && room.members[0] === myId,
      );

      return navigate(`/messages${myRoom ? "/"+myRoom._id : ''}`);
    }


    const membersWithoutMe =
      type === RoomTypes.OTR
        ? [...invitedUsers, createdByUserId].filter((id) => id !== myId)
        : members.filter((id: string) => (members.length > 1 ? id !== myId : id === myId))

    const myRoomsId = rooms.find(
      (room: IRoom) => room.members.length === 1 && !room.invitedUsers,
    )?._id

    const roomName = membersWithoutMe.map((userId: string) => userData[userId]?.fullname).join(', ')

    const description =
      type === RoomTypes.OTR || type === RoomTypes.GM
        ? members.length + ' ' + t(TABS.MEMBERS)
        : membersWithoutMe.map((userId: string) => userData[userId]?.title).join('')

    const url =
      type === RoomTypes.GM
        ? members.length.toString()
        : type === RoomTypes.OTR
        ? invitedUsers.length === 1
          ? createdByUserId === myId
            ? userData[invitedUsers[0]]?.avatar
            : userData[createdByUserId]?.avatar
          : invitedUsers.length.toString()
        : membersWithoutMe.map((userId: string) => userData[userId]?.avatar).join('')

    const isMe = !!name
    return {
      roomName,
      description,
      url,
      createdAt,
      isAppear,
      createdByUserId,
      isDeleted,
      isVanishMode,
      type,
      isOwnerMe: createdByUserId === myId,
      pins,
      members,
      acceptingUsers,
      isMe,
      myRoomsId,
      unreadCount,
      userData
    }
  }, [rooms, myId, roomId])

  return { room: roomInfo, myId, roomId, myRoomsId: roomInfo?.myRoomsId, users: roomInfo?.userData }
}

export default useRoom
