import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FORM, PLACEHOLDERS, TABS } from 'locales/locales'
import { IChannelMember } from 'types/user.types'
import { FixMeLater } from 'types'
import { icons } from 'utils/icons'
import colors from 'utils/colors'

import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import SearchInput from 'components/input/search-input/SearchInput'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Member from 'components/members/Member'
interface IAddParticipantsProps {
  formChecked?: boolean
  data?: IChannelMember[]
  searchedData?: IChannelMember[]
  channelDownData?: Array<FixMeLater>
  selectedChannels?: Array<FixMeLater>
  channelSelectedOption?: FixMeLater
  onRemoveUser?: (user: IChannelMember, isCancel?: boolean) => void
  onSelectMember?: (user: IChannelMember) => void
  removeUser: (param: FixMeLater) => void
  onChangeInput: (value: string | boolean | Date, key: string) => void
  onChangeSearchText?: (text: string) => void
}
const Participants = ({
  data,
  searchedData,
  channelDownData,
  channelSelectedOption,
  selectedChannels,
  onSelectMember,
  removeUser,
  onRemoveUser,
  onChangeInput,
  onChangeSearchText,
}: IAddParticipantsProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div style={{ width: '100%', height: '4rem', position: 'relative', 'zIndex': 8 }}>
        <CustomFormInput
          type={'text'}
          title={t(FORM.MEETING_GROUP)}
          disabled={(channelDownData ?? []).length === 0}
          dropDownData={channelDownData}
          selectedOption={channelSelectedOption}
          placeholder={t(FORM.MEETING_GROUP)}
          onChange={(value: string) => onChangeInput(value, 'channel_option')}
        />
      </div>
      {selectedChannels?.length !== 0 && (
        <MultipleSearchWrapper>
          {selectedChannels?.map((channel: any, index: number) => {
            return (
              <SelectedItemWrapper key={index}>
                <CustomText typography="body3">{channel.title || channel.name}</CustomText>
                <CustomIcon
                  iconPreset="small"
                  source={icons.closeIconSmall}
                  onClick={() => removeUser(channel)}
                />
              </SelectedItemWrapper>
            )
          })}
        </MultipleSearchWrapper>
      )}
      <SearchInputWrapper>
        <CustomText typography="header3">{t(FORM.MEETING_SINGLE_PARTICIPANT)}</CustomText>
        <SearchInput
          showInDropdown
          placeHolder={t(PLACEHOLDERS.SEARCH_PLACEHOLDER)}
          searchedData={searchedData}
          onChange={onChangeSearchText}
          onSelectMember={onSelectMember}
        />
      </SearchInputWrapper>
      {!!data && (
        <MembersBottomWrapper>
          <MemberInfoText typography="header5">
            {data?.length + ' ' + t(TABS.MEMBERS)}
          </MemberInfoText>
          <MemberListWrapper>
            {data?.map((user: IChannelMember) => (
              <Member
                isUserCardDisplayable={true}
                key={user.email}
                {...user}
                isRemove={true}
                onRemoveUser={(isCancel?: boolean) => onRemoveUser?.(user, isCancel)}
              />
            ))}
          </MemberListWrapper>
        </MembersBottomWrapper>
      )}
    </Wrapper>
  )
}

export default Participants

const MemberListWrapper = styled.div`
  overflow-y: auto;
`
const MembersBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-y: auto;
`
const MemberInfoText = styled(CustomText)``

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  gap: 16px;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 5px;
`
const MultipleSearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 3px;
  margin-top: 7px;
  margin-left: 5px;
  margin-bottom: 10px;
`
const SelectedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 2px 6px;
  background-color: ${colors.vime20};
  border-radius: 4px;
  gap: 6px;
  z-index: 7;
`
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: visible;
  &::-webkit-scrollbar {
    display: none;
  }
`
const SwitchWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
