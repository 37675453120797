import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import ServiceWorkerHelpers from 'utils/helpers/notification'
import { FixMeLater } from 'types'
import {
  useLoginMutation,
  useSendResetPasswordMailMutation,
} from '../../../app/services/auth.service'
import { useAcceptInviteMutation } from 'app/services/invite.service'

import Loading from 'components/spinner/Loading'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import ForgotPasswordModal from 'components/modals/ForgotPasswordModal'
import styled from 'styled-components'
import { BUTTONS, FORM, PLACEHOLDERS, TITLES } from 'locales/locales'
import { emailValidation, requiredValidation } from 'utils/helpers/validations'
import { useAppSelector } from 'app/hooks'
import { useSocket } from 'utils/hooks/useSocket'
import { getDataFromDB } from 'utils/helpers/storageHelper'
import CustomText from 'components/text/CustomText'
import colors from 'utils/colors'
import refreshIcon from 'assets/icons/reload-icon.png';
import getBaseUrl from 'utils/helpers/apiHelper'
import { useUpdateUserFcmMutation } from 'app/services/user.service'


const { getTokenFromFirebase, registerWebPushServiceWorker ,subscribeToPushService} = ServiceWorkerHelpers

export default function Login() {
  const { t } = useTranslation()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [formChecked, setFormChecked] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [resetPassFormChecked, setResetPassFormChecked] = useState(false)
  const [captchaInput, setCaptchaInput] = useState('')
  const [captchaSrc, setCaptchaSrc] = useState("");


  const currentUserId = useAppSelector((state) => state.auth.id)
  const systemSettings = useAppSelector((state) => state.system.data)
  const {clientId= ""} = useAppSelector((state) => state.hash)
  
  const navigate = useNavigate()
  const { state } = useLocation()
  const socket = useSocket()

  const [login] = useLoginMutation()
  const [updateFcm] = useUpdateUserFcmMutation()
  const [acceptInvite] = useAcceptInviteMutation()
  const [sendResetPasswordMail] = useSendResetPasswordMailMutation()

  const refreshCaptcha = () => {
    const uniqueString = Date.now().toString()
    if (clientId) {
      const newSrc = `${getBaseUrl()}api/config/generate/captcha.png?clientId=${clientId}&_=${uniqueString}`
      setCaptchaSrc(newSrc)
      setCaptchaInput('')
    }
  }

  useEffect(() => {
    _initial()
    async function _initial() {
      await systemInitializationTest()
      refreshCaptcha()
    }
  }, [])

  useEffect(() => {
    refreshCaptcha()
  }, [clientId])

  const systemInitializationTest = async () => {
    sessionStorage.setItem('isPageRefresh', 'true')
    if (currentUserId) {
      navigate('/news-feed')
    }
  }

  const formSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormChecked(true)

    const { isValid: isValidEmail } = emailValidation(
      state?.params?.email ? state?.params?.email : email,
    )
    const { isValid: isValidPass } = requiredValidation(password)
    try {
      Loading.ref.show()
      // Log user in if data is valid.
      if (isValidEmail && isValidPass) {

        const loginResult: FixMeLater = await login({
          email: state?.params?.email ? state?.params?.email.trim() : email?.trim(),
          password: password.trim(),
          clientId:clientId,
          captcha: captchaInput.trim(),
        })
        if (loginResult.data.success && state?.action.path && socket) {
          const resultAcceptInvite: FixMeLater = await acceptInvite(state?.action)
          if (resultAcceptInvite.data.success) {
            navigate(state?.action?.successUrl)
          } else {
              navigate('/news-feed')
          }
        } else {
          navigate('/news-feed')
  
        }
        // Subscribe to push service when logged in if permission has been granted before.
        // Otherwise user will be prompted and when permission is granted, subscription will be
        // made via permission listener on useHandleNotification hook.
        if (Notification.permission === 'granted') {
          await subscribeToPushService()
          await getTokenFromFirebase().then(async(fcmToken) => {
            if (fcmToken) {
              await updateFcm({ fcmToken: fcmToken })
            }
          })
        }
      }
    } catch (error) {
      refreshCaptcha(); 
      navigate(state?.action?.failUrl)
    } finally {
      Loading.ref.hide()
    }
  }

  const closeSettingsModal = () => setIsModalVisible(false)

  const handleForgotPassword = async (e: FixMeLater) => {
    e.preventDefault()
    setResetPassFormChecked(true)
    const { isValid: isValidEmail } = emailValidation(email)
    if (isValidEmail) {
      Loading.ref.show()
      await sendResetPasswordMail({ email })
      Loading.ref.hide()
      setIsModalVisible(false)
      setResetPassFormChecked(false)
    }
  }

  const isValidPass = formChecked && requiredValidation(password, t(FORM.PASSWORD)).errorText
  const isValidEmail =
    formChecked && emailValidation(state?.params?.email ? state?.params?.email : email).errorText

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo id="login-logo" />
      </LogoWrapper>
      <Form onSubmit={formSubmitHandler}>
        <CustomFormInput
          value={state?.params?.email ?? email}
          type="email"
          title={t(FORM.EMAIL) as string}
          placeholder={t(PLACEHOLDERS.EMAIL) as string}
          preset="medium"
          errorText={isValidEmail}
          onChange={setEmail}
          disabled={!!state?.params?.email}
        />
        <CustomFormInput
          value={password}
          type="password"
          title={t(FORM.PASSWORD) as string}
          placeholder={t(PLACEHOLDERS.PASSWORD) as string}
          preset="medium"
          errorText={isValidPass}
          onChange={setPassword}
        />
        {systemSettings?.features?.captchaEnabled && clientId && (
          <>
            <Row>
               <CaptchaImg src={captchaSrc} alt="CAPTCHA" />

        <RefreshButton
        onClick={
          (e:any) => {
            e.preventDefault();
            refreshCaptcha()
          }} 
          type={'button'}
          >
          <img src={refreshIcon} alt="Refresh" style={{ width: '13px', height: '13px', marginRight: '10px' }} />
           <p>{t(FORM.RELOAD) as string}</p>
          </RefreshButton>
            </Row>
            <CustomFormInput
              title={t(FORM.CAPTCHA) as string}
              type="text"
              preset="medium"
              value={captchaInput}
              onChange={setCaptchaInput}
            />
          </>
        )}

        <CustomButton typography="header1" size="large" text={t(BUTTONS.SIGN_IN) as string} />
        {systemSettings?.features?.passwordResetEnabled && (
          <CustomText
            onClick={() => setIsModalVisible(true)}
            typography="body9"
            textAlign="center"
            cursor="pointer"
            color={colors.vime}
          >
            {t(TITLES.FORGOT_PASSWORD)}
          </CustomText>
        )}
      </Form>
      <ForgotPasswordModal
        onSubmit={handleForgotPassword}
        closeModal={closeSettingsModal}
        visible={isModalVisible}
        value={email}
        errorText={resetPassFormChecked ? emailValidation(email).errorText : ''}
        onChangeValue={setEmail}
      />
    </Wrapper>
  )
}

const LogoWrapper = styled.div`
  max-height: 200px;
  max-width: 300px;
`

const Logo = styled.img`
  width: 100%;
  height: fit-content;
  object-fit: contain;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 70px;
`

const Form = styled.form`
  width: 372px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Row = styled.div`
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
    position: relative;
`

const CaptchaImg = styled.img`
  width: 100%;
  height: fit-content;
    border-radius: 10px;
margin-bottom:15px;
`

const RefreshButton = styled.button`
    background: #fbfbfb;
    cursor: pointer;
    display: flex; 
    justify-content: center; 
    align-items: center;
    border-radius: 6px;
    padding:12px 10px;
    width: 100%;
    border: 1px solid rgb(218, 229, 243);
    text-align: center;
`;