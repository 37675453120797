import { useAppDispatch } from 'app/hooks'
import { conversationActions } from 'app/slices/conversations.slice'
import { uploadActions } from 'app/slices/upload.slice'
import { useEffect, useState } from 'react'
import { FixMeLater } from 'types'
import { v4 as uuidv4 } from 'uuid'

interface IPasteFile {
  onChangeInputFileData?: (items: Array<File>) => void
}

const usePasteFile = ({ onChangeInputFileData }: IPasteFile) => {
  const [id, setId] = useState<string | undefined>(undefined)
  const dispatch = useAppDispatch()

  const pasteFile = (e: FixMeLater) => {
    if (e.clipboardData.files.length > 0 && onChangeInputFileData && !id) {
      onChangeInputFileData(e.clipboardData.files)
    }
    if (id) {
      Array.from(e.clipboardData.files).forEach((file: FixMeLater) => {
        const startAt = new Date().toISOString()
        const uniqueId = uuidv4()
        dispatch(uploadActions.addQueue({ file, id, uniqueId, startAt }))
        dispatch(conversationActions.addFileInDraft({ file, id, uniqueId, startAt }))
      })
    }
  }

  useEffect(() => {
    window.addEventListener('paste', pasteFile)
    return () => {
      window.removeEventListener('paste', pasteFile)
    }
  }, [id])

  const onUpdatePastedFileTargetId = (currentId: string) => {
    setId(currentId)
  }

  return { onUpdatePastedFileTargetId }
}

export default usePasteFile
