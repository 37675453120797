export enum UserStatus {
  AWAY = 'AWAY',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  DONOTDISTURB = 'DONOTDISTURB',
}

export interface IUser {
  _id: string
  email: string
  title: string
  fullname: string
  username?: string
  team?: string
  phoneNumber?: string
  status?: UserStatus
  avatar: string
  roles?: string[]
  isDeleted?: boolean
  createdAt: string
  isMe: boolean
  enableResendInvite: boolean
}

export interface IChannelMember {
  _id?: string
  isMe?: boolean
  email: string
  title?: string
  fullname?: string
  username?: string
  department?: string
  phoneNumber?: string
  avatar?: string
  createdAt?: string
  permissions?: TUserPermissionsData
  isDeleted?: boolean
  isModerator?: boolean
  isInvite?: boolean
}

export type IUserData = { [key: string]: IUser }

export interface UserUpdateData {
  fullname: string
  username?: string
  avatar: string
  title: string
  password?: string
}

export interface IUserPermissions {
  key: string
  title: string
  value: boolean
}

export type TUserPermissionsData = Array<IUserPermissions>
