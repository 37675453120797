import api from 'app/api'

export const authService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      login: build.mutation({
        query: (loginArgs) => ({
          url: 'public/login',
          method: 'POST',
          body: loginArgs,
        }),
        invalidatesTags: ['AUTH'],
      }),
      logout: build.mutation({
        query: () => ({
          url: '/logout',
          method: 'PUT',
        }),
        invalidatesTags: ['LOGOUT'],
      }),
      sendResetPasswordMail: build.mutation({
        query: (resetPasswordArgs) => ({
          url: 'public/password/reset',
          method: 'POST',
          body: resetPasswordArgs,
        }),
        invalidatesTags: ['AUTH'],
      }),

      updatePassword: build.mutation({
        query: (args) => ({
          url: `public/password`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['UPDATE_PASSWORD'],
      }),

      register: build.mutation({
        query: (args) => ({
          url: `public/register`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['REGISTER'],
      }),
      registerClient: build.mutation({
        query: (args) => ({
          url: `config/register/client`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['REGISTER_CLIENT'],
      }),
    }
  },
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
  useSendResetPasswordMailMutation,
  useUpdatePasswordMutation,
  useRegisterClientMutation,
} = authService
