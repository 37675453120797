import api from 'app/api'

export const dashboardService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getDashboardStatistics: build.query({
        query: () => ({
          url: `dashboard`,
        }),
      }),
    }
  },
})

export const { useLazyGetDashboardStatisticsQuery } = dashboardService
