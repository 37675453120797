import api from 'app/api'

export const postService = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      unreadPostNotification: builder.query({
        query: () => ({
          url: 'notification/news_feed/count/unread',
        }),
      }),
      getUnreadPosts: builder.query({
        query: () => ({
          url: 'news_feed/posts/list/unread',
        }),
      }),
      getPaginatedPosts: builder.query({
        query: ({ page }) => ({
          url: `news_feed/posts/list?page=${page}`,
        }),
      }),
      getCommentsOfPost: builder.query({
        query: ({ postId, page }) => ({
          url: `news_feed/posts/${postId}/comments?page=${page}`,
        }),
      }),
      getReactionsOfPost: builder.query({
        query: ({ postId }) => ({
          url: `news_feed/posts/${postId}/reactions`,
        }),
      }),
      createPost: builder.mutation({
        query: (post) => ({
          url: 'news_feed/posts',
          method: 'POST',
          body: post,
        }),
        invalidatesTags: ['SEND_POST'],
      }),
      sendComment: builder.mutation({
        query: (comment) => ({
          url: 'news_feed/comments',
          method: 'POST',
          body: comment,
        }),
        invalidatesTags: ['SEND_COMMENT'],
      }),
      editPost: builder.mutation({
        query: ({ _id: postId, content,commentToggle }) => ({
          url: `news_feed/posts/${postId}`,
          method: 'PUT',
          body: { content, commentToggle },
        }),
        invalidatesTags: ['EDIT_POST'],
      }),
      likePost: builder.mutation({
        query: ({ postId, myId }) => ({
          url: `news_feed/posts/${postId}/like`,
          method: 'PUT',
          body: { myId },
        }),
        invalidatesTags: ['LIKE_POST'],
      }),
      dislikePost: builder.mutation({
        query: ({ postId, myId }) => ({
          url: `news_feed/posts/${postId}/dislike`,
          method: 'PUT',
          body: { myId },
        }),
        invalidatesTags: ['LIKE_POST'],
      }),
      deletePost: builder.mutation({
        query: ({ postId }) => ({
          url: `news_feed/posts/${postId}/delete`,
          method: 'PUT',
        }),
        invalidatesTags: ['DELETE_POST'],
      }),
      getPostById: builder.query({
        query: ({ postId }) => ({
          url: `news_feed/posts/${postId}`,
        }),
      }),
      unreadPostCommentNotification: builder.mutation({
        query: ({ postId }) => ({
          url: `notification/news_feed/${postId}/comment/read`,
          method: 'PUT',
        }),
        invalidatesTags: ['GET_UNREAD_COMMENTS_NOTIFICATION'],
      }),
      getNewsFeedNotifications: builder.query({
        query: ({ page }) => ({
          url: `notification/news_feed/${page}`,
        }),
      }),
      getRealNotificationCount: builder.query({
        query: () => ({
          url: `notification/news_feed/count`,
        }),
      }),
      getJumpedComments: builder.query({
        query: ({ postId, commentId }) => ({
          url: `news_feed/posts/${postId}/comments/${commentId}/jump`,
        }),
      }),
    }
  },
})

export const {
  useLazyGetJumpedCommentsQuery,
  useLazyGetReactionsOfPostQuery,
  useLazyGetNewsFeedNotificationsQuery,
  useUnreadPostCommentNotificationMutation,
  useLazyGetPaginatedPostsQuery,
  useGetReactionsOfPostQuery,
  useLazyGetCommentsOfPostQuery,
  useLazyGetRealNotificationCountQuery,
  useLazyGetUnreadPostsQuery,
  useEditPostMutation,
  useLikePostMutation,
  useDeletePostMutation,
  useCreatePostMutation,
  useDislikePostMutation,
  useSendCommentMutation,
  useLazyGetPostByIdQuery,
  useLazyUnreadPostNotificationQuery,
} = postService
