import styled from 'styled-components'

import colors from 'utils/colors'

interface ICheckboxProps {
  checked: boolean
  onChange?: (checked: boolean) => void
}

const Checkbox = ({ checked, onChange }: ICheckboxProps) => (
  <CheckboxContainer onClick={() => onChange?.(checked)}>
    <HiddenCheckbox readOnly checked={checked} />
    <StyledCheckbox checked={checked}>
      <Icon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
)

export default Checkbox

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`

const Icon = styled.svg`
  fill: none;
  stroke: ${colors.white};
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props: { checked: boolean }) => (props.checked ? colors.vime : colors.white)};
  border-radius: 3px;
  border: 1px solid ${colors.vime};
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props: { checked: boolean }) => (props.checked ? 'visible' : 'hidden')};
  }
`
