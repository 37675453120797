import { SetStateAction, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { TUserPermissionsData } from 'types/user.types'

import Collapse from './Collapse'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import { useAppSelector } from 'app/hooks'
import { FixMeLater } from 'types'
import UserCard from 'components/new-message/user-card/UserCard'
import { useCreateConversationMutation } from 'app/services/room.service'
import Loading from 'components/spinner/Loading'
import { useNavigate } from 'react-router-dom'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import useInfoModal from 'utils/hooks/useInfoModal'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'
import { BUTTONS, MODALS, MESSAGES,USER_STATUS } from 'locales/locales'


import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO
} from 'utils/constants'
import { t } from 'i18next'
import { isArray } from 'lodash'
import UserGroupsModal from 'features/channels/userGroupModal'

interface IMemberProps {
  _id?: string
  isMe?: boolean
  fullname?: string
  isUserCardDisplayable?: boolean
  inSearchInput?: boolean
  permissions?: TUserPermissionsData
  isRemove?: boolean
  avatar?: string
  isInvite?: boolean
  title?:string
  isCollapse?: boolean
  isReadOnlyPermission?: boolean
  isRoomModerator?: boolean
  isInvited?: boolean
  isArchived?: boolean
  isModerator?: boolean
  isMemberAlready?: boolean
  onResendMember?: () => void
  onSelectMember?: () => void
  onRemoveUser?: (isCancel?: boolean) => void
  onInviteMember?: () => void
  onChangePermission?: (index: number, key: string, checked: boolean) => void
  onShowMemberInfo?: () => void

}

const Member = ({
  _id,
  isMe,
  avatar,
  title,
  fullname,
  inSearchInput,
  permissions,
  isRemove,
  isInvite,
  isCollapse,
  isReadOnlyPermission,
  isRoomModerator,
  isInvited,
  isModerator: isChannelModerator,
  isArchived,
  isMemberAlready,
  onSelectMember,
  onRemoveUser,
  onInviteMember,
  onChangePermission,
  onResendMember,
}: IMemberProps) => {
  const myId = useAppSelector((state) => state.auth.id)
  const [isUserGroupsModalVisible, setIsUserGroupsModalVisible] = useState(false);
  const [visibleMemberInfo, setVisibleMemberInfo] = useState<{
    visible: boolean
    data: FixMeLater
  }>({ visible: false, data: {} })
  const navigate = useNavigate()
  const [createConversation] = useCreateConversationMutation()
  const { t } = useTranslation()
  const [visibleCollapse, setVisibleCollapse] = useState(false)
  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()
  const [visibleUserCard, setVisibleUserCard] = useState(false);
  const userPermissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions);
  const isSystemAdmin = isFlagSetIn([TeamAndChannelPermissions.UPDATE_CHANNEL], userPermissions.teamAndChannel);
  const [windowApi,setWindowApi] = useState<FixMeLater>(null);
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const onToggleVisiblePermission = () => {
    setVisibleCollapse(!visibleCollapse)
  }

  const onHandleRemoveUser = () => {
    onRemoveUser?.(isInvited)
  }

  const onUsersGrup = (userId: string, fullname: string) => {
    setIsUserGroupsModalVisible(true);
    setSelectedUserId(userId);
    setSelectedUserName(fullname);  
  };

  const closeUserGroupsModal = () => {
    setIsUserGroupsModalVisible(false);
  };


  const onCloseMemberInfoCard = () => {
    setVisibleMemberInfo({ visible: false, data: {} })
  }

  const onShowMemberInfoCard = () => {
    setVisibleMemberInfo({
      visible: true,
      data: {
        avatar,
        fullname,
        title,
        _id,
      },
    });
    setVisibleUserCard(true);
};


 const onVideoCall = async () => {
    openConferenceModal({
      title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
      description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeConferenceModal,
      onOk: () => handleChatAndConference(CONFERENCE_TYPE_VIDEO)
    })
  
}
const creatingConference = async (conferenceType: string, roomId?: string) => {
  try {
    Loading.ref.show();
    const data = await createConference({
      roomId: roomId, 
      type: 'single',
      conferenceType: conferenceType,
    });
    if (windowApi) {
      windowApi.send('openConferenceUrl', { url: data.data?.url });
    } else {
      window.open(data.data?.url, '', CONFERENCE_BLANK_DIMENSIONS);
    }
  } finally {
    Loading.ref.hide();
    closeConferenceModal();
  }
};


const onClickChatIcon = async (): Promise<string | null> => {
  try {
    Loading.ref.show();
    const result = await createConversation({ members: [visibleMemberInfo.data._id] });
    if (!result.error) {
      const roomId = typeof result.data.data === 'string' ? result.data.data : result.data.data._id;
      navigate('/messages/' + roomId);
      return roomId; 
    }
  } catch (error) {
    console.error('Error in creating or fetching conversation:', error);
  } finally {
    Loading.ref.hide();
  }
  return null;  
};


const handleChatAndConference = async (conferenceType: string) => {
  const roomId = await onClickChatIcon();
  if (roomId) {
    await creatingConference(conferenceType, roomId);
  } else {
    console.error('Failed to get room ID');
  }
};

  const userRole = isChannelModerator
    ? t(USER_STATUS.MODERATOR)
    : null
    

  return (
    <div>
      <Row inSearchInput={inSearchInput}>
        <MemberLeftWrapper
          inSearchInput={inSearchInput}
          onClick={() => (_id ? onSelectMember?.() : onInviteMember?.())}
        >
          {visibleMemberInfo.visible && (
        <UserCard
          {...visibleMemberInfo.data}
          showChatIcon={visibleMemberInfo.data._id !== myId}
          showCallIcon={false}
          showCameraIcon={visibleMemberInfo.data._id !== myId}
          isCentered={true}
          onClickUserGroups={() => onUsersGrup(visibleMemberInfo.data._id, visibleMemberInfo.data.fullname)}
          onClickCameraIcon={onVideoCall}
          onClickChatIcon={onClickChatIcon}
        />
         )}
        <div
            onClick={(e) => {
              e.stopPropagation()
              onShowMemberInfoCard()
            }}
          >
          <Avatar
            source={avatar}
            text={fullname }
            size={AvatarSize.average}
          />
          </div>
          <MemberDescriptionWrapper>
            <MemberTitleWrapper isInvited={isInvited}>
              <MemberInfoText typography="header3">{fullname}</MemberInfoText>
              {userRole && !isInvited && (
                <CustomText typography="body7" color={colors.gray80}>
                  ({userRole})
                </CustomText>
              )}
              {isMemberAlready && (
                <CustomText typography="body7" color={colors.gray80}>
                  &bull; {t(MESSAGES.ALREADY_ADDED)}
                </CustomText>
              )}
              {isInvited && (
                <CustomText typography="body7" color={colors.gray80}>
                  {t(USER_STATUS.INVITED)}
                </CustomText>
              )}
              {
              isCollapse && permissions && isArray(permissions) && visibleCollapse && !isArchived && (
                <CollapseIcon
                  iconPreset="xSmall"
                  isOpen={visibleCollapse}
                  source={icons.downArrowSmall}
                  onClick={onToggleVisiblePermission}
                />
              )}
            </MemberTitleWrapper>
          </MemberDescriptionWrapper>
        </MemberLeftWrapper>
        {(isRoomModerator || isSystemAdmin) && (
          <OptionsWrapper>
            {isInvited && !isArchived && (
              <CustomText
                typography="body7"
                color={colors.vime}
                cursor="pointer"
                onClick={onResendMember}
              >
                {t(BUTTONS.RESEND_INVITE)}
              </CustomText>
            )}
            {!isMe && isRemove && !isArchived && (
              <CustomText
                typography="body7"
                color={colors.red}
                cursor="pointer"
                onClick={onHandleRemoveUser}
              >
                {t(BUTTONS.REMOVE)}
              </CustomText>
            )}
            {isInvite && !isArchived && (
              <CustomText
                typography="body7"
                color={colors.vime}
                cursor="pointer"
                onClick={onInviteMember}
              >
                {t(BUTTONS.INVITE)}
              </CustomText>
            )}
          </OptionsWrapper>
        )}
      </Row>
      {isCollapse && permissions && isArray(permissions) && visibleCollapse && !isArchived && (
        <Collapse
          data={permissions}
          disabled={isReadOnlyPermission || isMe}
          onChange={(index: number, key: string, value: boolean) =>
            onChangePermission?.(index, key, value)
          }
        />
      )}
     {visibleMemberInfo.visible && <MemberUserCardBackDrop onClick={onCloseMemberInfoCard} />}
     {ConferenceModal}
     {isUserGroupsModalVisible && selectedUserId && (
      <UserGroupsModal 
        onClose={closeUserGroupsModal} 
        isVisible={isUserGroupsModalVisible} 
        userId={selectedUserId}
        userName={selectedUserName} 
        />)}
    </div>
  )
}

export default Member

const Row = styled.div.attrs((props: { inSearchInput?: boolean }) => props)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px ${({ inSearchInput }) => (inSearchInput ? 10 : 0)}px;
  &:hover {
    background-color: ${colors.light50};
  }
`
const MemberUserCardBackDrop = styled.div`
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px);
`

const MemberLeftWrapper = styled.div.attrs((props: { inSearchInput?: boolean }) => props)`
  display: flex;
  cursor: pointer;
  column-gap: 4px;
  max-width: ${({ inSearchInput }) => (inSearchInput ? '450' : '480')}px;
`
const MemberDescriptionWrapper = styled.div.attrs((props) => props)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const MemberTitleWrapper = styled.div.attrs((props: { isInvited: boolean }) => props)`
  display: flex;
  align-items: center;
  column-gap: 10px;
  max-width: ${({ isInvited }) => (isInvited ? '380px' : '100%')};
  @media (max-width: 600px) {
    max-width: ${({ isInvited }) => (isInvited ? '280px' : '350px')};
  }
`

const MemberInfoText = styled(CustomText).attrs((props) => props)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const OptionsWrapper = styled.div.attrs((props) => props)`
  display: flex;
  column-gap: 16px;
`

const CollapseIcon = styled(CustomIcon).attrs((props: { isOpen: boolean }) => props)`
  transition: 300ms all;
  rotate: ${({ isOpen }) => (isOpen ? '180deg' : 'initial')};
`
