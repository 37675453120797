import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { icons } from 'utils/icons'
import { IChannelMember } from 'types/user.types'

import {
  useCreateConversationMutation,
  useCreateOtrConversationMutation,
} from 'app/services/room.service'

import CustomIcon from 'components/icon/CustomIcon'
import CustomModal from 'components/modals/CustomModal'
import SearchInput from 'components/input/search-input/SearchInput'
import { useTranslation } from 'react-i18next'
import { BUTTONS, ERRORS, INFORMATION, MODALS, TABS, TITLES } from 'locales/locales'
import { otrActions } from 'app/slices/otr.slice'
import { MessageUserType } from 'types/conversation.types'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import { IRoom } from 'types/rooms.types'
import useInfoModal from 'utils/hooks/useInfoModal'
import { MemberInfoText } from 'components/members/Members'
import { GM_ROOM_MEMBER_LIMIT } from 'utils/constants'
import colors from 'utils/colors'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { useLazyGetMembersBySearchForChannelQuery } from 'app/services/group.service'

interface ICreateNewRoomProps {
  currentUserId: string
  onClose: () => void
}

const CreateNewRoom = ({ currentUserId, onClose }: ICreateNewRoomProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const [getUsersByEmail, { data: userData }] = useLazyGetUsersByEmailQuery()
  const [createConversation] = useCreateConversationMutation()
  const [createOtrConversation] = useCreateOtrConversationMutation()

  const rooms = useAppSelector((state) => state.rooms.data)

  const myId = useAppSelector((state) => state.auth.id)
  const [isOTR, setOTR] = useState(false)

  const [selectedUsers, setSelectedUsers] = useState<IChannelMember[]>([])
  const [currentData, setCurrentData] = useState<IChannelMember[]>([])
  const [getMembersBySearch] = useLazyGetMembersBySearchForChannelQuery()

  const {
    openModal: openStartOtrConfirmModal,
    closeModal: closeStartOtrConfirmModal,
    Modal: StartOtrConfirmModal,
  } = useInfoModal()

  // useEffect(() => {
  //   if (userData?.data) {
  //     if (selectedUsers.length > 0) {
  //       const data = findDuplicateAndRemove(selectedUsers, userData?.data)
  //       setCurrentData(data)
  //     } else {
  //       setCurrentData(userData?.data)
  //     }
  //   }
  // }, [userData])

  const compareArrays = (a: Array<string>, b: Array<string | undefined>, roomId: string) => {
    if (a.length !== b.length) {
      return false
    }
    a.sort()
    b.sort()
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false
      }
    }
    navigate(`/messages/${roomId}`)
    onClose()
    return true
  }

  const onCreateNewConversation = () => {
    openStartOtrConfirmModal({
      title: t(MODALS.START_OTR_TITLE),
      description: t(MODALS.START_OTR_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeStartOtrConfirmModal,
      onOk: () => onCreateRoom(),
    })
  }
  const onCreateRoom = async () => {
    if (selectedUsers.length === 0) {
      onClose()
      return
    }

    const members = selectedUsers.map((user) => user._id)
    const alreadyExist = rooms.some((room: IRoom) => {
      const roomMembers = room.members.filter((member) => member !== myId)
      return compareArrays(roomMembers, members, room._id)
    })

    let result
    if (isOTR) {
      result = await createOtrConversation({
        members,
        isVanishMode: true,
      })
    } else if (!alreadyExist) {
      result = await createConversation({
        members,
        isVanishMode: false,
      })
    }
    if (!result || !result.data) {
      onClose()
      return
    }
    const roomId = result.data.data?._id || result.data.data.roomId
    console.log('Create room', roomId, result.data.data)

    if (roomId) {
      navigate(`/messages/${roomId}`)
      if (isOTR) {
        (['OTR_CREATED', 'OTR_JOINED']).forEach((status) => {
          dispatch(
            otrActions.sendOtrMessage({
              roomId,
              type: ['text'],
              sender: MessageUserType.SYSTEM,
              content: {
                mentionedUsers: [currentUserId],
                text: `@&_id:${currentUserId}& ${status}`,
              },
            }),
          )
        })
      }
      onClose()
    } else {
      onClose()
    }
  }
  const onChangeSearchText = async (text: string) => {
    // if (text) getUsersByEmail({ email: text })
    if (text.length > 2) {
      const filtered = (await getMembersBySearch({ searchName: text })).data?.data || []

      //const filtered = allUsers.filter((user) => user.email.includes(text) || user.fullname?.toUpperCase().includes(text.toUpperCase()))
      if (selectedUsers.length > 0) {
        const data = findDuplicateAndRemove(selectedUsers, filtered)
        setCurrentData(data)
      } else {
        setCurrentData(filtered)
      }
    } else {
      setCurrentData([])
    }
  }

  const onSelectUser = (user: IChannelMember) => {
    if (selectedUsers.length === GM_ROOM_MEMBER_LIMIT) {
      return DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.MEMBER_LIMIT_EXCEEDED),
      })
    }

    const index = selectedUsers.findIndex((selected) => selected._id === user._id)
    if (index == -1) {
      setSelectedUsers([...selectedUsers, user])
    }
    return
  }
  const onRemoveMember = (user: IChannelMember) => {
    const tempData = selectedUsers.filter(({ _id }) => _id !== user._id)
    setSelectedUsers([...tempData])
  }

  const onToggleOtr = () => {
    setOTR((prevOtr) => !prevOtr)
  }

  //todo Düzenlenecek ve taşınacak
  const findDuplicateAndRemove = (selectedUsers: IChannelMember[], allUsers: IChannelMember[]) => {
    const newArr = [...allUsers]
    for (let i = 0; i < selectedUsers.length; i++) {
      for (let j = 0; j < allUsers.length; j++) {
        if (selectedUsers[i]._id === allUsers[j]._id) {
          newArr.splice(j, 1)
        }
      }
    }
    return newArr
  }

  return (
    <>
      <CustomModal
        title={t(MODALS.NEW_CONVERSATION_TITLE)}
        showButtons={false}
        hasMember
        closeModal={onClose}
      >
        <div>
          {isOTR && <OtrWarningMessage>{t(TITLES.OTR_INFO_TITLE)}</OtrWarningMessage>}
          <MemberInfoText
            typography="header5"
            color={selectedUsers.length === GM_ROOM_MEMBER_LIMIT ? colors.red : 'initial'}
          >
            {`${selectedUsers.length}/${GM_ROOM_MEMBER_LIMIT} ${t(TABS.MEMBERS)}`}
          </MemberInfoText>
          <Wrapper>
            <SearchInput
              showInDropdown
              isAddMember
              isOtr={isOTR}
              searchedData={currentData}
              selectedItems={selectedUsers}
              onToggleOtr={onToggleOtr}
              onSelectMember={onSelectUser}
              onRemoveMember={onRemoveMember}
              onChange={onChangeSearchText}
            />
            <CustomIcon
              source={selectedUsers.length !== 0 ? icons.sendIconActive : icons.sendIconInactive}
              onClick={isOTR ? onCreateNewConversation : onCreateRoom}
            />
          </Wrapper>
        </div>
      </CustomModal>
      {StartOtrConfirmModal}
    </>
  )
}

export default CreateNewRoom

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
  margin-top: 8px;
`
const OtrWarningMessage = styled.div`
  background-color: #f3f3f3;
  color: #666;
  padding: 8px 16px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  margin: 8px 0;
  width: 100%;
`
