import api from 'app/api'

export const conferenceService = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getMeetings: builder.query({
        query: ({ skip, take, filter, count, sort, isDeleted }) => ({
          url: `conference/list?$skip=${skip}&$take=${take}&$count=${count}&$filter=${encodeURIComponent(
            JSON.stringify(filter ?? {}),
          )}&$sort=${encodeURIComponent(JSON.stringify(sort ?? {}))}`,
          method: 'GET',
        }),
      }),
      createChannelConference: builder.query({
        query: (args) => ({
          url: `conference/?roomId=${args.roomId}&type=${args.type}&conferenceType=${args.conferenceType}`,
          method: 'GET',
        }),
      }),
      getMeetingDetailsById: builder.mutation({
        query: (args) => ({
          url: `conference/${args.meetingId}`,
          method: 'GET',
        }),
      }),
      deleteMeetingById: builder.mutation({
        query: (args) => ({
          url: `conference/${args.meetingId}/delete`,
          method: 'PUT',
        }),
      }),
      updateMeetingById: builder.mutation({
        query: (args) => ({
          url: `conference/${args.meetingId}`,
          method: 'PUT',
          body: args.data,
        }),
      }),
      scheduleConference: builder.mutation({
        query: (args) => ({
          url: `conference/`,
          method: 'POST',
          body: args,
        }),
      }),
      checkConferenceActive: builder.query({
        query: (args) => ({
          url: `conference/${args.meetingId}/check`,
          method: 'GET',
        }),
      }),
      // getUserConferencesByUserId: builder.mutation({
      //   query: (args) => ({
      //     url: `conference/user/list`,
      //     method: 'GET',
      //   }),
      // }),
      updateActiveConference: builder.query({
        query: (args) => ({
          url: `conference/${args.meetingId}/invalidate`,
          method: 'PUT',
        }),
      }),
      rejectAllConference: builder.query({
        query: () => ({
          url: `conference/reject/all`,
          method: 'PUT',
        }),
      }),
      getConferenceUrl: builder.mutation({
        query: () => ({
          url: `conference/meetingUrl`,
          method: 'GET',
        }),
      }),
    }
  },
})

export const {
  useScheduleConferenceMutation,
  useLazyGetMeetingsQuery,
  useGetMeetingDetailsByIdMutation,
  useDeleteMeetingByIdMutation,
  useUpdateMeetingByIdMutation,
  useLazyCheckConferenceActiveQuery,
  useLazyRejectAllConferenceQuery,
 // useGetUserConferencesByUserIdMutation,
  useLazyCreateChannelConferenceQuery,
  useLazyUpdateActiveConferenceQuery,
  useGetConferenceUrlMutation,
} = conferenceService
