import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import { icons } from 'utils/icons'
import { MODALS, TITLES } from 'locales/locales'
import { IChannel, IGroup } from 'types/group.types'

import Channel from './Channel'
import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'
import OptionListCard from 'components/cards/OptionListCard'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import colors from 'utils/colors'
import { useAppSelector } from 'app/hooks'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import { IRolePermissions, MessagingPermissions, TeamAndChannelPermissions } from 'types/role.types'

export const GROUP_OPTION_LIST_DATA_FOR_WITHOUT_MY_GROUPS = [
  { key: 'group_info', value: t(TITLES.TEAM_INFO_TITLE) },
]

export const GROUP_OPTON_LIST_FOR_MY_GROUPS = [
  { key: 'group_info', value: t(TITLES.TEAM_INFO_TITLE) },
  { key: 'create_new_channel', value: t(MODALS.CREATE_NEW_CHANNEL_TITLE) },
]

interface IGroupProps {
  isMyGroup: boolean
  groupId: string
  url: string
  name: string
  channels: Array<IChannel>
  pathname: string
  isSearch: boolean
  group: IGroup
  onSelectRoom: (channel: IChannel, group: IGroup, isAppear?: boolean) => void
  onCreateChannel: (groupId: string) => void
  onOpenGroupInfo: (groupId: string) => void
}

const Group = ({
  isMyGroup,
  groupId,
  url,
  name,
  channels,
  pathname,
  isSearch,
  group,
  onSelectRoom,
  onCreateChannel,
  onOpenGroupInfo,
}: IGroupProps) => {
  const [visible, setVisible] = useState(false)
  const [position, setPosition] = useState<number | undefined>()
  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)


  const hasViewGroup = isFlagSetIn(
    [TeamAndChannelPermissions.VIEW_TEAM_MEMBERS ||TeamAndChannelPermissions.CREATE_OR_ARCHIVE_TEAM], permissions.teamAndChannel);


  const onClickGroupOption = (e: React.MouseEvent<HTMLElement>) => {
    setVisible(true)
    setPosition(e.nativeEvent.pageY)
  }

  const onSelectOption = (key: string) => {
    if (key === 'create_new_channel') {
      onCreateChannel(groupId)
      onHideOption()
    } else if (key === 'group_info') {
      onOpenGroupInfo(groupId)
      onHideOption()
    }
  }

  const onHideOption = () => {
    setVisible(false)
    setPosition(undefined)
  }
  const OPTION_LIST = useMemo(
    () =>
      isMyGroup ? GROUP_OPTON_LIST_FOR_MY_GROUPS : GROUP_OPTION_LIST_DATA_FOR_WITHOUT_MY_GROUPS,
    [isMyGroup],
  )

  return (
    <Wrapper>
      {isMyGroup && (
        <GroupOptionListCard
          visible={visible}
          position={position}
          items={OPTION_LIST}
          onClick={onSelectOption}
          onHideCard={onHideOption}
        />
      )}
      <Row>
        <DescriptionWrapper>
          {group.isArchived ? (
            <CustomIcon iconPreset="small" source={icons.unarchiveIcon} />
          ) : (
            <Avatar
              text={name}
              source={url}
              size={AvatarSize.small}
              color={group?.backgroundColor || colors.orange}
            />
          )}
          <OverflowText typography="header3" color={colors.white}>
            {name}
          </OverflowText>
        </DescriptionWrapper>
        {isMyGroup && !group.isArchived && hasViewGroup && (
          <CustomIcon source={icons.threeDotIconWhite} onClick={onClickGroupOption} />
        )}
      </Row>
      {channels?.map((channel) => (
        <Channel
          channel={channel}
          group={group}
          key={channel._id}
          isSearch={isSearch}
          selectedRoom={pathname.includes(channel._id)}
          onSelectRoom={onSelectRoom}
        />
      ))}
    </Wrapper>
  )
}

export default Group

const OverflowText = styled(CustomText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Wrapper = styled.div`
  padding: 10px 4px 10px 16px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
`

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const GroupOptionListCard = styled(OptionListCard).attrs(
  (props: { position: number }) => props,
)`
  top: ${({ position }) => position + 10}px;
  left: 210px;
`
