import { createSlice } from '@reduxjs/toolkit'
import { IRoom, RoomData } from 'types/rooms.types'

import { roomService } from '../services/room.service'
import { IUserData } from 'types/user.types'
import { isString } from 'lodash'
import { FixMeLater } from 'types'

interface RoomsState {
  data: RoomData
}

const initialUsersState: RoomsState = {
  data: [],
}

export const setRoomData = (room: IRoom): IRoom => ({
  ...room,
  members: room.members
    ? (room.members.map((member) => (isString(member) ? member : member['id'])) as string[])
    : [], //Fix for members returned by create that is an object array
  userData: room.users
    ? room.users.reduce((acc: IUserData, currentUser) => {
        acc[currentUser._id] = currentUser
        return acc
      }, {})
    : {},
})

const newConversationHandler = (state: RoomsState, payloadData: FixMeLater) => {
  payloadData = setRoomData(payloadData)
  const roomIndex = state.data.findIndex((room) => room._id === payloadData._id)
  if (roomIndex === -1) {
    state.data.push(payloadData)
    return
  }
  state.data[roomIndex] = payloadData
}

export const { actions: roomsActions, reducer: roomsReducer } = createSlice({
  name: 'rooms',
  initialState: initialUsersState,
  reducers: {
    updatePinnedConversation: (state, action) => {
      state.data.map((room) => {
        if (room._id === action.payload._id) {
          return (room.pins = action.payload.pins)
        }
      })
    },
    updateApperance: (state, action) => {
      const { roomId, isAppear } = action.payload
      const indexByRoomId = state.data.findIndex((room) => room._id === roomId)
      if (indexByRoomId !== -1) {
        state.data[indexByRoomId].isAppear = isAppear
      }
    },
    makeRoomAppear: (state, action) => {
      const roomId = action.payload
      const indexByRoomId = state.data.findIndex((room) => room._id === roomId)

      if (indexByRoomId !== -1) {
        state.data[indexByRoomId].isAppear = true
      }
    },
    updateUnreadCount: (state, { payload }) => {
      const dataByRoomId = state.data.find((room) => room._id === payload.roomId)
      if (dataByRoomId) {
        dataByRoomId.unreadCount = payload.increase ? dataByRoomId.unreadCount + 1 : 0
      }
    },
    updateConversation: (state, action) => {
      const roomIndex = state.data.findIndex((room) => room._id === action.payload._id)
      const isAddIfNotExistEnabled = action.payload.addRoomIfNotExist === undefined
      if (roomIndex === -1) {
        isAddIfNotExistEnabled && state.data.push([action.payload].map(setRoomData)[0])
        return
      }
      !isAddIfNotExistEnabled && delete action.payload.addRoomIfNotExist

      const newRoomData = action.payload
      if (!newRoomData.users) {
        newRoomData.users = []
      }
      newRoomData.users = [...newRoomData.users, ...state.data[roomIndex].users]

      state.data[roomIndex] = { ...state.data[roomIndex], ...setRoomData(newRoomData) }
    },
    updateNotificationCount: (state, { payload }) => {
      const { roomId, notificationCount } = payload
      const indexByRoomId = state.data.findIndex((room) => room._id === roomId)
      if (indexByRoomId !== -1) {
        state.data[indexByRoomId].notificationCount = notificationCount
      }
    },
    removeConversation: (state, action) => {
      const roomIndex = state.data.findIndex((room) => room._id === action.payload._id)
      if (roomIndex === -1) {
        return
      }
      state.data.splice(roomIndex, 1)
    },
    makeConversationAppear: (state, action) => {
      const roomIndex = state.data.findIndex((room) => room._id === action.payload._id)
      if (roomIndex === -1) {
        return
      }
      state.data.splice(roomIndex, 1)
    },
    createConversation: (state, action) => {
      newConversationHandler(state, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(roomService.endpoints.getRooms.matchFulfilled, (state, { payload }) => {
      state.data = payload.data.map(setRoomData)
    })
    builder.addMatcher(
      roomService.endpoints.searchConversation.matchFulfilled,
      (state, { payload }) => {
        payload.data = payload.data.map(setRoomData)
      },
    )
    builder.addMatcher(
      roomService.endpoints.createConversation.matchFulfilled,
      (state, { payload }) => {
        newConversationHandler(state, payload.data)
      },
    )
  },
})
