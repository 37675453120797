import { useEffect, useMemo, useRef, useState } from 'react'
import EmojiPicker, { IEmojiData } from 'emoji-picker-react'
import { t } from 'i18next'
import styled from 'styled-components'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import Tools from '../tools/tools'
import { icons } from 'utils/icons'
import Typing from '../typing/Typing'
import Mentions from '../mention/Mention'
import { useAppSelector } from 'app/hooks'
import { PLACEHOLDERS } from 'locales/locales'
import { IUserData } from 'types/user.types'
import { ICustomFile } from 'types/file.types'
import useAudioRecord from 'utils/hooks/useAudioRecord'
import useMessageInput from 'utils/hooks/useMessageInput'
import { IMessage, IMessageContent } from 'types/conversation.types'
import { scrollBottom } from '../../../../utils/helpers/scrollHelper'
import { MOBILE_DEVICE_WIDTH, MOBILE_WIDTH } from 'utils/constants'
import useGetTypingUsers from 'utils/hooks/useGetTypingUsers'

import CustomFile from 'components/file/CustomFile'
import Recorder from 'components/file/record/Record'
import Waveform from 'components/file/wavesurfer/Waveform'
import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'

let prevMentionIndex: string | null = null

interface IInputProps {
  roomId?: string
  isOtr?: boolean
  messageId?: string
  messageIdInThread?: string
  placeHolder: string
  users: IUserData
  initialInputData?: IMessageContent
  getInputProps?: FixMeLater
  onUploadAudio?: (audioData: Blob) => void
  onCancelEditMessage?: () => void
  pendingHandler?: (message: IMessage) => void
  onScrollBottom?: () => void
  onUpdatePastedFileTargetId?: (id: string, item?: boolean) => void
}

interface IInputProps {
  isFocus?: boolean
  edit?: boolean | undefined
  size?: number
  number?: boolean
  isPreviewableFile?: boolean
}

const Input = ({
  roomId = '',
  isOtr,
  messageId,
  messageIdInThread,
  placeHolder,
  users,
  initialInputData,
  getInputProps,
  onUploadAudio,
  onCancelEditMessage,
  pendingHandler,
  onScrollBottom,
  onUpdatePastedFileTargetId,
}: IInputProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)
  const mentionsDropdownRef = useRef<HTMLDivElement>(null)
  const clientYRef = useRef<FixMeLater>(null)
  const scrollableInput = useRef(true)

  const [isFocus, setFocus] = useState(false)
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState(false)

  const [mentionsDropdown, setMentionsDropdown] = useState(false)
  const { queue } = useAppSelector((state) => state.upload)

  const { inputData, isValid, onSendMessage, onSelectEmoji, onCancelFile, onChangeInputData } =
    useMessageInput({
      roomId,
      isOtr,
      messageIdInThread,
      messageId,
      initialInputData,
      pendingHandler,
      users
    })

  const { usersOfTyping } = useGetTypingUsers({
    currentRoomId: roomId,
    messageText: inputData.text,
    openThreadId: messageIdInThread || '',
  })

  const {
    recorder: stream,
    timer,
    status: recordingStatus,
    startRecording: onStartRecording,
    stopRecording: onStopRecording,
    cancelRecording: onCancelRecording,
  } = useAudioRecord({ onUploadAudio })

  useEffect(() => {
    const end = inputRef?.current?.value.length || 0
    inputRef?.current?.setSelectionRange(end, end)
    inputRef?.current?.focus()
    onUpdatePastedFileTargetId?.(messageIdInThread || roomId)
  }, [roomId])

  useEffect(() => {
    onKeyDown({})
  }, [inputRef?.current?.scrollHeight])

  useEffect(() => {
    let observer: MutationObserver | null
    if (inputRef.current) {
      const config = { attributes: true, childList: true, subtree: true }
      observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'aria-activedescendant'
          ) {
            if (mentionsDropdownRef.current) {
              const mentionIndex = inputRef.current
                ?.getAttribute('aria-activedescendant')
                ?.split('-')[1] as string
              const mentions = [...mentionsDropdownRef.current.children] as HTMLElement[]
              const mention = mentions[+mentionIndex]
              // Remove the background color of previously focused mention if exists.
              if (prevMentionIndex) {
                const prevMention = mentions[+prevMentionIndex]
                if (prevMention) prevMention.style.backgroundColor = 'initial'
              }
              // Set the bg color of the focused mention and scroll that into view.
              if (mention) {
                mention.style.backgroundColor = colors.light50
                mention.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                prevMentionIndex = mentionIndex
              }
            }
          }
        })
      })
      observer.observe(inputRef.current, config)
    }
    return () => {
      observer?.disconnect()
      observer = null
    }
  }, [inputRef.current, roomId])

  useEffect(() => {
    if (inputData.text.length === 0) {
      scrollableInput.current = true
    }
  }, [inputData.text])

  const onFocus = () => {
    onUpdatePastedFileTargetId?.(messageIdInThread || roomId)
    setFocus(true)
    if (inputRef?.current) {
      inputRef.current.style.height = `95px`
    }
  }

  const onBlur = () => {
    setFocus(false)
    scrollableInput.current = true
  }

  const onKeyUp = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault()
    if (
      event.key === 'Enter' &&
      isValid &&
      !mentionsDropdown &&
      !event.shiftKey &&
      inputRef.current &&
      queue.length === 0
    ) {
      onSendMessage()
      onScrollBottom?.()
      onCancelEditMessage?.()
    }
  }

  const onKeyDown = (event: FixMeLater) => {
    event.key === 'Enter' && !event.shiftKey && event.preventDefault()
    if (inputRef.current) {
      inputRef.current.style.height = `95px`
    }
  }

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (scrollableInput.current) {
      scrollBottom(inputRef)
    }
    scrollableInput.current = false
    onChangeInputData(event.target.value)
  }

  const onToggleVisibleEmojiPicker = (e: FixMeLater) => {
    clientYRef.current = e.clientY
    setVisibleEmojiPicker(!visibleEmojiPicker)
  }

  const onEmojiClick = (e: IEmojiData) => {
    onSelectEmoji?.(e)
    setVisibleEmojiPicker(false)
    inputRef?.current?.focus()
  }

  const onSaveEditMessage = () => {
    if (queue.length === 0) {
      onSendMessage()
    }
    onCancelEditMessage?.()
  }

  const fileContent = useMemo(() => {
    return (
      <DraggedFileContainer>
        {inputData.files?.map((file: ICustomFile) => {
          const url: string = file?.url || URL.createObjectURL(file?.file)
          const name = file?.file?.name || file.originalName
          const mimetype = file?.file?.type || file.mimetype
          const size = file?.file?.size || file.size
          const isLoading = !!file.file
          const isPreviewableFile = mimetype?.includes('image') || mimetype?.includes('video')
          const isWaveform = mimetype?.includes('audio')
          return (
            <FileMessageWrapper key={file?.uniqueId}>
              <SpinningIcon id="loading-file" source={icons.loadingIcon} isLoading={isLoading} />
              <CloseIcon
                id="cancel-file"
                source={icons.closeIconWhite}
                isCancel={!isLoading}
                onClick={() => onCancelFile(file.uniqueId)}
              />
              {!isWaveform && (
                <CustomFileWrapper isPreviewableFile={isPreviewableFile}>
                  <CustomFile
                    source={url}
                    preset="input"
                    filename={name}
                    mimetype={mimetype}
                    sentAt={file.sentAt}
                    size={size}
                    thumbnail={file?.thumbnail}
                  />
                </CustomFileWrapper>
              )}
              {isWaveform && <Waveform url={url} />}
            </FileMessageWrapper>
          )
        })}
      </DraggedFileContainer>
    )
  }, [inputData.files])

  return (
    <>
      <Container>
        {visibleEmojiPicker && (
          <EmojiWrapper
            isMobil={window.innerWidth < MOBILE_DEVICE_WIDTH}
            isEdit={!!messageId}
            positionY={clientYRef.current || 0}
          >
            <EmojiPicker
              native
              onEmojiClick={(_, e) => {
                onEmojiClick(e)
              }}
            />
          </EmojiWrapper>
        )}
        <Wrapper isEdit={!!messageId}>
          <MessageInputWrapper isFocus={isFocus} isEdit={!!messageId}>
            <Mentions
              isOtr={isOtr}
              roomId={roomId}
              users={users}
              inputRef={inputRef}
              value={inputData.text}
              placeholder={placeHolder}
              mentionsDropdownRef={mentionsDropdownRef}
              onBlur={onBlur}
              onKeyUp={onKeyUp}
              onFocus={onFocus}
              onKeyDown={onKeyDown}
              onChange={handleOnChange}
              onMentionDropdown={setMentionsDropdown}
            />
            {inputData.files.length > 0 && fileContent}
            {recordingStatus === 'recording' && (
              <Recorder
                timer={timer}
                stream={stream}
                onSave={onStopRecording}
                onCancel={onCancelRecording}
              />
            )}
            <Tools
              isValid={isValid}
              isEdit={!!messageId}
              queue={queue}
              onCancelEditMessage={onCancelEditMessage}
              onSaveEditMessage={onSaveEditMessage}
              onVisibleEmojiPicker={onToggleVisibleEmojiPicker}
              onStartRecording={onStartRecording}
              getInputProps={getInputProps}
              onSendMessage={onSendMessage}
            />
          </MessageInputWrapper>
          {
            <InputInfoWrapper isTyping={usersOfTyping.length > 0}>
              {usersOfTyping.length > 0 && <Typing data={usersOfTyping} />}
              {!messageIdInThread && !messageId && (
                <InputInfo typography="body6" color={colors.gray80}>
                  {t(PLACEHOLDERS.ADD_NEW_LINE)}
                </InputInfo>
              )}
            </InputInfoWrapper>
          }
        </Wrapper>
      </Container>
      {visibleEmojiPicker && <BackDrop onClick={onToggleVisibleEmojiPicker} />}
    </>
  )
}
export default Input

const BackDrop = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
`
const MessageInputWrapper = styled.div.attrs(
  (props: { isFocus: boolean; isEdit: boolean; isDeactive: boolean }) => props,
)`
  pointer-events: ${({ isDeactive }) => (isDeactive ? 'none' : '')};
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: 1px solid ${({ isFocus }) => (isFocus ? '#059EDA' : '#6D6E6F')};
  box-shadow: ${({ isFocus }) => (isFocus ? '0px 0px 6px #499eda' : 'unset')};
  border-radius: 8px;
  padding: 12px;
  margin: ${({ isEdit }) => (isEdit ? '0px' : '5px 30px')};
  background: ${colors.white};
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    margin: ${({ isEdit }) => (isEdit ? '0px' : '5px 8px')};
  }
`

const Container = styled.div`
  position: relative;
  word-break: break-all;
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    top: 0;
    left: 0;
    width: 100%;
  }
`
const Wrapper = styled.div.attrs((props: { isEdit: boolean }) => props)`
  position: relative;
  z-index: ${({ isEdit }) => (isEdit ? 2 : 0)};
`
const DraggedFileContainer = styled.div`
  display: flex;
  column-gap: 14px;
  align-self: flex-start;
  align-items: center;
  padding: 5px 4px;
  overflow-x: auto;
  width: 100%;
`
const EmojiWrapper = styled.div.attrs(
  (props: { positionY: number; isEdit?: boolean; isMobil?: boolean }) => props,
)`
  position: absolute;
  /* width: 33%; */
  bottom: ${({ positionY }) => (positionY > 380 ? '100%' : '-300px')};
  z-index: 4;
  left: ${({ isEdit }) => (isEdit ? 0 : 30)}px;
  .emoji-picker-react {
    width: ${({ isMobil }) => (isMobil ? '100%' : 'none')};
    height: ${({ isMobil }) => (isMobil ? '456px' : 'none')};
    margin: ${({ isMobil }) => (isMobil ? '0px 0px 10px 0px' : 'initial')};
    padding: ${({ isMobil }) => (isMobil ? '0px 0px 10px 0px' : 'initial')};
  }
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    bottom: -22px;
    left: 0;
    position: fixed;
    width: 100%;
    box-shadow: rgba(12, 33, 58, 0.29) 0px -10px 10px;
    border-radius: 4px;
    aside.emoji-picker-react {
      width: 100%;
    }
  }
  @media (max-height: ${400}px) {
    width: fit-content;
    bottom: -15px;
    box-shadow: rgba(12, 33, 58, 0.29) 0px -10px 10px;
    border-radius: 4px;
  }
`

const CustomFileWrapper = styled.div.attrs((props: IInputProps) => props)`
  display: flex;
  width: ${({ isPreviewableFile }) => (!isPreviewableFile ? 200 : 80)}px;
  overflow: hidden;
  justify-content: space-between;
  border: ${({ isPreviewableFile }) =>
    !isPreviewableFile ? ` 1px solid ${colors.gray60}` : 'initial'};
  border-radius: 4px;
  padding: ${({ isPreviewableFile }) => (!isPreviewableFile ? '8px 6px' : '0px')};
  column-gap: 4px;
`

const SpinningIcon = styled(CustomIcon).attrs((props: { isLoading: boolean }) => props)`
  display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
  position: absolute;
  top: -5px;
  right: -5px;
  animation: spin 1s linear infinite;
  background-color: transparent;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`
const CloseIcon = styled(CustomIcon).attrs((props: { isCancel: boolean }) => props)`
  display: ${({ isCancel }) => (isCancel ? 'flex' : 'none')};
  position: absolute;
  top: -5px;
  right: -5px;
  flex-shrink: 0;
  background-color: ${colors.vime};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

const InputInfoWrapper = styled.div.attrs((props: { isTyping: boolean }) => props)`
  display: flex;
  min-height: 20px;
  justify-content: ${({ isTyping }) => (isTyping ? 'space-between' : 'flex-end')};
  align-items: center;
  padding: 0px 30px;
  @media (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    flex-direction: column;
    justify-content: flex-start;
    column-gap: 4px;
    padding: 0px 8px;
  }
`

const InputInfo = styled(CustomText)`
  margin: 2px 0px 2px 0px;
`

const FileMessageWrapper = styled.div`
  position: relative;
  #cancel-file {
    display: flex;
  }
  #loading-file {
    display: none;
  }
`
