import { t } from 'i18next'
import { Middleware } from '@reduxjs/toolkit'

import { ERRORS } from 'locales/locales'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { clearDB } from 'utils/helpers/storageHelper'

const errorHandlerMiddleware: Middleware = (store) => (next) => (action) => {
  const { payload } = action
  const isRequestRejected = action.type.includes('apis') && action.type.endsWith('/rejected')
  const isRequestSucceeded = action.type.includes('apis') && action.type.endsWith('/fulfilled')

  if (isRequestRejected) {
    const isClientError = payload?.status === 403 && payload?.data?.isClientVerified === false
    const clientErrorMessage = isClientError && 'Client cannot be verified, you are logged out.'

    DropdownAlert.ref.show({
      isError: true,
      message: clientErrorMessage
        ? clientErrorMessage
        : payload?.data?.message || payload?.data || payload?.error,
    })

    if (payload?.status === 401 || isClientError) {
      store.dispatch({ type: 'RESET' })
      // localStorage.clear()
      clearDB('vimesoft')
      location.reload()
    }
  }

  if (isRequestSucceeded && payload?.success && payload?.message) {
    DropdownAlert.ref.show({
      isError: false,
      message: payload?.message,
    })
  }

  return next(action)
}

export default errorHandlerMiddleware
