import _ from 'lodash'

export const getObjectDiff = (
  obj1: Record<string, string | boolean>,
  obj2: Record<string, string | boolean>,
) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    // eslint-disable-next-line no-prototype-builtins
    if ((obj2 as Record<string, string | boolean>) && !obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))
  return diff
}
