import { useEffect, useState } from 'react'
import styled from 'styled-components'

import { IUserData } from 'types/user.types'
import { IMessage } from 'types/conversation.types'
import { useLazyGetSearchMessagesQuery } from 'app/services/conversation.service'

import Spinner from 'components/spinner/Spinner'
import EmptyPage from 'components/empty-page/EmptyPage'
import NewMessage from 'components/new-message/NewMessage'
import Separator from 'components/separator/Separator'
import { setMessageData } from 'app/slices/conversations.slice'

interface ISearchMessagesProps {
  value?: string
  users: IUserData
  myId: string
  roomId: string
  onJumpMessage: (messageId: string, threadId?: string) => void
}

const SearchMessages = ({ roomId, value, users, myId, onJumpMessage }: ISearchMessagesProps) => {
  const [searchedData, setSearchedData] = useState<IMessage[]>([])

  const [getSearchMessage, { data, isLoading }] = useLazyGetSearchMessagesQuery()

  useEffect(() => {
    if (data?.data) {
      const searchData = data.data.map(setMessageData)
      setSearchedData([...searchData])
    }
  }, [data?.data])

  useEffect(() => {
    if (value && value.length > 0) {
      getSearchMessage({ roomId, content: value })
    }
  }, [value])

  return (
    <ContentWrapper>
      {isLoading && <Spinner loading={isLoading} />}
      {!isLoading && searchedData?.length === 0 && <EmptyPage value={value} isEmptySearch={true} />}
      {!isLoading &&
        searchedData?.map((message: IMessage, index: number) => {
          return (
            <div key={message._id}>
              <NewMessage
                index={index}
                data={message}
                myId={myId}
                isSearch={true}
                searchTextInMessage={value}
                visibleThread={false}
                onJumpMessage={onJumpMessage}
              />
              <Separator />
            </div>
          )
        })}
    </ContentWrapper>
  )
}

export default SearchMessages

export const ContentWrapper = styled.div.attrs((props: { scrollHeight: number }) => props)`
  width: 100%;
  position: relative;
  max-height: '100vh';
  overflow-y: visible;
  overflow: ${({ scrollHeight }) => (scrollHeight < 600 ? 'visible' : 'auto')};
`
