import { FileState } from 'features/messages/Messages'
import { useOutletContext } from 'react-router-dom'
import { FixMeLater } from 'types'
import { IRoom } from 'types/rooms.types'

type ContextType = {
  onToggleConversationPanel: () => void
  visible: boolean
  groupId: string
  fileState: FileState
  rooms: IRoom[]
  channelId: string
  channel: FixMeLater
}

type NewsFeedContextType = {
  openNotifications: boolean
  newsFeedJumpData: FixMeLater
  onJumpComment: () => void
  onResetNewsFeedJumpData: () => void
  onCloseNotification: () => void
  onToggleNotification: () => void
}

export const useOutletState = () => useOutletContext<ContextType>()
export const useOutletNewsFeedState = () => useOutletContext<NewsFeedContextType>()
