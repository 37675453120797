import 'react-datepicker/dist/react-datepicker.css'
import tr from 'date-fns/locale/tr' // the locale you want
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale } from 'react-datepicker'

import { FORM, PLACEHOLDERS } from 'locales/locales'
import CustomText from 'components/text/CustomText'

import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

registerLocale('tr', tr) // register it with the name you want
interface IUserInfoProps {
  meetingName: string
  meetingDescription: string
  meetingTime: string
  duration: string
  onChange: (value: string | boolean | Date, key: string) => void
}

const MeetInfoForm = ({
  meetingName,
  meetingDescription,
  meetingTime,
  duration,
  onChange,
}: IUserInfoProps) => {
  const { t } = useTranslation()
  return (
    <InfoModalWrapper>
      <FormWrapper>
        <CustomFormInput
          type={'text'}
          title={t(FORM.MEETING_NAME)}
          placeholder={t(PLACEHOLDERS.MEETING_NAME)}
          value={meetingName}
          onChange={(value: string) => onChange(value, 'title')}
        />
        <CustomFormInput
          type={'text'}
          title={t(FORM.MEETING_DESCRIPTION)}
          placeholder={t(FORM.MEETING_DESCRIPTION)}
          value={meetingDescription}
          onChange={(value: string) => onChange(value, 'description')}
        />
        <div
          style={{
            width: '100%',
            height: '4rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          <CustomText typography="header3">{t(FORM.MEETING_TIME)}</CustomText>
          <DatePicker
            locale={'tr'}
            wrapperClassName="datePicker"
            timeIntervals={10}
            showTimeSelect
            dateFormat="Pp"
            selected={new Date(meetingTime)}
            onChange={(e) => onChange(e as Date, 'meetingTime')}
          />
        </div>
        <CustomFormInput
          type={'number'}
          title={t(FORM.MEETING_DURATION)}
          placeholder={t(FORM.MEETING_DURATION)}
          value={duration}
          onChange={(newValue) => {
            const value = Math.min(480, newValue || 0).toString();
            onChange(value, 'duration');
          }
          }
        />
      </FormWrapper>
    </InfoModalWrapper>
  )
}

export default MeetInfoForm

const InfoModalWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .p-datepicker {
    background-color: red !important;
    height: 10px !important;
  }
`
