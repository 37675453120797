import { useAppDispatch } from 'app/hooks'
import { useSendMessageMutation } from 'app/services/conversation.service'
import { conversationActions } from 'app/slices/conversations.slice'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import { BUTTONS } from 'locales/locales'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FixMeLater } from 'types'
import { IMessage, MessageStatus } from 'types/conversation.types'
import colors from 'utils/colors'
import { icons } from 'utils/icons'

interface IStatusProps {
  data: IMessage
}
const Status = ({ data }: IStatusProps) => {
  const { t } = useTranslation()
  const [visibleOption, setVisibleOption] = useState(false)
  const [sendMessage, { data: messageData, isSuccess }] = useSendMessageMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (messageData && isSuccess){
      dispatch(conversationActions.updateMessageByRoomId(messageData.data))
    }
  }, [messageData, isSuccess])

  const cancelHandler = (cti: string, roomId: string) => {
    dispatch(conversationActions.removeFailedMessage({ cti, roomId }))
    setVisibleOption(false)
  }

  const retryHandler = (data: IMessage) => {
    const { status, sender, subsequent, ...rest } = data
    sendMessage({ ...rest }).catch((error: FixMeLater) => {
      console.log(error)
      sendMessage({ ...rest })
    })
    setVisibleOption(false)
  }

  const onToggleVisibleOption = () => {
    setVisibleOption(!visibleOption)
  }

  return (
    <Wrapper>
      {visibleOption && data?.status === MessageStatus.FAILED && (
        <FailMessageWrapper>
          <FailMessageItem typography="header2" onClick={() => retryHandler(data)}>
            {t(BUTTONS.RETRY) as string}
          </FailMessageItem>
          <FailMessageItem
            typography="header2"
            onClick={() => cancelHandler(data.cti, data.roomId)}
          >
            {t(BUTTONS.CANCEL)}
          </FailMessageItem>
        </FailMessageWrapper>
      )}
      {data?.status === MessageStatus.FAILED && (
        <CustomIcon source={icons.infoFailIcon} onClick={onToggleVisibleOption} />
      )}
      {data?.status === MessageStatus.PENDING && <CustomIcon source={icons.clockIcon} />}
    </Wrapper>
  )
}

export default Status

const FailMessageWrapper = styled.div`
  position: absolute;
  width: max-content;
  z-index: 4;
  top: -90px;
  right: 25px;
  border: 1px solid ${colors.light50};
  border-radius: 8px;
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  padding: 10px 0px;
  background-color: ${colors.white};
`
const FailMessageItem = styled(CustomText)`
  padding: 6px 24px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.light50};
  }
`
const Wrapper = styled.div`
  position: absolute;
  right: 25px;
`
