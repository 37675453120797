import { useEffect, useState } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FileRejection, useDropzone } from 'react-dropzone'

import {
  FILE_COUNT_ERROR_CODE,
  FILE_SIZE_ERROR_CODE,
  GB,
  POST_SUPPORTED_IMAGES_TYPES,
  POST_SUPPORTED_VIDEO_TYPES,
} from 'utils/constants'
import { FixMeLater } from 'types'
import axiosApi from 'app/axiosApi'
import { ERRORS } from 'locales/locales'
import { IUserData } from 'types/user.types'
import { ICustomFile, IFiles } from 'types/file.types'
import { newsFeedActions } from 'app/slices/news-feed.slice'
import { useEditPostMutation } from 'app/services/post.service'
import { geValueByfullname, getInputValueByfullname } from 'utils/helpers/messageHelper'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'

export interface IMessageInputState {
  text: string
  value?: string
  mentionedUsers: string[]
  files: IFiles
}

const isEmpty = (str: string) => !str.trim().length

const inputDataValidation = (text = '', files?: IFiles) => {
  return !isEmpty(text) || !_isEmpty(files)
}

const getParseMentionedUsers = (text: string) => {
  const matchedMentionedUsers = text.split('&').filter((item) => item.includes('_id'))
  const mentionedUsers = matchedMentionedUsers?.map((id) => id.slice(4))
  return mentionedUsers
}

const useEditPostInput = ({
  post,
  isAcceptPhoto,
  onClose,
  onEditComment,
}: {
  post: FixMeLater
  isAcceptPhoto: boolean
  onClose: FixMeLater
  onEditComment?: FixMeLater
}) => {
  const [isValid, setValid] = useState(false)
  const [editedContent, setEditedContent] = useState({
    ...post.content,
  })

  const [itHasComment, setHasComment] = useState<boolean>(post?.commentToggle ?? false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [editPost] = useEditPostMutation()

  const acceptedType = isAcceptPhoto ? POST_SUPPORTED_IMAGES_TYPES : POST_SUPPORTED_VIDEO_TYPES

  const onDropAccepted = (files: Array<File>) => {
    if (
      files.length > 4 ||
      editedContent.files.length > 3 ||
      files.length + editedContent.files.length > 4
    ) {
      return DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_COUNT_ERROR, { count: 4 }),
      })
    }
    setEditedContent((prev: IMessageInputState) => {
      return {
        ...prev,
        files: [...prev.files, ...files],
      }
    })
  }

  const onEditedContentFileChange = (files: Array<File>) => {
    setEditedContent((prev: IMessageInputState) => {
      return {
        ...prev,
        files: [...prev.files, ...files],
      }
    })
  }

  const onDropRejected = (fileRejections: Array<FileRejection>) => {
    if (fileRejections[0].errors[0].code === FILE_COUNT_ERROR_CODE) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_COUNT_ERROR, { count: 4 }),
      })
    }
    if (fileRejections[0].errors[0].code === FILE_SIZE_ERROR_CODE) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_SIZE_ERROR, {
          file_name: fileRejections[0].file.name,
        }),
      })
    }
  }

  const { inputRef, getInputProps } = useDropzone({
    accept: acceptedType,
    maxFiles: 4,
    maxSize: 1 * GB,
    onDropAccepted,
    onDropRejected,
  })

  const onCancelEditFile = (file: FixMeLater) => {
    const tempArray = editedContent.files
    const upload = tempArray.filter((item: ICustomFile) => item._id)
    const unupload = tempArray.filter((item: ICustomFile) => !item._id)
    if (file?._id) {
      const filtered = upload.filter((item: ICustomFile) => item._id !== file?._id)
      return setEditedContent({ ...editedContent, files: filtered.concat(unupload) })
    } else {
      const filtered = unupload.filter((item: File) => item.name !== file?.name)
      return setEditedContent({ ...editedContent, files: filtered.concat(upload) })
    }
  }

  const onChangeEditText = (text: string) => {
    const mentionedUsers = getParseMentionedUsers(text)
    setEditedContent((prevData: IMessageInputState) => ({ ...prevData, text, mentionedUsers }))
  }

  const onChangeHeaderInputData = (header: string) => {
    setEditedContent((prevData: IMessageInputState) => ({ ...prevData, header }))
  }

  const onChangeToggleComment= (commentToggle: FixMeLater) => {
    setHasComment(commentToggle)
  }

  const onEditEmojiClick = (emojiData: FixMeLater) => {
    setEditedContent((prevData: IMessageInputState) => ({
      ...prevData,
      text: prevData.text + emojiData.emoji,
    }))
  }

  const onEditPost = async () => {
    const { mentionedUsers, text, files = [], header  } = getInputValueByfullname(editedContent)
    const value = geValueByfullname(text, mentionedUsers, post.userData)

    const trimmedText = text.trim()
    const uploadedFiles = editedContent.files?.filter((item: ICustomFile) => item._id) || []
    const unuploadFiles = editedContent.files?.filter((item: ICustomFile) => !item._id) || []

    try {
      dispatch(
        newsFeedActions.updatePost({
          post: {
            commentToggle :itHasComment,
            content: { mentionedUsers, text: trimmedText, value, files, header },
            postId: post?._id,
            status: 'pending',
          },
        }),
      )
      onClose()
      if (unuploadFiles?.length > 0) {
        dispatch(newsFeedActions.postLoading(true))
        const result = await axiosApi.uploadFile('files/?from=post&bucket=newsfeed', unuploadFiles, 'files')
        if (result?.data?.success) {
          const { data }: FixMeLater = await editPost({
            content: {
              text: trimmedText,
              mentionedUsers,
              value,
              files: [result.data.data, ...uploadedFiles],
            },
            _id: post?._id,
          })
          if (data.success) {
            dispatch(newsFeedActions.postLoading(false))
            dispatch(newsFeedActions.updatePost({ post: { ...data?.data, status: 'success' } }))
          }
        }
      } else {
        const { data }: FixMeLater = await editPost({
          commentToggle: itHasComment,
          content: { mentionedUsers, text: trimmedText, value, files, header },
          _id: post?._id,
        })
        if (data.success) {
          if (onEditComment) {
            onEditComment((prev: FixMeLater) => {
              const tempData = _cloneDeep(prev)
              const index = prev.findIndex((item: FixMeLater) => item._id === data.data.postId)
              tempData[index].content = { ...data?.data?.content }
              return tempData
            })
            onClose()
          }
          dispatch(newsFeedActions.updatePost({ post: { ...data?.data, status: 'success' } }))
          dispatch(newsFeedActions.postLoading(false))
          onClose()
        }
      }
    } catch (err) {
      console.warn('ERROR',err)
      dispatch(
        newsFeedActions.updatePost({
          post: {
            commentToggle :itHasComment,
            content: post.content,
            postId: post?._id,
            status: 'success',
          },
        }),
      )
      dispatch(newsFeedActions.postLoading(false))
    }
  }

  useEffect(() => {
    setValid(inputDataValidation(editedContent?.text, editedContent?.files))
  }, [editedContent])

  return {
    isValidEditPost: isValid,
    inputRef,
    getInputProps,
    onEditPost,
    onEditEmojiClick,
    onChangeEditText,
    onCancelEditFile,
    onEditedContentFileChange,
    onChangeHeaderInputData,
    onChangeToggleComment,
    editedContent,
  }
}

export default useEditPostInput
