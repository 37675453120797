import { createContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { io, Socket } from 'socket.io-client'
import { useTranslation } from 'react-i18next'

import { FixMeLater } from 'types'
import { SOCKET } from './socketHelper'
import { ERRORS } from 'locales/locales'
import { UserStatus } from 'types/user.types'
import getBaseUrl from 'utils/helpers/apiHelper'
import { IMessage } from 'types/conversation.types'
import { useAppDispatch, useAppSelector } from '../hooks'
import { otrEventHandlers } from 'utils/helpers/otrHelper'
import { useUpdateRoomChannelApparanceMutation } from '../services/room.service'

import { roomsActions } from '../slices/rooms.slice'
import { usersActions } from '../slices/users.slice'
import { groupsActions } from '../slices/groups.slice'
import { newsFeedActions } from '../slices/news-feed.slice'
import { conferenceActions } from '../slices/conference.slice'
import { conversationActions, addUnreadSeparator } from '../slices/conversations.slice'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { systemActions } from 'app/slices/system.slice'
import { useLazyGetUserPermissionsQuery } from 'app/services/system.service'
import { authActions } from 'app/slices/auth.slice'

interface IMessageDeletedData {
  roomId: string
  messageId: string
}

const baseUrl = getBaseUrl()

export const socket: Socket = io(baseUrl, {
  autoConnect: false,
  upgrade: true,
  transports: ['websocket', 'polling'],
})

export const SocketContext = createContext(socket)
interface ISocketProvider {
  children: React.ReactNode
}

export const SocketProvider = (props: ISocketProvider) => {
  const { t } = useTranslation()
  const { token, id: myId } = useAppSelector((state) => state.auth)
  const rooms = useAppSelector((state) => state.rooms.data)
  const [updateRoomChannelApparance] = useUpdateRoomChannelApparanceMutation()
  const [getUserPermissions] = useLazyGetUserPermissionsQuery()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const roomId = pathname.split('/')?.[2]

  const {
    onOtrRequest,
    onOtrMessage,
    onOtrRejected,
    onDestroyOtrRoom,
    onOtrRoomUpdated,
    onUserLeavesOtrRoom,
    onDestroyOtrRoomImmediately,
  } = otrEventHandlers(myId, rooms, dispatch, navigate)

  useEffect(() => {
    if (token) {
      socket.io.opts.query = { token: `Bearer ${token}` }
      //socket.auth = { token }
      socket.connect()

      socket.on(SOCKET.OTR_MESSAGE_EVENT, onOtrMessage)
      socket.on(SOCKET.OTR_REQUESTED_EVENT, onOtrRequest)
      socket.on(SOCKET.OTR_REJECTED_EVENT, onOtrRejected)
      socket.on(SOCKET.OTR_DESTROY_ROOM_EVENT, onDestroyOtrRoom)
      socket.on(SOCKET.OTR_ROOM_UPDATED_EVENT, onOtrRoomUpdated)
      socket.on(SOCKET.OTR_LEAVE_ROOM_EVENT, onUserLeavesOtrRoom)
      socket.on(SOCKET.OTR_DESTROY_ROOM_IMMEDIATELY_EVENT, onDestroyOtrRoomImmediately)

      socket.on(SOCKET.LOGOUT, () => {
        dispatch({ type: 'RESET' })
      })

      socket.on(SOCKET.ROOM_CREATED, (newRoom) => {
        dispatch(roomsActions.createConversation(newRoom))
      })

      socket.on(SOCKET.JOINED_PUBLIC_CHANNEL, (channel) => {
        dispatch(groupsActions.updateChannelInfo(channel))
      })

      socket.on(SOCKET.USER_DISCONNECTED, ({ userId }) => {
        dispatch(usersActions.updateUsersStatus({ status: UserStatus.OFFLINE, userId }))
      })

      socket.on(SOCKET.USER_DELETED, () => {
        dispatch({ type: 'RESET' })
        DropdownAlert.ref.show({ isError: true, message: t(ERRORS.ACCOUNT_DEACTIVE) })
      })

      socket.on(SOCKET.USER_CONNECTED, ({ userId }) => {
        dispatch(usersActions.updateUsersStatus({ status: UserStatus.ONLINE, userId }))
      })

      socket.on(SOCKET.SET_CUSTOM_STATUS, ({ status, userId }) => {
        dispatch(usersActions.updateUsersStatus({ status, userId }))
      })

      socket.on(SOCKET.NOTIFICATION_COUNT, ({ type, roomId, notificationCount }) => {
        if (type === 'CM') {
          dispatch(groupsActions.updateNotificationCount({ roomId, notificationCount }))
        } else {
          dispatch(roomsActions.updateNotificationCount({ roomId, notificationCount }))
        }
      })

      socket.on(SOCKET.RESET_NOTIF_COUNT, ({ type, roomId }) => {
        if (type === 'CM') {
          dispatch(groupsActions.updateNotificationCount({ roomId, notificationCount: 0 }))
        } else {
          dispatch(roomsActions.updateNotificationCount({ roomId, notificationCount: 0 }))
        }
      })

      socket.on(SOCKET.MESSAGE, async (message) => {
        const isIncrease = message.roomId !== roomId
        if (message.roomType === 'channels') {
          dispatch(
            groupsActions.updateUnreadCount({ roomId: message.roomId, increase: isIncrease }),
          )
        } else {
          dispatch(roomsActions.updateUnreadCount({ roomId: message.roomId, increase: isIncrease }))
        }

        if (myId === message.sender) {
          dispatch(conversationActions.updateMessageByRoomId(message))
        } else {
          dispatch(conversationActions.getNewMessage(message))
          dispatch(addUnreadSeparator({ roomId: message.roomId }))
          const result: FixMeLater = await updateRoomChannelApparance({
            roomId: message.roomId,
            isAppear: true,
          })

          if (message.roomType === 'messages') {
            dispatch(roomsActions.makeRoomAppear(result?.data?.roomId))
          } else if (message.roomType === 'groups') {
            dispatch(groupsActions.makeChannelAppear(result?.data?.roomId))
          }
        }
      })

      socket.on(SOCKET.NEW_COMMENT, () => {
        //dispatch(newsFeedActions.addComment({ comment }))
      })
      socket.on(SOCKET.NEWS_FEED_NOTIFICATION, () => {
        dispatch(newsFeedActions.increaseUnreadNewsFeedCount())
      })
      socket.on('news-feed-real-notification-count', (count) => {
        dispatch(newsFeedActions.updateRealNotificationCount(count))
      })
      socket.on(SOCKET.UPDATE_POST, (updatedPost) => {
        dispatch(newsFeedActions.updatePost({ post: updatedPost }))
      })
      socket.on(SOCKET.DELETE_POST, ({ postId }) => {
        dispatch(newsFeedActions.deletePost({ postId }))
        dispatch(newsFeedActions.decreaseUnreadNewsFeedCount())
      })
      socket.on(SOCKET.USER_UPDATED, (user) => {
        if (user.type === 'delete') {
          dispatch(usersActions.deleteUser(user.user))
        } else if (user.type === 'update') {
          dispatch(usersActions.updateUser(user.updatedUser))
        }
      })

      socket.on(SOCKET.GROUP_CREATED, (group) => {
        dispatch(groupsActions.addGroup(group))
      })

      socket.on(SOCKET.CHANNEL_CREATED, (channel) => {
        dispatch(groupsActions.addChannel(channel))
      })

      socket.on(SOCKET.PIN_UPDATED, (data) => {
        if (data?.groupId) {
          dispatch(groupsActions.updatePinnedConversation(data))
        } else {
          dispatch(roomsActions.updatePinnedConversation(data))
        }
      })

      socket.on(SOCKET.REACTION_SENT, (message: IMessage) => {
        dispatch(conversationActions.updateMessageReaction(message))
      })

      socket.on(SOCKET.MESSAGE_DELETED, (data: IMessageDeletedData) => {
        dispatch(conversationActions.deleteMessage(data))
      })

      socket.on(SOCKET.MESSAGE_UPDATED, (data: IMessage) => {
        dispatch(conversationActions.editMessage(data))
      })

      socket.on(SOCKET.PINNED_MESSAGE, (data) => {
        dispatch(conversationActions.updatePinnedMessage(data))
      })

      socket.on(SOCKET.UPDATE_THREAD_REPLY, (data) => {
        dispatch(conversationActions.updateThreadReplyUsers(data.value))
      })

      socket.on(SOCKET.THREAD_MESSAGE, (singleThreadMessage) => {
        dispatch(
          conversationActions.updateThreadMessagesCount({
            threadId: singleThreadMessage.threadId,
            roomId: singleThreadMessage.roomId,
          }),
        )
      })

      socket.on(SOCKET.GROUP_UPDATED, (groupData) => {
        dispatch(groupsActions.updateGroup(groupData))
      })

      socket.on(SOCKET.CHANNEL_UPDATED, (channel) => {
        dispatch(groupsActions.updateChannelInfo({ ...channel }))
      })

      socket.on(SOCKET.REMOVE_MEMBER_FROM_CHANNEL, (data) => {
        dispatch(groupsActions.removeChannelMembers(data))
      })

      socket.on(SOCKET.REMOVE_MY_CHANNEL, (data) => {
        dispatch(groupsActions.removeMeFromChannel(data))
        setTimeout(() => {
          dispatch(conversationActions.removeAllDraftAndFailedMessages(data.channelInfo._id))
          dispatch(conversationActions.removeAllMessages(data.channelInfo._id))
        }, 300)
      })

      socket.on(SOCKET.ADD_MEMBER_TO_CHANNEL, (data) => {
        dispatch(groupsActions.addChannelMembers(data))
      })

      socket.on(SOCKET.UPDATE_ROOM_ARCHIVE, (data) => {
        dispatch(groupsActions.updateChannelArchive(data))
      })

      socket.on(SOCKET.LEAVE_FROM_CHANNEL, (data) => {
        dispatch(groupsActions.leaveChannel({ ...data, myId }))
      })

      socket.on(SOCKET.LEAVE_FROM_GROUP_CONVERSATION, (data: FixMeLater) => {
        dispatch(roomsActions.updateConversation(data))
      })

      socket.on(SOCKET.VIDEO_CALL, (data) => {
        dispatch(conferenceActions.newConference(data))
      })

      socket.on(SOCKET.LEAVE_ME, (data) => {
        dispatch(groupsActions.removeMeFromChannel(data))
      })

      socket.on(SOCKET.UPDATE_USER_PERMISSON, (data) => {
        dispatch(groupsActions.updateUserPermissions(data))
      })

      socket.on(SOCKET.PERMISSIONS_CHANGED, async () => {
        const result = await getUserPermissions({})
        if (result?.isSuccess) {
          dispatch(authActions.updatePermissions(result.data.data))
        }
      })

      socket.on(SOCKET.SETTINGS_CHANGED, (data) => {
        dispatch(systemActions.updateSystem(data))
      })
    } else {
      socket.io.opts.extraHeaders = {}
      socket.disconnect()
    }

    return () => {
      //https://socket.io/docs/v4/client-api/#socketoffeventname
      socket.off()
      // socket.off(SOCKET.VIDEO_CALL)
      // socket.off(SOCKET.USER_CONNECTED)
      // socket.off(SOCKET.USER_DISCONNECTED)
      // socket.off(SOCKET.USER_UPDATED)
      // socket.off(SOCKET.MESSAGE)
      // socket.off(SOCKET.ROOM_CREATED)
      // socket.off(SOCKET.GROUP_CREATED)
      // socket.off(SOCKET.CHANNEL_CREATED)
      // socket.off(SOCKET.PIN_UPDATED)
      // socket.off(SOCKET.REACTION_SENT)
      // socket.off(SOCKET.MESSAGE_DELETED)
      // socket.off(SOCKET.PINNED_MESSAGE)
      // socket.off(SOCKET.THREAD_MESSAGE)
      // socket.off(SOCKET.MESSAGE_UPDATED)
      // socket.off(SOCKET.CHANNEL_UPDATED)
      // socket.off(SOCKET.GROUP_UPDATED)
      // socket.off(SOCKET.OTR_REQUESTED_EVENT)
      // socket.off(SOCKET.OTR_REJECTED_EVENT)
      // socket.off(SOCKET.OTR_DESTROY_ROOM_EVENT)
      // socket.off(SOCKET.OTR_MESSAGE_EVENT)
      // socket.off(SOCKET.OTR_ROOM_UPDATED_EVENT)
      // socket.off(SOCKET.OTR_LEAVE_ROOM_EVENT)
      // socket.off(SOCKET.OTR_DESTROY_ROOM_IMMEDIATELY_EVENT)
      // socket.off(SOCKET.UPDATE_USER_PERMISSON)
      // socket.off(SOCKET.LEAVE_ME)
      // socket.off(SOCKET.LEAVE_FROM_CHANNEL)
      // socket.off(SOCKET.UPDATE_ROOM_ARCHIVE)
      // socket.off(SOCKET.ADD_MEMBER_TO_CHANNEL)
      // socket.off(SOCKET.REMOVE_MY_CHANNEL)
      // socket.off(SOCKET.REMOVE_MEMBER_FROM_CHANNEL)
      // socket.off(SOCKET.UPDATE_THREAD_REPLY)
      // socket.off(SOCKET.UPDATE_POST)
      // socket.off(SOCKET.NEW_POST)
      // socket.off(SOCKET.NEW_COMMENT)
      // socket.off(SOCKET.RESET_NOTIF_COUNT)
      // socket.off(SOCKET.NOTIFICATION_COUNT)
      // socket.off(SOCKET.SET_CUSTOM_STATUS)
      // socket.off(SOCKET.USER_DELETED)
      // socket.off(SOCKET.JOINED_PUBLIC_CHANNEL)
    }
  }, [token, roomId, socket])

  return <SocketContext.Provider value={socket}>{props.children}</SocketContext.Provider>
}
