import { useEffect, useState } from 'react'
import { useCreateUserMutation } from 'app/services/user.service'
import CreateUserForm from './CreateUserForm'
import CustomModal from 'components/modals/CustomModal'
import { MODALS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import { emailValidation, requiredValidation } from 'utils/helpers/validations'
import { FixMeLater } from 'types'
import { useGetTeamsMutation } from 'app/services/group.service'
import { IGroup } from 'types/group.types'


interface ICreateUserModalProps {
  onCancel: () => void
  onClose: () => void
}

const initialUserInput = {
  fullname: '',
  email: '',
  teamId: { title: '', value: '' },
  title: '',
  isVerify:false
}

const CreateUserModal = ({ onCancel, onClose }: ICreateUserModalProps) => {
  const { t } = useTranslation()
  const [createUser] = useCreateUserMutation()
  const [isLoading, setIsLoading] = useState(true)

  const [formChecked, setFormChecked] = useState(false)
  const [userInput, setUserInput] = useState({ ...initialUserInput })
  const [dropDownData, setDropDownData] = useState([])
  const [selectedOption, setSelectedOption] = useState({})
  const [getTeams] = useGetTeamsMutation()

  useEffect(() => {
    fetchDepartmentsData()
  }, [])

  const fetchDepartmentsData = async () => {
    const data = (await getTeams({})) as FixMeLater
    setDropDownData(data.data.data)
    setIsLoading(false)
  }

  const onOk = async () => {
    setFormChecked(true)
    const { isValid: isValidEmail } = emailValidation(userInput.email)
    const { isValid: isValidFullname } = requiredValidation(userInput.fullname)

    try {
      setIsLoading(true)
      if (isValidFullname && isValidEmail) {
        const res: FixMeLater = await createUser({
          ...{
            ...userInput,
            email: userInput.email?.trim(),
            fullname: userInput.fullname?.trim(),
            title: userInput.title?.trim(),
          },
          teamId: userInput.teamId.value,
        })
        
        if (res?.data?.success) {
          onClose()
        }
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeInput = (value: string | boolean, key: string) => {
    if (key === 'teamId') setSelectedOption(value)
    setUserInput({ ...userInput, [key]: value })
  }

  const onChangeCheckbox = (isVerify:boolean)=>{
    setUserInput({ ...userInput, isVerify:!isVerify })
  }

  const closeModal = () => {
    setFormChecked(false)
    onClose()
  }
  return (
    <CustomModal
      closeModal={closeModal}
      title={t(MODALS.CREATE_USER_TITLE)}
      onCancel={onCancel}
      loading={isLoading}
      overflow={'visible'}
      onOk={onOk}
    >
      <CreateUserForm
        {...userInput}
        dropDownData={dropDownData}
        selectedOption={selectedOption}
        formChecked={formChecked}
        onChangeInput={onChangeInput}
        onChangeCheckbox={onChangeCheckbox}
      />
     
    </CustomModal>
  )
}

export default CreateUserModal
