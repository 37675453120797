import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { useTranslation } from 'react-i18next'
import { FORM, PLACEHOLDERS } from 'locales/locales'
import styled from 'styled-components'
import { emailValidation, requiredValidation } from 'utils/helpers/validations'
import { FixMeLater } from 'types'
import Checkbox from 'components/checkbox/Checkbox'
import CustomText from 'components/text/CustomText'

interface IAddUserProps {
  formChecked: boolean
  fullname: string
  email: string
  teamId: FixMeLater
  title: string
  isVerify: boolean
  dropDownData?: Array<FixMeLater>
  selectedOption?: FixMeLater
  onChangeInput: (value: string, key: string) => void
  onChangeCheckbox: (value: boolean) => void
}

const CreateUserForm = ({
  formChecked,
  fullname,
  email,
  teamId,
  title,
  isVerify,
  dropDownData,
  selectedOption,
  onChangeInput,
  onChangeCheckbox
}: IAddUserProps) => {
  const { t } = useTranslation()

  const isNameValid = formChecked ? requiredValidation(fullname, t(FORM.FULLNAME)).errorText : ''
  const isValidEmail = formChecked ? emailValidation(email).errorText : ''
  return (
    <Wrapper>
   
      <CustomFormInput
        errorText={isNameValid}
        type={'text'}
        title={t(FORM.FULLNAME)}
        placeholder={t(PLACEHOLDERS.FULLNAME)}
        value={fullname}
        onChange={(value: string) => onChangeInput(value, 'fullname')}
      />
      <CustomFormInput
        errorText={isValidEmail}
        type={'text'}
        title={t(FORM.EMAIL)}
        placeholder={t(PLACEHOLDERS.EMAIL)}
        value={email}
        onChange={(value: string) => onChangeInput(value, 'email')}
      />
      <CustomFormInput
        type={'text'}
        title={t(FORM.TITLE)}
        placeholder={t(PLACEHOLDERS.TITLE)}
        value={title}
        onChange={(value: string) => onChangeInput(value, 'title')}
      />
      <CustomFormInput
        type={'text'}
        title={t(FORM.TEAM)}
        dropDownData={dropDownData}
        selectedOption={selectedOption}
        placeholder={t(PLACEHOLDERS.TEAM_PLACEHOLDER)}
        value={teamId?.value === '' ? '' : teamId}
        onChange={(value: string) => onChangeInput(value, 'teamId')}
      />
      <CheckboxWrapper>
      <Checkbox checked={isVerify} onChange={onChangeCheckbox} />
      <CustomText typography="body8">{t(PLACEHOLDERS.ISVERIFY)}</CustomText>
      </CheckboxWrapper>
     
    </Wrapper>
  )
}

export default CreateUserForm

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: visible;
  &::-webkit-scrollbar {
    display: none;
  }
`
const CheckboxWrapper =styled.div`
display: flex;
width:100%;
justify-content: end;
column-gap: 4px;
margin-top:25px;
`