export const presets = {
  preview: {
    image: { maxWidth: '200px', maxHeight: '150px', borderRadius: '6px', objectFit: 'scale-down' },
    video: { maxWidth: '200px', maxHeight: '150px', borderRadius: '6px', objectFit: 'scale-down' },
    other: { maxWidth: '200px', maxHeight: '150px', borderRadius: '6px' },
  },
  input: {
    image: { maxWidth: '80px', maxHeight: '80px', borderRadius: '2px', objectFit: 'contain' },
    video: { maxWidth: '80px', maxHeight: '80px', borderRadius: '2px', objectFit: 'contain' },
    other: { maxWidth: '80px', maxHeight: '80px', borderRadius: '2px' },
  },
  files: {
    image: { maxWidth: '32px', maxHeight: '32px', borderRadius: '4px', objectFit: 'contain' },
    video: { maxWidth: '32px', maxHeight: '32px', borderRadius: '4px', objectFit: 'contain' },
    other: { maxWidth: '32px', maxHeight: '32px', borderRadius: '4px' },
  },
  fullscreen: {
    image: { maxWidth: '80vw', maxHeight: '100%', borderRadius: '0px', objectFit: 'scale-down' },
    video: { maxWidth: '80vw', maxHeight: '100%', borderRadius: '0px', objectFit: 'scale-down' },
    other: { maxWidth: '32px', maxHeight: '32px', borderRadius: '4px' },
  },
  post: {
    image: { width: '100%', maxHeight: '250px', borderRadius: '8px', objectFit: 'cover' },
    video: { width: '100%', maxHeight: '250px', borderRadius: '8px', objectFit: 'cover' },
    other: { maxWidth: '64px', maxHeight: '64px', borderRadius: '4px' },
  },
  editPost: {
    image: { maxWidth: '168px', maxHeight: '105px', borderRadius: '5px', objectFit: 'cover' },
    video: { maxWidth: '168px', maxHeight: '105px', borderRadius: '5px', objectFit: 'cover' },
    other: { maxWidth: '32px', maxHeight: '32px', borderRadius: '4px' },
  },
  editPostResponsive: {
    image: { maxWidth: '150px', maxHeight: '105px', borderRadius: '5px', objectFit: 'cover' },
    video: { maxWidth: '150px', maxHeight: '105px', borderRadius: '5px', objectFit: 'cover' },
    other: { maxWidth: '32px', maxHeight: '32px', borderRadius: '4px' },
  },
  postInput: {
    image: { width: '80px', height: '80px', borderRadius: '10px', objectFit: 'cover' },
    video: { width: '80px', height: '80px', borderRadius: '10px', objectFit: 'cover' },
    other: { width: '80px', height: '80px', borderRadius: '0px' },
  },
}
