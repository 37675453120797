import CustomIcon from 'components/icon/CustomIcon'
import React from 'react'
import styled from 'styled-components'
import { icons } from 'utils/icons'
import { presets } from '../presets'
import Image from 'components/file/image/Image'

interface IVideoProps {
  source: string
  preset:
    | 'preview'
    | 'input'
    | 'files'
    | 'fullscreen'
    | 'post'
    | 'editPost'
    | 'postInput'
    | 'editPostResponsive'
  type: string
  thumbnail?: string
}

const Video = ({ source, preset, type, thumbnail}: IVideoProps) => {
  return (
    <div style={{ position: 'relative' }}>
      {(preset === 'preview' || preset === 'post') && (
        <OverLay>
          <CustomIcon iconPreset="large" source={icons.sendActiveRecordPlay} />
        </OverLay>
      )}

      {preset !== 'fullscreen' && thumbnail && <Image source={thumbnail} preset={preset} />}

      {(preset === 'fullscreen' || !thumbnail) && (
        <StyledVideo {...presets[preset].video} controls={preset === 'fullscreen'}>
          <source src={source}  />
          Your browser does not support the video tag.
        </StyledVideo>
      )}
    </div>
  )
}

export default React.memo(Video, (prevProps, nextProps) => {
  return prevProps.preset === nextProps.preset
})

const StyledVideo = styled.video.attrs(
  (props: { maxWidth: string; maxHeight: string; borderRadius: string; objectFit: string }) =>
    props,
)`
  width: ${({ maxWidth }) => maxWidth};
  max-height: ${({ maxHeight }) => maxHeight};
  object-fit: ${({ objectFit }) => objectFit};
  border-radius: ${({ borderRadius }) => borderRadius};
`

const OverLay = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`
