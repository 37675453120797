import { ERRORS } from 'locales/locales'
import { FixMeLater } from 'types'
import { t } from 'i18next'
import { passwordValidator } from './passwordValidator'
import colors from 'utils/colors'

const PHONE_NUMBER_REGEX =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]/

export const emailValidation = (email: string) => {
  let validationObj = {
    isValid: true,
    errorText: '',
  }

  if (!email) {
    validationObj = { errorText: t(ERRORS.EMAIL_EMPTY), isValid: false }
  } else if (!EMAIL_REGEX.test(email)) {
    validationObj = { errorText: t(ERRORS.EMAIL_INVALID), isValid: false }
  }

  return validationObj
}

export const phoneNumberValidation = (phoneNumber: string) => {
  let validationObj = {
    isValid: true,
    errorText: '',
  }
  if (phoneNumber.trim() === '') return validationObj
  if (phoneNumber && phoneNumber.trim().length > 12) {
    validationObj = { errorText: t(ERRORS.PHONE_NUMBER_ERROR), isValid: false }
  } else if (!PHONE_NUMBER_REGEX.test(phoneNumber)) {
    validationObj = { errorText: t(ERRORS.PHONE_NUMBER_ERROR), isValid: false }
  }

  return validationObj
}

export const requiredValidation = (input: string, fieldName = '') => {
  let validationObj = {
    isValid: true,
    errorText: '',
  }

  if (input.trim().length === 0) {
    validationObj = {
      errorText: t(ERRORS.INPUT_REQUIRED, { fieldName: fieldName }),
      isValid: false,
    }
  }

  return validationObj
}

export const confirmPasswordValidation = (password: string, confirmPassword: string) => {
  let validationObj = {
    isValid: true,
    errorText: '',
    confirmError: '',
  }
  if (password.trim().length === 0) {
    validationObj = {
      errorText: t(ERRORS.PASSWORD_EMPTY),
      isValid: false,
      confirmError: ' ',
    }
  } else if (password.trim() !== confirmPassword.trim()) {
    validationObj = {
      errorText: ' ',
      isValid: false,
      confirmError: t(ERRORS.PASSWORD_DONT_MATCH),
    }
  }
  return validationObj
}

export const mailSettingsValidation = (mailSettings: FixMeLater) => {
  for (const key in mailSettings) {
    if (mailSettings[key] === null || mailSettings[key] === '') return false
  }
  return true
}

export const generatePasswordError = (passwordSettings: FixMeLater, password: string) => {
  const text =
    Object.keys(passwordSettings).length !== 0
      ? `${t(ERRORS.PASSWORD_NEEDS)} ${
          passwordSettings.mincharactes &&
          t(ERRORS.PASSWORD_CHAR_LONG, {
            char_length: passwordSettings.mincharactes,
          })
        } 
   ${
     passwordSettings.uniquecharacters &&
     t(ERRORS.PASSWORD_UNIQUE_LEAST, {
       unique_length: passwordSettings.uniquecharacters,
     })
   }, ${passwordSettings.uppercase ? t(ERRORS.PASSWORD_UPPER_CASE) : ''} ${
          passwordSettings.lowercase ? t(ERRORS.PASSWORD_LOWER_CASE) : ''
        } ${passwordSettings.letter ? t(ERRORS.PASSWORD_LETTER) : ''} ${
          passwordSettings.number ? t(ERRORS.PASSWORD_NUMBER) : ''
        } ${t(ERRORS.PASSWORD_END)}`
      : ''
  if (password.trim().length === 0)
    return {
      errorText: text,
      color: colors.gray80,
    }
  if (passwordSettings && Object.keys(passwordSettings).length) {
    return {
      color: passwordValidator(password, passwordSettings).valid ? colors.vime : colors.red,
      errorText: text,
    }
  }
  return { errorText: '', color: '' }
}
