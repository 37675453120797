import { memo } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'

interface IHeaderProps {
  type: string
  avatar: string
  fullname: string
  title: string
  team: string
  groupName?: string
}

const Header = ({ type, avatar, fullname, title, team, groupName }: IHeaderProps) => {
  if (type === 'GM')
    return (
      <Wrapper>
        <StyledText typography="header1">{groupName}</StyledText>
      </Wrapper>
    )
  return (
    <Wrapper>
      <Avatar source={avatar} text={fullname} size={AvatarSize.extraLarge} />
      <UserInfoWrapper gap={12}>
        <StyledText typography="header1">{fullname}</StyledText>
        <UserInfoWrapper gap={4}>
          <CustomText typography="body3" color={colors.gray80}>
            {title}
          </CustomText>
          <CustomText typography="body3" color={colors.gray80}>
            {team}
          </CustomText>
        </UserInfoWrapper>
      </UserInfoWrapper>
    </Wrapper>
  )
}

export default memo(Header)

const Wrapper = styled.div`
  display: flex;
  column-gap: 20px;
`
const StyledText = styled(CustomText)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: normal; 
`;


const UserInfoWrapper = styled.div.attrs((props: { gap: number }) => props)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  row-gap: ${({ gap }) => gap || 0}px;
`
