import fonts from 'utils/fonts'

export const customTextTypography = {
  header0: {
    fontSize: fonts.fonstSizeXL,
    lineHeight: fonts.lineHeightL,
    fontWeight: fonts.fontWeightSemiBold,
  },
  header1: {
    fontSize: fonts.fontSizeL,
    lineHeight: fonts.lineHeightL,
    fontWeight: fonts.fontWeightSemiBold,
  },
  header2: {
    fontSize: fonts.fontSizeM,
    lineHeight: fonts.lineHeightL,
    fontWeight: fonts.fontWeightSemiBold,
  },
  header3: {
    fontSize: fonts.fontSizeS,
    lineHeight: fonts.lineHeightS,
    fontWeight: fonts.fontWeightSemiBold,
  },
  header4: {
    fontSize: fonts.fontSizeXS,
    lineHeight: fonts.lineHeightXS,
    fontWeight: fonts.fontWeightBold,
  },
  header5: {
    fontSize: fonts.fontSizeXXS,
    lineHeight: fonts.lineHeightXXS,
    fontWeight: fonts.fontWeightBold,
  },
  body1: {
    fontSize: fonts.fontSizeL,
    lineHeight: fonts.lineHeightL,
    fontWeight: fonts.fontWeightRegular,
  },
  body2: {
    fontSize: fonts.fontSizeM,
    lineHeight: fonts.lineHeightM,
    fontWeight: fonts.fontWeightRegular,
  },
  body3: {
    fontSize: fonts.fontSizeS,
    lineHeight: fonts.lineHeightS,
    fontWeight: fonts.fontWeightRegular,
  },
  body4: {
    fontSize: fonts.fontSizeS,
    lineHeight: fonts.lineHeightS,
    fontWeight: fonts.fontWeightSemiBold,
  },
  body5: {
    fontSize: fonts.fontSizeXS,
    lineHeight: fonts.lineHeightXS,
    fontWeight: fonts.fontWeightSemiBold,
  },
  body6: {
    fontSize: fonts.fontSizeXS,
    lineHeight: fonts.lineHeightXS,
    fontWeight: fonts.fontWeightMedium,
  },
  body7: {
    fontSize: fonts.fontSizeXXS,
    lineHeight: fonts.lineHeightXXS,
    fontWeight: fonts.fontWeightMedium,
  },
  body8: {
    fontSize: fonts.fontSizeXS,
    lineHeight: fonts.lineHeightXS,
    fontWeight: fonts.fontWeightRegular,
  },
  body9: {
    fontSize: fonts.fontSizeS,
    lineHeight: fonts.lineHeightS,
    fontWeight: fonts.fontWeightMedium,
  },
}

export type CustomTextTypographyKey = keyof typeof customTextTypography
