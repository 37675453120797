import CustomFile from 'components/file/CustomFile'
import CustomText from 'components/text/CustomText'
import styled from 'styled-components'
import { FixMeLater } from 'types'
import { IUserData } from 'types/user.types'
import { updateContentText } from 'utils/helpers/messageHelper'

interface IContentProps {
  data: {
    header?:string
    text: string
    files: FixMeLater[]
    mentionedUsers: Array<string>
  }
  onShowUserInfo: (userId: string) => void
  users: IUserData
  isCollapsible?: boolean
}

const Content = ({ data, onShowUserInfo, users, isCollapsible = false }: IContentProps) => {
  const textByRepliedUsers: FixMeLater = updateContentText(
    data?.text,
    data?.mentionedUsers,
    undefined,
    users,
    onShowUserInfo,
  )

  return (
    <Wrapper>
      {data?.header && (
        <CustomText textAlign="center" typography="header1" isCollapsible={isCollapsible}>
          {data.header}
        </CustomText>
      )}
      <br />
      <CustomText typography="body2" isCollapsible={isCollapsible}>{textByRepliedUsers}</CustomText>
      {data?.files?.length > 0 && (
        <GridLayout onClick={(event) => event.stopPropagation()}>
          {data.files.map((file: FixMeLater, index: number) => {
            
            const url: string = file?.url || URL.createObjectURL(file)
            const name = file.originalName
            const mimetype = file?.mimetype || file.type
            const size = file?.size
            return (
              <FileMessageWrapper key={file?.lastModified || index}>
                <CustomFile
                  index={index}
                  files={data.files}
                  sentAt={file.sentAt}
                  source={url}
                  mimetype={mimetype}
                  preset="post"
                  size={size}
                  filename={name}
                  thumbnail={file?.thumbnail}
                />
              </FileMessageWrapper>
            )
          })}
        </GridLayout>
      )}
    </Wrapper>
  )
}

export default Content

const Wrapper = styled.div`
  margin: 16px 0px;
`

const GridLayout = styled.div`
  display: grid;
  gap: 10px;

  div:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
  }

  div:nth-child(2) {
    grid-column: 2;
    grid-row: 1;
  }

  div:nth-child(3) {
    grid-column: span 2;
    grid-row: 2 / 3;
  }

  div:nth-child(4) {
    grid-column: 1 / 2;
    grid-row: 2 / 4;
  }
`

const FileMessageWrapper = styled.div`
  margin-top: 10px;
  position: relative;
  #cancel-file {
    display: flex;
  }
  #loading-file {
    display: none;
  }
`
