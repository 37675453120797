import { FixMeLater } from 'types'
import { AnyAction, Dispatch, ThunkDispatch } from '@reduxjs/toolkit'

import { DELETE_OTR, LOGIN, LOGOUT } from 'utils/constants'
import { roomsActions } from 'app/slices/rooms.slice'
import { authActions } from 'app/slices/auth.slice'

export const broadcastMessageFactory = ({
  dispatch,
  navigate,
}: {
  dispatch: ThunkDispatch<FixMeLater, undefined, AnyAction> & Dispatch<AnyAction>
  navigate: FixMeLater
}) => {
  return {
    onMessage: ({ data: { type, payload } }: MessageEvent) => {
      switch (type) {
        case DELETE_OTR:
          dispatch(roomsActions.removeConversation({ _id: payload.roomId }))
          break
        case LOGOUT:
          dispatch({ type: 'RESET' })
          break
        case LOGIN:
          dispatch(authActions.login(payload))
          navigate('/news-feed')
      }
    },
  }
}
