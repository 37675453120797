import styled from 'styled-components'
import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import { useTranslation } from 'react-i18next'
import { BUTTONS, MESSAGES } from 'locales/locales'
import { IGroup } from 'types/group.types'
import { useMemo } from 'react'

interface IArchivedProps {
  roomId: string
  groups: IGroup[]
  isThread: boolean
  notSubscribed?: boolean
  onUnarchiveChannel?: () => void
}

const Archived = ({
  groups,
  isThread,
  notSubscribed,
  roomId,
  onUnarchiveChannel,
}: IArchivedProps) => {
  const { t } = useTranslation()
  const groupOfChannel = useMemo(() => {
    return groups.find((group) => {
      if (group.channels.some((channel) => channel._id === roomId)) {
        return group
      }
    })
  }, [roomId, groups])
  return (
    <Wrapper>
      <CustomText typography="body3" textAlign="center">
        {t(MESSAGES.ARCHIVED_CHANNEL_DESCRIPTION)}
      </CustomText>
      {!isThread && !notSubscribed && !groupOfChannel?.isArchived && (
        <CustomButton
          text={t(BUTTONS.DISAPPEAR_CHANNEL)}
          textColor={colors.white}
          size="medium"
          onClick={onUnarchiveChannel}
        />
      )}
    </Wrapper>
  )
}

export default Archived

const Wrapper = styled.div`
  display: flex;
  height: 120px;
  border-top: 1px solid ${colors.light80};
  align-items: center;
  justify-content: center;
  column-gap: 15px;
`
