import styled from 'styled-components'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { BUTTONS } from 'locales/locales'

import CustomButton from 'components/button/CustomButton'
import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'

interface IInfoModalProps {
  title: string
  description: string
  cancelTitle?: string
  okTitle?: string
  visible?: boolean
  closeModal: () => void
  onCancel?: () => void
  onOk?: () => void
}

const InfoModal = ({
  title,
  description,
  cancelTitle,
  okTitle,
  visible,
  closeModal,
  onCancel,
  onOk,
}: IInfoModalProps) => {
  const { t } = useTranslation()
  const modalRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (visible && modalRef?.current) {
      modalRef?.current?.focus()
    }
  }, [visible])

  const handleEscape = (event: { code: string }) => {
    if (event.code === 'Escape') {
      closeModal()
    }
  }

  if (!visible) return null
  return (
    <ModalContainer tabIndex={0} onKeyDown={handleEscape} ref={modalRef}>
      <ModalWrapper>
        <ModalHeader>
          <TitleText typography="header2" color={colors.gray100}>
            {title}
          </TitleText>
          <CloseIconButton onClick={closeModal} source={icons.closeIcon} />
        </ModalHeader>
        <ModalContent>
          <CustomText typography="header2" color={colors.gray100}>
            {description}
          </CustomText>
        </ModalContent>
        <ModalBottom>
          {onCancel && (
            <CustomButton
              type="secondary"
              typography="body4"
              textColor={colors.gray100}
              onClick={onCancel}
              text={cancelTitle || t(BUTTONS.CANCEL)}
            />
          )}
          {onOk && (
            <CustomButton onClick={onOk} typography="body4" text={okTitle || t(BUTTONS.YES)} />
          )}
        </ModalBottom>
      </ModalWrapper>
    </ModalContainer>
  )
}

export default InfoModal

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.transparentBackground};
`

const ModalWrapper = styled.div`
  border-radius: 0.5rem;
  width: 440px;
  background-color: ${colors.white};
`

const ModalHeader = styled.div`
  padding: 20px 20px 0px 20px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.light80};
`
const ModalBottom = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
  padding: 20px 20px;
`
const ModalContent = styled.div`
  padding: 20px;
`

const CloseIconButton = styled(CustomIcon)`
  height: 24px;
`

const TitleText = styled(CustomText)`
  margin-bottom: 20px;
`
