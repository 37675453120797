import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'

import colors from 'utils/colors'
import { UserStatus } from 'types/user.types'
import { NAVIGATION, USER_STATUS } from 'locales/locales'
import { useUpdateUserStatusMutation } from 'app/services/user.service'
import ServiceWorkerHelpers from '../../../utils/helpers/notification'
import CustomText from 'components/text/CustomText'
import Separator from 'components/separator/Separator'
import { clientChannel, LOGOUT } from 'utils/constants'
import { useLogoutMutation } from 'app/services/auth.service'
import { useEffect, useState } from 'react'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import { socket } from 'app/socket/socketContext'

const Status = ({
  status,
  text,
  onClick,
}: {
  status: string
  text: string
  onClick: (status: string) => void
}) => {
  const onClickStatus = () => {
    onClick(status)
  }

  return (
    <StatusWrapper onClick={onClickStatus}>
      <StatusColor status={status} />
      <CustomText>{text}</CustomText>
    </StatusWrapper>
  )
}

const Options = ({
  onOpenAccountSettings,
  onHide,
}: {
  onOpenAccountSettings: () => void
  onHide: () => void
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)
  const { unsubscribeFromPushService } = ServiceWorkerHelpers

  const [updateUserStatus] = useUpdateUserStatusMutation()
  const [logout] = useLogoutMutation()
  const [windowApi,setWindowApi] = useState<any>(null);

  const hasAdminRights = () => {
    return permissions.settings > 0 || permissions.userManagement > 0 ||
      isFlagSetIn([
        TeamAndChannelPermissions.CREATE_OR_ARCHIVE_TEAM,
        TeamAndChannelPermissions.REMOVE_TEAM_FROM_ARCHIVE,
        TeamAndChannelPermissions.UPDATE_TEAM,
        TeamAndChannelPermissions.CREATE_OR_ARCHIVE_CHANNEL,
        TeamAndChannelPermissions.UPDATE_CHANNEL
      ], permissions.teamAndChannel);
  }
  const isAdmin = hasAdminRights();

  const onUpdateUserStatus = (status: string) => {
    updateUserStatus({ status })
    onHide()
  }

  useEffect(()=>{
    const w = window as any;
    if (w.api)
      setWindowApi(w.api);
  },[])

  const onLogout = async () => {
    onHide()
    clientChannel.postMessage({ type: LOGOUT })
    await logout({})
    unsubscribeFromPushService()
    dispatch({ type: 'RESET' })
    if (socket && socket.connected) {
      socket.disconnect();
    }
    if(windowApi) {
      windowApi.send('sendLogout',true);
    }
  }
  const onNavigateToSettings = () => {
    navigate('/system-settings/dashboard')
    onHide()
  }
  return (
    <>
      <Wrapper>
        <Status
          status={UserStatus.ONLINE}
          text={t(USER_STATUS.ONLINE)}
          onClick={onUpdateUserStatus}
        />
        <Status status={UserStatus.AWAY} text={t(USER_STATUS.AWAY)} onClick={onUpdateUserStatus} />
        <Status
          status={UserStatus.DONOTDISTURB}
          text={t(USER_STATUS.DO_NOT_DISTURB)}
          onClick={onUpdateUserStatus}
        />
        <Status
          status={UserStatus.OFFLINE}
          text={t(USER_STATUS.OFFLINE)}
          onClick={onUpdateUserStatus}
        />
        <Separator margin={{ top: 5, bottom: 5 }} />
        <BottomText cursor="pointer" onClick={onOpenAccountSettings}>
          {t(NAVIGATION.ACCOUNT_SETTINGS)}
        </BottomText>
        {isAdmin && (
          <BottomText cursor="pointer" onClick={onNavigateToSettings}>
            {t(NAVIGATION.SETTINGS)}
          </BottomText>
        )}
        <BottomText color={colors.red} cursor="pointer" onClick={onLogout}>
          {t(NAVIGATION.LOGOUT)}
        </BottomText>
      </Wrapper>
      <BackDrop onClick={onHide} />
    </>
  )
}

export default Options

const Wrapper = styled.div`
  position: absolute;
  left: 40px;
  top: 80px;
  background-color: ${colors.white};
  z-index: 7;
  border: 1px solid ${colors.light80};
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
`

const BottomText = styled(CustomText)`
  padding: 6px 20px 6px 20px;
  white-space: nowrap;
  &:hover {
    background-color: ${colors.light50};
  }
`

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px 6px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.light50};
  }
`

const StatusColor = styled.span.attrs((props: { status: string }) => props)`
  border: ${({ status }) => (status === 'OFFLINE' ? '1px solid black' : '1px solid transparent')};
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: ${({ status }) =>
    status === 'ONLINE'
      ? `${colors.green}`
      : status === 'AWAY'
      ? `${colors.yellow}`
      : status === 'DONOTDISTURB'
      ? `${colors.red}`
      : status === 'OFFLINE'
      ? 'transparent'
      : ''};
`

export const BackDrop = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 6;
`
