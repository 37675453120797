import { forwardRef, ReactNode } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { UserStatus } from 'types/user.types'

import CustomText from 'components/text/CustomText'

interface IStyledProps {
  size?: number
  type?: string
  isError?: boolean
  isLoad?: boolean
  color?: string
  onClick?: () => void
}

interface IAvatarProps {
  source?: string
  status?: UserStatus
  size: AvatarSize
  type?: 'circle'
  text?: string
  disabled?: boolean
  className?: string
  children?: ReactNode
  color?: string
  onClick?: () => void
}

export enum AvatarSize {
  XXL = 96,
  extraLarge = 75,
  large = 48,
  medium = 40,
  average = 32,
  small = 24,
}

const Avatar = forwardRef<HTMLDivElement, IAvatarProps>(
  ({ source, type, status, size, className, text = '', children = '', color, onClick }, ref) => {
    const onError = (event: FixMeLater) => {
      event.target.style.display = 'none'
    }
    const onLoad = (event: FixMeLater) => {
      event.target.style.display = 'flex'
    }

    const result = text?.split(' ')
    const combinedText = !isNaN(+text)
      ? text
      : result?.[0]?.charAt(0)?.toUpperCase() + (result?.[1]?.charAt(0)?.toUpperCase() || '')
    return (
      <AvatarWrapper
        type={type}
        className={className}
        size={size}
        onClick={onClick}
        color={color}
        ref={ref}
      >
        <TextWrapper>
          <CustomText typography="body5" color={colors.white}>
            {combinedText}
          </CustomText>
        </TextWrapper>
        {source && (
          <StyledAvatar type={type} size={size} src={source} onError={onError} onLoad={onLoad} />
        )}
        {children}
        {status && status !== UserStatus.OFFLINE && <AvatarStatus type={type} status={status} />}
      </AvatarWrapper>
    )
  },
)

Avatar.displayName = 'Avatar'

export default Avatar

const AvatarWrapper = styled.div`
  display: flex;
  position: relative;
  border-radius: ${(props: IStyledProps) => (props.type == 'circle' ? '50%' : '4px')};
  background-color: ${(props: IStyledProps) => props.color || colors.mainVime};
  width: ${(props: IStyledProps) => props.size}px;
  height: ${(props: IStyledProps) => props.size}px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: ${(props: IStyledProps) => (props.onClick ? 'pointer' : 'inherit')}; ;
`
const TextWrapper = styled.div`
  position: absolute;
`

const StyledAvatar = styled.img`
  overflow: hidden;
  position: absolute;
  object-fit: cover;
  border-radius: ${(props: IStyledProps) => (props.type == 'circle' ? '50%' : '4px')};
  width: ${(props: IStyledProps) => props.size}px;
  height: ${(props: IStyledProps) => props.size}px;
`

const AvatarStatus = styled.div.attrs((props: { status: string }) => props)`
  position: absolute;
  z-index: 4;
  right: ${(props: IStyledProps) => (props.type == 'circle' ? '0px' : '-2px')};
  bottom: ${(props: IStyledProps) => (props.type == 'circle' ? '0px' : '-2px')};
  width: ${(props: IStyledProps) => (props.type == 'circle' ? '10px' : '5px')};
  height: ${(props: IStyledProps) => (props.type == 'circle' ? '10px' : '5px')};
  border: 1.5px solid
    ${(props: IStyledProps) => (props.type == 'circle' ? colors.vimeDark : colors.light40)};
  border-radius: 50%;
  box-sizing: content-box;
  background-color: ${({ status }) =>
    status === 'ONLINE'
      ? `${colors.green}`
      : status === 'AWAY'
      ? `${colors.yellow}`
      : status === 'DONOTDISTURB'
      ? `${colors.red}`
      : status === 'OFFLINE'
      ? 'transparent'
      : ''};
`
