import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  useLazyGetPaginatedUsersQuery,
  useUpdateUserArchiveMutation,
} from 'app/services/user.service'

import { FixMeLater } from 'types'
import { IUser } from 'types/user.types'
import colors from 'utils/colors'
import { RESEND_INVITE_DESCRIPTION, CONFIRM_RESEND_INVITE } from 'utils/constants'
import UserInfoModal from './user-info/UserInfoModal'
import CreateUserModal from './create-user/CreateUserModal'
import useInfoModal from 'utils/hooks/useInfoModal'
import SettingsHeader from 'features/settings/SettingsHeader'
import { BUTTONS, DROPDOWN, MODALS, TABLE, TITLES } from 'locales/locales'

import CustomButton from 'components/button/CustomButton'
import Loading from 'components/spinner/Loading'
import { useGetTeamsMutation } from 'app/services/group.service'
import { useResendInviteMutation } from 'app/services/invite.service'
import KGrid from 'components/kendo/grid/KGrid'
import { GridColumn } from '@progress/kendo-react-grid'
import { Button } from '@progress/kendo-react-buttons'
import { FaCheck, FaEnvelope, FaInfoCircle, FaTrash } from 'react-icons/fa'
import { TableWrapper } from '../channel-management/ChannelManagement'

export const USER_DROPDOWN_DATA = [
  {
    title: t(DROPDOWN.ALL_USERS),
    value: 'all_user',
  },
  {
    title: t(DROPDOWN.ACTIVE_USERS),
    value: 'active_user',
  },
  {
    title: t(DROPDOWN.DEACTIVE_USERS),
    value: 'deactive_user',
  },
]

const queryBodyInitial = {
  search: '',
  filter: '',
  sort: false,
  column: 'createdAt',
  pageNumber: 1,
  limit: 10,
}

const UserManagement = () => {
  const { t } = useTranslation()
  const [userId, setUserId] = useState('')
  const { openModal, closeModal, Modal } = useInfoModal()
  const [currentData, setCurrentData] = useState({ data: [] as FixMeLater[], totalCount: 1 })
  const [isCreateModalVisible, setisCreateModalVisible] = useState(false)
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [queryBody, setQueryBody] = useState(queryBodyInitial)
  const [teams, setTeams] = useState([])
  const [getPaginatedUsers] = useLazyGetPaginatedUsersQuery()
  const [updateUserArchiveById] = useUpdateUserArchiveMutation()
  const [resendInviteApi] = useResendInviteMutation()
  const [getTeams] = useGetTeamsMutation()
  useEffect(() => {
    fetchUsers()
    fetchTeams()
  }, [queryBody])

  const fetchTeams = async () => {
    try {
      const deparments = (await getTeams({})) as FixMeLater
      setTeams(deparments.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  const fetchUsers = async () => {
    try {
      const data = await getPaginatedUsers({ ...queryBody })
      const newArr = data.data?.users.map((user: IUser) => ({
        ...user,
        actions: {
          actionKey: !user?.isDeleted
            ? user?.enableResendInvite
              ? { text: t(TABLE.USER_RESEND_INVITE), color: colors.vime, icon: FaEnvelope }
              : { text: t(TABLE.USER_DEACTIVE), color: colors.red, icon: FaTrash }
            : { text: t(TABLE.USER_ACTIVE), color: colors.vime, icon: FaCheck },
        },
      }))
      if (data.data) setCurrentData({ data: newArr, totalCount: data.data?.totalCount || 0 })
    } catch (error) {
      console.log(error)
    }
  }

  const onToggleArchive = (params: IUser, index: number) => {
    const { enableResendInvite, isDeleted, email } = currentData.data[index]
 
    openModal({
      title: !enableResendInvite
        ? isDeleted
          ? t(MODALS.ACTIVE_USER_TITLE)
          : t(MODALS.INACTIVE_USER_TITLE)
        : t(BUTTONS.RESEND_INVITE),
      description: !enableResendInvite
        ? isDeleted
          ? t(MODALS.ACTIVE_USER_DESCRIPTION)
          : t(MODALS.INACTIVE_USER_DESCRIPTION)
        : t(BUTTONS.RESEND_INVITE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: onCancelDeleteUser,
      onOk: () =>
        enableResendInvite
          ? resendInviteApi({ email }).finally(() => {
              closeModal()
            })
          : onConfirmDeleteUser(params, !isDeleted),
    })
  }

  const onCloseCreateUserModal = () => {
    setisCreateModalVisible(false)
    fetchUsers()
  }

  const onEdit = (params: IUser) => {
    setUserId(params._id)
    setIsInfoModalVisible(true)
  }

  const onResendInvite = (params: IUser) => {
    setUserId(params._id)
    openModal({
      title: CONFIRM_RESEND_INVITE,
      description: RESEND_INVITE_DESCRIPTION,
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.SAVE) as string,
      onCancel: closeModal,
      onOk: () => onConfirmResendInvite(params.email),
    })
  }

  const onAction = (params: IUser, index: number) => {
    if (params.enableResendInvite) {
      onResendInvite(params)
    } else {
      onToggleArchive(params, index)
    }
  }

  const onConfirmDeleteUser = async (user: IUser, isDeleted: boolean) => {
    try {
      Loading.ref.show()
      const userId = user._id
      await updateUserArchiveById({ userId, isDeleted })
      fetchUsers()
    } finally {
      closeModal()
      Loading.ref.hide()
    }
  }

  const onConfirmResendInvite = async (email: string) => {
    try {
      Loading.ref.show()
      await resendInviteApi({ email })
      closeModal()
      fetchUsers()
    } finally {
      Loading.ref.hide()
    }
  }

  const onCancelDeleteUser = () => {
    closeModal()
  }

  const onClickAddNewUser = () => {
    setisCreateModalVisible(true)
  }

  const onCloseInfoModal = () => {
    setIsInfoModalVisible(false)
  }

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  })

  const ActionCell: React.FC<FixMeLater> = ({ dataItem, dataIndex }) => {
    const { t } = useTranslation()
    return (
      <td>
        <ButtonContainer>
          <Button
            style={{ color: dataItem.actions.actionKey.color }}
            onClick={() => {
              onAction(dataItem, dataIndex)
            }}
            aria-label={t(dataItem.actions.actionKey.text)}
          >
            {dataItem.actions.actionKey.icon && <dataItem.actions.actionKey.icon />}
            {t(dataItem.actions.actionKey.text)}
          </Button>
          <Button
            onClick={() => {
              onEdit(dataItem)
            }}
            aria-label={t(dataItem.actions.actionKey.text)}
          >
            <FaInfoCircle />
            {'Info' /*t(TABLE.USER_INFO)*/}
          </Button>
        </ButtonContainer>
      </td>
    )
  }

  const handleFilterChange = (event: FixMeLater) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    })
  }

  const onDataStateChange = (event: FixMeLater) => {
    const updatedState = {
      ...dataState,
      skip: event.dataState.skip,
      take: event.dataState.take,
      sort: event.dataState.sort,
    }
    setDataState(updatedState)
  }

  useEffect(() => {

    let searchText = ''
    if (dataState.filter && dataState.filter?.filters) {
      dataState.filter.filters.forEach((filter: FixMeLater) => {
        if (filter.field === 'fullname' || filter.field === 'email') {
          searchText += filter.value
        }
      })
    }
    queryBody.search = searchText

    let sortColumn = ''
    let sortDirection = false
    if (dataState.sort) {
      dataState.sort.forEach((sort: FixMeLater) => {
        sortColumn = sort.field
        if (sort.dir === 'asc') {
          sortDirection = true
        }
        return false
      })

      if (sortColumn != '') {
        queryBody.column = sortColumn
        queryBody.sort = sortDirection
      }
    }
    
    queryBody.pageNumber = dataState.skip / dataState.take + 1

    setQueryBody({ ...queryBody })
  }, [dataState])

  return (
    <Container>
      <Wrapper>
        <TopContainer>
          <SettingsHeader title={t(TITLES.USER_SETTINGS_TITLE)} />
          <CustomButton
            text={t(BUTTONS.CREATE_USER)}
            textColor="white"
            onClick={onClickAddNewUser}
          />
        </TopContainer>
        <TableWrapper>
          <KGrid
            //loading={loading}
            datasource={currentData}
            dataState={dataState}
            pageable={true}
            total={currentData.totalCount | 0}
            filterable={true}
            editMode={false}
            sortable={true}
            onFilterChange={handleFilterChange}
            onDataStateChange={onDataStateChange}
          >
            <GridColumn field="fullname" title={t(TABLE.USER_FULLNAME)}></GridColumn>
            <GridColumn field="email" title={t(TABLE.USER_EMAIL)}></GridColumn>
            <GridColumn
              filterable={false}
              sortable={false}
              field="isDeleted"
              title={t(TABLE.USER_DELETED)}
            ></GridColumn>
            <GridColumn
              filterable={false}
              sortable={false}
              field="role"
              title={t(TABLE.USER_TYPE)}
            ></GridColumn>
            <GridColumn
              field="actions"
              filterable={false}
              title={t(TABLE.USER_ACTIONS)}
              cell={(props) => <ActionCell {...props} />}
            />
          </KGrid>
        </TableWrapper>
        {isCreateModalVisible && (
          <CreateUserModal onCancel={onCloseCreateUserModal} onClose={onCloseCreateUserModal} />
        )}
        {isInfoModalVisible && (
          <UserInfoModal
            userId={userId}
            teams={teams}
            onClose={onCloseInfoModal}
            fetchUsers={fetchUsers}
          />
        )}
        {Modal}
      </Wrapper>
    </Container>
  )
}

export default UserManagement

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  button span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.8em;
  }
`
