import adminDashboardIcon from 'assets/icons/dashboard_white_icon.png'
import adminDashboardIcon2x from 'assets/icons/dashboard_white_icon@2x.png'
import adminDashboardIcon3x from 'assets/icons/dashboard_white_icon@3x.png'
import adminDashboardIcon4x from 'assets/icons/dashboard_white_icon@4x.png'
import adminSettingsIcon from 'assets/icons/settings_white_icon.png'
import adminSettingsIcon2x from 'assets/icons/settings_white_icon@2x.png'
import adminSettingsIcon3x from 'assets/icons/settings_white_icon@3x.png'
import adminSettingsIcon4x from 'assets/icons/settings_white_icon@4x.png'
import articleIcon from 'assets/icons/article_icon.png'
import articleIcon2x from 'assets/icons/article_icon@2x.png'
import articleIcon3x from 'assets/icons/article_icon@3x.png'
import articleIcon4x from 'assets/icons/article_icon@4x.png'
import attachmentIcon from 'assets/icons/attachment_icon.png'
import attachmentIcon2x from 'assets/icons/attachment_icon@2x.png'
import attachmentIcon3x from 'assets/icons/attachment_icon@3x.png'
import attachmentIcon4x from 'assets/icons/attachment_icon@4x.png'
import bellIcon from 'assets/icons/bell_icon.png'
import bellIcon2x from 'assets/icons/bell_icon@2x.png'
import bellIcon3x from 'assets/icons/bell_icon@3x.png'
import bellIcon4x from 'assets/icons/bell_icon@4x.png'
import bellIconMd from 'assets/icons/icon_bell_md.png'
import bellIconMd2x from 'assets/icons/icon_bell_md@2x.png'
import bellIconMd3x from 'assets/icons/icon_bell_md@3x.png'
import bellIconMd4x from 'assets/icons/icon_bell_md@4x.png'
import chevronDownIconSmall from 'assets/icons/chevron_down_small.png'
import chevronDownIconSmall2x from 'assets/icons/chevron_down_small@2x.png'
import chevronDownIconSmall3x from 'assets/icons/chevron_down_small@3x.png'
import chevronDownIconSmall4x from 'assets/icons/chevron_down_small@4x.png'
import chevronDownMid from 'assets/icons/chevron_down_mid.png'
import chevronDownMid2x from 'assets/icons/chevron_down_mid@2x.png'
import chevronDownMid3x from 'assets/icons/chevron_down_mid@3x.png'
import chevronDownMid4x from 'assets/icons/chevron_down_mid@4x.png'
import chevronDownIconBig from 'assets/icons/chevron_down.png'
import chevronDownIconBig2x from 'assets/icons/chevron_down@2x.png'
import chevronDownIconBig3x from 'assets/icons/chevron_down@3x.png'
import chevronDownIconBig4x from 'assets/icons/chevron_down@4x.png'
import chevronDownIconBlue from 'assets/icons/chevron_down_blue_icon.png'
import chevronDownIconBlue2x from 'assets/icons/chevron_down_blue_icon@2x.png'
import chevronDownIconBlue3x from 'assets/icons/chevron_down_blue_icon@3x.png'
import chevronDownIconBlue4x from 'assets/icons/chevron_down_blue_icon@4x.png'
import chevronRightIconBig from 'assets/icons/chevron_right.png'
import chevronRightIconBig2x from 'assets/icons/chevron_right@2x.png'
import chevronRightIconBig3x from 'assets/icons/chevron_right@3x.png'
import chevronRightIconBig4x from 'assets/icons/chevron_right@4x.png'
import chevronLeftIconBig from 'assets/icons/chevron_left.png'
import chevronLeftIconBig2x from 'assets/icons/chevron_left@2x.png'
import chevronLeftIconBig3x from 'assets/icons/chevron_left@3x.png'
import chevronLeftIconBig4x from 'assets/icons/chevron_left@4x.png'
import chevronUpIconSmall from 'assets/icons/chevron_up_small.png'
import chevronUpIconSmall2x from 'assets/icons/chevron_up_small@2x.png'
import chevronUpIconSmall3x from 'assets/icons/chevron_up_small@3x.png'
import chevronUpIconSmall4x from 'assets/icons/chevron_up_small@4x.png'
import chevronUpMid from 'assets/icons/chevron_up_mid.png'
import chevronUpMid2x from 'assets/icons/chevron_up_mid@2x.png'
import chevronUpMid3x from 'assets/icons/chevron_up_mid@3x.png'
import chevronUpMid4x from 'assets/icons/chevron_up_mid@4x.png'
import chevronUpWhiteIcon from 'assets/icons/chevron_up_white.png'
import chevronUpWhiteIcon2x from 'assets/icons/chevron_up_white@2x.png'
import chevronUpWhiteIcon3x from 'assets/icons/chevron_up_white@3x.png'
import chevronUpWhiteIcon4x from 'assets/icons/chevron_up_white@4x.png'
import chevronDownWhiteIcon from 'assets/icons/chevron_down_white.png'
import chevronDownWhiteIcon2x from 'assets/icons/chevron_down_white@2x.png'
import chevronDownWhiteIcon3x from 'assets/icons/chevron_down_white@3x.png'
import chevronDownWhiteIcon4x from 'assets/icons/chevron_down_white@4x.png'
import closeIcon from 'assets/icons/close_black_mid_icon.png'
import closeIcon2x from 'assets/icons/close_black_mid_icon@2x.png'
import closeIcon3x from 'assets/icons/close_black_mid_icon@3x.png'
import closeIcon4x from 'assets/icons/close_black_mid_icon@4x.png'
import commentIcon from 'assets/icons/comment_icon.png'
import commentIcon2x from 'assets/icons/comment_icon@2x.png'
import commentIcon3x from 'assets/icons/comment_icon@3x.png'
import commentIcon4x from 'assets/icons/comment_icon@4x.png'
import crossIcon from 'assets/icons/cross_blue_icon.png'
import crossIcon2x from 'assets/icons/cross_blue_icon@2x.png'
import crossIcon3x from 'assets/icons/cross_blue_icon@3x.png'
import crossIcon4x from 'assets/icons/cross_blue_icon@4x.png'
import crossIconMid from 'assets/icons/cross_mid_icon.png'
import crossIconMid2x from 'assets/icons/cross_mid_icon@2x.png'
import crossIconMid3x from 'assets/icons/cross_mid_icon@3x.png'
import crossIconMid4x from 'assets/icons/cross_mid_icon@4x.png'
import callIconBlue from 'assets/icons/call_blue_icon.png'
import callIconBlue2x from 'assets/icons/call_blue_icon@2x.png'
import callIconBlue3x from 'assets/icons/call_blue_icon@3x.png'
import callIconBlue4x from 'assets/icons/call_blue_icon@4x.png'
import callIconSmall from 'assets/icons/call_small_icon.png'
import callIconSmall2x from 'assets/icons/call_small_icon@2x.png'
import callIconSmall3x from 'assets/icons/call_small_icon@3x.png'
import callIconSmall4x from 'assets/icons/call_small_icon@4x.png'
import cameraIconBlue from 'assets/icons/camera_blue_icon.png'
import cameraIconBlue2x from 'assets/icons/camera_blue_icon@2x.png'
import cameraIconBlue3x from 'assets/icons/camera_blue_icon@3x.png'
import cameraIconBlue4x from 'assets/icons/camera_blue_icon@4x.png'
import chatIconBlue from 'assets/icons/chat_blue_icon.png'
import chatIconBlue2x from 'assets/icons/chat_blue_icon@2x.png'
import chatIconBlue3x from 'assets/icons/chat_blue_icon@3x.png'
import chatIconBlue4x from 'assets/icons/chat_blue_icon@4x.png'
import closeIconSmall from 'assets/icons/close_black_small_icon.png'
import closeIconSmall2x from 'assets/icons/close_black_small_icon@2x.png'
import closeIconSmall3x from 'assets/icons/close_black_small_icon@3x.png'
import closeIconSmall4x from 'assets/icons/close_black_small_icon@4x.png'
import cameraIconFilled from 'assets/icons/camera_blue_filled_icon.png'
import cameraIconFilled2x from 'assets/icons/camera_blue_filled_icon@2x.png'
import cameraIconFilled3x from 'assets/icons/camera_blue_filled_icon@3x.png'
import cameraIconFilled4x from 'assets/icons/camera_blue_filled_icon@4x.png'
import channelIconBold from 'assets/icons/channel_bold_icon.png'
import channelIconBold2x from 'assets/icons/channel_bold_icon@2x.png'
import channelIconBold3x from 'assets/icons/channel_bold_icon@3x.png'
import channelIconBold4x from 'assets/icons/channel_bold_icon@4x.png'
import clockIcon from 'assets/icons/clock_icon.png'
import clockIcon2x from 'assets/icons/clock_icon@2x.png'
import clockIcon3x from 'assets/icons/clock_icon@3x.png'
import clockIcon4x from 'assets/icons/clock_icon@4x.png'
import closeBlackIcon from 'assets/icons/close_black_icon.png'
import closeBlackIcon2x from 'assets/icons/close_black_icon@2x.png'
import closeBlackIcon3x from 'assets/icons/close_black_icon@3x.png'
import closeBlackIcon4x from 'assets/icons/close_black_icon@4x.png'
import callIconFilled from 'assets/icons/call_blue_filled_icon.png'
import callIconFilled2x from 'assets/icons/call_blue_filled_icon@2x.png'
import callIconFilled3x from 'assets/icons/call_blue_filled_icon@3x.png'
import callIconFilled4x from 'assets/icons/call_blue_filled_icon@4x.png'
import channelPublicIconWhite from 'assets/icons/public_white_icon.png'
import channelPublicIconWhite2x from 'assets/icons/public_white_icon@2x.png'
import channelPublicIconWhite3x from 'assets/icons/public_white_icon@3x.png'
import channelPublicIconWhite4x from 'assets/icons/public_white_icon@4x.png'
import channelPrivateIconWhite from 'assets/icons/private_white_icon.png'
import channelPrivateIconWhite2x from 'assets/icons/private_white_icon@2x.png'
import channelPrivateIconWhite3x from 'assets/icons/private_white_icon@3x.png'
import channelPrivateIconWhite4x from 'assets/icons/private_white_icon@4x.png'
import channelActiveIcon from 'assets/icons/active_channel.png'
import channelActiveIcon3x from 'assets/icons/active_channel@3x.png'
import channelActiveIcon2x from 'assets/icons/active_channel@2x.png'
import channelActiveIcon4x from 'assets/icons/active_channel@4x.png'
import circleIconDark from 'assets/icons/circle_dark_icon.png'
import circleIconDark2x from 'assets/icons/circle_dark_icon@2x.png'
import circleIconDark3x from 'assets/icons/circle_dark_icon@3x.png'
import circleIconDark4x from 'assets/icons/circle_dark_icon@4x.png'
import defaultGroupIcon from 'assets/icons/group_default_icon.png'
import defaultGroupIcon2x from 'assets/icons/group_default_icon@2x.png'
import defaultGroupIcon3x from 'assets/icons/group_default_icon@3x.png'
import defaultGroupIcon4x from 'assets/icons/group_default_icon@4x.png'
import deleteIcon from 'assets/icons/delete_icon.png'
import deleteIcon2x from 'assets/icons/delete_icon@2x.png'
import deleteIcon3x from 'assets/icons/delete_icon@3x.png'
import deleteIcon4x from 'assets/icons/delete_icon@4x.png'
import downloadIcon from 'assets/icons/download_icon.png'
import downloadIcon2x from 'assets/icons/download_icon@2x.png'
import downloadIcon3x from 'assets/icons/download_icon@3x.png'
import downloadIcon4x from 'assets/icons/download_icon@4x.png'
import downArrow from 'assets/icons/arraow_down_icon@1x.png'
import downArrow2 from 'assets/icons/arraow_down_icon@2x.png'
import downArrow3 from 'assets/icons/arraow_down_icon@3x.png'
import downArrow4 from 'assets/icons/arraow_down_icon@4x.png'
import dashboardIcon from 'assets/icons/dashboard_icon.png'
import dashboardIcon2x from 'assets/icons/dashboard_icon@2x.png'
import dashboardIcon3x from 'assets/icons/dashboard_icon@3x.png'
import dashboardIcon4x from 'assets/icons/dashboard_icon@4x.png'
import dislikeCircleIcon from 'assets/icons/dislike_circle_icon.png'
import dislikeCircleIcon2x from 'assets/icons/dislike_circle_icon@2x.png'
import dislikeCircleIcon3x from 'assets/icons/dislike_circle_icon@3x.png'
import dislikeCircleIcon4x from 'assets/icons/dislike_circle_icon@4x.png'
import downArrowIcon from 'assets/icons/chevron_gray_icon.png'
import downArrowIcon2x from 'assets/icons/chevron_gray_icon@2x.png'
import downArrowIcon3x from 'assets/icons/chevron_gray_icon@3x.png'
import downArrowIcon4x from 'assets/icons/chevron_gray_icon@4x.png'
import deleteActionIcon from 'assets/icons/action_delete_icon.png'
import deleteActionIcon2x from 'assets/icons/action_delete_icon@2x.png'
import deleteActionIcon3x from 'assets/icons/action_delete_icon@3x.png'
import deleteActionIcon4x from 'assets/icons/action_delete_icon@4x.png'
import editIcon from 'assets/icons/edit_icon.png'
import editIcon2x from 'assets/icons/edit_icon@2x.png'
import editIcon3x from 'assets/icons/edit_icon@3x.png'
import editIcon4x from 'assets/icons/edit_icon@4x.png'
import editActionIcon from 'assets/icons/action_edit_icon.png'
import editActionIcon2x from 'assets/icons/action_edit_icon@2x.png'
import editActionIcon3x from 'assets/icons/action_edit_icon@3x.png'
import editActionIcon4x from 'assets/icons/action_edit_icon@4x.png'
import emojiIcon from 'assets/icons/emoji_icon.png'
import emojiIcon2x from 'assets/icons/emoji_icon@2x.png'
import emojiIcon3x from 'assets/icons/emoji_icon@3x.png'
import emojiIcon4x from 'assets/icons/emoji_icon@4x.png'
import errorIcon from 'assets/icons/error_icon.png'
import errorIcon2x from 'assets/icons/error_icon@2x.png'
import errorIcon3x from 'assets/icons/error_icon@3x.png'
import errorIcon4x from 'assets/icons/error_icon@4x.png'
import emptyFile from 'assets/icons/empty_file@1x.png'
import emptyFile2x from 'assets/icons/empty_file@2x.png'
import emptyFile3x from 'assets/icons/empty_file@3x.png'
import emptyFile4x from 'assets/icons/empty_file@4x.png'
import excelIcon from 'assets/icons/file_xcl1x.png'
import excelIcon2x from 'assets/icons/file_xcl2x.png'
import excelIcon3x from 'assets/icons/file_xcl3x.png'
import excelIcon4x from 'assets/icons/file_xcl4x.png'
import filesIcon from 'assets/icons/files_icon.png'
import filesIcon2x from 'assets/icons/files_icon@2x.png'
import filesIcon3x from 'assets/icons/files_icon@3x.png'
import filesIcon4x from 'assets/icons/files_icon@4x.png'
import fileIcon from 'assets/icons/file_icon.png'
import fileIcon2x from 'assets/icons/file_icon@2x.png'
import fileIcon3x from 'assets/icons/file_icon@3x.png'
import fileIcon4x from 'assets/icons/file_icon@4x.png'
import fileActiveIcon from 'assets/icons/active_files.png'
import fileActiveIcon2x from 'assets/icons/active_files@2x.png'
import fileActiveIcon3x from 'assets/icons/active_files@3x.png'
import fileActiveIcon4x from 'assets/icons/active_files@4x.png'
import groupIcon from 'assets/icons/icon_teams.png'
import groupIcon2x from 'assets/icons/icon_teams@2x.png'
import groupIcon3x from 'assets/icons/icon_teams@3x.png'
import groupIcon4x from 'assets/icons/icon_teams@4x.png'
import groupActiveIcon from 'assets/icons/active_group.png'
import groupActiveIcon2x from 'assets/icons/active_group@2x.png'
import groupActiveIcon3x from 'assets/icons/active_group@3x.png'
import groupActiveIcon4x from 'assets/icons/active_group@4x.png'
import iconCloseWhite from 'assets/icons/close_white_small_icon.png'
import iconCloseWhite2x from 'assets/icons/close_white_small_icon@2x.png'
import iconCloseWhite3x from 'assets/icons/close_white_small_icon@3x.png'
import iconCloseWhite4x from 'assets/icons/close_white_small_icon@4x.png'
import infoFailIcon from 'assets/icons/info_fail_icon@1x.png'
import infoFailIcon2x from 'assets/icons/info_fail_icon@2x.png'
import infoFailIcon3x from 'assets/icons/info_fail_icon@3x.png'
import infoFailIcon4x from 'assets/icons/info_fail_icon@4x.png'
import iconDisabledUser from 'assets/icons/disabled_user_icon.png'
import iconDisabledUser2x from 'assets/icons/disabled_user_icon@2x.png'
import iconDisabledUser3x from 'assets/icons/disabled_user_icon@3x.png'
import iconDisabledUser4x from 'assets/icons/disabled_user_icon@4x.png'
import imageIcon from 'assets/icons/photo_icon.png'
import imageIcon2x from 'assets/icons/photo_icon@2x.png'
import imageIcon3x from 'assets/icons/photo_icon@3x.png'
import imageIcon4x from 'assets/icons/photo_icon@4x.png'
import jumpIcon from 'assets/icons/jump_icon@x1.png'
import jumpIcon2x from 'assets/icons/jump_icon@x2.png'
import jumpIcon3x from 'assets/icons/jump_icon@x3.png'
import jumpIcon4x from 'assets/icons/jump_icon@x4.png'
import leftIcon from 'assets/icons/left_circle_icon.png'
import leftIcon2x from 'assets/icons/left_circle_icon@2x.png'
import leftIcon3x from 'assets/icons/left_circle_icon@3x.png'
import leftIcon4x from 'assets/icons/left_circle_icon@4x.png'
import loadingIcon from 'assets/icons/loading_icon.png'
import loadingIcon2x from 'assets/icons/loading_icon@2x.png'
import loadingIcon3x from 'assets/icons/loading_icon@3x.png'
import loadingIcon4x from 'assets/icons/loading_icon@4x.png'
import lockIconBold from 'assets/icons/lock_bold_icon.png'
import lockIconBold2x from 'assets/icons/lock_bold_icon@2x.png'
import lockIconBold3x from 'assets/icons/lock_bold_icon@3x.png'
import lockIconBold4x from 'assets/icons/lock_bold_icon@4x.png'
import liveStreamIconRed from 'assets/icons/livestream_red_icon.png'
import liveStreamIconRed2x from 'assets/icons/livestream_red_icon@2x.png'
import liveStreamIconRed3x from 'assets/icons/livestream_red_icon@3x.png'
import liveStreamIconRed4x from 'assets/icons/livestream_red_icon@4x.png'
import liveStreamIcon from 'assets/icons/livestream_icon@1x.png'
import liveStreamIcon2x from 'assets/icons/livestream_icon@2x.png'
import liveStreamIcon3x from 'assets/icons/livestream_icon@3x.png'
import liveStreamIcon4x from 'assets/icons/livestream_icon@4x.png'
import liveStreamIconBig from 'assets/icons/Illustration@1x.png'
import liveStreamIconBig2x from 'assets/icons/Illustration@2x.png'
import liveStreamIconBig3x from 'assets/icons/Illustration@3x.png'
import liveStreamIconBig4x from 'assets/icons/Illustration@4x.png'
import likeCircleIcon from 'assets/icons/like_circle.png'
import likeCircleIcon2x from 'assets/icons/like_circle@2x.png'
import likeCircleIcon3x from 'assets/icons/like_circle@3x.png'
import likeCircleIcon4x from 'assets/icons/like_circle@4x.png'
import linkActionIcon from 'assets/icons/action_link_icon.png'
import linkActionIcon2x from 'assets/icons/action_link_icon@2x.png'
import linkActionIcon3x from 'assets/icons/action_link_icon@3x.png'
import linkActionIcon4x from 'assets/icons/action_link_icon@4x.png'
import joinActionIcon from 'assets/icons/action_camera_icon.png'
import joinActionIcon2x from 'assets/icons/action_camera_icon@2x.png'
import joinActionIcon3x from 'assets/icons/action_camera_icon@3x.png'
import joinActionIcon4x from 'assets/icons/action_camera_icon@4x.png'
import messageIconSmall from 'assets/icons/message_small_icon.png'
import messageIconSmall2x from 'assets/icons/message_small_icon@2x.png'
import messageIconSmall3x from 'assets/icons/message_small_icon@3x.png'
import messageIconSmall4x from 'assets/icons/message_small_icon@4x.png'
import messageActiveIcon from 'assets/icons/active_message.png'
import messageActiveIcon2x from 'assets/icons/active_message@2x.png'
import messageActiveIcon3x from 'assets/icons/active_message@3x.png'
import messageActiveIcon4x from 'assets/icons/active_message@4x.png'
import messageIcon from 'assets/icons/message_icon.png'
import messageIcon2x from 'assets/icons/message_icon@2x.png'
import messageIcon3x from 'assets/icons/message_icon@3x.png'
import messageIcon4x from 'assets/icons/message_icon@4x.png'
import microphoneIcon from 'assets/icons/microphone_icon.png'
import microphoneIcon2x from 'assets/icons/microphone_icon@2x.png'
import microphoneIcon3x from 'assets/icons/microphone_icon@3x.png'
import microphoneIcon4x from 'assets/icons/microphone_icon@4x.png'
import managementIcon from 'assets/icons/user_white_icon.png'
import managementIcon2x from 'assets/icons/user_white_icon@2x.png'
import managementIcon3x from 'assets/icons/user_white_icon@3x.png'
import managementIcon4x from 'assets/icons/user_white_icon@4x.png'
import msWordIcon from 'assets/icons/file_word@1x.png'
import msWordIcon2x from 'assets/icons/file_word@2x.png'
import msWordIcon3x from 'assets/icons/file_word@3x.png'
import msWordIcon4x from 'assets/icons/file_word@4x.png'
import noTasktIcon from 'assets/icons/no_task_icon.png'
import noTaskIcon2x from 'assets/icons/no_task_icon@2x.png'
import noTaskIcon3x from 'assets/icons/no_task_icon@3x.png'
import noTaskIcon4x from 'assets/icons/no_task_icon@4x.png'
import newsFeedIconBig from 'assets/icons/empty_news_feed.png'
import newsFeedIconBig2x from 'assets/icons/empty_news_feed@2x.png'
import newsFeedIconBig3x from 'assets/icons/empty_news_feed@3x.png'
import newsFeedIconBig4x from 'assets/icons/empty_news_feed@4x.png'
import otrTooltipText from 'assets/icons/otr_tooltip_icon.png'
import otrTooltipText2x from 'assets/icons/otr_tooltip_icon@2x.png'
import otrTooltipText3x from 'assets/icons/otr_tooltip_icon@3x.png'
import otrTooltipText4x from 'assets/icons/otr_tooltip_icon@4x.png'
import otrIconOn from 'assets/icons/otr_on_icon.png'
import otrIconOn2x from 'assets/icons/otr_on_icon@2x.png'
import otrIconOn3x from 'assets/icons/otr_on_icon@3x.png'
import otrIconOn4x from 'assets/icons/otr_on_icon@4x.png'
import otrIconOff from 'assets/icons/otr_off_icon.png'
import otrIconOff2x from 'assets/icons/otr_off_icon@2x.png'
import otrIconOff3x from 'assets/icons/otr_off_icon@3x.png'
import otrIconOff4x from 'assets/icons/otr_off_icon@4x.png'
import otrIcon from 'assets/icons/otr_icon.png'
import otrIcon2x from 'assets/icons/otr_icon@2x.png'
import otrIcon3x from 'assets/icons/otr_icon@3x.png'
import otrIcon4x from 'assets/icons/otr_icon@4x.png'
import pdfIcon from 'assets/icons/file_pdf.png'
import pdfIcon2x from 'assets/icons/file_pdf@2x.png'
import pdfIcon3x from 'assets/icons/file_pdf@3x.png'
import pdfIcon4x from 'assets/icons/file_pdf@4x.png'
import pinIcon from 'assets/icons/pin_icon.png'
import pinIcon2x from 'assets/icons/pin_icon@2x.png'
import pinIcon3x from 'assets/icons/pin_icon@3x.png'
import pinIcon4x from 'assets/icons/pin_icon@4x.png'
import pinIconSmall from 'assets/icons/pin_small_icon.png'
import pinIconSmall2x from 'assets/icons/pin_small_icon@2x.png'
import pinIconSmall3x from 'assets/icons/pin_small_icon@3x.png'
import pinIconSmall4x from 'assets/icons/pin_small_icon@4x.png'
import passwordEyeSlach from 'assets/icons/hidepw_icon@1x.png'
import passwordEyeSlach2x from 'assets/icons/hidepw_icon@2x.png'
import passwordEyeSlach3x from 'assets/icons/hidepw_icon@3x.png'
import passwordEyeSlach4x from 'assets/icons/hidepw_icon@4x.png'
import passwordEye from 'assets/icons/showpw_icon.png'
import passwordEye2x from 'assets/icons/showpw_icon@2x.png'
import passwordEye3x from 'assets/icons/showpw_icon@3x.png'
import passwordEye4x from 'assets/icons/showpw_icon@4x.png'
import powerpointIcon from 'assets/icons/file_ppt1x.png'
import powerpointIcon2x from 'assets/icons/file_ppt2x.png'
import powerpointIcon3x from 'assets/icons/file_ppt3x.png'
import powerpointIcon4x from 'assets/icons/file_ppt4x.png'
import replyIcon from 'assets/icons/reply_icon.png'
import replyIcon2x from 'assets/icons/reply_icon@2x.png'
import replyIcon3x from 'assets/icons/reply_icon@3x.png'
import replyIcon4x from 'assets/icons/reply_icon@4x.png'
import rightIcon from 'assets/icons/right_icon.png'
import rightIcon2x from 'assets/icons/right_icon@2x.png'
import rightIcon3x from 'assets/icons/right_icon@3x.png'
import rightIcon4x from 'assets/icons/right_icon@4x.png'
import searchIcon from 'assets/icons/search_icon.png'
import searchIcon2x from 'assets/icons/search_icon@2x.png'
import searchIcon3x from 'assets/icons/search_icon@3x.png'
import searchIcon4x from 'assets/icons/search_icon@4x.png'
import sendIconWithoutBackground from 'assets/icons/send_icon.png'
import sendIconWithoutBackground2x from 'assets/icons/send_icon@2x.png'
import sendIconWithoutBackground3x from 'assets/icons/send_icon@3x.png'
import sendIconWithoutBackground4x from 'assets/icons/send_icon@4x.png'
import successIcon from 'assets/icons/success_icon.png'
import successIcon2x from 'assets/icons/success_icon@2x.png'
import successIcon3x from 'assets/icons/success_icon@3x.png'
import successIcon4x from 'assets/icons/success_icon@4x.png'
import sendIconActive from 'assets/icons/send_icon_active.png'
import sendIconActive2x from 'assets/icons/send_icon_active@2x.png'
import sendIconActive3x from 'assets/icons/send_icon_active@3x.png'
import sendIconActive4x from 'assets/icons/send_icon_active@4x.png'
import sendIconInactive from 'assets/icons/send_icon_inactive.png'
import sendIconInactive2x from 'assets/icons/send_icon_inactive@2x.png'
import sendIconInactive3x from 'assets/icons/send_icon_inactive@3x.png'
import sendIconInactive4x from 'assets/icons/send_icon_inactive@4x.png'
import sendActiveRecordPlay from 'assets/icons/send_active_play.png'
import sendActiveRecordPlay2x from 'assets/icons/send_active_play@2x.png'
import sendActiveRecordPlay3x from 'assets/icons/send_active_play@3x.png'
import sendActiveRecordPlay4x from 'assets/icons/send_active_play@4x.png'
import sendActiveRecordPause from 'assets/icons/send_active_pause.png'
import sendActiveRecordPause2x from 'assets/icons/send_active_pause@2x.png'
import sendActiveRecordPause3x from 'assets/icons/send_active_pause@3x.png'
import sendActiveRecordPause4x from 'assets/icons/send_active_pause@4x.png'
import searchIconFilled from 'assets/icons/search_filled_icon.png'
import searchIconFilled2x from 'assets/icons/search_filled_icon@2x.png'
import searchIconFilled3x from 'assets/icons/search_filled_icon@3x.png'
import searchIconFilled4x from 'assets/icons/search_filled_icon@4x.png'
import sidebarIcon from 'assets/icons/sidebar_icon.png'
import sidebarIcon2x from 'assets/icons/sidebar_icon@2x.png'
import sidebarIcon3x from 'assets/icons/sidebar_icon@3x.png'
import sidebarIcon4x from 'assets/icons/sidebar_icon@4x.png'
import subtractIcon from 'assets/icons/subtract_icon.png'
import subtractIcon2x from 'assets/icons/subtract_icon@2x.png'
import subtractIcon3x from 'assets/icons/subtract_icon@3x.png'
import subtractIcon4x from 'assets/icons/subtract_icon@4x.png'
import sendIconGreenActive from 'assets/icons/send_icon_active_green.png'
import sendIconGreenActive2x from 'assets/icons/send_icon_active_green@2x.png'
import sendIconGreenActive3x from 'assets/icons/send_icon_active_green@3x.png'
import sendIconGreenActive4x from 'assets/icons/send_icon_active_green@4x.png'
import threedotIcon from 'assets/icons/threedot_black_icon.png'
import threedotIcon2x from 'assets/icons/threedot_black_icon@2x.png'
import threedotIcon3x from 'assets/icons/threedot_black_icon@3x.png'
import threedotIcon4x from 'assets/icons/threedot_black_icon@4x.png'
import thumbsUpIcon from 'assets/icons/thumb_icon.png'
import thumbsUpIcon2x from 'assets/icons/thumb_icon@2x.png'
import thumbsUpIcon3x from 'assets/icons/thumb_icon@3x.png'
import thumbsUpIcon4x from 'assets/icons/thumb_icon@4x.png'
import thumbsDownIcon from 'assets/icons/thumbs_down_icon.png'
import thumbsDownIcon2x from 'assets/icons/thumbs_down_icon@2x.png'
import thumbsDownIcon3x from 'assets/icons/thumbs_down_icon@3x.png'
import thumbsDownIcon4x from 'assets/icons/thumbs_down_icon@4x.png'
import thumbsUpIconActive from 'assets/icons/thumbsup_active_icon.png'
import thumbsUpIconActive2x from 'assets/icons/thumbsup_active_icon@2x.png'
import thumbsUpIconActive3x from 'assets/icons/thumbsup_active_icon@3x.png'
import thumbsUpIconActive4x from 'assets/icons/thumbsup_active_icon@4x.png'
import thumbsDownIconActive from 'assets/icons/thumbs_down_active_icon.png'
import thumbsDownIconActive2x from 'assets/icons/thumbs_down_active_icon@2x.png'
import thumbsDownIconActive3x from 'assets/icons/thumbs_down_active_icon@3x.png'
import thumbsDownIconActive4x from 'assets/icons/thumbs_down_active_icon@4x.png'
import threeDotIconWhite from 'assets/icons/three_dot_white_icon.png'
import threeDotIconWhite2x from 'assets/icons/three_dot_white_icon@2x.png'
import threeDotIconWhite3x from 'assets/icons/three_dot_white_icon@3x.png'
import threeDotIconWhite4x from 'assets/icons/three_dot_white_icon@4x.png'
import userActiveIcon from 'assets/icons/active_user.png'
import userActiveIcon2x from 'assets/icons/active_user@2x.png'
import userActiveIcon3x from 'assets/icons/active_user@3x.png'
import userActiveIcon4x from 'assets/icons/active_user@4x.png'
import unpinnedIcon from 'assets/icons/unpin_blue_icon@1x.png'
import unpinnedIcon2x from 'assets/icons/unpin_blue_icon@2x.png'
import unpinnedIcon3x from 'assets/icons/unpin_blue_icon@3x.png'
import unpinnedIcon4x from 'assets/icons/unpin_blue_icon@4x.png'
import upArrowIcon from 'assets/icons/arrow_up_icon.png'
import upArrowIcon2x from 'assets/icons/arrow_up_icon@2x.png'
import upArrowIcon3x from 'assets/icons/arrow_up_icon@3x.png'
import upArrowIcon4x from 'assets/icons/arrow_up_icon@4x.png'
import videoCallIcon from 'assets/icons/videocall_icon.png'
import videoCallIcon2x from 'assets/icons/videocall_icon@2x.png'
import videoCallIcon3x from 'assets/icons/videocall_icon@3x.png'
import videoCallIcon4x from 'assets/icons/videocall_icon@4x.png'
import videoCallIconBig from 'assets/icons/video_call_big_icon.png'
import videoCallIconBig2x from 'assets/icons/video_call_big_icon@2x.png'
import videoCallIconBig3x from 'assets/icons/video_call_big_icon@3x.png'
import videoCallIconBig4x from 'assets/icons/video_call_big_icon@4x.png'
import videoCallActiveIcon from 'assets/icons/active_videocall.png'
import videoCallActiveIcon2x from 'assets/icons/active_videocall@2x.png'
import videoCallActiveIcon3x from 'assets/icons/active_videocall@3x.png'
import videoCallActiveIcon4x from 'assets/icons/active_videocall@4x.png'
import voiceActiveIcon from 'assets/icons/active_voice.png'
import voiceActiveIcon2x from 'assets/icons/active_voice@2x.png'
import voiceActiveIcon3x from 'assets/icons/active_voice@3x.png'
import voiceActiveIcon4x from 'assets/icons/active_voice@4x.png'
import zipIcon from 'assets/icons/zip_icon.png'
import zipIcon2x from 'assets/icons/zip_icon@2x.png'
import zipIcon3x from 'assets/icons/zip_icon@3x.png'
import zipIcon4x from 'assets/icons/zip_icon@4x.png'
import unarchiveIcon from 'assets/icons/unarchive_icon.png'
import unarchiveIcon2x from 'assets/icons/unarchive_icon@2x.png'
import unarchiveIcon3x from 'assets/icons/unarchive_icon@3x.png'
import unarchiveIcon4x from 'assets/icons/unarchive_icon@4x.png'
import backwardIcon from 'assets/icons/backward_icon.png'
import backwardIcon2x from 'assets/icons/backward_icon@2x.png'
import backwardIcon3x from 'assets/icons/backward_icon@3x.png'
import backwardIcon4x from 'assets/icons/backward_icon@4x.png'
import smallDotIcon from 'assets/icons/icon_small_dot.png'
import smallDotIcon2x from 'assets/icons/icon_small_dot@2x.png'
import smallDotIcon3x from 'assets/icons/icon_small_dot@3x.png'
import smallDotIcon4x from 'assets/icons/icon_small_dot@4x.png'
import thinSignalIcon from 'assets/icons/icon_thin_signal.png'
import thinSignalIcon2x from 'assets/icons/icon_thin_signal@2x.png'
import thinSignalIcon3x from 'assets/icons/icon_thin_signal@3x.png'
import thinSignalIcon4x from 'assets/icons/icon_thin_signal@4x.png'
import mediumSignalIcon from 'assets/icons/icon_medium_signal.png'
import mediumSignalIcon2x from 'assets/icons/icon_medium_signal@2x.png'
import mediumSignalIcon3x from 'assets/icons/icon_medium_signal@3x.png'
import mediumSignalIcon4x from 'assets/icons/icon_medium_signal@4x.png'
import thickSignalIcon from 'assets/icons/icon_thick_signal.png'
import thickSignalIcon2x from 'assets/icons/icon_thick_signal@2x.png'
import thickSignalIcon3x from 'assets/icons/icon_thick_signal@3x.png'
import thickSignalIcon4x from 'assets/icons/icon_thick_signal@4x.png'

import navbarNewsfeedIcon from 'assets/icons/navbar_newsfeed.svg'
import navbarTeamsIcon from 'assets/icons/navbar_teams.svg'
import navbarMessagesIcon from 'assets/icons/navbar_messages.svg'
import navbarVideoIcon from 'assets/icons/navbar_video.svg'

export const icons = {
  messageIcon: {
    src: messageIcon3x,
    srcset: `${messageIcon4x} 4x,${messageIcon3x} 3x,${messageIcon2x} 2x, ${messageIcon} 1x`,
  },
  groupIcon: {
    src: groupIcon3x,
    srcset: `${groupIcon4x} 4x,${groupIcon3x} 3x,${groupIcon2x} 2x, ${groupIcon} 1x`,
  },
  attachmentIcon: {
    src: attachmentIcon3x,
    srcset: `${attachmentIcon4x} 4x,${attachmentIcon3x} 3x,${attachmentIcon2x} 2x, ${attachmentIcon} 1x`,
  },
  closeIcon: {
    src: closeIcon3x,
    srcset: `${closeIcon4x} 4x,${closeIcon3x} 3x,${closeIcon2x} 2x, ${closeIcon} 1x`,
  },
  commentIcon: {
    src: commentIcon3x,
    srcset: `${commentIcon4x} 4x,${commentIcon3x} 3x,${commentIcon2x} 2x, ${commentIcon} 1x`,
  },
  dashboardIcon: {
    src: dashboardIcon3x,
    srcset: `${dashboardIcon4x} 4x,${dashboardIcon3x} 3x,${dashboardIcon2x} 2x, ${dashboardIcon} 1x`,
  },
  emojiIcon: {
    src: emojiIcon3x,
    srcset: `${emojiIcon4x} 4x,${emojiIcon3x} 3x,${emojiIcon2x} 2x, ${emojiIcon} 1x`,
  },
  errorIcon: {
    src: errorIcon3x,
    srcset: `${errorIcon4x} 4x,${errorIcon3x} 3x,${errorIcon2x} 2x, ${errorIcon} 1x`,
  },
  filesIcon: {
    src: filesIcon3x,
    srcset: `${filesIcon4x} 4x,${filesIcon3x} 3x,${filesIcon2x} 2x, ${filesIcon} 1x`,
  },
  lockIconBold: {
    src: lockIconBold3x,
    srcset: `${lockIconBold4x} 4x,${lockIconBold3x} 3x,${lockIconBold2x} 2x, ${lockIconBold} 1x`,
  },
  microphoneIcon: {
    src: microphoneIcon3x,
    srcset: `${microphoneIcon4x} 4x,${microphoneIcon3x} 3x,${microphoneIcon2x} 2x, ${microphoneIcon} 1x`,
  },
  searchIcon: {
    src: searchIcon3x,
    srcset: `${searchIcon4x} 4x,${searchIcon3x} 3x,${searchIcon2x} 2x, ${searchIcon} 1x`,
  },
  sendIconWithoutBackground: {
    src: sendIconWithoutBackground3x,
    srcset: `${sendIconWithoutBackground4x} 4x,${sendIconWithoutBackground3x} 3x,${sendIconWithoutBackground2x} 2x, ${sendIconWithoutBackground} 1x`,
  },
  sidebarIcon: {
    src: sidebarIcon3x,
    srcset: `${sidebarIcon4x} 4x,${sidebarIcon3x} 3x,${sidebarIcon2x} 2x, ${sidebarIcon} 1x`,
  },
  successIcon: {
    src: successIcon3x,
    srcset: `${successIcon4x} 4x,${successIcon3x} 3x,${successIcon2x} 2x, ${successIcon} 1x`,
  },
  callIconSmall: {
    src: callIconSmall3x,
    srcset: `${callIconSmall4x} 4x,${callIconSmall3x} 3x,${callIconSmall2x} 2x, ${callIconSmall} 1x`,
  },
  closeIconWhite: {
    src: iconCloseWhite3x,
    srcset: `${iconCloseWhite4x} 4x,${iconCloseWhite3x} 3x,${iconCloseWhite2x} 2x, ${iconCloseWhite} 1x`,
  },
  deleteIcon: {
    src: deleteIcon3x,
    srcset: `${deleteIcon4x} 4x,${deleteIcon3x} 3x,${deleteIcon2x} 2x, ${deleteIcon} 1x`,
  },
  downloadIcon: {
    src: downloadIcon3x,
    srcset: `${downloadIcon4x} 4x,${downloadIcon3x} 3x,${downloadIcon2x} 2x, ${downloadIcon} 1x`,
  },
  rightIcon: {
    src: rightIcon3x,
    srcset: `${rightIcon4x} 4x,${rightIcon3x} 3x,${rightIcon2x} 2x, ${rightIcon} 1x`,
  },
  leftIcon: {
    src: leftIcon3x,
    srcset: `${leftIcon4x} 4x,${leftIcon3x} 3x,${leftIcon2x} 2x, ${leftIcon} 1x`,
  },
  passwordEyeSlach: {
    src: passwordEyeSlach3x,
    srcset: `${passwordEyeSlach4x} 4x,${passwordEyeSlach3x} 3x,${passwordEyeSlach2x} 2x, ${passwordEyeSlach} 1x`,
  },
  passwordEye: {
    src: passwordEye3x,
    srcset: `${passwordEye4x} 4x,${passwordEye3x} 3x,${passwordEye2x} 2x, ${passwordEye} 1x`,
  },
  replyIcon: {
    src: replyIcon3x,
    srcset: `${replyIcon4x} 4x,${replyIcon3x} 3x,${replyIcon2x} 2x, ${replyIcon} 1x`,
  },
  pinIcon: {
    src: pinIcon3x,
    srcset: `${pinIcon4x} 4x,${pinIcon3x} 3x,${pinIcon2x} 2x, ${pinIcon} 1x`,
  },
  editIcon: {
    src: editIcon3x,
    srcset: `${editIcon4x} 4x,${editIcon3x} 3x,${editIcon2x} 2x, ${editIcon} 1x`,
  },
  crossIcon: {
    src: crossIcon3x,
    srcset: `${crossIcon4x} 4x,${crossIcon3x} 3x,${crossIcon2x} 2x, ${crossIcon} 1x`,
  },
  loadingIcon: {
    src: loadingIcon3x,
    srcset: `${loadingIcon4x} 4x,${loadingIcon3x} 3x,${loadingIcon2x} 2x, ${loadingIcon} 1x`,
  },
  sendIconActive: {
    src: sendIconActive3x,
    srcset: `${sendIconActive4x} 4x,${sendIconActive3x} 3x,${sendIconActive2x} 2x, ${sendIconActive} 1x`,
  },
  sendIconInactive: {
    src: sendIconInactive3x,
    srcset: `${sendIconInactive4x} 4x,${sendIconInactive3x} 3x,${sendIconInactive2x} 2x, ${sendIconInactive} 1x`,
  },
  closeIconSmall: {
    src: closeIconSmall3x,
    srcset: `${closeIconSmall4x} 4x,${closeIconSmall3x} 3x,${closeIconSmall2x} 2x, ${closeIconSmall} 1x`,
  },
  callIconBlue: {
    src: callIconBlue3x,
    srcset: `${callIconBlue4x} 4x,${callIconBlue3x} 3x,${callIconBlue2x} 2x, ${callIconBlue} 1x`,
  },
  cameraIconBlue: {
    src: cameraIconBlue3x,
    srcset: `${cameraIconBlue4x} 4x,${cameraIconBlue3x} 3x,${cameraIconBlue2x} 2x, ${cameraIconBlue} 1x`,
  },
  chatIconBlue: {
    src: chatIconBlue3x,
    srcset: `${chatIconBlue4x} 4x,${chatIconBlue3x} 3x,${chatIconBlue2x} 2x, ${chatIconBlue} 1x`,
  },
  messageIconSmall: {
    src: messageIconSmall3x,
    srcset: `${messageIconSmall4x} 4x,${messageIconSmall3x} 3x,${messageIconSmall2x} 2x, ${messageIconSmall} 1x`,
  },
  defaultGroupIcon: {
    src: defaultGroupIcon3x,
    srcset: `${defaultGroupIcon4x} 4x,${defaultGroupIcon3x} 3x,${defaultGroupIcon2x} 2x, ${defaultGroupIcon} 1x`,
  },
  unpinnedIcon: {
    src: unpinnedIcon3x,
    srcset: `${unpinnedIcon4x} 4x,${unpinnedIcon3x} 3x,${unpinnedIcon2x} 2x,${unpinnedIcon} 1x`,
  },

  jumpIcon: {
    src: jumpIcon3x,
    srcset: `${jumpIcon4x} 4x,${jumpIcon3x} 3x,${jumpIcon2x} 2x,${jumpIcon} 1x`,
  },
  threedotIcon: {
    src: threedotIcon3x,
    srcset: `${threedotIcon4x} 4x,${threedotIcon3x} 3x,${threedotIcon2x} 2x,${threedotIcon} 1x`,
  },
  channelIconBold: {
    src: channelIconBold3x,
    srcset: `${channelIconBold4x} 4x,${channelIconBold3x} 3x,${channelIconBold2x} 2x,${channelIconBold} 1x`,
  },
  fileIcon: {
    src: fileIcon3x,
    srcset: `${fileIcon4x} 4x,${fileIcon3x} 3x,${fileIcon2x} 2x,${fileIcon} 1x`,
  },
  pdfIcon: {
    src: pdfIcon3x,
    srcset: `${pdfIcon4x} 4x,${pdfIcon3x} 3x,${pdfIcon2x} 2x,${pdfIcon} 1x`,
  },

  videoCallIcon: {
    src: videoCallIcon3x,
    srcset: `${videoCallIcon4x} 4x,${videoCallIcon3x} 3x,${videoCallIcon2x} 2x,${videoCallIcon} 1x`,
  },
  videoCallIconBig: {
    src: videoCallIconBig3x,
    srcset: `${videoCallIconBig4x} 4x,${videoCallIconBig3x} 3x,${videoCallIconBig2x} 2x,${videoCallIconBig} 1x`,
  },
  newsFeedIconBig: {
    src: newsFeedIconBig3x,
    srcset: `${newsFeedIconBig4x} 4x,${newsFeedIconBig3x} 3x,${newsFeedIconBig2x} 2x,${newsFeedIconBig} 1x`,
  },
  downArrow: {
    src: downArrow3,
    srcset: `${downArrow4} 4x,${downArrow3} 3x,${downArrow2} 2x,${downArrow} 1x`,
  },
  closeBlackIcon: {
    src: closeBlackIcon3x,
    srcset: `${closeBlackIcon4x} 4x,${closeBlackIcon3x} 3x,${closeBlackIcon2x} 2x,${closeBlackIcon} 1x`,
  },
  chevronDownIconBig: {
    src: chevronDownIconBig3x,
    srcset: `${chevronDownIconBig4x} 4x,${chevronDownIconBig3x} 3x,${chevronDownIconBig2x} 2x,${chevronDownIconBig} 1x`,
  },
  chevronRightIconBig: {
    src: chevronRightIconBig3x,
    srcset: `${chevronRightIconBig4x} 4x,${chevronRightIconBig3x} 3x,${chevronRightIconBig2x} 2x,${chevronRightIconBig} 1x`,
  },
  chevronLeftIconBig: {
    src: chevronLeftIconBig3x,
    srcset: `${chevronLeftIconBig4x} 4x,${chevronLeftIconBig3x} 3x,${chevronLeftIconBig2x} 2x,${chevronLeftIconBig} 1x`,
  },

  msWordIcon: {
    src: msWordIcon3x,
    srcset: `${msWordIcon4x} 4x,${msWordIcon3x} 3x,${msWordIcon2x} 2x,${msWordIcon} 1x`,
  },
  excelIcon: {
    src: excelIcon3x,
    srcset: `${excelIcon4x} 4x,${excelIcon3x} 3x,${excelIcon2x} 2x,${excelIcon} 1x`,
  },
  powerpointIcon: {
    src: powerpointIcon3x,
    srcset: `${powerpointIcon4x} 4x,${powerpointIcon3x} 3x,${powerpointIcon2x} 2x,${powerpointIcon} 1x`,
  },
  pinIconSmall: {
    src: pinIconSmall3x,
    srcset: `${pinIconSmall4x} 4x,${pinIconSmall3x} 3x,${pinIconSmall2x} 2x,${pinIconSmall} 1x`,
  },

  chevronDownIconSmall: {
    src: chevronDownIconSmall3x,
    srcset: `${chevronDownIconSmall4x} 4x,${chevronDownIconSmall3x} 3x,${chevronDownIconSmall2x} 2x,${chevronDownIconSmall} 1x`,
  },
  chevronUpIconSmall: {
    src: chevronUpIconSmall3x,
    srcset: `${chevronUpIconSmall4x} 4x,${chevronUpIconSmall3x} 3x,${chevronUpIconSmall2x} 2x,${chevronUpIconSmall} 1x`,
  },
  adminDashboardIcon: {
    src: adminDashboardIcon3x,
    srcset: `${adminDashboardIcon4x} 4x,${adminDashboardIcon3x} 3x,${adminDashboardIcon2x} 2x,${adminDashboardIcon} 1x`,
  },
  adminSettingsIcon: {
    src: adminSettingsIcon3x,
    srcset: `${adminSettingsIcon4x} 4x,${adminSettingsIcon3x} 3x,${adminSettingsIcon2x} 2x,${adminSettingsIcon} 1x`,
  },
  managementIcon: {
    src: managementIcon3x,
    srcset: `${managementIcon4x} 4x,${managementIcon3x} 3x,${managementIcon2x} 2x,${managementIcon} 1x`,
  },

  sendActiveRecordPlay: {
    src: sendActiveRecordPlay3x,
    srcset: `${sendActiveRecordPlay4x} 4x,${sendActiveRecordPlay3x} 3x,${sendActiveRecordPlay2x} 2x,${sendActiveRecordPlay} 1x`,
  },
  sendActiveRecordPause: {
    src: sendActiveRecordPause3x,
    srcset: `${sendActiveRecordPause4x} 4x,${sendActiveRecordPause3x} 3x,${sendActiveRecordPause2x} 2x,${sendActiveRecordPause} 1x`,
  },

  subtractIcon: {
    src: subtractIcon3x,
    srcset: `${subtractIcon4x} 4x,${subtractIcon3x} 3x,${subtractIcon2x} 2x,${subtractIcon} 1x`,
  },
  zipIcon: {
    src: zipIcon3x,
    srcset: `${zipIcon4x} 4x,${zipIcon3x} 3x,${zipIcon2x} 2x,${zipIcon} 1x`,
  },

  infoFailIcon: {
    src: infoFailIcon3x,
    srcset: `${infoFailIcon4x} 4x,${infoFailIcon3x} 3x,${infoFailIcon2x} 2x,${infoFailIcon} 1x`,
  },
  clockIcon: {
    src: clockIcon3x,
    srcset: `${clockIcon4x} 4x,${clockIcon3x} 3x,${clockIcon2x} 2x,${clockIcon} 1x`,
  },
  noTasktIcon: {
    src: noTaskIcon3x,
    srcset: `${noTaskIcon4x} 4x,${noTaskIcon3x} 3x,${noTaskIcon2x} 2x,${noTasktIcon} 1x`,
  },

  otrTooltip: {
    src: otrTooltipText3x,
    srcset: `${otrTooltipText4x} 4x,${otrTooltipText3x} 3x,${otrTooltipText2x} 2x,${otrTooltipText} 1x`,
  },
  downArrowSmall: {
    src: downArrowIcon3x,
    srcset: `${downArrowIcon4x} 4x,${downArrowIcon3x} 3x,${downArrowIcon2x} 2x,${downArrowIcon} 1x`,
  },

  chevronUpMid: {
    src: chevronUpMid3x,
    srcset: `${chevronUpMid4x} 4x,${chevronUpMid3x} 3x,${chevronUpMid2x} 2x,${chevronUpMid} 1x`,
  },
  chevronDownMid: {
    src: chevronDownMid3x,
    srcset: `${chevronDownMid4x} 4x,${chevronDownMid3x} 3x,${chevronDownMid2x} 2x,${chevronDownMid} 1x`,
  },
  iconDisabledUser: {
    src: iconDisabledUser3x,
    srcset: `${iconDisabledUser4x} 4x,${iconDisabledUser3x} 3x,${iconDisabledUser2x} 2x,${iconDisabledUser} 1x`,
  },
  emptyFile: {
    src: emptyFile3x,
    srcset: `${emptyFile4x} 4x,${emptyFile3x} 3x,${emptyFile2x} 2x,${emptyFile} 1x`,
  },

  thumbsUpIcon: {
    src: thumbsUpIcon3x,
    srcset: `${thumbsUpIcon4x} 4x,${thumbsUpIcon3x} 3x,${thumbsUpIcon2x} 2x,${thumbsUpIcon} 1x`,
  },
  thumbsDownIcon: {
    src: thumbsDownIcon3x,
    srcset: `${thumbsDownIcon4x} 4x,${thumbsDownIcon3x} 3x,${thumbsDownIcon2x} 2x,${thumbsDownIcon} 1x`,
  },
  thumbsDownIconActive: {
    src: thumbsDownIconActive3x,
    srcset: `${thumbsDownIconActive4x} 4x,${thumbsDownIconActive3x} 3x,${thumbsDownIconActive2x} 2x,${thumbsDownIconActive} 1x`,
  },
  thumbsUpIconActive: {
    src: thumbsUpIconActive3x,
    srcset: `${thumbsUpIconActive4x} 4x,${thumbsUpIconActive3x} 3x,${thumbsUpIconActive2x} 2x,${thumbsUpIconActive} 1x`,
  },
  upArrowIcon: {
    src: upArrowIcon3x,
    srcset: `${upArrowIcon4x} 4x,${upArrowIcon3x} 3x,${upArrowIcon2x} 2x,${upArrowIcon} 1x`,
  },

  crossIconMid: {
    src: crossIconMid3x,
    srcset: `${crossIconMid4x} 4x,${crossIconMid3x} 3x,${crossIconMid2x} 2x,${crossIconMid} 1x`,
  },

  chevronDownIconBlue: {
    src: chevronDownIconBlue3x,
    srcset: `${chevronDownIconBlue4x} 4x,${chevronDownIconBlue3x} 3x,${chevronDownIconBlue2x} 2x,${chevronDownIconBlue} 1x`,
  },
  callIconFilled: {
    src: callIconFilled3x,
    srcset: `${callIconFilled4x} 4x,${callIconFilled3x} 3x,${callIconFilled2x} 2x,${callIconFilled} 1x`,
  },
  cameraIconFilled: {
    src: cameraIconFilled3x,
    srcset: `${cameraIconFilled4x} 4x,${cameraIconFilled3x} 3x,${cameraIconFilled2x} 2x,${cameraIconFilled} 1x`,
  },
  searchIconFilled: {
    src: searchIconFilled3x,
    srcset: `${searchIconFilled4x} 4x,${searchIconFilled3x} 3x,${searchIconFilled2x} 2x,${searchIconFilled} 1x`,
  },
  threeDotIconWhite: {
    src: threeDotIconWhite3x,
    srcset: `${threeDotIconWhite4x} 4x,${threeDotIconWhite3x} 3x,${threeDotIconWhite2x} 2x,${threeDotIconWhite} 1x`,
  },
  channelPublicIconWhite: {
    src: channelPublicIconWhite3x,
    srcset: `${channelPublicIconWhite4x} 4x,${channelPublicIconWhite3x} 3x,${channelPublicIconWhite2x} 2x,${channelPublicIconWhite} 1x`,
  },
  channelPrivateIconWhite: {
    src: channelPrivateIconWhite3x,
    srcset: `${channelPrivateIconWhite4x} 4x,${channelPrivateIconWhite3x} 3x,${channelPrivateIconWhite2x} 2x,${channelPrivateIconWhite} 1x`,
  },
  circleIconDark: {
    src: circleIconDark3x,
    srcset: `${circleIconDark4x} 4x,${circleIconDark3x} 3x,${circleIconDark2x} 2x,${circleIconDark} 1x`,
  },
  otrIconOn: {
    src: otrIconOn3x,
    srcset: `${otrIconOn4x} 4x,${otrIconOn3x} 3x,${otrIconOn2x} 2x,${otrIconOn} 1x`,
  },
  otrIconOff: {
    src: otrIconOff3x,
    srcset: `${otrIconOff4x} 4x,${otrIconOff3x} 3x,${otrIconOff2x} 2x,${otrIconOff} 1x`,
  },
  liveStreamIcon: {
    src: liveStreamIcon3x,
    srcset: `${liveStreamIcon4x} 4x,${liveStreamIcon3x} 3x,${liveStreamIcon2x} 2x,${liveStreamIcon} 1x`,
  },
  liveStreamIconBig: {
    src: liveStreamIconBig3x,
    srcset: `${liveStreamIconBig4x} 4x,${liveStreamIconBig3x} 3x,${liveStreamIconBig2x} 2x,${liveStreamIconBig} 1x`,
  },
  articleIcon: {
    src: articleIcon3x,
    srcset: `${articleIcon4x} 4x,${articleIcon3x} 3x,${articleIcon2x} 2x,${articleIcon} 1x`,
  },
  imageIcon: {
    src: imageIcon3x,
    srcset: `${imageIcon4x} 4x,${imageIcon3x} 3x,${imageIcon2x} 2x,${imageIcon} 1x`,
  },

  liveStreamIconRed: {
    src: liveStreamIconRed3x,
    srcset: `${liveStreamIconRed4x} 4x,${liveStreamIconRed3x} 3x,${liveStreamIconRed2x} 2x,${liveStreamIconRed} 1x`,
  },
  groupActiveIcon: {
    src: groupActiveIcon3x,
    srcset: `${groupActiveIcon4x} 4x,${groupActiveIcon3x} 3x,${groupActiveIcon2x} 2x,${groupActiveIcon} 1x`,
  },
  channelActiveIcon: {
    src: channelActiveIcon3x,
    srcset: `${channelActiveIcon4x} 4x,${channelActiveIcon3x} 3x,${channelActiveIcon2x} 2x,${channelActiveIcon} 1x`,
  },
  userActiveIcon: {
    src: userActiveIcon3x,
    srcset: `${userActiveIcon4x} 4x,${userActiveIcon3x} 3x,${userActiveIcon2x} 2x,${userActiveIcon} 1x`,
  },
  videoCallActiveIcon: {
    src: videoCallActiveIcon3x,
    srcset: `${videoCallActiveIcon4x} 4x,${videoCallActiveIcon3x} 3x,${videoCallActiveIcon2x} 2x,${videoCallActiveIcon} 1x`,
  },
  fileActiveIcon: {
    src: fileActiveIcon3x,
    srcset: `${fileActiveIcon4x} 4x,${fileActiveIcon3x} 3x,${fileActiveIcon2x} 2x,${fileActiveIcon} 1x`,
  },
  messageActiveIcon: {
    src: messageActiveIcon3x,
    srcset: `${messageActiveIcon4x} 4x,${messageActiveIcon3x} 3x,${messageActiveIcon2x} 2x,${messageActiveIcon} 1x`,
  },
  sendIconGreenActive: {
    src: sendIconGreenActive3x,
    srcset: `${sendIconGreenActive4x} 4x,${sendIconGreenActive3x} 3x,${sendIconGreenActive2x} 2x, ${sendIconGreenActive} 1x`,
  },
  voiceActiveIcon: {
    src: voiceActiveIcon3x,
    srcset: `${voiceActiveIcon4x} 4x,${voiceActiveIcon3x} 3x,${voiceActiveIcon2x} 2x, ${voiceActiveIcon} 1x`,
  },
  chevronUpWhiteIcon: {
    src: chevronUpWhiteIcon3x,
    srcset: `${chevronUpWhiteIcon4x} 4x,${chevronUpWhiteIcon3x} 3x,${chevronUpWhiteIcon2x} 2x, ${chevronUpWhiteIcon} 1x`,
  },
  chevronDownWhiteIcon: {
    src: chevronDownWhiteIcon3x,
    srcset: `${chevronDownWhiteIcon4x} 4x,${chevronDownWhiteIcon3x} 3x,${chevronDownWhiteIcon2x} 2x, ${chevronDownWhiteIcon} 1x`,
  },
  bellIcon: {
    src: bellIcon3x,
    srcset: `${bellIcon4x} 4x,${bellIcon3x} 3x,${bellIcon2x} 2x, ${bellIcon} 1x`,
  },
  likeCircleIcon: {
    src: likeCircleIcon3x,
    srcset: `${likeCircleIcon4x} 4x,${likeCircleIcon3x} 3x,${likeCircleIcon2x} 2x, ${likeCircleIcon} 1x`,
  },
  dislikeCircleIcon: {
    src: dislikeCircleIcon3x,
    srcset: `${dislikeCircleIcon4x} 4x,${dislikeCircleIcon3x} 3x,${dislikeCircleIcon2x} 2x, ${dislikeCircleIcon} 1x`,
  },
  otrIcon: {
    src: otrIcon3x,
    srcset: `${otrIcon4x} 4x,${otrIcon3x} 3x,${otrIcon2x} 2x, ${otrIcon} 1x`,
  },
  unarchiveIcon: {
    src: unarchiveIcon3x,
    srcset: `${unarchiveIcon4x} 4x,${unarchiveIcon3x} 3x,${unarchiveIcon2x} 2x, ${unarchiveIcon} 1x`,
  },
  backwardIcon: {
    src: backwardIcon3x,
    srcset: `${backwardIcon4x} 4x,${backwardIcon3x} 3x,${backwardIcon2x} 2x, ${backwardIcon} 1x`,
  },
  editActionIcon: {
    src: editActionIcon3x,
    srcset: `${editActionIcon4x} 4x,${editActionIcon3x} 3x,${editActionIcon2x} 2x, ${editActionIcon} 1x`,
  },
  deleteActionIcon: {
    src: deleteActionIcon3x,
    srcset: `${deleteActionIcon4x} 4x,${deleteActionIcon3x} 3x,${deleteActionIcon2x} 2x, ${deleteActionIcon} 1x`,
  },
  linkActionIcon: {
    src: linkActionIcon3x,
    srcset: `${linkActionIcon4x} 4x,${linkActionIcon3x} 3x,${linkActionIcon2x} 2x, ${linkActionIcon} 1x`,
  },
  joinActionIcon: {
    src: joinActionIcon3x,
    srcset: `${joinActionIcon4x} 4x,${joinActionIcon3x} 3x,${joinActionIcon2x} 2x, ${joinActionIcon} 1x`,
  },
  bellIconMd: {
    src: bellIconMd3x,
    srcset: `${bellIconMd4x} 4x,${bellIconMd3x} 3x,${bellIconMd2x} 2x, ${bellIconMd} 1x`,
  },
  smallDotIcon: {
    src: smallDotIcon3x,
    srcset: `${smallDotIcon4x} 4x,${smallDotIcon3x} 3x,${smallDotIcon2x} 2x, ${smallDotIcon} 1x`,
  },
  thinSignalIcon: {
    src: thinSignalIcon3x,
    srcset: `${thinSignalIcon4x} 4x,${thinSignalIcon3x} 3x,${thinSignalIcon2x} 2x, ${thinSignalIcon} 1x`,
  },
  mediumSignalIcon: {
    src: mediumSignalIcon3x,
    srcset: `${mediumSignalIcon4x} 4x,${mediumSignalIcon3x} 3x,${mediumSignalIcon2x} 2x, ${mediumSignalIcon} 1x`,
  },
  thickSignalIcon: {
    src: thickSignalIcon3x,
    srcset: `${thickSignalIcon4x} 4x,${thickSignalIcon3x} 3x,${thickSignalIcon2x} 2x, ${thickSignalIcon} 1x`,
  },

  navbarNewsfeedIcon: {
    src: navbarNewsfeedIcon,
    srcset: navbarNewsfeedIcon
  },
  navbarTeamsIcon: {
    src: navbarTeamsIcon,
    srcset: navbarTeamsIcon
  },
  navbarMessagesIcon: {
    src: navbarMessagesIcon,
    srcset: navbarMessagesIcon
  },
  navbarVideoIcon: {
    src: navbarVideoIcon,
    srcset: navbarVideoIcon
  },
}
