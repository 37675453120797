import styled from 'styled-components'
import { uniqueId } from 'lodash'

import { FixMeLater } from 'types'

interface IFilePickerProps {
  getInputProps: FixMeLater
  icon?: boolean
}

const FilePicker = ({ getInputProps, icon }: IFilePickerProps) => {
  const uniqueID = uniqueId()

  const onResetInputValue = (event: FixMeLater) => {
    event.target.value = null
  }
  return (
    <>
      <input id={uniqueID} {...getInputProps()} onClick={onResetInputValue} />
      <Label htmlFor={uniqueID} icon={icon} />
    </>
  )
}

export default FilePicker

const Label = styled.label.attrs(({ icon }: { icon?: boolean }) => icon)`
  position: absolute;
  z-index: 2;
  cursor: pointer;
  background: transparent;
  width: ${({ icon }) => (icon ? '20px' : '110px')};
  height: ${({ icon }) => (icon ? '20px' : '25px')};
  left: ${({ icon }) => (icon ? '50' : '')}px;
`
