import { FixMeLater } from 'types'
import { IUserData } from 'types/user.types'
import { IMessageContent } from 'types/conversation.types'
import { updateContentText } from 'utils/helpers/messageHelper'

import CustomText from 'components/text/CustomText'
import colors from 'utils/colors'
import styled from 'styled-components'
import { t } from 'i18next'

interface ISystemMessageProps {
  data: IMessageContent
  users: IUserData
  searchTextInMessage?: string
  onShowUserInfo: FixMeLater
}

const SystemMessage = ({
  data,
  users,
  searchTextInMessage,
  onShowUserInfo,
}: ISystemMessageProps) => {
  const textByRepliedUsers: FixMeLater = updateContentText(
    data?.text,
    data?.mentionedUsers,
    searchTextInMessage,
    users,
    onShowUserInfo,
  )

  const index = textByRepliedUsers.findIndex(
    (item: FixMeLater) => typeof item === 'string' && item?.length > 0,
  )

  if (index !== -1) {
    if (textByRepliedUsers[index].trim().split(' ').length > 1) {
      let translatedText = ''
      for (const term of textByRepliedUsers[index].split(' ')) {
        translatedText += t(term.trim())
      }
      textByRepliedUsers[index] = translatedText
    } else {
      const transableText = textByRepliedUsers[index]
      const translatedText = t(transableText.trim())
      textByRepliedUsers[index] = translatedText
    }
  }

  if (!textByRepliedUsers) return null
  return (
    <SystemTextMessage typography="body3" textAlign="center" color={colors.gray80}>
      {textByRepliedUsers}
    </SystemTextMessage>
  )
}

export default SystemMessage

const SystemTextMessage = styled(CustomText)`
  margin: 10px 0px;
  padding: 0px 30px;
`
