import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useSocket } from 'utils/hooks/useSocket'
import { IMessage } from 'types/conversation.types'
import { useGetPinnedMessagesByRoomIdQuery } from 'app/services/room.service'
import { IUserData } from 'types/user.types'
import { SOCKET } from 'app/socket/socketHelper'

import Spinner from 'components/spinner/Spinner'
import EmptyPage from 'components/empty-page/EmptyPage'
import NewMessage from 'components/new-message/NewMessage'
import Separator from 'components/separator/Separator'

interface IPinMessagesProps {
  users: IUserData
  myId: string
  onJumpMessage: (messageId: string, threadId?: string) => void
}

const PinMessages = ({ myId, users, onJumpMessage }: IPinMessagesProps) => {
  const socket = useSocket()
  const channelOrRoomId = useLocation().pathname.split('/')[2]

  const [pinnedMessages, setPinnedMessages] = useState<IMessage[]>([])
  const pinnedMessagesRef = useRef<IMessage[]>([])

  const { data, isLoading } = useGetPinnedMessagesByRoomIdQuery(
    { roomId: channelOrRoomId },
    { refetchOnMountOrArgChange: true },
  )

  const onChangePinnedMessageFromSocket = (updatedMessage: IMessage) => {
    const index = pinnedMessagesRef.current.findIndex(
      (message: IMessage) => message._id === updatedMessage._id,
    )
    if (index === -1) {
      setPinnedMessages([...pinnedMessagesRef.current, updatedMessage])
      pinnedMessagesRef.current = [...pinnedMessagesRef.current, updatedMessage]
    } else {
      const tempPinnedMessages = [...pinnedMessagesRef.current]
      tempPinnedMessages.splice(index, 1)
      setPinnedMessages(tempPinnedMessages)
      pinnedMessagesRef.current = [...tempPinnedMessages]
    }
  }

  useEffect(() => {
    socket.on(SOCKET.PINNED_MESSAGE, (message: IMessage) => {
      onChangePinnedMessageFromSocket(message)
    })
  }, [])

  useEffect(() => {
    if (data?.messages) {
      pinnedMessagesRef.current = [...data.messages]
      setPinnedMessages([...data.messages])
    }
  }, [data?.messages])

  if (isLoading) {
    return <Spinner loading={isLoading} />
  }
  if (pinnedMessages?.length === 0) {
    return <EmptyPage isEmptyPin={true} />
  }

  return (
    <ContentWrapper>
      {pinnedMessages?.map((message: IMessage, index: number) => {
        return (
          <div key={message._id}>
            <NewMessage
              myId={myId}
              index={index}
              data={message}
              onJumpMessage={onJumpMessage}
              visibleReaction={false}
              visibleThread={false}
            />
            <Separator />
          </div>
        )
      })}
    </ContentWrapper>
  )
}

export default PinMessages  

export const ContentWrapper = styled.div.attrs((props: { scrollHeight: number }) => props)`
  width: 100%;
  height: 100%;
  position: relative;
  max-height: '100vh';
  height:-webkit-fill-available;
  overflow-y: visible;
  overflow: ${({ scrollHeight }) => (scrollHeight < 600 ? 'visible' : 'auto')};
`
