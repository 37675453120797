import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  useDeleteMessageMutation,
  useGetThreadMessageQuery,
  useUpdateMessageReactionMutation,
} from 'app/services/conversation.service'
import colors from 'utils/colors'
import { IUserData } from 'types/user.types'
import { SOCKET } from 'app/socket/socketHelper'
import { useSocket } from 'utils/hooks/useSocket'
import useInfoModal from 'utils/hooks/useInfoModal'
import useUploadFiles from 'utils/hooks/useUploadFiles'
import { BUTTONS, MODALS, PLACEHOLDERS } from 'locales/locales'
import { IMessage, MessageStatus } from 'types/conversation.types'
import { useSendPinnedConversationsMutation } from 'app/services/room.service'

import Spinner from 'components/spinner/Spinner'
import Dropzone from 'components/dropzone/Dropzone'
import Separator from 'components/separator/Separator'
import NewMessage from 'components/new-message/NewMessage'
import MessageInput from 'components/input/message-input/MessageInput'
import CustomText from 'components/text/CustomText'
import { setMessageData } from 'app/slices/conversations.slice'

interface IThreadMessagesProps {
  messageId: string
  threadId?: string
  roomId: string
  myId: string
  users: IUserData
  isArchived?: boolean
  isReadOnly?: boolean
  onResetThreadId: () => void
  onUpdatePastedFileTargetId: (id: string) => void
}

interface IUpdateMessage extends IMessage {
  messageId: string
}

const ThreadMessages = ({
  messageId,
  threadId,
  roomId,
  myId,
  users,
  isArchived = false,
  isReadOnly = false,
  onResetThreadId,
  onUpdatePastedFileTargetId,
}: IThreadMessagesProps) => {
  const socket = useSocket()

  const threadsRef = useRef<IMessage[]>([])
  const threadMessageRef = useRef<HTMLInputElement>(null)
  const [threads, setThreads] = useState<IMessage[]>([])
  const [jumpToIndex, setJumpToIndex] = useState<number>(-1)

  const [updateMessageReaction] = useUpdateMessageReactionMutation()
  const [sendPinnedConversations] = useSendPinnedConversationsMutation()
  const [deleteMessage] = useDeleteMessageMutation()
  const { data, isLoading } = useGetThreadMessageQuery(
    { messageId },
    { refetchOnMountOrArgChange: true },
  )

  const {
    isDragging,
    getInputProps,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    getRootProps,
    onUploadAudio,
  } = useUploadFiles({ id: messageId })

  const { openModal, closeModal, Modal } = useInfoModal()

  const onChangeThreadsFromSocket = (updatedMessage: IUpdateMessage, isDeleted?: boolean) => {
    const index = threadsRef.current.findIndex(
      (message: IMessage) =>
        message._id === updatedMessage._id || message._id === updatedMessage.messageId,
    )
    if (index !== -1) {
      let tempThreads = [...threadsRef.current]
      if (isDeleted) {
        tempThreads[index] = { ...threadsRef.current[index], isDeleted }
      } else {
        tempThreads[index] = { ...updatedMessage }
      }
      tempThreads = tempThreads.map(setMessageData)

      threadsRef.current = [...tempThreads]
      setThreads(tempThreads)
    }
  }

  useEffect(() => {
    const index = data?.data?.threads?.findIndex((message: IMessage) => message._id === threadId)
    setJumpToIndex(index)
  }, [threadId])

  useEffect(() => {
    socket.on(SOCKET.MESSAGE_DELETED, (message) => {
      onChangeThreadsFromSocket(message, true)
    })
    socket.on(SOCKET.MESSAGE_UPDATED, (message) => {
      onChangeThreadsFromSocket(message)
    })
    socket.on(SOCKET.REACTION_SENT, (message) => {
      onChangeThreadsFromSocket(message)
    })
    socket.on(SOCKET.PINNED_MESSAGE, (message) => {
      onChangeThreadsFromSocket(message)
    })
    socket.on(SOCKET.THREAD_MESSAGE_PANEL, (message) => {
      if (messageId === message.threadId) {
        const pendingMessageIndex = threadsRef.current.findIndex(
          (existingMessage: IMessage) =>
            existingMessage.status === MessageStatus.PENDING && existingMessage.cti === message.cti,
        )

        let tempThreads = [...threadsRef.current]

        if (pendingMessageIndex !== -1) {
          tempThreads[pendingMessageIndex] = { ...message }
        } else {
          tempThreads.push(message)
        }

        tempThreads = tempThreads.map(setMessageData)

        threadsRef.current = [...tempThreads]
        setThreads(tempThreads)
      }
    })
    return () => {
      socket.off(SOCKET.THREAD_MESSAGE_PANEL)
    }
  }, [messageId])

  useEffect(() => {
    if (messageId && !threadId) {
      threadMessageRef.current?.scrollTo({
        behavior: 'auto',
        top: threadMessageRef.current.scrollHeight,
      })
    }
  }, [threads])

  useEffect(() => {
    if (data?.data?.threads) {
      setThreads([...(data?.data?.threads || [])])
      threadsRef.current = [...(data?.data?.threads || [])]
      if (threadId) {
        const index = data?.data?.threads?.findIndex((message: IMessage) => {
          return message._id === threadId
        })
        setJumpToIndex(index)
      } else {
        setTimeout(() => {
          threadMessageRef.current?.scrollTo({
            behavior: 'smooth',
            top: threadMessageRef.current.scrollHeight,
          })
        }, 0)
      }
    }
  }, [data?.data?.threads, messageId])

  const onTogglePinMessage = (messageId: string, pinned: boolean) => {
    sendPinnedConversations({ roomId, messageId, pinned })
  }

  const onApproveDeleteMessage = async (messageId: string) => {
    deleteMessage({ messageId }).finally(closeModal)
  }

  const onDeleteMessage = (messageId: string) => {
    openModal({
      title: t(MODALS.DELETE_MESSAGE_TITLE),
      description: t(MODALS.DELETE_MESSAGE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.DELETE) as string,
      onCancel: closeModal,
      onOk: () => onApproveDeleteMessage(messageId),
    })
  }

  const onReactionMessage = (messageId: string, emoji: string, isAdd: boolean) => {
    updateMessageReaction({ messageId, emoji, add: isAdd })
  }

  if (isLoading) {
    return <Spinner loading={isLoading} />
  }

  const pendingHandler = (message: IMessage) => {
    setThreads([...threadsRef.current, message])
    threadsRef.current = [...threadsRef.current, message]
  }

  return (
    <OutThreadWrapper
      ref={threadMessageRef}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
    >
      <Dropzone isDragging={isDragging} getRootProps={getRootProps} />
      {data?.data && (
        <NewMessage
          data={data?.data}
          threadMainMessage
          index={-1}
          myId={myId}
          visibleOption={false}
          visibleThread={false}
          visibleReaction={false}
        />
      )}
      <Separator margin={{ top: 10, bottom: 10 }} />
      {threads?.map((message: IMessage, index: number) => {
        return (
          <NewMessage
            roomId={roomId}
            key={message._id}
            index={index}
            onResetThreadId={onResetThreadId}
            visibleOption={!isArchived && !isReadOnly}
            jumpToIndex={jumpToIndex}
            data={message}
            myId={myId}
            onTogglePinMessage={onTogglePinMessage}
            onDeleteMessage={onDeleteMessage}
            onReactionMessage={onReactionMessage}
          />
        )
      })}
      <div style={{ position: 'relative' }}>
        <MessageInput
          isReadOnly={isReadOnly}
          isArchived={isArchived}
          messageIdInThread={messageId}
          roomId={roomId}
          users={users}
          placeHolder={t(PLACEHOLDERS.REPLY_PLACEHOLDER)}
          getInputProps={getInputProps}
          onUploadAudio={onUploadAudio}
          pendingHandler={pendingHandler}
          onUpdatePastedFileTargetId={onUpdatePastedFileTargetId}
        />
        <InputInfo typography="body6" color={colors.gray80}>
          {t(PLACEHOLDERS.ADD_NEW_LINE)}
        </InputInfo>
      </div>
      {Modal}
    </OutThreadWrapper>
  )
}

export default ThreadMessages

export const InputInfo = styled(CustomText)`
  position: absolute;
  bottom: -2px;
  right: 30px;
  margin: 2px 0px 2px 0px;
`

export const OutThreadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2px;
  height: 100%;
  overflow: auto;
`
