import { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useIsElectron } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  useCloseThreadMutation,
  useDeleteMessageMutation,
  useLazyGetJumpMessagesQuery,
  useGetMessagesByPageMutation,
  useUpdateMessageReactionMutation,
} from 'app/services/conversation.service'
import {
  useJoinPublicChannelMutation,
  useSendPinnedConversationsMutation,
  useUpdateRoomChannelApparanceMutation,
} from 'app/services/room.service'
import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO,
  MOBILE_WIDTH,
  SCROLL_THRESHOLD,
  SIDE_PANEL_PIN,
  SIDE_PANEL_SEARCH,
  SIDE_PANEL_THREAD,
} from 'utils/constants'

import { FixMeLater } from 'types'
import { RoomTypes } from 'types/rooms.types'

import { ConversationSidePanelState, IMessage } from 'types/conversation.types'
import useInfoModal from 'utils/hooks/useInfoModal'
import { groupsActions } from 'app/slices/groups.slice'
import useUploadFiles from 'utils/hooks/useUploadFiles'
import { scrollBottom } from 'utils/helpers/scrollHelper'
import { useOutletState } from 'utils/hooks/useOutletState'
import { conversationActions } from 'app/slices/conversations.slice'
import { BUTTONS, MODALS, PLACEHOLDERS, TABS, TITLES } from 'locales/locales'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'

import Loading from 'components/spinner/Loading'
import Dropzone from 'components/dropzone/Dropzone'
import MessageInput from 'components/input/message-input/MessageInput'
import PinMessages from 'components/conversation-side-panel/PinMessages'
import ThreadMessages from 'components/conversation-side-panel/ThreadMessages'
import SearchMessages from 'components/conversation-side-panel/SearchMessages'
import ChannelInfoModal from 'features/channels/channel-info/ChannelInfoModal'
import ConversationHeader from 'components/conversation-header/ConversationHeader'
import CustomConversationScroll from 'components/infinite-scroll/CustomConversationScroll'
import ConversationSidePanel from 'components/conversation-side-panel/ConversationSidePanel'
import Jumper from 'components/new-message/jumper/Jumper'
import usePasteFile from 'utils/hooks/usePasteFile'
import Spinner from 'components/spinner/Spinner'
import { concatDaySeparator } from 'utils/helpers/messageHelper'
import { IGroup } from 'types/group.types'

const Conversation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { onToggleConversationPanel, channelId, channel } = useOutletState()

  const infiniteScrollContainerRef = useRef<FixMeLater>()
  const sidePanelStateRef = useRef<ConversationSidePanelState>()
  const channelIdRef = useRef<string>()

  const { openModal, closeModal, Modal } = useInfoModal()
  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()
  const { onUpdatePastedFileTargetId } = usePasteFile({})

  const myId = useAppSelector((state) => state.auth.id)
  const [isShowSpinner, setIsShowSpinner] = useState(false)
  const users = useAppSelector((state) => state.users.data)
  const { draftData, renderedRooms } = useAppSelector((state) => state.message)
  const messagesData = useAppSelector((state) => state.message.data)[channelId]
  const groups: IGroup[] = useAppSelector((state) => state.groups.data)

  const [counter, setCounter] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [jumpToIndex, setJumpToIndex] = useState(-1)
  const [isSearchInput, setSearchInput] = useState(false)
  const [visibleChannelInfo, setVisibleChannelInfo] = useState(false)
  const [isScrollAboveThreshold, setScrollAboveThreshold] = useState(false)
  const [conversationSidePanel, setConversationSidePanel] = useState<ConversationSidePanelState>({
    visible: false,
    type: undefined,
    params: undefined,
  })
  const [windowApi,setWindowApi] = useState<any>(null);

  const [deleteMessage] = useDeleteMessageMutation()
  const [getJumpMessages] = useLazyGetJumpMessagesQuery()
  const [joinPublicChannel] = useJoinPublicChannelMutation()
  const [getMessagesByPage] = useGetMessagesByPageMutation()
  const [updateMessageReaction] = useUpdateMessageReactionMutation()
  const [sendPinnedConversations] = useSendPinnedConversationsMutation()
  const [updateRoomChannelApparance] = useUpdateRoomChannelApparanceMutation()
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [closeThread] = useCloseThreadMutation()

  const {
    isDragging,
    getInputProps,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    getRootProps,
    onUploadAudio,
  } = useUploadFiles({ id: channelId })

  const onScrollBottom = () => {
    scrollBottom(infiniteScrollContainerRef)
  }

  const location = useLocation()
  const isElectron = useIsElectron();
  useEffect(()=>{
    const w = window as any;
    if (w.api)
    setWindowApi(w.api);
  },[])

  useEffect(() => {
    const onUnload = () => {
      closeOpenThread()
    }

    const closeOpenThread = () => {
      if (
        sidePanelStateRef.current?.params?.messageId &&
        sidePanelStateRef.current?.type === SIDE_PANEL_THREAD
      ) {
        closeThread({
          roomId: channelIdRef.current,
          threadId: sidePanelStateRef.current.params?.messageId,
        })
      }
    }
    // close open thread on page refresh
    window.addEventListener('beforeunload', onUnload)
    return () => {
      // close open thread on unmount
      closeOpenThread()
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  useEffect(() => {
    sidePanelStateRef.current = conversationSidePanel
  }, [conversationSidePanel])

  useEffect(() => {
    const teamOfChannel = groups.find((group: IGroup) => group._id === channel.groupId)
    // if team of the channel is archived, navigate to another random team which is unarchived,
    // if there is not such unarchived existing team, navigate to '/channels' instead.
    if (teamOfChannel?.isArchived) {
      const unarchivedTeam = groups.find((group) => !group.isArchived)
      if (unarchivedTeam) {
        const generalChannelOfUnarchivedTeam = unarchivedTeam.channels.find(
          (c) => c.name.toLowerCase() === 'general',
        )
        navigate('/channels/' + generalChannelOfUnarchivedTeam?._id)
      } else {
        navigate('/channels')
      }
    }
  }, [channel])

  useEffect(() => {
    if (location.state?.action === 'JUMP_TO_THREAD') {
      const { threadId, messageId, roomId } = location.state

      setConversationSidePanel({
        visible: true,
        type: SIDE_PANEL_THREAD,
        params: { threadId, messageId, roomId, users, myId },
      })
    }
  }, [location.state, users, myId])

  useEffect(() => {
    if (messagesData?.page === undefined) {
      getInitialData()
    } else if (messagesData?.page === 0 && !renderedRooms.includes(channelId)) {
      dispatch(conversationActions.clearRoomMessages({ roomId: channelId }))
      getInitialData()
    }
    onScrollBottom()
    setSearchInput(false)
    channelIdRef.current = channelId
    dispatch(conversationActions.updateRenderedRooms(channelId))
    setConversationSidePanel((prev) => {
      return { ...prev, visible: false }
    })
    return () => {
      dispatch(groupsActions.updateUnreadCount({ roomId: channelId, increase: false }))
      dispatch(conversationActions.removeUnreadSep({ roomId: channelId }))
    }
  }, [channelId])

  useEffect(() => {
    if (messagesData?.newMessage && isScrollAboveThreshold) {
      onIncreaseMessageJumperCount()
    }
  }, [messagesData?.messages?.length])

  const onChangeSearch = (text: string) => {
    setSearchText(text)
    if (text.trim().length > 0) {
      onToggleConversationSidePanel(true, SIDE_PANEL_SEARCH)
    }
  }

  const onToggleConversationSidePanel = useCallback(
    (visible: boolean, type?: string, params?: { messageId: string; threadId?: string }) => {
      setConversationSidePanel({ visible, type, params })

      const threadId = params?.messageId ?? conversationSidePanel.params?.messageId
      if (!visible && conversationSidePanel.type === SIDE_PANEL_THREAD) {
        closeThread({
          roomId: channelId,
          threadId,
        })
      } else if (visible && type === SIDE_PANEL_THREAD) {
        // if switched from one thread to another while panel is open, close previous one.
        if (conversationSidePanel.params?.messageId) {
          closeThread({
            roomId: channelId,
            threadId: conversationSidePanel.params?.messageId,
          })
        }
      }
    },
    [conversationSidePanel, channelId],
  )

  const onVideoCall = async () => {
    openConferenceModal({
      title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
      description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeConferenceModal,
      onOk: () => creatingConference(CONFERENCE_TYPE_VIDEO),
    })
  }



  const creatingConference = useCallback(
    async (conferenceType: string) => {
      try {
        Loading.ref.show();
        const data = await createConference({
          roomId: channelId,
          type: 'channel',
          conferenceType: conferenceType,
        }) || { status: 'pending', data: { data: {} } }; 

        if (data.status === 'rejected') {
          return;
        }

        const { title, url } = data.data || {};

      
        if (isElectron && windowApi) {
          const notifyType = conferenceType === CONFERENCE_TYPE_VIDEO ? 'videoConference' : 'audioConference';
          windowApi.send('callSelfBtn', {
            type: notifyType,
            title: "groupSelfCall",
            msg: title,
            url: url 
          });
        } else {
          window.open(url, '', CONFERENCE_BLANK_DIMENSIONS);
        }
      } finally {
        closeConferenceModal();
        Loading.ref.hide();
      }
    },
    [windowApi] 
  );


  const getInitialData = useCallback(async () => {
    try {
      setIsShowSpinner(true)
      await getMessagesByPage({ roomId: channelId, pageNumber: 0 })
    } finally {
      setIsShowSpinner(false)
    }
  }, [channelId])

  const onPagination = useCallback(() => {
    getMessagesByPage({ roomId: channelId, pageNumber: messagesData?.page + 1 || 0 })
  }, [channelId, messagesData?.page])

  const toggleChannelInfo = useCallback(() => {
    if (channel.hasSubscription !== false) setVisibleChannelInfo(!visibleChannelInfo)
  }, [channel.hasSubscription, visibleChannelInfo])

  const onUnarchiveChannel = useCallback(async () => {
    try {
      const result: FixMeLater = await updateRoomChannelApparance({
        roomId: channelId,
        isAppear: false,
      })
      dispatch(groupsActions.updateApperance(result.data))
      navigate(result.data.redirectUrl)
      dispatch(conversationActions.removeAllDraftAndFailedMessages(channelId))
    } catch (e) {
      console.log(e)
    }
  }, [channelId])

  const onJumpMessage = useCallback(
    async (messageId: string, threadId?: string) => {
      if (threadId) {
        onToggleConversationSidePanel(true, SIDE_PANEL_THREAD, { messageId, threadId })
      } else {
        const { data }: FixMeLater = await getJumpMessages({ roomId: channelId, messageId })
        dispatch(conversationActions.getJumpMessages(data))
        const clonedData = [...data.data]
        const reStructeredMessages = concatDaySeparator(clonedData)
        const index: number = reStructeredMessages.findIndex(
          (message: IMessage) => message._id === messageId,
        )
        setJumpToIndex(index)
      }
      setSearchInput(false)
    },
    [channelId],
  )

  const onTogglePinMessage = useCallback(
    (messageId: string, pinned: boolean) => {
      sendPinnedConversations({ roomId: channelId, messageId, pinned })
    },
    [channelId],
  )

  const onApproveDeleteMessage = async (messageId: string) => {
    deleteMessage({ messageId }).finally(closeModal)
  }

  const onDeleteMessage = (messageId: string) => {
    openModal({
      title: t(MODALS.DELETE_MESSAGE_TITLE),
      description: t(MODALS.DELETE_MESSAGE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.DELETE) as string,
      onCancel: closeModal,
      onOk: () => onApproveDeleteMessage(messageId),
    })
  }

  const onReactionMessage = (messageId: string, emoji: string, isAdd: boolean) => {
    updateMessageReaction({ messageId, emoji, add: isAdd })
  }
  const onJoinChannel = useCallback(async () => {
    await joinPublicChannel({ channelId })
  }, [channelId])

  const onAppearChannel = useCallback(async () => {
    try {
      const result: FixMeLater = await updateRoomChannelApparance({
        roomId: channelId,
        isAppear: true,
      })
      dispatch(groupsActions.updateApperance(result.data))
    } catch (e) {
      console.log(e)
    }
  }, [channelId])

  const onCloseMessageJumper = () => {
    setCounter(0)
  }

  const onIncreaseMessageJumperCount = () => {
    setCounter((counter) => counter + 1)
  }

  const scrollHandler = () => {
    if (infiniteScrollContainerRef.current.scrollTop <= SCROLL_THRESHOLD) {
      setScrollAboveThreshold(true)
    } else if (infiniteScrollContainerRef.current.scrollTop > SCROLL_THRESHOLD) {
      setCounter(0)
      setScrollAboveThreshold(false)
    }
  }

  const onResetThreadId = () => {
    setConversationSidePanel((prevState) => ({
      ...prevState,
      params: { ...prevState.params, threadId: undefined },
    }))
  }

  const group = groups.find((group: IGroup) => group._id === channel.groupId);

  return (
    <Content>
      {isShowSpinner && <Spinner position="absolute" loading={true} />}
      {visibleChannelInfo && <ChannelInfoModal onClose={toggleChannelInfo} />}
      <ConversationHeader
        type={RoomTypes.CM}
        roomName={channel.name}
        isPrivate={channel.isPrivate}
        description={channel?.members?.length + ' ' + t(TABS.MEMBERS)}
        pins={channel.pins}
        searchText={searchText}
        isVideoCall={channel.videoCall}
        visibleSearchInput={isSearchInput}
        roomId={channelId}
        onVideoCall={onVideoCall}
        onChangeSearch={onChangeSearch}
        onOpenRoomInfo={toggleChannelInfo}
        onToggleSearchInput={setSearchInput}
        onToggleConversationPanel={onToggleConversationPanel}
        onToggleConversationSidePanel={onToggleConversationSidePanel}
      />
      {counter > 0 && (
        <Jumper counter={counter} onScrollBottom={onScrollBottom} onClose={onCloseMessageJumper} />
      )}
      <ContentBody>
        <ContentSection
          isTrue={conversationSidePanel.visible}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <Dropzone isDragging={isDragging} getRootProps={getRootProps} />
          <CustomConversationScroll
            visibleOption={!channel?.isArchived && !channel?.readOnly}
            roomId={channelId}
            myId={myId}
            jumpToIndex={jumpToIndex}
            scrollRef={infiniteScrollContainerRef}
            dataLength={messagesData?.messages?.length || 0}
            data={messagesData?.messages || []}
            draftData={draftData}
            hasMore={messagesData?.hasMore === undefined ? false : messagesData.hasMore}
            onPagination={onPagination}
            onScrollHandler={scrollHandler}
            onDeleteMessage={onDeleteMessage}
            onResetJumpToIndex={setJumpToIndex}
            onReactionMessage={onReactionMessage}
            onTogglePinMessage={onTogglePinMessage}
            onReplyMessage={onToggleConversationSidePanel}
          />

          <MessageInput
            isJoinChannel={channel?.hasSubscription === false}
            isReadOnly={channel?.readOnly}
            isArchived={channel?.isArchived}
            isAppear={channel?.isAppear}
            users={group?.userData ?? {}}
            roomId={channelId}
            channel
            groups={groups}
            placeHolder={t(PLACEHOLDERS.MESSAGE_PLACEHOLDER)}
            getInputProps={getInputProps}
            onUploadAudio={onUploadAudio}
            onAppearChannel={onAppearChannel}
            onUnarchiveChannel={onUnarchiveChannel}
            onJoinChannel={onJoinChannel}
            onScrollBottom={onScrollBottom}
            onUpdatePastedFileTargetId={onUpdatePastedFileTargetId}
          />
        </ContentSection>

        {conversationSidePanel.visible && (
          <ConversationSidePanel
            title={
              conversationSidePanel.type === SIDE_PANEL_PIN
                ? t(TITLES.PIN_TITLE)
                : conversationSidePanel.type === SIDE_PANEL_THREAD
                ? t(TITLES.THREAD_TITLE)
                : t(TITLES.SEARCH_TITLE)
            }
            onClose={onToggleConversationSidePanel}
            onToggleSearchInput={setSearchInput}
            onChangeSearch={onChangeSearch}
            content={
              <>
                {conversationSidePanel.type === SIDE_PANEL_PIN && (
                  <PinMessages users={group?.userData ?? {}} myId={myId} onJumpMessage={onJumpMessage} />
                )}
                {conversationSidePanel.type === SIDE_PANEL_THREAD && (
                  <ThreadMessages
                    {...conversationSidePanel.params}
                    roomId={channelId}
                    users={group?.userData ?? {}}
                    myId={myId}
                    isReadOnly={channel?.readOnly}
                    isArchived={channel?.isArchived}
                    onResetThreadId={onResetThreadId}
                    onUpdatePastedFileTargetId={onUpdatePastedFileTargetId}
                  />
                )}
                {conversationSidePanel.type === SIDE_PANEL_SEARCH && (
                  <SearchMessages
                    value={searchText}
                    roomId={channelId}
                    users={group?.userData ?? {}}
                    myId={myId}
                    onJumpMessage={onJumpMessage}
                  />
                )}
              </>
            }
          />
        )}
      </ContentBody>
      {Modal}
      {ConferenceModal}
    </Content>
  )
}

export default Conversation

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
`

const ContentBody = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    display: ${(props: { isTrue: boolean }) => (props.isTrue ? `none;` : `flex`)};
    position: ${(props: { isTrue: boolean }) => (props.isTrue ? `absolute` : `static`)};
  }
`
