import { RefObject } from 'react'

export const scrollBottom = (ref: RefObject<HTMLElement>) => {
  setTimeout(() => {
    ref?.current?.scrollTo({
      behavior: 'auto',
      top: ref.current.scrollHeight + 200,
    })
  }, 0)
}
