import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  useLazyGetAllChannelsInSystemQuery,
  useLazyGetMembersBySearchForChannelQuery,
} from 'app/services/group.service'
import { useScheduleConferenceMutation } from 'app/services/conference.service'
import { FixMeLater } from 'types'
import { DROPDOWN, MODALS, TABS } from 'locales/locales'
import { IChannelMember } from 'types/user.types'

import AdvancedSettings from './AdvancedSettings'
import CreateMeetingForm from './CreateMeetingForm'
import CustomModal from 'components/modals/CustomModal'
import Participants from './Participants'

interface ICreateMeetingModalProps {
  onCancel: () => void
  onClose: () => void
}

export const DeviceShareOptions = [
  { title: t(DROPDOWN.UNRESTIRECTE), value: 0 },
  { title: t(DROPDOWN.ASK_ADMINISTATOR), value: 1 },
  { title: t(DROPDOWN.ADMINISTATOR_INITIATES), value: 2 },
]
export const RepeadDayOptions = [
  { title: t(DROPDOWN.MONDAY), value: 1 },
  { title: t(DROPDOWN.TUESDAY), value: 2 },
  { title: t(DROPDOWN.WEDNESDAY), value: 4 },
  { title: t(DROPDOWN.THURSDAY), value: 8 },
  { title: t(DROPDOWN.FRIDAY), value: 16 },
  { title: t(DROPDOWN.SATURDAY), value: 32 },
  { title: t(DROPDOWN.SUNDAY), value: 64 },
  { title: t(DROPDOWN.WEEKDAY), value: 128 },
  { title: t(DROPDOWN.WEEKEND), value: 256 },
  { title: t(DROPDOWN.EVERYDAY), value: 512 },
]
const initalMeetingData = {
  meetingName: '',
  meetingDescription: '',
  meetingTime: new Date(),
  duration: '15',
  deviceRestriction: 0,
  hasWaitingRoom: false,
  hasNotification: false,
  publicDraw: false,
  isPublic: true,
  guestMode: true,
  isPasswordProtected: false,
  password: '',
  isRepeatable: false,
  repeatDays: [],
  repeatEndTime: new Date(),
}
const getIsEmail = (email: string) => {
  const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return emailRegex.test(email)
}
const CreateMeetingModal = ({ onCancel, onClose }: ICreateMeetingModalProps) => {
  const { t } = useTranslation()
  const [members, setMembers] = useState<IChannelMember[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [formChecked, setFormChecked] = useState(false)
  const [meetingInput, setMeetingInput] = useState({ ...initalMeetingData })
  const [selectedUsers, setSelectedUsers] = useState<FixMeLater>([])
  const [membersBySearch, setMembersBySearch] = useState<IChannelMember[]>([])
  const [channelDropDownData, setChannelDropDownData] = useState<FixMeLater>([])
  const [selectedDeviceShareOption, setSelectedDeviceShareOption] = useState(DeviceShareOptions[0])
  const [selectedRepeatOptions, setselectedRepeatOptions] = useState<FixMeLater>([])
  const [channelSelectedOption, setChannelSelectedOption] = useState({})
  const [getMyChannels] = useLazyGetAllChannelsInSystemQuery()
  const [getMembersBySearch] = useLazyGetMembersBySearchForChannelQuery()
  const [scheduleMeet] = useScheduleConferenceMutation()

  useEffect(() => {
    fetchMyChannels()
    setIsLoading(false)
  }, [])

  const fetchMyChannels = async () => {
    const data = (await getMyChannels({})) as FixMeLater

    const dropdownChannel = data.data.data.map((group:any)=>{
      return{
        title:group.name,
        value:group._id
      }
    })

    setChannelDropDownData(dropdownChannel)
  }

  const onOk = async () => {
    setFormChecked(true)
    const repeatDays = selectedRepeatOptions.map((option: FixMeLater) => option.value)
    const data = {
      meetingName: meetingInput.meetingName,
      meetingDescription: meetingInput.meetingDescription,
      meetingTime: meetingInput.meetingTime,
      duration: meetingInput.duration,
      deviceRestriction: selectedDeviceShareOption.value,
      hasWaitingRoom: meetingInput.hasWaitingRoom,
      hasNotification: meetingInput.hasNotification,
      publicDraw: meetingInput.publicDraw,
      isPublic: meetingInput.isPublic,
      guestMode: meetingInput.guestMode,
      isPasswordProtected: meetingInput.isPasswordProtected,
      password: meetingInput.password,
      isRepeatable: meetingInput.isRepeatable,
      repeatDays: repeatDays,
      repeatEndTime: meetingInput.repeatEndTime,
      forChannel: selectedUsers,
      timeZoneOffset: -180,
      members,
    }
    try {
      setIsLoading(true)
      const res: FixMeLater = await scheduleMeet(data)
      if (res?.data?.success) {
        onClose()
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onSearch = async (text: string) => {
    try {
      const result: FixMeLater = await getMembersBySearch({ searchName: text })
      const filteredUsers = result.data.data.filter((fetchedUser: IChannelMember) => {
        const findedMember = members.findIndex(
          (member) => member._id === fetchedUser._id || member._id === fetchedUser.email,
        )
        return findedMember !== -1 ? false : true
      })
      if (getIsEmail(text)) {
        const hasUser = members.find((member) => member.email === text)
        if (!hasUser) {
          filteredUsers.push({ email: text })
        }
      }
      setMembersBySearch(filteredUsers)
    } catch (e) {
      console.log(e)
    }
  }
  const onChangeInput = (value: string | boolean | Date | FixMeLater, key: string) => {
    if (key === 'channel_option') {
      const filtered = channelDropDownData.filter((user: FixMeLater) => user.value !== value.value)
      setChannelDropDownData(filtered)
      setSelectedUsers([...selectedUsers, value])
      if(filtered.length === 0){
        value = ''; //Prevent [object object]
      }
      setChannelSelectedOption(value)
    } else if (key === 'device_share_restriction') {
      setSelectedDeviceShareOption(value)
    } else if (key === 'repeat_days') {
      const index = selectedRepeatOptions.findIndex((option: FixMeLater) => {
        return option.value === value.value
      })
      if (index === -1) {
        setselectedRepeatOptions([...selectedRepeatOptions, value])
      }
    } else if (key === 'duration') {
      if (parseInt(value) > 0) {
        setMeetingInput({ ...meetingInput, [key]: value })
      }
    } else {
      setMeetingInput({ ...meetingInput, [key]: value })
      console.log(meetingInput)
    }
  }

  const removeUser = (param: FixMeLater) => {
    const filteredData = selectedUsers.filter((user: FixMeLater) => user.value !== param.value)
    setChannelSelectedOption({})
    setSelectedUsers(filteredData)
    setChannelDropDownData([...channelDropDownData, param])
  }

  const onSelectMember = (user: IChannelMember) => {
    setMembers([...members, user])
  }

  const onRemoveUser = (user: IChannelMember) => {
    const removedData = members.filter(
      (member) => member._id !== user._id || member.email !== user.email,
    )
    setMembers([...removedData])
  }

  const onRemoveRepeatDay = (value: FixMeLater) => {
    const removedData = selectedRepeatOptions.filter((option: FixMeLater) => {
      return option.value !== value.value
    })
    setselectedRepeatOptions(removedData)
  }

  const closeModal = () => {
    setFormChecked(false)
    onClose()
  }

  const tab = [
    { title: t(TABS.GENERAL), action: true },
    { title: t(TABS.PARTICIPANTS) },
    { title: t(TABS.ADVANCED) },
  ]

  return (
    <CustomModal
      closeModal={closeModal}
      title={t(MODALS.CREATE_MEETING_TITLE)}
      onCancel={onCancel}
      loading={isLoading}
      overflow={'visible'}
      tab={tab}
      onOk={onOk}
    >
      <CreateMeetingForm {...meetingInput} onChangeInput={onChangeInput} />
      <Participants
        {...meetingInput}
        data={members}
        channelDownData={channelDropDownData}
        channelSelectedOption={channelSelectedOption}
        selectedChannels={selectedUsers}
        formChecked={formChecked}
        searchedData={membersBySearch}
        onRemoveUser={onRemoveUser}
        onSelectMember={onSelectMember}
        removeUser={removeUser}
        onChangeInput={onChangeInput}
        onChangeSearchText={onSearch}
      />
      <AdvancedSettings
        {...meetingInput}
        selectedRepeatOptions={selectedRepeatOptions}
        selectedOption={selectedDeviceShareOption}
        onChangeInput={onChangeInput}
        onRemoveRepeatDay={onRemoveRepeatDay}
      />
    </CustomModal>
  )
}

export default CreateMeetingModal
