import { createSlice } from '@reduxjs/toolkit'

export type TConferenceAlert = {
  meetingId: string
  from?: string
  title: string
  avatar: string
  description: string
  date: string
  url: string
  isPrivate?: boolean
  conferenceType: string
}

interface ConferenceState {
  data: TConferenceAlert[]
  visible: boolean
}

const initialState: ConferenceState = {
  data: [],
  visible: false,
}

export const { actions: conferenceActions, reducer: ConferenceReduder } = createSlice({
  initialState,
  name: 'conference',
  reducers: {
    fillInitialState(state, action) {
      if (action.payload?.length === 0) return
      state.data = action.payload
      state.visible = true
    },
    showAlerts(state) {
      state.visible = true
    },
    newConference(state, action) {
      state.data = [...state.data, action.payload]
      state.visible = true
    },
    resolveConference(state, action) {
      state.data.splice(action.payload.index, 1)
      if (state.data.length == 0) state.visible = false
    },
    rejectConference(state, action) {
      state.data = state.data.filter((meet) => meet.meetingId !== action.payload.meetingId)
      if (state.data.length == 0) state.visible = false
    },
    clearAllConference(state) {
      state.data = []
      state.visible = false
    },
  },
})
