import { DOCX_TYPE, DOC_TYPE, PDF_TYPE, PPTX_TYPE, XLSX_TYPE, ZIP_TYPE } from 'utils/constants'
import { icons } from 'utils/icons'

export const getFileIcon = (mimetype: string) => {
  if (mimetype === DOC_TYPE || mimetype === DOCX_TYPE) {
    return icons.msWordIcon
  } else if (mimetype === PDF_TYPE) {
    return icons.pdfIcon
  } else if (mimetype === PPTX_TYPE) {
    return icons.powerpointIcon
  } else if (mimetype === XLSX_TYPE) {
    return icons.excelIcon
  } else if (mimetype === ZIP_TYPE) {
    return icons.zipIcon
  } else if (mimetype?.includes('audio')) {
    return icons.sendActiveRecordPlay
  } else if (mimetype?.includes('text')) {
    return icons.subtractIcon
  }
  return icons.pdfIcon
}
