import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector, useIsElectron } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  SIDE_PANEL_PIN,
  SIDE_PANEL_THREAD,
  SIDE_PANEL_SEARCH,
  clientChannel,
  DELETE_OTR,
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO,
  SCROLL_THRESHOLD,
} from 'utils/constants'
import {
  useCloseThreadMutation,
  useDeleteMessageMutation,
  useLazyGetJumpMessagesQuery,
  useGetMessagesByPageMutation,
  useUpdateMessageReactionMutation,
} from 'app/services/conversation.service'
import {
  useCreateOtrConversationMutation,
  useSendPinnedConversationsMutation,
  useUpdateRoomChannelApparanceMutation,
} from 'app/services/room.service'

import { FixMeLater } from 'types'
import useRoom from 'utils/hooks/useRoom'
import { RoomTypes } from 'types/rooms.types'
import { otrActions } from 'app/slices/otr.slice'

import { roomsActions } from 'app/slices/rooms.slice'
import useUploadFiles from 'utils/hooks/useUploadFiles'
import useOffTheRecord from 'utils/hooks/useOffTheRecord'
import { scrollBottom } from 'utils/helpers/scrollHelper'
import { useOutletState } from 'utils/hooks/useOutletState'
import { concatDaySeparator, getPreparedMessages } from 'utils/helpers/messageHelper'
import { conversationActions } from 'app/slices/conversations.slice'
import { ConversationSidePanelState, IMessage, MessageUserType } from 'types/conversation.types'
import { BUTTONS, MODALS, PLACEHOLDERS, TITLES } from 'locales/locales'
import { useCloseOtrMutation, useLeaveOtrMutation } from 'app/services/otrService'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'

import Loading from 'components/spinner/Loading'
import useInfoModal from 'utils/hooks/useInfoModal'
import Dropzone from 'components/dropzone/Dropzone'
import InfoModal from 'components/info-modal/InfoModal'
import ConversationInfo from './conversation-info/ConversationInfo'
import MessageInput from 'components/input/message-input/MessageInput'
import PinMessages from 'components/conversation-side-panel/PinMessages'
import ThreadMessages from 'components/conversation-side-panel/ThreadMessages'
import SearchMessages from 'components/conversation-side-panel/SearchMessages'
import ConversationHeader from 'components/conversation-header/ConversationHeader'
import CustomConversationScroll from 'components/infinite-scroll/CustomConversationScroll'
import ConversationSidePanel from 'components/conversation-side-panel/ConversationSidePanel'
import Jumper from 'components/new-message/jumper/Jumper'
import usePasteFile from 'utils/hooks/usePasteFile'
import Spinner from 'components/spinner/Spinner'

const Messages = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isElectron = useIsElectron()
  const { t } = useTranslation()
  const { onToggleConversationPanel } = useOutletState()
  const { room, myId, roomId, myRoomsId, users } = useRoom()

  const otrData = useAppSelector((state) => state.otr)
  const { fullname } = useAppSelector((state) => state.auth.user)
  const [isShowSpinner, setIsShowSpinner] = useState(false)
  const { draftData, renderedRooms } = useAppSelector((state) => state.message)
  const messagesData = useAppSelector((state) => state.message.data)[roomId as string]

  const { onOtrRequest, setOtrRequestModal, OtrRequestModal } = useOffTheRecord({
    otrRooms: otrData,
    currentRoomId: roomId,
    currentUsersRoomId: myRoomsId,
  })

  const { openModal, closeModal, Modal } = useInfoModal()
  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()
  const {
    openModal: openStartOtrConfirmModal,
    closeModal: closeStartOtrConfirmModal,
    Modal: StartOtrConfirmModal,
  } = useInfoModal()
  const { onUpdatePastedFileTargetId } = usePasteFile({})
  // STATES AND REFS
  const infiniteScrollContainerRef = useRef<FixMeLater>()
  const sidePanelStateRef = useRef<ConversationSidePanelState>()
  const roomIdRef = useRef<string>()
  const roomRef = useRef<{
    roomName: FixMeLater
    description: FixMeLater
    url: FixMeLater
    createdAt: FixMeLater
    isAppear: FixMeLater
    createdByUserId: FixMeLater
    isDeleted: FixMeLater
    isVanishMode: FixMeLater
    type: FixMeLater
    isOwnerMe: boolean
    pins: FixMeLater
    members: FixMeLater
    acceptingUsers: FixMeLater
    isMe: boolean
    myRoomsId: FixMeLater
    unreadCount: FixMeLater
  }>()

  const [counter, setCounter] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [jumpToIndex, setJumpToIndex] = useState(-1)
  const [isSearchInput, setSearchInput] = useState(false)
  const [visibleRoomInfo, setVisibleRoomInfo] = useState(false)
  const [isScrollAboveThreshold, setScrollAboveThreshold] = useState(false)
  const [visibleOtrConfirmModal, setVisibleOtrConfirmModal] = useState(false)
  const [conversationSidePanel, setConversationSidePanel] = useState<ConversationSidePanelState>({
    visible: false,
    type: undefined,
    params: undefined,
  })
  const [windowApi, setWindowApi] = useState<FixMeLater>(null)

  const [closeOtr] = useCloseOtrMutation()
  const [leaveOtr] = useLeaveOtrMutation()
  const [deleteMessage] = useDeleteMessageMutation()
  const [getJumpMessages] = useLazyGetJumpMessagesQuery()
  const [getMessagesByPage] = useGetMessagesByPageMutation()
  const [createOtrConversation] = useCreateOtrConversationMutation()
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [updateMessageReaction] = useUpdateMessageReactionMutation()
  const [sendPinnedConversations] = useSendPinnedConversationsMutation()
  const [updateRoomChannelApparance] = useUpdateRoomChannelApparanceMutation()
  const [closeThread] = useCloseThreadMutation()

  const {
    isDragging,
    getInputProps,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onDrop,
    getRootProps,
    onUploadAudio,
  } = useUploadFiles({ id: roomId })

  const isMyRoom = useMemo(() => {
    return (
      room?.members.length === 1 && room.type !== RoomTypes.OTR && room.createdByUserId === myId
    )
  }, [room, myId])

  const location = useLocation()

  useEffect(() => {
    const w = window as FixMeLater
    if (w.api) setWindowApi(w.api)
  }, [])

  useEffect(() => {
    const onBeforeUnload = () => {
      closeOpenThread()
      setConversationSidePanel({
        visible: false,
        type: undefined,
        params: undefined,
      })
    }

    const closeOpenThread = () => {
      if (
        sidePanelStateRef.current?.params?.messageId &&
        sidePanelStateRef.current?.type === SIDE_PANEL_THREAD
      ) {
        closeThread({
          roomId: roomIdRef.current,
          threadId: sidePanelStateRef.current.params?.messageId,
        })
      }
    }
    // close open thread on page refresh
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      // close open thread on unmount
      closeOpenThread()
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  useEffect(() => {
    roomRef.current = room ? room : undefined
  }, [room])

  useEffect(() => {
    if (location.state?.action === 'JUMP_TO_THREAD') {
      const { threadId, messageId, roomId } = location.state

      setConversationSidePanel({
        visible: true,
        type: SIDE_PANEL_THREAD,
        params: { threadId, messageId, roomId, users, myId },
      })

      location.state = {}
    }
  }, [location.state, users, myId])

  useEffect(() => {
    sidePanelStateRef.current = conversationSidePanel
  }, [conversationSidePanel])

  useEffect(() => {
    if (room?.isDeleted) {
      setOtrRequestModal((prevState) => ({ ...prevState, visible: false }))
    }
  }, [room?.isDeleted])

  useEffect(() => {
    if (room?.type !== RoomTypes.OTR) {
      setOtrRequestModal((prevState) => ({ ...prevState, visible: false }))
    }

    if (room?.isVanishMode && !room?.members?.includes(myId)) {
      onOtrRequest(users[room?.createdByUserId]?.fullname)
    }

    if (messagesData?.page === undefined) {
      getInitialData()
    } else if (messagesData?.page === 0 && !renderedRooms.includes(roomId)) {
      dispatch(conversationActions.clearRoomMessages({ roomId }))
      getInitialData()
    }

    onScrollBottom()
    setSearchInput(false)
    roomIdRef.current = roomId
    dispatch(conversationActions.updateRenderedRooms(roomId))
    setConversationSidePanel((prev) => ({ ...prev, visible: false }))

    return () => {
      dispatch(roomsActions.updateUnreadCount({ roomId, increase: false }))
      dispatch(conversationActions.removeUnreadSep({ roomId }))
    }
  }, [roomId])

  useEffect(() => {
    if (messagesData?.newMessage && isScrollAboveThreshold) {
      onIncreaseMessageJumperCount()
    }
  }, [messagesData?.messages])

  const getInitialData = useCallback(async () => {
    setIsShowSpinner(true)
    try {
      await getMessagesByPage({ roomId, pageNumber: 0 })
    } finally {
      setIsShowSpinner(false)
    }
  }, [roomId])

  const onChangeSearch = (text: string) => {
    setSearchText(text)
    if (text.trim().length > 0) {
      onToggleConversationSidePanel(true, SIDE_PANEL_SEARCH)
    }
  }

  const onPagination = useCallback(() => {
    getMessagesByPage({ roomId, pageNumber: messagesData?.page + 1 })
  }, [messagesData?.page, roomId])

  const onToggleConversationSidePanel = useCallback(
    (visible: boolean, type?: string, params?: { messageId: string; threadId?: string }) => {
      setConversationSidePanel({ visible, type, params })

      const threadId = params?.messageId ?? conversationSidePanel.params?.messageId
      if (!visible && conversationSidePanel.type === SIDE_PANEL_THREAD) {
        closeThread({
          roomId,
          threadId,
        })
      } else if (visible && type === SIDE_PANEL_THREAD) {
        // if switched from one thread to another while panel is open, close previous one.
        if (conversationSidePanel.params?.messageId) {
          closeThread({
            roomId,
            threadId: conversationSidePanel.params?.messageId,
          })
        }
      }
    },
    [conversationSidePanel, roomId],
  )

  const toggleRoomInfo = useCallback(() => {
    setVisibleRoomInfo(!visibleRoomInfo)
  }, [visibleRoomInfo])

  const onScrollBottom = () => {
    scrollBottom(infiniteScrollContainerRef)
  }

  const onOkConfirmOtrModal = useCallback(() => {
    // Send message to client channel to capture it from other tabs and delete the otr room from them as well.
    clientChannel.postMessage({ type: DELETE_OTR, payload: { roomId } })
    navigate(`/messages/${myRoomsId}`)
    dispatch(roomsActions.removeConversation({ _id: roomId }))
    closeConfirmOtrModal()
    if (room?.createdByUserId === myId) {
      closeOtr({ roomId })
    } else {
      leaveOtr({ roomId })
    }
  }, [roomId, myRoomsId, myId, room?.createdByUserId])

  const closeConfirmOtrModal = () => {
    setVisibleOtrConfirmModal(false)
  }

  const onStartOtr = async () => {
    try {
      const result = await createOtrConversation({
        members: roomRef.current?.members?.filter((member: string) => member !== myId),
      })
      if (result?.data?.roomId) {
        navigate(`/messages/${result.data.roomId}`)
        return
      }
      const roomId = result?.data?.data?.roomId
      if (roomId) {
        dispatch(
          otrActions.sendOtrMessage({
            roomId,
            type: ['text'],
            sender: MessageUserType.SYSTEM,
            content: {
              mentionedUsers: [myId],
              text: `@&_id:${myId}& OTR_CREATED`,
            },
          }),
        )
        dispatch(
          otrActions.sendOtrMessage({
            type: ['text'],
            roomId,
            sender: MessageUserType.SYSTEM,
            content: {
              mentionedUsers: [myId],
              text: `@&_id:${myId}& OTR_JOINED`,
            },
          }),
        )
        navigate(`/messages/${roomId}`)
      }
    } finally {
      closeStartOtrConfirmModal()
    }
  }

  const onJumpMessage = useCallback(
    async (messageId: string, threadId?: string) => {
      if (threadId) {
        onToggleConversationSidePanel(true, SIDE_PANEL_THREAD, { messageId, threadId })
      } else {
        const { data } = await getJumpMessages({ roomId, messageId })
        dispatch(conversationActions.getJumpMessages(data))
        const clonedData = [...data.data]
        const restructeredMessages = concatDaySeparator(clonedData)
        const index = restructeredMessages.findIndex(
          (message: IMessage) => message._id === messageId,
        )
        setJumpToIndex(index)
      }
    },
    [roomId],
  )

  const onTogglePinMessage = useCallback(
    (messageId: string, pinned: boolean) => {
      sendPinnedConversations({ roomId, messageId, pinned })
    },
    [roomId],
  )

  const onApproveDeleteMessage = async (messageId: string) => {
    deleteMessage({ messageId }).finally(closeModal)
  }

  const onDeleteMessage = (messageId: string) => {
    openModal({
      title: t(MODALS.DELETE_MESSAGE_TITLE),
      description: t(MODALS.DELETE_MESSAGE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.DELETE) as string,
      onCancel: closeModal,
      onOk: () => onApproveDeleteMessage(messageId),
    })
  }

  const onOtr = useCallback(() => {
    if (!room?.isVanishMode) {
      openStartOtrConfirmModal({
        title: t(MODALS.START_OTR_TITLE),
        description: t(MODALS.START_OTR_DESCRIPTION),
        cancelTitle: t(BUTTONS.CANCEL) as string,
        okTitle: t(BUTTONS.YES) as string,
        onCancel: closeStartOtrConfirmModal,
        onOk: () => onStartOtr(),
      })
    } else {
      setVisibleOtrConfirmModal(true)
    }
  }, [room?.isVanishMode])

  const onVideoCall = () => {
    openConferenceModal({
      title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
      description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeConferenceModal,
      onOk: () => creatingConference(CONFERENCE_TYPE_VIDEO),
    })
  }

  const creatingConference = useCallback(
    async (conferenceType: string) => {
      try {
        Loading.ref.show()
        const data = (await createConference({
          roomId,
          type: 'single',
          conferenceType: conferenceType,
        })) || { status: 'pending', data: { data: {} } }
        if (data.status === 'rejected') {
          return
        }
        const { title, url } = data.data || {}
        if (isElectron && windowApi) {
          const notifyType =
            conferenceType === CONFERENCE_TYPE_VIDEO ? 'videoConference' : 'audioConference'
          windowApi.send('callSelfBtn', {
            type: notifyType,
            title: 'selfCall',
            msg: title,
            url: url,
          })
        } else {
          window.open(url, '', CONFERENCE_BLANK_DIMENSIONS)
        }
      } finally {
        closeConferenceModal()
        Loading.ref.hide()
      }
    },
    [roomId, isElectron, windowApi],
  )

  const onReactionMessage = (messageId: string, emoji: string, isAdd: boolean) => {
    updateMessageReaction({ messageId, emoji, add: isAdd })
  }

  const onAppearChannel = useCallback(async () => {
    try {
      const result = await updateRoomChannelApparance({
        roomId,
        isAppear: true,
      })
      dispatch(roomsActions.updateApperance(result.data))
    } catch (e) {
      console.log(e)
    }
  }, [roomId])

  const onCloseMessageJumper = () => {
    setCounter(0)
  }

  const onIncreaseMessageJumperCount = () => {
    setCounter((counter) => counter + 1)
  }

  const preparedMessages = useMemo(() => {
    if (room?.type === RoomTypes.OTR) {
      const preparedMessages = getPreparedMessages(
        [...(otrData[roomId].messages || [])],
        room?.unreadCount,
      )
      //TODO:Burada yapılacak işlem
      const combinedArray:any = [];

      preparedMessages.forEach((message:any) => {
        combinedArray.push({...message, userData:users});
      });
      return combinedArray
    }
  }, [roomId, room?.type, room?.unreadCount, otrData[roomId]])

  const scrollHandler = () => {
    if (infiniteScrollContainerRef.current.scrollTop <= SCROLL_THRESHOLD) {
      setScrollAboveThreshold(true)
    } else if (infiniteScrollContainerRef.current.scrollTop > SCROLL_THRESHOLD) {
      setCounter(0)
      setScrollAboveThreshold(false)
    }
  }

  const onResetThreadId = () => {
    setConversationSidePanel((prevState) => ({
      ...prevState,
      params: { ...prevState.params, threadId: undefined },
    }))
  }
 
  return (
    <Content>
      {isShowSpinner && <Spinner position="absolute" loading={true} />}
      {OtrRequestModal}
      {visibleRoomInfo && (
        <ConversationInfo
          type={room?.type}
          roomName={room?.roomName}
          isMyRoom={isMyRoom}
          roomId={roomId}
          onClose={toggleRoomInfo}
        />
      )}
      <InfoModal
        visible={visibleOtrConfirmModal}
        closeModal={closeConfirmOtrModal}
        title={
          myId === room?.createdByUserId ? t(MODALS.CLOSE_OTR_TITLE) : t(MODALS.LEAVE_OTR_TITLE)
        }
        description={
          myId === room?.createdByUserId
            ? t(MODALS.CLOSE_OTR_DESCRIPTION)
            : t(MODALS.LEAVE_OTR_DESCRIPTION)
        }
        okTitle={t(BUTTONS.LEAVE) as string}
        cancelTitle={t(BUTTONS.CANCEL) as string}
        onOk={onOkConfirmOtrModal}
        onCancel={closeConfirmOtrModal}
      />
      <ConversationHeader
        roomId={roomId}
        url={room?.url}
        isMe={room?.roomName === fullname && myId === room?.createdByUserId}
        type={room?.type}
        isMyRoom={isMyRoom}
        roomName={room?.roomName}
        isDeleted={room?.isDeleted}
        description={room?.description}
        pins={room?.pins}
        searchText={searchText}
        visibleSearchInput={isSearchInput}
        onVideoCall={onVideoCall}
        onOpenRoomInfo={toggleRoomInfo}
        onChangeSearch={onChangeSearch}
        onStartOtr={onOtr}
        onToggleSearchInput={setSearchInput}
        onToggleConversationPanel={onToggleConversationPanel}
        onToggleConversationSidePanel={onToggleConversationSidePanel}
      />
      {counter > 0 && (
        <Jumper counter={counter} onScrollBottom={onScrollBottom} onClose={onCloseMessageJumper} />
      )}
      <ContentBody>
        <ContentSection
          isTrue={conversationSidePanel.visible}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
        >
          <Dropzone isDragging={isDragging} getRootProps={getRootProps} />
          <CustomConversationScroll
            isOtr={room?.isVanishMode}
            jumpToIndex={jumpToIndex}
            myId={myId}
            roomId={roomId}
            scrollRef={infiniteScrollContainerRef}
            dataLength={
              room?.type === RoomTypes.OTR
                ? preparedMessages.length
                : messagesData?.messages?.length || 0
            }
            data={room?.type === RoomTypes.OTR ? preparedMessages : messagesData?.messages || []}
            draftData={draftData}
            hasMore={messagesData?.hasMore}
            onPagination={onPagination}
            onScrollHandler={scrollHandler}
            onDeleteMessage={onDeleteMessage}
            onResetJumpToIndex={setJumpToIndex}
            onReactionMessage={onReactionMessage}
            onTogglePinMessage={onTogglePinMessage}
            onReplyMessage={onToggleConversationSidePanel}
          />
          <MessageInput
            roomId={roomId}
            users={users}
            members={room?.members}
            isDeactive={room?.isDeleted}
            isOtr={room?.isVanishMode}
            isAppear={room?.isAppear}
            disabledOtr={room?.isDeleted}
            placeHolder={t(PLACEHOLDERS.MESSAGE_PLACEHOLDER)}
            onAppearChannel={onAppearChannel}
            getInputProps={getInputProps}
            onUploadAudio={onUploadAudio}
            onScrollBottom={onScrollBottom}
            onUpdatePastedFileTargetId={onUpdatePastedFileTargetId}
          />
        </ContentSection>

        {conversationSidePanel.visible && (
          <ConversationSidePanel
            title={
              conversationSidePanel.type === SIDE_PANEL_PIN
                ? t(TITLES.PIN_TITLE)
                : conversationSidePanel.type === SIDE_PANEL_THREAD
                ? t(TITLES.THREAD_TITLE)
                : t(TITLES.SEARCH_TITLE)
            }
            onClose={onToggleConversationSidePanel}
            onToggleSearchInput={setSearchInput}
            onChangeSearch={onChangeSearch}
            content={
              <>
                {conversationSidePanel.type === SIDE_PANEL_PIN && (
                  <PinMessages users={users} myId={myId} onJumpMessage={onJumpMessage} />
                )}
                {conversationSidePanel.type === SIDE_PANEL_THREAD && (
                  <ThreadMessages
                    {...conversationSidePanel.params}
                    users={users}
                    myId={myId}
                    roomId={roomId}
                    onUpdatePastedFileTargetId={onUpdatePastedFileTargetId}
                    onResetThreadId={onResetThreadId}
                  />
                )}
                {conversationSidePanel.type === SIDE_PANEL_SEARCH && (
                  <SearchMessages
                    roomId={roomId}
                    value={searchText}
                    users={users}
                    myId={myId}
                    onJumpMessage={onJumpMessage}
                  />
                )}
              </>
            }
          />
        )}
      </ContentBody>
      {Modal}
      {ConferenceModal}
      {StartOtrConfirmModal}
    </Content>
  )
}

export default Messages

const Content = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
`
const ContentBody = styled.div`
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  position: relative;
`

const ContentSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 812px) {
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    display: ${(props: { isTrue: boolean }) => (props.isTrue ? `none;` : `flex`)};
    position: ${(props: { isTrue: boolean }) => (props.isTrue ? `absolute` : `static`)};
  }
`
