import { useRef } from 'react'
import styled from 'styled-components'
import EmojiPicker, { IEmojiData } from 'emoji-picker-react'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { MOBILE_DEVICE_WIDTH, MOBILE_WIDTH, SIDE_PANEL_THREAD } from 'utils/constants'

import CustomIcon from 'components/icon/CustomIcon'

interface IOptionCardProps {
  isMyMessage: boolean
  messageId: string
  isPinned: boolean
  threadId?: string
  isSearch?: boolean
  visibleEmojiPicker: boolean
  jumpId: string
  subsequent?: boolean
  onTogglePinMessage?: (messageId: string, pinned: boolean) => void
  onDeleteMessage?: (messageId: string) => void
  onReactionMessage?: (messageId: string, emoji: string, isAdd: boolean) => void
  onReplyMessage?: (visible: boolean, type: string, params?: { messageId: string }) => void
  onJumpMessage?: (messageId: string, threadId?: string) => void
  onToggleVisiblePicker: () => void
  onEditMessage?: () => void
}

const Option = ({
  isMyMessage,
  messageId,
  isPinned,
  threadId,
  isSearch,
  visibleEmojiPicker,
  jumpId,
  subsequent,
  onTogglePinMessage,
  onDeleteMessage,
  onReactionMessage,
  onReplyMessage,
  onJumpMessage,
  onToggleVisiblePicker,
  onEditMessage,
}: IOptionCardProps) => {
  const optionRef = useRef<HTMLDivElement>(null)

  return (
    <Wrapper ref={optionRef} id="option" subsequent={subsequent}>
      {visibleEmojiPicker && (
        <EmojiPickerWrapper
          isMobil={window.innerWidth < MOBILE_DEVICE_WIDTH}
          positionY={optionRef?.current?.getBoundingClientRect().y}
        >
          <EmojiPicker
            native
            onEmojiClick={(_, { emoji }: IEmojiData) => {
              onReactionMessage?.(messageId, emoji, true)
              onToggleVisiblePicker()
            }}
          />
        </EmojiPickerWrapper>
      )}

      {onReactionMessage && <OptionIcon source={icons.emojiIcon} onClick={onToggleVisiblePicker} />}
      {isMyMessage && onEditMessage && !isPinned && !isSearch && (
        <OptionIcon source={icons.editIcon} onClick={onEditMessage} />
      )}
      {onDeleteMessage && isMyMessage && (
        <OptionIcon source={icons.deleteIcon} onClick={() => onDeleteMessage(messageId)} />
      )}
      {onTogglePinMessage && (
        <OptionIcon
          source={!isPinned ? icons.pinIcon : icons.unpinnedIcon}
          onClick={() => onTogglePinMessage?.(messageId, !isPinned)}
        />
      )}
      {onReplyMessage && (
        <OptionIcon
          source={icons.replyIcon}
          onClick={() => onReplyMessage(true, SIDE_PANEL_THREAD, { messageId })}
        />
      )}
      {onJumpMessage && (isSearch || isPinned) && (
        <OptionIcon
          source={icons.jumpIcon}
          onClick={() => {
            onJumpMessage(jumpId, threadId)
          }}
        />
      )}
    </Wrapper>
  )
}

export default Option

export const Wrapper = styled.div.attrs((props: { subsequent: boolean }) => props)`
  display: none;
  background-color: ${colors.white};
  position: absolute;
  top: ${({ subsequent }) => (subsequent ? '-20px' : '0px')};
  right: 30px;
  align-items: center;
  box-sizing: border-box;
  padding: 4px;
  gap: 4px;
  border: 1px solid ${colors.light80};
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 2;
`

const OptionIcon = styled(CustomIcon)`
  &:hover {
    background-color: ${colors.light50};
    border-radius: 4px;
    flex-grow: 0;
    width: 24px;
    height: 24px;
    display: flex;
  }
`

const EmojiPickerWrapper = styled.div.attrs(
  (props: { positionY: number; isMobil?: boolean }) => props,
)`
  z-index: 2;

  position: absolute;
  right: 0;
  bottom: ${({ positionY }) => (positionY < 300 ? -325 : 35)}px;
  .emoji-picker-react {
    width: ${({ isMobil }) => (isMobil ? '100%' : 'none')};
    height: ${({ isMobil }) => (isMobil ? '456px' : 'none')};
    margin: ${({ isMobil }) => (isMobil ? '0px 0px 10px 0px' : 'initial')};
    padding: ${({ isMobil }) => (isMobil ? '0px 0px 10px 0px' : 'initial')};
  }
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    bottom: -22px;
    position: fixed;
    left: 0;
    width: 100%;
    opacity: 1;
    box-shadow: rgba(12, 33, 58, 0.29) 0px -10px 10px;
    border-radius: 4px;
  }
  @media (max-height: ${500}px) {
    position: fixed;
    width: fit-content;
    right: 150px;
    top: 0;
    box-shadow: rgba(12, 33, 58, 0.29) 0px -10px 10px;
    border-radius: 4px;
    height: fit-content;
  }
`
