import api from 'app/api'
import { FixMeLater } from 'types'

export const roomService: FixMeLater = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getRooms: build.query({
        query: () => ({ url: 'subscription/list' }),
      }),
      joinPublicChannel: build.mutation({
        query: ({ channelId: roomId }) => ({
          url: `room/${roomId}/join`,
          method: 'POST',
        }),
      }),
      readMessages: build.query({
        query: ({ roomId }) => ({
          url: `/subscription/${roomId}/view`,
        }),
      }),
      createConversation: build.mutation({
        query: (args) => ({ url: 'room', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_CONVERSATION'],
      }),
      createOtrConversation: build.mutation({
        query: (args) => ({ url: 'room/otr', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_CONVERSATION'],
      }),
      searchConversation: build.mutation({
        query: (args) => ({
          url: `room/list/search/${args}`,
          method: 'GET',
        }),
        invalidatesTags: ['SEARCH_CONVERSATION'],
      }),
      sendPinnedConversations: build.mutation({
        query: ({ roomId, messageId, pinned }) => ({
          url: `/room/${roomId}/message/pin`,
          method: 'PUT',
          body: { messageId, pinned },
        }),
        invalidatesTags: ['SEND_PINNED_MESSAGE'],
      }),
      getPinnedMessagesByRoomId: build.query({
        query: ({ roomId }) => ({
          url: `/room/${roomId}/list/messages/pinned`,
        }),
      }),
      getRoomInfo: build.mutation({
        query: ({ roomId }) => ({
          url: `/room/${roomId}/info`,
        }),
      }),
      getRoomInfoForAdmin: build.query({
        query: ({ roomId }) => ({
          url: `/room/${roomId}/stats`,
        }),
      }),
      updateNotification: build.mutation({
        query: ({ roomId, isMuted, notifyOnlyMentions }) => ({
          url: `/subscription/${roomId}/notification`,
          method: 'PUT',
          body: { isMuted, notifyOnlyMentions },
        }),
        invalidatesTags: ['UPDATE_NOTIFICATION'],
      }),
      updateRoomChannelApparance: build.mutation({
        query: ({ roomId, isAppear }) => ({
          url: `/subscription/${roomId}/toggle/appearance`,
          method: 'PUT',
          body: { isAppear },
        }),
        invalidatesTags: ['UPDATE_APPARANCE'],
      }),
      updateRoomArchive: build.mutation({
        query: ({ roomId, isArchived }) => ({
          url: `/room/${roomId}/archive`,
          method: 'PUT',
          body: { isArchived },
        }),
        invalidatesTags: ['UPDATE_ARCHIVE'],
      }),
      updateChannel: build.mutation({
        query: ({ channelId: roomId, params }) => ({
          url: `room/${roomId}`,
          method: 'PUT',
          body: params,
        }),
        invalidatesTags: ['UPDATE_CHANNEL'],
      }),
      leaveChannel: build.mutation({
        query: ({ roomId }) => ({
          url: `/room/${roomId}/leave`,
          method: 'PUT',
        }),
        invalidatesTags: ['LEAVE_CHANNEL'],
      }),
      view: build.mutation({
        query: ({ roomId }) => ({
          url: `/subscription/${roomId}/view`,
          method: 'GET',
        }),
        invalidatesTags: ['VIEW_MESSAGES'],
      }),
      unview: build.query({
        query: () => ({
          url: `/subscription/unview`,
        }),
      }),
      getThreadNotifications: build.query({
        query: ({ roomId, page }) => ({
          url: `notification/${roomId}/list/thread/page/${page}`,
          method: 'GET',
        }),
      }),
      getUnreadThreadCount: build.query({
        query: ({ roomId }) => ({
          url: `notification/${roomId}/thread/unread/count`,
          method: 'GET',
        }),
      }),
      getUsersForMention: build.query({
        query: ({ roomId }) => ({
          url: `room/${roomId}/list/members`,
        }),
      }),
    }
  },
})

export const {
  useLazyUnviewQuery,
  useJoinPublicChannelMutation,
  useLazyGetRoomsQuery,
  useCreateConversationMutation,
  useCreateOtrConversationMutation,
  useSearchConversationMutation,
  useSendPinnedConversationsMutation,
  useGetPinnedMessagesByRoomIdQuery,
  useUpdateNotificationMutation,
  useLazyReadMessagesQuery,
  useGetRoomInfoMutation,
  useLazyGetRoomInfoForAdminQuery,
  useUpdateRoomChannelApparanceMutation,
  useUpdateRoomArchiveMutation,
  useLeaveChannelMutation,
  useViewMutation,
  useLazyGetUsersForMentionQuery,
  useLazyGetThreadNotificationsQuery,
  useLazyGetUnreadThreadCountQuery,
  useUpdateChannelMutation,
} = roomService
