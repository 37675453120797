import moment from 'moment'
import styled from 'styled-components'
import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { useTranslation } from 'react-i18next'
import { FORM, MESSAGES, PLACEHOLDERS } from 'locales/locales'
import { requiredValidation } from 'utils/helpers/validations'

interface IUserInfoProps {
  formChecked: boolean
  name: string
  description: string
  createdBy: string
  createdAt: string
  onChange: (value: string | boolean, key: string) => void
}

const ChannelInfo = ({
  formChecked,
  name,
  description,
  createdBy,
  createdAt,
  onChange,
}: IUserInfoProps) => {
  const { t } = useTranslation()

  const validName = formChecked && requiredValidation(name, t(FORM.CHANNEL_NAME)).errorText
  return (
    <InfoModalWrapper>
      <FormWrapper>
        <CustomFormInput
          value={name}
          title={t(FORM.CHANNEL_NAME)}
          onChange={(value: string) => onChange(value, 'name')}
          disabled={false}
          placeholder={t(PLACEHOLDERS.CHANNEL_NAME)}
          errorText={validName}
        />
        <CustomFormInput
          value={description}
          title={t(FORM.CHANNEL_DESCRIPTION)}
          placeholder={t(PLACEHOLDERS.CHANNEL_DESCRIPTION)}
          disabled={false}
          optionalTitle={t(FORM.OPTIONAL)}
          onChange={(value: string) => onChange(value, 'description')}
        />
        <TextWrapper>
          <CustomText typography="header3">{t(MESSAGES.CREATED_BY)}</CustomText>
          <CustomText typography="body3" color={colors.gray80}>
            {`${createdBy} on ${moment(createdAt).format('DD/MM/YYYY HH:mm')}`}
          </CustomText>
        </TextWrapper>
      </FormWrapper>
    </InfoModalWrapper>
  )
}

export default ChannelInfo

const InfoModalWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 10;
`
