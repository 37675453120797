import styled from 'styled-components'
import { ReactNode, useEffect } from 'react'

import { MOBILE_WIDTH } from 'utils/constants'
import { useOutletState } from 'utils/hooks/useOutletState'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { icons } from 'utils/icons'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

const SettingsHeader = ({
  title,
  content,
  className,
  hasSideBar = true,
}: {
  title: string
  content?: ReactNode
  className?: string
  hasSideBar?: boolean
}) => {
  const { onToggleConversationPanel, visible } = useOutletState()

  const { screenWidth } = useWindowDimensions()

  useEffect(() => {
    if (screenWidth > MOBILE_WIDTH && visible) {
      onToggleConversationPanel()
    }
  }, [screenWidth])
  return (
    <Header className={className}>
      {hasSideBar && (
        <SideBarToggleWrapper onClick={onToggleConversationPanel}>
          <CustomIcon source={icons.sidebarIcon} />
        </SideBarToggleWrapper>
      )}
      <CustomText typography="header1">{title}</CustomText>
      {content}
    </Header>
  )
}

export default SettingsHeader

const SideBarToggleWrapper = styled.div`
  cursor: pointer;
  flex-shrink: 0;
  @media only screen and (min-width: ${MOBILE_WIDTH}px) {
    display: none;
  }
`
const Header = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 10px;
  justify-content: flex-start;
`
