import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { icons } from 'utils/icons'
import { EMPTY, PLACEHOLDERS } from 'locales/locales'
import { ICustomFile, IFiles } from 'types/file.types'

import CustomFile from 'components/file/CustomFile'
import SearchInput from 'components/input/search-input/SearchInput'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

interface IFilesProps {
  data?: IFiles
  isArchived?: boolean
}

const Files = ({ data = [] }: IFilesProps) => {
  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')
  const [filteredFiles, setFilterFiles] = useState([...data])

  const onChangeSearchText = (text: string) => {
    setSearchText(text)
  }

  useEffect(() => {
    if (searchText) {
      const newData =
        data.filter((file) =>
          file?.originalName?.toLowerCase()?.includes(searchText?.toLowerCase()),
        ) || []
      setFilterFiles([...newData])
    } else {
      setFilterFiles([...data])
    }
  }, [searchText])

  return (
    <Wrapper>
      <SearchInput
        value={searchText}
        placeHolder={t(PLACEHOLDERS.SEARCH_FILES_PLACEHOLDER) as string}
        onChange={onChangeSearchText}
      />
      {filteredFiles?.length !== 0 && (
        <Content>
          {filteredFiles?.map((file: ICustomFile, index: number) => (
            <CustomFileWrapper key={file._id}>
              <CustomFile
                files={data}
                index={index}
                mimetype={file.mimetype}
                preset="files"
                source={file.url}
                sentAt={file.sentAt}
                fullname={file.sender}
                filename={file.originalName}
                thumbnail={file?.thumbnail}
              />
            </CustomFileWrapper>
          ))}
        </Content>
      )}
      {data?.length === 0 && (
        <EmptyFileWrapper>
          <EmptyFile>
            <CustomIcon source={icons.emptyFile} />
          </EmptyFile>
          <StyledText typography="body4">{t(EMPTY.EMPTY_NO_FILES)}</StyledText>
        </EmptyFileWrapper>
      )}
      {data?.length !== 0 && filteredFiles?.length === 0 && (
        <EmptyFileWrapper>
          <EmptyFile>
            <CustomIcon source={icons.emptyFile} />
          </EmptyFile>
          <StyledText typography="body4">{t(EMPTY.EMPTY_NO_RESULTS)}</StyledText>
        </EmptyFileWrapper>
      )}
    </Wrapper>
  )
}

export default Files

const EmptyFileWrapper = styled.div`
  padding-top: 20px;
`

const EmptyFile = styled.div`
  text-align: center;
  padding: 10px 0px;
`
const StyledText = styled(CustomText)`
  text-align: center;
  padding: 10px 0px;
`

const Wrapper = styled.div`
  max-height: 370px;
  overflow-y: auto;
`

const Content = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  display: flex;
  gap: 10px;
  flex-direction: column;
`

const CustomFileWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`
