import styled from 'styled-components'
import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import { useTranslation } from 'react-i18next'
import { BUTTONS, MESSAGES } from 'locales/locales'

interface IAppearProps {
  isChannel?: boolean
  onAppearChannel?: () => void
}

const Appear = ({ onAppearChannel, isChannel }: IAppearProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <CustomText typography="body3" textAlign="center">
        {t(
          isChannel
            ? MESSAGES.DISAPPEARED_CHANNEL_DESCRIPTION
            : MESSAGES.DISAPPEARED_CONVERSATION_DESCRIPTION,
        )}
      </CustomText>
      <CustomButton
        text={t(isChannel ? BUTTONS.APPEAR_CHANNEL : BUTTONS.APPEAR_CONVERSATION)}
        textColor={colors.white}
        size="medium"
        onClick={onAppearChannel}
      />
    </Wrapper>
  )
}

export default Appear

const Wrapper = styled.div`
  display: flex;
  height: 120px;
  border-top: 1px solid ${colors.light80};
  align-items: center;
  justify-content: center;
  column-gap: 15px;
`
