import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CustomModal from 'components/modals/CustomModal';
import CustomText from 'components/text/CustomText';
import { useLazyGetUserGroupsAndChannelsWithMembersInfoQuery } from 'app/services/group.service';


import { UserStatus } from 'types/user.types';
import { GroupPanel } from 'components/group/GroupPanel';


interface Group {
  name: string;
  imageUrl?: string;
  backgroundColor?: string;
  members?: Member[];
}

interface Member {
  userId: string;
  fullname: string;
  status?: UserStatus; 
  imageUrl?: string;
}

interface IUserGroupsModalProps {
  onClose: () => void;
  isVisible: boolean;
  userId: string;
  userName: string;
}

const UserGroupsModal = ({ onClose, isVisible, userId, userName }: IUserGroupsModalProps) => {
  const { t } = useTranslation();
  const [groups, setGroups] = useState<Group[]>([]); 
  const [trigger, { data, isLoading, error }] = useLazyGetUserGroupsAndChannelsWithMembersInfoQuery();

  useEffect(() => {
    if (isVisible && userId) {
      trigger(userId);
    }
  }, [isVisible, userId, trigger]);

  useEffect(() => {
    if (data && data.success) {
      setGroups(data.data);
    }
  }, [data]);

  return (
    <CustomModal
      header={<CustomText typography="header2">{t('USERGROUPSANDCHANNELS', { name: userName })}</CustomText>}
      closeModal={onClose}
      showButtons={false}
      loading={isLoading}
    >
      {error ? (
        <div>Error: {error.message}</div>
      ) : (
        <GroupPanel groups={groups} userId={userId} />
      )}
    </CustomModal>
  );
};

export default UserGroupsModal;
