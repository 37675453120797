import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { IRoom } from 'types/rooms.types'
import { IGroup } from 'types/group.types'
import { NAVIGATION, INFORMATION } from 'locales/locales'
import { useAppSelector } from 'app/hooks'
import { useLazyUnviewQuery, useViewMutation } from 'app/services/room.service'
import {
  getNotificationBadgeForMessages,
  getNotificationBadgeForTeams,
} from 'utils/helpers/notification'

import Options from './options/Options'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import AccountSettings from 'features/account-settings/AccountSettings'

import useWindowFocus from 'utils/hooks/useWindowFocus'
import { clearDB } from 'utils/helpers/storageHelper'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import {
  IRolePermissions,
  MessagingPermissions,
  NewsFeedPermissions,
  TeamAndChannelPermissions,
  VideoConferencePermissions,
} from 'types/role.types'

interface UStyledProps {
  marginBottom?: number
  isActive?: boolean
  hasNotification?: boolean
}

const regex = /[0-9A-Fa-f]{6}/g

const NavigationBar = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [updateView] = useViewMutation()
  const [unViewMessages] = useLazyUnviewQuery()
  const isFocus = useWindowFocus()

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('news-feed')
    }
  }, [])

  useEffect(() => {
    if (!sessionStorage.getItem('isPageRefresh')) clearDB('vimesoft')

    const handleTabClosing = () => {
      if (!sessionStorage.getItem('isPageRefresh')) clearDB('vimesoft')
    }

    window.addEventListener('beforeunload', handleTabClosing)

    return () => {
      window.removeEventListener('beforeunload', handleTabClosing)
    }
  }, [location.pathname])

  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)

  const hasNewsFeedViewPermission = isFlagSetIn(
    [NewsFeedPermissions.VIEW_NEWSFEED],
    permissions.newsFeed,
  )
  const hasVideoCallPermission = isFlagSetIn(
    [VideoConferencePermissions.VIEW_ALL_MEETING_LIST],
    permissions.videoConference,
  )
  const hasMessageViewPermission = isFlagSetIn(
    [MessagingPermissions.START_MESSAGING],
    permissions.messaging,
  )
  const hasTeamsViewPermission = isFlagSetIn(
    [TeamAndChannelPermissions.VIEW_TEAMS],
    permissions.teamAndChannel,
  )

  const authUser = useAppSelector((state) => state.auth.user)
  const rooms = useAppSelector((state) => state.rooms.data)
  const groups = useAppSelector((state) => state.groups.data)
  const users = useAppSelector((state) => state.users.data)

  const { unreadNotificationCount, realNotificationCount } = useAppSelector(
    (state) => state.newsFeed,
  )

  const systemSettings = useAppSelector((state) => state.system.data)

  const { roomId, channelId } = useParams()

  const [visible, setVisible] = useState(false)
  const [visibleAccountSettings, setVisibleAccountSettings] = useState(false)

  const notificationBadgeForMessages = getNotificationBadgeForMessages(rooms)
  const notificationBadgeForTeams = getNotificationBadgeForTeams(groups)

  useEffect(() => {
    const w = window as any
    if (w.api) {
      if (notificationBadgeForMessages)
        w.api.send('sendNotify', {
          type: 'messages',
          title: t(INFORMATION.NEW_NOTIFICATION),
          msg: t(INFORMATION.MESSAGES_RECEIVED),
        })

      if (notificationBadgeForTeams)
        w.api.send('sendNotify', {
          type: 'teams',
          title: t(INFORMATION.NEW_NOTIFICATION),
          msg: t(INFORMATION.CHANNEL_MESSAGES_RECEIVED),
        })

      if (unreadNotificationCount > 0 || realNotificationCount > 0)
        w.api.send('sendNotify', {
          type: 'newsfeed',
          title: t(INFORMATION.NEW_NOTIFICATION),
          msg: t(INFORMATION.NEWSFEED_RECEIVED),
        })
    }
  }, [
    unreadNotificationCount,
    realNotificationCount,
    notificationBadgeForTeams,
    notificationBadgeForMessages,
  ])

  useEffect(() => {
    const w = window as any
    if (w.api) w.api.send('sendStatus', { status: authUser.status })
  }, [authUser.status])

  const relavantPages = !!(roomId?.match(regex) || channelId?.match(regex))

  useEffect(() => {
    if (relavantPages && isFocus) {
      updateView({ roomId: roomId || channelId })
    }
  }, [roomId, channelId, isFocus, relavantPages])

  useEffect(() => {
    if ((relavantPages && !isFocus) || (!relavantPages && isFocus)) {
      unViewMessages()
    }
  }, [isFocus, relavantPages])

  const firstAvailableRoom = rooms.find((room: IRoom) => room?.isAppear === true)
  const AvailableChannels = groups.map((group: IGroup) =>
    group?.channels?.find((channel) => channel.isAppear === true),
  )
  const firstAvailableChannel = AvailableChannels?.[0]?._id

  const navigateByRouteName = (routeName: string) => {
    if (routeName.includes('messages') && !roomId) {
      navigate(routeName)
    } else if (routeName.includes('channels') && !channelId) {
      navigate(routeName)
    } else if (!routeName.includes('messages') && !routeName.includes('channels')) {
      navigate(routeName)
    }
  }

  const onToggleVisible = () => {
    setVisible(!visible)
  }

  const onCloseAccountSettings = () => {
    setVisibleAccountSettings(false)
  }

  const onOpenAccountSettings = () => {
    setVisibleAccountSettings(true)
    onToggleVisible()
  }

  return (
    <Wrapper>
      {visible && (
        <Options onOpenAccountSettings={onOpenAccountSettings} onHide={onToggleVisible} />
      )}
      {visibleAccountSettings && (
        <AccountSettings myUserInfo={authUser || {}} onClose={onCloseAccountSettings} />
      )}

      <Avatar
        onClick={onToggleVisible}
        type="circle"
        size={AvatarSize.large}
        source={authUser.avatar}
        text={authUser.fullname}
        status={users[authUser._id]?.status}
        color={colors.orange}
      />

      <ScrollableDiv>
        <Dock>
          {hasNewsFeedViewPermission && (
            <Section
              isActive={location.pathname === '/news-feed'}
              onClick={() => {
                navigateByRouteName('/news-feed')
              }}
            >
              <CustomIcon source={icons.navbarNewsfeedIcon} />
              <StyledCustomText typography="header5" color={colors.white}>
                {t(NAVIGATION.NEWS_FEED)}
              </StyledCustomText>
              {unreadNotificationCount > 0 || realNotificationCount > 0 ? (
                <RedDot hasNotification={unreadNotificationCount > 0} />
              ) : (
                ''
              )}
            </Section>
          )}
          {hasTeamsViewPermission && (
            <Section
              isActive={location.pathname.includes('channels')}
              onClick={() =>
                navigateByRouteName(
                  '/channels' +
                    (firstAvailableChannel !== undefined ? '/' + firstAvailableChannel : ''),
                )
              }
            >
              <CustomIcon source={icons.navbarTeamsIcon} />
              <StyledCustomText typography="header5" color={colors.white}>
                {t(NAVIGATION.TEAMS)}
              </StyledCustomText>
              {notificationBadgeForTeams && <RedDot hasNotification={notificationBadgeForTeams} />}
            </Section>
          )}

          {hasMessageViewPermission && (
            <Section
              isActive={location.pathname.includes('messages')}
              onClick={() =>
                navigateByRouteName(
                  '/messages' +
                    (firstAvailableRoom !== undefined ? '/' + firstAvailableRoom._id : ''),
                )
              }
            >
              <CustomIcon source={icons.navbarMessagesIcon} />
              <StyledCustomText typography="header5" color={colors.white}>
                {t(NAVIGATION.MESSAGES)}
              </StyledCustomText>
              {notificationBadgeForMessages && (
                <RedDot hasNotification={notificationBadgeForMessages} />
              )}
            </Section>
          )}
          {hasVideoCallPermission && (
            <Section
              isActive={location.pathname.includes('video-call')}
              onClick={() => navigateByRouteName('/video-call')}
            >
              <CustomIcon source={icons.navbarVideoIcon} />

              <StyledCustomText typography="header5" color={colors.white}>
                {t(NAVIGATION.VIDEO_CALL)}
              </StyledCustomText>
            </Section>
          )}
          {/* <Section
            isActive={location.pathname.includes('live-stream')}
            onClick={() => navigateByRouteName('/live-stream')}
          >
            <CustomIcon source={icons.liveStreamIcon} iconPreset="xLarge" />
            <StyledCustomText typography="header5" color={colors.white}>
              {t(NAVIGATION.LIVE_STREAM)}
            </StyledCustomText>
          </Section> */}
        </Dock>
      </ScrollableDiv>
      <Logo src={systemSettings?.imageUrlLogo} />
    </Wrapper>
  )
}

export default NavigationBar

export const Wrapper = styled.div`
  width: 80px;
  height: 100%;
  padding: 10px 0px;
  background-color: ${colors.vimeDark};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  overflow: hidden;
  flex-shrink: 0;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
`

export const ScrollableDiv = styled.div`
  overflow-y: hidden;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  li img {
    transition: all 0.3s;
    transform-origin: 50% 100%;
  }
  li:hover img {
    transform: scale(1.05);
    margin: 0 2em;
  }

  li:hover div {
    transform: scale(1.05);
  }
  li {
    overflow: hidden;
    margin:0 auto;
    padding: 10px 0;
  }
  margin-top: 40px
`
export const Section = styled.li.attrs((props: UStyledProps) => props)`
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || 20}px;
  row-gap: 4px;
  opacity: ${({ isActive = false }) => (isActive ? '1' : '.8')};

  div {
    transition: transform 0.3s;
    text-align: center;
    color: ${colors.white};
  }
  img {
    transform: ${({ isActive = false }) => (isActive ? 'scale(1.0)' : 'scale(0.8)')};
  }
`

export const Logo = styled.img`
  margin-top: 10px;
  padding: 5px;
  width: 80px;
  max-height: 50px;
`

export const TriangleIcon = styled(CustomIcon)`
  position: absolute;
  left: 71px;
  top: 6px;
`
export const RedDot = styled.span`
  animation: ${({ hasNotification }: { hasNotification?: boolean }) =>
    hasNotification ? 'blinker 1.5s linear infinite;' : 'none'};
  position: absolute;
  width: 10px;
  height: 10px;
  z-index: 2;
  background-color: ${colors.red};
  border-radius: 50%;
  right: 8px;
`

export const Dock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  overflow-y: hidden;
`

export const StyledCustomText = styled(CustomText)`
  width: 100%;
  text-align: -webkit-center !important;
  padding: 2px 4px;
  border-radius: 4px;
`
