import styled from 'styled-components'
import { useEffect, useRef, useState } from 'react'

import { icons } from 'utils/icons'
import { IUser } from 'types/user.types'
import colors from 'utils/colors'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Separator from 'components/separator/Separator'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import { useAppSelector } from 'app/hooks'



interface IUserCardProps extends IUser {
  isCentered?: boolean
  positionY?: number | undefined
  showChatIcon?: boolean
  showCallIcon?: boolean
  forceLeft?: boolean
  showCameraIcon?: boolean
  showUserGroupMembers? : boolean
  onClickChatIcon?: () => void
  onClickCameraIcon?: () => void
  onClickUserGroups?: () => void
  onKeyUp?: (event: KeyboardEvent) => void
}

const UserCard = ({
  avatar,
  fullname,
  title,
  team,
  phoneNumber,
  positionY,
  forceLeft,
  isCentered,
  showChatIcon = true,
  showCameraIcon = true,  
  showUserGroupMembers = true,  
  showCallIcon = true,
  onClickChatIcon,
  onClickCameraIcon,
  onClickUserGroups,
  onKeyUp = () => '',
}: IUserCardProps) => {
  const wrapperClass = forceLeft ? 'left' : showChatIcon ? 'left' : 'right'
  const cardRef = useRef<HTMLDivElement>(null)
  const [isTallerThanWindow, setIsTallerThanWindow] = useState(false)
  const groupMembersEnabled = useAppSelector(state => state.system.data.features?.isGroupMembersEnabled);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp)
    return () => {
      window.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  useEffect(() => {
    const checkHeight = () => {
      const cardRectangle = cardRef.current?.getBoundingClientRect()
      if ((cardRectangle?.bottom ?? 0) > window.innerHeight) {
        setIsTallerThanWindow(true)
      } else {
        setIsTallerThanWindow(false)
      }

    }
    checkHeight()
    window.addEventListener('resize', checkHeight)
    return () => {
      window.removeEventListener('resize', checkHeight)
    }
  }, [])

  return (
    <UserCardWrapper
      ref={cardRef}
      className={wrapperClass}
      positionY={positionY}
      isCentered={isCentered}
      isIconsVisible={showCallIcon || showCameraIcon || showChatIcon}
      isTallerThanWindow={isTallerThanWindow}
    >
      <UserRowWrapper>
        <Avatar text={fullname} source={avatar} size={AvatarSize.extraLarge} />
        <TopWrapper>
          <UserCardText typography="header1" marginBottom={12} marginLeft={10}>
            {fullname}
          </UserCardText>
          <UserCardText typography="body5" marginBottom={2} marginLeft={10}>
            {title}
          </UserCardText>
          <UserCardText typography="body5" marginLeft={10}>
            {team}
          </UserCardText>
        </TopWrapper>
      </UserRowWrapper>
      <Separator margin={{ top: 20, bottom: 20 }} />

      {phoneNumber && (
        <UserRowWrapper gap={12} alignItems="center">
          <CustomIcon width="25px" height="16px" source={icons.callIconSmall} />
          <CustomText typography="body3">{phoneNumber}</CustomText>
        </UserRowWrapper>
      )}

      <UserRowWrapper alignItems="center" justifyContent="space-around">
        {showChatIcon && (
          <CustomIcon
            width="20px"
            height="20px"
            source={icons.chatIconBlue}
            onClick={onClickChatIcon}
          />
        )}
        {showCameraIcon && (
          <CustomIcon
            width="25px"
            height="16px"
            source={icons.cameraIconBlue}
            onClick={onClickCameraIcon}
          />
        )}

    {showUserGroupMembers && groupMembersEnabled && (
      <CustomIcon
        width="22px"
        height="22px"
        source={icons.userActiveIcon}
        onClick={onClickUserGroups}
      />
    )}
  
      </UserRowWrapper>
    </UserCardWrapper>
  )
}

export default UserCard

const TopWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const StyledText = styled(CustomText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const CommonCardWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.light80};
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  position: absolute;
  z-index: 4;
  padding: 10px 0px 10px 0px;
`

const UserCardWrapper = styled(CommonCardWrapper).attrs(
  (props: {
    positionY: number
    isCentered: boolean
    isIconsVisible: boolean
    showChatIcon?: boolean
    isTallerThanWindow: boolean
  }) => props,
)`
  padding: 24px;
  z-index: 3;
  position: absolute;
  top: ${({ positionY, isCentered, isTallerThanWindow }) => (isCentered ? (isTallerThanWindow ? 'auto' : '60px') : `${positionY < 350 ? 40 : -200}px`)};
  bottom: ${({ isTallerThanWindow }) => ((isTallerThanWindow ? '80px' : 'auto'))};
  left: ${({ isCentered }) => (isCentered ? '50%' : 'auto')};

  max-width: 300px;
  overflow: hidden;
  &.right {
    right: ${({ isCentered }) => (isCentered ? 'auto' : '30px')};
  }
  &.left {
    left: ${({ isCentered }) => (isCentered ? 'auto' : '30px')};
  }
`

const UserRowWrapper = styled.div.attrs(
  (props: {
    gap: number
    alignItems: string
    justifyContent: string
    marginBottom: number
    marginTop: number
  }) => props,
)`
  display: flex;
  gap: ${({ gap }) => gap || 0}px;
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`

const UserCardText = styled(CustomText).attrs(
  (props: { marginLeft: number; marginBottom: number; marginRight: number; marginTop: number }) =>
    props,
)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`
