import { useMemo, useRef } from 'react'
import _isEqual from 'lodash/isEqual'

import { FixMeLater } from 'types'
import { useAppSelector } from 'app/hooks'
import { IGroup } from 'types/group.types'

const useChannel = ({ id }: { id: string }) => {
  const groups = useAppSelector((state) => state.groups.data)
  const previousGroupRef = useRef()
  const channelInfo = useMemo(() => {
    let channelInfo: FixMeLater = {}
    if (!_isEqual(previousGroupRef.current, groups)) {
      previousGroupRef.current = { ...groups }
      groups.forEach((group: IGroup) => {
        const channelInfoById = group?.channels?.find((channel) => channel._id === id)
        if (channelInfoById) {
          channelInfo = { ...channelInfoById }
        }
      })
    }
    return { ...channelInfo }
  }, [groups, id])

  return channelInfo
}

export default useChannel
