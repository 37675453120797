/* eslint-disable no-prototype-builtins */
import { createSlice } from '@reduxjs/toolkit'
import { conversationService } from 'app/services/conversation.service'
import { t } from 'i18next'
import { MESSAGES } from 'locales/locales'
import { FixMeLater } from 'types'
import { IConversation, IDraftData, IMessage, MessageStatus } from 'types/conversation.types'
import { IGroup } from 'types/group.types'
import { IUserData } from 'types/user.types'
import {
  concatDaySeparator,
  getSubsequentMessages,
  singleMessageConstructor,
} from 'utils/helpers/messageHelper'

export interface ConversationState {
  data: IConversation
  draftData: IDraftData
  renderedRooms: string[]
}

const initialMessageState: ConversationState = {
  data: {},
  draftData: {},
  renderedRooms: [],
}

export const setMessageData = (message: IMessage): IMessage => {
  const toReturn = {
    ...message,
    userData: message.users
      ? message.users.reduce((acc: IUserData, currentUser) => {
          acc[currentUser._id] = currentUser
          return acc
        }, {})
      : {},
  }

  if (toReturn.threads) {
    toReturn.threads = toReturn.threads.map((thread) => {
      thread.users = [...toReturn.users, ...(thread.users || [])]
      return {
        ...thread,
        userData: thread.users
          ? thread.users.reduce((acc: IUserData, currentUser) => {
              acc[currentUser._id] = currentUser
              return acc
            }, {})
          : {},
      }
    })
  }

  return toReturn
}

export const addUnreadSeparator =
  ({ roomId }: { roomId: string }) =>
  async (dispatch: FixMeLater, getState: FixMeLater) => {
    const store = getState()
    const allRooms = [
      ...store.rooms.data,
      ...store.groups.data.map((group: IGroup) => group.channels).flat(),
    ]
    const roomOfMessage = allRooms.find((r) => r._id === roomId)
    dispatch(
      conversationActions.addUnreadSeparator({
        roomId,
        unreadCountForRoom: roomOfMessage?.unreadCount,
      }),
    )
  }

export const { actions: conversationActions, reducer: conversationReducer } = createSlice({
  name: 'message',
  initialState: initialMessageState,
  reducers: {
    removeDataInDraft(state, { payload }) {
      delete state.draftData?.[payload?.id]
    },
    removeDataByUniqueIdInDraft(state, { payload }) {
      const indexByStartAt = state.draftData?.[payload.id]?.files?.findIndex(
        ({ uniqueId }) => uniqueId === payload?.uniqueId,
      )

      if (indexByStartAt !== -1) {
        state.draftData?.[payload?.id].files?.splice(indexByStartAt, 1)
      }
    },
    removeAllDraftAndFailedMessages(state, { payload }) {
      const allDraftKeys = Object.keys(state.draftData)
      const draftMessagesIds = allDraftKeys.filter((id) => {
        return state.data[payload].messages.some((message) => message._id === id)
      })
      draftMessagesIds.forEach((messageId) => {
        delete state.draftData?.[messageId]
      })
      delete state.draftData?.[payload]
      if (state.data[payload]) {
        state.data[payload].messages = state.data[payload]?.messages?.filter(
          (message) => message.status !== 'pending' && message.status !== 'failed',
        )
      }
    },
    removeAllMessages(state, { payload }) {
      delete state.data?.[payload]
    },
    updateMessageId(state, { payload }) {
      const { roomId, sentAt, _id } = payload
      const messageIndex = state.data[roomId].messages.findIndex(
        (m) => m.sentAt === sentAt && !('_id' in m),
      )
      if (messageIndex === -1) {
        return
      }
      state.data[roomId].messages[messageIndex]._id = _id
    },
    addDataInDraft(state, { payload }) {
      state.draftData[payload.id] = payload
    },
    addFileInDraft(state, { payload }) {
      const files = state.draftData[payload.id]?.files || []
      files.push({ ...payload })
      state.draftData[payload.id] = { ...state.draftData[payload.id], files }
      return
    },
    updateDraftData(state, { payload }) {
      const indexByUniqueId = state.draftData[payload.id].files?.findIndex(
        ({ uniqueId }) => uniqueId === payload.uniqueId,
      )
      if (state.draftData[payload.id]?.files?.[indexByUniqueId]) {
        state.draftData[payload.id].files[indexByUniqueId] = {
          ...payload.fileInfo,
          startAt: payload.startAt,
          uniqueId: payload.uniqueId,
        }
      }
    },
    updateMessageByRoomId(state, { payload }) {
      const pendingMessageIndex = state.data[payload.roomId].messages.findIndex(
        (message) => message.status === MessageStatus.PENDING && payload.cti === message.cti,
      )
      const failedMessageIndex = state.data[payload.roomId].messages.findIndex(
        (message) => message.status === MessageStatus.FAILED && payload.cti === message.cti,
      )

      const structeredMessages = singleMessageConstructor(
        state.data[payload.roomId].messages,
        payload,
        true,
      )

      if (pendingMessageIndex !== -1) {
        if (structeredMessages[0].length === 0) {
          delete structeredMessages[0].users
          delete structeredMessages[0].userData
        }

        state.data[payload.roomId].messages[pendingMessageIndex] = setMessageData({
          ...state.data[payload.roomId].messages[pendingMessageIndex],
          ...structeredMessages[0],
        })

        if (!structeredMessages[0].status) {
          //Delete the status if it's not present anymore (means: it's sent successfully)
          delete state.data[payload.roomId].messages[pendingMessageIndex].status
        }
      }
      if (failedMessageIndex !== -1) {
        state.data[payload.roomId].messages.splice(failedMessageIndex, 1)
        state.data[payload.roomId].messages.unshift(structeredMessages[0])
      }
      const failedMessages = state.data[payload.roomId].messages.filter(
        (message) => message.status === MessageStatus.FAILED,
      )
      state.data[payload.roomId].messages = [
        ...failedMessages,
        ...state.data[payload.roomId].messages.filter(
          (message) => message.status !== MessageStatus.FAILED,
        ),
      ].map(setMessageData)
    },
    sendMessage(state, { payload }) {
      const messages = state.data[payload.roomId]?.messages || []
      const messageIndex = messages.findIndex((message) => message.cti === payload.cti)
      if (messageIndex === -1) {
        const structeredMessages = singleMessageConstructor(
          state.data[payload.roomId].messages,
          payload,
        )
        messages.unshift(...structeredMessages)
        state.data[payload.roomId] = {
          messages: messages.map(setMessageData),
          page: state.data[payload.roomId]?.page || 0,
          hasMore: state.data[payload.roomId]?.hasMore || false,
        }
      }
    },
    removeFailedMessage(state, { payload }) {
      const index = state.data[payload.roomId].messages.findIndex(
        (message) => message.cti === payload.cti,
      )
      if (index === -1) {
        return
      }
      state.data[payload.roomId].messages.splice(index, 1)
    },
    updateRenderedRooms(state, { payload }) {
      if (!state.renderedRooms.includes(payload)) {
        state.renderedRooms.push(payload)
      }
    },
    updatePinnedMessage(state, { payload }) {
      const index = state.data[payload.roomId].messages.findIndex(
        (message) => message._id === payload._id,
      )
      if (index !== -1) {
        const message = state.data[payload.roomId].messages[index]
        state.data[payload.roomId].messages[index] = { ...message, ...payload.map(setMessageData) }
      }
    },
    updateMessageReaction(state, { payload }) {
      const index = state.data[payload.roomId].messages.findIndex(
        (message) => message._id === payload._id,
      )
      if (index !== -1) {
        const message = state.data[payload.roomId].messages[index]
        state.data[payload.roomId].messages[index] = { ...message, ...payload }
      }
    },
    //WILL BE FIX THIS LINE
    deleteMessage(state, { payload }) {
      const index = state.data[payload.roomId].messages.findIndex(
        (message) => message._id === payload._id,
      )
      const message = state.data[payload.roomId].messages[index]
      state.data[payload.roomId].messages[index] = { ...message, ...payload }
    },

    editMessage(state, { payload }) {
      const room = state.data[payload.roomId]
      const messageIndex = room.messages.findIndex((message) => message._id === payload._id)
      if (messageIndex === -1) {
        return
      }
      const message = state.data[payload.roomId].messages[messageIndex]
      room.messages[messageIndex] = { ...message, ...payload }
    },
    getJumpMessages(state, { payload }) {
      const copiedPayload = [...payload.data]
      const subsequentedData = getSubsequentMessages(copiedPayload)
      const structeredMessages = concatDaySeparator(subsequentedData)
      state.data[payload.data[0].roomId].messages = structeredMessages
      state.data[payload.data[0].roomId].page = payload.page
    },
    updateThreadMessagesCount(state, action) {
      const { roomId, threadId } = action.payload
      const room = state.data[roomId]
      const messageIndex = room.messages.findIndex((message) => message._id === threadId)
      if (messageIndex === -1) {
        return
      }
      if (!room.messages[messageIndex].threadCount) {
        room.messages[messageIndex] = { ...room.messages[messageIndex], threadCount: 1 }
      } else {
        room.messages[messageIndex].threadCount += 1
      }
    },
    updateThreadReplyUsers(state, action) {
      const { roomId, _id, repliedUsers } = action.payload
      const room = state.data[roomId]
      const messageIndex = room?.messages?.findIndex((message) => message?._id === _id)
      if (messageIndex === -1 || messageIndex === undefined) {
        return
      }
      if (!room.messages[messageIndex].repliedUsers) {
        room.messages[messageIndex] = { ...room.messages[messageIndex], repliedUsers }
      } else {
        room.messages[messageIndex].repliedUsers = repliedUsers
      }
    },
    addUnreadSeparator(state, { payload: { roomId, unreadCountForRoom } }) {
      const messages = state.data[roomId]?.messages || []
      const hasUnreadSep = messages.find((item) => !item?._id && item?.separatorType === 'unread')
      const roomUnreadCount = unreadCountForRoom || 0
      if (roomUnreadCount !== 0 && !hasUnreadSep) {
        messages?.splice(roomUnreadCount, 0, {
          description: t(MESSAGES.NEW_MESSAGES),
          separator: true,
          separatorType: 'unread',
        })
      }
    },
    getNewMessage(state, { payload }) {
      const messages = state.data[payload.roomId]?.messages || []
      const structeredMessages = singleMessageConstructor(
        state.data[payload.roomId]?.messages || [],
        payload,
      )
      messages.unshift(...structeredMessages)
      state.data[payload.roomId] = {
        messages: messages.map(setMessageData),
        page: state.data[payload.roomId]?.page || 0,
        hasMore: state.data[payload.roomId]?.hasMore || false,
        newMessage: true,
      }
    },
    getReconnectedMessages(state, { payload }) {
      const { roomId, data } = payload
      if (data.length > 0) {
        const roomMessages = state.data[roomId]
        if (!roomMessages) {
          const copiedPayload = [...data]
          copiedPayload.reverse()
          const subsequentedData = getSubsequentMessages(copiedPayload)
          const messages = []
          messages.unshift(...subsequentedData)
          state.data[roomId] = { messages, page: Math.floor(data.length / 50) }
        } else {
          const messagesWithoutSep = state.data[roomId].messages.filter((item) =>
            item.hasOwnProperty('_id'),
          )
          const lastIndex = messagesWithoutSep.length - 1
          const lastMessage = { ...messagesWithoutSep[lastIndex] }
          const copiedPayload = [...data]
          copiedPayload.reverse()
          copiedPayload.unshift(lastMessage)
          const subsequentedData = getSubsequentMessages(copiedPayload)
          subsequentedData.shift()
          state.data[roomId].messages.unshift(...subsequentedData)
        }
      }
    },
    removeUnreadSep(state, { payload: { roomId } }) {
      if (!state.data[roomId]) return
      const messages = state.data[roomId].messages
      state.data[roomId].messages = messages.filter(
        (message) => !(!message?._id && message?.separatorType === 'unread'),
      )
    },
    clearAllMessages(state) {
      state.data = {}
      state.draftData = {}
      state.renderedRooms = []
    },
    clearRoomMessages(state, { payload: { roomId } }) {
      if (state.data[roomId]?.messages) {
        state.data[roomId].messages = []
      }
    },
    exceptionalClearAllMessages(state, { payload: { roomId } }) {
      for (const key in state.data) {
        if (key !== roomId) {
          delete state.data[key]
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      conversationService.endpoints.getMessagesByPage.matchFulfilled,
      (state, { payload, meta }) => {
        const { roomId } = meta.arg.originalArgs
        if (state.data[roomId]) {
          const messagesWithoutSep = state.data[roomId].messages.filter((item) =>
            item.hasOwnProperty('_id'),
          )
          const lastIndex = messagesWithoutSep.length - 1
          const lastMessage = { ...messagesWithoutSep[lastIndex] }
          const copiedPayload = [...payload.data]
          copiedPayload.unshift(lastMessage)
          const subsequentedData = getSubsequentMessages(copiedPayload)
          const structeredMessages = concatDaySeparator(subsequentedData).map(setMessageData)
          structeredMessages.shift()
          state.data[roomId].messages.push(...structeredMessages)
          state.data[roomId].page =
            payload.data.length === 0 ? state.data[roomId].page : state.data[roomId].page + 1
          state.data[roomId].hasMore = payload.data.length === 0 ? false : true
          state.data[roomId].newMessage = false
        } else {
          const copiedPayload = [...payload.data]
          const subsequentedData = getSubsequentMessages(copiedPayload)
          const structeredMessages = concatDaySeparator(subsequentedData).map(setMessageData)
          state.data = {
            ...state.data,
            [roomId]: {
              messages: structeredMessages,
              hasMore: payload.data.length === 0 || payload.data.length < 50 ? false : true,
              page: 0,
              newMessage: false,
            },
          }
        }
      },
    ),
      builder.addMatcher(
        conversationService.endpoints.sendMessage.matchRejected,
        (state, PayloadAction) => {
          const roomId = PayloadAction.meta.arg.originalArgs.roomId
          state.data[roomId].messages[0].status = 'failed'
        },
      ),
      builder.addMatcher(
        conversationService.endpoints.getThreadMessage.matchFulfilled,
        (state, { payload }) => {
          payload.data = setMessageData(payload.data)
        },
      )
  },
})
