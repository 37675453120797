import React from 'react'
import styled from 'styled-components'

import { FixMeLater } from 'types'

import FilePicker from 'components/button/FilePicker'
import { presets } from './presets'

export interface IconSourceType {
  src: string
  srcset: string
}
interface IIconProps {
  id?: string
  source: IconSourceType
  className?: string
  isFilePicker?: boolean
  getInputProps?: FixMeLater
  iconPreset?: 'xSmall' | 'small' | 'thin' | 'medium' | 'grande' | 'large' | 'xLarge' | 'xxLarge'
  height?: string
  width?: string
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseDown?: (e: React.MouseEvent<HTMLElement>) => void
  onMouseUp?: (e: React.MouseEvent<HTMLElement>) => void
}

const CustomIcon = ({
  id = '',
  source,
  className,
  isFilePicker,
  getInputProps,
  iconPreset,
  width,
  height,
  onClick,
  onMouseDown,
  onMouseUp,
}: IIconProps) => {
  return (
    <>
      <IconWrapper
        id={id}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        className={className}
        src={source.src}
        width={width}
        height={height}
        // srcSet={source.srcset}
        preset={presets[iconPreset || 'medium']}
        clickable={!!onClick || !!onMouseUp}
      />
      {isFilePicker && <FilePicker getInputProps={getInputProps} icon />}
    </>
  )
}

export default CustomIcon

const IconWrapper = styled.img`
  width: ${({
    clickable,
    preset,
    width,
    height,
  }: {
    clickable: boolean
    preset: any
    width?: string
    height?: string
  }) => {
    if (width) return width
    return preset.width
  }};
  height: ${({
    clickable,
    preset,
    width,
    height,
  }: {
    clickable: boolean
    preset: any
    width?: string
    height?: string
  }) => {
    if (height) return height
    return preset.height
  }};
  cursor: ${({ clickable }: { clickable: boolean; preset: any }) =>
    clickable ? 'pointer' : 'unset'};
`
