import { memo, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import colors from 'utils/colors';
import { icons } from 'utils/icons';
import { useAppSelector } from 'app/hooks'
import CustomText from 'components/text/CustomText';
import CustomIcon from 'components/icon/CustomIcon';
import Avatar, { AvatarSize } from 'components/avatar/Avatar';
import { MOBILE_DEVICE_WIDTH } from 'utils/constants';
import UserCard from 'components/new-message/user-card/UserCard';
import { useCreateConversationMutation } from 'app/services/room.service'
import Loading from 'components/spinner/Loading'
import { FixMeLater } from 'types'
import { useNavigate } from 'react-router-dom';
import { BUTTONS, MODALS, TABS } from 'locales/locales'
import useInfoModal from 'utils/hooks/useInfoModal'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'
import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO,
  CHANNEL_MEMBER_LIMIT,
} from 'utils/constants'
import UserGroupsModal from 'features/channels/userGroupModal';
interface IPostHeaderProps {
  postId?: string;
  avatar: string;
  fullname: string;
  team?: string;
  date?: string;
  isOption?: boolean;
  creatorId?: string;
  isPostDetail?: boolean;
  isComment?: boolean;
  onDelete?: (postId: string) => void;
  onUpdate?: () => void;
}

const PostHeader: React.FC<IPostHeaderProps> = ({
  postId,
  avatar,
  fullname,
  team,
  date,
  isOption,
  creatorId,
  isPostDetail,
  isComment,
  onDelete,
  onUpdate,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [visibleOption, setVisibleOption] = useState(false);
  const [createConversation] = useCreateConversationMutation()
  const [windowApi,setWindowApi] = useState<FixMeLater>(null);
  const [selectedUserName, setSelectedUserName] = useState("");
  const [isUserGroupsModalVisible, setIsUserGroupsModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [visibleMemberInfo, setVisibleMemberInfo] = useState<{
    visible: boolean;
    data: any;
  }>({ visible: false, data: {} });

  const avatarRef = useRef<HTMLDivElement>(null)

  const myId = useAppSelector((state) => state.auth.id)
  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()

  const [visibleUserCard, setVisibleUserCard] = useState(false); 

  const onShowMemberInfoCard = () => {
      setVisibleMemberInfo({
        visible: true,
        data: {
          avatar,
          fullname,
          team,
          creatorId,    
        },
      });
      setVisibleUserCard(true);
  };


  const onUsersGrup = (userId: string, fullname: string) => {
    setIsUserGroupsModalVisible(true);
    setSelectedUserId(userId);
    setSelectedUserName(fullname);  
  };
  
  const closeUserGroupsModal = () => {
    setIsUserGroupsModalVisible(false);
  };
  
  const onToggleOption = () => setVisibleOption(!visibleOption);

  const onClickDelete = () => {
    onToggleOption();
    if (postId && onDelete) {
      onDelete(postId);
    }
  };

  const onClickBackDrop = () => {
    setVisibleMemberInfo({ visible: false, data: {} }); 
    setVisibleUserCard(false); 
  };

  useEffect(()=>{
    const w = window as any;
    if (w.api)
    setWindowApi(w.api);
  },[])


  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && visibleMemberInfo.visible) {
      setVisibleMemberInfo({ visible: false, data: {} }); 
      setVisibleUserCard(false); 
    }
  };

  const onVideoCall = async () => {
    openConferenceModal({
      title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
      description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeConferenceModal,
      onOk: () => handleChatAndConference(CONFERENCE_TYPE_VIDEO)
    })
  
}
const creatingConference = async (conferenceType: string, roomId?: string) => {
  try {
    Loading.ref.show();
    const data = await createConference({
      roomId: roomId, 
      type: 'single',
      conferenceType: conferenceType,
    });
    if (windowApi) {
      windowApi.send('openConferenceUrl', { url: data.data?.url });
    } else {
      window.open(data.data?.url, '', CONFERENCE_BLANK_DIMENSIONS);
    }
  } finally {
    Loading.ref.hide();
    closeConferenceModal();
  }
};


const onClickChatIcon = async (): Promise<string | null> => {
  try {
    Loading.ref.show();
    const result = await createConversation({ members: [creatorId] });
    if (!result.error) {
      const roomId = typeof result.data.data === 'string' ? result.data.data : result.data.data._id;
      navigate('/messages/' + roomId);
      return roomId; 
    }
  } catch (error) {
    console.error('Error in creating or fetching conversation:', error);
  } finally {
    Loading.ref.hide();
  }
  return null;  
};


const handleChatAndConference = async (conferenceType: string) => {
  const roomId = await onClickChatIcon();
  if (roomId) {
    await creatingConference(conferenceType, roomId);
  } else {
    console.error('Failed to get room ID');
  }
};


  const onClickUpdate = () => {
    onToggleOption();
    if (onUpdate) {
      onUpdate();
    }
  };

  return (
    <>
    
      <Wrapper>
        <Avatar
          source={avatar}
          text={fullname}
          ref={avatarRef}
          size={isComment ? AvatarSize.medium : AvatarSize.large}
          type="circle"
          onClick={onShowMemberInfoCard}
        />
        <RightWrapper>
          <CreatorInfo>
            <CustomText typography="body4">{fullname}</CustomText>
            {team && (
              <ResponsiveCustomText typography="body6" color={colors.gray60}>
                {team}
              </ResponsiveCustomText>
            )}
            {date && (
              <ResponsiveCustomText typography="body6" color={colors.gray60}>
                {date}
              </ResponsiveCustomText>
            )}
          </CreatorInfo>
        </RightWrapper>
        {isOption && !isPostDetail && (
          <SettingsIcon iconPreset="grande" source={icons.threedotIcon} onClick={onToggleOption} />
        )}  
        {visibleOption && (
          <SettingsWrapper>
            <OptionText onClick={onClickUpdate}>{t(BUTTONS.EDIT)}</OptionText>
            <OptionText color={colors.secondaryRed} onClick={onClickDelete}>
              {t(BUTTONS.DELETE)}
            </OptionText>
          </SettingsWrapper>
        )}
      </Wrapper>
      {visibleOption}
        {visibleUserCard && (
            <UserCard
            forceLeft={true}
            isCentered={true}
            showChatIcon={creatorId !== myId} 
            showCallIcon={false}
            onClickUserGroups={() => {
              creatorId && fullname &&
                onUsersGrup(creatorId, fullname);
            }}
            showCameraIcon={creatorId !== myId}
            onClickChatIcon={onClickChatIcon}
            onClickCameraIcon={onVideoCall}
            positionY={avatarRef?.current?.getBoundingClientRect().y}
            onKeyUp={onKeyUp}
            {...visibleMemberInfo.data}
            onClose={() => {
              setVisibleMemberInfo({ visible: false, data: {} });
              setVisibleUserCard(false);
            }}
          />
       
      )}
        {(visibleMemberInfo.visible) && (
        <BackDrop onClick={onClickBackDrop} />
      )}
      {ConferenceModal}
      {isUserGroupsModalVisible && selectedUserId && (
      <UserGroupsModal 
        onClose={closeUserGroupsModal} 
        isVisible={isUserGroupsModalVisible} 
        userId={selectedUserId}
        userName={selectedUserName} 
        /> 
      )}
    </>
  );
};

export default memo(PostHeader);

const Wrapper = styled.div`
  display: flex;
  column-gap: 20px;
  position: relative;
  align-items: center;
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CreatorInfo = styled.div`
  display: flex;
  row-gap: 1px;
  flex-direction: column;
`
const ResponsiveCustomText = styled(CustomText)`
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    font-size: 12px;
  }
`

const SettingsIcon = styled(CustomIcon)`
  position: absolute;
  right: 0px;
  top: 0px;
  &:hover {
    background-color: ${colors.light50};
    border-radius: 4px;
  }
`

const SettingsWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.light80};
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px 0px;
  z-index: 2;
`
const OptionText = styled(CustomText)`
  cursor: pointer;
  padding: 5px 15px;
  &:hover {
    background-color: ${colors.gray20};
  }
`
const BackDrop = styled.div`
position: fixed;
z-index: 1;
width: 100vw;
height: 100vh;
top: 0;
left: 0;
backdrop-filter: blur(1px);
`
