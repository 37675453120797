import styled from 'styled-components'

import colors from 'utils/colors'

import CustomText from 'components/text/CustomText'

interface Item {
  key: string
  value: string
  color?: string
}

interface IOptionListCardProps {
  visible?: boolean
  className?: string
  items: Array<Item>
  onClick: (key: string) => void
  onHideCard: () => void
}

const OptionListCard = ({
  visible,
  className,
  items,

  onClick,
  onHideCard,
}: IOptionListCardProps) => {
  if (!visible) return null
  return (
    <>
      <CommonCardWrapper className={className}>
        {items.map(({ key, value, color }: Item) => {
          return (
            <OptionListText key={key} color={color} cursor="pointer" onClick={() => onClick(key)}>
              {value}
            </OptionListText>
          )
        })}
      </CommonCardWrapper>
      <BackDrop onClick={onHideCard} />
    </>
  )
}

export default OptionListCard

const CommonCardWrapper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.light80};
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  box-sizing: border-box;
  position: absolute;
  z-index: 4;
  padding: 10px 0px 10px 0px;
`

const OptionListText = styled(CustomText)`
  padding: 6px 20px 6px 20px;
  white-space: nowrap;
  &:hover {
    background-color: ${colors.light50};
  }
`

const BackDrop = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 3;
`
