import { Mention, MentionsInput } from 'react-mentions'
import { useRef, useState } from 'react'
import EmojiPicker from 'emoji-picker-react'
import styled from 'styled-components'

import MentionsDropdown from 'components/input/message-input/mention/MentionsDropdown'
import { defaultMentionStyle } from 'components/input/message-input/mention/Mention'
import CustomIcon from 'components/icon/CustomIcon'
import { IUser, IUserData } from 'types/user.types'
import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { FixMeLater } from 'types'
import useCommonInputObserver from './useCommonInputObserver'
import { CommentInputData, InputData } from 'app/slices/news-feed.slice'
import { scrollBottom } from 'utils/helpers/scrollHelper'
import { MOBILE_DEVICE_WIDTH, MOBILE_WIDTH } from 'utils/constants'
import { PLACEHOLDERS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import CustomText from 'components/text/CustomText'
import { useLazyGetUsersByFullnameQuery } from 'app/services/user.service'

interface INewsFeedCommonInputProps {
  classNames?: FixMeLater
  data: InputData | CommentInputData
  placeHolder: string
  id: string
  isValid?: boolean
  textInfo?: boolean
  onMentionDropDown?: (item: boolean) => void
  onKeyUp?: (event: FixMeLater) => void
  onKeyDown?: (event: FixMeLater) => void
  onChangeInputData?: (text: string) => void
  onSelectEmoji?: (emojiData: FixMeLater) => void
  onNewCommentForPost?: () => void
}

const CommonInput = ({
  data,
  classNames,
  placeHolder,
  id,
  isValid,
  textInfo,
  onKeyUp,
  onKeyDown,
  onSelectEmoji,
  onChangeInputData,
  onMentionDropDown,
  onNewCommentForPost,
}: INewsFeedCommonInputProps) => {
  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState(false)
  const newsFeedInputRef = useRef<HTMLTextAreaElement>(null)
  const mentionsDropdownRef = useRef<HTMLDivElement>(null)
  const clintYCommentRef = useRef<FixMeLater>(null)
  const { t } = useTranslation()

  useCommonInputObserver({ newsFeedInputRef, mentionsDropdownRef })

  const changeTextHandler = (event: FixMeLater) => {
    onChangeInputData?.(event.target.value)
    scrollBottom(newsFeedInputRef)
  }

  const onVisibleEmojiPicker = (event: FixMeLater) => {
    clintYCommentRef.current = Math.ceil((event.clientY / document.body.scrollHeight) * 100)
    setVisibleEmojiPicker(!visibleEmojiPicker)
  }
  const emojiHandler = (event: FixMeLater, emojiData: FixMeLater) => {
    onSelectEmoji?.(emojiData)
    newsFeedInputRef?.current?.focus()
  }

  const onToggleVisibleEmojiPicker = () => {
    setVisibleEmojiPicker(!visibleEmojiPicker)
  }

  const [getMembersBySearch] = useLazyGetUsersByFullnameQuery()

  const [mentionedUsers, setMentionedUsers] = useState<IUserData>({})

  const searchMembersToMention = async (query: string, callback: (response: FixMeLater) => void) => {
    // fetch users
    const searchResult = (await getMembersBySearch({ searchText: query })).data?.data || []

    // Temporary object to hold new mentioned users
    const newMentionedUsers: IUserData = {}

    searchResult.forEach((user: IUser) => {
      newMentionedUsers[user._id] = user
    })
    setMentionedUsers(newMentionedUsers)

    callback(
      Object.values(newMentionedUsers).map((user: IUser) => ({
        id: user._id,
        display: user.fullname,
        ...user,
      })),
    )
  }

  return (
    <>
      <Wrapper className={classNames} type={id}>
        {visibleEmojiPicker && (
          <EmojiPickerWrapper type={id} clintYComRef={clintYCommentRef.current}>
            <EmojiPicker
              native
              onEmojiClick={(_, e) => {
                emojiHandler(_, e)
                setVisibleEmojiPicker(false)
              }}
            />
          </EmojiPickerWrapper>
        )}
        <TopWrapper type={id}>
          <IconWrapper textInfo={textInfo}>
            <CustomIcon source={icons.emojiIcon} onClick={onVisibleEmojiPicker} />
            {textInfo && (
              <CustomText typography="body6" color={colors.gray80}>
                {t(PLACEHOLDERS.ADD_NEW_LINE)}
              </CustomText>
            )}
          </IconWrapper>

          <StyledMentionsInput
            id={id}
            type={id}
            value={data?.text}
            allowSpaceInQuery={true}
            inputRef={newsFeedInputRef}
            placeholder={placeHolder}
            onChange={changeTextHandler}
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            customSuggestionsContainer={(children: FixMeLater) => {
              return (
                <MentionsDropdown
                  items={children}
                  users={mentionedUsers}
                  id={id}
                  mentionsDropdownRef={mentionsDropdownRef}
                  onMentionDropdown={onMentionDropDown}
                />
              )
            }}
          >
            <Mention
              trigger={'@'}
              style={defaultMentionStyle}
              appendSpaceOnAdd={true}
              markup={'@[__display__]&_id:__id__&'}
              data={searchMembersToMention}
              displayTransform={(id, display) => {
                return `@${display}`
              }}
            />
          </StyledMentionsInput>
          {id === 'comment' && (
            <ButtonWrapper>
              <CustomIcon
                onClick={isValid ? onNewCommentForPost : undefined}
                source={isValid ? icons.sendIconGreenActive : icons.sendIconWithoutBackground}
              />
            </ButtonWrapper>
          )}
        </TopWrapper>
      </Wrapper>
      {visibleEmojiPicker && <BackDrop onClick={onToggleVisibleEmojiPicker} />}
    </>
  )
}

const ButtonWrapper = styled.div`
  align-self: center;
`

export const StyledMentionsInputWrapper = styled.div.attrs((props: FixMeLater) => props)`
  display: block;
  padding: ${(props) => (props.type === 'comment' ? `0` : '0 8px')};
  border: ${(props) => (props.type === 'comment' ? `none` : '1px solid rgba(0, 0, 0, 0.08)')};
  border-radius: ${(props) => (props.type === 'comment' ? `0` : '4px')};
  position: relative;
  width: 100%;
  height: auto;
  min-height: ${(props) => (props.type === 'comment' ? `19px` : '95px')};
  margin: 10px 0;
  box-sizing: border-box;
`

export const StyledMentionsInput = styled(MentionsInput).attrs((props: FixMeLater) => props)`
  margin: 10px 0px;
  width: 100%;
  background-color: ${colors.white};
  word-break: break-word;

  border: ${(props) => (props.type === 'comment' ? `none` : '1px solid rgba(0, 0, 0, 0.08)')};
  border-radius: ${(props) => (props.type === 'comment' ? `0` : '25px')};
  padding: ${(props) => (props.type === 'comment' ? `0` : '20px')};

  :nth-child(2) {
    padding: ${(props) => (props.type === 'comment' ? `0` : '20px 19px')};
    word-break: break-word;
    max-height: 150px !important;
    min-height: ${(props) => (props.type === 'comment' ? `19px` : '150px')} !important;
    div {
      :nth-child(1) {
        max-height: 150px;
        /* overflow-y: scroll !important; */
      }
    }
  }

  textarea {
    padding: ${(props) => (props.type === 'comment' ? `0` : '20px 19px')};
  }
`

export const TopWrapper = styled.div.attrs((props: FixMeLater) => props)`
  display: flex;
  width: 100%;
  align-items: flex-start;
  padding: ${(props) => (props.type === 'comment' ? `0px 10px` : '0px')};
  gap: ${(props) => (props.type === 'comment' ? `5px` : '0px')};
  flex-direction: ${(props) => (props.type === 'comment' ? `row` : 'column-reverse')};
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    textarea {
      margin: 0px !important;
    }
  }
`

export const IconWrapper = styled.div.attrs((props: { textInfo?: boolean }) => props)`
  width: ${({ textInfo }) => (textInfo ? `100%` : 'none')};
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: ${({ textInfo }) => (textInfo ? `space-between` : 'none')};
`

export const EmojiPickerWrapper = styled.div.attrs((props: FixMeLater) => props)`
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 3;
  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    top: ${({ type, clintYComRef }) =>
      type === 'comment' && clintYComRef < 60 ? `46px` : '-325px'};
  }
`

const Wrapper = styled.div.attrs((props: FixMeLater) => props)`
  background-color: ${colors.white};
  width: 100%;
  border: ${(props) => (props.type === 'comment' ? `1px solid ${colors.gray60}` : 'none')};
  border-radius: ${(props) => (props.type === 'comment' ? `8px` : '0px')};
  position: relative;
`

export const BackDrop = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
`

export default CommonInput
