import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { icons } from 'utils/icons'
import { TITLES } from 'locales/locales'

import StatisticsCard from 'components/cards/StatisticsCard'

interface IStatisticData {
  totalChannels: number
  totalMessages: number
  totalFiles: number
  totalVideoCall?: number
}

const UserStatistic = ({
  totalChannels,
  totalFiles,
  totalMessages,
  totalVideoCall,
}: IStatisticData) => {
  const { t } = useTranslation()

  return (
    <StatisticWrapper>
      <StatisticsCard
        icon={icons.channelActiveIcon}
        description={t(TITLES.CHANNELS_JOINED) as string}
        value={totalChannels}
      />
      <StatisticsCard
        icon={icons.messageActiveIcon}
        description={t(TITLES.TOTAL_MESSAGES) as string}
        value={totalMessages}
      />
      <StatisticsCard
        icon={icons.fileActiveIcon}
        description={t(TITLES.TOTAL_FILES) as string}
        value={totalFiles}
      />
      <StatisticsCard
        icon={icons.videoCallActiveIcon}
        description={t(TITLES.TOTAL_VIDEO_CALL) as string}
        value={totalVideoCall || 0}
      />
    </StatisticWrapper>
  )
}

export default UserStatistic

const StatisticWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  flex-direction: row;
  row-gap: 16px;
`
