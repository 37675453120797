import { memo, useEffect, useMemo } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { IUser, IUserData, UserStatus } from 'types/user.types'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import { RoomTypes } from 'types/rooms.types'
import { useAppSelector } from 'app/hooks'

interface IRoomProps {
  isSearch: boolean
  isAppear: boolean
  myId: string
  roomId: string
  notificationCount?: number
  users: IUserData
  roomType: string
  members: Array<string>
  currentRoomId: string
  isVanishMode?: boolean
  roomOwner?: IUser
  invitedUsers?: Array<string>
  onNavigate: (roomId: string) => void
  onHideConversationPanel?: () => void
}

const Room = ({
  isSearch,
  isAppear,
  myId,
  roomId,
  roomType,
  members,
  users,
  currentRoomId,
  notificationCount = 0,
  isVanishMode,
  roomOwner,
  invitedUsers = [],
  onNavigate,
  onHideConversationPanel,
}: IRoomProps) => {
  const isMyRoom = roomOwner?._id === myId
  const globalUsers: IUserData = useAppSelector((state) => state.users.data)

  const getRoomInfoByMembers = () => {
    members = members || []
    users = users || {}

    const membersWithoutMe = isVanishMode
      ? members
      : members.filter((memberId) => memberId !== myId)

    let otrName = ''
    let otrAvatar = ''

    if (isVanishMode) {
      if (isMyRoom) {
        // my room, 1-1
        if (invitedUsers.length === 1) {
          otrName = users[invitedUsers[0]]?.fullname
          otrAvatar = users[invitedUsers[0]]?.avatar
        } else {
          // my room 1-n
          otrName = membersWithoutMe
            .map((userId: string) => {
              const result = users[userId]?.fullname.split(' ') || ''
              return (
                result?.[0]?.charAt(0)?.toUpperCase() +
                (result?.[1]?.charAt(0)?.toUpperCase() || '')
              )
            })
            .join(', ')
        }
      } else {
        // not my room, 1-1
        if (invitedUsers.length === 1) {
          otrName = roomOwner?.fullname || ''
          otrAvatar = roomOwner?.avatar || ''
        } else {
          // not my room 1-n
          otrName = [roomOwner?._id, ...membersWithoutMe]
            .filter((id) => id !== myId)
            .map((userId) => {
              const result = users[userId as string]?.fullname?.split(' ') || ''
              return (
                result?.[0]?.charAt(0)?.toUpperCase() +
                (result?.[1]?.charAt(0)?.toUpperCase() || '')
              )
            })
            .join(', ')
        }
      }
    }

    if (membersWithoutMe.length === 0) {
      const { avatar, fullname, status, isDeleted } = users[myId] ?? {}
      return { name: fullname, roomAvatar: avatar, status, isDeleted }
    } else if (roomType === 'GM') {
      const name = membersWithoutMe
        .map((userId: string) => {
          const result = users[userId]?.fullname?.split(' ') || ''
          return (
            result?.[0]?.charAt(0)?.toUpperCase() + (result?.[1]?.charAt(0)?.toUpperCase() || '')
          )
        })
        .join(', ')
      return { name, roomAvatar: undefined, status: undefined }
    }

    const userId = membersWithoutMe[0]
    const { avatar = '', fullname, status, isDeleted } = users[userId] || {}

    return { name: fullname, roomAvatar: avatar, status: globalUsers[userId]?.status ?? status, isDeleted, otrName, otrAvatar }
  }

  const onClick = () => {
    if (currentRoomId !== roomId) {
      onNavigate(roomId)
    }
    onHideConversationPanel?.()
  }

  const {
    status,
    name = '',
    roomAvatar,
    isDeleted,
    otrName = '',
    otrAvatar = '',
  } = getRoomInfoByMembers()

  const NotificationView = useMemo(() => {
    return (
      <CountWrapper more={notificationCount > 10}>
        <CustomText color={colors.vimeDark} typography="body7">
          {notificationCount > 10 ? '10+' : notificationCount}
        </CustomText>
      </CountWrapper>
    )
  }, [notificationCount])
  

  if (!isSearch && !isAppear) return null
  return (
    <Wrapper selectedRoom={currentRoomId === roomId} onClick={onClick}>
      <RoomInfoWrapper>
        {isVanishMode && <CustomIcon source={icons.otrIconOn} />}
        <Avatar
          color={colors.orange}
          source={
            !isDeleted
              ? roomType === RoomTypes.OTR
                ? otrAvatar
                : roomAvatar
              : icons.iconDisabledUser.src
          }
          text={
            roomType === RoomTypes.DM
              ? name
              : roomType === RoomTypes.OTR
              ? invitedUsers.length === 1
                ? isMyRoom
                  ? users[invitedUsers[0]]?.fullname
                  : roomOwner?.fullname
                : invitedUsers.length.toString()
              : members.length.toString()
          }
          status={!isDeleted ? status : UserStatus.OFFLINE}
          size={AvatarSize.small}
        />
        <StyledText typography="header3" color={colors.white}>
          {(isVanishMode ? otrName : name)}
        </StyledText>
      </RoomInfoWrapper>
      {!!notificationCount && NotificationView}
    </Wrapper>
  )
}

export default memo(Room)

const StyledText = styled(CustomText)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 6px 16px 6px 16px;
  background-color: ${({ selectedRoom }: { selectedRoom: boolean }) =>
    selectedRoom ? 'rgba(231, 245, 251, 0.35)' : colors.vime};
  &:hover {
    background-color: rgba(231, 245, 251, 0.2);
  }
`

const RoomInfoWrapper = styled.div`
  max-width: 180px;
  display: flex;
  align-items: center;
  column-gap: 8px;
`

const CountWrapper = styled.div.attrs((props: { more: boolean }) => props)`
  /* position: absolute; */
  width: fit-content;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 26px;
  width: ${({ more }) => (more ? 28 : 18)}px;
  height: 18px;
  border-radius: ${({ more }) => (more ? '50px' : '50%')};
  background-color: ${colors.white};
  color: ${colors.white};
`
