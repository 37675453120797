import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { icons } from 'utils/icons'
import { groupsActions } from 'app/slices/groups.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { IChannel, IGroup, TGroupData } from 'types/group.types'

import Group from './Group'
import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'
import { EMPTY } from 'locales/locales'
import colors from 'utils/colors'
import { useMemo } from 'react'
import { orderBy } from 'lodash';

interface Props {
  isSearching: boolean
  onCreateChannel: (groupId: string) => void
  onOpenGroupInfo: (groupId: string) => void
  channelsBySearch: TGroupData | string | undefined
  onHideConversationPanel?: () => void
}

const ConversationList = ({
  isSearching,
  onCreateChannel,
  onOpenGroupInfo,
  channelsBySearch,
  onHideConversationPanel,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const myId = useAppSelector((state) => state.auth.id)
  const groupsData: TGroupData = useAppSelector((state) => state?.groups?.data || [])

  const groups = orderBy(groupsData, [group => group.name.toLowerCase()], ['asc'])

  const onSelectRoom = async (channel: IChannel, group: IGroup) => {
    const existingChannel = groups.find((group) =>
      group.channels.some((item) => item._id === channel._id),
    )

    const existingGroup = groups.some((item) => item._id === group._id)

    if (!existingChannel && existingGroup) {
      dispatch(
        groupsActions.addChannel({
          ...channel,
          hasSubscription: false,
        }),
      )
    } else if (!existingGroup && !existingChannel) {
      dispatch(
        groupsActions.addChannelAndGroup({
          ...group,
          channels: [{ ...channel, hasSubscription: false }],
        }),
      )
    }
    navigate(channel._id)
    onHideConversationPanel?.()
  }

  const mappingRooms = channelsBySearch || groups

  const groupsToRender = useMemo(() => {
    if (Array.isArray(mappingRooms)) {
      return isSearching ? mappingRooms : mappingRooms.filter((r) => !r.isArchived)
    }
  }, [isSearching, mappingRooms])

  if (typeof mappingRooms === 'string') {
    return (
      <NoResultText typography="header3" textAlign="center" color={colors.white}>
        {mappingRooms}
      </NoResultText>
    )
  }

  if (mappingRooms.length === 0 || (mappingRooms?.every((r) => r.isArchived) && !isSearching)) {
    return (
      <EmptyPanelWrapper>
        <CustomIcon iconPreset="xLarge" source={icons.groupIcon} />
        <CustomText typography="header3" color={colors.white}>
          {t(EMPTY.EMPTY_TEAMS_TITLE)}
        </CustomText>
        <StyledText typography="body7" color={colors.white}>
          {t(EMPTY.EMPTY_TEAMS_DESCRIPTION)}
        </StyledText>
      </EmptyPanelWrapper>
    )
  }

  return (
    <Wrapper>
      {groupsToRender?.map((group) => (
        <Group
          key={group._id}
          isSearch={!!channelsBySearch}
          groupId={group._id}
          url={group?.imageUrl}
          name={group.name}
          isMyGroup={group.members.includes(myId)}
          channels={group.channels}
          group={group}
          pathname={pathname}
          onSelectRoom={onSelectRoom}
          onCreateChannel={onCreateChannel}
          onOpenGroupInfo={onOpenGroupInfo}
        />
      ))}
    </Wrapper>
  )
}

export default ConversationList

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 0px 30px 0px;
  flex-grow: 1;
  overflow-y: auto;
`

const EmptyPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: center;
  margin-top: 30px;
  width: 70%;
`
const NoResultText = styled(CustomText)`
  margin-top: 20px;
`

const StyledText = styled(CustomText)`
  text-align: center;
`
