import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FixMeLater } from 'types'
import useInfoModal from 'utils/hooks/useInfoModal'
import { BUTTONS, MODALS, TABS } from 'locales/locales'
import {
  useLazyGetAllChannelsInSystemQuery,
  useLazyGetMembersBySearchForChannelQuery,
} from 'app/services/group.service'
import { IChannelMember } from 'types/user.types'
import {
  useGetMeetingDetailsByIdMutation,
  useUpdateMeetingByIdMutation,
} from 'app/services/conference.service'

import { DeviceShareOptions, RepeadDayOptions } from '../create-meeting/CreateMeetingModal'
import MeetInfoForm from './MeetingInfoForm'
import CustomModal from 'components/modals/CustomModal'
import Participants from '../create-meeting/Participants'
import AdvancedSettings from '../create-meeting/AdvancedSettings'

interface IUserInfoProps {
  meetingId: FixMeLater
  onClose: () => void
}

const initialMeetDetail = {
  title: '',
  description: '',
  meetingTime: '',
  duration: '0',
  participants: [],
  deviceRestriction: 0,
  hasWaitingRoom: false,
  hasNotification: false,
  publicDraw: false,
  isPublic: true,
  guestMode: true,
  isPasswordProtected: false,
  isRepeatable: false,
  repeatDays: [],
  repeatEndTime: '',
  password: '',
}

const getIsEmail = (email: string) => {
  const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return emailRegex.test(email)
}

const MeetingDetail = ({ meetingId, onClose }: IUserInfoProps) => {
  const { t } = useTranslation()
  const { openModal, closeModal, Modal } = useInfoModal()

  const [members, setMembers] = useState<IChannelMember[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [removedUsers, setRemovedUsers] = useState<FixMeLater>([])
  const [selectedOption, setSelectedOption] = useState({ title: 'Unrestricted', value: 0 })
  const [selectedRepeatOptions, setselectedRepeatOptions] = useState<FixMeLater>([])
  const [singleMeetData, setSingleMeetData] = useState(initialMeetDetail)
  const [membersBySearch, setMembersBySearch] = useState<IChannelMember[]>([])
  const [selectedChannels, setSelectedChannels] = useState<FixMeLater>([])
  const [channelList, setChannelList] = useState<FixMeLater>([])
  const [channelDropDownData, setChannelDropDownData] = useState<FixMeLater>([])
  const [channelSelectedOption, setChannelSelectedOption] = useState({})

  const [getMembersBySearch] = useLazyGetMembersBySearchForChannelQuery()
  const [getMeetingDetailById] = useGetMeetingDetailsByIdMutation()
  const [getMyChannels] = useLazyGetAllChannelsInSystemQuery()
  const [updateMeeting] = useUpdateMeetingByIdMutation()

  useEffect(() => {
    fetchMeetingDetail()
    fetchMyChannels()
  }, [])

  useEffect(() => {
    const selectedValues = new Set(selectedChannels.map((channel: FixMeLater) => channel.value))
    const filtered = channelList.filter((channel: FixMeLater) => !selectedValues.has(channel.value))
    setChannelDropDownData(filtered)
  }, [selectedChannels,channelList])

  const fetchMyChannels = async () => {
    const data = (await getMyChannels({})) as FixMeLater
    const list = data.data.data.map((channel: FixMeLater) => ({
      ...channel,
      title: channel.title || channel.name,
      value: channel.value || channel._id,
      id: channel.id || channel._id,
    }))

    setChannelList([...list])
  }

  const fetchMeetingDetail = async () => {
    try {
      const data = (await getMeetingDetailById({ meetingId })) as FixMeLater
      setSingleMeetData(data.data.data)
      setMembers(
        data.data.data.participants.map((u: FixMeLater) => ({
          ...u,
          email: u.detail,
          fullname: u.fullname,
        })),
      )
      const option = DeviceShareOptions.find(
        (item: FixMeLater) => item.value === data.data.data.deviceRestriction,
      )

      if (data.data.data.repeatDays) {
        const repeatOptions = RepeadDayOptions.filter((option: FixMeLater) =>
          data.data.data.repeatDays.find((data: FixMeLater) => option.value == data),
        )
        setselectedRepeatOptions([...repeatOptions])
      }
      setSelectedOption(option || DeviceShareOptions[0])
    } catch {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  const onConfirmOk = async () => {
    try {
      setIsLoading(true)
      const repeatDays = selectedRepeatOptions.map((option: FixMeLater) => option.value)
      const data = {
        singleMeetData: {
          ...singleMeetData,
          deviceRestriction: selectedOption.value,
          isRepeatable: singleMeetData.isRepeatable,
          repeatDays,
          repeatEndTime: singleMeetData.repeatEndTime,
        },
        selectedChannels,
        members,
        removedUsers,
      }
      await updateMeeting({ data, meetingId })
    } finally {
      setIsLoading(false)
      handleClose()
    }
  }

  const onConfirmCancel = () => {
    closeModal()
  }

  const onOk = () => {
    openModal({
      title: t(MODALS.UPDATE_USER_TITLE),
      description: t(MODALS.UPDATE_USER_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: onConfirmCancel,
      onOk: onConfirmOk,
    })
  }

  const onChangeInput = (value: string | boolean | Date | FixMeLater, key: string) => {
    
    if (key === 'channel_option') {
      setSelectedChannels([...selectedChannels, value])
      setChannelSelectedOption(value)
    } else if (key === 'device_share_restriction') {
      setSelectedOption(value)
    } else if (key === 'repeat_days') {
      const index = selectedRepeatOptions.findIndex((option: FixMeLater) => {
        return option.value === value.value
      })
      if (index === -1) {
        setselectedRepeatOptions([...selectedRepeatOptions, value])
      }
    } else {
      setSingleMeetData({ ...singleMeetData, [key]: value })
    }
  }

  const handleClose = () => {
    onClose()
    setSingleMeetData(initialMeetDetail)
    closeModal()
  }

  const onSearch = async (text: string) => {
    try {
      const result: FixMeLater = await getMembersBySearch({ searchName: text })
      const filteredUsers = result.data.data.filter((fetchedUser: IChannelMember) => {
        return !members.some(
          (member) => member._id === fetchedUser._id || member.email === fetchedUser.email,
        )
      })
      if (getIsEmail(text)) {
        const hasUser = members.find((member) => member.email === text)
        if (!hasUser) {
          filteredUsers.push({ email: text })
        }
      }
      setMembersBySearch(filteredUsers)
    } catch (e) {
      console.log(e)
    }
  }

  const removeUser = (param: FixMeLater) => {
    const filteredData = selectedChannels.filter((user: FixMeLater) => user.value !== param.value)
    setChannelSelectedOption({})
    setSelectedChannels(filteredData)
  }

  const onSelectMember = (user: IChannelMember) => {
    setMembers([...members, user])
  }

  const onRemoveUser = (user: IChannelMember) => {
    const index = singleMeetData.participants.findIndex(
      (participant: FixMeLater) => participant?.email === user.email,
    )
    if (index !== -1) {
      setRemovedUsers([...removedUsers, user])
    }
    const removedData = members.filter(
      (member) => member._id !== user._id || member.email !== user.email,
    )
    setMembers([...removedData])
  }
  const onRemoveRepeatDay = (value: FixMeLater) => {
    const removedData = selectedRepeatOptions.filter((option: FixMeLater) => {
      return option.value !== value.value
    })
    setselectedRepeatOptions(removedData)
  }
  return (
    <>
      <CustomModal
        closeModal={onClose}
        onCancel={onClose}
        onOk={onOk}
        title={t(MODALS.MEETING_INFO_TITLE)}
        tab={[
          { title: t(TABS.GENERAL), action: true },
          { title: t(TABS.PARTICIPANTS), action: true },
          { title: t(TABS.ADVANCED), action: true },
        ]}
        showButtons={false}
        loading={isLoading}
        overflow={'visible'}
      >
        <MeetInfoForm
          meetingName={singleMeetData.title}
          meetingDescription={singleMeetData.description}
          meetingTime={singleMeetData.meetingTime}
          duration={singleMeetData.duration}
          onChange={onChangeInput}
        />
        <Participants
          data={members}
          searchedData={membersBySearch}
          channelDownData={channelDropDownData}
          channelSelectedOption={channelSelectedOption}
          selectedChannels={selectedChannels}
          onChangeSearchText={onSearch}
          onRemoveUser={onRemoveUser}
          onSelectMember={onSelectMember}
          removeUser={removeUser}
          onChangeInput={onChangeInput}
        />
        <AdvancedSettings
          {...singleMeetData}
          meetingName={singleMeetData.title}
          meetingDescription={singleMeetData.description}
          selectedRepeatOptions={selectedRepeatOptions}
          selectedOption={selectedOption}
          onChangeInput={onChangeInput}
          onRemoveRepeatDay={onRemoveRepeatDay}
        />
      </CustomModal>
      {Modal}
    </>
  )
}

export default MeetingDetail
