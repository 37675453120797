import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { POST } from 'locales/locales'
import { getThumbsDownIcon, getThumbsUpIcon } from 'utils/helpers/postHelper'

import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'
import Separator from 'components/separator/Separator'
import CustomButton from 'components/button/CustomButton'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'

interface IActionsProps {
  postId: string
  myId: string
  likes: Array<string>
  dislikes: Array<string>
  commentCount: number
  commentToggle: boolean
  onShowReactions: (postId: string) => void
  onClickComment?: (postId: string) => void
  onReactionPost: (postId: string, isLike: boolean) => void
}

const Actions = ({
  postId,
  myId,
  likes,
  dislikes,
  commentCount,
  commentToggle,
  onShowReactions,
  onClickComment,
  onReactionPost,
}: IActionsProps) => {
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()

  const onLikePost = () => {
    onReactionPost(postId, true)
  }

  const onDislikePost = () => {
    onReactionPost(postId, false)
  }

  const onClickReactions = () => {
    onShowReactions(postId)
  }

  const onClickCommentIcon = () => {
    onClickComment?.(postId)
  }

  return (
    <Wrapper>
      <TopWrapper hasReaction={!!dislikes?.length || !!likes?.length}>
        {(!!dislikes?.length || !!likes?.length) && (
          <ReactionWrapper onClick={onClickReactions}>
            {!!likes?.length && (
              <StyledIcon iconPreset="small" zIndex={2} source={icons.likeCircleIcon} />
            )}
            {!!dislikes?.length && (
              <StyledIcon
                iconPreset="small"
                positionLeft={likes?.length ? 12 : 0}
                zIndex={1}
                source={icons.dislikeCircleIcon}
              />
            )}
            <CustomText
              marginLeft={likes?.length ? (dislikes?.length ? 34 : 22) : 22}
              typography="body4"
              color={colors.gray80}
            >
              {likes?.length + dislikes?.length}
            </CustomText>
          </ReactionWrapper>
        )}
        {!!commentCount && commentToggle &&  (
          <CustomText
            typography="body4"
            color={colors.gray80}
            onClick={onClickCommentIcon}
            cursor="pointer"
          >
            {commentCount + ' ' + t(POST.COMMENT)}
          </CustomText>
        )}
      </TopWrapper>
      <Separator margin={{ top: !!dislikes?.length || !!likes?.length ? 10 : 0, bottom: 10 }} />
      <ActionWrapper>
        <ActionButton
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.LIKE) : ''}
          icon={getThumbsUpIcon(likes, myId)}
          typography="body4"
          textColor={colors.gray80}
          onClick={onLikePost}
        />
        <ActionButton
          text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.DISLIKE) : ''}
          icon={getThumbsDownIcon(dislikes, myId)}
          typography="body4"
          textColor={colors.gray80}
          onClick={onDislikePost}
        />
        {commentToggle && onClickComment  && (
          <ActionButton
            text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.COMMENT) : ''}
            icon={icons.commentIcon}
            typography="body4"
            textColor={colors.gray80}
            onClick={onClickCommentIcon}
          />
        )}
      </ActionWrapper>
    </Wrapper>
  )
}

export default Actions

const Wrapper = styled.div``

export const StyledIcon = styled(CustomIcon).attrs(
  (props: { positionLeft?: number; zIndex?: number }) => props,
)`
  position: absolute;
  left: ${({ positionLeft }) => positionLeft}px;
  z-index: ${({ zIndex }) => zIndex};
`

const ReactionWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const TopWrapper = styled.div.attrs((props: { hasReaction?: boolean }) => props)`
  display: flex;
  justify-content: ${({ hasReaction }) => (hasReaction ? 'space-between' : 'flex-end')};
  align-items: center;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  flex-wrap: wrap;
`

const ActionButton = styled(CustomButton)`
  box-shadow: none;
  height: 28px;
  gap: 8px;
  background-color: ${colors.white};
  @media only screen and (min-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 0px 20px;
  }
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    padding: 0px 12px;
  }
  &:hover {
    background-color: ${colors.vime10};
  }
`
