import { icons } from 'utils/icons'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FixMeLater } from 'types'
import { NAVIGATION, TITLES } from 'locales/locales'

import CollapsiblePanelMenu from './CollepsiblePanelMenu'
import ConversationPanel from 'components/conversation-panel/ConversationPanel'

export interface ICollapsibleSubMenu {
  url: string
  title: string
  active: boolean
}

export interface ICollapsibleProps {
  visible: boolean
  onToggleConversationPanel: () => void
}
const SettingsSidePanel = ({ visible, onToggleConversationPanel }: ICollapsibleProps) => {
  const { t } = useTranslation()
  const location = useLocation()

  const menuItem = [
    {
      url: '/system-settings/dashboard',
      title: t(TITLES.DASHBOARD_TITLE),
      active: location.pathname.includes('dashboard'),
      icon: icons.adminDashboardIcon,
    },
    {
      url: '/system-settings/server-domain-management',
      title: t(TITLES.SETTINGS_TITLE),
      active: location.pathname.includes('server-domain-management'),
      icon: icons.adminSettingsIcon,
      sub: [
        {
          url: '/system-settings/server-domain-management',
          title: t(TITLES.SYSTEM_SETTINGS_TITLE),
          active: location.pathname.includes('server-domain-management'),
        },

        {
          url: '/system-settings/password-management',
          title: t(TITLES.PASSWORD_SETTINGS_TITLE),
          active: location.pathname.includes('password-management'),
        },
        {
          url: '/system-settings/mail-management',
          title: t(TITLES.MAIL_SETTINGS_TITLE),
          active: location.pathname.includes('mail-management'),
        },
        {
          url: '/system-settings/ldap-management',
          title: t(TITLES.LDAP_SETTINGS_TITLE),
          active: location.pathname.includes('ldap-management'),
        },
        {
          url: '/system-settings/role-management',
          title: t(TITLES.ROLES_SETTINGS_TITLE),
          active: location.pathname.includes('role-management'),
        },
      ],
    },
    {
      url: 'management',
      title: t(TITLES.MANAGEMENT_TITLE),
      active: location.pathname.includes('management'),
      icon: icons.managementIcon,
      sub: [
        {
          url: '/system-settings/user-management',
          title: t(TITLES.USER_SETTINGS_TITLE),
          active: location.pathname.includes('user-management'),
        },
        {
          url: '/system-settings/group-management',
          title: t(TITLES.TEAMS_SETTINGS_TITLE),
          active: location.pathname.includes('group-management'),
        },
        {
          url: '/system-settings/channel-management',
          title: t(TITLES.CHANNEL_SETTINGS_TITLE),
          active: location.pathname.includes('channel-management'),
        },
      ],
    },
  ]

  const onHideConversationPanel = () => {
    if (visible) {
      onToggleConversationPanel()
    }
  }

  return (
    <Wrapper>
      <ConversationPanel
        title={t(NAVIGATION.SETTINGS)}
        visible={visible}
        isGroup={false}
        content={menuItem.map((item: FixMeLater, index: number) => {
          return (
            <CollapsiblePanelMenu
              onToggleConversationPanel={onHideConversationPanel}
              key={index}
              url={item.url}
              active={item.active}
              title={item.title}
              sub={item.sub}
              icon={item.icon}
            />
          )
        })}
        onHideConversationPanel={onToggleConversationPanel}
      />
    </Wrapper>
  )
}

export default SettingsSidePanel

export const Wrapper = styled.div`
  z-index: 5;
`
