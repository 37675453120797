import { createSlice } from '@reduxjs/toolkit'
import { IGroup } from 'types/group.types'
import { groupService } from '../services/group.service'
import { IUserData } from 'types/user.types'

interface GroupState {
  data: IGroup[]
}

const initialGroupsState: GroupState = {
  data: [],
}

export const setGroupData = (group: IGroup): IGroup => ({
  ...group,
  userData: group.users ? group.users.reduce((acc: IUserData, currentUser) => {
    acc[currentUser._id] = currentUser
    return acc
  }, {}) : {},
})

export const { actions: groupsActions, reducer: groupsReducer } = createSlice({
  name: 'groups',
  initialState: initialGroupsState,
  reducers: {
    addGroup: (state, { payload }) => {
      state.data.push(payload)
    },
    addChannel: (state, { payload: { channelInfo, group } }) => {
      
      const existingGroupIndex = state.data.findIndex((item) => item._id === channelInfo.groupId)
      if (existingGroupIndex !== -1) {
        if (state.data[existingGroupIndex].channels) {
          state.data[existingGroupIndex].channels.push(channelInfo)
        } else {
          state.data[existingGroupIndex].channels = [channelInfo]
        }
      } else if (group) {
        const newGroup = { ...group, channels: [channelInfo] }
        state.data.push(newGroup)
      }
    },
    addChannelAndGroup: (state, { payload }) => {
      state.data.push(payload)
    },
    updateChannelInfo: (state, { payload: { channelInfo } }) => {
      const group = state.data.find((group) => group._id === channelInfo.groupId)
      if (group) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === channelInfo._id)
        const existingChannel = group.channels[channelIndex]
        group.channels[channelIndex] = {
          ...existingChannel,
          ...channelInfo,
          hasSubscription: true,
        }
      }
    },
    updateUserPermissions: (state, { payload }) => {
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === payload.channelId),
      )
      if (group) {
        const channelIndex = group.channels.findIndex(
          (channel) => channel._id === payload.channelId,
        )
        if (payload.key === 'readOnly') {
          group.channels[channelIndex].readOnly = payload.value
        } else if (payload.key === 'videoCall')
          group.channels[channelIndex].videoCall = payload.value
      }
    },
    addChannelMembers: (state, { payload: { channelInfo, updatedGroup } }) => {
      const groupIndex = state.data.findIndex((group) => group._id === channelInfo.groupId)
      if (groupIndex !== -1) {
        state.data[groupIndex] = { ...state.data[groupIndex], ...updatedGroup }
      }

      const group = state.data.find((group) => group._id === channelInfo.groupId)
      if (group) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === channelInfo._id)
        if (channelIndex === -1) {
          state.data[groupIndex].channels.push(channelInfo)
        } else {
          const existingChannel = group.channels[channelIndex]
          group.channels[channelIndex] = { ...existingChannel, ...channelInfo }
          state.data[groupIndex] = group
        }
      } else {
        state.data.push({ ...updatedGroup, channels: [channelInfo] })
      }
    },
    removeChannelMembers: (state, { payload: { channelInfo, updatedGroup } }) => {
      const groupIndex = state.data.findIndex((group) => group._id === channelInfo.groupId)
      if (groupIndex !== -1) {
        if (updatedGroup) {
          state.data[groupIndex] = { ...state.data[groupIndex], ...updatedGroup }
        }
        const channelIndex = state.data[groupIndex].channels.findIndex(
          (channel) => channel._id === channelInfo._id,
        )
        const existingChannel = state.data[groupIndex].channels[channelIndex]
        state.data[groupIndex].channels[channelIndex] = { ...existingChannel, ...channelInfo }
      }
    },

    removeMeFromChannel: (state, { payload: { channelInfo, updatedGroup } }) => {
      const groupIndex = state.data.findIndex((group) => group._id === channelInfo.groupId)
      if (groupIndex !== -1) {
        const updatedChannels = state.data[groupIndex].channels.filter(
          (channel) => channel._id !== channelInfo._id,
        )
        state.data[groupIndex].channels = updatedChannels
        state.data[groupIndex] = { ...state.data[groupIndex], ...updatedGroup }
        state.data = state.data.filter((team) => team.channels.length !== 0)
      }
    },
    leaveChannel: (state, { payload: { channelInfo, myId } }) => {
      const group = state.data.find((group) => group._id === channelInfo.groupId)
      const groupIndex = state.data.findIndex((group) => group._id === channelInfo.groupId)
      if (group && groupIndex !== -1) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === channelInfo._id)
        const existChannel = group.channels[channelIndex]
        const isExistInChannel = channelInfo.members.includes(myId)
        if (isExistInChannel) {
          group.channels[channelIndex] = { ...existChannel, ...channelInfo }
          state.data[groupIndex] = group
        } else {
          const group = state.data[groupIndex]
          const channels = group.channels
          const updatedChannels = channels.filter((channel) => channel._id !== channelInfo._id)
          group.channels = updatedChannels
          state.data[groupIndex] = group
          state.data = state.data.filter((item) => item.channels.length !== 0)
        }
      }
    },
    clearChannel: (state, { payload }) => {
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === payload),
      )
      if (group) {
        group.channels.filter((channel) => channel._id !== payload)
        state.data = state.data.filter((item) => item.channels.length !== 0)
      }
    },
    updateGroup: (state, action) => {
      const existingGroupIndex = state.data.findIndex((group) => group._id === action.payload._id)
      state.data[existingGroupIndex] = { ...state.data[existingGroupIndex], ...action.payload }
    },
    makeChannelAppear: (state, { payload }) => {
      const channelId = payload
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === channelId),
      )
      if (group) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === channelId)
        group.channels[channelIndex].isAppear = true
      }
    },
    updateChannelArchive: (state, { payload: { channelId, groupId, isArchived } }) => {
      const group = state.data.find((group) => group._id === groupId)
      if (group) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === channelId)
        group.channels[channelIndex].isArchived = isArchived
        if (!isArchived) {
          group.channels[channelIndex].isAppear = true
        }
      }
    },
    updateApperance: (state, { payload: { roomId: channelId, isAppear } }) => {
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === channelId),
      )
      if (group) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === channelId)
        group.channels[channelIndex].isAppear = isAppear
      }
    },
    updatePinnedConversation: (state, { payload: { pins, groupId, _id } }) => {
      const group = state.data.find((group) => group._id === groupId)
      if (group) {
        const existingChannel = group.channels.findIndex((channel) => channel._id === _id)
        group.channels[existingChannel].pins = pins
      }
    },
    updateUnreadCount: (state, { payload }) => {
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === payload.roomId),
      )
      if (group) {
        const channelByRoomId = group.channels.find((channel) => channel._id === payload.roomId)
        if (channelByRoomId) {
          channelByRoomId.unreadCount = payload.increase ? channelByRoomId.unreadCount + 1 : 0
        }
      }
    },
    updateNotificationCount: (state, { payload }) => {
      const { roomId, notificationCount } = payload
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === roomId),
      )
      if (group) {
        const channelByRoomId = group.channels.find((channel) => channel._id === roomId)
        if (channelByRoomId) {
          channelByRoomId.notificationCount = notificationCount
        }
      }
    },
    resetUnreadCount: (state, { payload }) => {
      const group = state.data.find((item) =>
        item.channels.some((channel) => channel._id === payload),
      )
      if (group) {
        const channelIndex = group.channels.findIndex((channel) => channel._id === payload)
        if (channelIndex !== -1) group.channels[channelIndex].unreadCount = 0
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(groupService.endpoints.getChannels.matchFulfilled, (state, { payload }) => {
      if (payload.data.length > 0) {
        state.data = payload.data.map(setGroupData)
      }
    })
  },
})
