import { createSlice } from '@reduxjs/toolkit'

interface AppState {}

const initialState: AppState = {}

export const { actions: appActions, reducer: AppReducer } = createSlice({
  initialState,
  name: 'app',
  reducers: {},
})
