import { Component } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import { icons } from 'utils/icons'

import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'

interface IShowProps {
  isError?: boolean
  message?: string
}

const TIMEOUT = 5000
class DropdownAlert extends Component {
  static ref: DropdownAlert
  state = { visible: false, isError: false, message: '' }

  show = ({ isError = false, message = '' }: IShowProps) => {
    if (!this.state.visible && message) {
      this.setState({ visible: true, isError, message })
      setTimeout(() => {
        this.hide()
      }, TIMEOUT)
    }
  }

  hide = () => {
    this.setState({ visible: false, message: '' })
  }

  render() {
    const { visible, isError, message } = this.state
    return (
      <Wrapper>
        <Content isError={isError} visible={visible}>
          <CustomIcon source={isError ? icons.errorIcon : icons.successIcon} />
          <CustomText typography="header2" color={colors.white}>
            {typeof message === 'string' ? message : 'Something went wrong'}
          </CustomText>
          <CustomIcon source={icons.closeIconWhite} onClick={this.hide} />
        </Content>
      </Wrapper>
    )
  }
}

export default DropdownAlert

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 450px) {
    padding-left: 8px;
    padding-right: 8px;
  }
`

const Content = styled.div.attrs((props: { isError: boolean; visible: boolean }) => props)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;
  background-color: ${({ isError }) => (isError ? colors.red : colors.primaryVime)};
  border-radius: 8px;
  padding: 10px 5px;
  gap: 10px;
  transform: ${({ visible }) => (visible ? 'translateY(5rem)' : 'translateY(-120%)')};
  @media only screen and (max-width: 450px) {
    transform: ${({ visible }) => (visible ? 'translateY(8px)' : 'translateY(-120%)')};
    width: 100%;
  }
  transition: all 300ms ease;
  z-index: 10;
  pointer-events: fill;
`
