import { IRequirements } from 'types/system.types'

export const passwordValidator = (password: string, requirements: IRequirements) => {
  if (!SpecialCharFunction(password, requirements.uniquecharacters)) {
    return {
      valid: false,
      message: 'En az count kadar özel karakter kullanmalisiniz.',
      key: 'uniquecharacters',
    }
  } else if (!MinLengthFunction(password, requirements.mincharactes)) {
    return {
      valid: false,
      message: 'Şifre uzunluğu en az count kadar olmalidir.',
      key: 'mincharactes',
    }
  } else if (requirements.lowercase && !lowerCaseFunction(password)) {
    return {
      valid: !requirements.lowercase,
      message: 'Şifreniz lovercase içermelidir.',
      key: 'lowercase',
    }
  } else if (requirements.uppercase && !UpperCaseFunction(password)) {
    return {
      valid: !requirements.uppercase,
      message: 'Şifreniz upeercase içermelidir.',
      key: 'uppercase',
    }
  } else if (requirements.number && !IncludeNumberFunction(password)) {
    return { valid: !requirements.number, message: 'Şifreniz number içermelidir.', key: 'number' }
  } else if (requirements.letter && !AlphanumericFunction(password)) {
    return { valid: !requirements.letter, message: 'Şifreniz harf içermelidir.', key: 'letter' }
  } else {
    return { valid: true, message: '', key: 'success' }
  }
}

// Lovercase Function

export const lowerCaseFunction = (password: string): boolean => {
  const pattern = /(?=.*[a-z])/

  return pattern.test(password)
}

//Uppercase Funcstion

export const UpperCaseFunction = (password: string): boolean => {
  const pattern = /(?=.*[A-Z])/

  return pattern.test(password)
}

//Special
export const SpecialCharFunction = (password: string, count: number): boolean => {
  const pattern = new RegExp(`(?=.[-~_*!.?@#$/+:;><=%^&]{${count}})`)

  return pattern.test(password)
}

//Min Length
export const MinLengthFunction = (password: string, count: number): boolean => {
  const pattern = new RegExp(`(?=.{${count},})`)

  return pattern.test(password)
}

//Include Number Function

export const IncludeNumberFunction = (password: string): boolean => {
  const pattern = /^(?=.*\d).+$/

  return pattern.test(password)
}

//Alphanumeric Function

export const AlphanumericFunction = (password: string) => {
  const pattern = /[a-zA-Z]+/

  return pattern.test(password)
}
