import { IGroup } from 'types/group.types'
import { IUserData } from 'types/user.types'
import { FixMeLater } from 'types'
import { IMessage, IMessageContent } from 'types/conversation.types'

import Input from './input/Input'
import Appear from './appear/appear'
import Archived from './archived'
import ReadOnly from './read-only/ReadOnly'
import Disabled from './disabled/Disabled'
import DisableOtr from './disable-otr/DisableOtr'
import JoinChannel from './join-channel/JoinChannel'
import { useAppSelector } from 'app/hooks'
import { useMemo } from 'react'
import { MESSAGES } from 'locales/locales'
import DisabledStatusMessage from '../disable-status/DisabledStatus'

interface IMessageInputProps {
  groups?: IGroup[]
  isJoinChannel?: boolean
  isReadOnly?: boolean
  isArchived?: boolean
  isAppear?: boolean
  isDeactive?: boolean
  roomId?: string
  isOtr?: boolean
  disabledOtr?: boolean
  members?:string[]
  messageId?: string
  messageIdInThread?: string
  placeHolder: string
  users: IUserData
  initialInputData?: IMessageContent
  channel?: boolean
  isEditMessage?: boolean
  getInputProps?: FixMeLater
  onAppearChannel?: () => void
  onUploadAudio?: (audioData: Blob) => void
  onCancelEditMessage?: () => void
  onUnarchiveChannel?: () => void
  onJoinChannel?: () => void
  pendingHandler?: (message: IMessage) => void
  onScrollBottom?: () => void
  onUpdatePastedFileTargetId?: (id: string) => void
}

const MessageInput = ({
  groups,
  isJoinChannel,
  isReadOnly,
  isArchived,
  isAppear,
  isDeactive,
  roomId = '',
  isOtr,
  disabledOtr,
  members,
  messageId,
  messageIdInThread,
  placeHolder,
  users,
  channel,
  initialInputData,
  isEditMessage,
  getInputProps,
  onUploadAudio,
  onCancelEditMessage,
  onUnarchiveChannel,
  onJoinChannel,
  pendingHandler,
  onScrollBottom,
  onAppearChannel,
  onUpdatePastedFileTargetId,
}: IMessageInputProps) => {

  const offlineModeEnabled = useAppSelector(state => state.system.data.features?.offlineModeEnabled);
  const myId = useAppSelector(state => state.auth.id);
  const myStatus = users?.[myId]?.status;
  const isDirectMessaging = (members?.length ?? 0) === 2 && members?.includes(myId);
  const isGroupMessaging = (groups?.length ?? 0) ;

  let messagingType = 'none';
  if (isDirectMessaging) {
    messagingType = 'direct';
  } else if (isGroupMessaging) {
    messagingType = 'group';
  }

  const shouldCheckReceiverStatus = useMemo(() => {
    if (!offlineModeEnabled) return { restricted: false };

    switch (messagingType) {
      case 'direct': {
        const otherUserId = members?.find(id => id !== myId);
        const otherUserStatus = otherUserId ? users[otherUserId]?.status ?? '' : '';
        if (['OFFLINE', 'DONOTDISTURB'].includes(myStatus ?? '') || ['OFFLINE', 'DONOTDISTURB'].includes(otherUserStatus)) {
          return { restricted: true, reason: ['OFFLINE', 'DONOTDISTURB'].includes(otherUserStatus) ? 'otherUserOfflineOrDnd' : 'userOfflineOrDnd' };
        }
        break;
      }
      case 'group': {
        if (['OFFLINE', 'DONOTDISTURB'].includes(myStatus ?? '')) {
          return { restricted: true, reason: 'groupMessagingRestricted' };
        }
        break;
      }
    }
    return { restricted: false };
  }, [offlineModeEnabled, messagingType, members, users, myId, myStatus]);


  if (!isAppear && !messageIdInThread && !isEditMessage) {
    return <Appear onAppearChannel={onAppearChannel} isChannel={channel} />
  } else if (isArchived) {
    return (
      <Archived
        groups={groups || []}
        roomId={roomId}
        isThread={!!messageIdInThread}
        notSubscribed={isJoinChannel}
        onUnarchiveChannel={onUnarchiveChannel}
      />
    )
  } else if (isReadOnly) {
    return <ReadOnly />
  } else if (isDeactive && !isOtr) {
    return <Disabled />
  } else if (isJoinChannel) {
    return <JoinChannel onJoinChannel={onJoinChannel} />
  } else if (disabledOtr) {
    return <DisableOtr />
  }else if (shouldCheckReceiverStatus.restricted) {
    let messageKey;
    switch (shouldCheckReceiverStatus.reason) {
      case 'otherUserOfflineOrDnd':
        messageKey = MESSAGES.OTHER_USER_OFFLINE;
        break;
      case 'groupMessagingRestricted':
        messageKey = MESSAGES.USER_OFFLINE;
        break;
      case 'userOfflineOrDnd':
        messageKey = MESSAGES.USER_OFFLINE;
        break;
      default:
        messageKey = MESSAGES.USER_OFFLINE;
    }
    return <DisabledStatusMessage message={messageKey} />;
  }

  return (
    <Input
      users={users}
      isOtr={isOtr}
      roomId={roomId}
      messageId={messageId}
      messageIdInThread={messageIdInThread}
      initialInputData={initialInputData}
      placeHolder={placeHolder}
      onUploadAudio={onUploadAudio}
      onCancelEditMessage={onCancelEditMessage}
      getInputProps={getInputProps}
      pendingHandler={pendingHandler}
      onScrollBottom={onScrollBottom}
      onUpdatePastedFileTargetId={
        onUpdatePastedFileTargetId ? onUpdatePastedFileTargetId : undefined
      }
    />
  )
}

export default MessageInput
