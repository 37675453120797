import moment from 'moment'
import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ON } from 'utils/constants'
import {
  useLeaveChannelMutation,
  useUpdateNotificationMutation,
  useUpdateRoomChannelApparanceMutation,
} from 'app/services/room.service'
import { FixMeLater } from 'types'
import colors from 'utils/colors'
import { roomsActions } from 'app/slices/rooms.slice'

import Switch from 'components/switch/Switch'
import CustomText from 'components/text/CustomText'
import useInfoModal from 'utils/hooks/useInfoModal'
import Separator from 'components/separator/Separator'
import { BUTTONS, FORM, MESSAGES, MODALS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import { conversationActions } from 'app/slices/conversations.slice'

import Loading from 'components/spinner/Loading'

interface IGeneralProps {
  roomId: string
  type: string
  phoneNumber: string
  createdBy?: string
  createdAt?: string
  isMuted: boolean
  isAppear: boolean
  isMyRoom: boolean
  notifyOnlyMentions?: boolean
  onClose: () => void
}

const General = ({
  roomId,
  type,
  phoneNumber,
  createdBy,
  createdAt,
  isMuted,
  isMyRoom,
  isAppear,
  notifyOnlyMentions,
  onClose,
}: IGeneralProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openModal, closeModal, Modal } = useInfoModal()
  const [updateNotification] = useUpdateNotificationMutation()
  const [updateRoomChannelApparance] = useUpdateRoomChannelApparanceMutation()
  const  [leaveGroup] = useLeaveChannelMutation()

  const [isMutedState, setIsMuted] = useState(true)
  const [isNotifyMentionsOnly, setNotifyMentionsOnly] = useState(false)
  useEffect(() => {
    setIsMuted(isMuted || false)
    setNotifyMentionsOnly(notifyOnlyMentions || false)
  }, [isMuted])

  const onToggleNotification = (updatedNotification: boolean) => {
    setIsMuted(updatedNotification)
    const notificationObj: { isMuted: boolean; notifyOnlyMentions?: boolean } = {
      isMuted: updatedNotification,
      notifyOnlyMentions: type === 'DM' ? false : isNotifyMentionsOnly,
    }
    if (type === 'GM' && updatedNotification) {
      notificationObj.notifyOnlyMentions = false
    }
    updateNotification({ roomId, ...notificationObj })
  }

  const onToggleNotifyMentionsOnly = (updatedNotifyMentionOnly: boolean) => {
    setNotifyMentionsOnly(!updatedNotifyMentionOnly)
    updateNotification({
      roomId,
      isMuted: isMutedState,
      notifyOnlyMentions: !updatedNotifyMentionOnly,
    })
  }

  const onApproveDisappearChannel = async () => {
    const result: FixMeLater = await updateRoomChannelApparance({
      roomId,
      isAppear: false,
    })
    if (result.data) {
      dispatch(roomsActions.updateApperance(result.data))
      dispatch(conversationActions.removeAllDraftAndFailedMessages(result.data.roomId))
      navigate(result.data.redirectUrl)
      closeModal()
      onClose()
    }
  }

  const onApproveAppearChannel = async () => {
    try {
      const result: FixMeLater = await updateRoomChannelApparance({
        roomId,
        isAppear: true,
      })
      dispatch(roomsActions.updateApperance(result.data))
      closeModal()
      onClose()
    } catch (e) {
      console.log(e)
    }
  }

  const onDisappearConversation = () => {
    openModal({
      title: t(MODALS.DISAPPEAR_TITLE) as string,
      description: t(MODALS.DISAPPEAR_DESCRIPTION) as string,
      okTitle: t(BUTTONS.DISAPPEAR) as string,
      onCancel: closeModal,
      onOk: onApproveDisappearChannel,
    })
  }

  const onAppearConversation = () => {
    openModal({
      title: t(MODALS.APPEAR_TITLE) as string,
      description: t(MODALS.APPEAR_DESCRIPTION) as string,
      okTitle: t(BUTTONS.APPEAR) as string,
      onCancel: closeModal,
      onOk: onApproveAppearChannel,
    })
  }
  const onApproveLeaveChannel = async () => {
    let data: FixMeLater = null
    try {
      Loading.ref.show()
      data = await leaveGroup({ roomId: roomId })
      navigate(data?.data?.data)
      closeModal()
      onClose()
    } finally {
      Loading.ref.hide()
      closeModal()
      onClose()
      if (!data?.error) {
        setTimeout(() => {
          dispatch(conversationActions.removeAllDraftAndFailedMessages(roomId))
          dispatch(conversationActions.removeAllMessages(roomId))
          dispatch(roomsActions.removeConversation({_id:roomId}))
        }, 200)
      }
    }
  }

  const onLeaveChannel = () => {
    openModal({
      title: t(MODALS.LEAVE_CHANNEL_TITLE),
      description: t(MODALS.LEAVE_CHANNEL_DESCRIPTION),
      okTitle: t(BUTTONS.LEAVE) as string,
      onCancel: closeModal,
      onOk: onApproveLeaveChannel,
    })
  }


  return (
    <Wrapper>
      {createdBy && (
        <Row direction="column" gap={10} marginBottom={10}>
          <CustomText typography="header3">{t(MESSAGES.CREATED_BY)}</CustomText>
          <CustomText typography="body3">
            {createdBy + ON + moment(createdAt).format('DD MMM yyyy HH:mm')}
          </CustomText>
        </Row>
      )}
     
      {phoneNumber && (
        <Row direction="column" gap={10}>
          <CustomText typography="header3">{t(FORM.PHONE_NUMBER)}</CustomText>
          <CustomText typography="body3">{phoneNumber}</CustomText>
        </Row>
      )}

  
      {!isMyRoom && (
        <Row align="center" justify="space-between" marginBottom={20}>
          <CustomText typography="body4">{t(BUTTONS.NOTIFICATIONS)}</CustomText>
          <Switch checked={!isMutedState} onChange={onToggleNotification} />
        </Row>
      )}
      {!isMyRoom && type === 'GM' && !isMutedState && (
        <Row align="center" justify="space-between" marginBottom={10}>
          <CustomText typography="body4">{t(BUTTONS.MENTIONS_ONLY)}</CustomText>
          <Switch checked={isNotifyMentionsOnly} onChange={onToggleNotifyMentionsOnly} />
        </Row>
      )}
      {!isMyRoom && isAppear && (
        <Row marginBottom={10} direction="column">
          <CustomText
            typography="body4"
            color={colors.red}
            cursor="pointer"
            onClick={onDisappearConversation}
          >
            {t(BUTTONS.DISAPPEAR_CONVERSATION)}
          </CustomText>
        </Row>
      )}
      {!isMyRoom && !isAppear && (
        <Row marginBottom={10} direction="column">
          <CustomText
            typography="body4"
            color={colors.red}
            cursor="pointer"
            onClick={onAppearConversation}
          >
            {t(BUTTONS.APPEAR_CONVERSATION)}
          </CustomText>
        </Row>
      )}
      {type === 'GM' && (
        <Row marginBottom={10} direction="column">
          <CustomText
            typography="body4"
            color={colors.red}
            cursor="pointer"
            onClick={onLeaveChannel}
          >
            {t(BUTTONS.LEAVE_GROUP)}
          </CustomText>
        </Row>
      )}
      {Modal}
    </Wrapper>
  )
}

export default memo(General)

const Wrapper = styled.div``

const Row = styled.div.attrs(
  (props: {
    direction?: string
    gap: number
    marginBottom: number
    align: string
    justify: string
  }) => props,
)`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  gap: ${({ gap }) => gap || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
`
