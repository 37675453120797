import { t } from 'i18next'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { FixMeLater } from 'types'
import { BUTTONS, TITLES } from 'locales/locales'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'

export const DASHBOARD_TITLES = [
  t(TITLES.TOTAL_USER),
  t(TITLES.TOTAL_TEAM),
  t(TITLES.TOTAL_CHANNEL),
  t(TITLES.TOTAL_MESSAGES),
  t(TITLES.TOTAL_FILES),
  t(TITLES.TOTAL_VIDEO_CALL),
]

interface ICardItem {
  title: string
  value: number
}

interface IDashboardProps {
  icon: {
    src: string
    srcset: string
  }
  index: number
  headerValue: number
  body: ICardItem[]
  isDisplay: boolean
  onSeeMore?: FixMeLater
}

const DashboardCard = ({
  icon,
  index,
  headerValue,
  body,
  isDisplay,
  onSeeMore,
}: IDashboardProps) => {
  const { t } = useTranslation()
  return (
    <Card>
      <div>
        <CardHeader>
          <CustomIcon source={icon} iconPreset="xxLarge" />
          <HeaderRight>
            <CustomText typography="header1">{headerValue}</CustomText>
            <CustomText typography="body6">{DASHBOARD_TITLES[index]}</CustomText>
          </HeaderRight>
        </CardHeader>

        <CardBody>
          {body.map((bodyItem: ICardItem, index: number) => (
            <BodyWrapper key={index}>
              <StyledTitle typography="body2">{bodyItem.title}</StyledTitle>
              <StyledValue typography="header2">{bodyItem.value}</StyledValue>
            </BodyWrapper>
          ))}
        </CardBody>
      </div>
      <Wrapper>
        {isDisplay && (
          <StyledButton typography="body3" text={t(BUTTONS.SEE_MORE)} onClick={onSeeMore} />
        )}
      </Wrapper>
    </Card>
  )
}

export default DashboardCard

const StyledButton = styled(CustomButton)`
  width: 100%;
  min-width: 100%;
  border-radius: 8px;
`

const StyledTitle = styled(CustomText)`
  min-width: 190px;
`

const StyledValue = styled(CustomText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Card = styled.div`
  width: 300px;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 24px;
  align-items: center;
  text-align: right;
  gap: 12px;
  border-bottom: 1px solid #dae5f3;
`
const HeaderRight = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const CardBody = styled.div`
  padding: 24px 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
`

const BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
`

const Wrapper = styled.div`
  padding: 24px;
`
