import storage from 'app/storage'

export const getDataFromDB = async (db: string, key: string) => {
  return await storage(db).getItem(key)
}
export const setDataToDB = async (db: string, key: string, value: any) => {
  return await storage(db).setItem(key, value)
}
export const clearDB = async (db: string) => {
  return await storage(db).clear()
}
