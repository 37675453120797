import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ERRORS } from 'locales/locales'
import { useAppSelector } from '../app/hooks'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'

const ProtectedAdminRoute = ({ children }: { children: ReactNode }) => {
  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)
  const { t } = useTranslation()
  const hasAdminRights = () => {
    return permissions.settings > 0 || permissions.userManagement > 0 ||
      isFlagSetIn([
        TeamAndChannelPermissions.CREATE_OR_ARCHIVE_TEAM,
        TeamAndChannelPermissions.REMOVE_TEAM_FROM_ARCHIVE,
        TeamAndChannelPermissions.UPDATE_TEAM,
        TeamAndChannelPermissions.CREATE_OR_ARCHIVE_CHANNEL,
        TeamAndChannelPermissions.UPDATE_CHANNEL
      ], permissions.teamAndChannel);
  }
  const isAdmin = hasAdminRights();

  if (!isAdmin) {
    DropdownAlert.ref.show({ isError: true, message: t(ERRORS.DONT_HAVE_ACCESS) })
    return <Navigate to="/news-feed" />
  }

  return <>{children}</>
}

export default ProtectedAdminRoute
