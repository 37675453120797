import styled from 'styled-components'

import colors from 'utils/colors'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

interface IStatisticCard {
  icon: {
    src: string
    srcset: string
  }
  description: string
  value: number
}

const StatisticsCard = ({ icon, description, value }: IStatisticCard) => {
  return (
    <StatisticCardWrapper>
      <CustomIcon iconPreset="xLarge" source={icon} />
      <BodyWrapper>
        <CustomText typography="body3">{description}</CustomText>
        <CustomText typography="header2">{value}</CustomText>
      </BodyWrapper>
    </StatisticCardWrapper>
  )
}

export default StatisticsCard

const StatisticCardWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 8px;
  border: 1px solid ${colors.light80};
  border-radius: 8px;
  width: 49%;
  @media only screen and (max-width: 474px) {
    width: 100%;
  }
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
