import moment from 'moment'
import styled from 'styled-components'
import colors from 'utils/colors'
import CustomText from 'components/text/CustomText'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { AvatarSize } from 'components/avatar/Avatar'
import { useTranslation } from 'react-i18next'
import { BUTTONS, FORM, MESSAGES, PLACEHOLDERS } from 'locales/locales'
import UploadImage from 'components/input/uplaod-input/UploadImage'
import { requiredValidation } from 'utils/helpers/validations'

interface IUserInfoProps {
  formChecked: boolean
  name: string
  description: string
  imageUrl: string
  createdBy: string
  createdAt: string
  onChange: (value: string | boolean, key: string) => void
  onUploadPhoto: (fileUrl: string) => void
}

const GroupInfo = ({
  formChecked,
  name,
  description,
  imageUrl,
  createdBy,
  createdAt,
  onChange,
  onUploadPhoto,
}: IUserInfoProps) => {
  const { t } = useTranslation()
  const validName = formChecked && requiredValidation(name, t(FORM.TEAM_NAME)).errorText
  return (
    <InfoModalWrapper>
      <UploadImage
        avatar={imageUrl}
        text={name}
        avatarSize={AvatarSize.XXL}
        buttonText={BUTTONS.UPLOAD}
        onUploadPhoto={onUploadPhoto}
      />
      <FormWrapper>
        <CustomFormInput
          value={name}
          title={t(FORM.NAME) as string}
          placeholder={t(PLACEHOLDERS.TEAM_NAME)}
          onChange={(value: string) => onChange(value, 'name')}
          errorText={validName}
        />
        <CustomFormInput
          value={description}
          title={t(FORM.TEAM_DESCRIPTION) as string}
          placeholder={t(PLACEHOLDERS.TEAM_DESCRIPTION)}
          optionalTitle={t(FORM.OPTIONAL) as string}
          onChange={(value: string) => onChange(value, 'description')}
        />
        <TextWrapper>
          <CustomText typography="header3">{t(MESSAGES.CREATED_BY)}</CustomText>
          <CustomText typography="body3" color={colors.gray80}>
            {`${createdBy} on ${moment(createdAt).format('DD/MM/YYYY HH:mm')}`}
          </CustomText>
        </TextWrapper>
      </FormWrapper>
    </InfoModalWrapper>
  )
}

export default GroupInfo

const InfoModalWrapper = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 10;
`
