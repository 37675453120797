import { Component } from 'react'

import DisconnectionAlert from 'components/dropdown-alert/DisconnectionAlert'

class DropdownSocket extends Component {
  static ref: DropdownSocket
  state = { visible: false }

  show = () => {
    if (!this.state.visible) {
      this.setState({ visible: true })
    }
  }

  hide = () => {
    this.setState({ visible: false })
  }

  render() {
    const { visible } = this.state
    return <DisconnectionAlert visible={visible} />
  }
}

export default DropdownSocket
