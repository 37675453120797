import tr from 'date-fns/locale/tr' // the locale you want
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { registerLocale } from 'react-datepicker'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { FORM, PLACEHOLDERS } from 'locales/locales'

import CustomText from 'components/text/CustomText'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

registerLocale('tr', tr) // register it with the name you want
interface IAddMeetingProps {
  meetingName: string
  meetingDescription: string
  meetingTime: FixMeLater
  duration: string
  onChangeInput: (value: string | boolean | Date, key: string) => void
}
const CreateMeetingForm = ({
  meetingName,
  meetingDescription,
  meetingTime,
  duration,
  onChangeInput,
}: IAddMeetingProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <CustomFormInput
        type={'text'}
        title={t(FORM.MEETING_NAME)}
        placeholder={t(PLACEHOLDERS.MEETING_NAME)}
        value={meetingName}
        onChange={(value: string) => onChangeInput(value, 'meetingName')}
      />
      <CustomFormInput
        type={'text'}
        title={t(FORM.MEETING_DESCRIPTION)}
        placeholder={t(FORM.MEETING_DESCRIPTION)}
        value={meetingDescription}
        onChange={(value: string) => onChangeInput(value, 'meetingDescription')}
      />
      <div
        style={{
          width: '100%',
          height: '4rem',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
        }}
      >
        <CustomText typography="header3">{t(FORM.MEETING_TIME)}</CustomText>
        <DatePicker
          locale={'tr'}
          wrapperClassName="datePicker"
          timeIntervals={15}
          showTimeSelect
          dateFormat="Pp"
          selected={new Date(meetingTime)}
          onChange={(e) => onChangeInput(e as Date, 'meetingTime')}
        />
      </div>
      <CustomFormInput
        type={'number'}
        title={t(FORM.MEETING_DURATION)}
        placeholder={t(FORM.MEETING_DURATION)}
        value={duration}
        onChange={(newValue) => {
          const value = Math.min(480, newValue || 480);
          onChangeInput(value.toString(), 'duration');
        }}
        
      />
    </Wrapper>
  )
}

export default CreateMeetingForm

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: visible;
  .datePicker {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.light80};
    border-radius: 8px;
    height: 32px;
    padding: 0px 8px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`
