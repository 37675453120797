import { useMemo } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'

import { IRoom, RoomData, RoomTypes } from 'types/rooms.types'
import { useAppSelector } from 'app/hooks'

import Room from './Room'
import CustomText from 'components/text/CustomText'
import Separator from 'components/separator/Separator'
import colors from 'utils/colors'

interface IConversationListProps {
  roomsBySearch: RoomData | undefined | string
  onHideConversationPanel?: () => void
}

const ConversationList = ({ roomsBySearch, onHideConversationPanel }: IConversationListProps) => {
  const navigate = useNavigate()
  const { roomId = '' } = useParams()

  const myId = useAppSelector((state) => state.auth.id)
  const otrEnabled = useAppSelector((state) => state.system.data.features?.otrEnabled)
  const rooms: IRoom[] = useAppSelector((state) => state.rooms.data)
  const otrRooms = useMemo(() => rooms.filter((room) => room?.isVanishMode), [rooms])

  const regularRooms = rooms.filter((room) => !room?.isVanishMode)

  const onNavigateByRoomId = async (directRoomId: string) => {
    navigate(directRoomId)
  }

  const mappingRooms = roomsBySearch || regularRooms
  if (typeof mappingRooms === 'string') {
    return (
      <NoResultText typography="header3" textAlign="center" color={colors.white}>
        {mappingRooms}
      </NoResultText>
    )
  }

  return (
    <Wrapper>
      {otrEnabled && (
        <>
          {otrRooms?.map((room) => (
            <Room
              key={room._id}
              isAppear
              isSearch={!!roomsBySearch}
              myId={myId}
              roomId={room._id}
              currentRoomId={roomId}
              roomType={room.type}
              roomOwner={room.userData ? room.userData[room.createdByUserId] : undefined}
              invitedUsers={room.invitedUsers}
              members={(room.type === RoomTypes.OTR ? room.invitedUsers : room.members) || []}
              users={room.userData || {}}
              isVanishMode={room.isVanishMode}
              onNavigate={onNavigateByRoomId}
              onHideConversationPanel={onHideConversationPanel}
            />
          ))}
          {otrRooms?.length !== 0 && (
            <Separator margin={{ top: 20, bottom: 10, left: 16, right: 16 }} />
          )}
        </>
      )}
      {mappingRooms?.map((room) => (
        <Room
          key={room._id}
          myId={myId}
          isAppear={room?.isAppear}
          notificationCount={room?.notificationCount}
          isSearch={!!roomsBySearch}
          roomId={room._id}
          currentRoomId={roomId}
          roomType={room.type}
          members={room.members}
          users={room.userData || {}}
          onNavigate={onNavigateByRoomId}
          onHideConversationPanel={onHideConversationPanel}
        />
      ))}
    </Wrapper>
  )
}

export default ConversationList

const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px 0px 16px 0px;
`

const NoResultText = styled(CustomText)`
  margin-top: 20px;
`
