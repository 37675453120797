import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en/en.json'
import tr from './tr/tr.json'

import 'moment/locale/tr'
import moment from 'moment'

export const resources = {
  en: {
    translation: en,
  },
  tr: {
    translation: tr,
  },
}

moment.locale(navigator.language || 'tr-TR')

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: () => navigator.language || 'tr-TR',
  resources,
  returnNull: false,
})

export default i18n
