import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { LDAP_MANAGEMENT, TITLES } from 'locales/locales'
import {
  useLazyGetLdapSyncServerListQuery,
  useCreateLdapServerWithConferenceMutation,
  useCreateLdapGroupWithConferenceMutation, useSendLdapSyncLogMutation,

} from 'app/services/system.service'

import SettingsHeader from 'features/settings/SettingsHeader'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import { useAppSelector } from '../../../../app/hooks'

interface LdapSyncLogItem {
  _id: string;
  type: string;
  username: string;
  email: string;
  details: {
    action: string;
    syncDate: string | number | Date;
  };
  createdAt: string | number | Date;
}

interface ILogData {
  type: string;
  username: string;
  email: string;
  details: {
    action: string;
    syncDate: Date;
  };
  createdAt: Date;
}

const LdapManagement = () => {
  const users = useAppSelector((state) => state.users.data)
  const myId = useAppSelector((state) => state.auth.id)
  const { t } = useTranslation()
  const [sendLdapSyncLog] = useSendLdapSyncLogMutation()
  const [getLdapSyncList] = useLazyGetLdapSyncServerListQuery()
  const [createLdapServerWithConference] = useCreateLdapServerWithConferenceMutation()
  const [createLdapGroupWithConference] = useCreateLdapGroupWithConferenceMutation()

  const [loading, setLoading] = useState(false)
  const [currentData, setCurrentData] = useState<LdapSyncLogItem[]>([])
  useEffect(() => {
    fetchLdapSync()
  }, [])


  const updateGridData = () => {
    setCurrentData(currentData => [...currentData]);
  }


  const createAndSendLog = (action: string, syncType: string) => {
    const logData: ILogData = {
      type: syncType,
      username: users?.[myId]?.fullname,
      email: users?.[myId]?.email,
      details: {
        action: action,
        syncDate: new Date(),
      },
      createdAt: new Date(),
    }

    sendLdapSyncLog(logData)
    updateGridData();
  }

  const fetchLdapSync = async () => {
    try {
      const response = await getLdapSyncList({}).unwrap()
      if (response && response.length > 0) {
        const formattedData: LdapSyncLogItem[] = response.map((item: LdapSyncLogItem) => ({
          ...item,
          createdAt: new Date(item.createdAt),
          details: {
            ...item.details,
            syncDate: new Date(item.details.syncDate),
          },
        }))
        setCurrentData(formattedData.reverse());
      }
    } catch (e) {
      console.warn('ERROR', e);
    }
  }
  const handleCreateLdapServerWithConference = async () => {
    setLoading(true)
    try {
      await createLdapServerWithConference({ });
      createAndSendLog('update', 'user')
      await fetchLdapSync();
    } catch (error) {
     // console.error('LDAP Server senkronizasyonu hatası:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleCreateLdapGroupWithConference = async () => {
    setLoading(true)
    try {
      await createLdapGroupWithConference({ });
      createAndSendLog('update', 'group')
      await fetchLdapSync();
    } catch (error) {
      console.error('LDAP Server senkronizasyonu hatası:', error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Wrapper>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
          <div className="spinner-text">{t(LDAP_MANAGEMENT.UPDATING_LDAP)}</div>
        </div>
      )}
      <TopContainer>
        <SettingsHeader title={t(TITLES.LDAP_SETTINGS_TITLE)} />
        <ButtonContainer>
          <LdapButton onClick={handleCreateLdapServerWithConference}>{t(LDAP_MANAGEMENT.ADD_UPDATE_LDAP_USERS)}</LdapButton>
          <LdapButton  onClick={() => {if (currentData && currentData.length > 0) {handleCreateLdapGroupWithConference()}}} disabled={!currentData || currentData.length === 0}>{t(LDAP_MANAGEMENT.ADD_UPDATE_LDAP_GROUP)}</LdapButton>
        </ButtonContainer>
        {/* <CustomButton
          textColor="white"
          onClick={() => setIsCreateModalVisible(true)}
          text={t(LDAP_MANAGEMENT.NEW_REGISTRY)}
        /> */}
      </TopContainer>

      <Grid data={currentData} style={{ height: '700px' }}>
        <GridColumn field="_id" title={t(LDAP_MANAGEMENT.ID)} />
        <GridColumn
          field="type"
          title={t(LDAP_MANAGEMENT.TYPE)}
          cell={(props) => (
            <td>
              {props.dataItem.type === 'group' ? t(LDAP_MANAGEMENT.GROUP_SYNC) : t(LDAP_MANAGEMENT.USER_SYNC)}
            </td>
          )}
        />
        <GridColumn field="username" title={t(LDAP_MANAGEMENT.USER_NAME)} />
        <GridColumn field="email" title={t(LDAP_MANAGEMENT.EMAIL)} />
        <GridColumn
          field="action"
          title={t(LDAP_MANAGEMENT.ACTION)}
          cell={(props) => (
            <td>
              {props.dataItem.action === 'update' ? t(LDAP_MANAGEMENT.UPDATE_SYNC) : t(LDAP_MANAGEMENT.CREATE_SYNC)}
            </td>
          )}
        />
        <GridColumn field="createdAt" title={t(LDAP_MANAGEMENT.SYNCHRONIZATION_DATE)} format="{0:yyyy-MM-dd HH:mm:ss}" />
      </Grid>

    </Wrapper>
  )
}

export default LdapManagement
export const Wrapper = styled.div`
    padding: 0px 30px;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`
export const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`
const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: flex-start;
`

const LdapButton = styled.button`
    background-color: #fff;
    color: #1c1c1c;
    padding: 13px 20px;
    font-weight: 600;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    transition: box-shadow 0.3s ease;

    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;
