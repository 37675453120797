import { createSlice } from '@reduxjs/toolkit'

const initialHashState = {
    clientId:"",
}

export const { actions: hashActions, reducer: hashReducer } = createSlice({
    name: 'hash',
    initialState: initialHashState,
    reducers: {
      updateHashClientId: (state, action) => {
        state.clientId = action.payload
      },
    },
  })
  