import { useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from 'app/hooks'
import styled from 'styled-components'

import { FixMeLater } from 'types'
import { EMPTY, NAVIGATION } from 'locales/locales'
import useChannel from 'utils/hooks/useChannel'
import { IChannel, IGroup, TGroupData } from 'types/group.types'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { useLazyGetChannelsQuery, useLazySearchChannelsQuery } from 'app/services/group.service'
import { MOBILE_WIDTH } from 'utils/constants'

import ConversationList from './conversation-list/ConversationList'
import CreateChannelModal from './create-channel/CreateChannelModal'
import ConversationPanel from 'components/conversation-panel/ConversationPanel'
import GroupInfoModal from './group-info/GroupInfoModal'

const Channels = () => {
  const { t } = useTranslation()
  const { channelId = '' } = useParams()
  const { screenWidth } = useWindowDimensions()
  const [searchChannels] = useLazySearchChannelsQuery()
  const [getChannels]: FixMeLater = useLazyGetChannelsQuery()
  
  const navigate = useNavigate()

  const channel = useChannel({ id: channelId })

  const [groupId, setGroupId] = useState('')
  const [visiblePanel, setVisiblePanel] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [visibleCreateChannel, setVisibleCreateChannel] = useState(false)
  const [visibleGroupInfo, setVisibleGroupInfo] = useState(false)
  const [channelsBySearch, setChannelsBySearch] = useState<TGroupData | string | undefined>()
  const groups = useAppSelector((state) => state.groups.data)
  const emptyChannelPanel = groups.length === 0

  useEffect(() => {
    getChannels()
  }, [])
  
  useEffect(() => {
    if (screenWidth > MOBILE_WIDTH && visiblePanel && !emptyChannelPanel) {
      onToggleConversationPanel()
    } else if (emptyChannelPanel) {
      setVisiblePanel(true)
    }
  }, [screenWidth, emptyChannelPanel])

  useEffect(() => {
    if (isSearching) {
      navigate(`/channels/${channel._id}`)
      return
    }
    if (_isEmpty(channel) && channelId) {
      const myGeneralGroup = groups.find((group: IGroup) =>
        group.channels.some((channel) => channel.name === 'General' && !channel.isArchived),
      )
      if (myGeneralGroup) {
        myGeneralGroup.channels.forEach((channel: IChannel) => {
          if (channel.name === 'General' && !channel.isArchived) {
            navigate(`/channels/${channel._id}`)
          }
        })
      } else {
        navigate(`/channels`)
      }
    }
  }, [channel])

  const onChangeSearchText = async (searchText: string) => {
    try {
      const result: FixMeLater = await searchChannels({ searchText })
      if (searchText.length > 0) {
        setIsSearching(true)
        setChannelsBySearch(
          result?.data?.length !== 0 ? result?.data : t(EMPTY.EMPTY_NO_RESULTS),
        )
      } else {
        setIsSearching(false)
        setChannelsBySearch(undefined)
      }
    } catch {
      setChannelsBySearch(t(EMPTY.EMPTY_NO_RESULTS))
    }
  }

  const onCreateChannel = (groupId: string) => {
    setGroupId(groupId)
    setVisibleCreateChannel(true)
  }

  const onCloseNewChannelModal = () => {
    setVisibleCreateChannel(false)
  }

  const onOpenGroupInfo = (groupId: string) => {
    setGroupId(groupId)
    setVisibleGroupInfo(true)
  }

  const onCloseGroupInfo = () => {
    setVisibleGroupInfo(false)
  }

  const onToggleConversationPanel = () => {
    setVisiblePanel((prev) => !prev)
  }

  const Content = useMemo(() => {
    return <Outlet context={{ onToggleConversationPanel, groupId, channelId, channel }} />
  }, [groupId, channelId, channel])

  return (
    <Wrapper>
      {visibleCreateChannel && (
        <CreateChannelModal groupId={groupId} onClose={onCloseNewChannelModal} />
      )}
      {visibleGroupInfo && <GroupInfoModal groupId={groupId} onClose={onCloseGroupInfo} />}
      <ConversationPanel
        title={t(NAVIGATION.TEAMS)}
        isGroup
        emptyChannelPanel={emptyChannelPanel}
        visible={visiblePanel}
        onChangeSearch={onChangeSearchText}
        onHideConversationPanel={onToggleConversationPanel}
        content={
          <ConversationList
            isSearching={isSearching}
            onHideConversationPanel={onToggleConversationPanel}
            channelsBySearch={channelsBySearch}
            onCreateChannel={onCreateChannel}
            onOpenGroupInfo={onOpenGroupInfo}
          />
        }
      />
      {Content}
    </Wrapper>
  )
}

export default Channels

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`
