import styled from 'styled-components'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import { CustomButtontypographyKey } from './cbTypography'
import { CustomTextTypographyKey } from 'components/text/customTextTypography'

import CustomIcon, { IconSourceType } from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import FilePicker from './FilePicker'

type IconAlignment = 'left' | 'right'

type ButtonType = 'primary' | 'secondary' | 'danger'

type ButtonSize = 'small' | 'medium' | 'large'

interface ICustomButtonProps {
  text: string
  typography?: CustomTextTypographyKey
  textColor?: string
  icon?: IconSourceType
  iconAlignment?: IconAlignment
  type?: ButtonType
  size?: ButtonSize
  disabled?: boolean
  className?: string
  isFilePicker?: boolean
  getInputProps?: FixMeLater
  onClick?: () => void
}

const CustomButton = ({
  text,
  typography = 'header2',
  textColor = colors.white,
  icon,
  iconAlignment = 'left',
  type = 'primary',
  size = 'medium',
  disabled = false,
  className,
  isFilePicker,
  getInputProps,
  onClick,
}: ICustomButtonProps) => {
  let typeTypography
  let sizeProp

  if (type === 'secondary') textColor = 'black'
  if (type === 'primary') typeTypography = CustomButtontypographyKey.primary
  if (type === 'secondary') typeTypography = CustomButtontypographyKey.secondary
  if (type === 'danger') typeTypography = CustomButtontypographyKey.danger

  if (size === 'small') sizeProp = CustomButtontypographyKey.small
  if (size === 'medium') sizeProp = CustomButtontypographyKey.medium
  if (size === 'large') sizeProp = CustomButtontypographyKey.large
  return (
    <ButtonWrapper
      className={className}
      size={sizeProp}
      disabled={disabled}
      onClick={onClick}
      type={typeTypography}
      hasIcon={icon != undefined}
    >
      {iconAlignment == 'left' && icon && <CustomIcon source={icon} iconPreset="medium" />}
      {typography && (
        <ResponsiveCustomText
          isEmpty={text.length === 0}
          color={textColor}
          className="button-text"
          typography={typography}
          cursor="pointer"
        >
          {text}
        </ResponsiveCustomText>
      )}
      {iconAlignment == 'right' && icon && <CustomIcon source={icon} iconPreset="medium" />}
      {isFilePicker && <FilePicker getInputProps={getInputProps} />}
    </ButtonWrapper>
  )
}

export default CustomButton

const ResponsiveCustomText = styled(CustomText).attrs((props: { isEmpty?: boolean }) => props)`
  @media only screen and (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    display: ${({ isEmpty }) => (isEmpty ? 'none' : 'block')};
  }
`
const ButtonWrapper = styled.button.attrs((props: FixMeLater) => props)`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  gap: 13px;
  box-shadow: 0px 2px 5px ${colors.gray60};
  border-radius: 4px;
  width: ${(props) => props.size.width};
  padding: ${(props) => (props.hasIcon ? props.size.withIconPadding : props.size.padding)};
  height: ${(props) => props.size.height};
  background-color: ${(props) => props.type.backgroundColor};
  color: ${(props) => props.type.backgroundColor};
  &:hover {
    background-color: ${(props) => props.type.hoverBackgroundColor};
  }
  &:focus-visible {
    // Yalnızca tab index için focus olursa.
    outline: 1px solid ${colors.vime};
    outline-offset: 2px;
  }
  &:disabled {
    color: ${(props) => props.type.disabledColor};
    opacity: 0.5;
    cursor: default;
    pointer-events: none;
  }
`
