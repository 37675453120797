import { createSlice } from '@reduxjs/toolkit'

import { IOtr } from 'types/otr.types'
import { MessageStatus } from 'types/conversation.types'

const initialOtrState: IOtr = {}

export const { actions: otrActions, reducer: otrReducer } = createSlice({
  name: 'otr',
  initialState: initialOtrState,
  reducers: {
    createOtrRoom: (state, { payload: { roomId, requestSentAt } }) => {
      if (!state[roomId]) {
        state[roomId] = { requestSentAt, roomId, messages: [] }
      }
    },
    deleteOtrRoom: (state, { payload: { roomId } }) => {
      if (state[roomId]) {
        delete state[roomId]
      }
    },
    sendOtrMessage: (state, { payload }) => {
      const messageIndex = state[payload.roomId]?.messages?.findIndex(
        (message) => message.sentAt === payload.sentAt && message.status === MessageStatus.PENDING,
      )
      if (messageIndex === -1) {
        state[payload.roomId].messages.unshift(payload)
      } else {
        state[payload.roomId].messages[messageIndex] = payload
      }
    },
  },
})
