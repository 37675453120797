import { configureStore } from '@reduxjs/toolkit'
import { getPersistConfig } from 'redux-deep-persist'
import { persistReducer, persistStore } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import api from 'app/api'
import rootReducer from './rootReducer'
import errorHandlerMiddleware from './middlewares/error.middleware'
import storage from './storage'
// import { encryptTransform } from 'redux-persist-transform-encrypt'
// import createCompressor from 'redux-persist-transform-compress'

// const compressor = createCompressor()

const persistConfig = getPersistConfig({
  key: 'root',
  version: 1,
  storage: storage('vimesoft'),
  blacklist: ['app', 'conference', 'upload', 'message.data', 'message.renderedRooms'],
  // transforms: [compressor],
  rootReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(api.middleware)
      .concat(errorHandlerMiddleware),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
