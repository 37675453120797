import { memo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useGetRoomInfoMutation } from 'app/services/room.service'

import Header from './Header'
import General from './General'
import Files from 'components/file/files/Files'
import Members from 'components/members/Members'
import CustomModal from 'components/modals/CustomModal'
import { TABS } from 'locales/locales'
import { RoomTypes } from 'types/rooms.types'
import { useGetTeamsMutation, useLazyGetUserGroupsAndChannelsWithMembersInfoQuery } from 'app/services/group.service'
import { FixMeLater } from 'types'
import { GroupPanel } from 'components/group/GroupPanel'
import { UserStatus } from 'types/user.types'
import { useAppSelector } from 'app/hooks'


interface IConversationInfoProps {
  roomId: string
  type: string
  roomName?: string
  isMyRoom: boolean
  onClose: () => void
}


interface Group {
  name: string;
  imageUrl?: string;
  backgroundColor?: string;
  members?: Member[];
}

interface Member {
  userId: string;
  fullname: string;
  status?: UserStatus;  
  imageUrl?: string;
}const ConversationInfo = ({
  isMyRoom,
  roomId,
  type,
  roomName,
  onClose,
}: IConversationInfoProps) => {
  const { t } = useTranslation()
  const [getRoomInfo, { data }] = useGetRoomInfoMutation()
  const [isLoading, setIsLoading] = useState(true)
  const [teams, setTeams] = useState([])
  const [getTeams] = useGetTeamsMutation()
  const [groups, setGroups] = useState<Group[]>([]);
  const [triggerGetGroups, { data: groupData, error }] = useLazyGetUserGroupsAndChannelsWithMembersInfoQuery();
  const groupMembersEnabled = useAppSelector(state => state.system.data.features?.isGroupMembersEnabled);
  
  const fetchTeams = async () => {
    try {
      const departments = (await getTeams({})) as FixMeLater
      setTeams(departments.data.data)
    } catch (err) {
     // console.log(err)
    }
  }
  const fetchRoomInfo = async () => {
    try {
      await getRoomInfo({ roomId })
    } catch (e) {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    const fetchInitialData = async () => {
      await fetchRoomInfo(); 
      await fetchTeams();    
    };
  
    fetchInitialData();
  }, [roomId]);

  useEffect(() => {
    if (data?.general?._id && typeof data?.general?._id === 'string' && groupMembersEnabled) {
      triggerGetGroups(data.general._id);
    }
  }, [isMyRoom, data?.general?._id, triggerGetGroups, groupMembersEnabled]);

  useEffect(() => {
    if (groupData && groupData.success) {
      setGroups(groupData.data);
    }
  }, [groupData]);

  const tab = type === RoomTypes.GM
    ? [{ title: t(TABS.GENERAL) }, { title: t(TABS.MEMBERS) }, { title: t(TABS.FILES) }]
    : [{ title: t(TABS.GENERAL) }, { title: t(TABS.FILES) }];

  if (groupMembersEnabled) {
    tab.push({ title: t(TABS.USERS_GROUPS) });
  }

  const team = teams.find((team: FixMeLater) => team?.value === data?.general.department) as any;

  return (
    <CustomModal
      header={
        <Header type={type} {...data?.general} team={team?.title || ''} groupName={roomName} />
      }
      closeModal={onClose}
      tab={tab}
      showButtons={false}
      loading={isLoading}
      hasMember
    >
      <General
        {...data?.general}
        type={type}
        roomId={roomId}
        isMyRoom={isMyRoom}
        onClose={onClose}
      />
      {type === RoomTypes.GM && <Members data={data?.general?.members} />}
      <Files data={data?.files} />
      {groupMembersEnabled && <GroupPanel groups={groups} userId={data?.general?._id} />}
    </CustomModal>
  )
}

export default memo(ConversationInfo);
