import { useEffect, useState } from 'react'

type WindowDimensions = {
  screenWidth: number
  screenHeight: number
}

function getWindowDimensions() {
  const { innerWidth: screenWidth, innerHeight: screenHeight } = window
  return {
    screenWidth,
    screenHeight,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
