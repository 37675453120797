import { IUser, IUserData } from 'types/user.types'
export interface IRoom {
  _id: string
  members: Array<string>
  pins: Array<string>
  type: RoomTypes
  isPrivate: boolean
  isVanishMode: boolean
  description: string
  createdByUserId: string
  createdAt: string
  unreadCount: number
  notificationCount: number
  isDeleted: boolean
  isAppear: boolean
  invitedUsers?: Array<string>
  acceptingUsers?: Array<string>
  rejectingUsers?: Array<string>
  users: Array<IUser>
  userData: IUserData
}

export type RoomData = Array<IRoom>
export type TRoomMemberForAdmin = Array<IUser>
export interface IRoomForAdmin {
  _id: string
  name: string
  type: string
  groupId: string
  description: string
  isPrivate: boolean
  isDeleted: boolean
  isArchived: boolean
  isAppear: boolean
  createdAt: string
  user: {
    fullname: string
  }
}

export interface IRoomStatisticForAdmin {
  totalUser: number
  totalFiles: number
  totalMessages: number
  totalVideoCall: number
  totalAudioCall: number
}

export enum RoomTypes {
  GM = 'GM',
  DM = 'DM',
  CM = 'CM',
  OTR = 'OTR',
}
