/* eslint-disable no-empty-pattern */
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from 'app/hooks'

import { FixMeLater } from 'types'
import { otrActions } from 'app/slices/otr.slice'
import { OTR_REQUESTED_TEXT } from 'utils/constants'
import { roomsActions } from 'app/slices/rooms.slice'
import { BUTTONS, INFORMATION, MODALS } from 'locales/locales'
import { useSendOtrResponseMutation } from 'app/services/otrService'

import InfoModal from 'components/info-modal/InfoModal'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'

interface IUseVanishModeProps {
  otrRooms: FixMeLater
  currentRoomId: string
  currentUsersRoomId: string
}

export default function useOffTheRecord({
  otrRooms,
  currentRoomId = '',
  currentUsersRoomId,
}: IUseVanishModeProps) {
  const [otrRequestModal, setOtrRequestModal] = useState({
    visible: false,
    description: '',
  })

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [sendOtrResponse] = useSendOtrResponseMutation()

  const onCloseModal = () => {
    setOtrRequestModal({ ...otrRequestModal, visible: false })
    navigate(`/messages/${currentUsersRoomId}`)
  }

  const onOtrRequest = (creator: string) => {
    setOtrRequestModal({
      visible: true,
      description: OTR_REQUESTED_TEXT(creator, otrRooms[currentRoomId]?.potentialMembers),
    })
  }

  const onApprove = () => {
    setOtrRequestModal({ ...otrRequestModal, visible: false })
    DropdownAlert.ref.show({ message: t(INFORMATION.OTR_APPROVED_ALERT) })
    sendOtrResponse({ response: true, roomId: currentRoomId })
  }

  const onReject = () => {
    setOtrRequestModal({ ...otrRequestModal, visible: false })
    navigate(`/messages/${currentUsersRoomId}`)
    dispatch(otrActions.deleteOtrRoom({ roomId: currentRoomId }))
    dispatch(roomsActions.removeConversation({ _id: currentRoomId }))
    sendOtrResponse({ response: false, roomId: currentRoomId })
  }

  return {
    onOtrRequest,
    setOtrRequestModal,
    OtrRequestModal: InfoModal({
      closeModal: onCloseModal,
      visible: otrRequestModal.visible,
      description: otrRequestModal.description,
      title: t(MODALS.OTR_REQUEST_TITLE),
      okTitle: t(BUTTONS.APPROVE) as string,
      cancelTitle: t(BUTTONS.REJECT) as string,
      onOk: onApprove,
      onCancel: onReject,
    }),
  }
}
