import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FixMeLater } from 'types'
import { useAppSelector } from 'app/hooks'
import useInfoModal from 'utils/hooks/useInfoModal'
import { getObjectDiff } from 'utils/helpers/objectDiffHelper'
import { BUTTONS, ERRORS, MODALS, TABS } from 'locales/locales'
import { useLazyGetUserByIdQuery, useUpdateUserByIdMutation } from 'app/services/user.service'

import UserInfo from './UserInfoForm'
import CustomModal from 'components/modals/CustomModal'
import UserStatistic from 'components/user-statistic/UserStatistics'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'

interface IUserInfoProps {
  userId?: string
  teams?: FixMeLater
  onClose: () => void
  fetchUsers: () => void
}
const initialUserState: {
  [key: string]: FixMeLater
} = {
  avatar: '',
  fullname: '',
  email: '',
  title: '',
  team: '',
  isDeleted: false,
  isAdmin: false,
}
const statisticDataInitial = {
  totalChannels: 0,
  totalMessages: 0,
  totalFiles: 0,
  totalVideoCall: 0,
}
const UserInfoModal = ({ userId, teams = [], onClose, fetchUsers }: IUserInfoProps) => {
  const { t } = useTranslation()
  const { openModal, closeModal, Modal } = useInfoModal()
  const [formChecked, setFormChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentUserData, setCurrentUserData] = useState({})

  const [selectedOption, setSelectedOption] = useState({})
  const [selectedUserData, setSelectedUserData] = useState(initialUserState)
  const [userStatisticData, setUserStatisticData] = useState(statisticDataInitial)

  const [updateUser] = useUpdateUserByIdMutation()
  const [getUserById] = useLazyGetUserByIdQuery()

  useEffect(() => {
    fetchChannelInfo()
  }, [])

  const fetchChannelInfo = async () => {
    try {
      const result: FixMeLater = await getUserById({ id: userId })
      setSelectedUserData(result.data.data.info)
      setCurrentUserData(result.data.data.info)
      setUserStatisticData(result.data.data.statistic)

      setSelectedOption(
        teams.find((team: FixMeLater) => team.value === result.data.data.info.teamId),
      )
    } catch {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }
  const onConfirmOk = async () => {
    setFormChecked(true)
    GenerateDiffObject.then(async (value) => {
      try {
        setIsLoading(true)
        await updateUser({ _id: userId, params: value })
        fetchUsers()
      } finally {
        setFormChecked(false)
        setIsLoading(false)
      }
    })
      .catch((error) => {
        DropdownAlert.ref.show({ isError: true, message: t(ERRORS.THERE_WAS_NO_CHANGES) || error })
      })
      .finally(() => handleClose())
  }

  const GenerateDiffObject = new Promise(function (myResolve, myReject) {
    try {
      let data = {}
      const diffrence = getObjectDiff(currentUserData, selectedUserData)
      if (diffrence.length != 0) {
        diffrence.map((key: string) => {
          data = { ...data, [key]: selectedUserData[key].value || selectedUserData[key] }
        })
        myResolve(data)
      }
    } catch (error) {
      myReject(error)
    }
  })
  const onConfirmCancel = () => {
    closeModal()
  }

  const onOk = () => {
    openModal({
      title: t(MODALS.UPDATE_USER_TITLE),
      description: t(MODALS.UPDATE_USER_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL),
      okTitle: t(BUTTONS.YES),
      onCancel: onConfirmCancel,
      onOk: onConfirmOk,
    })
  }

  const onChangeInput = (value: string | boolean, key: string) => {
    if (key === 'teamId') setSelectedOption(value)
    setSelectedUserData({ ...selectedUserData, [key]: value })
  }

  const handleClose = () => {
    onClose()
    setSelectedUserData(initialUserState)
    closeModal()
  }
  return (
    <>
      <CustomModal
        closeModal={onClose}
        onCancel={onClose}
        onOk={onOk}
        title={t(MODALS.USER_INFO_TITLE)}
        tab={[{ title: t(TABS.GENERAL), action: true }, { title: t(TABS.STATISTIC) }]}
        showButtons={false}
        loading={isLoading}

      >
        <UserInfo
          id={selectedUserData._id}
          avatar={selectedUserData.avatar}
          fullname={selectedUserData.fullname}
          email={selectedUserData.email}
          title={selectedUserData.title}
          team={selectedUserData.team}
          formChecked={formChecked}
          isDeleted={selectedUserData.isDeleted}
          dropDownData={teams}
          selectedOption={selectedOption}
          onChange={onChangeInput}
        />
        <UserStatistic {...userStatisticData} />
      </CustomModal>
      {Modal}
    </>
  )
}

export default UserInfoModal
