import { memo } from 'react'
import styled from 'styled-components'
import _isEqual from 'lodash/isEqual'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { IUserData } from 'types/user.types'
import { IDraftData } from 'types/conversation.types'
import { MOBILE_WIDTH, SIDE_PANEL_THREAD } from 'utils/constants'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import { useTranslation } from 'react-i18next'
import { BUTTONS, MESSAGES } from 'locales/locales'
import { useAppSelector } from 'app/hooks'

interface IThreadDraftInfoProps {
  messageId: string
  users: IUserData
  data: Array<string>
  draftData?: IDraftData
  threadCount?: number
  onReplyMessage?: (visible: boolean, type: string, params?: { messageId: string }) => void
}
const ThreadDraftInfo = ({
  messageId,
  users,
  data,
  draftData,
  threadCount,
  onReplyMessage,
}: IThreadDraftInfoProps) => {
  const auth = useAppSelector((state) => state.auth)
  const { t } = useTranslation()

  const getRepliedUsers = () => {
    return data?.map((userId: string) => {
      
      if (userId === auth.user._id) { // a hack to fix userData
        return {
          id: auth.user._id,
          avatar: auth.user.avatar,
          fullname: auth.user.fullname,
        }
      }

      return {
        id: users[userId]?._id ?? '',
        avatar: users[userId]?.avatar ?? '',
        fullname: users[userId]?.fullname ?? '',
      }
    })
  }

  const getThreadAndDraftText = () => {
    const draftText = draftData?.[messageId]
      ? `${threadCount ? ' & ' : ''}  ${t(MESSAGES.DRAFT)}`
      : ''
    const threadText = threadCount
      ? `${threadCount} ${threadCount === 1 ? t(MESSAGES.REPLY) : t(MESSAGES.REPLIES)}`
      : ''
    return threadText + draftText
  }
  if (!data && !draftData?.[messageId]) {
    return null
  }

  const userInfo = getRepliedUsers()
  const threadAndDraftText = getThreadAndDraftText()

  return (
    <Wrapper onClick={() => onReplyMessage?.(true, SIDE_PANEL_THREAD, { messageId })}>
      <LeftWrapper>
        {userInfo && (
          <AvatarWrapper>
            {userInfo.length > 5
              ? userInfo
                  .slice(0, 5)
                  .map((user: { id: string; avatar?: string; fullname: string }) => (
                    <Avatar
                      key={user.id}
                      text={user.fullname}
                      source={user?.avatar}
                      size={AvatarSize.small}
                    />
                  ))
              : userInfo?.map((user: { id: string; avatar?: string; fullname: string }) => (
                  <Avatar
                    key={user.id}
                    text={user.fullname}
                    source={user?.avatar}
                    size={AvatarSize.small}
                  />
                ))}
            {userInfo.length > 5 && (
              <>
                <CustomText typography="body5" color={colors.vime}>
                  +{userInfo.length - 5} {t(BUTTONS.PARTICIPANT)}
                </CustomText>
              </>
            )}
          </AvatarWrapper>
        )}
        <CustomText typography="body5" color={colors.vime}>
          {threadAndDraftText}
        </CustomText>
        <CustomText typography="body8" color={colors.gray60}>
          {t(MESSAGES.VIEW_THREAD)}
        </CustomText>
      </LeftWrapper>
      <ArrowIconWrapper>
        <CustomIcon id="thread-arrow" iconPreset="small" source={icons.downArrow} />
      </ArrowIconWrapper>
    </Wrapper>
  )
}

function areEqual(prevProps: IThreadDraftInfoProps, nextProps: IThreadDraftInfoProps) {
  return (
    _isEqual(prevProps.data, nextProps.data) &&
    _isEqual(
      prevProps.draftData?.[prevProps?.messageId],
      nextProps.draftData?.[nextProps?.messageId],
    ) &&
    _isEqual(prevProps.threadCount, nextProps.threadCount)
  )
}

export default memo(ThreadDraftInfo, areEqual)

const Wrapper = styled.div`
  width: fit-content;
  @media (max-width: ${MOBILE_WIDTH}px) {
    width: 100%;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  padding: 4px;
  margin-top: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${colors.white};
    border-radius: 4px;
  }
  &:hover #thread-arrow {
    display: flex;
  }
  #thread-arrow {
    display: none;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  @media screen and (max-width: 560px) {
    flex-direction: column;
    align-items: start;
    row-gap: 4px;
  }
`

// const ViewThreadText = styled(CustomText)`
//   @media screen and (max-width: 560px){
//     display: none;
//   }
// `

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const ArrowIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 16px;
`
