import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { MODALS, NAVIGATION } from 'locales/locales'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import { useDispatch } from 'react-redux'
import { newsFeedActions } from 'app/slices/news-feed.slice'
import NotificationModal from 'components/modals/NotificationModal'
import { useAppSelector } from 'app/hooks'
import { useNavigate } from 'react-router-dom'

interface IHeader {
  openNotifications: boolean
  onJumpComment: () => void
  onCloseNotification: () => void
  onToggleNotification: () => void
  backButton?: boolean
}

const Header: React.FC<IHeader> = ({
  onJumpComment,
  onToggleNotification,
  onCloseNotification,
  openNotifications,
  backButton,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { realNotificationCount } = useAppSelector((state) => state.newsFeed)
  const navigate = useNavigate()

  return (
    <>
      <Wrapper>
        <Content backButton={backButton}>
          {openNotifications && (
            <NotificationModal title={t(MODALS.NOTIFICATION)} onJumpComment={onJumpComment} />
          )}
          <div style={{ display: 'flex', cursor: 'pointer', gap: '29px', height: '28px' }}>
            {backButton && (
              <span onClick={() => navigate('/news-feed')}>
                <CustomIcon source={icons.backwardIcon} />
              </span>
            )}
            <CustomText
              cursor="pointer"
              typography="header1"
              onClick={() => {
                dispatch(newsFeedActions.setScrollTop(true))
                dispatch(newsFeedActions.setIsRenderedBefore(false))
                navigate('/news-feed')
              }}
            >
              {t(NAVIGATION.NEWS_FEED)}
            </CustomText>
          </div>
          <BellIconWrapper>
            {realNotificationCount > 0 && (
              <NotificationWrapper>
                <NotificationCount>
                  {realNotificationCount > 99 ? '99+' : realNotificationCount}
                </NotificationCount>
              </NotificationWrapper>
            )}
            <CustomIcon
              width="20px"
              height="23px"
              source={icons.bellIcon}
              onClick={onToggleNotification}
            />
          </BellIconWrapper>
        </Content>
      </Wrapper>
      {openNotifications && <Backdrop onClick={onCloseNotification} />}
    </>
  )
}

export default Header

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: ${colors.white};
  border-bottom: 1px solid ${colors.light80};
  align-items: center;
  justify-content: center;
  padding: 0px 16px;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
const Content = styled.div.attrs(({ backButton }: { backButton?: boolean }) => backButton)`
  display: flex;
  height: 72px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: ${({ backButton }) => (backButton ? '800px' : '700px')};
  padding-right: ${({ backButton }) => (backButton ? '48px' : '0px')};
  @media only screen and (max-width: 800px) {
    padding-right: 0;
  }
`
const BellIconWrapper = styled.span`
  padding: 0px 5px;
  position: relative;
  z-index: 2;
`
const NotificationCount = styled.span`
  color: ${colors.white};
  font-size: 8px;
  font-weight: 600;
`
const NotificationWrapper = styled.span`
  background-color: ${colors.red};
  position: absolute;
  top: -5px;
  height: 19px;
  width: 19px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`
const Backdrop = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 88vh;
`
