import { useEffect } from 'react'
import { FixMeLater } from 'types'
import colors from 'utils/colors'

let prevMentionIndex: string | null = null

const useCommonInputObserver = ({ newsFeedInputRef, mentionsDropdownRef }: FixMeLater) => {
  useEffect(() => {
    let observer: MutationObserver | null
    if (newsFeedInputRef?.current) {
      const config = { attributes: true, childList: true, subtree: true }
      observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName === 'aria-activedescendant'
          ) {
            if (mentionsDropdownRef?.current) {
              const mentionIndex = newsFeedInputRef.current
                ?.getAttribute('aria-activedescendant')
                ?.split('-')[1] as string
              const mentions = [...mentionsDropdownRef.current.children] as HTMLElement[]
              const mention = mentions[+mentionIndex]
              if (prevMentionIndex) {
                const prevMention = mentions[+prevMentionIndex]
                if (prevMention) prevMention.style.backgroundColor = 'initial'
              }
              if (mention) {
                mention.style.backgroundColor = colors.light50
                mention.scrollIntoView({
                  behavior: 'smooth',
                  block: 'nearest',
                  inline: 'start',
                })
                prevMentionIndex = mentionIndex
              }
            }
          }
        })
      })
      observer.observe(newsFeedInputRef.current, config)
    }
    return () => {
      observer?.disconnect()
      observer = null
    }
  }, [newsFeedInputRef?.current])
}

export default useCommonInputObserver
