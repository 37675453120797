import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { IPost } from 'types/post.types'
import { IUser, IUserData } from 'types/user.types'
import { FixMeLater } from 'types'
import { BUTTONS, POST } from 'locales/locales'
import { icons } from 'utils/icons'
import { INewsFeedSlice } from 'app/slices/news-feed.slice'
import { useAppSelector } from 'app/hooks'
import colors from 'utils/colors'
import useEditPostInput from './useEditPostInput'

import CustomButton from 'components/button/CustomButton'
import Content from './EditPostContent'
import CustomModal from 'components/modals/CustomModal'
import { useState } from 'react'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import usePasteFile from '../../../utils/hooks/usePasteFile'
import { Switch } from '@progress/kendo-react-inputs'
import CustomText from 'components/text/CustomText'

interface IEditPostProps {
  post: IPost
  userInfo: IUser
  avatar: string
  fullname: string
  onClose: () => void
  onEditComment?: (prev?: FixMeLater) => void
}

const EditPostContent = ({ post, avatar, fullname, onClose, onEditComment }: IEditPostProps) => {
  
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()

  const { isPostLoading }: INewsFeedSlice = useAppSelector((state) => state.newsFeed)
  const [isAcceptPhoto, setAcceptPhoto] = useState(false)
  const [commentToggle, setCommentToggle] = useState(post?.commentToggle)

  const {
    editedContent,
    isValidEditPost,
    onEditPost,
    getInputProps,
    onEditEmojiClick,
    onChangeEditText,
    onCancelEditFile,
    onChangeHeaderInputData,
    onEditedContentFileChange,
    onChangeToggleComment,
  } = useEditPostInput({ post, isAcceptPhoto, onClose, onEditComment })

  const onChangeInputHeader = (e: FixMeLater) => {
    setHeader(e.value as string)
    onChangeHeaderInputData(e.value as string)
  }

  const onChangeCommentToggle = () => {
    setCommentToggle(!commentToggle)
    onChangeToggleComment(!commentToggle)
  }

  const [header, setHeader] = useState(editedContent.header)

  usePasteFile({ onChangeInputFileData: onEditedContentFileChange })

  return (
    <CustomModal
      okTitle={t(BUTTONS.EDIT)}
      onOk={onEditPost}
      closeModal={onClose}
      title={onEditComment ? t(POST.EDIT_COMMENT) : t(POST.EDIT_POST)}
      overflow={'visible'}
      size="large"
      extraButtons={
        onEditComment
          ? undefined
          : [
              <ActionButton
                key={'postphoto'}
                text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.PHOTO) : ''}
                typography="body4"
                icon={icons.imageIcon}
                textColor={colors.gray100}
                isFilePicker
                getInputProps={getInputProps}
                onClick={() => setAcceptPhoto(true)}
              />,
              <ActionButton
                key={'postvideo'}
                text={screenWidth > MOBILE_DEVICE_WIDTH ? t(POST.VIDEO) : ''}
                typography="body4"
                icon={icons.cameraIconFilled}
                textColor={colors.gray100}
                isFilePicker
                getInputProps={getInputProps}
                onClick={() => setAcceptPhoto(false)}
              />,
              <div
                onClick={onChangeCommentToggle}
                key={'comment'}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Switch
                  checked={commentToggle}
                  defaultChecked={commentToggle}
                  offLabel="0"
                  onLabel="I"
                  size={'small'}
                />
                <div style={{ marginLeft: '20px' }}>
                  <CustomText color={colors.gray100} cursor="pointer" typography="body4">
                    {screenWidth > MOBILE_DEVICE_WIDTH ? 'Yorumlar' : ''}
                  </CustomText>
                </div>
              </div>,
            ]
      }
    >
      <Content
        data={editedContent}
        placeHolder={t(POST.EDIT_CONTENT)}
        users={post.userData as IUserData}
        avatar={avatar}
        fullname={fullname}
        header={header}
        isComment={onEditComment ? true : false}
        commentToggle={post.commentToggle ?? true}
        onChangeInputHeader={onChangeInputHeader}
        isValidEditPost={isValidEditPost}
        isPostLoading={isPostLoading}
        onClose={onClose}
        onEditPost={onEditPost}
        onCancelFile={onCancelEditFile}
        onChangeText={onChangeEditText}
        onEmojiClick={onEditEmojiClick}
      />
    </CustomModal>
  )
}

export default EditPostContent

const ActionButton = styled(CustomButton)`
  box-shadow: none;
  height: 28px;
  gap: 8px;
  padding: 0px 20px;
  background-color: ${colors.white};
  &:hover {
    background-color: ${colors.light50};
  }
`
