import moment from 'moment'

import { icons } from 'utils/icons'
import { IPost } from 'types/post.types'
import { POST_DATE_FORMATS } from 'utils/constants'
import { t } from 'i18next'
import { POST } from 'locales/locales'

export const checkIfPostExists = (posts: IPost[], postId: string) => {
  return posts.some((post) => post._id === postId)
}

export const formatPostDate = (postDate: moment.Moment) => {
  const diffInDays = moment().endOf('day').diff(postDate, 'days')
  // if posted today, print 'Today at post.sentAt'
  // if posted yesterday, print 'Yesterday at post.sentAt'
  // otherwise print the exact date 'DD/MM/YYYY HH:MM'
  if (diffInDays === 0) {
    return t(POST.TODAY_AT).concat(postDate.format(POST_DATE_FORMATS.POSTED_TODAY))
  } else if (diffInDays === 1) {
    return t(POST.YESTERDAY_AT).concat(postDate.format(POST_DATE_FORMATS.POSTED_YESTERDAY))
  }
  return postDate.format(POST_DATE_FORMATS.POSTED_AT_LEAST_TWO_DAYS_AGO)
}

export const formatPostStatistic = (likeCount: number) => {
  const formatter = new Intl.NumberFormat(navigator.language, { maximumFractionDigits: 1 })
  if (likeCount < 1000) {
    return likeCount
  } else if (likeCount >= 1000 && likeCount < 1000 * 1000) {
    return formatter.format(likeCount / 1000) + 'k'
  } else if (likeCount >= 1000 * 1000) {
    return formatter.format(likeCount / (1000 * 1000)) + 'm'
  }
}

export const getThumbsDownIcon = (dislikes: string[], currentUserId: string) => {
  return dislikes?.includes(currentUserId) ? icons.thumbsDownIconActive : icons.thumbsDownIcon
}

export const getThumbsUpIcon = (likes: string[], currentUserId: string) => {
  return likes?.includes(currentUserId) ? icons.thumbsUpIconActive : icons.thumbsUpIcon
}

export const findLikeAndDislike = (isLike: boolean, post: IPost, myId: string) => {
  let likeData = []
  let dislikeData = []
  if (isLike) {
    if (post.likes.includes(myId)) {
      likeData = post.likes.filter((item: string) => item !== myId)
      dislikeData = [...post.dislikes]
    } else if (!post.likes.includes(myId) && post.dislikes.includes(myId)) {
      likeData = [...post.likes, myId]
      dislikeData = post.dislikes.filter((item: string) => item !== myId)
    } else {
      likeData = [...post.likes, myId]
      dislikeData = [...post.dislikes]
    }
  } else {
    if (post.dislikes.includes(myId)) {
      dislikeData = post.dislikes.filter((item: string) => item !== myId)
      likeData = [...post.likes]
    } else if (!post.dislikes.includes(myId) && post.likes.includes(myId)) {
      dislikeData = [...post.dislikes, myId]
      likeData = post.likes.filter((item: string) => item !== myId)
    } else {
      likeData = [...post.likes]
      dislikeData = [...post.dislikes, myId]
    }
  }

  return { likeData, dislikeData }
}
