import api from 'app/api'

export const groupService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getChannels: build.query({
        query: () => ({ url: 'group/list/channels' }),
      }),
      getGroupByIdForAdmin: build.query({
        query: ({ groupId }) => ({ url: `group/${groupId}` }),
      }),
      getGroupsForAdmin: build.query({
        query: ({ search, limit, sort, pageNumber, column, filter }) => ({
          url: `group/list?searchText=${search}&limit=${limit}&sort=${sort}&page=${pageNumber}&column=${column}&filter=${filter}`,
        }),
      }),

      getUserGroupsAndChannelsWithMembersInfo: build.query({
        query: (userId) => ({
          url: `group/list/groups/channels/with/members/info/${userId}`,
        }),
      }),

      getUserGroupsTags: build.query({
        query: (userId) => ({
          url: `group/list/groups/channels/with/tags/${userId}`,
        }),
      }),

      getGroupInfoByGroupId: build.query({
        query: ({ groupId }) => ({ url: `group/${groupId}` }),
      }),
      createGroup: build.mutation({
        query: (args) => ({ url: 'group', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_GROUP'],
      }),
      updateGroup: build.mutation({
        query: (args) => ({ url: `group/${args.id}`, method: 'PUT', body: args.data }),
        invalidatesTags: ['UPDATE_GROUP'],
      }),

      toggleTeamArchive: build.mutation({
        query: ({ groupId }) => ({
          url: `group/${groupId}/archive/toggle`,
          method: 'PUT',
        }),
        invalidatesTags: ['UPDATE_CHANNEL'],
      }),
      createChannel: build.mutation({
        query: (args) => ({ url: 'group/channel', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_CHANNEL'],
      }),
      searchChannels: build.query({
        query: ({ searchText }) => ({
          url: `group/list/channels/search?searchText=${encodeURIComponent(searchText)}`,
        }),
      }),
      removeChannelMember: build.mutation({
        query: ({ channelId: roomId, userId, name }) => ({
          url: `room/${roomId}/members/remove?userId=${userId}`,
          method: 'PUT',
          body: { name },
        }),
        invalidatesTags: ['REMOVE_CHANNEL_MEMBER'],
      }),
      getMembersBySearchForGroup: build.query({
        query: ({ searchName }) => ({
          url: `group/list/members?searchText=${searchName}`,
        }),
      }),
      getMembersBySearchForChannel: build.query({
        query: ({ channelId = '', searchName }) => ({
          url: `group/list/channels/members/search?searchText=${searchName}&roomId=${channelId}`,
        }),
      }),
      getAllChannelsInSystem: build.query({
        query: () => ({
          url: `/group/list/channels`,
        }),
      }),
      getPaginatedChannels: build.query({
        query: ({ search, limit, sort, pageNumber, column, filter }) => ({
          url: `group/list/channels/filter?searchText=${search}&limit=${limit}&sort=${sort}&page=${pageNumber}&column=${column}&filter=${filter}`,
        }),
      }),
      addChannelMember: build.mutation({
        query: ({ channelId: roomId, userId, name }) => ({
          url: `room/${roomId}/members/add?userId=${userId}`,
          method: 'PUT',
          body: { name },
        }),
        invalidatesTags: ['ADD_CHANNEL_MEMBER'],
      }),
      addGroupMember: build.mutation({
        query: ({ groupId, userId, name }) => ({
          url: `group/${groupId}/members/add/${userId}`,
          method: 'PUT',
          body: { name },
        }),
        invalidatesTags: ['ADD_GROUP_MEMBER'],
      }),
      removeGroupMember: build.mutation({
        query: ({ groupId, userId, name }) => ({
          url: `group/${groupId}/members/remove/${userId}`,
          method: 'PUT',
          body: { name },
        }),
        invalidatesTags: ['REMOVE_GROUP_MEMBER'],
      }),
      sendInvitation: build.mutation({
        query: ({ channelId: roomId, email }) => ({
          url: `group/channel/${roomId}/invite`,
          method: 'POST',
          body: { email },
        }),
        invalidatesTags: ['INVITE_CHANNEL'],
      }),
      updateUserPermission: build.mutation({
        query: ({ channelId: roomId, key, value, userId }) => ({
          url: `group/channels/${roomId}/members/permission`,
          method: 'PUT',
          body: { key, value, userId },
        }),
        invalidatesTags: ['UPDATE_USER_PERMISSION'],
      }),
      getTeams: build.mutation({
        query: () => ({
          url: `group/list/teams`,
          method: 'GET',
        }),
      }),
    }
  },
})

export const {
  useLazyGetChannelsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useCreateChannelMutation,
  useLazySearchChannelsQuery,
  useLazyGetGroupByIdForAdminQuery,
  useLazyGetGroupsForAdminQuery,
  useAddChannelMemberMutation,
  useAddGroupMemberMutation,
  useRemoveGroupMemberMutation,
  useRemoveChannelMemberMutation,
  useSendInvitationMutation,
  useLazyGetMembersBySearchForChannelQuery,
  useLazyGetMembersBySearchForGroupQuery,
  useUpdateUserPermissionMutation,
  useLazyGetGroupInfoByGroupIdQuery,
  useGetTeamsMutation,
  useToggleTeamArchiveMutation,
  useLazyGetAllChannelsInSystemQuery,
  useLazyGetPaginatedChannelsQuery,
  useLazyGetUserGroupsAndChannelsWithMembersInfoQuery,
  useLazyGetUserGroupsTagsQuery,
} = groupService
