import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import moment from 'moment'

import colors from 'utils/colors'
import { EMPTY } from 'locales/locales'
import { IUser, IUserData } from 'types/user.types'
import { formatPostDate } from 'utils/helpers/postHelper'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'

import Spinner from 'components/spinner/Spinner'
import CustomText from 'components/text/CustomText'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'

export interface INotificationData {
  _id: string
  createdAt: string
  senderId: string
  isRead: boolean
  data: {
    redirectUrl: string
    roomId: string
    messageId: string
    body: { name: string; threadText: string }
    threadId: string
  }
  userData: IUserData
  users: IUser[]
}

interface INotification {
  data: INotificationData[]
  title: string
  showMore: boolean
  isFetching: boolean
  isFetchingMore: boolean
  onClickNotification: (notification: INotificationData) => void
  onClickShowMore: () => void
  onFetchInitialData: () => void
}

const ThreadNotificationModal = ({
  data,
  title,
  showMore,
  isFetching,
  isFetchingMore,
  onClickNotification,
  onClickShowMore,
  onFetchInitialData,
}: INotification) => {
  const { t } = useTranslation()

  useEffect(() => {
    onFetchInitialData()
  }, [])

  return (
    <Wrapper>
      <Header>
        <CustomText typography="header1"> {title}</CustomText>
      </Header>
      {!isFetching && data.length === 0 && <Empty>{t(EMPTY.EMPTY_NEWS_FEED_NOTIFICATION)}</Empty>}
      {isFetching && (
        <Empty>
          <Spinner loading={true} height="18" width="18" />
        </Empty>
      )}
      {data.length > 0 && (
        <Content>
          {!isFetching &&
            data.map((notification) => (
              <Notification
                key={notification._id}
                onClick={() => onClickNotification(notification)}
              >
                {!notification.isRead && <NotificationPoint />}
                <Avatar
                  source={notification.userData?.[notification?.senderId]?.avatar}
                  text={notification.userData?.[notification?.senderId]?.fullname}
                  size={AvatarSize.small}
                  type="circle"
                />
                <NotificationContent>
                  <CustomText typography="body7" color={colors.gray60}>
                    <Body>
                      <StyledUserName>{notification.data.body.name}</StyledUserName>
                      {` ${notification.data.body.threadText?.split(':')[0]}:`}
                      <b>{notification.data.body.threadText?.split(':')[1]}</b>
                    </Body>
                  </CustomText>
                  <CustomText typography="body7" color={colors.gray60}>
                    {formatPostDate(moment(notification.createdAt))}
                  </CustomText>
                </NotificationContent>
              </Notification>
            ))}
          {showMore &&
            (isFetchingMore ? (
              <Spinner loading={true} height="18" width="18" />
            ) : (
              <StyledShowMore typography="header5" color={colors.vime} onClick={onClickShowMore}>
                Show More
              </StyledShowMore>
            ))}
        </Content>
      )}
    </Wrapper>
  )
}

export default ThreadNotificationModal

const StyledUserName = styled.span`
  color: #139fdb;
  font-weight: 600;
`
const Wrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  top: 60px;
  width: 350px;
  height: 325px;
  border: 1px solid ${colors.light80};
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: ${colors.white};
  padding: 16px 19px 16px 16px;
  @media (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    width: 100%;
    height: 85vh;
    overflow-y: auto;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 245px;
  overflow: auto;
  @media (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    height: 100%;
    overflow: none;
  }
`
const Empty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 78px);
`
const Body = styled.p`
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: rgb(57, 57, 57);
  margin-left: 0px;
  cursor: inherit;
  text-align: start;
  word-break: break-word;
  white-space: pre-line;
`
const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-left: 5px;
`
const Notification = styled.div`
  display: flex;
  cursor: pointer;
  gap: 10px;
  position: relative;
  margin: 5px 5px 0px 5px;
`
const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
`
const NotificationPoint = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.vime};
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  top: -5px;
  left: -5px;
`
const StyledShowMore = styled(CustomText)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
`
