import { combineReducers, Reducer } from '@reduxjs/toolkit'
import api from 'app/api'
import { authReducer } from 'app/slices/auth.slice'
import { uploadReducer } from 'app/slices/upload.slice'
import { FixMeLater } from 'types'
import { AppReducer } from './slices/app.slice'
import { conversationReducer } from './slices/conversations.slice'
import { groupsReducer } from './slices/groups.slice'
import { roomsReducer } from './slices/rooms.slice'
import { usersReducer } from './slices/users.slice'
import { RootState } from './store'
import { otrReducer } from './slices/otr.slice'
import { systemReducer } from './slices/system.slice'
import { newsFeedReducer } from './slices/news-feed.slice'
import { ConferenceReduder } from './slices/conference.slice'
import { hashReducer } from './slices/hash.slice'
import axiosApi from './axiosApi'

const CommonReducers = {
  app: AppReducer,
  conference: ConferenceReduder,
  message: conversationReducer,
  auth: authReducer,
  upload: uploadReducer,
  users: usersReducer,
  rooms: roomsReducer,
  groups: groupsReducer,
  otr: otrReducer,
  system: systemReducer,
  hash: hashReducer,
  newsFeed: newsFeedReducer,
  [api.reducerPath]: api.reducer,
}

const appReducer = combineReducers(CommonReducers)

const rootReducer: Reducer = (state: RootState, action: FixMeLater) => {
  if (action.type === 'RESET') {
    axiosApi.clearToken()
    state = {
      system: state.system,
      hash: state.hash,
    }
  }
  return appReducer(state, action)
}

export default rootReducer
