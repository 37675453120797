import api from 'app/api'

export const systemService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getConfig: build.mutation({
        query: () => ({
          url: `/config`,
          method: 'GET',
        }),
        invalidatesTags: ['CONFIG'],
      }),
      initializeSystem: build.mutation({
        query: (body) => ({
          url: `/config/initialize`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['INITIALIZE_SYSTEM'],
      }),
      getLdapServerList: build.query({
        query: ({ search, limit, sort, pageNumber, column, filter }) => ({
          url: `/ldap/list?searchText=${search}&limit=${limit}&sort=${sort}&page=${pageNumber}&column=${column}&filter=${filter}`,
          method: 'GET',
        }),
      }),
      getLdapSyncServerList: build.query({
        query: ({ startDate, endDate, type }) => {
          const queryParams = new URLSearchParams()
          if (startDate) queryParams.append('startDate', startDate)
          if (endDate) queryParams.append('endDate', endDate)
          if (type) queryParams.append('type', type)

          return {
            url: `ldap/ldapSync?${queryParams}`,
            method: 'GET',
          }
        },
      }),
      deleteLdapServer: build.query({
        query: (serverId) => ({
          url: `/ldap/${serverId}`,
          method: 'delete',
        }),
      }),
      getLdapServer: build.query({
        query: (serverId) => ({
          url: `/ldap/${serverId}`,
        }),
      }),
      createNewSystem: build.mutation({
        query: (args) => ({
          url: 'system/',
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['CREATE_NEW_SETTING'],
      }),
      getSystem: build.query({
        query: () => ({
          url: `system/`,
          method: 'GET',
        }),
      }),
      updateSystemSettings: build.mutation({
        query: (args) => ({
          url: `system/`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_SYSTEM_SETINGS'],
      }),
      createMailSettings: build.mutation({
        query: (args) => ({
          url: 'system/settings/mail',
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['CREATE_MAIL_SETTINGS'],
      }),
      getPassword: build.query({
        query: () => ({
          url: `config/password`,
          method: 'GET',
        }),
      }),
      getMailSettings: build.query({
        query: () => ({
          url: `system/settings/mail`,
          method: 'GET',
        }),
      }),
      createPassword: build.mutation({
        query: (args) => ({
          url: `system/password`,
          method: 'POST',
          body: args,
        }),
        invalidatesTags: ['CREATE_PASSWORD_SETTINGS'],
      }),
      updatePasswordSettings: build.mutation({
        query: (args) => ({
          url: `system/password`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_SYSTEM_PASSWORD_SETTINGS'],
      }),
      updateMailSettings: build.mutation({
        query: (args) => ({
          url: `system/settings/mail`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_MAIL_SETTINGS'],
      }),
      createLdapServerWithConference: build.mutation({
        query: (args) => ({ url: 'ldap/conference/connection/user', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_LDAP_SERVER_WITH_CONFERENCE'],
      }),
      sendLdapSyncLog: build.mutation({
        query: (logData) => ({
          url: '/ldap/ldapSync',
          method: 'POST',
          body: logData,
        }),
        invalidatesTags: ['LDAP_SYNC_LOGS'],
      }),
      createLdapGroupWithConference: build.mutation({
        query: (args) => ({ url: 'ldap/conference/connection/group', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_LDAP_GROUP_WITH_CONFERENCE'],
      }),
      checkLdapConnection: build.mutation({
        query: (args) => ({ url: 'ldap/check/connection', method: 'POST', body: args }),
        invalidatesTags: ['CHECK_LDAP_CONNECTION'],
      }),
      createLdapServer: build.mutation({
        query: (args) => ({ url: 'ldap', method: 'POST', body: args }),
        invalidatesTags: ['CREATE_LDAP_SERVER'],
      }),
      updateLdapServer: build.mutation({
        query: (args) => ({ url: 'ldap', method: 'PUT', body: args }),
        invalidatesTags: ['UPDATE_LDAP_SERVER'],
      }),

      // pairLdapManuel: build.mutation({
      //   query: (args) => ({ url: `ldap/pair/${args._id}`, method: 'POST' }),
      //   invalidatesTags: ['PAIR_LDAP_SERVER'],
      // }),
      synchronizeLdapServerToMongo: build.mutation({
        query: (args) => ({ url: 'ldap/pair', method: 'POST', body: args }),
        invalidatesTags: ['SYNCHRONIZE_LDAP_TO_MONGO'],
      }),
      getRoles: build.query({
        query: (args) => {
          const { skip, take, sort, count } = args
          return {
            url: `role?$skip=${skip}&$take=${take}&$sort=${sort}&$count=${count}`,
            method: 'GET',
          }
        },
        providesTags: ['GET_ROLES'],
      }),
      updateRole: build.mutation({
        query: (args) => ({ url: 'role', method: 'PUT', body: args }),
      }),
      deleteRole: build.mutation({
        query: (args) => ({ url: 'role/delete', method: 'PUT', body: args }),
      }),
      addRole: build.mutation({
        query: (args) => ({ url: 'role', method: 'POST', body: args }),
      }),
      getRoleUsers: build.query({
        query: (args) => {
          const { skip, take, sort, count, filter, id } = args
          return {
            url: `role/users?$skip=${skip}&$take=${take}&$count=${count}&$roleId=${id}&$filters=${encodeURIComponent(
              JSON.stringify(filter ?? {}),
            )}&$sort=${encodeURIComponent(JSON.stringify(sort ?? {}))}`,
            method: 'GET',
          }
        },
      }),
      getRoleExternalUsers: build.query({
        query: (args) => {
          const { skip, take, sort, count, filter, id } = args
          return {
            url: `role/external/users?$skip=${skip}&$take=${take}&$count=${count}&$roleId=${id}&$filters=${encodeURIComponent(
              JSON.stringify(filter ?? {}),
            )}&$sort=${encodeURIComponent(JSON.stringify(sort ?? {}))}`,
            method: 'GET',
          }
        },
      }),
      getRolePermissions: build.query({
        query: (id) => {
          return {
            url: `role/permissions/${id}`,
            method: 'GET',
          }
        },
      }),
      addUserToRole: build.mutation({
        query: (args) => ({ url: 'role/addusertorole', method: 'PUT', body: args }),
      }),
      removeUserFromRole: build.mutation({
        query: (args) => ({ url: 'role/removeuserfromrole', method: 'PUT', body: args }),
      }),
      addPermissionToRole: build.mutation({
        query: (args) => ({ url: 'role/addpermissiontorole', method: 'PUT', body: args }),
      }),
      removePermissionFromRole: build.mutation({
        query: (args) => ({ url: 'role/removepermissionfromrole', method: 'PUT', body: args }),
      }),
      getMessagingFromRole: build.query({
        query: (id) => {
          return {
            url: `role/messaging/${id}`,
            method: 'GET',
          }
        },
      }),
      setMessagingFromRole: build.mutation({
        query: (args) => ({ url: 'role/messaging', method: 'PUT', body: args }),
      }),
      getUserPermissions: build.query({
        query: () => ({
          url: `system/user/permissions`,
          method: 'GET',
        }),
      }),
    }
  },
})

export const {
  useGetConfigMutation,
  useLazyGetSystemQuery,
  useUpdateSystemSettingsMutation,
  useLazyGetLdapSyncServerListQuery,
  useLazyDeleteLdapServerQuery,
  useLazyGetLdapServerQuery,
  // useGetLdapServerMutation,
  useCreateNewSystemMutation,
  useCheckLdapConnectionMutation,
  useCreateLdapServerMutation,

  useSynchronizeLdapServerToMongoMutation,
  // usePairLdapManuelMutation,
  useUpdateLdapServerMutation,
  useCreatePasswordMutation,
  useLazyGetPasswordQuery,
  useUpdatePasswordSettingsMutation,
  useCreateMailSettingsMutation,
  useLazyGetMailSettingsQuery,
  useUpdateMailSettingsMutation,
  useInitializeSystemMutation,
  useSendLdapSyncLogMutation,
  useCreateLdapServerWithConferenceMutation,
  useCreateLdapGroupWithConferenceMutation,
  useLazyGetRolesQuery,
  useUpdateRoleMutation,
  useDeleteRoleMutation,
  useAddRoleMutation,
  useLazyGetRoleUsersQuery,
  useLazyGetRoleExternalUsersQuery,
  useLazyGetRolePermissionsQuery,
  useAddUserToRoleMutation,
  useRemoveUserFromRoleMutation,
  useAddPermissionToRoleMutation,
  useRemovePermissionFromRoleMutation,
  useLazyGetMessagingFromRoleQuery,
  useSetMessagingFromRoleMutation,
  useLazyGetUserPermissionsQuery
} = systemService
