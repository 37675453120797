import { useEffect } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'

import { FixMeLater } from 'types'
import { PROCESSING_INVITE_TEXT, SECOND } from 'utils/constants'
import { useAcceptInviteMutation, useLazyCheckInvitationQuery } from 'app/services/invite.service'

import Spinner from 'components/spinner/Spinner'

export default function Invite() {
  const { inviteId, roomId } = useParams()
  const navigate = useNavigate()
  const [checkInvitationQuery] = useLazyCheckInvitationQuery()
  const [acceptInvite] = useAcceptInviteMutation()

  useEffect(() => {
    // Check invitation when invitation link is clicked by the user.
    // Delay for a second to make sure that user sees this page.
    setTimeout(() => {
      checkInvitation()
    }, SECOND)

    async function checkInvitation() {
      const { data }: FixMeLater = await checkInvitationQuery({ inviteId, roomId })
      try {
        if (data) {
          const { action = null, params = null, returnUrl = null } = data
          // If server responds with a returnUrl, navigate and pass the data along with it.
          if (returnUrl) {
            navigate(returnUrl, { state: { action, ...params } })
          } else {
            acceptInvite(action)
              .then(() => navigate(action.successUrl))
              .catch(() => navigate(action.failUrl))
          }
        } else {
          navigate('/')
        }
      } catch (err) {
        console.log('err: ', err)
      }
    }
  }, [])
  return (
    <InviteContainer>
      <InviteBodyWrapper>
        <div>{PROCESSING_INVITE_TEXT}</div>
        <Spinner loading />
      </InviteBodyWrapper>
    </InviteContainer>
  )
}

const InviteContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;
`

const InviteBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`
