import styled from 'styled-components'

import colors from 'utils/colors'

interface ISwitchProps {
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}

const Switch = ({ checked, onChange, disabled = false }: ISwitchProps) => {
  return (
    <SwitchContainer
      disabled={disabled}
      onClick={() => !disabled && onChange(checked)}
      checked={checked}
    >
      <SwitchContent checked={checked} />
    </SwitchContainer>
  )
}

export default Switch

const SwitchContainer = styled.div.attrs((props: { checked: boolean; disabled: boolean }) => props)`
  display: flex;
  height: 14px;
  width: 24px;
  cursor: pointer;
  align-items: center;
  border-radius: 14px;
  border-width: 1px;
  border-color: ${({ checked }) => (!checked ? colors.gray60 : colors.darkBlue)};
  background-color: ${({ checked }) => (!checked ? colors.gray60 : colors.primaryVime)};
  padding: 0px 2px;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')}; ;
`

const SwitchContent = styled.div.attrs((props: { checked: boolean }) => props)`
  height: 10px;
  width: 10px;
  border-radius: 9999px;
  background-color: ${colors.white};
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  translate: ${({ checked }) => (checked ? '10px 0px' : '0px 0px')};
`
