import { useMemo } from 'react'
import { AppDispatch, RootState } from 'app/store'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useIsElectron = () => {
    return useMemo(() => {
      const userAgent = navigator.userAgent.toLowerCase();
      return userAgent.includes('electron/');
    }, []);
  }