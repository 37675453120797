import { useEffect, useRef, useState } from 'react'
import { IEmojiData } from 'emoji-picker-react'
import _isEmpty from 'lodash/isEmpty'

import {
  useEditMessageMutation,
  useSendMessageMutation,
  useSendThreadMessageMutation,
  useSendVanishMessageMutation,
} from 'app/services/conversation.service'
import uploadApi from 'app/axiosApi'
import { otrActions } from 'app/slices/otr.slice'
import { ICustomFile, IFiles } from 'types/file.types'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { conversationActions } from 'app/slices/conversations.slice'
import {
  getInputValueByfullname,
  geValueByfullname,
  parseUserDataFromInputData,
} from 'utils/helpers/messageHelper'
import { IMessageContent, MessageStatus } from 'types/conversation.types'
import { FixMeLater } from 'types'
import { v4 } from 'uuid'
import { IUser, IUserData } from 'types/user.types'
import { values } from 'lodash'

const initialState = { text: '', value: '', mentionedUsers: [], files: [], users: {} }
export interface IMessageInputState {
  text: string
  value: string
  mentionedUsers: string[]
  files: IFiles
  users: IUserData
}

const isEmpty = (str: string) => !str.trim().length

const inputDataValidation = (text: string, files: IFiles) => {
  return !isEmpty(text) || !_isEmpty(files)
}

const getParseMentionedUsers = (text: string) => {
  const matchedMentionedUsers = text.split('&').filter((item) => item.includes('_id'))
  const mentionedUsers = matchedMentionedUsers?.map((id) => id.slice(4))
  return mentionedUsers
}

const useMessageInput = ({
  roomId,
  messageIdInThread,
  messageId,
  isOtr,
  initialInputData,
  pendingHandler,
  users,
}: {
  roomId: string
  messageIdInThread?: string
  messageId?: string
  isOtr?: boolean
  initialInputData?: IMessageContent
  pendingHandler?: (message: FixMeLater) => void
  users?: IUserData
}) => {
  const replicateInputData = useRef<IMessageInputState>({ ...initialState })
  const [isValid, setValid] = useState(false)
  const [inputData, setInputData] = useState<IMessageInputState>({
    ...initialState,
    ...initialInputData,
  })

  // const mounted = useIsMounted()
  const dispatch = useAppDispatch()
  const myId = useAppSelector((state) => state.auth.id)
  const { draftData } = useAppSelector((state) => state.message)

  const [sendMessage, { data, isSuccess }] = useSendMessageMutation()
  const [sendThreadMessage] = useSendThreadMessageMutation()
  const [sendOtrMessage] = useSendVanishMessageMutation()
  const [sendEditedMessage] = useEditMessageMutation()

  useEffect(() => {
    if (data && isSuccess) {
      dispatch(conversationActions.updateMessageByRoomId(data.data))
    }
  }, [data, isSuccess])

  useEffect(() => {
    replicateInputData.current = inputData
  }, [inputData])

  useEffect(() => {
    window.onbeforeunload = function () {
      addDataInDraft(replicateInputData.current, true, messageIdInThread, roomId)
      if (inputDataValidation(replicateInputData.current.text, replicateInputData.current.files)) {
        return true
      }
    }

    return () => {
      addDataInDraft(replicateInputData.current, false, messageIdInThread, roomId)
      window.onbeforeunload = null
    }
  }, [messageIdInThread, roomId])

  useEffect(() => {
    setValid(inputDataValidation(inputData.text, inputData.files))
  }, [inputData.text, inputData.files])

  useEffect(() => {
    const draftId = messageIdInThread ? messageIdInThread : roomId
    setInputData({ ...inputData, files: draftData?.[draftId]?.files || [] })

    if (!_isEmpty(draftData?.[draftId]?.files) && !messageId) {
      const files: IFiles = []
      const filesInDraftData = draftData?.[messageIdInThread || roomId]?.files
      const combinedFiles = [...replicateInputData.current.files, ...filesInDraftData]
      combinedFiles.forEach((file: ICustomFile) => {
        const index = files.findIndex(
          (localFile: ICustomFile) => localFile.uniqueId === file.uniqueId,
        )
        if (index == -1) {
          files.push(file)
        } else {
          if (file.url) {
            files[index] = file
          }
        }
      })
    }
  }, [draftData?.[messageIdInThread || roomId]?.files])

  useEffect(() => {
    if ((messageIdInThread || roomId) && !initialInputData) {
      const draftId = messageIdInThread || roomId
      const { id, ...rest } = draftData?.[draftId] || {}
      setInputData({ ...initialState, ...rest })
      dispatch(conversationActions.removeDataInDraft({ id: draftId }))
    }
  }, [messageIdInThread, roomId])

  const onCancelFile = (uniqueId: string) => {
    const files = inputData.files.filter((file: ICustomFile) => uniqueId !== file.uniqueId)
    setInputData({ ...inputData, files })
    uploadApi.cancelUploadFile()
    dispatch(
      conversationActions.removeDataByUniqueIdInDraft({
        uniqueId,
        id: messageIdInThread || roomId,
      }),
    )
  }

  const onChangeInputData = async (text: string) => {
    const mentionedUsers = getParseMentionedUsers(text)
    const tempData = { ...inputData, text, value: text, mentionedUsers }
    setInputData({ ...tempData })
  }

  const onSelectEmoji = (emojiParams: IEmojiData) => {
    const text = inputData.text + emojiParams.emoji
    const value = inputData.value + emojiParams.emoji
    setInputData({ ...inputData, text, value })
  }

  const onSendMessage = async () => {
    const sentAt = new Date().toISOString()

    const mentionedUserData = parseUserDataFromInputData(inputData)
    users = { ...mentionedUserData, ...users }

    const { mentionedUsers, text, files = [], originalText } = getInputValueByfullname(inputData)
    const value = geValueByfullname(text, mentionedUsers, users)

    const trimmedText = text.trim()
    const cti = v4()
    const params: {
      type: Array<string>
      sentAt: string
      content: {
        mentionedUsers: Array<string>
        text: string
        value: string
        files?: IFiles
        originalText: string
      }
      userData?: IUserData
      users?: IUser[]
    } = {
      type: [],
      sentAt,
      content: { mentionedUsers, text: trimmedText, value, files, originalText },
      userData: users,
      users: values(users),
    }

    if (!isEmpty(text)) {
      params.type.push('text')
    }
    if (!_isEmpty(files)) {
      params.type.push('files')
    }
    try {
      setInputData({ ...initialState })
      dispatch(conversationActions.removeDataInDraft({ id: messageIdInThread || roomId }))
      if (messageIdInThread) {
        pendingHandler?.({
          ...params,
          roomId,
          sender: myId,
          status: MessageStatus.PENDING,
          cti,
        })
        await sendThreadMessage({ messageId: messageIdInThread, cti, ...params })
      } else if (messageId) {
        delete params.content.files
        delete params.users
        delete params.userData
        await sendEditedMessage({ messageId, cti, ...params })
      } else if (roomId) {
        if (isOtr) {
          dispatch(
            otrActions.sendOtrMessage({
              status: MessageStatus.PENDING,
              roomId,
              sender: myId,
              ...params,
            }),
          )
          sendOtrMessage({ roomId, sender: myId, ...params })
        } else {
          dispatch(
            conversationActions.sendMessage({
              roomId,
              sender: myId,
              status: MessageStatus.PENDING,
              cti,
              ...params,
            }),
          )
          await sendMessage({ roomId, cti, ...params })
        }
      }
    } catch (e) {
      if (messageIdInThread) {
        pendingHandler?.({
          ...params,
          cti,
          roomId,
          sender: myId,
          status: MessageStatus.PENDING,
        })
        await sendThreadMessage({ messageId: messageIdInThread, cti, ...params })
      } else if (messageId) {
        await sendEditedMessage({ messageId, cti, ...params })
      } else if (!isOtr) {
        await sendMessage({ roomId, cti, ...params })
      }
    }
  }

  const addDataInDraft = (
    beforeData: IMessageInputState,
    isRefresh: boolean,
    beforeMessageIdInThread?: string,
    beforeMessageRoomId?: string,
  ) => {
    if (inputDataValidation(beforeData.text, beforeData.files) && !messageId) {
      const filterFiles = isRefresh
        ? beforeData.files.filter((file) => file?.url)
        : beforeData.files
      if (beforeMessageRoomId && !beforeMessageIdInThread) {
        dispatch(
          conversationActions.addDataInDraft({
            ...beforeData,
            files: filterFiles,
            id: beforeMessageRoomId,
          }),
        )
      } else if (beforeMessageIdInThread) {
        dispatch(
          conversationActions.addDataInDraft({
            ...beforeData,
            files: filterFiles,
            id: beforeMessageIdInThread,
          }),
        )
      }
    }
  }

  return {
    inputData,
    isValid,
    onCancelFile,
    onSendMessage,
    onSelectEmoji,
    onChangeInputData,
  }
}

export default useMessageInput
