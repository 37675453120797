import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import colors from 'utils/colors'
import { ERRORS, MESSAGES } from 'locales/locales'
import { icons } from 'utils/icons'
import { FixMeLater } from 'types'
import { IChannelMember } from 'types/user.types'
import { customTextTypography } from 'components/text/customTextTypography'
import { CHANNEL_MEMBER_LIMIT } from 'utils/constants'

import Switch from 'components/switch/Switch'
import Member from 'components/members/Member'
import Spinner from 'components/spinner/Spinner'
import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { debounce } from 'lodash';
import { useAppSelector } from 'app/hooks'

interface ISearchProps {
  value?: string
  placeHolder?: string
  selectedItems?: IChannelMember[]
  showInDropdown?: boolean
  isOtr?: boolean
  searchedData?: IChannelMember[]
  isInvite?: boolean
  isAddMember?: boolean
  className?: string
  isSearchForThread?: boolean
  isOval?: boolean
  members?: IChannelMember[]
  isChannelCreation?: boolean
  onToggleOtr?: () => void
  onClickCancelButton?: () => void
  onChange?: (value: string) => void
  onSelectMember?: (user: IChannelMember) => void
  onRemoveMember?: (user: IChannelMember) => void
  onInviteMember?: (email: string) => void
}

interface IInputProps {
  isFocus?: boolean
  fontSize?: number
  fontWeight?: number
  lineHeight?: number
  isOval?: boolean
}

const SearchInput = ({
  value = '',
  placeHolder,
  searchedData,
  selectedItems = [],
  showInDropdown,
  isOtr,
  isInvite,
  isAddMember,
  className,
  isSearchForThread,
  isOval,
  members = [],
  isChannelCreation = false,
  onChange,
  onToggleOtr,
  onClickCancelButton,
  onSelectMember,
  onRemoveMember,
  onInviteMember,
}: ISearchProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [searchText, setSearchText] = useState(value)
  const [isFocus, setFocus] = useState(false)
  const systemSettings = useAppSelector((state) => state.system.data)

  const debounceRef = useRef(debounce((value) => {
    onChange?.(value);
  }, 200));

  useEffect(() => {
    return () => {
      debounceRef.current.cancel();
    };
  }, []);

  useEffect(() => {
    setSearchText(value)
  }, [value])

  useEffect(() => {
    if (inputRef && isSearchForThread) {
      inputRef?.current?.focus()
    }
  }, [isSearchForThread])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const { value } = e.target; 

    setSearchText(value)
    if(value.length>2)
      debounceRef.current(value);

    if(value.length === 0){
      handleOnClear();
    }
  }

  const handleOnClear = () => {
    inputRef?.current?.focus()
    setSearchText('')
    onChange?.('')
    onClickCancelButton?.()
  }

  const onFocus = () => {
    setFocus(true)
  }

  const onBlur = () => {
    setFocus(false)
  }

  const onHandleSelectedItem = (user: IChannelMember) => {
    setSearchText('')
    inputRef.current?.focus()

    if ((isChannelCreation ? members?.length + 1 : members?.length) === CHANNEL_MEMBER_LIMIT) {
      return DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.MEMBER_LIMIT_EXCEEDED),
      })
    }

    onSelectMember?.(user)
  }

  const onHandleInviteMember = (email: string) => {
    onInviteMember?.(email)
    setSearchText('')
    inputRef.current?.focus()
  }

  const onHandleRemoveItem = (user: IChannelMember) => {
    onRemoveMember?.(user)
    setSearchText('')
    inputRef.current?.focus()
  }

  return (
    <SearchInputWrapper className={className}>
      <Wrapper isFocus={isFocus} isOval={isOval}>
        <div>
          <InputWrapper>
            <CustomIcon source={icons.searchIcon} />
            <Input
              ref={inputRef}
              value={searchText}
              placeholder={placeHolder}
              {...customTextTypography.body3}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={handleOnChange}
            />
            {searchText && <CustomIcon source={icons.closeIcon} onClick={handleOnClear} />}
            { isOtr !== undefined && systemSettings?.features?.otrEnabled && (
              <OtrSwitchWrapper>
                <OtrSwitch>
                  <StyledTextComponent typography="body7">OTR</StyledTextComponent>
                  <Switch checked={isOtr} onChange={() => onToggleOtr?.()} />
                </OtrSwitch>
                <OtrTooltip>
                  <CustomIcon width="80px" height="41px" source={icons.otrTooltip} />
                </OtrTooltip>
              </OtrSwitchWrapper>
            )}
          </InputWrapper>
        </div>
        {selectedItems?.length !== 0 && (
          <MultipleSearchWrapper>
            {selectedItems?.map((user: IChannelMember, index: number) => {
              return (
                <SelectedItemWrapper key={index}>
                  <CustomText typography="body3">{user.fullname}</CustomText>
                  <CustomIcon
                    iconPreset="xSmall"
                    source={icons.closeIconSmall}
                    onClick={() => onHandleRemoveItem(user)}
                  />
                </SelectedItemWrapper>
              )
            })}
          </MultipleSearchWrapper>
        )}
      </Wrapper>
      {searchText && showInDropdown && (
        <SearchDropdown>
          <Spinner loading={!searchedData} />
          {searchedData?.map((user: FixMeLater, index: number) => {
            return (
              <Member
                key={index}
                {...user}
                inSearchInput 
                isUserCardDisplayable={true}
                isMemberAlready={members?.some((member) => member._id === user._id)}
                isInvite={isInvite && !user?._id}
                isAddMember={isAddMember}
                onSelectMember={() => onHandleSelectedItem(user)}
                onInviteMember={() => onHandleInviteMember(user.email)}
              />
            )
          })}
          {searchedData?.length === 0 && (
            <EmptySearchText>
              <StyledTextComponent typography="header3">
                {t(MESSAGES.NO_RESULTS_FOR)} {`"${searchText}"`}
              </StyledTextComponent>
              <CustomText typography="body6" color={colors.gray80}>
                {t(MESSAGES.CHECK_SPELLING)}
              </CustomText>
            </EmptySearchText>
          )}
        </SearchDropdown>
      )}
    </SearchInputWrapper>
  )
}

export default SearchInput

const StyledTextComponent = styled(CustomText)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
`
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${(props: IInputProps) => (props.isFocus ? colors.vime : colors.light80)};
  border-radius: ${(props: IInputProps) => (props.isOval ? '20px' : '4px')};
  padding: 3px 3px;
  background-color: ${colors.white};
  flex-direction: column;
  box-shadow: ${(props: IInputProps) => (props.isOval ? '0px 0px 6px #499eda;' : 'none')};
`
const Input = styled.input`
  width: 100%;
  font-size: ${(props: IInputProps) => props.fontSize}px;
  font-weight: ${(props: IInputProps) => props.fontWeight};
  line-height: ${(props: IInputProps) => props.lineHeight}px;
`
const SearchDropdown = styled.div`
  position: initial;
  width: 100%;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-color: ${colors.white};
  margin-top: 5px;
  padding: 10px 0px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 2;
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const MultipleSearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 3px;
  margin-top: 7px;
  margin-left: 5px;
`

const SelectedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 6px;
  background-color: ${colors.vime20};
  border-radius: 4px;
  gap: 6px;
`

const EmptySearchText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  gap: 4px;
`

const OtrSwitchWrapper = styled.div`
  position: relative;
`

const OtrTooltip = styled.div`
  display: none;
  position: absolute;
  bottom: 18px;
  right: -25px;
`

const OtrSwitch = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-right: 7px;

  &:hover ~ ${OtrTooltip} {
    display: block;
  }
`
