import { useCallback, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { FixMeLater } from 'types'

const NewsFeedLayout = () => {
  const [newsFeedJumpData, setNewsFeedJumpData] = useState(null)
  const [openNotifications, setOpenNotifications] = useState(false)

  const navigate = useNavigate()

  const onJumpComment = useCallback((data: FixMeLater) => {
    setNewsFeedJumpData(data)
    setOpenNotifications(false)
    navigate(`${data.postId}`)
  }, [])

  const onToggleNotification = () => {
    setOpenNotifications((pre) => !pre)
  }

  const onCloseNotification = () => {
    setOpenNotifications(false)
  }

  const onResetNewsFeedJumpData = () => {
    setNewsFeedJumpData(null)
  }

  return (
    <Outlet
      context={{
        onJumpComment,
        onResetNewsFeedJumpData,
        onToggleNotification,
        onCloseNotification,
        openNotifications,
        newsFeedJumpData,
      }}
    />
  )
}

export default NewsFeedLayout
