import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import {
  useCreateNewSystemMutation,
  useGetConfigMutation,
  useLazyGetSystemQuery,
  useUpdateSystemSettingsMutation,
} from 'app/services/system.service'
import { icons } from 'utils/icons'
import SettingsHeader from 'features/settings/SettingsHeader'
import { systemActions } from 'app/slices/system.slice'
import { BUTTONS, FORM, NAVIGATION, OTHERS, PLACEHOLDERS } from 'locales/locales'
import { requiredValidation } from 'utils/helpers/validations'
import colors from 'utils/colors'

import UploadImage from 'components/input/uplaod-input/UploadImage'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import CustomText from 'components/text/CustomText'
import Loading from 'components/spinner/Loading'
import { SUPPORTED_FILE_TYPES } from 'utils/constants'
import Checkbox from 'components/checkbox/Checkbox'
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { otrActions } from 'app/slices/otr.slice'
import { useCloseOtrMutation } from 'app/services/otrService'
import { setDataToDB } from 'utils/helpers/storageHelper'

interface SystemSettings {
  name: string;
  licence: string;
  apiKey: string;
  imageUrlFavicon: string;
  imageUrlLogo: string;
  fileExtensions: string[];
  features: {
    newsFeedEditAndDeleteEnabled: boolean;
    videoCallEnabled: boolean;
    fcmDetailEnabled: boolean;
    captchaEnabled: boolean;
    passwordChangeEnabled: boolean;
    passwordResetEnabled: boolean;
    groupWithMembers: boolean;
    isGroupMembersEnabled: boolean;
    otrEnabled: boolean;
    offlineModeEnabled: boolean;
    notificationSound: boolean;
    showUnpermittedFeatures: boolean;
  };
}

interface FeatureCheckboxProps {
  title: string;
  featureKey: keyof SystemSettings['features'];
  label: string; // Etiket metni için yeni özellik
}
const SystemManagement = () => {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [createNewSystem] = useCreateNewSystemMutation()
  const [updateSystem] = useUpdateSystemSettingsMutation()
  const [getSystemSettings] = useLazyGetSystemQuery()
  const [closeOtr] = useCloseOtrMutation()
  const [config] = useGetConfigMutation()

  const systemSettingsSlice = useAppSelector((state) => state.system.data)
  const existOtrRooms = useAppSelector((state) => state.otr)
  const [systemSettingId, setSystemSettingId] = useState('')
  const [formChecked, setFormChecked] = useState(false)

  const [systemSettings, setSystemSettings] = useState({
    name: '',
    licence: '',
    apiKey: '',
    imageUrlFavicon: '',
    imageUrlLogo: '',
    fileExtensions: [""],
    features: {
      fcmDetailEnabled: false,
      captchaEnabled: false,
      newsFeedEditAndDeleteEnabled: false,
      videoCallEnabled: false,
      isGroupMembersEnabled: true,
      groupWithMembers: true,
      passwordChangeEnabled: false,
      passwordResetEnabled: false,
      otrEnabled: false,
      offlineModeEnabled: false,
      notificationSound: true,
      showUnpermittedFeatures: false
    }
  })

  useEffect(() => {
    fetchSystemSettings()
  }, [])

  const fetchSystemSettings = async () => {
    try {
      Loading.ref.show();
      const response = await getSystemSettings({});
      if (response?.data?.data && Object.keys(response.data.data).length !== 0) {
        setSystemSettings(response.data.data);
        setSystemSettingId(response.data.data._id);
        dispatch(systemActions.updateSystem(response.data.data));
      } else {
        setSystemSettings(systemSettingsSlice);
        setNameAndFavicon(
          systemSettingsSlice.name,
          systemSettingsSlice.imageUrlFavicon,
          systemSettingsSlice.imageUrlLogo,
        );
      }
    } catch (error) {
      console.error("Error fetching system settings:", error);
    } finally {
      Loading.ref.hide();
    }
  };


  const onSubmitHandler = async () => {
    setFormChecked(true)
    const { isValid } = requiredValidation(systemSettings.name)
    if (!isValid) return
    try {
      Loading.ref.show()
      if (systemSettingId === '') {
        await createNewSystem({ ...systemSettings })
      } else {
        await updateSystem({
          ...{
            ...systemSettings,
            name: systemSettings.name?.trim(),
            licence: systemSettings.licence?.trim(),
            apiKey: systemSettings.apiKey?.trim(),
          },
          _id: systemSettingId,
        })
      }
    } finally {
      setNameAndFavicon(
        systemSettings.name,
        systemSettings.imageUrlFavicon,
        systemSettings.imageUrlLogo,
      )

      dispatch(systemActions.updateSystem(systemSettings))
      const { data: configData } = (await config({})) as any
      await setDataToDB('vimesoft', 'config', configData)
      if (Object.keys(existOtrRooms).length && !systemSettings.features.otrEnabled) {
        Object.keys(existOtrRooms).forEach((roomId: string) => {
          dispatch(otrActions.deleteOtrRoom({ roomId }))
          closeOtr({ roomId })
        })
      }
      Loading.ref.hide()
      setFormChecked(false)
    }
  }

  const onChangeLogo = async (fileUrl: string) => {
    setSystemSettings({ ...systemSettings, imageUrlLogo: fileUrl })
  }

  const onChangeFavicon = async (fileUrl: string) => {
    setSystemSettings({ ...systemSettings, imageUrlFavicon: fileUrl })
  }

  const onChangeInput = (value: string, key: string) => {
    setSystemSettings({ ...systemSettings, [key]: value })
  }

  const onChangeFileExtension = (e: boolean, value: string) => {
    if (!e) {
      setSystemSettings(prevSettings => ({
        ...prevSettings,
        fileExtensions: [...(prevSettings.fileExtensions || []), value],
      }));
    } else {
      setSystemSettings(prevSettings => ({
        ...prevSettings,
        fileExtensions: prevSettings.fileExtensions ? prevSettings.fileExtensions.filter((m) => m !== value) : [],
      }));
    }
  }

  const handleFeatureToggle = (featureKey: keyof SystemSettings['features']) => {
    setSystemSettings(prevSettings => ({
      ...prevSettings,
      features: {
        ...prevSettings.features,
        [featureKey]: !(prevSettings.features?.[featureKey] ?? false)
      }
    }));
  };


  const FeatureCheckbox: React.FC<FeatureCheckboxProps> = ({ title, featureKey, label }) => (
    <StyledFeatureItem>
      <Checkbox
        checked={systemSettings?.features?.[featureKey] ?? false}
        onChange={() => handleFeatureToggle(featureKey)}
      />
      <StyledSpecName>{title}</StyledSpecName>
      <FeatureLabel>{label}</FeatureLabel> 
    </StyledFeatureItem>
  );


  const setNameAndFavicon = (name: string, url: string, logo: string) => {
    document.title = name
    document.getElementById('favicon')?.setAttribute('href', `${url}`)
    document.getElementById('login-logo')?.setAttribute('src', `${logo}`)
    document.getElementById('login-logo')?.setAttribute('srcSet', `${logo}`)
  }

  const isValidName =
    formChecked && requiredValidation(systemSettings.name, t(FORM.SYSTEM_NAME)).errorText
  return (
    <Wrapper>
      <TopContainer>
        <SettingsHeader title={t(NAVIGATION.SYSTEM_SETTINGS)} />
        <CustomText typography="header3">{t(OTHERS.LICENCE_EXPIRY)}</CustomText>
      </TopContainer>
      <Layout>
        <Column>
          <ImageInputWrapper>
            <UploadImage
              type="favicon"
              title={'Favicon'}
              avatar={systemSettings.imageUrlFavicon || icons.defaultGroupIcon.src}
              buttonText={BUTTONS.UPLOAD}
              onUploadPhoto={onChangeFavicon}
            />
            <UploadImage
              type="logo"
              title={'Logo'}
              avatar={systemSettings.imageUrlLogo || icons.defaultGroupIcon.src}
              buttonText={BUTTONS.UPLOAD}
              onUploadPhoto={onChangeLogo}
            />
          </ImageInputWrapper>
          <CustomFormInput
            title={t(FORM.SYSTEM_NAME) as string}
            placeholder={t(PLACEHOLDERS.SYSTEM_NAME) as string}
            errorText={isValidName}
            onChange={(value: string) => onChangeInput(value, 'name')}
            value={systemSettings.name}
            type="text"
          />
          <CustomFormInput
            title={t(FORM.LICENCE) as string}
            onChange={(value: string) => onChangeInput(value, 'licence')}
            type="text"
            value={systemSettings.licence}
          />
          <CustomFormInput
            title={t(FORM.API_KEY) as string}
            onChange={(value: string) => onChangeInput(value, 'apiKey')}
            type="text"
            value={systemSettings.apiKey}
          />
          <StyledSettingsHeader>{t(FORM.SYSTEM_SETTINGS)}</StyledSettingsHeader>
          <PanelBar>
            <PanelBarItem title={t(FORM.SYSTEM_SETTINGS)}>
              <StyledFeatureContainer>
                <FeatureCheckbox title={t(FORM.CAPTCHA)} featureKey="captchaEnabled" label="Web" />
                <FeatureCheckbox title={t(FORM.PASSWORD_CHANGE)} featureKey="passwordChangeEnabled" label="Web" />
                <FeatureCheckbox title={t(FORM.FORGOT_PASSWORD)} featureKey="passwordResetEnabled" label="Web" />
                <FeatureCheckbox title={t(FORM.OTR)} featureKey="otrEnabled" label="Web" />
                <FeatureCheckbox title={t(FORM.NOTIFICATION_SOUND)} featureKey="notificationSound" label="Web" />
                <FeatureCheckbox title={t(FORM.OFFLINE_CONVERSATION)} featureKey="offlineModeEnabled" label="Web" />
                <FeatureCheckbox title={t(FORM.FCM_DETAIL)} featureKey="fcmDetailEnabled" label="Mobile/Web" />
                <FeatureCheckbox title={t(FORM.GROUP_MEMBERS_ENABLE)} featureKey="isGroupMembersEnabled" label="Web" />
                <FeatureCheckbox title={t(FORM.GROUP_WITH_MEMBERS)} featureKey="groupWithMembers" label="Web" />
                <FeatureCheckbox title={t(FORM.VIDEO_CALL_ENABLE)} featureKey="videoCallEnabled" label="Mobile" />
                <FeatureCheckbox title={t(FORM.NEWS_FEED_DELETE_EDIT)} featureKey="newsFeedEditAndDeleteEnabled" label="Mobile" />
                <FeatureCheckbox title={t(FORM.SHOW_UNPERMITTED_FEATURES)} featureKey="showUnpermittedFeatures" label="Web" />
              </StyledFeatureContainer>
            </PanelBarItem>
          </PanelBar>

          <StyledSettingsHeader>{t(FORM.FILE_UPLOAD_TITLE)}</StyledSettingsHeader>
          <PanelBar>
            {SUPPORTED_FILE_TYPES.map((category, cIndex) => (
              <PanelBarItem title={category.name} key={category.name + cIndex}>
                <StyledMimeTypeContainer>
                  {category.extensions.map((mimeType, index) => (
                    <StyledMimeTypeItem key={mimeType.value + index}>
                      <StyledCheckbox>
                        <Checkbox
                          checked={systemSettings.fileExtensions?.some((ex) => ex === mimeType.value)}
                          onChange={(e) => onChangeFileExtension(e, mimeType.value)}
                        ></Checkbox>
                      </StyledCheckbox>
                      <StyledSpecName > {mimeType.name} </StyledSpecName>
                    </StyledMimeTypeItem>
                  ))}
                </StyledMimeTypeContainer>
              </PanelBarItem>
            ))}
          </PanelBar>
        </Column>
        <CustomText typography="header3">{`${t(FORM.SYSTEM_VERSION)} : ${systemSettingsSlice.version
          }`}</CustomText>
      </Layout>
      <VimeButtonWrapper>
        <CustomButton text={t(BUTTONS.SAVE)} textColor="white" onClick={onSubmitHandler} />
      </VimeButtonWrapper>
    </Wrapper>
  )
}

export default SystemManagement

const StyledMimeTypeContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  `;

const StyledMimeTypeItem = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    min-width: 100px; 
  `;


const StyledFeatureItem = styled.label`
display: flex;
background: rgb(248 248 248);
padding: 7px 10px;
-webkit-box-align: center;
align-items: center;
width: 100%;
`;
const FeatureLabel = styled.span`
border-radius: 5px;
    margin-left: 10px;
    font-size: 11px;
    color: white;
    font-weight: bold;
    background: #119fdb;
    padding: 5px 12px;
`;

const StyledSpecName = styled.span`
flex: 1 1 0%;
text-align: left;
padding-right: 13px;
padding-left: 14px;
font-weight: 500;
margin-top: -3px;
`;


const StyledCheckbox = styled.div`
    display:flex;
    margin-right: 11px;
    margin-top: 6px;
  `
const StyledFeatureContainer = styled.div`
  display: flex;
  flex-direction: column; // Dikey sıralama için
  align-items: flex-start; // Öğeleri sola hizala
  gap: 10px; // Öğeler arasında dikey boşluk
  margin-bottom: 20px; // Altına boşluk ekle
`;



const StyledSettingsHeader = styled.h2`
      text-align: left;
      font-size: 16px;
      margin-bottom: 20px; 
      margin-top: 25px;
  `;


const Wrapper = styled.div`
    padding: 0px 30px;
    width: 100%;
    overflow: auto;
  
    &::-webkit-scrollbar {
      display: none;
    }
  `

const Layout = styled.div`
    min-height: calc(100vh - 200px);
    overflow-x: auto;
    padding: 20px;
    border: 1px solid ${colors.light80};
  `

const Column = styled.div`
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  `

const VimeButtonWrapper = styled.div`
    padding: 4px;
    float: right;
    margin-top: 20px;
  `

const TopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 10px;
  `

const ImageInputWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 24px;
    margin-top: 10px;
    width: 100%;
    @media (max-width: 665px) {
      flex-direction: column;
      align-items: center;
    }
  `
