import { FixMeLater } from './../../types/index'
import { createSlice } from '@reduxjs/toolkit'

import { postService } from 'app/services/post.service'
import { INotificationData } from 'components/modals/ThreadNotificationsModal'
import { IComment, IPost } from 'types/post.types'
import { IUserData } from 'types/user.types'
import getBaseUrl from 'utils/helpers/apiHelper'

export interface InputData {
  header: string
  text: string
  value?: string
  files: Array<FixMeLater>
  mentionedUsers: Array<string>
}
export interface CommentInputData {
  text: string
  value: string
  mentionedUsers: Array<string>
}
export interface INewsFeedSlice {
  totalPostCount: number
  pageIndex: number
  posts: IPost[]
  isPostLoading: boolean
  inputData: InputData
  unreadNotificationCount: number
  postId?: string
  commentToggle: boolean
  commentPageIndex: number
  commentInputData: {
    [postId: string]: CommentInputData
  }
  isCommentLoading: boolean
  isScrollTop: boolean
  isRenderedBefore: boolean
  notifications: FixMeLater[]
  realNotificationCount: number
  realNotificationPageNumber: number
}

// Transformer function to enrich a post with userData
export const setPostData = (post: IPost): IPost => {
  // Add all properties from post except userData, comments, and content

  const finalPost = { ...post } as IPost //strip users, comments, and content

  // add userData if users array is not empty
  if (!finalPost.users) {
    finalPost.users = []
  }

  finalPost.userData = finalPost.users.reduce((acc: IUserData, currentUser) => {
    acc[currentUser._id] = currentUser
    return acc
  }, {})

  // add content, checking both the existence and non-emptiness of files
  if (post.content) {
    finalPost.content = {...post.content}
    if (post.content.files && post.content.files.length > 0) {
      finalPost.content.files = finalPost.content.files.map((file) => {
        if (file instanceof File) {
          return file
        }
        const toReturn = { ...file }
        if (file.url) {
          toReturn.url = file.url.startsWith('http') ? file.url : `${getBaseUrl(true)}${file.url}`
        }
        if (file.thumbnail) {
          toReturn.thumbnail = file.thumbnail.startsWith('http')
            ? file.thumbnail
            : `${getBaseUrl(true)}${file.thumbnail}`
        }

        return toReturn
      })
    }
  }

  return finalPost
}

// Transformer function to enrich a post with userData
export const setCommentData = (comment: IComment): IComment => {
  const finalComment = { ...comment } as IComment
  if (!finalComment.users) {
    finalComment.users = []
  }

  finalComment.userData = finalComment.users.reduce((acc: IUserData, currentUser) => {
    acc[currentUser._id] = currentUser
    return acc
  }, {})

  return finalComment
}

const initialState: INewsFeedSlice = {
  pageIndex: 0,
  totalPostCount: 0,
  posts: [],
  isPostLoading: false,
  unreadNotificationCount: 0,
  postId: '',
  commentInputData: {},
  isCommentLoading: false,
  commentPageIndex: 0,
  commentToggle: false,
  inputData: {
    header: '',
    text: '',
    value: '',
    files: [],
    mentionedUsers: [],
  },
  isScrollTop: false,
  isRenderedBefore: true,
  notifications: [],
  realNotificationCount: 0,
  realNotificationPageNumber: 0,
}

export const { actions: newsFeedActions, reducer: newsFeedReducer } = createSlice({
  name: 'newsFeed',
  initialState,
  reducers: {
    setPageIndex: (state, action) => {
      state.pageIndex = action.payload - 1
    },
    setInitialCommentData: (state, { payload }) => {
      state.commentInputData[payload.postId] = {
        text: '',
        value: '',
        mentionedUsers: [],
      }
    },
    changeInputData: (state, { payload }) => {
      const { text, value, mentionedUsers, header } = payload
      state.inputData = { ...state.inputData, text, value, mentionedUsers, header }
    },
    changeInputFileData: (state, { payload }) => {
      state.inputData = { ...state.inputData, files: [...state.inputData.files, ...payload] }
    },
    selectEmoji: (state, { payload }) => {
      state.inputData = {
        ...state.inputData,
        text: state.inputData.text + payload.emoji,
        value: state.inputData.value + payload.emoji,
      }
    },
    cancelFile: (state, { payload }) => {
      if (payload !== -1) state.inputData.files.splice(payload, 1)
    },
    resetInputData: (state) => {
      state.inputData = { text: '', value: '', files: [], mentionedUsers: [], header: '' }
    },
    setScrollTop: (state, { payload }) => {
      state.isScrollTop = payload
    },
    setIsRenderedBefore: (state, { payload }) => {
      state.isRenderedBefore = payload
    },
    changeCommentData: (state, { payload }) => {
      const { text, value, mentionedUsers } = payload
      state.commentInputData[payload.postId] = {
        ...state.commentInputData,
        text,
        value,
        mentionedUsers,
      }
    },
    selectEmojiForComment: (state, { payload }) => {
      state.commentInputData[payload.postId] = {
        ...state.commentInputData[payload.postId],
        text: state.commentInputData[payload.postId].text + payload.emoji,
        value: state.commentInputData[payload.postId].value + payload.emoji,
      }
    },
    resetCommentData: (state, { payload }) => {
      state.commentInputData[payload.postId] = {
        text: '',
        value: '',
        mentionedUsers: [],
      }
    },
    setInitialUnreadPostCount: (state, { payload }) => {
      state.unreadNotificationCount = payload.count
    },
    addUnreadPost: (state, { payload }) => {
      if (payload && payload.length > 0) {
        state.posts.unshift(...payload)
        state.totalPostCount += payload.length
      }
      state.posts.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())
      state.posts = [...state.posts].map(setPostData)
    },

    postLoading: (state, { payload }) => {
      state.isPostLoading = payload
    },
    commentLoading: (state, { payload }) => {
      state.isCommentLoading = payload
    },
    createPost: (state, { payload }) => {
      payload = setPostData(payload)

      const replacablePostIndex = state.posts.findIndex(
        (item) => item.sentAt === payload.sentAt && item.status === 'pending',
      )
      if (replacablePostIndex !== -1) {
        state.posts[replacablePostIndex] = payload
        return
      }
      const postIndex = state.posts.findIndex((existingPost) => existingPost._id === payload._id)
      if (postIndex === -1) {
        state.posts.unshift(payload)
        if (payload.status !== 'pending') {
          state.totalPostCount += 1
        }
      }
    },
    updatePost: (state, { payload }) => {
      const post = setPostData(payload.post)

      const postIndex = state.posts.findIndex((existingPost) => existingPost._id === post.postId)
      const { ...croppedPost } = setPostData(post)

      if (postIndex !== -1) {
        const updatedPost = {
          ...state.posts[postIndex],
          ...croppedPost,
          users: [...state.posts[postIndex].users, ...(croppedPost.users ? croppedPost.users : [])],
        }

        state.posts[postIndex] = setPostData(updatedPost)
      }
    },
    clearAllPost: (state) => {
      state.posts = []
    },
    updateLikeCount: (state, { payload }) => {
      const post = state.posts.find(({ _id }) => _id === payload.postId)
      if (post) {
        if (payload?.type === 'increase') {
          if (!post.likes.includes(payload.myId) && !post.dislikes.includes(payload.myId)) {
            post.likes.push(payload.myId)
          } else if (post.dislikes.includes(payload.myId)) {
            post.dislikes = post.dislikes.filter((item: string) => item !== payload.myId)
            post.likes.push(payload.myId)
          } else {
            post.likes = post.likes.filter((item: string) => item !== payload.myId)
          }
        } else if (payload?.type === 'descrease') {
          if (!post.dislikes.includes(payload.myId) && !post.likes.includes(payload.myId)) {
            post.dislikes.push(payload.myId)
          } else if (post.likes.includes(payload.myId)) {
            post.likes = post.likes.filter((item: string) => item !== payload.myId)
            post.dislikes.push(payload.myId)
          } else {
            post.dislikes = post.dislikes.filter((item: string) => item !== payload.myId)
          }
        }
      }
    },
    increaseUnreadNewsFeedCount: (state) => {
      state.unreadNotificationCount += 1
    },
    decreaseUnreadNewsFeedCount: (state) => {
      if (state.unreadNotificationCount > 0) {
        state.unreadNotificationCount -= 1
      }
    },
    resetUnreadPostCount: (state) => {
      state.unreadNotificationCount = 0
    },
    deletePost: (state, { payload }) => {
      const { postId } = payload
      if (!postId) {
        const replacablePostIndex = state.posts.findIndex(
          (item) => item.sentAt === payload.sentAt && item.status === 'pending',
        )
        if (replacablePostIndex !== -1) {
          state.posts.splice(replacablePostIndex, 1)
          return
        }
      }
      const postIndex = state.posts.findIndex((existingPost) => existingPost._id === postId)
      if (postIndex !== -1) {
        state.posts.splice(postIndex, 1)
      }
    },
    setInitialNotifications: (state, { payload }) => {
      state.notifications = payload
    },
    updateRealNotificationCount: (state, { payload }) => {
      state.realNotificationCount = payload
    },
    updateRealNotificationPage: (state, { payload }) => {
      state.realNotificationPageNumber = payload
    },
    updateNotifications: (state, { payload }) => {
      state.notifications = [...state.notifications, ...payload]
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      postService.endpoints.getPaginatedPosts.matchFulfilled,
      (state, { payload }) => {
        if (payload.data.length > 0) {
          const newPosts = payload.data.filter(
            (newPost: { _id: string }) =>
              !state.posts.some((existingPost) => existingPost._id === newPost._id),
          )

          state.posts = [...state.posts, ...newPosts]
          state.totalPostCount = payload.totalPostCount
          state.pageIndex = payload.nextPageIndex || state.pageIndex + 1
        }

        state.posts = state.posts.map(setPostData)
      },
    )

    builder.addMatcher(postService.endpoints.getPostById.matchFulfilled, (state, { payload }) => {
      payload = setPostData(payload)
      const postIndex = state.posts.findIndex((post) => post._id === payload._id)
      if (postIndex !== -1) {
        state.posts[postIndex] = payload
      }
    })

    builder.addMatcher(
      postService.endpoints.getRealNotificationCount.matchFulfilled,
      (state, { payload }) => {
        state.realNotificationCount = payload.data
      },
    )

    builder.addMatcher(postService.endpoints.likePost.matchRejected, (state, action) => {
      const { myId, postId } = action.meta.arg.originalArgs
      const post = state.posts.find(({ _id }) => _id === postId)
      if (post) {
        if (post.likes.includes(myId) && !post.dislikes.includes(myId)) {
          post.likes = post.likes.filter((item: string) => {
            item !== myId
          })
          post.dislikes.push(myId)
        } else if (post.likes.includes(myId)) {
          post.likes = post.likes.filter((item: string) => {
            item !== myId
          })
        } else if (!post.likes.includes(myId)) {
          post.likes.push(myId)
        }
      }
    })

    builder.addMatcher(postService.endpoints.dislikePost.matchRejected, (state, action) => {
      const { myId, postId } = action.meta.arg.originalArgs
      const post = state.posts.find(({ _id }) => _id === postId)

      if (!post) return

      const likesIncludesMyId = post?.likes.includes(myId)
      const dislikesIncludesMyId = post?.dislikes.includes(myId)

      if (dislikesIncludesMyId) {
        post.dislikes = post.dislikes.filter((item: string) => item !== myId)
        if (!likesIncludesMyId) {
          post.likes.push(myId)
        }

        return
      }

      if (!dislikesIncludesMyId) {
        post.dislikes.push(myId)
      }
    })

    builder.addMatcher(
      postService.endpoints.getNewsFeedNotifications.matchFulfilled,
      (state, action) => {
        action.payload.data = action.payload.data.map((notification: INotificationData) => {
          return {
            ...notification,
            userData: notification.users?.reduce((acc: IUserData, currentUser) => {
              acc[currentUser._id] = currentUser
              return acc
            }, {}),
          }
        })
      },
    )

    builder.addMatcher(postService.endpoints.getCommentsOfPost.matchFulfilled, (state, action) => {
      action.payload.data = action.payload.data.map(setCommentData)
    })
  },
})
