import moment from 'moment'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { FORM, MESSAGES } from 'locales/locales'

import CustomText from 'components/text/CustomText'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

interface IUserInfoProps {
  formChecked: boolean
  name: string
  description: string
  createdBy: string
  createdAt: string
}

const GroupGeneral = ({ name, description, createdBy = '', createdAt }: IUserInfoProps) => {
  const { t } = useTranslation()
  return (
    <InfoModalWrapper>
      <FormWrapper>
        <CustomFormInput value={name} title={t(FORM.CHANNEL_NAME) as string} disabled />
        <CustomFormInput
          value={description}
          title={t(FORM.CHANNEL_DESCRIPTION) as string}
          optionalTitle={t(FORM.OPTIONAL) as string}
          disabled
        />
        <TextWrapper>
          <CustomText typography="header3">{t(MESSAGES.CREATED_BY)}</CustomText>
          <CustomText typography="body3" color={colors.gray80}>
            {`${createdBy} on ${moment(createdAt).format('DD/MM/YYYY HH:mm')}`}
          </CustomText>
        </TextWrapper>
      </FormWrapper>
    </InfoModalWrapper>
  )
}

export default GroupGeneral

const InfoModalWrapper = styled.div`
  display: flex;
  gap: 30px;
  width: 100%;
`

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 10;
`
