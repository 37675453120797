import moment from 'moment'
import { memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { IUser } from 'types/user.types'
import { MESSAGE_DATE_FORMAT } from 'utils/constants'
import { MESSAGES, USER_STATUS } from 'locales/locales'

import CustomText from 'components/text/CustomText'

interface IHeaderProps {
  createdAt?: string
  user: IUser
}
const Header = ({ user, createdAt }: IHeaderProps) => {
  const { t } = useTranslation()
  const { fullname, isDeleted } = user || {}

  return (
    <Wrapper>
      <CustomText typography="header3">{fullname}</CustomText>
   {
     //TODO Servis yazılacak rolleri getiren
     /*   {userRole && (
        <CustomText typography="body8" color={colors.gray60}>
          ({userRole})
        </CustomText>
      )}*/}
      {isDeleted && (
        <CustomText typography="body7" color={colors.gray60}>
          {t(MESSAGES.DEACTIVE)}
        </CustomText>
      )}
      <CustomText typography="body8" color={colors.gray60}>
        {moment(createdAt).format(MESSAGE_DATE_FORMAT)}
      </CustomText>
    </Wrapper>
  )
}

export default memo(Header)

const Wrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  margin-bottom: 4px;
`
