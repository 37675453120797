import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useAppSelector } from 'app/hooks'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  confirmPasswordValidation,
  emailValidation,
  generatePasswordError,
  requiredValidation,
} from 'utils/helpers/validations'
import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { passwordValidator } from 'utils/helpers/passwordValidator'
import { BUTTONS, FORM, PLACEHOLDERS, TITLES } from 'locales/locales'
import { useRegisterMutation } from '../../../app/services/auth.service'

import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { getDataFromDB } from 'utils/helpers/storageHelper'

export default function Login() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [registerState, setRegisterState] = useState({
    email: state?.email || '',
    fullname: state?.fullname || '',
    password: '',
    confirmPassword: '',
  })
  const [formChecked, setFormChecked] = useState(false)

  const currentUserId = useAppSelector((state) => state.auth.id)
  const passwordSettingsSlice = useAppSelector((state) => state.system.passwordSettings)

  const [register] = useRegisterMutation()

  const registerTest = async () => {
    if (currentUserId) {
      navigate('/news-feed')
    }
    const config = (await getDataFromDB('vimesoft', 'config')) as any
    if (config) {
      // const config = JSON.parse(localStorage.getItem('config') as string)
      if (!config?.isSystemInit) {
        navigate('/init')
      }
    }
  }

  useEffect(() => {
    registerTest()
  }, [])

  const formSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setFormChecked(true)
    const { isValid: isFullnameRequired } = requiredValidation(registerState.fullname)
    const { isValid: isValidEmail } = emailValidation(registerState.email)
    const { isValid: isRequiredPass } = requiredValidation(registerState.password)
    const { isValid: isRequiredConfirmNew } = requiredValidation(registerState.confirmPassword)
    const { isValid: isConfirm } = confirmPasswordValidation(
      registerState.password,
      registerState.confirmPassword,
    )

    if (isFullnameRequired && isValidEmail && isRequiredPass && isConfirm && isRequiredConfirmNew) {
      if (passwordSettingsSlice && Object.keys(passwordSettingsSlice).length) {
        const validate = passwordValidator(registerState.password, passwordSettingsSlice)
        if (!validate.valid) {
          return
        }
      }
      const { data }: FixMeLater = await register({
        fullname: registerState.fullname,
        password: registerState.password,
        roomId: state?.roomId,
        inviteId: state?.inviteId,
        email: state?.email ? state?.email : registerState.email,
      })
      // If register page is visited from an invitation, then pass state along with navigation.
      const navigationState = state?.inviteId
        ? {
            action: { ...state?.action, body: { roomId: state?.roomId, id: data?.userId } },
            params: { email: state?.email },
          }
        : null
      navigate('/login', { state: navigationState })
    }
  }

  const onChangeText = (key: string, value: string) => {
    setRegisterState({ ...registerState, [key]: value })
  }

  const isFullnameValid = formChecked
    ? requiredValidation(registerState.fullname, t(FORM.FULLNAME)).errorText
    : ''
  const isValidEmail = formChecked ? emailValidation(registerState.email).errorText : ''
  const isConfirmValid =
    formChecked &&
    confirmPasswordValidation(registerState.password, registerState.confirmPassword).confirmError
  const isValiPass =
    formChecked && requiredValidation(registerState.password, t(FORM.PASSWORD)).errorText
  const passValidationText = generatePasswordError(
    passwordSettingsSlice,
    registerState.password,
  ).errorText

  const passValidationColor = formChecked
    ? generatePasswordError(passwordSettingsSlice, registerState.password).color
    : colors.gray80

  return (
    <Wrapper>
      <LogoWrapper>
        <Logo id="login-logo" />
      </LogoWrapper>
      <CustomText typography="header1" color={colors.gray100}>
        {t(TITLES.REGISTER_TITLE) as string}
      </CustomText>
      <Form onSubmit={formSubmitHandler}>
        <CustomFormInput
          type={'text'}
          title={t(FORM.FULLNAME) as string}
          placeholder={t(PLACEHOLDERS.FULLNAME) as string}
          value={registerState.fullname}
          onChange={(value: string) => onChangeText('fullname', value)}
          errorText={isFullnameValid}
        />
        <CustomFormInput
          type={'email'}
          title={t(FORM.EMAIL) as string}
          placeholder={t(PLACEHOLDERS.EMAIL) as string}
          value={state?.email ? state?.email : registerState.email}
          disabled={!!state?.email}
          onChange={(value: string) => onChangeText('email', value)}
          errorText={isValidEmail}
        />
        <CustomFormInput
          type={'password'}
          title={t(FORM.PASSWORD) as string}
          value={registerState.password}
          placeholder={t(PLACEHOLDERS.PASSWORD) as string}
          onChange={(value: string) => onChangeText('password', value)}
          errorText={isValiPass}
          errorColor={passValidationColor}
          validationText={passValidationText}
        />
        <CustomFormInput
          type="password"
          title={t(FORM.CONFIRM_PASSWORD) as string}
          value={registerState.confirmPassword}
          placeholder={t(PLACEHOLDERS.PASSWORD) as string}
          onChange={(value: string) => onChangeText('confirmPassword', value)}
          errorText={isConfirmValid}
        />
        <br />
        <CustomButton typography="header1" size="large" text={t(BUTTONS.SIGN_UP) as string} />
        <br />
        <CustomText
          onClick={() => navigate('/login')}
          typography="body9"
          textAlign="center"
          color={colors.vime}
          cursor="pointer"
        >
          {t(BUTTONS.HAVE_ACCOUNT) as string}
        </CustomText>
      </Form>
    </Wrapper>
  )
}

const LogoWrapper = styled.div`
  max-height: 200px;
  max-width: 500px;
  margin-bottom: 30px;
`
const Logo = styled.img`
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 50px;
`

const Form = styled.form`
  position: relative;
  width: 372px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`
