import React from 'react';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import Avatar, { AvatarSize } from 'components/avatar/Avatar';
import CustomIcon from 'components/icon/CustomIcon';
import { icons } from 'utils/icons';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/spinner/Loading';
import useInfoModal from 'utils/hooks/useInfoModal';
import { CONFERENCE_TYPE_VIDEO, CONFERENCE_BLANK_DIMENSIONS } from 'utils/constants';
import { useCreateConversationMutation } from 'app/services/room.service';
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service';
import { UserStatus } from 'types/user.types';
import { useTranslation } from 'react-i18next';
import { ERRORS } from 'locales/locales';

interface Group {
    name: string;
    imageUrl?: string;
    backgroundColor?: string;
    members?: Member[];
}

interface Member {
    userId: string;
    fullname: string;
    status?: UserStatus; 
    imageUrl?: string;
}

interface GroupPanelProps {
    groups: Group[];
    userId: string;
    error?: string; 
}

export const GroupPanel: React.FC<GroupPanelProps> = ({ groups, userId, error }) => {
    const navigate = useNavigate();

    
    const { t } = useTranslation();
    const [createConversation] = useCreateConversationMutation();
    const [createConference] = useLazyCreateChannelConferenceQuery();
    const { openModal: openConferenceModal, closeModal: closeConferenceModal, Modal: ConferenceModal } = useInfoModal();

    const onClickChatIcon = async (memberId: string): Promise<string | null> => {
        try {
            Loading.ref.show();
            const result = await createConversation({ members: [memberId] });
            if (!result.error) {
                const roomId = typeof result.data.data === 'string' ? result.data.data : result.data.data._id;
                navigate('/messages/' + roomId);
                return roomId;
            }
        } catch (error) {
            console.error('Error in creating or fetching conversation:', error);
        } finally {
            Loading.ref.hide();
        }
        return null;
    };

    const onVideoCall = async (memberId: string) => {
        openConferenceModal({
            title: t('Create Video Conference'),
            description: t('Would you like to start a video conference?'),
            cancelTitle: t('Cancel'),
            okTitle: t('Yes'),
            onCancel: closeConferenceModal,
            onOk: () => handleChatAndConference(CONFERENCE_TYPE_VIDEO, memberId)
        });
    };

    const handleChatAndConference = async (conferenceType: string, memberId: string) => {
        const roomId = await onClickChatIcon(memberId);
        if (roomId) {
            try {
                await creatingConference(conferenceType, roomId);
            } catch (error) {
                console.error('Failed to create conference:', error);
            }
        } else {
            console.error('Failed to get room ID');
        }
    };

    const creatingConference = async (conferenceType: string, roomId: string) => {
        try {
            Loading.ref.show();
            const data = await createConference({
                roomId: roomId,
                type: 'single',
                conferenceType: conferenceType,
            });
            window.open(data.data?.url, '', CONFERENCE_BLANK_DIMENSIONS);
        } finally {
            Loading.ref.hide();
        }
    };
    if (error) {
        return <div>{t(ERRORS.DONT_HAVE_ACCESS)}</div>;
    }
    
    if (!groups.length) {
        return <div>{t(ERRORS.NO_CHANNELS_FOUND)}</div>;
    }
    

    return (
        <>
            <PanelBar>
                {groups.map(group => (
                    <PanelBarItem
                        title={
                            <GroupTitle>
                                <Avatar text={group.name} source={group.imageUrl} size={AvatarSize.small} color={group.backgroundColor || 'orange'} />
                                <GroupName>{group.name}</GroupName>
                            </GroupTitle>
                        }
                        key={group.name}
                    >
                        {group.members?.map(member => (
                            <MemberInfo key={member.userId}>
                                <MemberDetails>
                                    <Avatar source={member.imageUrl} size={AvatarSize.small} status={member.status} text={member.fullname} />
                                    <span style={{ marginLeft: '10px' }}>{member.fullname}</span>
                                    {member.status && <span style={{ marginLeft: '5px', color: 'gray' }}>({member.status})</span>}
                                </MemberDetails>
                                <IconsContainer>
                                    <CustomIcon
                                        width="18px"
                                        height="18px"
                                        source={icons.chatIconBlue}
                                        onClick={() => onClickChatIcon(member.userId)}
                                    />
                                    <CustomIcon
                                        width="18px"
                                        height="12px"
                                        source={icons.cameraIconBlue}
                                        onClick={() => onVideoCall(member.userId)}
                                    />
                                </IconsContainer>
                            </MemberInfo>
                        ))}
                    </PanelBarItem>
                ))}
            </PanelBar>
            {ConferenceModal}
        </>
    );
};

const GroupTitle = styled.div`
    display: flex;
    align-items: center;
`;

const GroupName = styled.span`
    margin-left: 10px;
`;

const MemberInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
`;

const MemberDetails = styled.div`
    display: flex;
    align-items: center;
`;

const IconsContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`;
