import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import useInfoModal from 'utils/hooks/useInfoModal'
import { useTranslation } from 'react-i18next'
import { FORM, NAVIGATION, PLACEHOLDERS } from 'locales/locales'
import styled from 'styled-components'
import { FixMeLater } from 'types'
import CollapsibleTags from 'components/tags/TagsCollapse'
import { useLazyGetUserGroupsTagsQuery } from 'app/services/group.service'
import { useEffect } from 'react'



interface IUserInfoProps {
  id:string
  avatar: string
  fullname: string
  email: string
  title: string
  team: string
  isDeleted: boolean
  formChecked: boolean
  dropDownData?: Array<FixMeLater>
  selectedOption?: FixMeLater
  onChange: (value: string | boolean, key: string) => void
}



const UserInfo = ({
  id,
  avatar,
  fullname,
  title,
  team,
  email,
  isDeleted,
  dropDownData,
  selectedOption,
  onChange,
}: IUserInfoProps) => {
  const { t } = useTranslation()
  const { Modal } = useInfoModal()
  const [trigger, { data, isLoading, error }] = useLazyGetUserGroupsTagsQuery();

  const groupTags = data?.data?.map((groupName: string) => ({ title: groupName })) || [];

  const renderCollapsibleTags = groupTags.length ? (
    <CollapsibleTagsWrapper>
      <CollapsibleTags data={groupTags} title={t(NAVIGATION.TEAMS)} maxItemsToShow={5} />
    </CollapsibleTagsWrapper>
  ) : null;



  useEffect(() => {
    trigger(id);
  }, [id, trigger]);


  return (
    <InfoModalWrapper>
      <AvatarWrapper>
        <Avatar source={avatar} text={fullname} size={AvatarSize.extraLarge} />
      </AvatarWrapper>
      <FormWrapper>
        <CustomFormInput
          disabled
          value={fullname}
          title={t(FORM.FULLNAME)}
          placeholder={t(PLACEHOLDERS.FULLNAME)}
          onChange={(value: string) => onChange(value, 'fullname')}
        />
        <CustomFormInput
          disabled
          value={email}
          title={t(FORM.EMAIL)}
          placeholder={t(PLACEHOLDERS.EMAIL)}
          onChange={(value: string) => onChange(value, 'email')}
        />
        <CustomFormInput
          disabled={isDeleted}
          value={title}
          title={t(FORM.TITLE)}
          placeholder={t(PLACEHOLDERS.TITLE)}
          onChange={(value: string) => onChange(value, 'title')}
        />
        {renderCollapsibleTags}
        {Modal}
      </FormWrapper>
    </InfoModalWrapper>
  )
}

export default UserInfo

const InfoModalWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  align-items: center;  
  gap: 20px;
  width: 100%;
`
const AvatarWrapper = styled.div`
  width: 100%;       
  display: flex;        
  justify-content: center;  
  align-items: center;
`
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const CollapsibleTagsWrapper = styled.div`
  width: 100%;
  height: 5rem;
`