import { RefObject, useEffect } from 'react'
import styled from 'styled-components'
import { FixMeLater } from 'types'
import colors from 'utils/colors'
import React from 'react'
import MentionList from './MentionList'
import { IUserData } from 'types/user.types'

interface IProps {
  items: FixMeLater
  users?: IUserData
  id: string
  mentionsDropdownRef?: RefObject<HTMLDivElement>
  onMentionDropdown?: (item: boolean) => void
}

const MentionsParent: React.FC<IProps> = ({
  items,
  users,
  id,
  mentionsDropdownRef,
  onMentionDropdown,
}) => {
  const element = document.getElementById(id)

  useEffect(() => {
    onMentionDropdown?.(true)
    return () => {
      if (element !== null) {
        setTimeout(() => {
          onMentionDropdown?.(false)
        }, 300)
      }
    }
  }, [element])

  return (
    <MentionableWraper ref={mentionsDropdownRef}>
      {items?.props?.children?.map((item: React.ReactElement<FixMeLater>) => {
        return <MentionList key={item?.key} item={item} users={users} />
      })}
    </MentionableWraper>
  )
}

export const MentionableWraper = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.light80};
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 6px 0px;
  max-height: 125px;
  overflow: auto;
`

export default MentionsParent
