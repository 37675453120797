import { createSlice } from '@reduxjs/toolkit'
import { authService } from 'app/services/auth.service'
import { clientChannel } from 'utils/constants'
import { IRolePermissions } from 'types/role.types'
import { IUser } from 'types/user.types'

interface Auth {
  token: string | null
  id: null | string
  permissions: IRolePermissions
  user: IUser | null
}

const initialAuthState: Auth = {
  token: null,
  id: null,
  permissions: {
    messaging: 0,
    newsFeed: 0,
    teamAndChannel: 0,
    settings: 0,
    videoConference: 0,
    userManagement: 0
  },
  user: null
}

export const { actions: authActions, reducer: authReducer } = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    login: (state, { payload }) => {
      state.id = payload.user._id
      state.token = payload.token
      state.permissions = payload.user.permissions;
    },
    updatePermissions: (state, {payload}) => {
      state.permissions = payload.permissions;
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(authService.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.token = payload.token
      state.id = payload.user._id
      state.permissions = payload.user.permissions;
      state.user = {
        ...payload.user,
        avatar: payload.user.avatar ?? "", 
        email: payload.user.email ?? "",
        fullname: payload.user.fullname ?? "",
      };

      clientChannel.postMessage({ type: 'LOGIN', payload })
    })
  },
})
