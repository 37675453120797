import { useEffect } from 'react'

import { clientChannel } from 'utils/constants'
import { useAppDispatch } from 'app/hooks'
import { broadcastMessageFactory } from 'utils/helpers/serviceWorkerHelper'
import { useNavigate } from 'react-router-dom'

export default function useBroadcastChannel() {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { onMessage } = broadcastMessageFactory({ dispatch, navigate })

  useEffect(() => {
    clientChannel.addEventListener('message', onMessage)
    return () => {
      clientChannel.removeEventListener('message', onMessage)
    }
  }, [])
}
