import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector } from 'app/hooks'
import styled from 'styled-components'

import { FixMeLater } from 'types'
import { IChannelMember } from 'types/user.types'
import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO,
  CHANNEL_MEMBER_LIMIT,
} from 'utils/constants'
import { BUTTONS, MODALS, TABS } from 'locales/locales'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'
import { useCreateConversationMutation } from 'app/services/room.service'

import Member from './Member'
import UserCard from 'components/new-message/user-card/UserCard'
import Loading from 'components/spinner/Loading'
import SearchInput from 'components/input/search-input/SearchInput'
import CustomText from 'components/text/CustomText'
import useInfoModal from 'utils/hooks/useInfoModal'
import { useGetTeamsMutation } from 'app/services/group.service'
import colors from 'utils/colors'
import UserGroupsModal from 'features/channels/userGroupModal'

interface IMembersProps {
  placeholder?: string
  data?: IChannelMember[]
  searchedData?: IChannelMember[]
  isUserCardDisplayable?: boolean
  isSearch?: boolean
  isInvite?: boolean
  isRemove?: boolean
  isCollapse?: boolean
  isReadOnlyPermission?: boolean
  isRoomModerator?: boolean
  isAddMember?: boolean
  isArchived?: boolean
  isChannelCreation?: boolean
  onRemoveUser?: (user: IChannelMember, isCancel?: boolean) => void
  onSelectMember?: (user: IChannelMember) => void
  onInviteMember?: (email: string) => void
  onChangeSearchText?: (text: string) => void
  onChangePermission?: (userId: string, index: number, key: string, value: boolean) => void
  isAdmin?: boolean;
}

const Members = ({
  placeholder,
  data,
  searchedData,
  isSearch,
  isInvite,
  isRemove,
  isCollapse,
  isReadOnlyPermission,
  isRoomModerator,
  isAddMember,
  isArchived,
  isUserCardDisplayable = true,
  isChannelCreation = false,
  onRemoveUser,
  onChangeSearchText,
  onSelectMember,
  onInviteMember,
  onChangePermission,
  isAdmin
}: IMembersProps) => {
  const [visibleMemberInfo, setVisibleMemberInfo] = useState<{
    visible: boolean
    data: FixMeLater
  }>({ visible: false, data: {} })

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { roomId = '', channelId = '' } = useParams()
  const { id: myId } = useAppSelector((state) => state.auth)
  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()
  const [selectedUserName, setSelectedUserName] = useState("");
  const [isUserGroupsModalVisible, setIsUserGroupsModalVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [createConversation] = useCreateConversationMutation()
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [teams, setTeams] = useState([])
  const [windowApi,setWindowApi] = useState<FixMeLater>(null);

  const [getTeams] = useGetTeamsMutation()
  useEffect(() => {
    fetchTeams()
  }, [])

  useEffect(()=>{
    const w = window as any;
    if (w.api)
    setWindowApi(w.api);
  },[])

  const fetchTeams = async () => {
    try {
      const deparments = (await getTeams({})) as FixMeLater
      setTeams(deparments?.data?.data)
    } catch (err) {
      console.log(err)
    }
  }
  const onCloseMemberInfoCard = () => {
    setVisibleMemberInfo({ visible: false, data: {} })
  }


  const onUsersGrup = (userId: string, fullname: string) => {
    setIsUserGroupsModalVisible(true);
    setSelectedUserId(userId);
    setSelectedUserName(fullname);  
  };
  
  const closeUserGroupsModal = () => {
    setIsUserGroupsModalVisible(false);
  };


  const onShowMemberInfoCard = (user: IChannelMember) => {
    if (user._id !== myId && isUserCardDisplayable) {
      setVisibleMemberInfo({ visible: true, data: { ...user } })
    }
  }

  const onVideoCall = async () => {
    if (roomId || channelId) {
      openConferenceModal({
        title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
        description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
        cancelTitle: t(BUTTONS.CANCEL) as string,
        okTitle: t(BUTTONS.YES) as string,
        onCancel: closeConferenceModal,
        onOk: () => creatingConference(CONFERENCE_TYPE_VIDEO),
      })
    }
  }



  const creatingConference = async (conferenceType: string) => {
    try {
      Loading.ref.show()
      const data = await createConference({
        roomId: roomId || channelId,
        type: 'single',
        conferenceType: conferenceType,
      })
   if(windowApi){
        windowApi.send('openConferenceUrl',{url:data.data?.url});
      }else{
        window.open(data.data?.url, '', CONFERENCE_BLANK_DIMENSIONS)
      }
    } finally {
      Loading.ref.hide()
      closeConferenceModal()
    }
  }
  const onClickChatIcon = async () => {
    try {
      Loading.ref.show()
      const result: FixMeLater = await createConversation({ members: [visibleMemberInfo.data._id] })
      if (!result.error) {
        // room already exists
        if (typeof result.data.data === 'string') {
          navigate('/messages/' + result.data.data)
          // room doesn't exist
        } else {
          navigate('/messages/' + result.data.data._id)
        }
      }
    } finally {
      Loading.ref.hide()
    }
  }
  const team = teams?.find(
    (team: FixMeLater) => team?.value === visibleMemberInfo.data.team,
  ) as FixMeLater
  return (
    <>
      {visibleMemberInfo.visible && (
        <UserCard
          {...visibleMemberInfo.data}
          team={team?.title}
          isCentered={true}
          onClickUserGroups={() => onUsersGrup(visibleMemberInfo.data._id, visibleMemberInfo.data.fullname)}
          onClickCameraIcon={onVideoCall}
          onClickChatIcon={onClickChatIcon}
        />
      )}
      <MembersWrapper>
        {isSearch && !isArchived && (
          <SearchInputWrapper>
            <SearchInput
              showInDropdown
              isInvite={isInvite}
              placeHolder={placeholder}
              searchedData={searchedData}
              isAddMember={isAddMember}
              isChannelCreation={isChannelCreation}
              members={data}
              onChange={onChangeSearchText}
              onSelectMember={onSelectMember}
              onInviteMember={onInviteMember}
            />
          </SearchInputWrapper>
        )}

        {!!data && (
          <MembersBottomWrapper>
            {(isRoomModerator || isAdmin) && (
              <MemberInfoText
                typography="header5"
                color={
                  (isChannelCreation ? data?.length + 1 : data?.length) >= CHANNEL_MEMBER_LIMIT
                    ? colors.red
                    : 'initial'
                }
              >
                {`${
                  isChannelCreation ? data?.length + 1 : data?.length
                }/${CHANNEL_MEMBER_LIMIT} ${t(TABS.MEMBERS)}`}
              </MemberInfoText>
            )}
            <MemberListWrapper>
              {data?.map((user: IChannelMember) => (
                <Member
                  key={user.email}
                  {...user}
                  isRemove={isRemove}
                  isArchived={isArchived}
                  isMe={user._id === myId}
                  isCollapse={isCollapse}
                  isModerator={user?.isModerator || false}
                  isRoomModerator={isRoomModerator}
                  isReadOnlyPermission={isReadOnlyPermission}
                  onResendMember={() => onInviteMember?.(user.email)}
                  onShowMemberInfo={() => onShowMemberInfoCard(user)}
                  onRemoveUser={(isCancel?: boolean) => onRemoveUser?.(user, isCancel)}
                  onChangePermission={(pIndex: number, key: string, value: boolean) =>
                    onChangePermission?.(user?._id || user?.email, pIndex, key, value)
                  }
                />
              ))}
            </MemberListWrapper>
          </MembersBottomWrapper>
        )}
      </MembersWrapper>
      {ConferenceModal}
      {isUserGroupsModalVisible && selectedUserId && (
      <UserGroupsModal 
        onClose={closeUserGroupsModal} 
        isVisible={isUserGroupsModalVisible} 
        userId={selectedUserId}
        userName={selectedUserName} 
        /> )}
      {visibleMemberInfo.visible && <MemberUserCardBackDrop onClick={onCloseMemberInfoCard} />}
    </>
  )
}

export default Members

const MembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
  margin-top: 5px;
`
const MembersBottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 370px;
  overflow-y: auto;
`

export const MemberInfoText = styled(CustomText)``

const MemberListWrapper = styled.div`
  overflow-y: auto;
`

const MemberUserCardBackDrop = styled.div`
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px);
`
