import { useEffect, useState } from 'react'
import CustomModal from 'components/modals/CustomModal'
import Members from 'components/members/Members'
import {
  useAddGroupMemberMutation,
  useLazyGetMembersBySearchForGroupQuery,
  useLazyGetGroupByIdForAdminQuery,
  useRemoveGroupMemberMutation,
  useUpdateGroupMutation,
} from 'app/services/group.service'
import { IChannelMember, IUser } from 'types/user.types'
import { FixMeLater } from 'types'
import useInfoModal from 'utils/hooks/useInfoModal'
import GroupInfo from './GroupInfo'
import GroupStatistic from './GroupStatistic'
import { useTranslation } from 'react-i18next'
import { BUTTONS, MODALS, PLACEHOLDERS, TABS } from 'locales/locales'
import { requiredValidation } from 'utils/helpers/validations'
import { useAppSelector } from 'app/hooks'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'

interface ICreateUserModalProps {
  groupId: string
  fetchGroups: () => void
  onClose: () => void
}

const GroupInfoModal = ({ groupId, fetchGroups, onClose }: ICreateUserModalProps) => {
  const { t } = useTranslation()
  const [getGroupInfoById, { data }] = useLazyGetGroupByIdForAdminQuery()
  const { openModal, closeModal, Modal } = useInfoModal()
  const [formChecked, setFormChecked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [generalInfo, setGeneralInfo] = useState({ ...data })
  const [statisticInfo, setStatisticInfo] = useState({ ...data })
  const [groupMembers, setGroupMembers] = useState<IChannelMember[]>([])
  const [membersBySearch, setMembersBySearch] = useState<IChannelMember[]>([])
  const [updateGroup] = useUpdateGroupMutation()
  const [addGroupMember] = useAddGroupMemberMutation()
  const [removeGroupMember] = useRemoveGroupMemberMutation()
  const [getMembersBySearch] = useLazyGetMembersBySearchForGroupQuery()
  const permissions = useAppSelector<IRolePermissions>(state => state.auth.permissions);
  const isAdmin = isFlagSetIn([TeamAndChannelPermissions.UPDATE_CHANNEL], permissions.teamAndChannel);

  useEffect(() => {
    fetchGroupInfo()
  }, [])
  const fetchGroupInfo = async () => {
    try {
      const result: FixMeLater = await getGroupInfoById({ groupId: groupId })
      setGeneralInfo(result.data.team)
      setStatisticInfo(result.data.statistic)
      setGroupMembers(result.data.team.members)
    } catch {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeInput = (value: string | boolean, key: string) => {
    setGeneralInfo({ ...generalInfo, [key]: value })
  }

  const onConfirmOk = async () => {
    setFormChecked(true)
    const { isValid } = requiredValidation(generalInfo.name)
    if (!isValid) {
      closeModal()
      return
    }
    try {
      setIsLoading(true)
      await updateGroup({
        id: groupId,
        data: {
          name: generalInfo.name,
          description: generalInfo.description,
          imageUrl: generalInfo.imageUrl,
        },
      })
      onClose()
      fetchGroups()
    } finally {
      setIsLoading(false)
    }
    closeModal()
    setFormChecked(false)
  }

  const onOk = async () => {
    openModal({
      title: t(MODALS.UPDATE_TEAM_CONFIRM_TITLE),
      description: t(MODALS.UPDATE_TEAM_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.SAVE) as string,
      onCancel: onConfirmCancel,
      onOk: onConfirmOk,
    })
  }
  const onConfirmCancel = () => {
    closeModal()
  }

  const uploadAvatar = async (fileUrl: string) => {
    setGeneralInfo({ ...generalInfo, imageUrl: fileUrl })
  }
  const onSearch = async (text: string) => {
    try {
      const result: FixMeLater = await getMembersBySearch({
        groupId: groupId,
        searchName: text,
      })
      const searchedUsers = [...result.data.data]
      if (groupMembers.length > 0) {
        return setMembersBySearch([
          ...searchedUsers.sort((member: IUser) => {
            if (groupMembers?.some((m) => m._id === member._id)) {
              return 1
            }
            return -1
          }),
        ])
      }
      setMembersBySearch([...searchedUsers])
    } catch (e) {
      console.log(e)
    }
  }

  const onRemoveMember = async (user: IChannelMember) => {
    try {
      await removeGroupMember({ groupId: groupId, userId: user._id })
      const removedUserFromMembersData = groupMembers.filter(
        (member: IChannelMember) => member._id !== user._id,
      )
      setGroupMembers([...removedUserFromMembersData])
    } finally {
      fetchGroups()
    }
  }

  const onAddMember = async (user: IChannelMember) => {
    try {
      const data = (await addGroupMember({ groupId: groupId, userId: user._id })) as FixMeLater
      if (!data?.error) {
        setGroupMembers([...groupMembers, user])
      }
    } finally {
      fetchGroups()
    }
  }

  return (
    <>
      <CustomModal
        closeModal={onClose}
        title={generalInfo.name}
        onCancel={onClose}
        onOk={onOk}
        loading={isLoading}
        tab={[
          { title: t(TABS.GENERAL), action: true },
          { title: t(TABS.MEMBERS), action: false },
          { title: t(TABS.STATISTIC), action: false },
        ]}
        showButtons={false}
        hasMember
      >
        <GroupInfo
          {...generalInfo}
          onUploadPhoto={uploadAvatar}
          formChecked={formChecked}
          onChange={onChangeInput}
        />
        <Members
          isSearch={true}
          isRemove={true}
          isCollapse
          isAddMember
          isReadOnlyPermission
          isAdmin={isAdmin}
          isUserCardDisplayable={true}
          placeholder={t(PLACEHOLDERS.MEMBERS_PLACEHOLDER) as string}
          data={groupMembers}
          searchedData={membersBySearch}
          onRemoveUser={onRemoveMember}
          onChangeSearchText={onSearch}
          onSelectMember={onAddMember}
        />
        <GroupStatistic {...statisticInfo} />
      </CustomModal>
      {Modal}
    </>
  )
}

export default GroupInfoModal
