import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  confirmPasswordValidation,
  generatePasswordError,
  requiredValidation,
} from 'utils/helpers/validations'
import colors from 'utils/colors'
import { useAppSelector } from 'app/hooks'
import { useUpdatePasswordMutation } from 'app/services/auth.service'
import { useCheckResetPasswordLinkQuery } from 'app/services/invite.service'
import { BUTTONS, FORM, NAVIGATION, PLACEHOLDERS } from 'locales/locales'
import { passwordValidator } from 'utils/helpers/passwordValidator'

import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

const ResetPassword = () => {
  const passwordSettingsSlice = useAppSelector((state) => state.system.passwordSettings)
  const { t } = useTranslation()
  const [formChecked, setFormChecked] = useState(false)
  const [password, onChangePassword] = useState('')
  const [confirmPassword, onChangeConfirmPassword] = useState('')
  const params = useParams<{ inviteId: string }>()

  const navigate = useNavigate()
  const [updatePassword] = useUpdatePasswordMutation()
  const {
    data: checkData,
    isSuccess: checkIsSuccess,
    isError: checkIsError,
  } = useCheckResetPasswordLinkQuery(
    { inviteId: params.inviteId },
    { refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (checkData?.success === false) {
      navigate('/news-feed')
    }
  }, [checkIsSuccess, checkIsError])

  const handleCreatePassword = async () => {
    setFormChecked(true)
    const { isValid: isRequiredNew } = requiredValidation(password)
    const { isValid: isRequiredConfirm } = requiredValidation(confirmPassword)
    const { isValid: isConfirm } = confirmPasswordValidation(password, confirmPassword)
    if (isConfirm && isRequiredNew && isRequiredConfirm) {
      if (passwordSettingsSlice && Object.keys(passwordSettingsSlice).length) {
        const validate = await passwordValidator(password, passwordSettingsSlice)
        if (!validate.valid) {
          return
        }
      }

      await updatePassword({ password: password, inviteId: params.inviteId })
      navigate('/login')
    }
  }
  const isConfirmValid =
    formChecked && confirmPasswordValidation(password, confirmPassword).confirmError
  const isValiPass = formChecked && requiredValidation(password, t(FORM.PASSWORD)).errorText
  const passValidationText = generatePasswordError(passwordSettingsSlice, password).errorText

  const passValidationColor = formChecked
    ? generatePasswordError(passwordSettingsSlice, password).color
    : colors.gray80

  return (
    <ModalWrapper>
      <LogoWrapper>
        <Logo id="login-logo" />
      </LogoWrapper>
      <HeadWrapper>
        <CustomText typography="header1">{t(NAVIGATION.RESET_PASSWORD) as string}</CustomText>
      </HeadWrapper>
      <BottomWrapper>
        <InputWrapper>
          <CustomFormInput
            placeholder={t(PLACEHOLDERS.PASSWORD) as string}
            value={password}
            title={t(FORM.NEW_PASSWORD) as string}
            onChange={onChangePassword}
            errorColor={passValidationColor}
            validationText={passValidationText}
            errorText={isValiPass}
            type="password"
          />
        </InputWrapper>
        <InputWrapper>
          <CustomFormInput
            errorText={isConfirmValid}
            title={t(FORM.CONFIRM_PASSWORD) as string}
            placeholder={t(PLACEHOLDERS.PASSWORD) as string}
            value={confirmPassword}
            onChange={onChangeConfirmPassword}
            type="password"
          />
        </InputWrapper>
        <div style={{ width: '100%', marginBottom: '32px' }}>
          <CustomButton
            typography="header1"
            size="large"
            onClick={handleCreatePassword}
            text={t(BUTTONS.RESET_PASSWORD) as string}
          />
        </div>
        <CustomText
          onClick={() => navigate('/login')}
          cursor="pointer"
          color={colors.vime}
          typography="body4"
        >
          {t(BUTTONS.BACK_TO_SIGN_IN) as string}
        </CustomText>
      </BottomWrapper>
    </ModalWrapper>
  )
}

export default ResetPassword

const LogoWrapper = styled.div`
  max-height: 200px;
  max-width: 500px;
  margin-bottom: 30px;
`
const Logo = styled.img`
  width: 100%;
  height: 100%;
`
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 326px;
  align-items: center;
  gap: 16px;
  margin-bottom: 60px;
`

const BottomWrapper = styled.div`
  gap: 16px;
  max-width: 342px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 100vh;
  background-color: ${colors.white};
`

const InputWrapper = styled.div`
  width: 328px;
`
