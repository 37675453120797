import styled from 'styled-components'
import CustomText from 'components/text/CustomText'

import Separator from 'components/separator/Separator'
import Checkbox from 'components/checkbox/Checkbox'
import { TUserPermissionsData } from 'types/user.types'

interface ICollapseProps {
  data?: TUserPermissionsData
  disabled?: boolean
  onChange: (index: number, key: string, value: boolean) => void
}

const Collapse = ({ data, disabled, onChange }: ICollapseProps) => {
  return (
    <>
      <Wrapper>
        {data?.map((item, index: number) => {
          return (
            <CheckboxWrapper
              key={index}
              disabled={disabled}
              onClick={() => !disabled && onChange(index, item.key, !item.value)}
            >
              <Checkbox checked={item?.value} />
              <CustomText typography="body8">{item.title}</CustomText>
            </CheckboxWrapper>
          )
        })}
      </Wrapper>
      <Separator margin={{ bottom: 10 }} />
    </>
  )
}

export default Collapse

const Wrapper = styled.div`
  padding: 15px 70px;
`
const CheckboxWrapper = styled.div.attrs((props: { disabled: boolean }) => props)`
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
`
