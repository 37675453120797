import api from 'app/api'

export const conversationService = api.injectEndpoints({
  endpoints: (build) => {
    return {
      sendMessage: build.mutation({
        query: (messageArgs) => ({
          url: 'message/',
          method: 'POST',
          body: messageArgs,
        }),
        invalidatesTags: ['SEND_MESSAGE'],
      }),
      sendVanishMessage: build.mutation({
        query: (messageArgs) => ({
          url: 'message/vanish',
          method: 'POST',
          body: messageArgs,
        }),
        invalidatesTags: ['SEND_VANISH_MESSAGE'],
      }),
      sendThreadMessage: build.mutation({
        query: ({ messageId, ...rest }) => ({
          url: `message/${messageId}/thread`,
          method: 'POST',
          body: rest,
        }),
        invalidatesTags: ['SEND_THREAD_MESSAGE'],
      }),
      getThreadMessage: build.query({
        query: ({ messageId }) => ({ url: `message/${messageId}/list/replies` }),
      }),
      getMessagesByPage: build.mutation({
        query: ({ roomId, pageNumber }) => ({
          url: `message/${roomId}/list/page/${pageNumber}`,
        }),
        invalidatesTags: ['GET_MESSAGES_BY_PAGE'],
      }),
      updateMessageReaction: build.mutation({
        query: ({ messageId, ...args }) => ({
          url: `message/${messageId}/reaction`,
          method: 'PUT',
          body: args,
        }),
        invalidatesTags: ['UPDATE_REACTION'],
      }),
      deleteMessage: build.mutation({
        query: ({ messageId }) => ({
          url: `message/${messageId}/delete`,
          method: 'PUT',
        }),
        invalidatesTags: ['DELETE_MESSAGE'],
      }),
      editMessage: build.mutation({
        query: ({ messageId, ...rest }) => ({
          url: `message/${messageId}`,
          method: 'PUT',
          body: rest,
        }),
        invalidatesTags: ['EDIT_MESSAGE'],
      }),
      getSearchMessages: build.query({
        query: ({ roomId, content }) => ({
          url: `message/${roomId}/list/search/${content}`,
        }),
      }),
      getJumpMessages: build.query({
        query: ({ roomId, messageId }) => ({
          url: `message/${messageId}/${roomId}/jump`,
        }),
      }),
      getReconnectedMessages: build.query({
        query: ({ roomId, createdAt }) => ({
          url: `message/list?roomId=${roomId}&createdAt=${createdAt}`,
        }),
      }),
      closeThread: build.mutation({
        query: ({ threadId, roomId }) => ({
          url: `subscription/close/thread/${threadId}`,
          method: 'PUT',
          body: { roomId },
        }),
      }),
    }
  },
})

export const {
  useSendMessageMutation,
  useGetThreadMessageQuery,
  useSendThreadMessageMutation,
  useGetMessagesByPageMutation,
  useUpdateMessageReactionMutation,
  useDeleteMessageMutation,
  useEditMessageMutation,
  useLazyGetSearchMessagesQuery,
  useLazyGetJumpMessagesQuery,
  useSendVanishMessageMutation,
  useLazyGetReconnectedMessagesQuery,
  useCloseThreadMutation,
} = conversationService
