import api from 'app/api'

export const inviteService = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      acceptInvite: builder.mutation({
        query: ({ path, body }) => ({ url: path, method: 'POST', body }),
      }),
      checkInvitation: builder.query({
        query: ({ inviteId, roomId }) => ({
          url: `public/invites/${inviteId}/check/invite?roomId=${roomId ?? ''}`,
        }),
      }),
      cancelInvitation: builder.mutation({
        query: ({ email, roomId }) => ({
          url: `invite/${roomId}/cancel`,
          method: 'PUT',
          body: { email },
        }),
      }),
      checkResetPasswordLink: builder.query({
        query: (args) => ({
          url: `public/invites/${args.inviteId}/check/password`,
          method: 'GET',
        }),
      }),
      resendInvite: builder.mutation({
        query: (args) => ({
          url: `invite/resend`,
          method: 'POST',
          body: args,
        }),
      }),
    }
  },
})

export const {
  useResendInviteMutation,
  useAcceptInviteMutation,
  useLazyCheckInvitationQuery,
  useCancelInvitationMutation,
  useCheckResetPasswordLinkQuery,
} = inviteService
