import { icons } from 'utils/icons'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import { useTranslation } from 'react-i18next'
import { EMPTY } from 'locales/locales'
import colors from 'utils/colors'
import styled from 'styled-components'

export default function EmptyNewsFeed() {
  const { t } = useTranslation()
  return (
    <Container>
      <EmptyNewsFeedWrapper>
        <div>
          <CustomIcon width="202px" height="202px" source={icons.newsFeedIconBig} />
        </div>
        <TextWrapper>
          <CustomText textAlign="center" typography="header1">
            {t(EMPTY.EMPTY_NEWS_FEED_TITLE)}
          </CustomText>
        </TextWrapper>
        <CustomText textAlign="center" typography="body3" color={colors.gray80}>
          {t(EMPTY.EMPTY_NEWS_FEED_DESCRIPTION)}
        </CustomText>
      </EmptyNewsFeedWrapper>
    </Container>
  )
}

const Container = styled.div`
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptyNewsFeedWrapper = styled.div`
  min-height: 80vh;
  max-width: 50%;
  min-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 730px) {
    max-width: 100%;
    min-width: 100%;
  }
`

const TextWrapper = styled.div`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`
