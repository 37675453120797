import CustomFile from 'components/file/CustomFile'
import CustomIcon from 'components/icon/CustomIcon'
import { useState } from 'react'

import styled from 'styled-components'
import { FixMeLater } from 'types'
import { IUserData } from 'types/user.types'
import colors from 'utils/colors'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import { convertTextForInitialInputData } from 'utils/helpers/messageHelper'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { icons } from 'utils/icons'
import CommonInput from '../_components/common-input/CommonInput'
import PostHeader from '../_components/post-header/PostHeader'
import { TextBox } from '@progress/kendo-react-inputs'

interface IContentProps {
  data: {
    header:string;
    text: string
    files: FixMeLater[]
    mentionedUsers: Array<string>
  }
  commentToggle: boolean;
  users: IUserData
  placeHolder: string
  avatar: string
  fullname: string
  isValidEditPost: boolean
  isPostLoading: boolean
  header:string
  isComment:boolean
  onChangeInputHeader:(e:FixMeLater) => void,
  onClose: () => void
  onCancelFile: (index: number) => void
  onChangeText: (text: string) => void
  onEmojiClick: (emojiData: FixMeLater) => void
  onEditPost: () => void
}

const Content = ({
  data,
  users,
  placeHolder,
  avatar,
  fullname,
  isValidEditPost,
  isPostLoading,
  header,
  isComment,
  onChangeInputHeader,
  onClose,
  onCancelFile,
  onChangeText,
  onEmojiClick,
  onEditPost,
}: IContentProps) => {

  
  const initialInputData = convertTextForInitialInputData(data, users)
  const [mentionsDropdown, setMentionsDropdown] = useState(false)

  const { screenWidth } = useWindowDimensions()

  const handleOnChange = (text: string) => {
    onChangeText(text)
  }

  const emojiHandler = (emojiData: FixMeLater) => {
    onEmojiClick(emojiData)
  }

  const onKeyUp = (event: FixMeLater) => {
    event.preventDefault()
    if (
      event.key === 'Enter' &&
      isValidEditPost &&
      !event.shiftKey &&
      !mentionsDropdown &&
      !isPostLoading
    ) {
      onEditPost()
      onClose()
    }
  }
  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  return (
    <Wrapper>
      <PostHeader avatar={avatar} fullname={fullname} />
      {!isComment && (
        <div style={{ marginTop: '10px' }}>
          <TextBox
            value={header}
            onChange={onChangeInputHeader}
            style={{ fontSize: 16, borderRadius: 25, padding: '6px 10px'}}
            placeholder="Başlık Giriniz..."
          />
        </div>
      )}

      <CommonInput
        id={'common_edit_input'}
        textInfo
        data={initialInputData}
        placeHolder={placeHolder}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onSelectEmoji={emojiHandler}
        onMentionDropDown={setMentionsDropdown}
        onChangeInputData={handleOnChange}
      />
      {data?.files && data.files.length > 0 && (
        <Layout onClick={(event) => event.stopPropagation()}>
          {data.files.map((file: FixMeLater, index: number) => {
            const url: string = file?.url || URL.createObjectURL(file)
            const name = file.originalName
            const mimetype = file?.mimetype || file?.type
            const size = file?.size
            return (
              <FileMessageWrapper key={file?._id || file?.lastModified}>
                <CloseIcon
                  id="cancel-file"
                  iconPreset="small"
                  source={icons.closeIconWhite}
                  isCancel={false}
                  onClick={() => onCancelFile(file)}
                />
                <CustomFile
                  index={index}
                  files={data.files}
                  sentAt={file.sentAt}
                  source={url}
                  mimetype={mimetype}
                  preset={screenWidth > MOBILE_DEVICE_WIDTH ? 'editPost' : 'editPostResponsive'}
                  size={size}
                  filename={name}
                  thumbnail={file?.thumbnail}
                />
              </FileMessageWrapper>
            )
          })}
        </Layout>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Layout = styled.div`
  margin-bottom: 24px;
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
const FileMessageWrapper = styled.div`
  position: relative;
  #cancel-file {
    display: flex;
  }
  #loading-file {
    display: none;
  }
`

const CloseIcon = styled(CustomIcon).attrs((props: { isCancel: boolean }) => props)`
  display: ${({ isCancel }) => (isCancel ? 'flex' : 'none')};
  position: absolute;
  top: -5px;
  right: -5px;
  flex-shrink: 0;
  background-color: ${colors.vime};
  border-radius: 50%;

  align-items: center;
  justify-content: center;
  z-index: 2;
`

export default Content
