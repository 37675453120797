import { useTranslation } from 'react-i18next'
import { uniqueId } from 'lodash'
import { useRef } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'
import useUploadPhoto from 'utils/hooks/useUploadPhoto'

import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import CustomButton from 'components/button/CustomButton'
import CustomText from 'components/text/CustomText'
import Spinner from 'components/spinner/Spinner'

type IImageTyoe = 'avatar' | 'logo' | 'favicon'

interface IUploadImage {
  type?: IImageTyoe
  avatar: string
  title?: string
  text?: string
  buttonText?: string
  avatarSize?: AvatarSize
  onUploadPhoto: (fileUrl: string) => void
}

const UploadImage = ({
  type = 'avatar',
  avatar,
  title = '',
  text,
  buttonText,
  avatarSize,
  onUploadPhoto,
}: IUploadImage) => {
  const { t } = useTranslation()
  const ref = useRef<HTMLInputElement>(null)
  const { onUploadPhoto: onUploadImage, isUploadLoading: loading } = useUploadPhoto(onUploadPhoto)
  return (
    <AvatarWrapper htmlFor={uniqueId()}>
      {title && <CustomText typography="header3">{title}</CustomText>}
      {type === 'avatar' && (
        <>
          <Avatar source={avatar} text={text} size={avatarSize || AvatarSize.medium}>
            <Spinner loading={loading} position="absolute" />
          </Avatar>
        </>
      )}
      {type === 'logo' && (
        <ImageWrapper>
          <LogoImage src={avatar} />
          <Spinner loading={loading} position="absolute" />
        </ImageWrapper>
      )}
      {type === 'favicon' && (
        <ImageWrapper>
          <FaviconImage src={avatar} />
          <Spinner loading={loading} position="absolute" />
        </ImageWrapper>
      )}
      <input
        type="file"
        id={uniqueId()}
        accept="image/png, image/gif, image/jpeg"
        ref={ref}
        onChange={(e) => onUploadImage(e.target.files as FileList)}
        style={{ display: 'none' }}
      />
      {buttonText && (
        <CustomButton
          onClick={() => ref.current && ref.current.click()}
          text={t(buttonText)}
          size="small"
          typography="body7"
        />
      )}
    </AvatarWrapper>
  )
}

export default UploadImage

const ImageWrapper = styled.div`
  display: flex;
  position: relative;
`

const AvatarWrapper = styled.label`
  display: flex;
  height: fit-content;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  position: relative;
`

const LogoImage = styled.img`
  padding: 1px;
  max-height: 74px;
  min-height: 74px;
  min-width: 247px;
  width: 100%;
  border-radius: 4px;
  object-fit: contain;
  border: 1px solid ${colors.light80};
  outline: none;
`

const FaviconImage = styled.img`
  object-fit: contain;
  padding: 1px;
  max-height: 74px;
  min-height: 74px;
  max-width: 74px;
  border-radius: 4px;
  border: 1px solid ${colors.light80};
  outline: none;
`
