import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'

import { customTextTypography, CustomTextTypographyKey } from './customTextTypography'
import { isElement } from 'lodash'
import { useTranslation } from 'react-i18next'
import { POST } from 'locales/locales'

interface ICustomTextProps {
  id?: string
  typography?: CustomTextTypographyKey
  color?: string
  display?: string
  children: React.ReactNode
  marginLeft?: number
  cursor?: string
  textAlign?: string
  className?: string
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isCollapsible?: boolean
}

interface ITextProps {
  fontSize: number
  fontWeight: number
  lineHeight: number
  color?: string
  display?: string
  marginLeft?: number
  cursor?: string
  textAlign?: string
  messageSearch?: boolean
  onClick?: () => void
  isExpanded?: boolean
  maxHeight?: string
}

const CustomText = ({
  id,
  typography,
  color,
  children,
  display,
  marginLeft,
  cursor,
  textAlign,
  className,
  onClick = () => '',
  isCollapsible = false,
}: ICustomTextProps) => {

  const { t } = useTranslation()

  const [isExpanded, setIsExpanded] = useState(false)
  const [showToggle, setShowToggle] = useState(false)
  const [maxHeight, setMaxHeight] = useState('0px')

  const toggleExpand = () => setIsExpanded(!isExpanded)

  const typographyStyle = customTextTypography[typography || 'body2']

  const contentRef = useRef<HTMLDivElement>(null)

  const { lineHeight } = typographyStyle

  useEffect(() => {
    if (contentRef.current && isCollapsible) {
      const contentHeight = contentRef.current.scrollHeight
      const maxCollapsedHeight = lineHeight * 2
      setShowToggle(contentHeight > maxCollapsedHeight)
      setMaxHeight(`${contentRef.current.scrollHeight}px`)
    }
  }, [children, isCollapsible])

  return (
    <Container
      id={id}
      onClick={onClick as () => void}
      className={className + (isCollapsible ? ' collapsible' : '')}
      {...typographyStyle}
      display={display}
      color={color}
      textAlign={textAlign}
      marginLeft={marginLeft}
      cursor={cursor}
      isExpanded={isExpanded}
      lineHeight={lineHeight}
      maxHeight={isExpanded ? maxHeight : `calc(${lineHeight * 2}px)`}
    >
      <div ref={contentRef}>{children}</div>
      {isCollapsible && showToggle && (
        <div className="read_more_container">
          <button className="button_read_more" onClick={toggleExpand}>
            {isExpanded ? t(POST.READ_LESS) : t(POST.READ_MORE)}
          </button>
        </div>
      )}
    </Container>
  )
}

export default CustomText

const Container = styled.div`
  display: ${(props: ITextProps) => props?.display || 'block'};
  font-size: ${(props: ITextProps) => props.fontSize}px;
  font-weight: ${(props: ITextProps) => props.fontWeight};
  line-height: ${(props: ITextProps) => props.lineHeight}px;
  color: ${(props: ITextProps) => props?.color || colors.gray100};
  margin-left: ${(props: ITextProps) => props.marginLeft || 0}px;
  cursor: ${(props: ITextProps) => props.cursor || 'inherit'};
  text-align: ${(props: ITextProps) => props.textAlign || 'start'};
  word-break: break-word;
  white-space: pre-line;

  &.collapsible > div {
    transition: max-height 0.3s ease;
    overflow: hidden;
    word-break: break-word;
    white-space: pre-line;
    overflow: hidden;
    max-height: ${(props: ITextProps) =>
      props.isExpanded ? `${props.maxHeight}` : `calc(${props.lineHeight * 5}px)`};
  }

  &.collapsible > .read_more_container {
    text-align: right;
  }
`
