import { useTranslation } from 'react-i18next'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { t } from 'i18next'

import {
  useLazyGetGroupsForAdminQuery,
  useToggleTeamArchiveMutation,
} from 'app/services/group.service'
import { BUTTONS, DROPDOWN, MODALS, TABLE, TITLES } from 'locales/locales'
import CreateGroupModal from './create-group/CreateGroupModal'
import SettingsHeader from 'features/settings/SettingsHeader'
import GroupInfoModal from './group-info/GroupInfoModal'
import { FixMeLater } from 'types'

import CustomButton from 'components/button/CustomButton'
import colors from 'utils/colors'
import useInfoModal from 'utils/hooks/useInfoModal'
import Loading from 'components/spinner/Loading'
import { IGroup } from 'types/group.types'
import KGrid from 'components/kendo/grid/KGrid'
import { GridColumn } from '@progress/kendo-react-grid'
import { Button } from '@progress/kendo-react-buttons'
import { FaArchive, FaCheck, FaInfoCircle } from 'react-icons/fa'

export const GROUP_DROPDOWN_DATA = [
  {
    title: t(DROPDOWN.ALL_TEAM),
    value: 'all_group',
  },
  {
    title: t(DROPDOWN.ACTIVE_TEAM),
    value: 'active_group',
  },

  {
    title: t(DROPDOWN.DEACTIVE_TEAM),
    value: 'deactive_group',
  },
]

const queryBodyInitial = {
  search: '',
  filter: '',
  sort: false,
  column: 'createdAt',
  pageNumber: 1,
  limit: 10,
}

const GroupManagement = () => {
  const { t } = useTranslation()
  const [groupId, setGroupId] = useState('')
  const [visibleCreateGropModal, setVisibleCreateGroupModal] = useState(false)
  const [queryBody, setQueryBody] = useState(queryBodyInitial)
  const [currentData, setCurrentData] = useState({ data: [] as FixMeLater[], totalCount: 1 })
  const [getAllGroups] = useLazyGetGroupsForAdminQuery()
  const [channelInfoModalVisible, setChannelInfoModalVisible] = useState(false)
  const [toggleTeamArchivingStatus] = useToggleTeamArchiveMutation()
  const { openModal, closeModal, Modal } = useInfoModal()

  useEffect(() => {
    fetchGroups()
  }, [queryBody])

  const onConfirmArchiveGroup = async (groupId: string) => {
    try {
      closeModal()
      Loading.ref.show()
      await toggleTeamArchivingStatus({ groupId })
      await fetchGroups()
    } finally {
      Loading.ref.hide()
    }
  }

  const onCloseConfirmArchiveGroup = () => {
    closeModal()
  }

  const onToggleArchive = async (group: IGroup) => {
    const groupId = group._id
    setGroupId(groupId)
    setCurrentData((state) => {
      const clonedState = { ...state }
      const groupIndex = clonedState.data.findIndex((g) => g._id === groupId)
      if (groupIndex !== -1) {
        clonedState.data[groupIndex] = {
          ...clonedState.data[groupIndex],
          isArchived: !clonedState.data[groupIndex].isArchived,
        }
      }
      return clonedState
    })
    openModal({
      title: group.isArchived ? t(MODALS.UNARCHIVE_TITLE) : t(MODALS.ARCHIVE_TITLE),
      description: group.isArchived
        ? t(MODALS.UNARCHIVE_DESCRIPTION)
        : t(MODALS.ARCHIVE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.SAVE) as string,
      onCancel: onCloseConfirmArchiveGroup,
      onOk: () => onConfirmArchiveGroup(groupId),
    })
  }
  const fetchGroups = async () => {
    try {
      const data = await getAllGroups({ ...queryBody })
      if (data.data) {
        const modifiedGroups = data.data?.groups.map((group: IGroup) => ({
          ...group,
          actions: {
            actionKey: group?.isArchived
              ? { text: t(BUTTONS.UNARCHIVE), color: colors.vime, icon: FaCheck }
              : { text: t(BUTTONS.ARCHIVE), color: colors.red, icon: FaArchive },
          },
        }))
        setCurrentData({ data: modifiedGroups, totalCount: data.data?.totalCount || 0 })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onToggleCreateGroup = () => {
    setVisibleCreateGroupModal((prevVisible) => !prevVisible)
  }

  const onClickGroup = async (params: FixMeLater) => {
    setGroupId(params._id)
    setChannelInfoModalVisible(true)
  }

  const closeInfoModalVisible = () => {
    setChannelInfoModalVisible(false)
  }

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  })

  const ActionCell: React.FC<FixMeLater> = ({ dataItem }) => {
    const { t } = useTranslation()
    return (
      <td>
        <ButtonContainer>
          <Button
            onClick={() => {
              onClickGroup(dataItem)
            }}
            aria-label={t(dataItem.actions.actionKey.text)}
          >
            <FaInfoCircle />
            {'Info' /*t(TABLE.USER_INFO)*/}
          </Button>
          <Button
            style={{ color: dataItem.actions.actionKey.color }}
            onClick={() => {
              onToggleArchive(dataItem)
            }}
            aria-label={t(dataItem.actions.actionKey.text)}
          >
            {dataItem.actions.actionKey.icon && <dataItem.actions.actionKey.icon />}
            {t(dataItem.actions.actionKey.text)}
          </Button>
        </ButtonContainer>
      </td>
    )
  }

  const handleFilterChange = (event: FixMeLater) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    })
  }

  const onDataStateChange = (event: FixMeLater) => {
    const updatedState = {
      ...dataState,
      skip: event.dataState.skip,
      take: event.dataState.take,
      sort: event.dataState.sort,
    }
    setDataState(updatedState)
  }

  useEffect(() => {
    console.log(dataState)

    let searchText = ''
    if (dataState.filter && dataState.filter?.filters) {
      dataState.filter.filters.forEach((filter: FixMeLater) => {
        if (filter.field === 'name' || filter.field === 'description') {
          searchText += filter.value
        }
      })
    }
    queryBody.search = searchText

    let sortColumn = ''
    let sortDirection = false

    if (dataState.sort) {
      dataState.sort.forEach((sort: FixMeLater) => {
        sortColumn = sort.field
        if (sort.dir === 'asc') {
          sortDirection = true
        }
        return false
      })

      if (sortColumn != '') {
        queryBody.column = sortColumn
        queryBody.sort = sortDirection
      }
    }
    queryBody.pageNumber = dataState.skip / dataState.take + 1

    setQueryBody({ ...queryBody })
  }, [dataState])

  return (
    <Container>
      <Wrapper>
        <TopContainer>
          <SettingsHeader title={t(TITLES.TEAMS_SETTINGS_TITLE)} />
          <CustomButton
            text={t(BUTTONS.CREATE_TEAM)}
            textColor="white"
            onClick={onToggleCreateGroup}
          />
        </TopContainer>
        <TableWrapper>
          <KGrid
            //loading={loading}
            datasource={currentData}
            dataState={dataState}
            pageable={true}
            total={currentData.totalCount | 0}
            filterable={true}
            editMode={false}
            sortable={true}
            onFilterChange={handleFilterChange}
            onDataStateChange={onDataStateChange}
          >
            <GridColumn field="name" title={t(TABLE.TEAM_NAME)}></GridColumn>
            <GridColumn field="description" title={t(TABLE.TEAM_DESCRIPTION)}></GridColumn>
            <GridColumn
              filterable={false}
              sortable={false}
              field="isArchived"
              title={t(TABLE.TEAM_STATUS)}
            />
            <GridColumn
              filterable={false}
              sortable={false}
              field="isDeleted"
              title={t(TABLE.TEAM_DELETED)}
            />
            <GridColumn
              filterable={false}
              sortable={false}
              field="members"
              title={t(TABLE.TEAM_MEMBERS)}
            />
            <GridColumn
              filterable={false}
              sortable={false}
              field="channels"
              title={t(TABLE.TEAM_CHANNELS)}
            />
            <GridColumn
              field="actions"
              filterable={false}
              title={t(TABLE.USER_ACTIONS)}
              cell={(props) => <ActionCell {...props} />}
            />
          </KGrid>
        </TableWrapper>

        {visibleCreateGropModal && (
          <CreateGroupModal refetch={fetchGroups} onClose={onToggleCreateGroup} />
        )}
        {channelInfoModalVisible && (
          <GroupInfoModal
            groupId={groupId}
            fetchGroups={fetchGroups}
            onClose={closeInfoModalVisible}
          />
        )}
      </Wrapper>
      {Modal}
    </Container>
  )
}

export default GroupManagement

const TableWrapper = styled.div`
  width: 100%;
  height: 90vh;
  overflow: hidden;
`

const Container = styled.div`
  width: 100%;
`

const Wrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  button span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.8em;
  }
`
