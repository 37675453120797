import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { PLACEHOLDERS } from 'locales/locales'
import useWindowDimensions from 'utils/hooks/useWindowDimensions'
import { MOBILE_WIDTH } from 'utils/constants'

import CustomText from 'components/text/CustomText'
import SearchInput from 'components/input/search-input/SearchInput'
import Separator from 'components/separator/Separator'
import CustomIcon from 'components/icon/CustomIcon'

interface IConversationPanelProps {
  title: string
  isGroup?: boolean
  visible: boolean
  content: React.ReactNode
  emptyChannelPanel?: boolean
  onClickCircleButton?: () => void
  onChangeSearch?: (text: string) => void
  onHideConversationPanel?: () => void
}

interface IStyledProps {
  visible?: boolean
}

const ConversationPanel = ({
  title,
  isGroup,
  visible,
  content,
  emptyChannelPanel,
  onChangeSearch,
  onClickCircleButton,
  onHideConversationPanel,
}: IConversationPanelProps) => {
  const { t } = useTranslation()
  const { screenWidth } = useWindowDimensions()
  return (
    <>
      <Wrapper visible={visible}>
        <TopContainer>
          <TitleWrapper>
            <CustomText typography="header1" color={colors.white}>
              {title}
            </CustomText>
            {!isGroup && onClickCircleButton && (
              <NewRoomButton
                width="54px"
                height="54px"
                source={icons.circleIconDark}
                onClick={onClickCircleButton}
              />
            )}
          </TitleWrapper>
          {onChangeSearch && (
            <SearchInput
              placeHolder={t(PLACEHOLDERS.SEARCH_PLACEHOLDER)}
              isOval
              onChange={onChangeSearch}
            />
          )}
        </TopContainer>
        <Separator margin={{ top: 20, bottom: 10, left: 16, right: 16 }} />
        {content}
      </Wrapper>
      {screenWidth < MOBILE_WIDTH && visible && !emptyChannelPanel && (
        <PanelBackground onClick={onHideConversationPanel} />
      )}
    </>
  )
}

export default ConversationPanel

export const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 240px;
  background-color: ${colors.vime};
  transition: 600ms;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0px 20px 20px 0px;
  @media only screen and (max-width: ${MOBILE_WIDTH}px) {
    position: absolute;
    z-index: 4;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transform: ${(props: IStyledProps) => (props.visible ? 'translateX(0%)' : 'translateX(-100%)')};
    transition: 600ms;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`

export const TopContainer = styled.div`
  padding: 20px 16px 0px 16px;
`

export const PanelBackground = styled.div`
  display: flex;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
`

export const NewRoomButton = styled(CustomIcon)`
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.3));
`
