export const SOCKET = {
  THREAD_NOTIFICATION_COUNT_UPDATE: 'thread-notification-count-update',
  ROOM_CREATED: 'roomCreated',
  USER_DISCONNECTED: 'user-disconnected',
  USER_DELETED: 'user-deleted',
  USER_CONNECTED: 'user-connected',
  VIDEO_CALL: 'video-call',
  MESSAGE: 'message',
  USER_UPDATED: 'user',
  GROUP_CREATED: 'createdGroup',
  CHANNEL_CREATED: 'createdChannel',
  PIN_UPDATED: 'updatedPin',
  REACTION_SENT: 'reactions',
  MESSAGE_DELETED: 'message-deleted',
  THREAD_MESSAGE_PANEL: 'thread-message-sidebar',
  THREAD_MESSAGE: 'thread-message',
  PINNED_MESSAGE: 'updatedPinMessage',
  TYPING_STARTED: 'typing-started',
  USER_STARTED_TYPING: 'user-started-typing',
  USER_STARTED_TYPING_REPLY: 'user-started-typing-reply',
  GROUP_UPDATED: 'updateGroup',
  CHANNEL_UPDATED: 'channel-updated',
  MESSAGE_UPDATED: 'updated-message',
  UPDATE_THREAD_REPLY: 'update-replied-users',
  SET_CUSTOM_STATUS: 'set-custom-status',
  UPDATE_ROOM_ARCHIVE: 'update-room-archive',
  LEAVE_FROM_CHANNEL: 'leave-channel',
  LEAVE_FROM_GROUP_CONVERSATION: "leave-group-conversation",
  UPDATE_CHANNEL_MEMBERS: 'update-channel-members',
  LEAVE_SYSTEM_MESSAGE: 'leave-channel-system-message',
  OTR_MESSAGE_EVENT: 'otr-message',
  OTR_REJECTED_EVENT: 'otr-rejected',
  OTR_REQUESTED_EVENT: 'otr-request',
  OTR_LEAVE_ROOM_EVENT: 'user-left-otr',
  OTR_ROOM_UPDATED_EVENT: 'otr-room-updated',
  OTR_DESTROY_ROOM_EVENT: 'otr-destroy-room',
  OTR_DESTROY_ROOM_IMMEDIATELY_EVENT: 'otr-destroy-room-immediately',
  JOINED_PUBLIC_CHANNEL: 'joined-public-channel-info',
  NEW_COMMENT: 'new-comment',
  NEW_POST: 'new-post',
  UPDATE_POST: 'update-post',
  DELETE_POST: 'delete-post',
  JOIN_POST: 'join-post',
  LEAVE_POST: 'leave-post',
  UPDATE_USER_PERMISSON: 'update-user-permissions',
  REMOVE_MEMBER_FROM_CHANNEL: 'remove-channel-member',
  ADD_MEMBER_TO_CHANNEL: 'add-channel-member',
  REMOVE_MY_CHANNEL: 'remove-me',
  LEAVE_ME: 'leave-me',
  NOTIFICATION_COUNT: 'notification-count',
  RESET_NOTIF_COUNT: 'reset-notification-count',
  NEWS_FEED_NOTIFICATION: 'news-feed-notification',
  LOGOUT: 'logout',
  PERMISSIONS_CHANGED: 'permissions-changed',
  SETTINGS_CHANGED: 'settings-changed'
}
