import { useEffect, useRef, useState, memo, useCallback } from 'react'
import styled from 'styled-components'
import { useAppSelector } from 'app/hooks'
import { useNavigate } from 'react-router-dom'
import { t } from 'i18next'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { IUserData } from 'types/user.types'
import {
  CONFERENCE_BLANK_DIMENSIONS,
  CONFERENCE_TYPE_VIDEO,
  MESSAGE_DATE_FORMAT,
  MOBILE_WIDTH,
} from 'utils/constants'
import { BUTTONS, MESSAGES, MODALS } from 'locales/locales'
import { IRoom, RoomData, RoomTypes } from 'types/rooms.types'
import { useCreateConversationMutation } from 'app/services/room.service'
import { IMessage, MessageStatus, MessageUserType } from 'types/conversation.types'
import { useLazyCreateChannelConferenceQuery } from 'app/services/conference.service'

import Header from './header/Header'
import Option from './option/Option'
import Status from './status/Status'
import Content from './content/Content'
import Reaction from './reaction/Reaction'
import UserCard from './user-card/UserCard'
import Loading from 'components/spinner/Loading'
import CustomText from 'components/text/CustomText'
import SystemMessage from './system-message/SystemMessage'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import ThreadDraftInfo from './thread-draft-info/ThreadDraftInfo'
import useInfoModal from 'utils/hooks/useInfoModal'
import moment from 'moment'
import { v4 } from 'uuid'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import UserGroupsModal from 'features/channels/userGroupModal'

interface INewMessageProps {
  isOtr?: boolean
  roomId?: string
  myId: string
  index: number
  data: IMessage
  draftData?: FixMeLater
  isSearch?: boolean
  jumpToIndex?: number
  visibleOption?: boolean
  visibleThread?: boolean
  visibleReaction?: boolean
  threadMainMessage?: boolean
  searchTextInMessage?: string
  onTogglePinMessage?: (messageId: string, pinned: boolean) => void
  onDeleteMessage?: (messageId: string) => void
  onReactionMessage?: (messageId: string, emoji: string, isAdd: boolean) => void
  onReplyMessage?: (visible: boolean, type: string, params?: { messageId: string }) => void
  onJumpMessage?: (messageId: string, threadId?: string) => void
  onResetJumpToIndex?: (item: number) => void
  onResetThreadId?: () => void
}
const NewMessage = ({
  isOtr,
  roomId,
  myId,
  data,
  index,
  draftData,
  jumpToIndex,
  isSearch,
  visibleOption = true,
  visibleThread,
  visibleReaction = true,
  searchTextInMessage,
  threadMainMessage,
  onTogglePinMessage,
  onDeleteMessage,
  onReactionMessage,
  onResetJumpToIndex,
  onReplyMessage,
  onJumpMessage,
  onResetThreadId,
}: INewMessageProps) => {
  const messageRef = useRef<HTMLDivElement>(null)
  const avatarRef = useRef<HTMLDivElement>(null)
  const roomRef = useRef<IRoom | undefined>(undefined)

  const [isEditMessage, setEditMessage] = useState(false)
  const [windowApi,setWindowApi] = useState<FixMeLater>(null);

  const [visibleEmojiPicker, setVisibleEmojiPicker] = useState(false)
  const [visibleUserCard, setVisibleUserCard] = useState<{
    visible: boolean
    userId: string | null
    fullname:string | null
  }>({ visible: false, userId: null , fullname:null})

  const {
    openModal: openConferenceModal,
    closeModal: closeConferenceModal,
    Modal: ConferenceModal,
  } = useInfoModal()

  const navigate = useNavigate()
  const rooms: RoomData = useAppSelector((state) => state.rooms.data)
  const [createConversation] = useCreateConversationMutation()
  const [createConference] = useLazyCreateChannelConferenceQuery()
  const [isUserGroupsModalVisible, setIsUserGroupsModalVisible] = useState(false);
  const [selectedUserName, setSelectedUserName] = useState("");
  
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const messageAlignmentClass = data.sender === myId ? 'right' : 'left';
  const permissions = useAppSelector<IRolePermissions>((state) => state.auth.permissions)
  const hasViewUserCard = isFlagSetIn(
    [TeamAndChannelPermissions.VIEW_TEAM_MEMBERS],
    permissions.teamAndChannel,
  )

  useEffect(() => {
    const w = window as any
    if (w.api) setWindowApi(w.api)
  }, [])

  useEffect(() => {
    if (rooms.length > 0) {
      roomRef.current = rooms.find((room) => room._id === roomId)
    }
  }, [rooms])


  const onUsersGrup = (userId: string, fullname: string) => {
    setIsUserGroupsModalVisible(true);
    setSelectedUserId(userId);
    setSelectedUserName(fullname);  
  };
  
  const closeUserGroupsModal = () => {
    setIsUserGroupsModalVisible(false);
  };


  useEffect(() => {
    if (jumpToIndex === index) {
      if (messageRef?.current) {
        messageRef.current.style.transition = 'backgroundColor 1s cubic-bezier(.2,.94,.93,.95)'
        messageRef.current.style.backgroundColor = `${colors.vime10}`
      }
      messageRef?.current?.scrollIntoView({
        behavior: 'auto',
      })
      setTimeout(() => {
        if (messageRef?.current) {
          messageRef.current.style.backgroundColor = ''
          onResetJumpToIndex?.(-1)
          onResetThreadId?.()
        }
      }, 2000)
    }
  }, [jumpToIndex])

  const onClickChatIcon = async () => {
    try {
      Loading.ref.show()
      const result: FixMeLater = await createConversation({ members: [visibleUserCard.userId] })
      if (!result.error) {
        // room already exists
        if (typeof result.data.data === 'string') {
          navigate('/messages/' + result.data.data)
          // room doesn't exist
        } else {
          navigate('/messages/' + result.data.data._id)
        }
      }
    } finally {
      Loading.ref.hide()
    }
  }

  const onKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && visibleUserCard.visible) {
      setVisibleUserCard({ visible: false, userId: null, fullname:null })
    }
  }

  const onVisibleUserCard = useCallback(
    (userId: string, fullname: string) => {
      if (roomRef.current?.type !== RoomTypes.DM) {
        setVisibleUserCard({ visible: true, userId, fullname });
      }
    },
    [roomRef.current],
  );


  const onVideoCall = async () => {
    openConferenceModal({
      title: t(MODALS.CREATE_VIDEO_CONFERENCE_TITLE),
      description: t(MODALS.CREATE_VIDEO_CONFERENCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: closeConferenceModal,
      onOk: () => creatingConference(CONFERENCE_TYPE_VIDEO),
    })
  }

  const creatingConference = async (conferenceType: string) => {
    try {
      Loading.ref.show()
      const data = await createConference({
        roomId,
        type: 'single',
        conferenceType: conferenceType,
      })
      if (windowApi) {
        windowApi.send('openConferenceUrl', { url: data.data?.url })
      } else {
        window.open(data.data?.url, '', CONFERENCE_BLANK_DIMENSIONS)
      }
    } finally {
      Loading.ref.hide()
      closeConferenceModal()
    }
  }

  const onToggleVisiblePicker = () => {
    setVisibleEmojiPicker((prevState) => !prevState)
  }

  const onClickBackDrop = () => {
    setVisibleEmojiPicker(false);    
    setEditMessage(false);            
    setVisibleUserCard({              
      visible: false,
      userId: null,
      fullname: null 
    });
  }

  const onToggleEditMessage = useCallback(() => {
    setEditMessage((prevState) => !prevState)
  }, [])

  if (data.sender === MessageUserType.SYSTEM) {
    return (
      <SystemMessage
        data={data?.content}
        users={data?.userData}
        searchTextInMessage={searchTextInMessage}
        onShowUserInfo={onVisibleUserCard}
      />
    )
  }
  return (
    <>
      <Wrapper
        className={messageAlignmentClass}
        ref={messageRef}
        isPinned={threadMainMessage ? false : data.pinned}
        visibleEmojiPicker={visibleEmojiPicker}
        isEdit={isEditMessage}
        subsequent={data?.subsequent}
      >
        {!data?.subsequent && (
          <Avatar
            type={'circle'}
            ref={avatarRef}
            source={data?.userData[data?.sender]?.avatar}
            text={data?.userData[data?.sender]?.fullname}
            size={AvatarSize.average}
            onClick={() => onVisibleUserCard(data.sender, data?.userData[data?.sender]?.fullname)}
            />
        )}
        {data?.subsequent && (
          <Time>
            <StyledText id="date" typography="body8" color={colors.white}>
              {moment(data?.createdAt).format(MESSAGE_DATE_FORMAT)}
            </StyledText>
          </Time>
        )}
        <OutWrapper className={messageAlignmentClass}>
          {!data?.subsequent && (
            <Header createdAt={data?.createdAt} user={data?.userData[data?.sender]} />
          )}
          <Content
            key={v4()}
            roomId={roomId}
            users={data?.userData}
            messageId={data._id}
            data={data?.content}
            isDeleted={data.isDeleted}
            isEditMessage={isEditMessage}
            isMyMessage={data.sender === myId}
            searchTextInMessage={searchTextInMessage}
            isPending={data.status === 'pending'}
            onShowUserInfo={() => onVisibleUserCard(data.sender, data?.userData[data?.sender]?.fullname)}
            onCancelEditMessage={onToggleEditMessage}
          />
          {data.isEdited && !data.isDeleted && (
            <EditedText typography="body8" color={colors.gray60}>
              {t(MESSAGES.EDITED_MESSAGE)}
            </EditedText>
          )}
          {visibleReaction && !data.isDeleted && (
            <Reaction
              messageId={data._id}
              users={data?.userData}
              data={data?.reactions}
              onReactionMessage={onReactionMessage}
            />
          )}
          {visibleThread && (
            <ThreadDraftInfo
              messageId={data._id}
              users={data?.userData}
              data={data.repliedUsers}
              threadCount={data?.threadCount}
              draftData={draftData}
              onReplyMessage={onReplyMessage}
            />
          )}
        </OutWrapper>
        {visibleOption &&
          !data.isDeleted &&
          !isOtr &&
          data.status !== MessageStatus.FAILED &&
          data.status !== MessageStatus.PENDING && (
            <Option
              isMyMessage={myId === data.sender}
              messageId={data._id}
              isPinned={data.pinned}
              isSearch={isSearch}
              jumpId={data.threadId ? data.threadId : data._id}
              threadId={data.threadId ? data._id : ''}
              visibleEmojiPicker={visibleEmojiPicker}
              subsequent={data?.subsequent}
              onTogglePinMessage={onTogglePinMessage}
              onDeleteMessage={onDeleteMessage}
              onReactionMessage={onReactionMessage}
              onReplyMessage={onReplyMessage}
              onJumpMessage={onJumpMessage}
              onToggleVisiblePicker={onToggleVisiblePicker}
              onEditMessage={onToggleEditMessage}
            />
          )}
        {!isOtr &&
          visibleUserCard.visible &&
          !data?.userData[visibleUserCard?.userId ?? data.sender]?.isDeleted &&
          hasViewUserCard && (
            <UserCard
              showChatIcon={myId !== data.sender}
              showCallIcon={myId !== data.sender}
              showCameraIcon={myId !== data.sender}
              positionY={avatarRef?.current?.getBoundingClientRect().y}
              onKeyUp={onKeyUp}
              isCentered={false}
              onClickUserGroups={() => {
                visibleUserCard.userId && visibleUserCard.fullname &&
                  onUsersGrup(visibleUserCard.userId, visibleUserCard.fullname);
              }}
              onClickCameraIcon={onVideoCall}
              onClickChatIcon={onClickChatIcon}
              {...data?.userData[visibleUserCard?.userId || data.sender]}
            />
          )}
        {data?.status && <Status data={data} />}
      </Wrapper>
      {(visibleUserCard.visible || visibleEmojiPicker || isEditMessage) && (
        <BackDrop onClick={onClickBackDrop} />
      )}
      {ConferenceModal}
      {isUserGroupsModalVisible && selectedUserId && (
      <UserGroupsModal 
        onClose={closeUserGroupsModal} 
        isVisible={isUserGroupsModalVisible} 
        userId={selectedUserId}
        userName={selectedUserName} 
        /> )}
    </>
  )
}

export default memo(NewMessage)

const Wrapper = styled.div.attrs(
  (props: {
    isPinned: boolean
    visibleEmojiPicker: boolean
    isEdit: boolean
    myId: string
    subsequent: boolean
    senderId: string
  }) => props,
)`
  display: flex;
  align-items: center;
  position: relative;
  padding: ${({ subsequent }) => (subsequent ? '2.5px 10px 10px 30px' : '7px 10px 7px 30px')};
  column-gap: 10px;
  background-color: ${({ isPinned }) => (isPinned ? colors.vime10 : 'initial')};
  &.left {
    justify-content: flex-start;
  }

  &.right {
    -webkit-box-pack: end;
    justify-content: flex-start;
    flex-direction: row-reverse;
  }
  &:hover {
    background-color: ${colors.light50};
  }

  &:hover #option {
    display: ${({ isEdit }) => (!isEdit ? 'flex' : 'none')};
  }

  &:hover #date {
    color: ${colors.gray60};
  }

  #option {
    display: ${({ visibleEmojiPicker }) => (visibleEmojiPicker ? 'flex' : 'none')};
  }

  @media (max-width: ${MOBILE_WIDTH - 400}px) {
    padding: ${({ subsequent }) => (subsequent ? '2.5px 18px' : '20px 10px 5px 8px')};
  }
`

const BackDrop = styled.div`
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  backdrop-filter: blur(1px);
`
const OutWrapper = styled.div`
  &.right {
    max-width: 450px;
    border-radius: 15px 15px 0 15px;
    background-color: #eaf9ff;
  }

  &.left {
    max-width: 450px;
    border-radius: 15px 15px 15px 0;
    background-color: #f6f6f6;
  }
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 10px;
  margin: 5px 0;
  max-width: 70%;
  word-wrap: break-word;
`
const EditedText = styled(CustomText).attrs((props: { isPendingTextStyle: boolean }) => props)`
  color: ${({ isPendingTextStyle }) => (isPendingTextStyle ? ` ${colors.gray60}` : 'initial')};
  word-break: break-word;
  white-space: pre-line;
  font-style: italic;
`

const Time = styled.div`
  flex-shrink: 0;
`

const StyledText = styled(CustomText)`
  min-width: 30px;
`
