import { memo } from 'react'
import styled from 'styled-components'

import { FixMeLater } from 'types'
import colors from 'utils/colors'
import { IUserData } from 'types/user.types'
import { ICustomFile } from 'types/file.types'
import { IMessageContent } from 'types/conversation.types'
import { convertTextForInitialInputData, updateContentText } from 'utils/helpers/messageHelper'

import CustomFile from 'components/file/CustomFile'
import CustomText from 'components/text/CustomText'
import MessageInput from 'components/input/message-input/MessageInput'
import Waveform from 'components/file/wavesurfer/Waveform'
import { useTranslation } from 'react-i18next'
import { MESSAGES, PLACEHOLDERS } from 'locales/locales'

interface IContentProps {
  roomId?: string
  messageId: string
  users: IUserData
  data: IMessageContent
  isDeleted: boolean
  isEditMessage: boolean
  searchTextInMessage?: string
  isPending?: boolean
  isMyMessage: boolean;
  onShowUserInfo: (userId: string, fullname: string) => void
  onCancelEditMessage?: () => void
}
const Content = ({
  roomId,
  messageId,
  users,
  data,
  isDeleted,
  isPending,
  isEditMessage,
  searchTextInMessage,
  onShowUserInfo,
  isMyMessage,
  onCancelEditMessage,
}: IContentProps) => {
  const { t } = useTranslation()
  if (isDeleted) {
    return <MessageText typography="body3">{t(MESSAGES.DELETED_MESSAGE)}</MessageText>
  }

  if (isEditMessage) {
    const initialInputData = convertTextForInitialInputData(data, users)
    delete initialInputData?.files
    return (
      <MessageInput
        initialInputData={initialInputData}
        isEditMessage={isEditMessage}
        messageId={messageId}
        roomId={roomId}
        users={users}
        placeHolder={t(PLACEHOLDERS.MESSAGE_PLACEHOLDER)}
        onCancelEditMessage={onCancelEditMessage}
      />
    )
  }

  const textByRepliedUsers: FixMeLater = updateContentText(
    data?.text,
    data?.mentionedUsers,
    searchTextInMessage,
    users,
    onShowUserInfo,
  )

  return (
    <MessageContentWrapper>
      {data?.text && (
        <MessageText typography="body3" isPending={isPending} isMyMessage={isMyMessage}>
          {textByRepliedUsers}
        </MessageText>
      )}
      {data?.files && data?.files?.length > 0 && (
        <FileWrapper>
          {data?.files?.map((file: ICustomFile, index: number) => {
            const isPreviewableFile =
              file.mimetype?.includes('image') || file.mimetype?.includes('video')
            const isWaveform = file.mimetype?.includes('audio')
            return (
              <div key={file._id}>
                {!isWaveform && (
                  <CustomFileWrapper isPreviewableFile={isPreviewableFile}>
                    <CustomFile
                      index={index}
                      files={data.files}
                      sentAt={file.sentAt}
                      source={file.url}
                      mimetype={file.mimetype}
                      preset="preview"
                      size={file.size}
                      filename={file.originalName}
                      thumbnail={file?.thumbnail}
                    />
                  </CustomFileWrapper>
                )}
                {isWaveform && <Waveform url={file.url} />}
              </div>
            )
          })}
        </FileWrapper>
      )}
    </MessageContentWrapper>
  )
}

export default memo(Content)

const FileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`
const MessageContentWrapper = styled.div.attrs((props: { isSubsequent: boolean }) => props)`
  width: 100%;
`
const CustomFileWrapper = styled.div.attrs((props: { isPreviewableFile: boolean }) => props)`
  display: flex;        
  width: 200px;
  overflow: hidden;
  justify-content: space-between;
  border: ${({ isPreviewableFile }) =>
    !isPreviewableFile ? ` 1px solid ${colors.gray60}` : 'initial'};
  border-radius: 4px;
  padding: ${({ isPreviewableFile }) => (!isPreviewableFile ? '8px 6px' : '0px')};
  column-gap: 4px;
`

const MessageText = styled(CustomText).attrs((props: { isPending: boolean, isMyMessage: boolean }) => props)`
    color: ${({ isPending, isMyMessage }) =>
            isPending ? colors.gray60 : isMyMessage ? '#000000' : '#0c0c0c'};
  word-break: break-word;
  white-space: pre-line;
`
