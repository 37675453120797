import { useEffect, useState } from 'react'

const useWindowFocus = () => {
  const [isFocus, setIsFocus] = useState(true)
  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      setIsFocus(true)
    } else {
      setIsFocus(false)
    }
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange)
    return () => document.removeEventListener('visibilitychange', onVisibilityChange)
  }, [])

  return isFocus
}

export default useWindowFocus
