import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import useBroadcastChannel from './useBroadcastChannel'
import ServiceWorkerHelpers, { playNotificationSound } from 'utils/helpers/notification'
import { INFORMATION } from 'locales/locales'
import { useAppSelector } from 'app/hooks'
import { notificationChannel } from 'utils/constants'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { useUpdateUserFcmMutation } from 'app/services/user.service'

const {unsubscribeFromPushService, registerWebPushServiceWorker, getTokenFromFirebase, subscribeToPushService } = ServiceWorkerHelpers

export default function useHandleNotification() {
  const { t } = useTranslation()
  const token = useAppSelector((state) => state.auth.token)
  const notificationSound = useAppSelector((state) => state.system.data.features?.notificationSound)

  const [updateFcm] = useUpdateUserFcmMutation()

  useBroadcastChannel()

  notificationChannel.addEventListener('message', (e) => {
    switch (e.data.type) {
      case 'PLAY_NOTIFICATION_SOUND':
        if (notificationSound) playNotificationSound()
        break
      default:
        break
    }
  })

  useEffect(() => {
    let permissionStatus: PermissionStatus | null = null

    if ('permissions' in navigator) {
      // Handle push service subscription based on notification permission changes.
      navigator.permissions
        .query({ name: 'notifications' })
        .then(function (notificationPermission) {
          permissionStatus = notificationPermission
          notificationPermission.onchange = async function () {
            if (notificationPermission.state === 'denied') {
              // Unsubscribe from push service if notification permission is denied.
              unsubscribeFromPushService()
              await updateFcm({fcmToken: null})
            } else if (notificationPermission.state === 'granted') {
              // Subscribe to push service if notification permission is granted.
              await subscribeToPushService()
              const fcmToken = await getTokenFromFirebase()
              await updateFcm({ fcmToken: fcmToken })
            }
          }
        })
    }

    return () => {
      if (permissionStatus) {
        permissionStatus.onchange = null
      }
    }
  }, [])

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      if (token) {
        (async () => {
          await registerWebPushServiceWorker().then(() => {
            // Request notification permission if it's not been requested before.
            if (Notification.permission === 'default') {
              Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                  DropdownAlert.ref.show({ message: t(INFORMATION.ALLOW_NOTIFICATIONS_ALERT) })
                }
              })
            }
          })
        })()
      }
    } else {
      // If browser does not support service workers, log it.
      console.warn('This browser doesnt have support for service workers.')
    }
  }, [token])
}
