import React from 'react'
import styled from 'styled-components'

import { presets } from '../presets'

interface IImageProps {
  source: string
  preset:
    | 'preview'
    | 'input'
    | 'files'
    | 'fullscreen'
    | 'post'
    | 'editPost'
    | 'postInput'
    | 'editPostResponsive'
}

const Image = ({ source, preset }: IImageProps) => {
  return <StyledImage src={source} {...presets[preset].image} />
}

export default React.memo(Image, (prevProps, nextProps) => {
  return prevProps.preset === nextProps.preset
})

const StyledImage = styled.img.attrs(
  (props: { maxWidth: string; maxHeight: string; borderRadius: string; objectFit: string }) =>
    props,
)`
  display: block;
  width: 100%;
  height: auto;
  max-height: ${({ maxHeight }) => maxHeight};
  object-fit: ${({ objectFit }) => objectFit};
  border-radius: ${({ borderRadius }) => borderRadius};
`
