const fonts = {
  fontWeightBold: 700,
  fontWeightSemiBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 400,

  fonstSizeXL: 24,
  fontSizeL: 20,
  fontSizeM: 16,
  fontSizeS: 14,
  fontSizeXS: 12,
  fontSizeXXS: 10,

  lineHeightL: 24,
  lineHeightM: 20,
  lineHeightS: 16,
  lineHeightXS: 14,
  lineHeightXXS: 12,
}

export default fonts
