import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { IChannel, IGroup } from 'types/group.types'

import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'

interface IChannelProps {
  channel: IChannel
  isSearch: boolean
  group: IGroup
  selectedRoom: boolean
  onSelectRoom: (channel: IChannel, group: IGroup, isAppear?: boolean) => void
}

const Channel = ({ channel, isSearch, selectedRoom, group, onSelectRoom }: IChannelProps) => {
  const { channelId: currentChannelId } = useParams()

  const onClickChannel = () => {
    if (channel._id !== currentChannelId) onSelectRoom(channel, group, false)
  }

  const NotificationView = useMemo(() => {
    return (
      <CountWrapper more={channel?.notificationCount > 10}>
        <CustomText color={colors.vimeDark} typography="body7">
          {channel?.notificationCount > 10 ? '10+' : channel?.notificationCount}
        </CustomText>
      </CountWrapper>
    )
  }, [channel.notificationCount])

  if (!isSearch && !channel?.isAppear) return null
  return (
    <Wrapper selectedRoom={selectedRoom} onClick={onClickChannel}>
      <DescriptionWrapper>
        <CustomIcon
          iconPreset="small"
          source={
            channel.isArchived
              ? icons.unarchiveIcon
              : channel?.isPrivate
              ? icons.channelPrivateIconWhite
              : icons.channelPublicIconWhite
          }
        />
        <OverflowText typography="body9" color={colors.white}>
          {channel?.name}
        </OverflowText>
        {/* {channel.isArchived && (
          <StyledText typography="body3" color={colors.secondaryLight}>
            {`(${t(MESSAGES.ARCHIVED)})`}
          </StyledText>
        )} */}
        {!!channel?.notificationCount && NotificationView}
      </DescriptionWrapper>
    </Wrapper>
  )
}

export default memo(Channel)

const Wrapper = styled.div.attrs((props: { selectedRoom: boolean }) => props)`
  cursor: pointer;
  padding: 6px 16px 6px 46px;
  margin-left: -16px;
  width: 109%;
  -webkit-font-smoothing: antialiased;
  background-color: ${({ selectedRoom }: { selectedRoom: boolean }) =>
    selectedRoom ? 'rgba(231, 245, 251, 0.35)' : colors.transparent};
  &:hover {
    background-color: rgba(231, 245, 251, 0.2);
  }
`

const OverflowText = styled(CustomText).attrs((props: { isArchived: boolean }) => props)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: ${({ isArchived }) => (isArchived ? '60' : '140')}px;
`

const DescriptionWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 2px;
`

const CountWrapper = styled.div.attrs((props: { more: boolean }) => props)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -6px;
  width: ${({ more }) => (more ? 28 : 18)}px;
  height: 18px;
  border-radius: ${({ more }) => (more ? '50px' : '50%')};
  background-color: ${colors.white};
  color: ${colors.white};
`
