import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSocket } from './useSocket'
import { INotificationData } from 'components/modals/ThreadNotificationsModal'
import {
  useLazyGetThreadNotificationsQuery,
  useLazyGetUnreadThreadCountQuery,
} from 'app/services/room.service'
import { SOCKET } from 'app/socket/socketHelper'

const NOTIFICATION_PER_PAGE = 20

export default function useThreadNotifications({ currentRoomId }: { currentRoomId: string }) {
  const [data, setData] = useState<INotificationData[]>([])
  const [notificationState, setNotificationState] = useState({
    currentPage: 0,
    unreadCount: 0,
    showMore: false,
    isFetching: false,
    isFetchingMore: false,
    showNotifications: false,
  })

  const socket = useSocket()
  const navigate = useNavigate()

  const [getUnreadThreadCount] = useLazyGetUnreadThreadCountQuery()
  const [getThreadNotifications] = useLazyGetThreadNotificationsQuery()

  useEffect(() => {
    onFetchInitialThreadCount()

    setNotificationState({
      ...notificationState,
      showNotifications: false,
      showMore: false,
      currentPage: 0,
    })

    const onUpdateNotificationCount = ({ count, roomId }: { count: number; roomId: string }) => {
      if (roomId === currentRoomId) {
        setNotificationState({
          ...notificationState,
          unreadCount: count,
        })
      }
    }

    socket.on(SOCKET.THREAD_NOTIFICATION_COUNT_UPDATE, onUpdateNotificationCount)
    return () => {
      socket.off(SOCKET.THREAD_NOTIFICATION_COUNT_UPDATE, onUpdateNotificationCount)
    }
  }, [currentRoomId])

  useEffect(() => {
    if (!notificationState.showNotifications) {
      setData([])
      setNotificationState({
        ...notificationState,
        showMore: false,
        currentPage: 0,
        isFetching: true,
        isFetchingMore: false,
      })
    }
  }, [notificationState.showNotifications])

  const onToggleNotifications = () => {
    setNotificationState({
      ...notificationState,
      showNotifications: !notificationState.showNotifications,
    })
  }

  const onClickNotification = (notification: INotificationData) => {
    const { threadId, messageId, roomId, redirectUrl } = notification.data
    setNotificationState({ ...notificationState, showNotifications: false })
    navigate(redirectUrl, {
      state: {
        roomId,
        messageId: threadId,
        threadId: messageId,
        action: 'JUMP_TO_THREAD',
      },
    })
  }

  const onFetchInitialThreadCount = useCallback(async () => {
    const data = await getUnreadThreadCount({ roomId: currentRoomId })
    
    setNotificationState((prevState) => ({ ...prevState, unreadCount: data?.data?.count ?? 0 }))
    if (data?.count > 20) {
      setNotificationState((prevState) => ({ ...prevState, showMore: true }))
    }
  }, [currentRoomId])

  const onFetchInitialNotifications = useCallback(async () => {
    // TODO: fetch only if there is a new notification
    setNotificationState((prevState) => ({ ...prevState, isFetching: true }))
    try {
      const {
        data: { data },
      } = await getThreadNotifications({
        roomId: currentRoomId,
        page: notificationState.currentPage,
      })
      setData(data.notifications)
      if (data.totalCount > 20) {
        setNotificationState((prevState) => ({
          ...prevState,
          currentPage: ++prevState.currentPage,
          showMore: true,
        }))
      }
    } finally {
      setNotificationState((prevState) => ({ ...prevState, isFetching: false }))
    }
  }, [currentRoomId, notificationState.currentPage])

  const onClickShowMore = useCallback(async () => {
    try {
      setNotificationState((prevState) => ({ ...prevState, isFetchingMore: true }))
      const { data: response } = await getThreadNotifications({
        roomId: currentRoomId,
        page: notificationState.currentPage,
      })
      if (response.data.notifications && response.data.notifications.length > 0) {
        // existing notifications + new ones
        setData((prevState) => [
          ...prevState.slice(0, notificationState.currentPage * NOTIFICATION_PER_PAGE),
          ...response.data.notifications,
        ])
        if (response.data.totalCount - (response.data.notifications.length + data.length) > 0) {
          setNotificationState((prevState) => ({
            ...prevState,
            currentPage: ++prevState.currentPage,
            showMore: true,
          }))
        } else {
          setNotificationState((prevState) => ({
            ...prevState,
            showMore: false,
          }))
        }
      }
    } finally {
      setNotificationState((prevState) => ({ ...prevState, isFetchingMore: false }))
    }
  }, [currentRoomId, notificationState.currentPage])

  return {
    data,
    notificationState,
    onClickShowMore,
    onToggleNotifications,
    onClickNotification,
    onFetchInitialNotifications,
  }
}
