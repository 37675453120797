import React from 'react'

import { icons } from 'utils/icons'
import { ICustomFile, IFiles } from 'types/file.types'

import CustomIcon from 'components/icon/CustomIcon'
import CustomFile from 'components/file/CustomFile'
import Waveform from 'components/file/wavesurfer/Waveform'
import axiosApi from 'app/axiosApi'
import styled from 'styled-components'
import colors from 'utils/colors'

class FileModal extends React.Component {
  static ref: FileModal
  state = { visible: false, index: 1, files: [] }

  componentDidMount = () => {
    window.addEventListener('keydown', this.onKeyPressed)
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.onKeyPressed)
  }

  show = (visible: boolean, index: number, files?: IFiles) => {
    this.setState({ visible, index, files })
  }
  close = () => {
    this.setState({ visible: false, files: [], index: 0 })
  }

  onPreview = () => {
    this.setState({ index: this.state.index - 1 })
  }

  onNext = () => {
    this.setState({ index: this.state.index + 1 })
  }

  onKeyPressed = (event: { code: string }) => {
    const hasPreview = this.state.index > 0
    const hasNext = this.state.files.length - 1 > this.state.index
    if (event.code === 'Escape') {
      this.close()
    } else if (event.code === 'ArrowLeft') {
      if (hasPreview) this.setState({ index: this.state.index - 1 })
    } else if (event.code === 'ArrowRight') {
      if (hasNext) this.setState({ index: this.state.index + 1 })
    }
  }

  onDownloadFile = async (file: ICustomFile) => {
    const downloadHref = await axiosApi.downloadFileWithUrl(file.url)
    const link = document.createElement('a')
    link.href = downloadHref
    link.download = file.originalName
    link.click()
  }

  render() {
    const { visible, files, index } = this.state
    return (
      <FileModalWrapper visible={visible} tabIndex={0} onKeyDown={this.onKeyPressed}>
        {files.map((file: ICustomFile, fileIndex: number) => {
          const audio = file.mimetype?.includes('audio')
          const hasPreview = fileIndex > 0
          const hasNext = files.length - 1 > fileIndex
          if (fileIndex === index)
            return (
              <Content key={fileIndex}>
                <CloseIcon iconPreset="xLarge" source={icons.closeBlackIcon} onClick={this.close} />
                <ChevronWrapper>
                  {hasPreview && (
                    <CustomIcon
                      iconPreset="xLarge"
                      source={icons.leftIcon}
                      onClick={this.onPreview}
                    />
                  )}
                </ChevronWrapper>
                {!audio && (
                  <CustomFileWrapper>
                    <CustomFile
                      key={file.sentAt}
                      source={file.url}
                      filename={file.originalName}
                      mimetype={file.mimetype}
                      sentAt={file.sentAt}
                      size={file.size}
                      preset="fullscreen"
                    />
                    <DownloadIcon
                      source={icons.downloadIcon}
                      onClick={() => this.onDownloadFile(file)}
                    />
                  </CustomFileWrapper>
                )}
                {audio && <Waveform url={file.url} />}
                <ChevronWrapper>
                  {hasNext && (
                    <CustomIcon
                      iconPreset="xLarge"
                      source={icons.rightIcon}
                      onClick={this.onNext}
                    />
                  )}
                </ChevronWrapper>
              </Content>
            )
          return null
        })}
      </FileModalWrapper>
    )
  }
}

export default FileModal

const FileModalWrapper = styled.div.attrs(({ visible }: { visible: boolean }) => visible)`
  display: ${({ visible }) => (visible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(57, 57, 57, 0.6);
  backdrop-filter: blur(2px);
  z-index: 5;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`

const CloseIcon = styled(CustomIcon)`
  position: absolute;
  right: 8.5vw;
  top: 2.5vh;
  z-index: 2;
`

const CustomFileWrapper = styled.div`
  height: 90vh;
  width: 80vw;
  display: flex;
  background-color: ${colors.white};
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 8px;
`

const ChevronWrapper = styled.div`
  width: 45px;
`

const DownloadIcon = styled(CustomIcon)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`
