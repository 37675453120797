import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import _cloneDeep from 'lodash/cloneDeep'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import {
  useAddChannelMemberMutation,
  useLazyGetMembersBySearchForChannelQuery,
  useRemoveChannelMemberMutation,
  useSendInvitationMutation,
  useUpdateUserPermissionMutation,
} from 'app/services/group.service'
import { FixMeLater } from 'types'
import { icons } from 'utils/icons'
import { IChannelMember } from 'types/user.types'
import { useCancelInvitationMutation } from 'app/services/invite.service'
import {
  useGetRoomInfoMutation,
  useUpdateChannelMutation,
  useUpdateNotificationMutation,
} from 'app/services/room.service'

import General from './General'
import Files from 'components/file/files/Files'
import Members from 'components/members/Members'
import CustomText from 'components/text/CustomText'
import CustomIcon from 'components/icon/CustomIcon'
import CustomModal from 'components/modals/CustomModal'
import { BUTTONS, ERRORS, PLACEHOLDERS, TABS } from 'locales/locales'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { CHANNEL_MEMBER_LIMIT } from 'utils/constants'
import { useAppSelector } from 'app/hooks'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'

interface IChannelInfoModalProps {
  onClose: () => void
}

const initialGeneralInfo = {
  createdBy: '',
  creatorId: '',
  description: '',
  isAppear: false,
  isArchived: false,
  isMuted: false,
  isPrivate: false,
  name: '',
  createdAt: '',
  notifyOnlyMentions: false,
}

const getIsEmail = (email: string) => {
  const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return emailRegex.test(email)
}

const ChannelInfoModal = ({ onClose }: IChannelInfoModalProps) => {
  const { channelId } = useParams()
  const { t } = useTranslation()

  //WILL BE UPDATE
  const [updateChannel] = useUpdateChannelMutation()
  const [sendInvitation] = useSendInvitationMutation()
  const [cancelInvite] = useCancelInvitationMutation()
  const [addChannelMember] = useAddChannelMemberMutation()
  const [getRoomInfo, { data }] = useGetRoomInfoMutation()
  const [updateNofication] = useUpdateNotificationMutation()
  const [removeChannelMember] = useRemoveChannelMemberMutation()
  const [updateUserPermission] = useUpdateUserPermissionMutation()
  const [getMembersBySearch] = useLazyGetMembersBySearchForChannelQuery()

  //WILL BE UPDATE
  const [generalInfo, setGeneralInfo] = useState({ ...initialGeneralInfo })
  const [members, setMembers] = useState<IChannelMember[] | FixMeLater[]>([])
  const [membersBySearch, setMembersBySearch] = useState<IChannelMember[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const permissions = useAppSelector<IRolePermissions>(state => state.auth.permissions);
  const isAdmin = isFlagSetIn([TeamAndChannelPermissions.UPDATE_CHANNEL], permissions.teamAndChannel);

  const fetchRoomInfo = async () => {
    try {
      const result: FixMeLater = await getRoomInfo({ roomId: channelId })
      setGeneralInfo(result.data.general)
      setMembers(result.data.members)
    } catch {
      onClose()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchRoomInfo()
  }, [])

  const onSearch = async (text: string) => {
    try {
      const result: FixMeLater = await getMembersBySearch({ channelId, searchName: text })
      const searchMembers = [...result.data.data]
      if (getIsEmail(text)) {
        const hasUser = members.find(({ email }) => email === text)
        if (!hasUser) {
          searchMembers.push({ email: text })
        }
      }
      if (members.length > 0) {
        return setMembersBySearch([
          ...searchMembers.sort((member) => {
            if (members?.some((m) => m._id === member._id)) {
              return 1
            }
            return -1
          }),
        ])
      }
      setMembersBySearch([...searchMembers])
    } catch (e) {
      console.log(e)
    }
  }

  const onAddMember = async (user: IChannelMember) => {
    if (members.length === CHANNEL_MEMBER_LIMIT) {
      return DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.MEMBER_LIMIT_EXCEEDED),
      })
    }

    try {
      const { data }: FixMeLater = await addChannelMember({ channelId, userId: user._id })
      if (data?.success) {
        setMembers([...members, user])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onRemoveMember = async (user: IChannelMember, isCancel?: boolean) => {
    try {
      const removedUserFromMembersData = members.filter(
        (member: IChannelMember) => member._id !== user._id,
      )
      if (isCancel) {
        const { data }: FixMeLater = await cancelInvite({
          roomId: channelId,
          email: user.email,
        })
        if (data?.success) setMembers([...removedUserFromMembersData])
      } else {
        const { data }: FixMeLater = await removeChannelMember({
          channelId,
          userId: user._id,
        })
        if (data?.success) setMembers([...removedUserFromMembersData])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onChangeGeneralInfo = (key: string, value: string | boolean) => {
    setGeneralInfo({ ...generalInfo, [key]: value })
    const notificationObj: FixMeLater = {
      isMuted: generalInfo?.isMuted,
      notifyOnlyMentions: generalInfo?.notifyOnlyMentions,
    }

    if (key === 'isMuted' || key === 'notifyOnlyMentions') {
      notificationObj[key] = value
      updateNofication({
        roomId: channelId,
        ...notificationObj,
      })
    }
  }

  const onUpdateChannel = async () => {
    try {
      setIsLoading(true)
      const result: FixMeLater = await updateChannel({
        channelId,
        params: {
          name: generalInfo.name,
          description: generalInfo.description,
          isPrivate: generalInfo.isPrivate,
        },
      })
      if (result?.data?.success) {
        onClose()
      }
    } finally {
      setIsLoading(false)
    }
  }
  const onUpdatePermission = async (
    userId: string,
    index: number,
    key: string,
    updatedValue: boolean,
  ) => {
    try {
      const { data }: FixMeLater = await updateUserPermission({
        channelId,
        userId,
        key,
        value: updatedValue,
      })
      if (data?.success) {
        const indexByUserId = members.findIndex((member) => member._id === userId)
        const tempMembers = _cloneDeep(members)
        tempMembers[indexByUserId].permissions[index].value = updatedValue
        tempMembers[indexByUserId].isModerator = updatedValue
        setMembers([...tempMembers])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onInviteMember = async (email: string) => {
    if (members.length === CHANNEL_MEMBER_LIMIT) {
      return DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.MEMBER_LIMIT_EXCEEDED),
      })
    }

    try {
      setIsLoading(true)
      const result: FixMeLater = await sendInvitation({ email, channelId })
      setIsLoading(false)
      //TODO success message
      if (result.data.data.isInvited) {
        const tempData = [...members]
        const userIndex = members.find((member) => member.email === email)
        if (userIndex) {
          tempData[userIndex] = { ...result.data.data }
        } else {
          tempData.push({ ...result.data.data })
        }

        setMembers([...tempData])
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <CustomModal
      header={<Header {...data?.general} />}
      okTitle={!generalInfo.isArchived ? t(BUTTONS.SAVE) : ''}
      cancelTitle={!generalInfo.isArchived ? t(BUTTONS.CANCEL) : ''}
      onOk={!generalInfo.isArchived ? onUpdateChannel : undefined}
      onCancel={onClose}
      closeModal={onClose}
      loading={isLoading}
      hasMember
      tab={[
        { title: t(TABS.GENERAL), action: true },
        { title: t(TABS.MEMBERS) },
        { title: t(TABS.FILES) },
      ]}
      showButtons={false}
    >
      <General
        channelId={channelId}
        {...generalInfo}
        isModerator={data?.isModerator || isAdmin}
        onChange={onChangeGeneralInfo}
        onClose={onClose}
      />
      <Members
        data={members}
        isSearch={data?.isModerator || isAdmin}
        searchedData={membersBySearch}
        isRemove={data?.isModerator || isAdmin}
        isInvite={data?.isModerator || isAdmin}
        isCollapse={true}
        isArchived={generalInfo.isArchived}
        isAddMember={data?.isModerator || isAdmin}
        isReadOnlyPermission={!(data?.isModerator || isAdmin)}
        placeholder={t(PLACEHOLDERS.MEMBERS_PLACEHOLDER)}
        isAdmin={isAdmin}
        isRoomModerator={data?.isModerator}
        onRemoveUser={onRemoveMember}
        onChangeSearchText={onSearch}
        onSelectMember={onAddMember}
        onInviteMember={onInviteMember}
        isUserCardDisplayable={true}
        onChangePermission={onUpdatePermission}
      />
      <Files data={data?.files} />
    </CustomModal>
  )
}

export default ChannelInfoModal

export const Header = ({ name, isPrivate }: { name?: string; isPrivate?: boolean }) => {
  return (
    <Wrapper>
      <CustomIcon
        iconPreset="grande"
        source={isPrivate ? icons.lockIconBold : icons.channelIconBold}
      />
      <StyledText typography="header1">{name}</StyledText>
    </Wrapper>
  )
}

const StyledText = styled(CustomText)`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`
