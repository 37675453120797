import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { userService } from 'app/services/user.service'
import { IUser, IUserData, UserStatus } from 'types/user.types'
import getBaseUrl from 'utils/helpers/apiHelper'

interface UsersState {
  data: IUserData
}

const initialUsersState: UsersState = {
  data: {},
}

// Transformer function to enrich a post with userData
export const setUserData = (user: IUser): IUser => {
  // Add all properties from post except userData, comments, and content

  const finalUser = { ...user } as IUser

  if (finalUser.avatar) {
    finalUser.avatar = finalUser.avatar.startsWith('http')
      ? finalUser.avatar
      : `${getBaseUrl(true)}${finalUser.avatar}`
  }

  return finalUser
}
export const { actions: usersActions, reducer: usersReducer } = createSlice({
  name: 'users',
  initialState: initialUsersState,
  reducers: {
    getPaginatedUsers: (state, action) => {
      state.data = action.payload
    },
    updateUsersStatus: (state, action: PayloadAction<{ userId: string; status: UserStatus }>) => {
      const { userId, status } = action.payload

      if (!state.data[userId]) {
        //We don't actively refresh the user list anymore so it's mostly going to be empty.
        state.data[userId] = { status } as IUser
      }

      if (state.data[userId] && state.data[userId].status !== status) {
        state.data[userId].status = status
      }
    },
    updateUser: (state, action) => {
      state.data[action.payload._id] = action.payload
    },
    deleteUser: (state, action) => {
      delete state.data[action.payload]
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userService.endpoints.getUsersByFullname.matchFulfilled,
      (state, { payload }) => {
        payload.data = payload.data.map(setUserData)
      },
    )
  },
})
