import { BsFillExclamationCircleFill } from 'react-icons/bs'
import styled from 'styled-components'
import colors from 'utils/colors'
import { icons } from 'utils/icons'

export default function DisconnectionAlert({ visible }: { visible: boolean }) {
  if (!visible) {
    return null
  }

  return (
    <ConnectionContainer>
      <BsFillExclamationCircleFill
        style={{ position: 'absolute', top: 0, right: 0, zIndex: 5 }}
        color={colors.red}
        size={20}
      />
      <ConnectionAlertWrapper>
        <img
          className="thick"
          src={icons.thickSignalIcon.src}
          srcSet={icons.thickSignalIcon.srcset}
        />
        <img
          className="med"
          src={icons.mediumSignalIcon.src}
          srcSet={icons.mediumSignalIcon.srcset}
        />
        <img className="thin" src={icons.thinSignalIcon.src} srcSet={icons.thinSignalIcon.srcset} />
        <img className="dot" src={icons.smallDotIcon.src} srcSet={icons.smallDotIcon.srcset} />
      </ConnectionAlertWrapper>
    </ConnectionContainer>
  )
}

const ConnectionContainer = styled.div`
  scale: 0.25;
  z-index: 50;
  position: fixed;
  top: -2.5%;
  left: 25px;
`

const ConnectionAlertWrapper = styled.div`
  width: 80px;
  height: 80px;
  background-color: #ceecf8;
  border-radius: 50%;
  position: relative;

  & img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  & .dot {
    bottom: 16px;
  }

  & .thin {
    bottom: 27px;
  }

  & .med {
    bottom: 34px;
  }

  & .thick {
    bottom: 42px;
  }
`
