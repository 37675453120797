import moment from 'moment'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { FaSignInAlt, FaLink, FaTrash, FaEdit } from 'react-icons/fa'
import { BUTTONS, DROPDOWN, FORM, INFORMATION, MODALS, NAVIGATION } from 'locales/locales'

import { useAppSelector, useIsElectron } from 'app/hooks'
import { FixMeLater } from 'types'
import {
  useDeleteMeetingByIdMutation,
  useGetConferenceUrlMutation,
  useLazyGetMeetingsQuery,
} from 'app/services/conference.service'

import SettingsHeader from 'features/settings/SettingsHeader'
import CustomButton from 'components/button/CustomButton'
import CreateMeetingModal from './create-meeting/CreateMeetingModal'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import useInfoModal from 'utils/hooks/useInfoModal'
import MeetingDetail from './meeting-info/MeetingInfoModal'
import KGrid from 'components/kendo/grid/KGrid'
import { GridColumn } from '@progress/kendo-react-grid'

import { Button } from '@progress/kendo-react-buttons'

interface ActionCellProps {
  dataItem: any
  myId: string
}

export default function VideoCall() {
  const isElectron = useIsElectron();
  const myId = useAppSelector((state) => state.auth.id)
  const { t } = useTranslation()
  const { openModal, closeModal, Modal } = useInfoModal()
  const [meetingId, setMeetingId] = useState('')
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false)
  const [isCreateModalVisible, setisCreateModalVisible] = useState(false)
  const [windowApi, setWindowApi] = useState<any>(null)
  const [meeting, setMeeting] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
  })
  const [lazyGetMeetings] = useLazyGetMeetingsQuery()
  const [deleteMeetingById] = useDeleteMeetingByIdMutation()
  const [getConferenceUrl] = useGetConferenceUrlMutation()

  useEffect(() => {
    const w = window as any
    if (w.api) setWindowApi(w.api)
  }, [])

  const getVideoCalls = async () => {
    const response = await lazyGetMeetings({
      ...dataState,
      count: true,
    })

    if (response?.data) {
      setMeeting(response.data.data)
      const totalRecords = response.data.total[0]?.count
      setTotalPages(Math.ceil(totalRecords / dataState.take))
    }
    setLoading(false)
  }

  useEffect(() => {
    getVideoCalls()
  }, [dataState])

  const ActionCell: React.FC<ActionCellProps> = ({ dataItem, myId }) => {
    const { t } = useTranslation()
    const isMine = dataItem.fromUserId === myId

    return (
      <td>
      <ButtonContainer>
       {!dataItem.isDeleted && (
      <>
      <Button
          onClick={() => onJoin(`${dataItem.url}`)}
          aria-label={t(BUTTONS.JOIN)}
        >
          <FaSignInAlt /> {t(BUTTONS.JOIN)}
        </Button>
        <Button
          onClick={() => onLink(`${dataItem.url}`)}
          aria-label={t(BUTTONS.LINK)}
        >
          <FaLink /> {t(BUTTONS.LINK)}
        </Button>
      {isMine && (
        <>
          <Button onClick={() => onDelete(dataItem.meetingId)} aria-label={t(BUTTONS.DELETE)}>
            <FaTrash /> {t(BUTTONS.DELETE)}
          </Button>
          <Button onClick={() => onEdit(dataItem.meetingId)} aria-label={t(BUTTONS.EDIT)}>
            <FaEdit /> {t(BUTTONS.EDIT)}
          </Button>
        </>
      )}
      </>
    )}
  </ButtonContainer>
      </td>
    )
  }

  const handleFilterChange = (event: any) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    })
  }

  const onConfirmOk = async (meetingId: string) => {
    try {
      await deleteMeetingById({ meetingId })
      getVideoCalls()
    } finally {
      closeModal()
    }
  }
  const onConfirmCancel = () => {
    closeModal()
  }

  const onDelete = (params: FixMeLater) => {
    openModal({
      title: t(MODALS.DELETE_CONFERANCE_TITLE),
      description: t(MODALS.DELETE_CONFERANCE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.YES) as string,
      onCancel: onConfirmCancel,
      onOk: () => onConfirmOk(params),
    })
  }

  const onEdit = async (params: FixMeLater) => {
    setMeetingId(params)
    setIsInfoModalVisible(true)
  }

  const onLink = async (params: FixMeLater) => {
    try {
      const url = `${params}`; 
      navigator.clipboard.writeText(url);
      DropdownAlert.ref.show({ isError: false, message: t(INFORMATION.COPIED_ALERT) });
    } catch(e) {
      //console.log(e);
    }
  }

  const onJoin = async (params: FixMeLater) => {
    try {
      const url = `${params}`; 
      if (isElectron && windowApi) {
        windowApi.send('openConferenceUrl', {
          url, 
          title: 'openConferenceBtn'
        });
      } else {
      window.open(url, '_blank');
      }
    } catch (e) {
      //console.log(e);
    }
  }

  const onCloseInfoModal = () => {
    setIsInfoModalVisible(false)
  }

  const onCloseCreateUserModal = () => {
    setisCreateModalVisible(false)
    getVideoCalls()
  }

  const onClickAddNewMeeting = () => {
    setisCreateModalVisible(true)
  }

  const onDataStateChange = (event: any) => {
    const updatedState = {
      ...dataState,
      skip: event.dataState.skip,
      take: event.dataState.take,
      sort: event.dataState.sort,
    }
    setDataState(updatedState)
  }

  return (
    <Wrapper>
      <TopContainer>
        <SettingsHeader hasSideBar={false} title={t(NAVIGATION.VIDEO_CALL)} />
        <CustomButton
          text={t(BUTTONS.CREATE_MEETING)}
          textColor="white"
          onClick={onClickAddNewMeeting}
        />
      </TopContainer>
      <TableWrapper>
        <div className="grid-header-container">
          <GridContainer>
            <KGrid
              loading={loading}
              datasource={meeting}
              dataState={dataState}
              pageable={true}
              total={totalPages * dataState.take}
              filterable={true}
              editMode={false}
              sortable={true}
              onFilterChange={handleFilterChange}
              onDataStateChange={onDataStateChange}
            >
              <GridColumn field="title" title={t(FORM.MEETING_NAME)}></GridColumn>
              <GridColumn
                field="description"
                title={t(FORM.MEETING_DESCRIPTION)}
                filterable={true}
              />
              <GridColumn
                field="createdAt"
                title={t(FORM.MEETING_CREDATE)}
                filterable={false}
                cell={(props) => {
                  const field = props.field || 'createdAt'
                  const dateStr = props.dataItem[field]
                  const readableDate = moment(dateStr).format('D MMMM YYYY, HH:mm')
                  return <td>{readableDate}</td>
                }}
              />
              <GridColumn
                field="meetingTime"
                title={t(FORM.MEETING_TIME)}
                filterable={false}
                cell={(props) => {
                  const field = props.field || 'meetingTime'
                  const dateStr = props.dataItem[field]
                  const readableDate = moment(dateStr).format('D MMMM YYYY, HH:mm')
                  return <td>{readableDate}</td>
                }}
              />

              <GridColumn
                field="duration"
                title={t(FORM.MEETING_DURATION)}
                filterable={false}
                cell={(props) => {
                  const duration = props.dataItem[props.field || 'duration']
                  return <td>{duration ? `${duration} minutes` : '-'}</td>
                }}
              />
              <GridColumn
                field="isDeleted"
                title={t(DROPDOWN.DELETED_MEETINGS)}
                filterable={false}
                cell={(props) => {
                  const isDeletedText = props.dataItem.isDeleted
                    ? t(DROPDOWN.DELETED_MEETINGS_TRUE)
                    : t(DROPDOWN.DELETED_MEETINGS_FALSE)
                  return <td>{isDeletedText}</td>
                }}
              />
              <GridColumn
                field="isActive"
                title={t(FORM.MEETING_STATE)}
                filterable={false}
                cell={(props) => {
                  const isActiveText = props.dataItem.isActive
                    ? t(FORM.MEETING_STATE_TRUE)
                    : t(FORM.MEETING_STATE_FALSE)
                  return <td>{isActiveText}</td>
                }}
              />

              <GridColumn
                field="actions"
                filterable={false}
                title={t(FORM.ACTIONS)}
                width="400px"
                cell={(props) => <ActionCell {...props} myId={myId} />}
              />
            </KGrid>
          </GridContainer>
        </div>
      </TableWrapper>
      {isCreateModalVisible && (
        <CreateMeetingModal onCancel={onCloseCreateUserModal} onClose={onCloseCreateUserModal} />
      )}
      {isInfoModalVisible && <MeetingDetail meetingId={meetingId} onClose={onCloseInfoModal} />}
      {Modal}
    </Wrapper>
  )
}

const GridContainer = styled.div`
  width: 100%;
`

const TableWrapper = styled.div`
  width: 100%;
  height: 90vh;
  overflow: hidden;
`

const Wrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
`
const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: space-between;
  }
`
