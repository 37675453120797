import { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { FileRejection, useDropzone } from 'react-dropzone'

import { FILE_SIZE_ERROR_CODE, GB, MAX_FILE_NUMBER, MAX_FILE_NUMBER_ERROR } from 'utils/constants'
import { ERRORS } from 'locales/locales'
import { uploadActions } from 'app/slices/upload.slice'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { conversationActions } from 'app/slices/conversations.slice'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import { FixMeLater } from 'types'
interface IProps {
  id: string
  isNewsFeed?: boolean
}

const useUploadFiles = ({ id }: IProps) => {
  const [isDragging, setDragging] = useState(false)
  const { queue } = useAppSelector((state) => state.upload)
  const roomId = location.pathname.split('/')?.[2]
  const { draftData } = useAppSelector((state) => state.message)
  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const onDropRejected = (fileRejections: Array<FileRejection>) => {
    if (fileRejections[0].errors[0].code === FILE_SIZE_ERROR_CODE) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_SIZE_ERROR, {
          file_name: fileRejections[0].file.name,
        }),
      })
    }
  }
  const onDropAccepted = (files: Array<File>) => {
  
    if (
      files.length > MAX_FILE_NUMBER ||
      queue.length > 10 ||
      draftData[roomId]?.files?.length > 10 ||
      draftData[roomId]?.files?.length + files.length > 10
    ) {
      DropdownAlert.ref.show({ isError: true, message: MAX_FILE_NUMBER_ERROR })
      return
    } else {
      files.forEach((file: File) => {
        const startAt = new Date().toISOString()
        const uniqueId = uuidv4()
        dispatch(uploadActions.addQueue({ file, id, uniqueId, startAt }))
        dispatch(conversationActions.addFileInDraft({ file, id, uniqueId, startAt }))
      })
    }
  }

  const Files: File[] | null = []

  //AUDIO UPLOAD
  const onUploadAudio = (audioData: Blob) => {
    const file = new File([audioData], 'audio.mp3', {
      lastModified: new Date().getTime(),
      type: 'audio/mpeg',
    })
    Files.push(file)
    if (Files.length > 10) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.FILE_COUNT_ERROR, { count: 10 }),
      })
    } else {
      Files.forEach((file: File) => {
        const startAt = new Date().toISOString()
        const uniqueId = uuidv4()
        dispatch(conversationActions.addFileInDraft({ uniqueId, file, id, startAt }))
        dispatch(uploadActions.addQueue({ uniqueId, file, id, startAt }))
      })
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: MAX_FILE_NUMBER,
    maxSize: 1 * GB,
    onDropRejected,
    onDropAccepted,
    onDrop: (acceptedFiles, fileRejections) => {
      let includeFolder = false
      includeFolder =
        acceptedFiles.filter((file: FixMeLater) => file.name !== file.path).length > 0 ||
        acceptedFiles.length === 0
      if (includeFolder) {
        DropdownAlert.ref.show({
          isError: true,
          message: t(ERRORS.UPLOAD_FOLDER),
        })
        throw fileRejections
      }
    },
  })

  const onDragEnter = useCallback(() => {
    setDragging(true)
  }, [isDragging])

  const onDragOver = useCallback(() => {
    setDragging(true)
  }, [isDragging])

  const onDragLeave = useCallback(() => {
    setDragging(false)
  }, [isDragging])

  const onDrop = useCallback(() => {
    setDragging(false)
  }, [isDragging])

  return {
    isDragging,
    getInputProps,
    onDragEnter,
    onDragOver,
    onDragLeave,
    onUploadAudio,
    onDrop,
    getRootProps,
  }
}

export default useUploadFiles
