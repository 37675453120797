import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { icons } from 'utils/icons'
import { IPost } from 'types/post.types'
import { IUserData } from 'types/user.types'
import { BUTTONS, OTHERS } from 'locales/locales'

import Comment from './comment/Comment'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import { useEffect } from 'react'

interface ICommentsProps {
  postId: string
  commentPageIndex: number
  comments: IPost[]
  totalCommentCount?: number
  myId: string
  onPagenateComment: FixMeLater
  onShowEditPostModal: FixMeLater
  onResetNewsFeedJumpData?: () => void
  onDeleteComment: (postId: string) => void
  newsFeedJumpData?: FixMeLater
  readyToScrollComment: boolean
}

const Comments = ({
  comments,
  totalCommentCount = 0,
  onPagenateComment,
  myId,
  readyToScrollComment,
  onDeleteComment,
  onShowEditPostModal,
  onResetNewsFeedJumpData,
  newsFeedJumpData,
}: ICommentsProps) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (
      newsFeedJumpData &&
      comments.length > 0 &&
      newsFeedJumpData?.commentId &&
      onResetNewsFeedJumpData &&
      readyToScrollComment
    ) {
      const index = comments.findIndex((item) => item._id === newsFeedJumpData.commentId)
      const element = document.getElementById(`comment-${index}`)
      if (element) {
        element.scrollIntoView()
        onResetNewsFeedJumpData()
      }
    }
  }, [newsFeedJumpData, comments, readyToScrollComment])

  if (comments?.length == 0) {
    return (
      <EmptyComment>
        <CustomText typography="body3" color={colors.gray80} textAlign="center">
          {t(OTHERS.NO_COMMENTS_YET)}
        </CustomText>
      </EmptyComment>
    )
  }

  return (
    <Wrapper>
      {comments.map((comment, index) => {
        const creatorInfo = comment.users?.find((user) => user._id === comment.ownerId)
        if(!creatorInfo) return null;
        return (
          <Comment
            id={'comment-' + index.toString()}
            key={comment._id}
            postId={comment._id}
            fullname={creatorInfo.fullname}
            creatorId={creatorInfo._id}
            avatar={creatorInfo?.avatar}
            team={creatorInfo?.team}
            data={comment.content}
            users={comment.userData}
            createdAt={comment.createdAt}
            isOption={comment.ownerId === myId}
            onDeleteComment={onDeleteComment}
            onShowEditPostModal={() => onShowEditPostModal(comment._id)}
          />
        )
      })}
      {totalCommentCount > comments?.length && (
        <ActionButton
          text={t(BUTTONS.SEE_MORE)}
          icon={icons.chevronDownIconBlue}
          iconAlignment="right"
          typography="body4"
          textColor={colors.vime}
          onClick={() => onPagenateComment((prev: number) => prev + 1)}
        />
      )}
    </Wrapper>
  )
}

export default Comments

const EmptyComment = styled.div`
  margin-top: 20px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ActionButton = styled(CustomButton)`
  margin-top: 8px;
  align-items: center;
  width: 150px;
  box-shadow: none;
  height: 28px;
  gap: 8px;
  padding: 0px 20px;
  background-color: ${colors.white};
  &:hover {
    background-color: ${colors.vime10};
  }
`
