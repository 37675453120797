import { useAppDispatch, useAppSelector } from 'app/hooks'
import { useLazyGetNewsFeedNotificationsQuery } from 'app/services/post.service'
import { newsFeedActions } from 'app/slices/news-feed.slice'
import Avatar, { AvatarSize } from 'components/avatar/Avatar'
import Spinner from 'components/spinner/Spinner'
import CustomText from 'components/text/CustomText'
import { EMPTY } from 'locales/locales'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FixMeLater } from 'types'
import colors from 'utils/colors'
import { MOBILE_DEVICE_WIDTH } from 'utils/constants'
import { formatPostDate } from 'utils/helpers/postHelper'

interface INotification {
  title: string
  onJumpComment: () => void
}

const NotificationModal: React.FC<INotification> = ({ title, onJumpComment }) => {
  const { t } = useTranslation()
  const { notifications, realNotificationPageNumber } = useAppSelector((state) => state.newsFeed)
  const [showMore, setShowMore] = useState(false)
  const dispatch = useAppDispatch()

  const [isFetching, setFetching] = useState(true)
  const [getAllNotifications] = useLazyGetNewsFeedNotificationsQuery()

  const [scrollPosition, setScrollPosition] = useState(0)

  useEffect(() => {
    fetchNotificationsByPage(0)
  }, [])

  const fetchNotificationsByPage = async (page: number) => {
    const { data }: FixMeLater = await getAllNotifications({ page })
    dispatch(newsFeedActions.setInitialNotifications(data?.data))
    dispatch(newsFeedActions.updateRealNotificationPage(page))
    if (data?.data?.length % 20 === 0) {
      setShowMore(true)
    }
    setFetching(false)
  }

  const onFetchNotifByPage = async (page: number) => {
    saveScrollPosition() // Save the scroll position before fetching
    const { data }: FixMeLater = await getAllNotifications({ page })
    dispatch(newsFeedActions.updateNotifications(data?.data))
    if (data?.data?.length % 20 === 0) {
      dispatch(newsFeedActions.updateRealNotificationPage(page))
    } else {
      setShowMore(false)
    }
  }

  useEffect(() => {
    const element = document.getElementById('notificationContent')
    if (element) {
      element.scrollTop = scrollPosition
    }

    
  }, [notifications])

  const saveScrollPosition = () => {
    const element = document.getElementById('notificationContent')
    if (element) {
      setScrollPosition(element.scrollTop)
    }
  }

  const printedNotifications:string[] = []

  return (
    <Wrapper>
      <Header>
        <CustomText typography="header1"> {title}</CustomText>
      </Header>
      {!isFetching && notifications.length === 0 && (
        <Empty>{t(EMPTY.EMPTY_NEWS_FEED_NOTIFICATION)}</Empty>
      )}
      {isFetching && <Spinner loading={true} height="18" width="18" />}
      {notifications.length > 0 && (
        <Content id="notificationContent">
          {!isFetching &&
            notifications.map((notification: FixMeLater) => {
              if (printedNotifications.includes(notification._id)) return null

              printedNotifications.push(notification._id);
              return (
                <Notification
                  onClick={onJumpComment.bind(this, notification)}
                  key={notification._id}
                >
                  {!notification.isRead && <NotificationPoint />}
                  <Avatar
                    source={notification.userData?.[notification?.relatedUser]?.avatar}
                    text={notification.userData?.[notification?.relatedUser]?.fullname}
                    size={AvatarSize.small}
                    type="circle"
                  />
                  <NotificationContent>
                    <div>
                      <CustomText typography="body8">
                        <RelatedUser>
                          {notification.userData?.[notification?.relatedUser]?.fullname}
                        </RelatedUser>
                        {notification.text.split(':*')[0]}: &quot;
                        <b>{notification.text?.split(':*')[1]?.trim()}</b>&quot;
                      </CustomText>
                    </div>
                    <div>
                      <CustomText typography="body7" color={colors.gray60}>
                        {formatPostDate(moment(notification.createdAt))}
                      </CustomText>
                    </div>
                  </NotificationContent>
                </Notification>
              )
            })}
          {showMore && (
            <StyledShowMore
              typography="header5"
              color={colors.vime}
              onClick={onFetchNotifByPage.bind(this, realNotificationPageNumber + 1)}
            >
              Show More
            </StyledShowMore>
          )}
        </Content>
      )}
    </Wrapper>
  )
}

export default NotificationModal

const Wrapper = styled.div`
  position: absolute;
  z-index: 3;
  right: 0;
  top: 60px;
  width: 350px;
  border: 1px solid ${colors.light80};
  box-shadow: 4px 4px 20px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  background: ${colors.white};
  padding: 16px 19px 16px 16px;
  @media (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    width: 100%;
    height: 85vh;
    overflow-y: auto;
  }
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 245px;
  overflow: auto;
  @media (max-width: ${MOBILE_DEVICE_WIDTH}px) {
    height: 100%;
    overflow: none;
  }
`
const Empty = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 120px;
  height: 300px;
`

const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-left: 5px;
`
const Notification = styled.div`
  display: flex;
  cursor: pointer;
  gap: 10px;
  position: relative;
  margin: 5px 5px 0px 5px;
`
const NotificationContent = styled.div`
  display: flex;
  flex-direction: column;
`
const RelatedUser = styled.span`
  color: ${colors.vime};
  font-weight: '600';
`
const NotificationPoint = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${colors.vime};
  border-radius: 50%;
  position: absolute;
  z-index: 10;
  top: -5px;
  left: -5px;
`

const StyledShowMore = styled(CustomText)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
`
