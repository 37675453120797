import { GridItemChangeEvent } from '@progress/kendo-react-grid'
import { cloneDeep } from 'lodash'


export const enterEdit = (datasource: any, id: any, status: boolean) => {
  const cloneDataSource = cloneDeep(datasource.data);
  const updatedDataSource = cloneDataSource.map((cloneRole: any) =>
    cloneRole._id === id ? { ...cloneRole, inEdit: status } : cloneRole,
  );
  return { ...datasource, data: updatedDataSource };
}

export const discard = (datasource:any) => ({
  ...datasource,
  data: Array.isArray(datasource.data) ? datasource.data.filter((item: { inEdit: any; }) => !item.inEdit) : datasource.data,
});

export const itemChange = (datasource: any, e: GridItemChangeEvent) => {
  if (!datasource || !datasource.data || !Array.isArray(datasource.data)) {
    return [];
  }
  const updatedData = datasource.data.map((item: any) =>
    item._id === e.dataItem._id ? { ...item, [e.field ?? '']: e.value } : item,
  );
  return { ...datasource, data: updatedData };
}


export const addNew = (datasource: any) => {
  const newDataItem = { inEdit: true };
  if (Array.isArray(datasource.data)) {
    const updatedData = [newDataItem, ...datasource.data];
    return { ...datasource, data: updatedData };
  } else {
    //console.error('datasource.data bir dizi değil:', datasource);
    return datasource;
  }
};



export const addChange = (datasource: any, insertedId: string) => {
  const cloneDataSource = cloneDeep(datasource)
  const newsource = cloneDataSource.map((item: any) => {
    return !item._id ? { ...item, _id: insertedId } : item
  })

  return newsource
}
