import { useState } from 'react'
import styled from 'styled-components'

import colors from 'utils/colors'

import { IUserData } from 'types/user.types'

import CustomText from 'components/text/CustomText'
import { useTranslation } from 'react-i18next'
import { MESSAGES } from 'locales/locales'
import { useAppSelector } from 'app/hooks'

interface IReactionProps {
  messageId: string
  users: IUserData
  data: Array<{ emoji: string; users: Array<string> }>
  onReactionMessage?: (messageId: string, emoji: string, isAdd: boolean) => void
}

const Reaction = ({ messageId, data, users, onReactionMessage }: IReactionProps) => {

  const {myId} = useAppSelector(state => state.auth.id) 
  const { t } = useTranslation()
  const [visibleTooltipIndex, setVisibleTooltipIndex] = useState<number | undefined>()
  const getReactedPeople = (reactedPeopleIds: Array<string>) => {
    return reactedPeopleIds
      .filter((userId: string) => users[userId])
      .map((userId: string) => (users[userId]._id === myId ? t(MESSAGES.YOU) : users[userId].fullname))
      .join(', ')
  }

  const onToggleVisibleTooltip = (index?: number) => {
    setVisibleTooltipIndex(index)
  }

  const onReaction = (reactionParams: { emoji: string; users: Array<string> }) => {
    const isAdd = !reactionParams.users.find((userId: string) => users[userId]._id === myId)
    onReactionMessage?.(messageId, reactionParams.emoji, isAdd)
    onToggleVisibleTooltip()
  }
  if (!data) return null
  return (
    <Wrapper>
      {data?.map((item, index: number) => {
        const reactedPeople = visibleTooltipIndex === index ? getReactedPeople(item.users) : null
        return (
          <ReactionWrapper
            key={index}
            onMouseOver={() => onToggleVisibleTooltip(index)}
            onMouseLeave={() => onToggleVisibleTooltip()}
          >
            {reactedPeople && (
              <>
                <ReactedPeopleWrapper>{reactedPeople}</ReactedPeopleWrapper>
                <Triangle />
              </>
            )}
            <ReactioBox onClick={() => onReaction(item)}>
              <div>{item?.emoji}</div>
              <CustomText typography="header4" color={colors.gray60}>
                {item.users.length}
              </CustomText>
            </ReactioBox>
          </ReactionWrapper>
        )
      })}
    </Wrapper>
  )
}

export default Reaction

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
`

const ReactionWrapper = styled.div`
  display: flex;
  position: relative;
`

const ReactedPeopleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 140px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  position: absolute;
  padding: 8px;
  background-color: ${colors.vimeDark};
  color: ${colors.white};
  bottom: 140%;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  left: 15px;
  z-index: 4;
`

export const Triangle = styled.div`
  clip-path: polygon(100% 0, 0 0, 0 100%);
  position: absolute;
  bottom: 32px;
  left: 15px;
  height: 8px;
  width: 8px;
  background-color: ${colors.vimeDark};
`

const ReactioBox = styled.div`
  column-gap: 5px;
  border: 1px solid ${colors.vime};
  display: flex;
  align-items: center;
  border-radius: 4.5px;
  padding: 0px 4px;
  cursor: pointer;
  position: relative;
  margin-right: 4px;
  box-shadow: 0px 0.120674px 0.362021px rgba(0, 0, 0, 0.2),
    inset -0.120674px -0.362021px 0px rgba(0, 0, 0, 0.1);
`
