import { memo } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import colors from 'utils/colors'
import { icons } from 'utils/icons'
import { MESSAGES } from 'locales/locales'

import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'

export interface IJumperProps {
  onClose: () => void
  onScrollBottom: () => void
  counter: number
}

const Jumper = ({ onClose, onScrollBottom, counter }: IJumperProps) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <CustomText typography="body3" color={colors.white} cursor="pointer" onClick={onScrollBottom}>
        {t(MESSAGES.JUMP_NEW_MESSAGES)}
      </CustomText>
      <CustomText typography="body4" color={colors.white}>
        {counter + ' ' + t(MESSAGES.NEW_MESSAGES) + '!'}
      </CustomText>
      <CustomIcon source={icons.closeIconWhite} onClick={onClose} />
    </Wrapper>
  )
}

export default memo(Jumper)

export const Wrapper = styled.div`
  display: flex;
  position: absolute;
  top: 80px;
  left: 0px;
  right: 0px;
  margin: 0px 20px;
  background-color: ${colors.vime};
  align-items: center;
  padding: 0px 2px 2px 6px;
  border-radius: 4px;
  z-index: 3;
  justify-content: space-between;
`
