import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  useCreateChannelMutation,
  useLazyGetMembersBySearchForChannelQuery,
} from 'app/services/group.service'
import { FixMeLater } from 'types'
import { IChannelMember } from 'types/user.types'
import { MODALS, PLACEHOLDERS, TABS } from 'locales/locales'

import General from './General'
import Members from 'components/members/Members'
import CustomModal from '../../../components/modals/CustomModal'
import { useAppSelector } from 'app/hooks'
import { IRolePermissions, TeamAndChannelPermissions } from 'types/role.types'
import { isFlagSetIn } from 'utils/helpers/isSetInFlags'

interface ICreateChannelModalProps {
  groupId: string
  onClose: () => void
}

const getIsEmail = (email: string) => {
  const emailRegex = /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/
  return emailRegex.test(email)
}

const CreateChannelModal = ({ groupId, onClose }: ICreateChannelModalProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [generalInfo, setGeneralInfo] = useState({ name: '', description: '', isPrivate: false })
  const [members, setMembers] = useState<IChannelMember[]>([])
  const [membersBySearch, setMembersBySearch] = useState<IChannelMember[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [createChannel] = useCreateChannelMutation()
  const [getMembersBySearch] = useLazyGetMembersBySearchForChannelQuery()
  const permissions = useAppSelector<IRolePermissions>(state => state.auth.permissions);
  const isAdmin = isFlagSetIn([TeamAndChannelPermissions.CREATE_OR_ARCHIVE_CHANNEL], permissions.teamAndChannel);

  const onCreate = async () => {
    try {
      setIsLoading(true)
      const preparedMembers: { guestUsers: Array<string>; systemUsers: Array<string> } = {
        guestUsers: [],
        systemUsers: [],
      }
      members.forEach((member) => {
        if (member._id) preparedMembers.systemUsers.push(member._id)
        else preparedMembers.guestUsers.push(member.email)
      })
      const result: FixMeLater = await createChannel({
        ...generalInfo,
        groupId,
        members: preparedMembers,
      })
      if (result?.data?.success) {
        onClose()
        navigate(`/channels/${result.data.data._id}`)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const onChangeGeneralInfo = (name: string, value: string | boolean) => {
    setGeneralInfo({ ...generalInfo, [name]: value })
  }

  const onSelectMember = (user: IChannelMember) => {
    setMembers([...members, user])
  }

  const onInviteMember = (email: string) => {
    setMembers([...members, { email }])
  }

  const onRemoveUser = (user: IChannelMember) => {
    const removedData = members.filter(
      (member) => member._id !== user._id || member.email !== user.email,
    )
    setMembers([...removedData])
  }

  const onSearch = async (text: string) => {
    try {
      const result: FixMeLater = await getMembersBySearch({ searchName: text })
      const filteredUsers = result.data.data.filter((fetchedUser: IChannelMember) => {
        const findedMember = members.findIndex(
          (member) => member._id === fetchedUser._id || member._id === fetchedUser.email,
        )
        return findedMember !== -1 ? false : true
      })
      if (getIsEmail(text)) {
        const hasUser = members.find((member) => member.email === text)
        if (!hasUser) {
          filteredUsers.push({ email: text })
        }
      }
      setMembersBySearch(filteredUsers)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <CustomModal
      title={t(MODALS.CREATE_NEW_CHANNEL_TITLE)}
      onOk={onCreate}
      closeModal={onClose}
      onCancel={onClose}
      loading={isLoading}
      tab={[
        { title: t(TABS.GENERAL), action: true },
        { title: t(TABS.MEMBERS), action: true },
      ]}
      hasMember
    >
      <General {...generalInfo} onChange={onChangeGeneralInfo} />
      <Members
        isSearch
        isAddMember
        isInvite
        isRemove
        data={members}
        searchedData={membersBySearch}
        isAdmin={isAdmin}
        placeholder={t(PLACEHOLDERS.MEMBERS_PLACEHOLDER)}
        isChannelCreation={true}
        onChangeSearchText={onSearch}
        onSelectMember={onSelectMember}
        onRemoveUser={onRemoveUser}
        onInviteMember={onInviteMember}
      />
    </CustomModal>
  )
}

export default CreateChannelModal
