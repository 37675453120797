import tr from 'date-fns/locale/tr' // the locale you want
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DatePicker, { registerLocale } from 'react-datepicker'
import { DeviceShareOptions, RepeadDayOptions } from './CreateMeetingModal'
import 'react-datepicker/dist/react-datepicker.css'

import colors from 'utils/colors'
import { FixMeLater } from 'types'
import { FORM } from 'locales/locales'
import { icons } from 'utils/icons'

import Switch from 'components/switch/Switch'
import CustomIcon from 'components/icon/CustomIcon'
import CustomText from 'components/text/CustomText'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

registerLocale('tr', tr)
interface IAddMeetingProps {
  selectedOption: FixMeLater
  hasWaitingRoom: boolean
  hasNotification: boolean
  publicDraw: boolean
  isPublic: boolean
  guestMode: boolean
  isPasswordProtected: boolean
  password: string
  meetingName: string
  meetingDescription: string
  meetingTime: FixMeLater
  duration: string
  isRepeatable: boolean
  repeatEndTime: FixMeLater
  selectedRepeatOptions?: FixMeLater
  onChangeInput: (value: string | boolean | Date, key: string) => void
  onRemoveRepeatDay?: (value: any) => void
}
const AdvancedSettings = ({
  selectedOption,
  selectedRepeatOptions,
  hasWaitingRoom,
  hasNotification,
  publicDraw,
  isPublic,
  guestMode,
  isPasswordProtected,
  password,
  isRepeatable,
  repeatEndTime,
  onChangeInput,
  onRemoveRepeatDay,
}: IAddMeetingProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <div style={{ width: '100%', height: '5rem' }}>
        <CustomFormInput
          value={selectedOption}
          title={t(FORM.DEVICE_SHARE_RESTRICTION)}
          placeholder={t(FORM.DEVICE_SHARE_RESTRICTION)}
          dropDownData={DeviceShareOptions}
          selectedOption={selectedOption}
          onChange={(value: string) => onChangeInput(value, 'device_share_restriction')}
        />
      </div>
      <SwitchWrapper>
        <CustomText typography="body4">{t(FORM.WAITING_ROOM)}</CustomText>
        <Switch
          onChange={() => onChangeInput(!hasWaitingRoom, 'hasWaitingRoom')}
          checked={hasWaitingRoom}
        />
      </SwitchWrapper>
      <SwitchWrapper>
        <CustomText typography="body4">{t(FORM.NOTIFICATION)}</CustomText>
        <Switch
          onChange={() => onChangeInput(!hasNotification, 'hasNotification')}
          checked={hasNotification}
        />
      </SwitchWrapper>
      <SwitchWrapper>
        <CustomText typography="body4">{t(FORM.DRAW)}</CustomText>
        <Switch onChange={() => onChangeInput(!publicDraw, 'publicDraw')} checked={publicDraw} />
      </SwitchWrapper>
      <SwitchWrapper>
        <CustomText typography="body4">{t(FORM.IS_PUBLIC)}</CustomText>
        <Switch onChange={() => onChangeInput(!isPublic, 'isPublic')} checked={isPublic} />
      </SwitchWrapper>
      {isPublic && (
        <SwitchWrapper>
          <CustomText typography="body4">{t(FORM.GUEST_MODE)}</CustomText>
          <Switch onChange={() => onChangeInput(!guestMode, 'guestMode')} checked={guestMode} />
        </SwitchWrapper>
      )}
      <SwitchWrapper>
        <CustomText typography="body4">{t(FORM.IS_ROOM_PROTECTED)}</CustomText>
        <Switch
          onChange={() => onChangeInput(!isPasswordProtected, 'isPasswordProtected')}
          checked={isPasswordProtected}
        />
      </SwitchWrapper>
      {isPasswordProtected && (
        <CustomFormInput
          type={'text'}
          title={t(FORM.ROOM_PASSWORD)}
          placeholder={t(FORM.ROOM_PASSWORD)}
          value={password}
          onChange={(value: string) => onChangeInput(value, 'password')}
        />
      )}
      <SwitchWrapper>
        <CustomText typography="body4">{t(FORM.REPEATABLE)}</CustomText>
        <Switch
          onChange={() => onChangeInput(!isRepeatable, 'isRepeatable')}
          checked={isRepeatable}
        />
      </SwitchWrapper>
      {isRepeatable && (
        <>
          <div style={{ width: '100%', height: '4rem' }}>
            <CustomFormInput
              type={'text'}
              title={t(FORM.REPEAT_DAYS)}
              dropDownData={RepeadDayOptions}
              selectedOption={selectedRepeatOptions}
              placeholder={t(FORM.REPEAT_DAYS)}
              value={selectedRepeatOptions}
              onChange={(value: string) => onChangeInput(value, 'repeat_days')}
            />
          </div>
          {selectedRepeatOptions?.length !== 0 && (
            <MultipleSearchWrapper>
              {selectedRepeatOptions?.map((channel: any, index: number) => {
                return (
                  <SelectedItemWrapper key={index}>
                    <CustomText typography="body3">{channel.title}</CustomText>
                    <CustomIcon
                      iconPreset="small"
                      source={icons.closeIconSmall}
                      onClick={() => onRemoveRepeatDay?.(channel)}
                    />
                  </SelectedItemWrapper>
                )
              })}
            </MultipleSearchWrapper>
          )}
          <div
            style={{
              width: '100%',
              height: '4rem',
              display: 'flex',
              flexDirection: 'column',
              gap: '5px',
            }}
          >
            <CustomText typography="header3">{t(FORM.REPEAT_END_TIME)}</CustomText>
            <DatePicker
              locale={'tr'}
              wrapperClassName="datePicker"
              timeIntervals={10}
              showTimeSelect
              dateFormat="Pp"
              selected={new Date(repeatEndTime)}
              onChange={(e) => onChangeInput(e as Date, 'repeatEndTime')}
            />
          </div>
        </>
      )}
    </Wrapper>
  )
}

export default AdvancedSettings
const SelectedItemWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;
  padding: 2px 6px;
  background-color: ${colors.vime20};
  border-radius: 4px;
  gap: 6px;
  z-index: 7;
`
const MultipleSearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
  row-gap: 3px;
  margin-top: 7px;
  margin-left: 5px;
  margin-bottom: 10px;
  z-index: 1;
`

const SwitchWrapper = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const Wrapper = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: visible;
  .datePicker {
    display: flex;
    align-items: center;
    border: 1px solid ${colors.light80};
    border-radius: 8px;
    height: 32px;
    padding: 0px 8px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`
