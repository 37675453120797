import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MB } from 'utils/constants'
import uploadApi from 'app/axiosApi'
import { ERRORS } from 'locales/locales'

import DropdownAlert from 'components/dropdown-alert/DropdownAlert'

const useUploadPhoto = (cb: (url: string) => void) => {
  const [isUploadLoading, setIsLoading] = useState(false)
  const { t } = useTranslation()
  const onUploadPhoto = async (selectedFile: FileList) => {
    setIsLoading(true)
    const file = Array.from(selectedFile)
    if (file[0]?.size / MB > 50) {
      setIsLoading(false)
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.UPLOAD_IMAGE_ERROR),
      })
      return
    }
    try {
      const result = await uploadApi.uploadFile('files/', file, 'file')
      setIsLoading(false)
      cb(result.data.data.url)

      return result.data.data.url
    } catch (err) {
      setIsLoading(false)
    }
  }

  return {
    isUploadLoading,
    onUploadPhoto,
  }
}

export default useUploadPhoto
