import { memo } from 'react'

import colors from 'utils/colors'

import CustomText from 'components/text/CustomText'
import styled from 'styled-components'

interface IMargin {
  left?: number | 0
  right?: number | 0
  top?: number | 0
  bottom?: number | 0
}
interface ISeparator {
  description?: string
  margin?: IMargin
  color?: string
}
interface IStyledProps {
  margin?: {
    top?: number
    bottom?: number
    left?: number
    right?: number
  }
  color?: string
}

const Separator = ({ description, margin, color = colors.light80 }: ISeparator) => {
  return (
    <Wrapper margin={margin}>
      <SeparatorLine color={color} />
      {description && (
        <PositionalText>
          <CustomText color={colors.gray100} typography="body5">
            {description}
          </CustomText>
        </PositionalText>
      )}
    </Wrapper>
  )
}

export default memo(Separator)

const SeparatorLine = styled.div`
  background-color: ${(props: IStyledProps) => props.color};
  height: 1px;
  width: 100%;
  border-radius: 2px;
`

const Wrapper = styled.div`
  margin-top: ${(props: IStyledProps) => (props.margin ? props.margin.top : 0)}px;
  margin-bottom: ${(props: IStyledProps) => (props.margin ? props.margin.bottom : 0)}px;
  margin-left: ${(props: IStyledProps) => (props.margin ? props.margin.left : 0)}px;
  margin-right: ${(props: IStyledProps) => (props.margin ? props.margin.right : 0)}px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PositionalText = styled.div`
  padding: 2px 5px;
  position: absolute;
  text-align: center;
  background-color: ${colors.white};
  border-radius: 4px;
`
