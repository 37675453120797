import { memo } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import colors from 'utils/colors'
import { ON } from 'utils/constants'
import {
  useLeaveChannelMutation,
  useUpdateRoomArchiveMutation,
  useUpdateRoomChannelApparanceMutation,
} from 'app/services/room.service'
import { FixMeLater } from 'types'
import useInfoModal from 'utils/hooks/useInfoModal'
import { groupsActions } from 'app/slices/groups.slice'

import Switch from 'components/switch/Switch'
import Loading from 'components/spinner/Loading'
import CustomText from 'components/text/CustomText'
import Separator from 'components/separator/Separator'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import { BUTTONS, FORM, MESSAGES, MODALS, PLACEHOLDERS } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import { conversationActions } from 'app/slices/conversations.slice'
import { requiredValidation } from 'utils/helpers/validations'

interface IGeneralProps {
  channelId?: string
  isModerator: boolean
  name: string
  description: string
  isPrivate: boolean
  createdBy: string
  createdAt?: string
  isMuted: boolean
  notifyOnlyMentions: boolean
  isArchived: boolean
  isAppear: boolean
  onChange: (key: string, value: string | boolean) => void
  onClose: () => void
}

const General = ({
  channelId,
  isModerator,
  name,
  description,
  isPrivate,
  createdBy,
  createdAt,
  isMuted,
  notifyOnlyMentions,
  isArchived,
  isAppear,
  onChange,
  onClose,
}: IGeneralProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { openModal, closeModal, Modal } = useInfoModal()

  const [leaveChannel] = useLeaveChannelMutation()
  const [updateRoomArchive] = useUpdateRoomArchiveMutation()
  const [updateRoomChannelApparance] = useUpdateRoomChannelApparanceMutation()

  const onApproveLeaveChannel = async () => {
    let data: FixMeLater = null
    try {
      Loading.ref.show()
      data = await leaveChannel({ roomId: channelId })
      navigate(data?.data?.data)
      closeModal()
      onClose()
    } finally {
      Loading.ref.hide()
      closeModal()
      onClose()
      if (!data?.error) {
        setTimeout(() => {
          dispatch(conversationActions.removeAllDraftAndFailedMessages(channelId))
          dispatch(conversationActions.removeAllMessages(channelId))
          dispatch(groupsActions.clearChannel(channelId))
        }, 200)
      }
    }
  }

  const onApproveAppearChannel = async () => {
    try {
      Loading.ref.show()
      const result: FixMeLater = await updateRoomChannelApparance({
        roomId: channelId,
        isAppear: !isAppear,
      })
      closeModal()
      onClose()
      dispatch(groupsActions.updateApperance(result.data))
      navigate(result.data.redirectUrl)
    } finally {
      Loading.ref.hide()
    }
  }

  const onApproveArchiveChannel = async () => {
    try {
      Loading.ref.show()
      await updateRoomArchive({ roomId: channelId, isArchived: !isArchived })
      if (isArchived && !isAppear) {
        const result: FixMeLater = await updateRoomChannelApparance({
          roomId: channelId,
          isAppear: true,
        })
        dispatch(groupsActions.updateApperance(result.data))
      }
      closeModal()
      onClose()
    } finally {
      dispatch(conversationActions.removeAllDraftAndFailedMessages(channelId))
      Loading.ref.hide()
    }
  }

  const onLeaveChannel = () => {
    openModal({
      title: t(MODALS.LEAVE_CHANNEL_TITLE),
      description: t(MODALS.LEAVE_CHANNEL_DESCRIPTION),
      okTitle: t(BUTTONS.LEAVE) as string,
      onCancel: closeModal,
      onOk: onApproveLeaveChannel,
    })
  }

  const onToggleAppearChannel = () => {
    openModal({
      title: t(isAppear ? MODALS.DISAPPEAR_TITLE : MODALS.APPEAR_TITLE),
      description: t(
        isAppear ? MODALS.DISAPPEAR_CHANNEL_DESCRIPTION : MODALS.APPEAR_CHANNEL_DESCRIPTION,
      ),
      okTitle: t(isAppear ? BUTTONS.DISAPPEAR : BUTTONS.APPEAR) as string,
      onCancel: closeModal,
      onOk: onApproveAppearChannel,
    })
  }

  const onToggleArchiveChannel = () => {
    openModal({
      title: isArchived ? t(MODALS.UNARCHIVE_TITLE) : t(MODALS.ARCHIVE_TITLE),
      description: isArchived ? t(MODALS.UNARCHIVE_DESCRIPTION) : t(MODALS.ARCHIVE_DESCRIPTION),
      okTitle: (isArchived ? t(BUTTONS.UNARCHIVE) : t(BUTTONS.ARCHIVE)) as string,
      onCancel: closeModal,
      onOk: onApproveArchiveChannel,
    })
  }
  const validName = requiredValidation(name, t(FORM.CHANNEL_NAME)).errorText
  return (
    <Wrapper>
      <FormWrapper>
        <CustomFormInput
          value={name}
          disabled={!isModerator  || isArchived}
          title={t(FORM.CHANNEL_NAME) as string}
          placeholder={t(PLACEHOLDERS.CHANNEL_NAME_PLACEHOLDER) as string}
          onChange={(value: string) => onChange('name', value)}
          errorText={validName}
        />
        <CustomFormInput
          value={description}
          disabled={!isModerator  || isArchived}
          title={t(FORM.CHANNEL_DESCRIPTION) as string}
          optionalTitle={t(FORM.OPTIONAL) as string}
          placeholder={t(PLACEHOLDERS.CHANNEL_DESCRIPTION_PLACEHOLDER) as string}
          onChange={(value: string) => onChange('description', value)}
        />
      </FormWrapper>
      <Row direction="column" gap={10} marginBottom={10}>
        <CustomText typography="header3">{t(MESSAGES.CREATED_BY)}</CustomText>
        <CustomText typography="body3">
          {createdBy + ON + moment(createdAt).format('DD MMM yyyy HH:mm')}
        </CustomText>
      </Row>
        <Row align="center" justify="space-between">
          <CustomText typography="body4">{t(BUTTONS.PRIVATE)}</CustomText>
          <Switch
            checked={isPrivate}
            disabled={!isModerator || isArchived}
            onChange={(checked) => onChange('isPrivate', !checked)}
          />
        </Row>
      <Separator margin={{ top: 20, bottom: 10 }} />
      <Row align="center" justify="space-between" marginBottom={10}>
        <CustomText typography="body4">{t(BUTTONS.NOTIFICATIONS)}</CustomText>
        <Switch
          checked={!isMuted}
          onChange={(checked) => onChange('isMuted', checked)}
          disabled={isArchived}
        />
      </Row>
      {!isMuted && (
        <Row align="center" justify="space-between" marginBottom={10}>
          <CustomText typography="body4">{t(BUTTONS.MENTIONS_ONLY)}</CustomText>
          <Switch
            checked={notifyOnlyMentions}
            disabled={isArchived}
            onChange={(checked) => onChange('notifyOnlyMentions', !checked)}
          />
        </Row>
      )}
      {isModerator && (
        <Row marginBottom={10} direction="column">
          <CustomText
            typography="body4"
            color={colors.red}
            cursor="pointer"
            onClick={onToggleArchiveChannel}
          >
            {isArchived ? t(BUTTONS.UNARCHIVE) : t(BUTTONS.ARCHIVE)}
          </CustomText>
        </Row>
      )}
      {isArchived && (
        <Row marginBottom={10} direction="column">
          <CustomText
            typography="body4"
            color={colors.red}
            cursor="pointer"
            onClick={onToggleAppearChannel}
          >
            {t(isAppear ? BUTTONS.DISAPPEAR_CHANNEL : BUTTONS.APPEAR_CHANNEL)}
          </CustomText>
        </Row>
      )}
      <Row marginBottom={10} direction="column">
        <CustomText typography="body4" color={colors.red} cursor="pointer" onClick={onLeaveChannel}>
          {t(BUTTONS.LEAVE_CHANNEL)}
        </CustomText>
      </Row>
      {Modal}
    </Wrapper>
  )
}

export default memo(General)

const Wrapper = styled.div``

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`
const Row = styled.div.attrs(
  (props: {
    direction?: string
    gap: number
    marginBottom: number
    marginTop?: number
    align: string
    justify: string
  }) => props,
)`
  display: flex;
  flex-direction: ${({ direction }) => direction || 'row'};
  align-items: ${({ align }) => align || 'flex-start'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  gap: ${({ gap }) => gap || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
`
