import { useEffect, useState } from 'react'
import styled from 'styled-components'

import {
  useCreateMailSettingsMutation,
  useLazyGetMailSettingsQuery,
  useUpdateMailSettingsMutation,
} from 'app/services/system.service'
import SettingsHeader from 'features/settings/SettingsHeader'
import { BUTTONS, FORM, PLACEHOLDERS, TITLES } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import {
  emailValidation,
  mailSettingsValidation,
  requiredValidation,
} from 'utils/helpers/validations'
import colors from 'utils/colors'

import CustomText from 'components/text/CustomText'
import Switch from 'components/switch/Switch'
import CustomButton from 'components/button/CustomButton'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'
import Loading from 'components/spinner/Loading'

const MailManagement = () => {
  const { t } = useTranslation()
  const [mailDataId, setmailDataId] = useState('')
  const [formChecked, setFormChecked] = useState(false)
  const [mailSettings, setMailSettings] = useState({
    hostname: '',
    port: '',
    from: '',
    fromName: '',
    username: '',
    password: '',
    ssl: false,
  })
  const [getMailSettings] = useLazyGetMailSettingsQuery()
  const [createMailSettings] = useCreateMailSettingsMutation()
  const [updateMailSettings] = useUpdateMailSettingsMutation()

  useEffect(() => {
    fetchMailSettings()
  }, [])

  const fetchMailSettings = async () => {
    try {
      const data = await getMailSettings({})
      if (data.data.data[0]) {
        setMailSettings({ ...data.data.data[0] })
        setmailDataId(data.data.data[0]._id)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmitHandler = async () => {
    setFormChecked(true)
    const isValidInput = mailSettingsValidation(mailSettings)
    const { isValid } = emailValidation(mailSettings.from)
    if (!isValidInput || !isValid) {
      return
    }
    try {
      Loading.ref.show()
      if (mailDataId == '') {
        await createMailSettings(mailSettings)
      } else {
        await updateMailSettings({ ...mailSettings, _id: mailDataId })
      }
    } finally {
      Loading.ref.hide()
      setFormChecked(false)
    }
  }
  const onChangeInput = (value: string | boolean, key: string) => {
    setMailSettings({ ...mailSettings, [key]: value })
  }

  const validHostname =
    formChecked && requiredValidation(mailSettings.hostname, t(FORM.HOSTNAME)).errorText
  const validPort = formChecked && requiredValidation(mailSettings.port, t(FORM.PORT)).errorText
  const validFrom = formChecked && emailValidation(mailSettings.from).errorText
  const validFromName =
    formChecked && requiredValidation(mailSettings.fromName, t(FORM.FROM_NAME)).errorText
  const validUsername =
    formChecked && requiredValidation(mailSettings.username, t(FORM.EMAIL_API_USERNAME)).errorText
  const validPassword =
    formChecked && requiredValidation(mailSettings.password, t(FORM.PASSWORD)).errorText
  return (
    <Wrapper>
      <SettingsHeader title={t(TITLES.MAIL_SETTINGS_TITLE)} />
      <Layout>
        <Side>
          <LeftSide>
            <CustomFormInput
              errorText={validHostname}
              title={t(FORM.HOSTNAME) as string}
              placeholder={t(PLACEHOLDERS.MAIL_HOST)}
              value={mailSettings.hostname}
              onChange={(value: string) => onChangeInput(value, 'hostname')}
              type="text"
            />

            <CustomFormInput
              errorText={validPort}
              title={t(FORM.PORT) as string}
              placeholder={t(PLACEHOLDERS.MAIL_PORT)}
              value={mailSettings.port}
              onChange={(value: string) => onChangeInput(value, 'port')}
              type="text"
            />

            <CustomFormInput
              errorText={validFrom}
              title={t(FORM.FROM) as string}
              placeholder={t(PLACEHOLDERS.MAIL_FROM)}
              value={mailSettings.from}
              onChange={(value: string) => onChangeInput(value, 'from')}
              type="text"
            />

            <CustomFormInput
              errorText={validFromName}
              title={t(FORM.FROM_NAME) as string}
              placeholder={t(PLACEHOLDERS.MAIL_FROM_NAME)}
              value={mailSettings.fromName}
              onChange={(value: string) => onChangeInput(value, 'fromName')}
              type="text"
            />

            <CustomFormInput
              errorText={validUsername}
              title={t(FORM.EMAIL_API_USERNAME) as string}
              placeholder={t(PLACEHOLDERS.MAIL_API_USERNAME)}
              value={mailSettings.username}
              onChange={(value: string) => onChangeInput(value, 'username')}
              type="text"
            />

            <CustomFormInput
              errorText={validPassword}
              title={t(FORM.PASSWORD) as string}
              value={mailSettings.password}
              onChange={(value: string) => onChangeInput(value, 'password')}
              placeholder={t(PLACEHOLDERS.PASSWORD)}
              type="password"
            />
            <SwitchWrapper>
              <CustomText typography="header3">{t(FORM.SSL)}</CustomText>
              <Switch
                checked={mailSettings.ssl}
                onChange={(value: boolean) => onChangeInput(!value, 'ssl')}
              />
            </SwitchWrapper>
          </LeftSide>
        </Side>
      </Layout>
      <VimeButtonWrapper>
        <CustomButton onClick={onSubmitHandler} text={t(BUTTONS.SAVE)} textColor="white" />
      </VimeButtonWrapper>
    </Wrapper>
  )
}

export default MailManagement

const Wrapper = styled.div`
  /* margin-top: 40px; */
  padding: 0px 30px;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const Layout = styled.div`
  min-height: calc(100vh - 200px);
  overflow-x: auto;
  padding: 20px;
  border-collapse: collapse;
  border: 1px solid ${colors.light80};
  margin-top: 10px;
`

const Side = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const LeftSide = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
`

const VimeButtonWrapper = styled.div`
  padding: 4px;
  float: right;
  margin-top: 20px;
`
