import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {

  useLazyGetSystemQuery,
  useUpdatePasswordSettingsMutation,
} from 'app/services/system.service'
import colors from 'utils/colors'
import { systemActions } from 'app/slices/system.slice'
import { BUTTONS, TITLES, FORM, ERRORS } from 'locales/locales'

import Switch from 'components/switch/Switch'
import Loading from 'components/spinner/Loading'
import CustomText from 'components/text/CustomText'
import CustomButton from 'components/button/CustomButton'
import SettingsHeader from 'features/settings/SettingsHeader'
import DropdownAlert from 'components/dropdown-alert/DropdownAlert'
import CustomFormInput from 'components/input/custom-form-input/CustomFormInput'

const PasswordManagement = () => {
  const { t } = useTranslation()
  const [getPasswordSettings] = useLazyGetSystemQuery()
  const [passwordSettings, setPasswordSettings] = useState({
    uppercase: false,
    lowercase: false,
    letter: false,
    number: false,
    mincharactes: 0,
    uniquecharacters: 0,
  })
  const [passDataId, setPassDataId] = useState('')
  const dispatch = useDispatch()

  const [updatePasswordSettings] = useUpdatePasswordSettingsMutation()

  useEffect(() => {
    fetchMailSettings()
  }, [])

  const fetchMailSettings = async () => {
    try {
      const data = await getPasswordSettings({})

      if (data.data.data.passwordSettings) {
        setPasswordSettings(data.data.data.passwordSettings)
      }
      setPassDataId(data.data.data._id)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmitHandler = () => {
    if (
      Number(passwordSettings.uniquecharacters) < 0 ||
      Number(passwordSettings.mincharactes) < 0
    ) {
      DropdownAlert.ref.show({
        isError: true,
        message: t(ERRORS.INVALID_PASSWORD_SETTINGS),
      })
      return
    }
    Loading.ref.show()
    try {
      if (passDataId == '') {
        dispatch(systemActions.updatePasswordSettings(passwordSettings))
      }
      updatePasswordSettings({ ...passwordSettings, _id: passDataId })
    } finally {
      Loading.ref.hide()
    }
  }

  const onChangeInput = (key: string, value: string | boolean | number) => {
    value = key === 'uniquecharacters' ? Number(value) : value
    value = key === 'mincharactes' ? Number(value) : value
    setPasswordSettings({ ...passwordSettings, [key]: value })
  }

  return (
    <Wrapper>
      <SettingsHeader title={t(TITLES.PASSWORD_SETTINGS_TITLE)} />
      <Layout>
        <Side>
          <LeftSide>
            <SwitchWrapper>
              <CustomText typography="header3">{t(FORM.LOWER_CASE_REQUIRED)}</CustomText>
              <Switch
                checked={passwordSettings.lowercase}
                onChange={(checked: boolean) => onChangeInput('lowercase', !checked)}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <CustomText typography="header3">{t(FORM.UPPER_CASE_REQUIRED)}</CustomText>
              <Switch
                checked={passwordSettings.uppercase}
                onChange={(checked: boolean) => onChangeInput('uppercase', !checked)}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <CustomText typography="header3">{t(FORM.LETTER_CHAR_REQUIRED)}</CustomText>
              <Switch
                checked={passwordSettings.letter}
                onChange={(checked: boolean) => onChangeInput('letter', !checked)}
              />
            </SwitchWrapper>
            <SwitchWrapper>
              <CustomText typography="header3">{t(FORM.NUMBER_REQUIRED)}</CustomText>
              <Switch
                checked={passwordSettings.number}
                onChange={(checked: boolean) => onChangeInput('number', !checked)}
              />
            </SwitchWrapper>
          </LeftSide>
          <NumberInputWrapper>
            <CustomFormInput
              type="number"
              placeholder="Min Char Req"
              title={t(FORM.UNIQUE_CHARACTER_REQUIRED)}
              value={String(passwordSettings.uniquecharacters)}
              onChange={(value: string) => {
                if (Number(value) < 0) return
                onChangeInput('uniquecharacters', Number(value))
              }}
            />
            <CustomFormInput
              type="number"
              placeholder="Min Char Req"
              title={t(FORM.MINIMUM_CHARACTER_REQUIRED)}
              value={String(passwordSettings.mincharactes)}
              onChange={(value: string) => {
                if (Number(value) < 0) return
                onChangeInput('mincharactes', Number(value))
              }}
            />
          </NumberInputWrapper>
        </Side>
      </Layout>
      <VimeButtonWrapper>
        <CustomButton text={t(BUTTONS.SAVE)} textColor="white" onClick={onSubmitHandler} />
      </VimeButtonWrapper>
    </Wrapper>
  )
}

export default PasswordManagement

export const Wrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`

const SwitchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

const NumberInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 210px;
`
const Layout = styled.div`
  min-height: calc(100vh - 200px);
  overflow-x: auto;
  padding: 20px;
  border: 1px solid ${colors.light80};
  margin-top: 10px;
`

const Side = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`

const VimeButtonWrapper = styled.div`
  padding: 4px;
  float: right;
  margin-top: 20px;
`
