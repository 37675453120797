import { t } from 'i18next'
import { IUser } from 'types/user.types'
import { DROPDOWN } from 'locales/locales'

const LANGUAGE = navigator.language.split('-')[0]

export const SCROLL_THRESHOLD = -150
export const MOBILE_WIDTH = 1182
export const MOBILE_DEVICE_WIDTH = 575
export const STORAGE_KEY = 'this is secretly key'
export const MESSAGE_DATE_FORMAT = 'HH:mm'
export const TAG_IMG_NAME = 'IMG'
export const TAG_IMG_VIDEO = 'VIDEO'
export const CONFERENCE_TYPE_VIDEO = 'video'

export const CONFERENCE_BLANK_DIMENSIONS = 'top=50,left=242,width=1200,height=800'
export const PUBLIC_VAPID_KEY ='BC__iuXE3m-VoQPAnr8WvLsA7JrF-gDvqPzJmFq6DvlUcEo8ohPHltZK9braLLxbfWG6gfAXdvPQ8GXtM5gt9eU'

//SIDE PANEL TYPES
export const SIDE_PANEL_PIN = 'Pin'
export const SIDE_PANEL_THREAD = 'Thread'
export const SIDE_PANEL_SEARCH = 'Search'

//USER MANAGEMENT
export const SUPER_ADMIN = 'SUPER_ADMIN'
export const SYSTEM_GUEST = 'SYSTEM_GUEST'
export const SYSTEM_ADMIN = 'SYSTEM_ADMIN'

export const LDAP_DROPDOWN_DATA = [
  {
    title: t(DROPDOWN.ALL_LDAP_SERVERS),
    value: 'all_server',
  },
  {
    title: t(DROPDOWN.ACTIVE_LDAP_SERVERS),
    value: 'active_server',
  },

  {
    title: t(DROPDOWN.INACTIVE_LDAP_SERVERS),
    value: 'inactive_server',
  },
]

export const LDAP_SYNC_TIME_DATA = [
  { title: 'Hour', value: 'hour' },
  { title: 'Day', value: 'day' },
  { title: 'Month', value: 'month' },
  { title: 'Year', value: 'year' },
]

export const ON = ' on '

// FILE UPLOAD
export const XLSX_TYPE = 'application/vnd.ms-excel'
export const PPTX_TYPE = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
export const DOCX_TYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const DOC_TYPE = 'application/msword'
export const PDF_TYPE = 'application/pdf'
export const ZIP_TYPE = 'application/zip'
export const FILE_SIZE_ERROR_CODE = 'file-too-large'
export const FILE_COUNT_ERROR_CODE = 'too-many-files'
export const MB = 1000000
export const GB = MB * 1024
export const MAX_FILE_NUMBER = 10
export const POST_SUPPORTED_FILE_TYPES = {
  'image/*': ['.png', '.jpeg', '.gif', '.jpg', '.bmp', '.psd'],
  'video/*': [
    '.mov',
    '.mp4',
    '.wmv',
    '.avi',
    '.avchd',
    '.mkv',
    '.flv',
    '.f4v',
    '.swf',
    '.3gp',
    '.dat',
  ],
}
export const POST_SUPPORTED_IMAGES_TYPES = {
  'image/*': [],
}
export const POST_SUPPORTED_VIDEO_TYPES = {
  'video/*': [],
}

// export const SUPPORTED_FILE_TYPES = {
//   'image/': ['.png', '.jpeg', '.gif', '.jpg', '.bmp', '.psd'],
//   'video/': [
//     '.mov',
//     '.mp4',
//     '.wmv',
//     '.avi',
//     '.avchd',
//     '.mkv',
//     '.flv',
//     '.f4v',
//     '.swf',
//     '.3gp',
//     '.dat',
//   ],
//   'application/x-bzip/': ['.bz'],
//   'application/x-bzip2/': ['.bz2'],

//   'application/gzip/': ['.gz'],
//   'application/zip/': ['.zip'],
//   'application/pdf/': ['.pdf'],

//   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet/': ['.xlsx', '.xls'],

//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document/': ['.docx'],

//   'application/msword/': ['.doc'],

//   'application/vnd.ms-powerpoint/': ['.ppt'],
//   'application/vnd.openxmlformats-officedocument.presentationml.presentation/': ['.pptx'],

//   'text/plain/': ['.css', '.txt', '.csv', '.htm', '.html', '.javascript', '.js'],
// }

export const SUPPORTED_FILE_TYPES = [
  {
    name: 'Image Extensions',
    extensions: [
      { name: '.png', value: 'image/png' },
      { name: '.jpeg', value: 'image/jpeg' },
      { name: '.jpg', value: 'image/jpg' },
    ],
  },
  {
    name: 'Video Extensions',
    extensions: [
      { name: '.mov', value: 'video/quicktime' },
      { name: '.mp4', value: 'video/mp4' },
      { name: '.avi', value: 'video/x-msvideo' },
      { name: '.flv', value: 'video/x-flv' },
      { name: '.mkv', value: 'video/x-matroska' },
    ],
  },
  {
    name: 'Audio Extensions',
    extensions: [
      { name: '.aac', value: 'audio/aac' },
      { name: '.mp3', value: 'audio/mpeg' },
    ],
  },
  {
    name: 'Text Extensions',
    extensions: [
      { name: '.txt', value: 'text/plain' },
      { name: '.csv', value: 'text/csv' },
    ],
  },
  {
    name: 'Archive Extensions',
    extensions: [
      { name: '.zip', value: 'application/zip' },
      { name: '.rar', value: 'application/vnd.rar' },
    ],
  },
  {
    name: 'PDF',
    extensions: [{ name: '.pdf', value: 'application/pdf' }],
  },
  {
    name: 'Microsoft Excel',
    extensions: [
      {
        name: '.xlsx',
        value: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      {
        name: '.xls',
        value: 'application/vnd.ms-excel',
      },
    ],
  },
  {
    name: 'Microsoft Word',
    extensions: [
      {
        name: '.docx',
        value: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
      { name: '.doc', value: 'application/msword' },
    ],
  },
  {
    name: 'Microsoft PowerPoint',
    extensions: [
      {
        name: '.pptx',
        value: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      },
      { name: '.ppt', value: 'application/vnd.ms-powerpoint' },
    ],
  },
]

export const MAX_FILE_NUMBER_ERROR =
  'Uploads limited to 10 files maximum. Please use additional messages for more files.!'

// OTR
export const OTR_REQUESTED_TEXT = (roomOwner: string, users?: IUser[]) =>
  LANGUAGE === 'en'
    ? `${roomOwner} wants to start Off The Record chat with ${
        users && users.length > 1 ? users.map((u) => u.fullname).join(', ') + ' and ' : ''
      } you. Do you want to join?`
    : `${roomOwner} sizle ${
        users && users.length > 1 ? ', ' + users.map((u) => u.fullname).join(', ') + ' ile ' : ''
      } OTR oluşturmak istiyor. Katılmak istiyor musunuz?`

// TIME
export const SECOND = 1000
export const MINUTE = 60 * SECOND
export const HOUR = 60 * MINUTE
export const DAY = 24 * HOUR
export const WEEK = 7 * DAY
export const MONTH = 30 * DAY

// SERVICE WORKER
export const DELETE_OTR = 'DELETE_OTR'
export const LOGOUT = 'LOGOUT'
export const LOGIN = 'LOGIN'
export const clientChannel = new BroadcastChannel('client')
export const notificationChannel = new BroadcastChannel('notification')
export const commentChannel = new BroadcastChannel('news-feed-comment')

// INVITE
export const PROCESSING_INVITE_TEXT = 'Processing invitation, please wait...'

export const RESEND_INVITE_DESCRIPTION = 'Are you sure you want to send invite to user again?'
export const CONFIRM_RESEND_INVITE = 'Confirm Resend Invite to User?'

export const POST_DATE_FORMATS = {
  POSTED_TODAY: 'HH:mm',
  POSTED_YESTERDAY: 'HH:mm',
  POSTED_AT_LEAST_TWO_DAYS_AGO: 'LLL',
}

export const DATES_IN_MINUTES = {
  HOUR: 60,
  get DAY() {
    return this.HOUR * 24
  },
  get WEEK() {
    return this.DAY * 7
  },
  get MONTH() {
    return this.DAY * 30
  },
  get YEAR() {
    return this.MONTH * 12
  },
}

export const CHANNEL_MEMBER_LIMIT = 250
export const GM_ROOM_MEMBER_LIMIT = 15
