import { useState, useEffect } from 'react'
import {
  useLazyGetRoomInfoForAdminQuery,
  useUpdateRoomArchiveMutation,
} from 'app/services/room.service'
import { FixMeLater } from 'types'
import { IRoomForAdmin } from 'types/rooms.types'
import colors from 'utils/colors'
import ChannelInfoModal from './channel-info/ChannelInfoModal'
import styled from 'styled-components'
import useInfoModal from 'utils/hooks/useInfoModal'
import Loading from 'components/spinner/Loading'
import SettingsHeader from 'features/settings/SettingsHeader'
import { useTranslation } from 'react-i18next'
import { BUTTONS, DROPDOWN, MODALS, TABLE, TITLES } from 'locales/locales'
import { conversationActions } from 'app/slices/conversations.slice'
import { useDispatch } from 'react-redux'
import { t } from 'i18next'
import { useLazyGetPaginatedChannelsQuery } from 'app/services/group.service'
import KGrid from 'components/kendo/grid/KGrid'
import { GridColumn } from '@progress/kendo-react-grid'
import { Button } from '@progress/kendo-react-buttons'
import { FaArchive, FaCheck, FaInfoCircle } from 'react-icons/fa'
import { IChannel } from 'types/group.types'

export const CHANNEL_DROPDOWN_DATA = [
  {
    title: t(DROPDOWN.ALL_CHANNELS),
    value: 'all_group',
  },
  {
    title: t(DROPDOWN.ACTIVE_CHANNELS),
    value: 'active_channel',
  },

  {
    title: t(DROPDOWN.DEACTIVE_CHANNELS),
    value: 'deactive_channel',
  },
]

const queryBodyInitial = {
  search: '',
  filter: '',
  sort: false,
  column: 'createdAt',
  pageNumber: 1,
  limit: 10,
}

const ChannelManagement = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [roomId, setRoomId] = useState('')
  const { openModal, closeModal, Modal } = useInfoModal()
  //Queries
  const [getChannelInfoById] = useLazyGetRoomInfoForAdminQuery()
  const [getAllChannels] = useLazyGetPaginatedChannelsQuery()
  const [updateRoomArchiveById] = useUpdateRoomArchiveMutation()
  //States
  const [queryBody, setQueryBody] = useState(queryBodyInitial)
  const [currentData, setCurrentData] = useState({ data: [] as FixMeLater[], totalCount: 1 })
  //Modal visible state
  const [channelInfoModalVisible, setChannelInfoModalVisible] = useState(false)

  useEffect(() => {
    fetchChannels()
  }, [queryBody])

  const fetchChannels = async () => {
    try {
      const data = await getAllChannels({ ...queryBody })
      if (data.data) {
        const newArr = data.data?.channels.map((channel: IRoomForAdmin) => ({
          ...channel,
          actions: {
            actionKey: channel.isArchived
              ? { text: t(BUTTONS.UNARCHIVE), color: colors.vime,icon: FaCheck }
              : { text: t(BUTTONS.ARCHIVE), color: colors.red ,icon: FaArchive },
          },
        }))
        setCurrentData({ data: newArr, totalCount: data.data?.totalCount || 0 })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onToggleArchive = async (channel: IChannel) => {
    const channelId = channel._id
    setRoomId(channelId)
    openModal({
      title: channel.isArchived ? t(MODALS.UNARCHIVE_TITLE) : t(MODALS.ARCHIVE_TITLE),
      description: channel.isArchived
        ? t(MODALS.UNARCHIVE_DESCRIPTION)
        : t(MODALS.ARCHIVE_DESCRIPTION),
      cancelTitle: t(BUTTONS.CANCEL) as string,
      okTitle: t(BUTTONS.SAVE) as string,
      onCancel: onCloseConfirmArchiveChannel,
      onOk: () => onOkConfirmArchiveChannel(channelId),
    })
  }

  //Table per row edit action
  const onClickChannel = (params: IRoomForAdmin) => {
    setRoomId(params._id)
    setChannelInfoModalVisible(true)
  }

  //Confirm func
  const onOkConfirmArchiveChannel = async (roomId: string) => {
    try {
      closeModal()
      Loading.ref.show()
      const response = await getChannelInfoById({ roomId })
      await updateRoomArchiveById({
        roomId,
        isArchived: !response.data.team.isArchived,
      })
      fetchChannels()
    } finally {
      Loading.ref.hide()
      dispatch(conversationActions.removeAllDraftAndFailedMessages({ channelId: roomId }))
    }
  }

  const onCloseConfirmArchiveChannel = () => {
    closeModal()
  }

  const closeInfoModalVisible = () => {
    setChannelInfoModalVisible(false)
  }

  const [dataState, setDataState] = useState({
    take: 10,
    skip: 0,
    filter: {
      logic: 'and',
      filters: [],
    },
    sort: [],
  })

  const ActionCell: React.FC<FixMeLater> = ({ dataItem }) => {
    const { t } = useTranslation()
    return (
      <td>
        <ButtonContainer>
          <Button
            onClick={() => {
              onClickChannel(dataItem)
            }}
            aria-label={t(dataItem.actions.actionKey.text)}
          >
            <FaInfoCircle />
            {'Info' /*t(TABLE.USER_INFO)*/}
          </Button>
          <Button
            style={{ color: dataItem.actions.actionKey.color }}
            onClick={() => {
              onToggleArchive(dataItem)
            }}
            aria-label={t(dataItem.actions.actionKey.text)}
          >
            {dataItem.actions.actionKey.icon && <dataItem.actions.actionKey.icon />}
            {t(dataItem.actions.actionKey.text)}
          </Button>
        </ButtonContainer>
      </td>
    )
  }

  const handleFilterChange = (event: FixMeLater) => {
    setDataState({
      ...dataState,
      filter: event.filter,
    })
  }

  const onDataStateChange = (event: FixMeLater) => {
    const updatedState = {
      ...dataState,
      skip: event.dataState.skip,
      take: event.dataState.take,
      sort: event.dataState.sort,
    }
    setDataState(updatedState)
  }

  useEffect(() => {
 

    let searchText = ''
    if (dataState.filter && dataState.filter?.filters) {
      dataState.filter.filters.forEach((filter: FixMeLater) => {
        if (filter.field === 'name' || filter.field === 'description') {
          searchText += filter.value
        }
      })
    }
    queryBody.search = searchText

    let sortColumn = ''
    let sortDirection = false

    if (dataState.sort) {
      dataState.sort.forEach((sort: FixMeLater) => {
        sortColumn = sort.field
        if (sort.dir === 'asc') {
          sortDirection = true
        }
        return false
      })

      if (sortColumn != '') {
        queryBody.column = sortColumn
        queryBody.sort = sortDirection
      }
    }
    queryBody.pageNumber = dataState.skip / dataState.take + 1

    setQueryBody({ ...queryBody })
  }, [dataState])

  return (
    <Container>
      <Wrapper>
        <TopContainer>
          <SettingsHeader title={t(TITLES.CHANNEL_SETTINGS_TITLE)} />
        </TopContainer>
        <TableWrapper>
          <KGrid
            //loading={loading}
            datasource={currentData}
            dataState={dataState}
            pageable={true}
            total={currentData.totalCount | 0}
            filterable={true}
            editMode={false}
            sortable={true}
            onFilterChange={handleFilterChange}
            onDataStateChange={onDataStateChange}
          >

            <GridColumn field="name" title={t(TABLE.CHANNEL_NAME)}></GridColumn>
            <GridColumn field="groupName" title={t(TABLE.CHANNEL_TEAM_NAME)}></GridColumn>
            <GridColumn
              filterable={false}
              sortable={false}
              field="isArchived"
              title={t(TABLE.TEAM_STATUS)}
            />
            <GridColumn
              filterable={false}
              sortable={false}
              field="members"
              title={t(TABLE.CHANNEL_MEMBERS)}
            />
            <GridColumn
              field="actions"
              filterable={false}
              title={t(TABLE.CHANNEL_ACTIONS)}
              cell={(props) => <ActionCell {...props} />}
            />
          </KGrid>
        </TableWrapper>
      </Wrapper>
      {channelInfoModalVisible && (
        <ChannelInfoModal
          roomId={roomId}
          fetchChanels={fetchChannels}
          onClose={closeInfoModalVisible}
        />
      )}
      {Modal}
    </Container>
  )
}

export default ChannelManagement

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const Container = styled.div`
  width: 100%;
`

export const TableWrapper = styled.div`
  width: 100%;
  height: 90vh;
  overflow: hidden;
`

export const Wrapper = styled.div`
  padding: 0px 30px;
  width: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`
const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  button span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.8em;
  }
`
