import CustomIcon from 'components/icon/CustomIcon'
import { icons } from 'utils/icons'
import { EMPTY } from 'locales/locales'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  value?: string
  isEmptySearch?: boolean
  isEmptyPin?: boolean
}

const EmptyPage = ({ value, isEmptySearch, isEmptyPin }: Props) => {
  const { t } = useTranslation()
  return (
    <>
      {isEmptySearch && (
        <EmptyPageWrapper>
          <CustomIcon source={icons.searchIcon} />
          <EmptyPageHeader>
            {t(EMPTY.EMPTY_NO_RESULTS)}
            {value && value?.length > 0 ? ` " ${value} "` : ''}
          </EmptyPageHeader>
          <EmptyPageBody>{t(EMPTY.EMPTY_FOR_SEARCH_MESSAGE)}</EmptyPageBody>
        </EmptyPageWrapper>
      )}
      {isEmptyPin && (
        <EmptyPageWrapper>
          <EmptyPageIcon>
            <CustomIcon source={icons.pinIcon} />
          </EmptyPageIcon>
          <EmptyPageHeader>{t(EMPTY.EMPTY_FOR_PIN_MESSAGE_HEADER)}</EmptyPageHeader>
          <EmptyPageBody>{t(EMPTY.EMPTY_FOR_PIN_MESSAGE)}</EmptyPageBody>
        </EmptyPageWrapper>
      )}
    </>
  )
}

export default EmptyPage

const EmptyPageWrapper = styled.div`
  margin-top: 3rem;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  row-gap: 1rem;
`
const EmptyPageIcon = styled.span`
  transform: rotate(30deg);
`
const EmptyPageHeader = styled.h4`
  text-align: center;
  font-weight: 600;
  word-break: break-all;
  padding: 0 30px;
  @media (max-width: 812px) {
    width: 100%;
  }
`

const EmptyPageBody = styled.p`
  text-align: center;
  word-break: normal;
  padding: 0 30px;
  @media (max-width: 812px) {
    width: 100%;
  }
`
