const colors = {
  vimeDark: '#0C213A',
  primaryBlack: '#041124',
  primaryVime: '#499EDA',
  vime: '#139FDB',
  vimeMid: '#182C4A',
  vime50: '#85CEEB',
  vime20: '#CEECF8',
  vime10: '#E6F5FB',
  darkBlue: '#1651A4',
  lightBlue: '#EAECF7',
  light100: '#B2BBC7',
  light80: '#DAE5F3',
  light50: '#F2F4F7',
  light40: '#F8F9FA',
  lightGray: '#F8F7FA',
  secondaryLight: '#C4CEDB',
  white: '#FFFFFF',
  black: '#000000',
  gray100: '#393939',
  gray80: '#6D6E6F',
  gray60: '#ADB0B0',
  gray40: '#DADEDE',
  gray20: '#EDEEEF',
  red: '#EF4958',
  secondaryRed: '#B14E4E',
  yellow: '#FAA72A',
  green: '#08A881',
  secondaryLightBlue: '#8B98A9',
  mainVime: '#059EDA',
  transparentBackground: '#00000040',
  mainVimeMid: '#0977A5',
  secondaryLight50: '#3482AE',
  orange: '#0977A5',
  transparent: 'transparent',
  purple100: '#5E56A5',
}

export default colors
