export const presets = {
  xSmall: { width: '12px', height: '12px' },
  small: {
    width: '16px',
    height: '16px',
  },
  thin: {
    width: '20px',
    height: '20px',
  },
  medium: {
    width: '24px',
    height: '24px',
  },
  grande: {
    width: '28px',
    height: '28px',
  },
  large: {
    width: '32px',
    height: '32px',
  },
  xLarge: { width: '40px', height: '40px' },
  xxLarge: {
    width: '48px',
    height: '48px',
  },
}
